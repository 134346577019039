import React from 'react'
import { DashboardEmptyContainer, DashboardEmptyContainerImage, DashboardEmptyContainerText, DashboardEmptyContainerTitle } from './styled/styled.dashboard'

function EmptyScreen({title, contents, visible, imgSrc}) {
  return (
    <DashboardEmptyContainer style={{'marginTop': '20px'}} visible={visible}>
        <DashboardEmptyContainerTitle>{title}</DashboardEmptyContainerTitle>
        {imgSrc && <DashboardEmptyContainerImage src={imgSrc} />}
        <DashboardEmptyContainerText>{contents}</DashboardEmptyContainerText>
    </DashboardEmptyContainer>
  )
}

export default EmptyScreen