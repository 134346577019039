import React, { useState } from 'react'
import { Button, PageHolder, Text } from './styled/styled.container';
import { StoreCard, StoreCardsContainer, StoreCardsContainerWrapper, StoreCardsPageContainer, TitleText } from './styled/styled.organisationchoice';
import { Colors } from './styled/styled.container';
import LogoImg from "./imgs/logo_name_black.png"
import { LogoNameImg, SplashLogo, TextContainer } from './styled/styled.splash';
import { ButtonTrial } from './styled/styled.login';
import Loader from './Loader';
import { logout } from './firebase';

function OrganisationChoice(props) {
  const {storeClaims, rootID, setRootID} = props;
  const [showLoading, setShowLoading] = useState(false)

  function handleOrganisationChoice(id) {
    setShowLoading(true)
    localStorage.setItem("store", id)
    setRootID(id)
  }

  //console.log(storeClaims)

  return (

        <StoreCardsPageContainer>
            <Loader show={showLoading} />
            
            <StoreCardsContainerWrapper>
            {/* <Text style={{'marginBottom': '5px'}} color={`${Colors.dark_grey_highlight}`} fontSize={"1.8rem"} fontWeight={"700"}>Welcome to</Text> */}
            <LogoNameImg style={{'marginBottom': '10px'}} src={LogoImg} />
            {!rootID && <>
            
            
            <StoreCardsContainer>
                {storeClaims && storeClaims.map((store, i) => {
                    return <StoreCard key={i} onClick={() => handleOrganisationChoice(store.storeID)}>{store.storeID}</StoreCard>
                })}
                
            </StoreCardsContainer>

            </>}
            <Button color={Colors.blue} onClick={() => logout()} style={{'marginTop': '30px'}}>Logout</Button>
              
            
            </StoreCardsContainerWrapper>
            
            

        </StoreCardsPageContainer>

  )
}

export default OrganisationChoice