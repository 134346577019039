import React, { useContext, useEffect, useState } from 'react'
import { BlockContainer, Button, HeaderHolder, PageContainer, PageHolder, StyledAddIcon, StyledClosedIcon, StyledEditIcon, Text } from './styled/styled.container'
import Header from './Header'
import { TemplateDescription, TemplateFormButtonStack, TemplateFormFieldRow } from './styled/styled.templateform'
import AuthContext from './AuthContext'
import { Dialog, DialogContent, DialogTitle, InputAdornment, List, ListItem, ListItemText, TextField } from '@mui/material'
import Loader from './Loader'
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { db } from './firebase'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Colors } from './styled/styled.container'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function TemplatesManagerCategories({ open, setOpen }) {

  const { storeDetails } = useContext(AuthContext);
  const [templateCategories, setTemplateCategories] = useState([])
  const [newCategory, setNewCategory] = useState("")
  const [showFieldError, setShowFieldError] = useState(false)
  const [showLoading, setShowLoading] = useState(false);
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState({})


  useEffect(() => {
    setTemplateCategories(storeDetails.template_categories);
  }, [storeDetails])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') handleAddCategory()
  }

  const handleClose = (event, reason) => {


    setOpen(false)
  }

  async function handleAddCategory() {

    if (newCategory.length === 0) {
      setShowFieldError(true);
      return;
    }

    setShowLoading(true)

    const categoryRef = collection(db, "stores/" + storeDetails.rootID + "/template_categories");
    const newCategoryItem = await addDoc(categoryRef, {
      name: newCategory
    });

    setShowLoading(false)

    setNewCategory("")

  }



  async function handleDeleteCategory(e, cloud_id) {

    
    const templateListCategory = storeDetails.templates.filter(template => template.category === cloud_id);
    if (templateListCategory.length > 0) {
      setBarMessage({
        type: "error",
        message: "You cannot delete a category with templates linked to it."
      })
      setOpenBar(true)
      return;
    }
    else {

      setShowLoading(true)
      const categoryItemRef = doc(db, "stores/" + storeDetails.rootID + "/template_categories/" + cloud_id);
      await deleteDoc(categoryItemRef);

      setTemplateCategories(prevState => prevState.filter(category => category.cloud_id !== cloud_id))
      setShowLoading(false)
    }
  }

  function editSelectedCategoryName(value) {
    setSelectedCategory(prevState => ({ ...prevState, name: value }))
  }

  function handleShowDialog(category) {
    setSelectedCategory(category)
    setIsDialogOpen(true)
  }

  async function handleUpdateCategory() {
    try {

      setIsDialogOpen(false)
      const categoryRef = doc(db, "stores/" + storeDetails.rootID + "/template_categories/" + selectedCategory.cloud_id);
      await updateDoc(categoryRef, { name: selectedCategory.name });

      setBarMessage({
        type: "info",
        message: "Template category updated successfully."
      })
      setOpenBar(true)
      return;

    } catch {
      setIsDialogOpen(false)
      setBarMessage({
        type: "error",
        message: "There was an error updating your template category."
      })
      setOpenBar(true)

    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      
      

      <PageContainer empty_shadow={true}>
      <Loader show={showLoading} />
      <Snackbar open={openBar} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
          {barMessage.message}
        </Alert>
      </Snackbar>
    
      <DialogTitle sx={{'margin': '0px', padding: '0px'}} id="customized-dialog-title">
        <Text fontSize={"1.2rem"} fontWeight={"700"}>Manage Template Categories</Text>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <StyledClosedIcon />
        </IconButton>
      </DialogTitle>
      

        <BlockContainer style={{ 'margin': '10px 0px' }}>
          <TemplateFormFieldRow>
            <TextField
              fullWidth
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              onKeyDown={handleKeyDown}
              error={showFieldError && newCategory.length === 0}
              helperText={showFieldError && newCategory.length === 0 ? "Enter a category name" : ""}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end" onClick={handleAddCategory}>
                    <StyledAddIcon
                      size={32}

                    />

                  </InputAdornment>

              }}
              label="New Template Category"
              placeholder='Enter a new template category name'
              variant="outlined" />

          </TemplateFormFieldRow>
        </BlockContainer>

        <List>
          {templateCategories.map((category, i) => {
            return (
              <ListItem
                sx={{ 'cursor': 'pointer' }}
                
                key={i}
                secondaryAction={
                  <>
                  <IconButton sx={{'margin': '0px 2px'}} edge="end" onClick={(e) => handleShowDialog(category)}><StyledEditIcon /></IconButton>
                  <IconButton sx={{'margin': '0px 2px'}} edge="end" onClick={(e) => handleDeleteCategory(e, category.cloud_id)}>
                    <DeleteIcon />
                  </IconButton>
                  </>
                  
                }>
                <ListItemAvatar>

                  <Avatar sx={{ bgcolor: `${Colors.green}` }}>
                    <AppRegistrationIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={category.name}
                />
              </ListItem>)
          })}

        </List>

        <Dialog fullWidth open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
          <DialogTitle>Edit Category</DialogTitle>

          <DialogContent>
            <TextField
              sx={{ 'marginTop': '10px' }}
              fullWidth
              label="Category Name"
              value={selectedCategory.name}
              onChange={(e) => editSelectedCategoryName(e.target.value)}
            />
            <TemplateFormButtonStack align="right">
            <Button style={{ 'marginTop': '20px' }} onClick={handleUpdateCategory}>
              Update
            </Button>
            </TemplateFormButtonStack>
            
          </DialogContent>
        </Dialog>
        <TemplateFormButtonStack style={{'marginTop': '10px'}} align="right">
          <Button onClick={() => setOpen(false)}>Close</Button>
        </TemplateFormButtonStack>
      </PageContainer>
    </Dialog>
  )
}

export default TemplatesManagerCategories