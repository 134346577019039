import styled from "styled-components";
import { Colors } from "./styled.container";

export const StoreCardsPageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    align-self: center;
    min-height: 100vh;
    justify-self: center;
    margin: auto;
    flex-direction: column;
`

export const TitleText = styled.div`
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${Colors.dark_grey};

`

export const StoreCardsContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px 20px;
    max-width: 800px;
    align-items: center;
    margin: auto;
`

export const StoreCardsContainer = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: center;
    flex-wrap: wrap;
    

`

export const StoreCard = styled.div`
    text-align: center;
    margin: 10px;
    font-size: 0.8rem;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    letter-spacing: 1.1px;
    min-height: 80px;
    text-transform: uppercase;
    color: ${Colors.dark_grey_highlight};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: ${Colors.card_background};
    
    border-radius: 10px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.blue};
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
`