import React, { useContext, useEffect, useState } from 'react'
import { Button, Colors, HeaderHolder, PageContainer, PageHolder, StyledAddValueIcon, StyledAlertIcon, StyledFilterListIcon, StyledTemplateIcon } from './styled/styled.container'
import Header from './Header'
import { TemplateDescription, TemplateFormButtonStack, TemplateFormFieldRow } from './styled/styled.templateform'
import Loader from './Loader'
import { TableContainer } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import AuthContext from './AuthContext'
import { useNavigate } from 'react-router-dom'
import TemplatesManagerCategories from './TemplatesManagerCategories'

const columns = [
    { field: "cloud_id", headerName: "ID", hide: true },
    {
        field: "name",
        headerName: "Template Category",
        minWidth: 200,
        flex: 1

    },
    {
        field: "quantity",
        headerName: "Templates linked",
        minWidth: 100,
        flex: 1
    }
];

function TemplatesViewCategories() {
    const [showLoading, setShowLoading] = useState(false);
    const { storeDetails } = useContext(AuthContext);
    const [categoryList, setCategoryList] = useState([])
    const [showTemplateCategoryDialog, setShowTemplateCategoryDialog] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        if (storeDetails.template_categories) {
            const listCategories = storeDetails.template_categories;
            const allTemplates = storeDetails.templates;
            const updatedList = listCategories.map(category => {
                const qty = allTemplates.filter(template => template.category === category.cloud_id);
                if (qty && qty.length > 0) return { ...category, quantity: qty.length }
                else return { ...category, quantity: 0 }
            }).sort((a, b) => a.name.localeCompare(b.name))

            setCategoryList(updatedList)
        }
    }, [storeDetails])

    const handleRowClick = (params) => {

        navigate("/app/templates/" + params.row.cloud_id);
    };

    return (
        <PageHolder>
            <Header>
                <HeaderHolder>
                    Template Categories
                    <TemplateDescription>Select a category and view the templates linked to it</TemplateDescription>
                </HeaderHolder>
            </Header>

            {showTemplateCategoryDialog && <TemplatesManagerCategories open={showTemplateCategoryDialog} setOpen={setShowTemplateCategoryDialog} />}

            <PageContainer style={{ 'padding': '0px' }}>
                <Loader show={showLoading} />


                <TableContainer style={{ 'marginBottom': '0px' }}>
                    <DataGrid
                        autoHeight
                        sx={{ 'border': '0' }}

                        rows={categoryList}
                        onRowClick={handleRowClick}
                        columns={columns}
                        getRowId={(row) => row.cloud_id}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </TableContainer>
            </PageContainer>

            {storeDetails.permissions.templates.add &&
                <TemplateFormButtonStack align="right" style={{ 'marginTop': '10px' }}>
                    <Button color={Colors.green} onClick={() => navigate("/app/templates/library")}>
                        <StyledAddValueIcon style={{ 'marginRight': '10px' }} />
                        Storecall Library</Button>

                    <Button color={Colors.blue} 
                    onClick={() => setShowTemplateCategoryDialog(true)}>
                        <StyledFilterListIcon />
                        Manage categories</Button>

                    <Button onClick={() => navigate("/app/templates/new")}>
                        <StyledTemplateIcon />New template</Button>

                </TemplateFormButtonStack>
            }

            <TemplateFormFieldRow style={{ 'marginTop': '20px' }} align="right">

            </TemplateFormFieldRow>

        </PageHolder>
    )
}

export default TemplatesViewCategories