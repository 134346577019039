import React from 'react'

import { HeaderTitle, PageContainer } from './styled/styled.container'
import { DashboardButton, DashboardEmptyContainer, DashboardEmptyContainerText, DashboardEmptyContainerTitle } from './styled/styled.dashboard'
import { useNavigate } from 'react-router-dom'



function ErrorRoute() {

    const navigate = useNavigate();

    function returnHome() {
        navigate("/app")
    }
    
  return (
    <PageContainer style={{'margin': '20px 10px'}}>
        <HeaderTitle>Ooops! You're in the wrong place...</HeaderTitle>
        <DashboardEmptyContainer style={{'padding': '30px 10px'}} visible={true}>
            <DashboardEmptyContainerTitle>
            There seems to be an issue
            </DashboardEmptyContainerTitle>
            <DashboardEmptyContainerText>This page does not exist or you do not have sufficient permissions to access it.</DashboardEmptyContainerText>
            <DashboardEmptyContainerText style={{'marginTop': '10px', 'fontWeight': '600'}}>Try contacting your administrator if this issue persists.</DashboardEmptyContainerText>
            <DashboardButton onClick={returnHome}>Return Home</DashboardButton>
        </DashboardEmptyContainer>
    </PageContainer>
    
  )
}

export default ErrorRoute