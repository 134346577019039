import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react'
import { Button, Colors } from './styled/styled.container';

function DeleteOrganisationDialog(props) {
    const { open, setOpen, action } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleAction = () => {
        action()
        setOpen(false);
    }

    return (
        <div>
      <Dialog
        open={open}
        onClose={handleClose}
  
      >
        <DialogTitle>
          Delete Organisation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this organisation? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{margin: '15px'}}>
          <Button onClick={handleClose}>No</Button>
          <Button color={Colors.highlight} onClick={handleAction} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    )
}

export default DeleteOrganisationDialog