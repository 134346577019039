import React from 'react'
import { Route, Routes } from 'react-router-dom';
import SettingsHome from './SettingsHome';
import SettingsDetails from './SettingsDetails';
import SettingsManageUsers from './SettingsManageUsers';
import SettingsUserGroups from './SettingsUserGroups';
import SettingsUserGroupPermissions from './SettingsUserGroupPermissions';
import { HeaderTitle, PageContainer, HeaderSubTitle, CardContainer, Button, Card, CardRow, PageHolder } from './styled/styled.container'
import Header from './Header';
import SettingsTimeZone from './SettingsTimeZone';
import SettingsLogs from './SettingsLogs';

function Settings(props) {
    const {storeDetails} = props;

  return (
    <PageHolder>
    <PageContainer>
        <Header>Settings</Header>

        <Routes>
            <Route exact path='/' element={<SettingsHome storeDetails={storeDetails} />} />
            <Route path="/details/:id" element={<SettingsDetails storeDetails={storeDetails} />} />
            <Route path="/users" element={<SettingsManageUsers storeDetails={storeDetails} />} />
            <Route path="/usergroups" element={<SettingsUserGroups storeDetails={storeDetails} />} />
            <Route path="/usergroups/:id" element={<SettingsUserGroupPermissions />} />
            <Route path="/timezone" element={<SettingsTimeZone />} />
            <Route path="/logs" element={<SettingsLogs />} />

        </Routes>

    </PageContainer>
    </PageHolder>
  )
}

export default Settings