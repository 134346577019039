import React, { useEffect } from 'react';
import { FileItem, FileItemComment, FileDetails, FileItemDescription, FileItemText, FilePreviewContainer, FilePreviewContainerHeading, FileRemoveIcon, FilePreviewImage, FileImageContainer } from './styled/styled.filepreview';

const FilePreview = React.memo(({ files, canEdit, setFileSizeError, newFiles, user, section_item_id, handleRemoveFile, handleRemoveNewFile, handleAddFileComment }) => {
  useEffect(() => {
    const containsLargeFiles = newFiles.filter(file => checkLargeFile(file.file.size));
    if (containsLargeFiles.length > 0) {
      setFileSizeError(true);
    } else {
      setFileSizeError(false);
    }
  }, [newFiles]);

  function isFileImage(description) {
    const lastDotIndex = description.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return false;
    }

    const fileType = description.slice(lastDotIndex + 1);
    const acceptedTypes = ['jpeg', 'jpg', 'png'];

    if (acceptedTypes.includes(fileType.toLowerCase())) {
      return true;
    }
    return false;
  }

  function getFileType(description) {
    const lastDotIndex = description.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return null;
    }

    const fileType = description.slice(lastDotIndex + 1);
    return fileType;
  }

  function handleOpenFile(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  function removeFile(e, i, file) {
    e.stopPropagation();

    
    if(!canEdit) return;

    handleRemoveFile(section_item_id, i, file);
  }

  function removeNewFile(e, i) {
    e.stopPropagation();
    handleRemoveNewFile(section_item_id, i);
  }

  function getFileSize(size) {
    return (size / 1000000).toFixed(2);
  }

  function checkLargeFile(size) {
    const mbSize = (size / 1000000).toFixed(2);
    if (mbSize > 8) {
      return true;
    }

    return false;
  }

  return (
    <FilePreviewContainer>
      <FilePreviewContainerHeading>Media files ({files.length + newFiles.length})</FilePreviewContainerHeading>
      {files && files.map((file, i) => {
        return (
          <FileItem user={file.user} size={getFileSize(file.size)} date={file.date} onClick={() => handleOpenFile(file.url)} key={i}>
            {(user.type === 'admin' || file.user === user.name) && (
              <FileRemoveIcon onClick={(e) => removeFile(e, i, file)} size={22} />
            )}

            {isFileImage(file.description) ? (
              <FileImageContainer>
                <FilePreviewImage src={file.url} />
              </FileImageContainer>
            ) : (
              <FileDetails>
                <FileItemDescription>{file.description}</FileItemDescription>
                <FileItemText>{getFileType(file.description).toUpperCase()} file</FileItemText>
              </FileDetails>
            )}

            <FileItemComment value={file.comment}
            placeholder="No Comment"
            readOnly
          />
        </FileItem>
      );
    })}
    {newFiles &&
      newFiles.map((newFile, newIndex) => {
        return (
          <FileItem user={user.name} size={getFileSize(newFile.file.size)} date="Today" key={newIndex}>
            <FileRemoveIcon onClick={(e) => removeNewFile(e, newIndex)} size={22} />

            {isFileImage(newFile.file.name) ? (
              <FileImageContainer>
                <FilePreviewImage src={newFile.url} />
              </FileImageContainer>
            ) : (
              <FileDetails>
                <FileItemDescription>{newFile.file.name}</FileItemDescription>
                <FileItemText>{getFileType(newFile.file.name).toUpperCase()} file</FileItemText>
              </FileDetails>
            )}

            <FileItemComment onClick={(e) => e.stopPropagation()} onChange={(e) => handleAddFileComment(section_item_id, newIndex, e.target.value)} type="text" maxLength={20} placeholder="Enter comment" />
          </FileItem>
        );
      })}
  </FilePreviewContainer>
);
});

export default FilePreview;