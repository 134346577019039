import React, {useState, useEffect} from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';

import ErrorVisit from './ErrorVisit';
import SchedulerHome from './SchedulerHome';

function Scheduler(props) {

  const {storeDetails} = props;
  
  const navigate = useNavigate();

  useEffect(() => {

    if(!storeDetails.permissions.schedule.view) navigate("/app")

  }, [storeDetails])

//   function updateSelectedStore(store) {
//     setSelectedStore(store)
//   }

//   function updateSelectedTemplate(template) {
//     setSelectedTemplate(template)
//   }

//   function handleStoreVisit() {
//     navigate("/report");
//   }


  return (
    <>
    <Routes>
      <Route path='/' element={<SchedulerHome storeDetails={storeDetails} />} />
      
      <Route path='/error' element={<ErrorVisit />} />
    </Routes>

    </>
  )
}

export default Scheduler;