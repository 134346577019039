// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, query,
  getDocs,
  doc,
  collection,
  where,
  addDoc,
  setDoc,
  getDoc} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail, updateProfile,
  signOut, updateEmail,
  } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBySsIJLWI0lDgI00POF4sq4QQLuMV9dmk",
  authDomain: "storecall.firebaseapp.com",
  projectId: "storecall",
  storageBucket: "storecall.appspot.com",
  messagingSenderId: "935149714001",
  appId: "1:935149714001:web:e0b29c3c55923c8ffc5670",
  measurementId: "G-MD23RK913F"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// const signInWithGoogle = async (storeID) => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "stores/" + storeID + "/users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "stores/" + storeID + "/users"), {
//         uid: user.uid,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);

//   }
// };

const logInWithEmailAndPassword = async (email, password) => {
  try {

    // const q = query(collection(db, "stores/" + storeID + "/users"), where("email", "==", email), where("active", "==", true));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   return "You have entered an incorrect username and password."
    // }
    /*
    else {
      docs.forEach(doc => {
        const displayName = doc.data().name;
        localStorage.setItem("displayName", displayName);
      })
    }
    */

    await signInWithEmailAndPassword(auth, email, password)
    //localStorage.setItem("store", storeID);
  } catch (err) {
    return err.message;
  }
};

async function updateUserEmail(auth, newEmail) {
  await updateEmail(auth, newEmail);
}

const registerAdminWithEmailAndPassword = async (storeID, name, email, password) => {
  try {

    //console.log("Creating store " + storeID);
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    localStorage.setItem("store", storeID);
    //console.log("User login details created");

    addDoc(collection(db, "stores/" + storeID + "/users"), {}).then(result => {
      setDoc(collection(db, "stores/" + storeID + "/users/" + result.id), {
        uid: user.uid,
        name,
        key: result.id,
        authProvider: "local",
        email,
        type: "admin",
        master_admin: true,
        active: true
      })
    })

    //console.log("User added to store");

  }catch(err) {
    return err.message;
  }
};

const createStoreDB = async (storeID) => {
  await setDoc(doc(db, "stores/" + storeID), {
    store: storeID
  })

  //console.log("Store created in db");

}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return {
      status: true, 
      message: "Your password reset instructions have been emailed to you."
    };
    
  } catch (err) {

    if(err.message === "Firebase: Error (auth/user-not-found).")
    {
      return {
        status: false,
        message: "You have entered an incorrect email."
      }
    }
    else return {
      status: false,
      message: err.message
    };
  }
};
const logout = () => {
  localStorage.removeItem("store")
  signOut(auth);
};

export {db, storage, auth, createStoreDB,
  logInWithEmailAndPassword,
  registerAdminWithEmailAndPassword,
  sendPasswordReset, updateUserEmail,
  logout};