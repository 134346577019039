import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useEffect } from 'react'
import { BlueContainer, Colors, StyledScheduleIcon } from '../styled/styled.container'
import { StyledCalendarIcon } from '../styled/styled.icons'

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        hide: true
    },
    {
        field: "start",
        headerName: "Date",
        minWidth: 100,
    },
    {
        field: "template",
        headerName: "Template",
        minWidth: 150,
        flex: 1
    },
    {
        field: "store",
        headerName: "Store",
        minWidth: 150,
        flex: 1
    },
    {
        field: "status",
        headerName: "Status",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
      
            let cellColor = `${Colors.green}`
            if(params.value === "missed") cellColor = `${Colors.highlight}`
            else if(params.value === "waiting") cellColor = `${Colors.orange}`
    
            return (
              <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                {params.value}
              </div>
            );
          },
    },
    {
        field: "name",
        headerName: "User",
        minWidth: 150,
        flex: 1
    }
]

function HomeWeekEvents({ events, storeDetails }) {

    const [rows, setRows] = React.useState([])

    useEffect(() => {
        if (!events || storeDetails.templates.length === 0 || storeDetails.stores.length === 0) return;
        const rows = events.map((event) => {

            let formattedStart = moment(event.start);
            if(formattedStart.isSame(moment(), 'day')) formattedStart = "Today"
            else if(formattedStart.isSame(moment().add(1, 'day'), 'day')) formattedStart = "Tomorrow"
            else formattedStart = formattedStart.format("DD MMM YY")

            return {
                id: event.id,
                
                start: formattedStart,
                original_start: event.start,
                template: storeDetails.templates?.find((template) => template.cloud_id === event.extendedProps.template_id).name || "Could Not Find",
                store: storeDetails.stores?.find((store) => store.cloud_id === event.extendedProps.store_id).name || "Could Not Find",
                status: event.extendedProps.status,
                name: event.extendedProps.name
            }
        })

        const sortedRowsByStart = rows.sort((a, b) => {
            return moment(a.original_start).isBefore(moment(b.original_start)) ? -1 : 1
        })

        setRows(sortedRowsByStart)

    }, [events, storeDetails])

    function EventsGridTitle() {
        return(
            <BlueContainer style={{'paddingTop': '10px', 'margin': '0px'}}><StyledCalendarIcon style={{'marginRight': '10px'}} sx={{'color': `${Colors.white}`}} />Upcoming events</BlueContainer>
        )
      }

    return (
        <DataGrid
            autoHeight
            width="100%"
            density='compact'
            sx={{ 'border': '0',
            '& .MuiDataGrid-columnHeaders': {
                
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                
                // Add more styles here as needed
              },
            }}

            rows={rows}
            //rows={filterActions===true ? storeVisits.filter(visit => visit.actions_outstanding > 0) : storeVisits}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
        />
    )
}

export default HomeWeekEvents