import { useContext, useEffect } from "react";
import AuthContext from "../AuthContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

async function logEvent(storeId, type, message, user) {

    try {
        const logEntry = {
            type: type,
            message: message,
            user: user,
            timestamp: new Date()
        }
        
        await addDoc(collection(db, "stores/" + storeId + "/logs"), logEntry)
    }
    catch(err) {
        console.log(err);
    }

}

export default logEvent;