import React, { useContext, useEffect, useState } from 'react'
import { TemplateFormButtonStack, TemplateFormFieldRow, TemplateFormFieldText } from './styled/styled.templateform'
import { auth, logInWithEmailAndPassword, updateUserEmail } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { Text, ErrorText, HeaderTitle, PageContainer, HeaderSubTitle, CardContainer, Button, Card, CardRow, Colors, StyledPasswordIcon, StyledUpdateIcon, StyledUserIcon, TextLabel, StyledAddIcon } from './styled/styled.container'
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { LoginItemHolder, LoginText, LoginInput } from './styled/styled.login';
import { ShowPasswordIcon, HidePasswordIcon } from './styled/styled.login';
import Loader from './Loader';
import { doc, setDoc, updateDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { db, logout } from "./firebase";
import { useParams } from 'react-router-dom';

import { MenuItem, TextField, InputAdornment, Divider, FormGroup } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoDialog from './InfoDialog';
import AlertDialog from './AlertDialog';
import { sendPasswordReset } from "./firebase"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SettingsCheckButton, SettingsCheckLabel, SettingsCheckOption, SettingsTemplateContainer } from './styled/styled.settings';
import Autocomplete from '@mui/material/Autocomplete';
import AuthContext from './AuthContext';
import { FormControl, InputLabel, Checkbox, ListItemText, FormControlLabel, Radio, RadioGroup, Select } from '@mui/material';
import logEvent from './hooks/logEvent';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function SettingsDetails(props) {

  const { storeDetails } = useContext(AuthContext)
  const [user, loading] = useAuthState(auth);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [newUser, setNewUser] = useState(false);
  const [showUserCreatedDialog, setShowUserCreatedDialog] = useState(false);
  const [showUserDeleteDialog, setShowUserDeleteDialog] = useState(false);
  const [showPasswordResetDialog, setShowPasswordResetDialog] = useState(false);
  const [userGroups, setUserGroups] = useState(storeDetails.user_groups);

  const [specificTemplates, setSpecificTemplates] = useState(false);
  const [specificStores, setSpecificStores] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [selectedStores, setSelectedStores] = useState([])
  const [actionEmailReceive, setActionEmailReceive] = useState(true)
  const [actionEmailReceiveOthers, setActionEmailReceiveOthers] = useState(true)
  const [templates, setTemplates] = useState([]);
  const [stores, setStores] = useState([]);

  const [selectedUserGroup, setSelectedUserGroup] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("user");
  const [selectedUserGroupManager, setSelectedUserGroupManager] = useState([]);

  const [showSpecificTemplatesOption, setShowSpecificTemplatesOption] = useState(false)
  const [showSpecificStoresOptions, setShowSpecificStoresOption] = useState(false);

  const [showFieldError, setShowFieldError] = useState(false);

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    type: "",
  });



  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [userUID, setUserUID] = useState();
  const [userKey, setUserKey] = useState();
  //const [selectedUserGroup, setSelectedUserGroup] = useState("user");
  const [userMe, setUserMe] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("")
  const [userAdmin, setUserAdmin] = useState(false)
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({});

  const handleClick = () => {
    setOpenBar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenBar(false);
    setShowLoading(false)
    if (barMessage.result === "success") navigate("/app/settings")
    else if (barMessage.result === "lotout") logout();
  };

  // useEffect(() => {
  //   if(storeDetails.user.type === "admin") 
  // }, [userType])

  useEffect(() => {

    async function getUser() {



      if (params.id !== "new") {

        setShowLoading(true);
        //console.log(storeDetails);

        const q = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", params.id), where("active", "==", true));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();

        if (data) {

          const actionEmails = data.action_emails === undefined ? true : data.action_emails;
          const actionEmailOthers = data.action_complete_others === undefined ? true : data.action_complete_others;

          setDisplayName(data.name);
          setEmail(data.email);
          setUserUID(data.uid);
          setUserKey(data.key);
          setActionEmailReceive(actionEmails)
          setActionEmailReceiveOthers(actionEmailOthers)

          //const group_manager_ids = storeDetails.user_groups.filter(group => data.group_manager.includes(group.id)).map(group => group.id)

          setSelectedUserGroup(data.group);
          setSelectedUserType(data.type);

          setSelectedUserGroupManager(data.group_manager || []);
          //console.log(storeDetails.user_groups)
          setUserAdmin(data.type === "admin");

          const userDetails = {
            ...data,
            name: data.name,
            email: data.email,
            type: data.type,
            specific_templates: data.specific_templates ? data.specific_templates : false,
            specific_template_list: data.specific_template_list ? data.specific_template_list : [],
            specific_store: data.specific_store ? data.specific_store : false,
            specific_store_list: data.specific_store_list ? data.specific_store_list : [],
          }


          setUserDetails(userDetails);

          if (data.uid === storeDetails.user.uid) setUserMe(true)
          // do not allow the master admin account to downgrade their user permission
          // otherwise with no admins will not be able to manage app

          if (!storeDetails.user.admin) {
            //setUserType(prevState => [...prevState, "admin"]);
            //const group = storeDetails.user_groups.find(group => group.id === data.group)

            setUserGroups([storeDetails.user_groups.find(group => group.id === data.group)])
          }





          setShowLoading(false)
          return userDetails;

        }
      }
      else {

        setNewUser(true);
        const userDetails = {
          name: "",
          email: "",
          type: "user",
          specific_templates: false,
          specific_template_list: [],
          specific_store: false,
          specific_store_list: [],
        }
        return userDetails
      }
    }

    async function getDetails() {
      const userDetails = await getUser();
      const templates = await getTemplates(userDetails.type, userDetails.group);
      const stores = await getStores(userDetails.group);

      setSpecificTemplates(userDetails.specific_templates)
      setSpecificStores(userDetails.specific_store)
      //console.log("List", templates.filter(template => userDetails.specific_template_list.indexOf(template.cloud_id) > -1))
      setSelectedTemplates(templates.filter(template => userDetails.specific_template_list.indexOf(template.cloud_id) > -1));

      // if(!userDetails.type === "admin") {

      //   setShowSpecificStoresOption(true)
      //   setShowSpecificTemplatesOption(true)

      // }

    }

    if (storeDetails.rootID.length > 0) {
      if (storeDetails.user.type !== "admin" && params.id !== auth.currentUser.uid)
        navigate("/app/settings")

      getDetails();
    }

  }, [storeDetails])

  useEffect(() => {

    //if(userDetails.specific_templates) setSpecificTemplates(true)
    //if(userDetails.specific_store) setSpecificStores(true);
    //console.log(userDetails)

    if (userDetails.specific_templates && userDetails.specific_template_list && userDetails.specific_template_list.length > 0) {
      const filteredTemplates = templates.filter(template => userDetails.specific_template_list.indexOf(template.cloud_id) > -1)
      //console.log("Specific", storeDetails.user_specific_templates);
      setSelectedTemplates(filteredTemplates);
    }

    if (userDetails.specific_store && userDetails.specific_store_list && userDetails.specific_store_list.length > 0) {
      //setSpecificStores(true)
      setSelectedStores(stores.filter(store => userDetails.specific_store_list.indexOf(store.cloud_id) > -1));
    }

  }, [userDetails, specificTemplates, showSpecificTemplatesOption, specificStores, showSpecificStoresOptions])

  useEffect(() => {

    if (selectedUserType === "admin") {
      setShowSpecificTemplatesOption(false)
      setShowSpecificStoresOption(false)
    }
    else {

      //if(selectedUserGroup === "manager") setShowSpecificStoresOption(false);
      //else setShowSpecificStoresOption(true);

      setShowSpecificTemplatesOption(true)
      setShowSpecificStoresOption(true)

      getTemplates(selectedUserType, selectedUserGroup)
      getStores(selectedUserGroup);
    }


  }, [selectedUserType])

  useEffect(() => {

    if (!specificTemplates) setSelectedTemplates([])

  }, [specificTemplates])

  useEffect(() => {

    if (!specificStores) setSelectedStores([])

  }, [specificStores])

  const getTemplates = async (userType, userGroup) => {

    let templateRef;

    if (userType === "admin" || params.id === "new")
      templateRef = query(collection(db, "stores/" + storeDetails.rootID + "/templates"))
    else templateRef = query(collection(db, "stores/" + storeDetails.rootID + "/templates"),
      where("permission_users", 'array-contains', userGroup));

    const templateDocs = await getDocs(templateRef);
    setTemplates([])

    const templateElements = await Promise.all(templateDocs.docs.map(item => {
      return new Promise((resolve, reject) => {
        const templateID = item.id;
        const templateData = item.data();

        const templateItem = {
          cloud_id: templateID,
          name: templateData.name,
        }


        resolve(templateItem);
        setSelectedTemplates([])
      })
    }))

    //console.log("User Templates", templateElements);

    setTemplates(templateElements);
    return templateElements;
  }

  const getStores = async (userGroups) => {

    const storesRef = query(collection(db, "stores/" + storeDetails.rootID + "/stores"));

    const storesDocs = await getDocs(storesRef);
    setStores([])

    const storeElements = await Promise.all(storesDocs.docs.map(item => {
      return new Promise((resolve, reject) => {
        const storeID = item.id;
        const storeData = item.data();

        const storeItem = {
          cloud_id: storeID,
          name: storeData.name,
        }

        resolve(storeItem);
        setSelectedStores([])
      })
    }))

    setStores(storeElements);
    return storeElements;
  }

  const handleTemplateChange = (event, values) => {

    //const templateNames = values.map(value => value.name);
    setSelectedTemplates(values);
  };

  const handleStoreChange = (event, values) => {

    //const templateNames = values.map(value => value.name);
    setSelectedStores(values);
  };

  function handleShowPassword() {
    setShowPassword(prevState => !prevState);
  }

  async function handleUpdatePassword() {

    const cred = EmailAuthProvider.credential(user.email, currentPassword);

    try {
      await reauthenticateWithCredential(user, cred)
      await updatePassword(auth.currentUser, newPassword);

      return true;

    } catch (e) {
      return false;
    }
  }

  function isValidEmail() {      // Regular expression pattern for email validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email) && email.length > 0;
  }

  function checkFields() {
    let bCheck = true;

    if (displayName.length === 0) {
      console.log("No display name")
      bCheck = false;
    }




    if (newUser && (newPassword.length === 0 || confirmNewPassword.length === 0)) {
      console.log("No valid password")
      bCheck = false
    }


    if (!isValidEmail()) {
      console.log("Not valid email")
      bCheck = false;
    }
    if (selectedUserGroup.length === 0) {
      console.log("No user group")
      bCheck = false;
    }


    if (!bCheck) setShowFieldError(true);

    if (selectedUserType === "manager" && selectedUserGroupManager.length === 0) bCheck = false;



    return bCheck;
  }

  function checkPasswords() {
    if (newPassword !== confirmNewPassword) return false;
    return true;
  }

  async function getIDToken() {
    const token = await auth.currentUser.getIdToken(true)
    return token;
  }

  async function createUser() {

    if (!checkFields()) {
      return;
    }

    if (!checkPasswords()) {
      setError("Your passwords do not match.")
      return;
    }

    auth.currentUser.getIdToken(true).then(idToken => {

      createUserAPI(idToken, email.trim().toLowerCase(), newPassword, displayName)
        .then(res => {

        })
        .catch(err => {

        });
    })

  }

  async function handleUpdateEmail() {
    const idToken = await getIDToken();

    const response = await fetch('https://us-central1-storecall.cloudfunctions.net/api/update-email', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idToken: idToken, // uid of current user
        current_uid: userUID, // uid of user being queried (if admin querying other user)
        key: userKey,
        storeid: storeDetails.rootID,
        email: email.toLocaleLowerCase()
      })
    });

    if (response.status === 200) {
      logEvent("User", `Updated - ${email} ${selectedUserType} `, storeDetails.user.email);
      return true;
    }
    return false;

  }

  async function handleUpdateUserTypeGroup() {
    const idToken = await getIDToken();

    const response = await fetch('https://us-central1-storecall.cloudfunctions.net/api/update-user-type', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idToken: idToken, // uid of current user
        key: userKey,
        uid: userUID,
        type: selectedUserType,
        group: selectedUserGroup,
        group_manager: selectedUserGroupManager,
        storeid: storeDetails.rootID,
      })
    });

    if (response.status === 200) {
      logEvent("User", `Updated - ${email} ${selectedUserType} `, storeDetails.user.email);
      return true;
    }
    return false;

  }

  async function handleDeleteUser() {

    setShowUserDeleteDialog(false)
    if (storeDetails.user.type !== "admin") return;
    const idToken = await getIDToken();

    setShowLoading(true);
    fetch("https://us-central1-storecall.cloudfunctions.net/api/delete-user", {
      method: "POST",
      headers: { 'Content-Type': "application/json" },
      body: JSON.stringify({
        idToken: idToken,
        current_uid: userUID,
        key: userKey,
        storeid: storeDetails.rootID,
        uid: userUID
      })
    }).then(response => {
      if (response.status == 200) {

        logEvent("User", `Deleted - ${email} ${selectedUserType} `, storeDetails.user.email);
        setShowLoading(false);
        setBarMessage({
          type: "info",
          message: "User has been deleted successfully.",
          result: "success"
        })
        setOpenBar(true)

      }
    }).catch(err => {
      setBarMessage({
        type: "error",
        message: "There was an error deleting this user."
      })
      setOpenBar(true)
    })
  }

  const createUserAPI = async (idToken, email, newPassword, name) => {

    if (!storeDetails.user.admin) logout();

    try {
      //fetch('/create-user?email=' + email + "&password=" + newPassword + "&name=" + name + "&storeid=" + storeDetails.rootID + "&type=" + selectedUserType)
      const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/create-user', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          idToken: idToken,
          email: email,
          password: newPassword,
          name: name,
          type: selectedUserType,
          group: selectedUserGroup,
          group_manager: selectedUserGroupManager,
          specific_templates: specificTemplates,
          specific_template_list: selectedTemplates.map(template => template.cloud_id),
          specific_store: specificStores,
          specific_store_list: selectedStores.map(store => store.cloud_id),
          storeid: storeDetails.rootID,
          action_emails: actionEmailReceive,
          action_complete_others: actionEmailReceiveOthers,
        })
      })

      if (result.status === 200) {
        logEvent("User", `Created - ${email} ${selectedUserType} `, storeDetails.user.email);
        setBarMessage({
          type: "info",
          message: "User has been created successfully!",
          result: "success"
        })
        setOpenBar(true)
      }
      else if (result.status === 500) {
        setBarMessage({
          type: "error",
          message: "Error - this email account is already in use."
        })
        setOpenBar(true)
      }
      else if (result.status === 300) {
        setBarMessage({
          type: "error",
          message: "Error - Organisation max users reached."
        })
        setOpenBar(true)
      }
      else if (result.status === 600) {
        setBarMessage({
          type: "error",
          message: "Error - password entered is too weak."
        })
        setOpenBar(true)
      }
      else {
        setBarMessage({
          type: "error",
          message: "There was an error creating this user."
        })
        setOpenBar(true)
      }

    } catch (err) {
      setBarMessage({
        type: "error",
        message: "There was an error creating this user."
      })
      setOpenBar(true)
    }

    return false;
  };

  async function handlePasswordReset() {
    setShowLoading(true)
    const result = await sendPasswordReset(userDetails.email);
    if (result.status) setShowPasswordResetDialog(true);
    else {
      setBarMessage({
        type: "error",
        message: result.message
      })
      setOpenBar(true)
    }
    setShowLoading(false)
  }

  async function handleChanges() {
    setShowLoading(true)
    await applyChanges();

  }

  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    return sortedArray1.every((element, index) => element === sortedArray2[index]);
  };

  const isUserGroupManagerEqual = () => {
    const userDetailsGroupManager = userDetails.group_manager.map((group) => group.id);
    return areArraysEqual(userDetailsGroupManager, selectedUserGroupManager);
  };

  async function applyChanges() {

    setError("");
    setShowLoading(true)
    let bChangePassword = false;

    if (!checkFields()) {
      setBarMessage({
        type: "error",
        message: "Please fill in all required fields before continuing.",

      })
      setOpenBar(true)
      setShowLoading(false)
      return;
    }



    if ((newPassword.length > 0 || confirmNewPassword.length > 0)) {
      if (currentPassword === "" && !newUser) {
        setBarMessage({
          type: "error",
          message: "Please fill in your current password.",

        })
        setOpenBar(true)
        setShowLoading(false)
        return;
      }

      if (newPassword !== confirmNewPassword) {
        setBarMessage({
          type: "error",
          message: "Your new passwords do not match.",

        })
        setOpenBar(true)
        setShowLoading(false)
        return;
      }

      bChangePassword = true;
    }

    if (userDetails.master_admin && selectedUserType !== "admin") {
      setBarMessage({
        type: "error",
        message: "You cannot change the master admin account type.",

      })
      setOpenBar(true)
      return;
    }

    if (newUser) {

      const result = await createUser();
      return;
    }
    else {

      // only possible if current user being edited
      // you will not see the current/new password fields 
      // if you click any user beside yourself

      try {

        if (bChangePassword) {
          const passwordCheck = await handleUpdatePassword();
          if (!passwordCheck) {
            setBarMessage({
              type: "error",
              message: "You have entered an incorrect current password.",

            })
            setOpenBar(true)
            setShowLoading(false);
            return;
          }
        }

        if (userDetails.email !== email) await handleUpdateEmail();
        if (userDetails.name !== displayName) await updateDoc(doc(db, "stores/" + storeDetails.rootID + "/users/" + userKey), { name: displayName }, { merge: true });
        if (userDetails.type !== selectedUserType || (userDetails.group !== selectedUserGroup && !isUserGroupManagerEqual()))
          await handleUpdateUserTypeGroup();

        await updateDoc(doc(db, "stores/" + storeDetails.rootID + "/users/" + userKey),
          {
            action_emails: actionEmailReceive,
            action_complete_others: actionEmailReceiveOthers,
            specific_templates: specificTemplates,
            specific_template_list: selectedTemplates.map(template => template.cloud_id),
            specific_store: specificStores,
            specific_store_list: selectedStores.map(store => store.cloud_id)
          }, { merge: true });



        if (userMe) {
          setBarMessage({
            type: "info",
            message: "User has been successfully updated.",
            result: "success"
          })
          setOpenBar(true)
        }
        else {
          setBarMessage({
            type: "info",
            message: "User has been successfully updated.",
            result: "success"
          })
          setOpenBar(true)
        }
      }
      catch (error) {
        console.log(error)
        setBarMessage({
          type: "error",
          message: "There was an error updating this user account.",
          result: "error"
        })
        setOpenBar(true)
      }
    }
  }

  function handleNameChange(e) {
    setUserDetails(prevState => ({ ...prevState, name: e }));
  }

  function handleEmailChange(e) {
    setUserDetails(prevState => ({ ...prevState, email: e }));
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleCloseUserScreen() {
    navigate("/app/settings/users")
  }

  function handleClosePasswordResetDialog() {
    setShowPasswordResetDialog(false);
  }

  const handleSelectedUserType = (event) => {

    if (!storeDetails.user.admin) return;

    const newValue = event.target.value;
    if (newValue !== "manager") setSelectedUserGroupManager([]);

    if (newValue !== "admin") {

      setSpecificTemplates(false)
      setSpecificStores(false);
      setShowSpecificStoresOption(false)
      setShowSpecificTemplatesOption(false)

    } else {
      setShowSpecificStoresOption(true);
      setShowSpecificTemplatesOption(true);
    }

    setSelectedUserType(newValue);

  };

  const handleSelectedUserGroupManager = (event, value) => {
    setSelectedUserGroupManager(value.map((option) => option.id));
  };

  return (
    <>
      <CardContainer>
        <Loader showLoading={showLoading} />
        <InfoDialog
          open={showUserCreatedDialog}
          setOpen={setShowUserCreatedDialog}
          title="User"
          content="User has been successfully created."
          action={handleCloseUserScreen} />

        <InfoDialog
          open={showPasswordResetDialog}
          setOpen={setShowPasswordResetDialog}
          title="Password Reset"
          content={`Password reset link has been emailed to ${userDetails.email}.`}
          action={handleClosePasswordResetDialog} />

        <AlertDialog
          open={showUserDeleteDialog}
          setOpen={setShowUserDeleteDialog}
          title="Delete User"
          content={`Are you sure you want to delete ${displayName}?`}
          action={handleDeleteUser} />

        <Snackbar open={openBar} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
            {barMessage.message}
          </Alert>
        </Snackbar>

        <Loader show={showLoading} />
        <LoginItemHolder>
          <Text style={{'marginBottom': '5px'}} fontSize={"0.9rem"} fontWeight={500}>Display Name</Text>
          <TextField
            fullWidth
            sx={{ flex: 1 }}
            variant='outlined'
            placeholder='Enter a name for this user'
            size='small'
            error={showFieldError && displayName.length === 0}
            helperText={showFieldError && displayName.length === 0 ? "Enter a name for this user" : ""}
            value={displayName}
            onChange={e => setDisplayName(e.target.value)} />

        </LoginItemHolder>

        <LoginItemHolder>
        <Text style={{'marginBottom': '5px'}} fontSize={"0.9rem"} fontWeight={500}>Email</Text>
          <TextField
            fullWidth sx={{ flex: 1 }}
            variant='outlined'
            size="small"
            placeholder='Enter the users email'
            error={showFieldError && !isValidEmail()}
            helperText={showFieldError && !isValidEmail() ? "Enter a valid email" : ""}
            value={email} onChange={e => setEmail(e.target.value)} />
        </LoginItemHolder>

        <LoginItemHolder>
        <Text style={{'marginBottom': '5px'}} fontSize={"0.9rem"} fontWeight={500}>Assign to user group</Text>
          <TextField
            sx={{ flex: 1 }}
            fullWidth
            select
            placeholder='Select a group to assign this user into'
            error={showFieldError && selectedUserGroup.length === 0}
            helperText={showFieldError && selectedUserGroup.length === 0 ? "Select a group for this user" : ""}

            
            size="small"
            value={selectedUserGroup}
            onChange={(e) => setSelectedUserGroup(e.target.value)}
          >

            {userGroups && userGroups.map((type, i) => (
              <MenuItem key={i} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>

        </LoginItemHolder>

        <LoginItemHolder>
        <Text style={{'marginBottom': '5px'}} fontSize={"0.9rem"} fontWeight={500}>User type</Text>
          <LoginText>Set a user type for this user (this will determine the amount of information that the user is privileged to)</LoginText>
          <RadioGroup value={selectedUserType} onChange={handleSelectedUserType}>
            <FormControlLabel value="admin" control={<Radio />} label="Administrator" />
            <FormControlLabel disabled={userDetails.master_admin} value="user" control={<Radio />} label="Standard user" />
            <FormControlLabel disabled={userDetails.master_admin} value="manager" control={<Radio />} label="User group manager" />
          </RadioGroup>

          {selectedUserType === 'manager' && (
            <Autocomplete
              sx={{ 'margin': '5px 0px' }}
              multiple
              fullWidth
              disabled={!storeDetails.user.admin}
              id="manager-tags"

              options={storeDetails.user_groups}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              value={selectedUserGroupManager.map((id) =>
                storeDetails.user_groups.find((group) => group.id === id)
              )}
              isOptionEqualToValue={(option, value) =>
                selectedUserGroupManager.includes(option.id)
              }
              onChange={handleSelectedUserGroupManager}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  error={showFieldError && selectedUserType === "manager" && selectedUserGroupManager.length === 0}
                  helperText={showFieldError && selectedUserType === "manager" && selectedUserGroupManager.length === 0 ? "Select a group for this user to manage" : ""}

                  placeholder="Add a user group"
                />
              )} />)}

        </LoginItemHolder>

        {selectedUserType === "user" && <>
          <Divider />
          {showSpecificTemplatesOption &&
            <LoginItemHolder>
              <SettingsCheckOption>
                <SettingsCheckLabel>
                  <SettingsCheckButton disabled={!storeDetails.user.admin} onChange={() => setSpecificTemplates(prevState => !prevState)} checked={specificTemplates} type="checkbox" name="specific-templates" />
                  Set user to have access to specific templates
                </SettingsCheckLabel>
              </SettingsCheckOption>

              {specificTemplates &&
                <SettingsTemplateContainer>

                  <Autocomplete
                    fullWidth
                    size='small'
                    sx={{ 'marginTop': '5px', 'marginBottom': '10px' }}
                    multiple
                    disabled={!storeDetails.user.admin}
                    id="template-tags"
                    options={templates}
                    getOptionLabel={(option) => option.name}
                    value={selectedTemplates}
                    isOptionEqualToValue={(option, value) => option.name === value.name}

                    filterSelectedOptions
                    onChange={handleTemplateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose a template"
                        fullWidth
                      />)}
                  />

                </SettingsTemplateContainer>}
            </LoginItemHolder>}

          {showSpecificStoresOptions &&
            <LoginItemHolder>
              <SettingsCheckOption>
                <SettingsCheckLabel>
                  <SettingsCheckButton disabled={!storeDetails.user.admin} onChange={() => setSpecificStores(prevState => !prevState)} checked={specificStores} type="checkbox" name="specific-templates" />
                  Set user to have access to specific locations
                </SettingsCheckLabel>
              </SettingsCheckOption>

              {specificStores &&
                <SettingsTemplateContainer>
                  <Autocomplete
                    fullWidth
                    size='small'
                    sx={{ 'marginTop': '5px', 'marginBottom': '10px' }}
                    multiple
                    id="store-tags"
                    options={stores}
                    getOptionLabel={(option) => option.name}
                    value={selectedStores}
                    isOptionEqualToValue={(option, value) => option.name === value.name}

                    filterSelectedOptions
                    onChange={handleStoreChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose a location"
                        fullWidth
                      />)}
                  />

                </SettingsTemplateContainer>}
            </LoginItemHolder>}
          <Divider />
        </>}



        {/* {!newUser && userMe &&    
            <LoginItemHolder>
            <LoginText>Current Password</LoginText>
            
            <TextField fullWidth size='small' variant='outlined'
            value={currentPassword}
            sx={{flex: 1}} 
            onChange={e => setCurrentPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
            ></TextField>
            </LoginItemHolder>} */}

        {(newUser) &&
          <LoginItemHolder>
            <LoginText>New Password</LoginText>
            <TextField fullWidth size='small' type={showPassword ? "text" : "password"}
              value={newPassword}
              sx={{ flex: 1 }}
              onChange={e => setNewPassword(e.target.value)}
              error={showFieldError && newPassword.length === 0}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            ></TextField>

          </LoginItemHolder>}

        {(newUser) &&
          <LoginItemHolder>
            <LoginText>Confirm Password</LoginText>
            <TextField fullWidth size='small' type={showPassword ? "text" : "password"}
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
              sx={{ flex: 1 }}
              error={showFieldError && confirmNewPassword.length === 0}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            ></TextField>
          </LoginItemHolder>}

        <LoginItemHolder style={{ 'marginBottom': '10px' }}>
        <Text style={{'marginBottom': '5px'}} fontSize={"0.9rem"} fontWeight={500}>Email Notifications</Text>
          <FormGroup>

            <FormControlLabel control={<Checkbox checked={actionEmailReceive} onChange={(e) => setActionEmailReceive(e.target.checked)} />} label={<TextLabel>Receive daily action reminders that are due.</TextLabel>} />
            
            <FormControlLabel control={<Checkbox checked={actionEmailReceiveOthers} onChange={(e) => setActionEmailReceiveOthers(e.target.checked)} />} label={<TextLabel>Receive notifications for completed actions that you set for your team.</TextLabel>} />
          </FormGroup>
        </LoginItemHolder>



        {error && <ErrorText>{error}</ErrorText>}


      </CardContainer>
      <TemplateFormButtonStack align="right">

        {!newUser &&

            <Button color={Colors.dark_grey} onClick={handlePasswordReset}>
              <StyledPasswordIcon />
              Reset Password
            </Button>}
        {storeDetails.user.admin &&
          !newUser &&
          displayName !== storeDetails.user.name &&
          <Button color={`${Colors.highlight}`} style={{ 'margin': '10px' }} onClick={() => setShowUserDeleteDialog(true)}>
            <StyledUserIcon />
            Delete User
          </Button>}
        <Button style={{ 'margin': '10px' }} onClick={handleChanges}><StyledUpdateIcon /> {newUser ? "Create User" : "Update User"}</Button>


      </TemplateFormButtonStack>
    </>
  )
}

export default SettingsDetails