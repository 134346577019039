import styled from "styled-components";
import { Colors } from "./styled.container";
import { Typography } from "@mui/material";

export const NavMainContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    z-index: 1500;
    height: fit-content;
`

export const NavMenuContainer = styled.div`
    width: 100%;
    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-self: center;
    display: flex;
    align-items: center;

    background-color: ${Colors.grey_shadow};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 25px;
    
    justify-content: space-around;
`
export const NavMenuItem = styled.div`
    font-size: 0.7rem;
`

export const NavContainer = styled.nav`
    width: 100%;
    display: flex;
    height: 60px;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 2px 0px;
    background: linear-gradient(to right, #6356e5, #3a2ade, #5433ff);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    color: ${Colors.blue};
    padding: 0px;
    
    z-index: 300;
`

export const NavInnerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    
`

export const NavExpandedContainer = styled.div`
    display: ${props => props.showExpanded ? "flex" : "none"};
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0, 0.9); 
    z-index: 200;
    overflow-x: hidden; 
    position: fixed;

`

export const NavLeftContainer = styled.div`
    display: flex;
    flex: 1;
    margin-left: 10px;
    align-items: center;
    justify-content: flex-start;
`

export const NavLeftContainerImage = styled.img`
    width: 13px;
    height: 16px;
    margin-right: 5px;

`

export const NavLeftContainerText = styled.h2`
    display: flex;
    
    cursor: pointer;
    align-items: center;
    color: ${Colors.blue};
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 1rem;
    background-color: ${Colors.logo_offwhite};
    height: fit-content;
    align-self: center;
    letter-spacing: 1px;
    margin-left: 10px;
    font-weight: 700;
    margin-right: 10px;

    @media (max-width: 600px) {
        font-size: 0.8rem;
    }
`

export const NavRightContainer = styled.div`
    display: flex;
    align-items: center;
    
    justify-content: flex-end;
    flex: 1;
`

export const NavQuoteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`

export const NavRightIconContainer = styled.div`
    display: flex;
    margin-right: 20px;
    align-items: center;
    letter-spacing: 1.1px;
    z-index: 301;
    color: ${Colors.white};

    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;


    border-radius: 50px;


    @media (max-width: 600px) {
        font-size: 0.8rem;
    }

    
`

export const NavLogoHolder = styled.div`

    display: flex;
    z-index: 300;
    align-items: center;
    justify-content: flex-start;
`

export const NavLogo = styled.img`
    width: 50px;
    height: 50px;
    z-index: 301;
`

export const NavItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 800px) {
        display: none;
    }
`
export const NavExpandedItemContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

`

export const NavExpandedItem = styled.div`
    font-size: 1.8rem;
    text-transform: lowercase;
    margin-top: 20px;
    font-weight: 700;
    color: white;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        color: #cdcdcd;
        font-weight: 700;
        letter-spacing: 2px;
    }
`

export const NavItem = styled.div`
    margin-right: 30px;
    font-size: 0.8rem;
    color: #2b2b2c;
    text-transform: lowercase;
    font-weight: 600;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        color: #cdcdcd;
    }
`

export const Header = styled.div`
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

export const Name = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const StyledSplashNavItem = styled.div`
    font-size: 0.8rem;
    color: ${Colors.light_blue_highlight};
    text-transform: uppercase;
    margin-right: 30px;
    letter-spacing: 1.3px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        background: ${Colors.light_blue_highlight};
        transition: width .5s ease-in-out;
    }

    &:hover::after {
        width: 100%;
        transition: width .3s;
    }
`