import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Navbar from './Navbar';
import { Colors, MainWrapper, StyledHomeIcon } from './styled/styled.container';
import { IconContainer, StyledActionIcon, StyledAddLocationIcon, StyledAddTemplateIcon, StyledArrowDownIcon, StyledArrowUpIcon, StyledCalendarIcon, StyledLocationGroupsIcon, StyledLocationIcon, StyledLogoutIcon, StyledReportsIcon, StyledSettingsIcon, StyledStartVisitIcon, StyledTemplateIcon, StyledUnpublishedVisitIcon, StyledViewLocationsIcon, StyledViewTemplatesIcon, StyledViewVisitsIcon, StyledVisitIcon } from './styled/styled.icons';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import AddTaskIcon from '@mui/icons-material/AddTask';
import TodayIcon from '@mui/icons-material/Today';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ViewListIcon from '@mui/icons-material/ViewList';
import NearMeIcon from '@mui/icons-material/NearMe';
import { Collapse, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from './firebase';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';


function SideDrawer({ children, window, storeDetails }) {

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [openVisits, setOpenVisits] = React.useState(true)
    const [openActions, setOpenActions] = React.useState(false)
    const [openCalendar, setOpenCalendar] = React.useState(false)
    const [openReports, setOpenReports] = React.useState(false)
    const [openTemplates, setOpenTemplates] = React.useState(false)
    const [openLocations, setOpenLocations] = React.useState(false)
    const [openPermanent, setOpenPermanent] = React.useState(true)
    const [drawerWidth, setDrawerWidth] = useState(220);
    const [activePage, setActivePage] = useState("Home");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {


        if (location.pathname === "/app" || location.pathname === "/app/") {
            setActivePage("Home");
        }
        else if (location.pathname.includes("/app/storevisit") || location.pathname.includes("/app/storevisit/")) {
            setActivePage("Visits");
        }
        else if (location.pathname.includes("/app/actions")) {
            setActivePage("Actions");
        }
        else if (location.pathname.includes("/app/scheduler")) {
            setActivePage("Scheduler");
        }
        else if(location.pathname.includes("/app/reports") || location.pathname.includes("/app/reports/")) {
            setActivePage("Reports");
        }
        else if(location.pathname.includes("/app/templates") || location.pathname.includes("/app/templates/")) {
            setActivePage("Templates");
        }
        else if(location.pathname.includes("/app/locations") || location.pathname.includes("/app/locations/")) {
            setActivePage("Locations");
        }
        else if(location.pathname.includes("/app/settings")) {
            setActivePage("Settings");
        }
    
    }, [location.pathname]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navigatePage = (page) => {
        navigate(page);
        setMobileOpen(!mobileOpen);

    }

    

    const styles = {
        menuText: {
            display: openPermanent ? "block" : "none",
            fontSize: "0.8rem",
            marginLeft: '5px',
            fontWeight: "500",
            fontFamily: "Poppins, sans-serif"
        },
        menuSubText: {
            display: openPermanent ? "block" : "none",
            fontSize: "0.8rem",
            marginLeft: '5px',
            fontWeight: "500",
            fontFamily: "Poppins, sans-serif"
        }
    }

    const ListItemComponent = ({ text, icon, onClick, isActive, children }) => {

        const iconStyle = {
            color: isActive ? Colors.white : Colors.blue, // Set the icon color based on isActive
        };

        return (
            <ListItem sx={{marginBottom: '5px'}} disablePadding>
                    <ListItemButton 
         
                        sx={{
                            padding: '5px 10px',
                            gap: '10px',
                            borderRadius: '5px', 
                            backgroundColor: `${isActive ? Colors.blue : Colors.white}`,
                            color: `${isActive ? Colors.white : Colors.dark_grey}`,

                            '&:hover': {
                                backgroundColor: `${isActive ? Colors.blue : Colors.grey_shadow}`,
                                color: `${isActive ? Colors.white : Colors.blue}`,
                            },
                            }} 
                            onClick={onClick}>
                        <ListItemIcon>{React.cloneElement(icon, { style: iconStyle })}</ListItemIcon>
                        <ListItemText primary={<Typography style={styles.menuText}>{text}</Typography>} />
                        {children}
                    </ListItemButton>
            </ListItem>
        )
    }

    const drawer = (
        <div style={{ 'backgroundColor': 'transparent' }}>

            <List sx={{ width: '100%', padding: '0px 10px' }}>
                <ListItemComponent
                    text="Home"
                    icon={<StyledHomeIcon />}
                    onClick={() => navigatePage("/app")}
                    isActive={activePage === "Home"}
                />

                <ListItemComponent
                    text="Visits"
                    icon={<StyledVisitIcon />}
                    onClick={() => setOpenVisits((prev) => !prev)}
                    isActive={activePage === "Visits"}
                    children={openVisits ? <StyledArrowUpIcon /> : <StyledArrowDownIcon />}
                />


                <Collapse in={openVisits} timeout="auto" unmountOnExit>
                    {/* Sub-items for Customers */}

                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/storevisit")}>
                            <Tooltip title="View visits" placement="right-start">
                                <ListItemIcon><StyledViewVisitsIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>View All</Typography>} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/storevisit/unpublished")}>
                            <Tooltip title="Unpublished visits" placement="right-start">
                                <ListItemIcon><StyledUnpublishedVisitIcon /></ListItemIcon>

                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>Unpublished</Typography>} />
                        </ListItemButton>
                    </ListItem>



                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/storevisit/new")}>
                            <Tooltip title="Start visit" placement="right-start">
                                <ListItemIcon><StyledStartVisitIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>Start New</Typography>} />
                        </ListItemButton>
                    </ListItem>


                </Collapse>
                

                <ListItemComponent
                    text="Actions"
                    icon={<StyledActionIcon />}
                    onClick={() => navigatePage("/app/actions")}
                    isActive={activePage === "Actions"}
                />

                <ListItemComponent
                    text="Scheduler"
                    icon={<StyledCalendarIcon />}
                    onClick={() => navigatePage("/app/scheduler")}
                    isActive={activePage === "Scheduler"}
                />

                <ListItemComponent
                    text="Reports"
                    icon={<StyledReportsIcon />}
                    onClick={() => navigatePage("/app/reports")}
                    isActive={activePage === "Reports"}
                />

                <ListItemComponent
                    text="Templates"
                    icon={<StyledTemplateIcon />}
                    onClick={() => setOpenTemplates((prev) => !prev)}
                    isActive={activePage === "Templates"}
                    children={openTemplates ? <StyledArrowUpIcon /> : <StyledArrowDownIcon />}
                />


                <Collapse in={openTemplates} timeout="auto" unmountOnExit>
                    {/* Sub-items for Customers */}

                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/templates")}>
                            <Tooltip title="View templates" placement="right-start">
                                <ListItemIcon><StyledViewTemplatesIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>View Templates</Typography>} />
                        </ListItemButton>
                    </ListItem>



                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/templates/new")}>
                            <Tooltip title="Create template" placement="right-start">
                                <ListItemIcon><StyledAddTemplateIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>Create Template</Typography>} />
                        </ListItemButton>
                    </ListItem>


                </Collapse>

                

                <ListItemComponent
                    text="Locations"
                    icon={<StyledLocationIcon />}
                    onClick={() => setOpenLocations((prev) => !prev)}
                    isActive={activePage === "Locations"}
                    children={openLocations ? <StyledArrowUpIcon /> : <StyledArrowDownIcon />}
                />


                <Collapse in={openLocations} timeout="auto" unmountOnExit>
                    {/* Sub-items for Customers */}

                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/locations")}>
                            <Tooltip title="View locations" placement="right-start">
                                <ListItemIcon><StyledViewLocationsIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>View Locations</Typography>} />
                        </ListItemButton>
                    </ListItem>



                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/locations/create")}>
                            <Tooltip title="Create location" placement="right-start">
                                <ListItemIcon><StyledAddLocationIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>Create Location</Typography>} />
                        </ListItemButton>
                    </ListItem>



                    <ListItem disablePadding>
                        <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => navigatePage("/app/locations/groups")}>
                            <Tooltip title="Location Groups" placement="right-start">
                                <ListItemIcon><StyledLocationGroupsIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary={<Typography style={styles.menuSubText}>Location Groups</Typography>} />
                        </ListItemButton>
                    </ListItem>



                </Collapse>

                

                <ListItemComponent
                    text="Settings"
                    icon={<StyledSettingsIcon />}
                    onClick={() => navigatePage("/app/settings")}
                    isActive={activePage === "Settings"}
                />

                <ListItem key={"logout"} sx={{ padding: '3px 0px', marginBottom: '0px' }} disablePadding>
                    <ListItemButton sx={{ gap: '10px', padding: '5px 10px' }} onClick={() => logout()}>
                        <Tooltip title="Logout" placement="right-start">
                            <ListItemIcon><StyledLogoutIcon /></ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={<Typography style={styles.menuText}>Logout</Typography>} />
                    </ListItemButton>
                </ListItem>



            </List>


        </div>
    );

    function togglePermanentDrawer() {
        setOpenPermanent(prevState => !prevState);
        setDrawerWidth(openPermanent ? 60 : 220);
    }


    const container = window !== undefined ? () => window().document.body : undefined;

    {/* <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar> */}

    return (

        <MainWrapper>
            <Navbar storeDetails={storeDetails} toggleDrawer={handleDrawerToggle} />
            <Box sx={{ display: 'flex', backgroundColor: `${Colors.card_background}` }}>

                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, backgroundColor: `${Colors.card_background}`, flexShrink: { sm: 0 } }}
                    aria-label="navbox"
                >

                    <Drawer

                        variant="temporary"
                        open={mobileOpen}
                        container={container}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { backgroundColor: `${Colors.card_background}`, paddingTop: '70px', boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"

                        sx={{

                            display: { xs: 'none', sm: 'block' },

                            '& .MuiDrawer-paper': {
                                '@media screen and (max-height: 699px)': {
                                    position: "relative",
                                    paddingTop: '20px',
                                    overflowX: 'hidden'
                                },
                                '@media screen and (min-height: 700px)': {
                                    position: "fixed",
                                    paddingTop: '55px',
                                    overflowX: 'hidden'
                                },
                                backgroundColor: `${Colors.card_background}`, boxSizing: 'border-box', width: drawerWidth
                            },
                        }}
                        open
                    >
                        <IconButton onClick={togglePermanentDrawer} sx={{ m: 2, 'width': 'fit-content', 'padding': 0, 'display': 'flex', 'justifyContent': 'flex-end', 'justifySelf': 'flex-end', alignSelf: `${openPermanent ? 'flex-end' : 'center'}` }}>
                            {openPermanent ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    container="true"
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    {/* <Toolbar /> */}
                    {children}
                </Box>
            </Box>
        </MainWrapper>


    );
}


export default SideDrawer;