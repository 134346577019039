import { BiShow, BiHide } from "react-icons/bi";
import styled from "styled-components";
import { Colors } from "./styled.container";


export const LoginPageContainer = styled.div`
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    border-radius: 10px;
    align-self: center;
    align-items: center;
    width: 80%;
    max-width: 500px;
    justify-content: center;
    

    @media (max-width: 800px) {
        max-width: none;
    }

    
`

export const LoginContainer = styled.form`
    
    border-radius: 10px;
    padding: 40px 20px;

//    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;    
    align-items: center;
    
`

export const LoginItemContainer = styled.div`
    display: flex;
    z-index: 300;
    flex-direction: column;
    width: 100%;
`

export const LoginHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: ${Colors.blue};
    margin: 10px;
    font-weight: 800;
    text-transform: uppercase;

    @media (max-width: 400px) {
        font-size: 1rem;
    }
`

export const LoginItemHolder = styled.div`
    display: flex;
    width: 100%;

    border-radius: 5px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    @media (max-width: 400px) {
        
    }
    
`

export const LoginText = styled.div`
    font-size: 0.8rem;
    margin-right: 40px;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;

`

export const LoginInput = styled.input`
    border: 0;
    outline: 0;
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background-color: ${Colors.white};
    font-size: 0.8rem;
` 
export const LoginButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-top: 20px;
    justify-content: space-around;
`


export const LoginButton = styled.button`
    background: ${Colors.blue};
    padding: 10px;
    outline: 0;
    border: 0;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: inline-block;

    font-size: 0.8rem;
    cursor: pointer;
    color: ${Colors.white};
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: ${Colors.blue_highlight};
    }
`
export const ShowPasswordIcon = styled(BiShow)`
    margin-right: -15px;
    cursor: pointer;
    position: absolute;
    right: 0;
`

export const HidePasswordIcon = styled(BiHide)`
    margin-right: -15px;
    cursor: pointer;
    position: absolute;
    right: 0;
`

export const LoginLink = styled.p`
    font-size: 0.9rem;
    display: flex;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 5px;
    color: ${Colors.dark_grey};
    cursor: pointer;
    
    ${({bold}) => bold && `
        font-weight: 600;
    `}

    ${({centered}) => centered && `
        justify-content: center;
    `}

    ${({left}) => left && `
        justify-content: start;
    `}

    ${({right}) => right && `
        justify-content: end;
    `}

`

export const ButtonTrial = styled.button`
    display: flex;
    border: 0;
    outline: 0;
    width: fit-content;
    
    color: ${Colors.white};
    background-color: ${Colors.dark_grey};
    border-radius: 10px;
    padding: 10px 15px;
    align-self: flex-end;
    margin-top: 20px;
    margin-right: 20px;
    align-items: center;
    font-weight: 500;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.dark_grey_highlight};
    }

`