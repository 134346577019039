import React, { useEffect, useState } from 'react'
import { FieldRatingContainer, FieldRatingItem, FieldRatingNumber } from './styled/styled.ratingfield';

function FieldRating(props) {

    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const {section_item_id, updateRating, prevRating, readOnly} = props;

    useEffect(() => {

        if(prevRating) {
            setRating(() => prevRating);
        }
    }, [prevRating])

    function handleUpdateRating(rating) {

        if(!readOnly) {
            setRating(rating);
            
            updateRating(section_item_id, rating);
        }
    }

    const percentageValues = ['0%', '25%', '50%', '75%', '100%'];

    return (
        <FieldRatingContainer>
            {[...Array(5)].map((item, i) => {
                return(
                    <FieldRatingItem 
                        type='button' 
                        key={i} 
                        activate={i<=(rating)} 
                        onClick={() => handleUpdateRating(i)}
                        onMouseEnter={() => setHover(i)}
                        onMouseLeave={() => setHover(rating)}>
                        
                        <FieldRatingNumber>{percentageValues[i]}</FieldRatingNumber>
                    
                    </FieldRatingItem>
                )
            })}
        </FieldRatingContainer>
    )
}

export default FieldRating