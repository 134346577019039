import React, { Fragment, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, Button, GroupHeader, GroupItems } from './styled/styled.container'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, where, getDocs, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chart from "react-apexcharts";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment/moment';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFilterContainer } from './styled/styled.templateform';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/*

cloud_id: visit.cloud_id,
                    id: visit.cloud_id,
                    store_name: visit.name,
                    template_name: selectedTemplates[0].name,
                    date: moment(visit.date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY"),
                    key: visit.key,
                    score: visit.scorable ? visit.score : "Not Scorable",
                    actions_outstanding: visit.actions_outstanding,
                    visit_by: visit.user,
                    visit_status: visit.visit_status

*/


const columns = [
  { field: "cloud_id", headerName: "Element ID", hide: true },
  { field: "id", headerName: "ID", hide: true },
  { field: 'store_name', headerName: 'Location', minWidth: 150, flex: 1 },
  { field: 'region', headerName: 'Location Group', minWidth: 150, flex: 1 },
  { field: 'sub_region', headerName: 'Sub Group', minWidth: 150, flex: 1 },
  { field: 'template_name', headerName: 'Template', minWidth: 150, flex: 1 },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 100,
    flex: 1

  },
  {
    field: "score", headerName: "Score",
    minWidth: 100,
    flex: 1
  },
  {
    field: "actions_outstanding", headerName: "Actions Outstanding",
    minWidth: 100,
    flex: 1
  },
  {
    field: "visit_by", headerName: "Visit By",
    minWidth: 100,
    flex: 1
  },
  {
    field: "visit_status", headerName: "Status",
    minWidth: 100,
    flex: 1
  },
];

function ReportsFrequencyAnalysis(props) {
  const { storeDetails, stores, templates } = props;

  // holds selected stores and template names only
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState('');
  const [selectedSection, setSelectedSection] = useState()
  const [sections, setSections] = useState([]);

  // holds selected store name + cloud id
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [visits, setVisits] = useState([]);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [sectionItems, setSectionItems] = useState([]);
  const [gridItems, setGridItems] = useState([]);
  const [filters, setFilters] = useState(true);
  const [filterActions, setFilterActions] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
  const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [filterFrequency, setFilterFrequency] = useState("")

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const years = ["2023"]
  const frequency = ["Daily", "Weekly", "Monthly"]

  const navigate = useNavigate();

  async function getVisits() {


    if (selectedStores.length === 0 || selectedTemplates.length === 0) {
      //console.log("No store/template")
      return;
    }

    setVisits([])

    const templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x.name == template.name) >= 0)).map((value) => {
      return value;
    })
    const storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name == store.name) >= 0)).map((value) => {
      return value;
    })

    setSelectedStoresInfo(storeIDList);
    setSelectedTemplatesInfo(templateIDList);

    let numbericFilterMonth = moment().month(filterMonth).format('M');
    let numericFilterYear = parseInt(filterYear, 10); // Convert filterYear to an integer

    let dateFrom = moment(`${numbericFilterMonth}/${filterYear}`, "M/YYYY").startOf('month').toISOString();
    let dateEnd = moment(`${numbericFilterMonth}/${filterYear}`, "M/YYYY").endOf('month').add("1", "days").toISOString();

    if (filterFrequency === "Monthly") {
      dateFrom = moment(`1/${filterYear}`, "M/YYYY").startOf('month').add("1", "days").toISOString();
      dateEnd = moment(`12/${filterYear}`, "M/YYYY").endOf('month').add("1", "days").toISOString();
    }



    //const dateTo = moment(filterDateTo).add("1", "days").toISOString();
    const visitItems = [];

    await Promise.all(
      templateIDList.map(async template => {
        for (const store of storeIDList) {
          const q = query(
            collection(
              db,
              "stores/" + storeDetails.rootID + "/visits"
            ),
            orderBy("date", "asc"),
            where("template_id", "==", template.cloud_id),
            where("store_id", "==", store.cloud_id),
            where("date", ">=", dateFrom),
            where("date", "<=", dateEnd)
          );

          const visitDocs = await getDocs(q);

          if (visitDocs.docs.length === 0) {
            // setSeries([]);
            // setOptions({
            //   title: {
            //     text: selectedTemplates[0].name + filterFrequency + " Frequency Analysis",
            //   },
            //   xaxis: {
            //     categories: [],
            //   },
            // });
          }

          for (const visit of visitDocs.docs) {
            const visitID = visit.id;
            const visitData = visit.data();

            const visitElement = {
              ...visitData,
              cloud_id: visit.id,
              date: moment(visitData.date).format("DD-MM-YYYY HH:mm"),
              name: store.name,
              key: visitID,
            };

            visitItems.push(visitElement);
          }
        }
      })
    );

    const gridArray = [];

    // for each store
    selectedStoresInfo.forEach((storeElement, i) => {

      // find the visits related to that store
      const storeVisits = visitItems.filter(visit => visit.store_id === storeElement.cloud_id);

      storeVisits.forEach(visit => {

        const gridItem = {
          cloud_id: visit.cloud_id,
          id: visit.cloud_id,
          store_name: storeElement.name,
          region: storeElement.region,
          sub_region: storeElement.sub_region,
          template_name: selectedTemplates[0].name,
          date: moment(visit.date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY"),
          key: visit.key,
          score: visit.scorable ? visit.score : "Not Scorable",
          actions_outstanding: visit.actions_outstanding,
          visit_by: visit.user,
          visit_status: visit.visit_status
        }

        gridArray.push(gridItem);




      })


    })

    setGridItems(() => gridArray);

    const rows = []; // Store names
    const columns = []; // Days of the month

    // Get unique store names from gridArray
    gridArray.forEach(item => {
      if (!rows.includes(item.store_name)) {
        rows.push(item.store_name);
      }
    });


    if (filterFrequency === 'Daily') {
      for (let day = 1; day <= 31; day++) {
        columns.push(day);
      }
    }
    else if (filterFrequency === "Weekly") {
      columns.push("1", "2", "3", "4")
    }
    else if (filterFrequency === 'Monthly') {
      columns.push("1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12");
    }

    const heatmapData = rows.map(store => {

      const data = columns.map(day => {

        let foundInWeek = false;

        if (filterFrequency === 'Weekly') {
          const weekNumber = parseInt(day); // Extract week number
          //console.log("Week", weekNumber, numbericFilterMonth, numericFilterYear)


          const weeksInMonth = Math.ceil(moment().year(numericFilterYear).month(numbericFilterMonth - 1).daysInMonth() / 7);
          const weekStart = moment().year(numericFilterYear).month(numbericFilterMonth - 1).date(1).add((weekNumber - 1) * 7, 'days').startOf('day');

          // Calculate weekEndDate based on weeksInMonth and weekStartDate
          const weekEnd = weekStart.clone().add(6, 'days').isAfter(moment().year(numericFilterYear).month(numbericFilterMonth - 1).endOf('month'))
            ? moment().year(numericFilterYear).month(numbericFilterMonth - 1).endOf('month')
            : weekStart.clone().add(6, 'days').endOf('day');




          const foundItem = gridArray.some(item => {
            const dayOfMonth = moment(item.date, 'DD/MM/YYYY').date();
            const isWithinWeek = moment(item.date, 'DD/MM/YYYY').isBetween(weekStart, weekEnd);
            //console.log(item.store_name, "Week Start", weekStart.format("DD/MM/YYYY"), weekEnd.format("DD/MM/YYYY"), isWithinWeek);

            return item.store_name === store && isWithinWeek;
          });

          foundInWeek = foundItem;
          //console.log(store, foundInWeek)
        }
        else if (filterFrequency === 'Monthly') {
          const matchingItem = gridArray.some(item => {
            const visitDate = moment(item.date, 'DD/MM/YYYY');
            const isWithinMonth = visitDate.year() === numericFilterYear && visitDate.month() === day - 1;
            //console.log(visitDate.format("DD/MM/YYYY"), isWithinMonth);

            return item.store_name === store && isWithinMonth;
          })
          foundInWeek = matchingItem
        }
        else {
          const matchingItem = gridArray.find(item => {
            const dayOfMonth = moment(item.date, 'DD/MM/YYYY').date();
            return item.store_name === store && dayOfMonth === day;
          });

          foundInWeek = matchingItem !== undefined;
        }

        return {
          x: `${day}`,
          y: foundInWeek ? 1 : 0,
        }

      });

      return {
        name: store,
        data,
      };
    });
    // const heatmapData = rows.map(store => {
    //   const data = columns.map(day => {
    //     const matchingItem = gridArray.find(item => {
    //       const dayOfMonth = moment(item.date, 'DD/MM/YYYY').date();
    //       return item.store_name === store && dayOfMonth === day;
    //     });

    //     return {
    //       x: `${day}`, // Assuming you want 'W' as a prefix for week-like labels
    //       y: matchingItem ? 1 : 0,
    //     };
    //   });

    //   return {
    //     name: store,
    //     data,
    //   };
    // });



    const heatmapOptions = {
      chart: {
        type: 'heatmap',
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: '#FF0000', // Red color for value 0
              },
              {
                from: 1,
                to: 1,
                color: '#00FF00', // Green color for value 1
              },
            ],
          },
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1
      },
      title: {
        text: `${selectedTemplates[0].name} ${filterFrequency} Analysis`
      },

    };

    setSeries(heatmapData)
    setOptions(heatmapOptions)






  }

  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleStoreChange = (event, values) => {

    //const storeNames = values.map(value => value.name);
    setSelectedStores(values);
  };

  function handleGroupLocationsClick(region) {
    const locationsGroup = stores.filter(store => store.region === region);
    setSelectedStores(locationsGroup);
  }

  const handleTemplateChange = async (event, value) => {
    if (value) {
      setSelectedTemplates([value])
      const visitItems = await getVisits();
      // const sectionNames = [];
      // visitItems.forEach(visit => {
      //   console.log("Visit")
      //   console.log(visit)
      //   visit.sections.forEach(section => {
      //     sectionNames.push(section.name)
      //   })
      // })
      // const uniqueSectionNames = [...new Set(sectionNames)]
      // console.log("Sections found")
      // console.log(uniqueSectionNames);
      // setSections(uniqueSectionNames)

    }
    else {
      setSelectedTemplates([]);
      setSections([])
    }
  };

  const handleRowClick = (params) => {
    navigate("/app/storevisit/report/" + params.row.cloud_id);
  };

  function handleSearchFilter() {
    setGridItems([]);
    getVisits();
  }

  return (
    <Fragment>

      <TemplateFormFieldRow align="right" style={{ margin: '10px 0px' }}>
        <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>
      </TemplateFormFieldRow>

      {filters &&
        <TemplateFilterContainer style={{ marginTop: '5px' }}>
          {stores && stores.length > 0 &&
            <Autocomplete
              multiple
              id="store-tags"
              options={[...stores].sort((a, b) => {


                const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                if (groupSort && groupSort === 0) {
                  return sortRegions(a, b);
                }
                return groupSort;
              })}
              groupBy={groupByRegion}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              value={selectedStores}
              onChange={handleStoreChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a location"
                  placeholder="Add a location"
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>

                  <GroupHeader onClick={() => handleGroupLocationsClick(params.group)}>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />}

          {templates && templates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              id="template-tags"
              options={[...templates].sort((a, b) => {
                const groupA = a.categoryName;
                const groupB = b.categoryName;
                if (groupA === groupB) {
                  return a.name.localeCompare(b.name);
                }
                return groupA.localeCompare(groupB);
              })}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              groupBy={(option) => option.categoryName}
              onChange={handleTemplateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a template"
                  placeholder="Add a template"
                />
              )}
            />}

          <TemplateFormFieldDateContainer>
            <TextField
              size="small"
              select
              sx={{ 'marginRight': '10px' }}
              fullWidth
              label="Month"
              placeholder='Choose a month'
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}>
              {months.map(option => {
                return <MenuItem key={option} value={option}>{option}</MenuItem>
              })}
            </TextField>

            <TextField
              size="small"
              select
              fullWidth
              sx={{ 'marginRight': '10px' }}
              label="Year"
              placeholder='Choose a year'
              value={filterYear}
              onChange={(e) => setFilterYear(e.target.value)}>
              {years.map(option => {
                return <MenuItem key={option} value={option}>{option}</MenuItem>
              })}
            </TextField>

            <TextField
              size="small"
              select
              fullWidth
              label="Frequency"

              value={filterFrequency}
              onChange={(e) => setFilterFrequency(e.target.value)}>
              {frequency.map(option => {
                return <MenuItem key={option} value={option}>{option}</MenuItem>
              })}
            </TextField>

          </TemplateFormFieldDateContainer>

          {/* <TemplateFormFieldDateContainer>
            <TextField
                        sx={{'marginRight': '10px'}}
                        label="Period from"
                        size='small'
                        type="date"
                        value={filterDateFrom}
                        onChange={(e) => setFilterDateFrom(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
            />
            <TextField
                        label="Period to"
                        type="date"
                        size='small'
                        fullWidth
                        value={filterDateTo}
                        onChange={(e) => setFilterDateTo(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
            />


            </TemplateFormFieldDateContainer> */}

          <Button disabled={selectedStores.length === 0 || selectedTemplates.length === 0 || filterMonth.length === 0 || filterYear.length === 0 || filterFrequency.length === 0} highlighted onClick={handleSearchFilter} style={{ margin: '10px 0px' }} fullWidth>Run Report</Button>

        </TemplateFilterContainer>}


      <Chart
        options={options}
        series={series}
        type="heatmap"
        height="400" />

      {gridItems && gridItems.length > 0 &&
        <TableContainer>
          <DataGrid
            autoHeight
            sx={{ 'border': '0' }}
            components={{ Toolbar: GridToolbar }}
            rows={gridItems}
            onRowClick={handleRowClick}
            initialState={{
              sorting: {
                sortModel: [{ field: 'visit_date', sort: 'desc' }],
              },
            }}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={7}
            rowsPerPageOptions={[7]}
          />
        </TableContainer>
      }
    </Fragment>
  )
}

export default ReportsFrequencyAnalysis