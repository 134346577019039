import React, {useState} from 'react';
import GoogleMapReact from 'google-map-react';
import { MapContainer } from './styled/styled.map';

const LocationMap = ({ center }) => {
    const Marker = () => <div style={{ color: 'red', fontSize: '30px' }}>📍</div>;

  return (
    <MapContainer>

      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBySsIJLWI0lDgI00POF4sq4QQLuMV9dmk' }}
        defaultCenter={center}
        defaultZoom={15}
      ><Marker lat={center.lat} lng={center.lng} /></GoogleMapReact>
      
    </MapContainer>
  );
};

export default LocationMap;