import React, { useContext, useEffect, useState } from 'react'
import { Button, CardContainer, Colors, ColumnContainer, TableContainer, Text } from './styled/styled.container'
import AuthContext from './AuthContext';
import { collection, limit, onSnapshot, orderBy, query, startAfter } from 'firebase/firestore';
import { db } from './firebase';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { TemplateFormButtonStack } from './styled/styled.templateform';
import Loader from './Loader';

const columns = [
  { field: "cloud_id", headerName: "ID", hide: true },
  { field: "timestamp", headerName: "Date", minWidth: 150, flex: 1,
   valueFormatter: (params) => {
    
    const firestoreTimestamp = params.value;
    const date = new Date(firestoreTimestamp.seconds * 1000 + firestoreTimestamp.nanoseconds / 1000000);
    // Convert JavaScript Date to Moment.js object
    const momentDate = moment(date);
    return momentDate.format("DD/MM/YYYY HH:mm");
  }
  },
  { field: "type", headerName: "Type", minWidth: 120, flex: 1 },
  { field: "message", headerName: "Message", minWidth: 200, flex: 1 },
  { field: "user", headerName: "User", minWidth: 150, flex: 1 },
]

function SettingsLogs() {

  const [logs, setLogs] = useState([]);
  const { storeDetails } = useContext(AuthContext);
  const [lastVisible, setLastVisible] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const loadLogs = (lastDoc) => {
    if (!storeDetails?.rootID) return;

    setShowLoading(true);

    let q;
    if (lastDoc) {
      q = query(
        collection(db, `stores/${storeDetails.rootID}/logs`),
        orderBy("timestamp", "desc"),
        startAfter(lastDoc),
        limit(10)
      );
    } else {
      q = query(
        collection(db, `stores/${storeDetails.rootID}/logs`),
        orderBy("timestamp", "desc"),
        limit(10)
      );
    }

    return onSnapshot(q, (querySnapshot) => {
      const newLogs = [];
      let lastDocInSnapshot = null;

      querySnapshot.forEach((doc) => {
        newLogs.push({ id: doc.id, ...doc.data() });
        lastDocInSnapshot = doc;
      });

      if (lastDocInSnapshot) {
        setLastVisible(lastDocInSnapshot);
      }

      if (lastDoc) {
        setShowLoading(false);
        setLogs(prevLogs => [...prevLogs, ...newLogs]);
      
      } else {
        setShowLoading(false);
        setLogs(newLogs);
      }

      
    });
  };

  useEffect(() => {
    const unsubscribe = loadLogs();
    return () => unsubscribe();
  }, [storeDetails]);

  const handleLoadMore = () => {
    loadLogs(lastVisible);
  };

  return (
    <CardContainer>
      <Loader open={showLoading} />
      <ColumnContainer style={{ 'marginBottom': '10px' }}>
        <Text style={{ flex: 'none', width: 'fit-content', margin: '5px', marginRight: '10px' }}
          fontWeight={600} fontSize={"1rem"} color={Colors.dark_grey_highlight}>Organisation activity</Text>
      </ColumnContainer>

      <TableContainer style={{ 'marginBottom': '15px' }}>
        <DataGrid
          autoHeight
          sx={{ 'border': '0' }}
          rows={logs}
          columns={columns}
          getRowId={(row) => row.id}
          pageSize={10}
          rowsPerPageOptions={[10]}
          onScrollToBottom={handleLoadMore}
        />
      </TableContainer>

      <TemplateFormButtonStack align="right">
        <Button onClick={handleLoadMore} style={{ margin: '5px' }}>Load more</Button>
      </TemplateFormButtonStack>

    </CardContainer>
  )
}

export default SettingsLogs