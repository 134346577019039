import styled from "styled-components";
import VisitIcon from "../imgs/audit_icon.png";
import ActionIcon from "../imgs/actions_icon.png";
import CalendarIcon from "../imgs/calendar_icon.png";
import ReportsIcon from "../imgs/reports_icon.png";
import TemplateIcon from "../imgs/template_icon.png";
import LocationIcon from "../imgs/location_icon.png";

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import AddTaskIcon from '@mui/icons-material/AddTask';
import TodayIcon from '@mui/icons-material/Today';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ViewListIcon from '@mui/icons-material/ViewList';
import NearMeIcon from '@mui/icons-material/NearMe';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import GradingIcon from '@mui/icons-material/Grading';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';

import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';

import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import BugReportIcon from '@mui/icons-material/BugReport';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { Colors } from "./styled.container";


export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

`

export const StyledViewVisitsIcon = styled(AccountTreeRoundedIcon)`

    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledUnpublishedVisitIcon = styled(DriveFileRenameOutlineIcon)`

    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledStartVisitIcon = styled(PlayCircleOutlineRoundedIcon)`
    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledViewTemplatesIcon = styled(ViewQuiltRoundedIcon)`

    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledAddTemplateIcon = styled(LightbulbRoundedIcon)`
    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledViewLocationsIcon = styled(EditLocationRoundedIcon)`

    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledLocationGroupsIcon = styled(LocationCityIcon)`

    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledAddLocationIcon = styled(AddLocationAltRoundedIcon)`
    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledArrowUpIcon = styled(ArrowDropUpIcon)`
    color: ${Colors.dark_blue};
    border-radius: 50%;
`

export const StyledArrowDownIcon = styled(ArrowDropDownIcon)`

    color: ${Colors.dark_blue};
    border-radius: 50%;
`

export const StyledHomeIcon = styled(HomeWorkIcon)`
    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledVisitIcon = styled(DoneAllIcon)`
    color: ${props => props.color ? props.color : Colors.dark_grey_highlight};
    border-radius: 50%;
`

export const StyledActionIcon = styled(AddTaskIcon)`
    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledCalendarIcon = styled(TodayIcon)`
    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledReportsIcon = styled(AssessmentIcon)`
    color: ${Colors.blue};
    border-radius: 50%;
`

export const StyledTemplateIcon = styled(ViewListIcon)`
    color: ${Colors.dark_grey_highlight};
    border-radius: 50%;
`

export const StyledLocationIcon = styled(NearMeIcon)`
    color: ${Colors.dark_grey_highlight};
    border-radius: 50%;
`

export const StyledSettingsIcon = styled(AdminPanelSettingsIcon)`
    color: ${Colors.dark_grey};
    border-radius: 50%;
`

export const StyledLogoutIcon = styled(ExitToAppIcon)`
    color: ${Colors.dark_grey};
    border-radius: 50%;
`

export const StyledEmptyIcon = styled(CheckBoxOutlineBlankIcon)`
    color: ${props => props.color ? props.color : Colors.dark_grey_highlight};

`

export const StyledLogIcon = styled(BugReportIcon)`
    color: ${props => props.color ? props.color : Colors.dark_grey_highlight};
`

export const StyledScoreIcon = styled(EqualizerIcon)`
    color: ${props => props.color ? props.color : Colors.dark_grey_highlight};
`
