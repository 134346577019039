import React, { useState, useEffect, useContext } from 'react';
import { Button } from './styled/styled.container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from "moment"
import Loader from './Loader';
import { TemplateFormFieldRow } from './styled/styled.templateform';
import MenuItem from '@mui/material/MenuItem';
import { Colors, StyledActionIcon } from './styled/styled.container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { Alert, Snackbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';
import FileUploader from './FileUploader';
import FilePreview from './FilePreview';
import DeleteFileDialog from './DeleteFileDialog';
import { auth } from "./firebase"

export default function ActionNewDialog(props) {
    const priorityLevels = ["Low", "Medium", "High"];

    const { open, setOpen, my_uid, i, report, permissions, addAction, updateAction, deleteAction, completeAction, undoCompleteAction, actionInfo, users, stores, removeActionFile } = props;
    const [actionText, setActionText] = useState("")
    const [actionDate, setActionDate] = useState(moment().format("YYYY-MM-DD"));
    const [actionPriority, setActionPriority] = useState("Low");
    const [showLoading, setShowLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedStore, setSelectedStore] = useState(null);
    const [showFieldError, setShowFieldError] = useState(false);
    const { storeDetails } = useContext(AuthContext);
    const [files, setFiles] = useState([])
    const [newFiles, setNewFiles] = useState([])

    const [fileSizeError, setFileSizeError] = useState(false);
    const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [currentUserType, setCurrentUserType] = useState();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('maxWidth(600px)');
    const navigate = useNavigate();

    const [barMessage, setBarMessage] = useState("")
    const [openBar, setOpenBar] = useState(false)

    useEffect(() => {
        if (actionInfo && users) {

            const actionByUID = actionInfo.actionBy;

            const actionByDate = actionInfo.action_due_date || null;

            const actionPriority = actionInfo.action_priority || "Low"
            if (actionByUID) {
                const user = users.filter(user => user.uid === actionByUID)[0]
                setSelectedUser(user)
            }
            if (actionByDate) setActionDate(moment(actionByDate).format("YYYY-MM-DD"))
            if (actionPriority) setActionPriority(actionPriority)

            if (actionInfo.actionBy)
                setActionText(actionInfo.section_item_name)

            if (actionInfo.store_name) {
                const store = stores.filter(store => store.name === actionInfo.store_name)[0];
                setSelectedStore(store)
            }

            const findUser = users.findIndex(user => user.uid === my_uid);
            //console.log("Me", users[findUser]);

            if (actionInfo.files && actionInfo.files.length > 0) {
                setFiles(actionInfo.files)
                
            }

            setCurrentUserType(users[findUser].type);
        }
    }, [actionInfo, users])

    const sortedUsers = [...users].sort((a, b) => a.type.localeCompare(b.type));
    const sortedStores = [...stores].sort((a, b) => a.name.localeCompare(b.name));

    //console.log("here")

    const handleClose = () => {
        setOpen(false);
    };

    const handleUserChange = (event, values) => {
        setSelectedUser(values)
    }

    const handleStoreChange = (event, values) => {
        setSelectedStore(values)
    }

    function handleCompleteAction() {
        
        if(actionInfo.actionComplete) handleUndoCompleteAction()
        else completeAction(actionInfo.action_id);

        setSelectedUser(null)
        setOpen(false)
    }

    function handleUndoCompleteAction() {
        const action = { 
            ...actionInfo,
            action_id: actionInfo.action_id,
            actionBy: selectedUser.uid,
            actionByName: selectedUser.name,
            actionComplete: false,
            actionCompleteDate: "",
            action_due_date: moment(actionDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ssZ"),
            action_priority: actionPriority
        }

        undoCompleteAction(action)
    }



    function handleGotoVisit() {

        const visit_id = actionInfo.visit_id;
        navigate("/app/storevisit/report/" + visit_id);
    }



    function handleEditAction() {

        if (!permissions.actions.edit && actionInfo.action_set_by !== my_uid) setOpen(false);

        if (!checkFieldsFilled()) return;

        updateAction({
            ...actionInfo,
            section_item_name: actionText,
            actionBy: selectedUser.uid,
            store_name: selectedStore?.name,
            files: newFiles,
            actionComplete: false,
            actionCompleteDate: "",
            actionByName: selectedUser.name,
            action_due_date: moment(actionDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ssZ"),
            action_priority: actionPriority
        });
        setSelectedUser(null)
        setOpen(false)
    }

    function handleDeleteAction() {
        deleteAction(actionInfo.section_item_id, actionInfo.index)
        setSelectedUser(null)
        setOpen(false)
    }

    function checkFieldsFilled() {
        let bCheck = true;

        if (actionText.length === 0 || selectedUser === null) bCheck = false;

        if(!moment(actionDate, "YYYY-MM-DD", true).isValid()) {
            setBarMessage({
                type: "error",
                message: "Please select a valid action due date."
            })

            setOpenBar(true)
            return false;
        }


        if (!bCheck) setShowFieldError(true)
        return bCheck;

    }

    function handleAddAction() {

        if (!checkFieldsFilled()) return;

        // user clicked update
        if (actionInfo.actionBy) {
            handleEditAction()
            return;
        }

        const action = {
            type: "solo",
            action_id: moment().format('YYYYMMDDHHmmssSSS'),
            section_item_name: actionText,
            actionBy: selectedUser.uid,
            actionByName: selectedUser.name,
            store_name: selectedStore?.name || null,
            actionByName: selectedUser.name,
            files: newFiles,
            actionComplete: false,
            actionCompleteDate: "",
            action_due_date: moment(actionDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ssZ"),
            action_priority: actionPriority,
            action_set_by: my_uid
        }

        setSelectedUser(null);
        addAction(action)
        setOpen(false)
    }

    function handleRemoveNewFile(section_item_id, i) {
        setNewFiles(prevState => [...prevState.filter((item, index) => index !== i)])
    }

    function handleAddFileComment(i, comment) {

        setFiles(prevState => [...prevState.map((item, index) => {
            if (index === i) {
                item.comment = comment;
            }
            return item;
        })])

    }

    async function getIDToken() {
        const token = await auth.currentUser.getIdToken(true)
        return token;
    }

    async function removeFileCloud(visitID, section_id, section_item_id, file_name) {
        setShowLoading(true);

        const idToken = await getIDToken();

        console.log("Attempting to remove file", storeDetails.rootID, visitID, section_id, section_item_id, file_name)

        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-action-file', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken,
                storeid: storeDetails.rootID,
                visitid: visitID,
                sectionid: section_id,
                sectionitemid: section_item_id,
                file_name: file_name
            })
        })

        if (result.status === 200) {
            setBarMessage({
                type: "info",
                message: "File has been successfully deleted.",
                result: "success"
            })

            setOpenBar(true)
            return true;

        }
        else {
            setShowLoading(false)
            setBarMessage({
                type: "error",
                message: "There was an error deleting this file."
            })

            setOpenBar(true)
            return false;
        }

    }

    async function handleRemoveFile() {
        setShowDeleteFileDialog(false);

        const fileId = selectedFile.file.file_id;

        const removeFileStatus = await removeFileCloud(actionInfo.action_id, actionInfo.action_id, actionInfo.action_id, selectedFile.file.description);
        if (!removeFileStatus) return;

        setFiles(prevState => [...prevState.filter((item, index) => item !== fileId)])

        removeActionFile(actionInfo.action_id, selectedFile.file.file_id)
    }

    function handleFilesUpload(section_item_id, newFiles) {


        // const selectedElementFiles = sections.filter(element => element.id === id);
        // let currentFiles = [...selectedElementFiles[0].files];

        // const updatedFiles = [...currentFiles, ...newFiles];
        // console.log(updatedFiles);
        //uploadFiles(visitID, section_id, section_item_id, newFiles);

        const updatedFiles = [...newFiles.map(newFile => {

            const imageUrl = URL.createObjectURL(newFile);
            return { file: newFile, comment: "", url: imageUrl }
        })]

        console.log("New files", updatedFiles)
        setNewFiles(updatedFiles);


        // setTemplateFields(prevState => (
        //   prevState.map(el => (el.id === id ? { ...el, files: updatedFiles } : el)) 
        // ))
    }

    function handleDeleteFileDialog(section_item_id, i, file) {
        //setSelectedSection(section_id);


        setSelectedFile({
            file: file,
            index: i
        })

        setShowDeleteFileDialog(true);
    }

    const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

    const sortTemplates = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };

    return (
        <div style={{ 'margin': '0px 0px' }}>
            <Loader show={showLoading} />

            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {actionInfo.actionBy ? "Action Info" : "Add Action"}
                </DialogTitle>
                <DialogContent>
                    <DeleteFileDialog
                        open={showDeleteFileDialog}
                        setOpen={setShowDeleteFileDialog}
                        title="Delete File"
                        content="Are you sure you want to delete this file?"
                        selectedFile={selectedFile}
                        action={handleRemoveFile} />

                    <Snackbar open={openBar} autoHideDuration={2500} onClose={() => setOpenBar(false)}>
                        <Alert onClose={() => setOpenBar(false)} severity={barMessage.type} sx={{ width: '100%' }}>
                            {barMessage.message}
                        </Alert>
                        
                    </Snackbar>

                    <TextField
                        sx={{ 'marginTop': '5px', 'fontWeight': '600 !important' }}
                        placeholder='What needs to be done?'
                        label={<Typography sx={{ color: `${Colors.dark_grey}`, fontSize: '1.2rem', fontWeight: '400' }}>Enter an action</Typography>}
                        variant='outlined'
                        value={actionText || ""}
                        disabled={!permissions.actions.edit || (actionInfo.actionBy && actionInfo.action_set_by !== my_uid)}

                        multiline
                        onChange={(e) => setActionText(e.target.value)}
                        fullWidth
                        error={showFieldError && actionText.length === 0}
                        helperText={showFieldError && actionText.length === 0 ? "Enter an action" : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Autocomplete
                        sx={{ 'marginTop': '15px' }}
                        id="user-list"
                        disabled={(!permissions.actions.edit || actionInfo.actionBy && actionInfo.action_set_by !== my_uid)}
                        options={sortedUsers}
                        groupBy={(option) => storeDetails.user_groups.find(group => group.id === option.group).name}
                        getOptionLabel={(option) => option.name}
                        value={selectedUser}

                        onChange={handleUserChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={showFieldError && selectedUser === null}
                                helperText={showFieldError && selectedUser === null ? "Select a user to complete this action" : ""}
                                label="Action by"
                                InputProps={{
                                    ...params.InputProps,

                                    type: 'User to Complete',
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        sx={{ 'marginTop': '15px' }}
                        id="user-list"
                        disabled={(!permissions.actions.edit || actionInfo.actionBy && actionInfo.action_set_by !== my_uid) || actionInfo.type === "audit"}
                        options={[...stores].sort((a, b) => {


                            const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                            if (groupSort && groupSort === 0) {
                                return sortTemplates(a, b);
                            }
                            return groupSort;
                        })}
                        groupBy={groupByRegion}
                        getOptionLabel={(option) => option.name}
                        value={selectedStore ? selectedStore : null}

                        onChange={handleStoreChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='Choose a location for the action'
                                label="Location (Optional)"
                                InputProps={{
                                    ...params.InputProps,

                                    type: 'Location',
                                }}
                            />
                        )}
                    />
                    <TextField
                        sx={{ 'marginTop': '25px' }}
                        label={<Typography sx={{ color: `${Colors.dark_grey}`, fontSize: '1rem', fontWeight: '500' }}>Due Date</Typography>}

                        type="date"
                        disabled={(!permissions.actions.edit || actionInfo.actionBy && actionInfo.action_set_by !== my_uid)}
                        value={actionDate}
                        onChange={(e) => setActionDate(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TemplateFormFieldRow style={{ 'marginTop': '25px' }}>
                        <TextField
                            id="priority-list"
                            select
                            disabled={(!permissions.actions.edit || actionInfo.actionBy && actionInfo.action_set_by !== my_uid)}
                            fullWidth
                            label={<Typography sx={{ color: `${Colors.dark_grey}`, fontSize: '1rem', fontWeight: '500' }}>Priority</Typography>}
                            value={actionPriority}
                            onChange={(e) => setActionPriority(e.target.value)}
                            variant="outlined"
                        >
                            {priorityLevels.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </TemplateFormFieldRow>
                    <TemplateFormFieldRow align="right">
                        <FileUploader canEdit allowMultipleFiles handleFilesUpload={handleFilesUpload} />
                    </TemplateFormFieldRow>

                    {(files.length > 0 || newFiles.length > 0) && <TemplateFormFieldRow>

                        <FilePreview
                            setFileSizeError={setFileSizeError}
                            canEdit={permissions.actions.edit}
                            files={actionInfo.files || []}
                            newFiles={newFiles}
                            user={storeDetails.user}
                            handleAddFileComment={handleAddFileComment}
                            handleRemoveNewFile={handleRemoveNewFile}
                            handleRemoveFile={handleDeleteFileDialog}
                        />
                    </TemplateFormFieldRow>}

                </DialogContent>
                <DialogActions sx={{ marginBottom: '10px', marginRight: '10px' }}>
                    <Stack sx={{ width: '100%', flexDirection: 'column-reverse' }}>
                        {permissions.actions.delete && props.actionInfo && props.actionInfo.actionBy && (
                            <Button
                                fullWidth
                                style={{
                                    backgroundColor: `${Colors.highlight}`,
                                    width: isSmallScreen ? '100%' : 'auto',
                                    margin: '5px',
                                    marginBottom: isSmallScreen ? '10px' : 0,
                                }}
                                color={Colors.highlight}
                                onClick={handleDeleteAction}
                            >
                                Delete
                            </Button>
                        )}
                        {permissions.actions.complete && props.actionInfo && props.actionInfo.actionBy && (
                            <Button
                                style={{
                                    backgroundColor: `${Colors.green}`,
                                    width: isSmallScreen ? '100%' : 'auto',
                                    margin: '5px',
                                    marginBottom: isSmallScreen ? '10px' : 0,
                                }}
                                fullWidth
                                onClick={handleCompleteAction}
                            >
                                {props.actionInfo.actionComplete ? 'Undo Complete' : 'Complete'}
                            </Button>
                        )}

                        {actionInfo.actionBy && permissions.actions.edit &&
                            <Button
                                style={{
                                    backgroundColor: `${Colors.blue}`,
                                    width: isSmallScreen ? '100%' : 'auto',
                                    margin: '5px',
                                    marginBottom: isSmallScreen ? '10px' : 0,
                                }}
                                variant="contained"

                                autoFocus
                                onClick={handleEditAction}
                            >
                                <StyledActionIcon />
                                Update Action
                            </Button>}
                        {!actionInfo.actionBy && permissions.actions.add &&
                            <Button
                                style={{
                                    backgroundColor: `${Colors.blue}`,
                                    width: isSmallScreen ? '100%' : 'auto',
                                    margin: '5px',
                                    marginBottom: isSmallScreen ? '10px' : 0,
                                }}
                                fullWidth

                                autoFocus
                                onClick={handleAddAction}
                            >
                                <StyledActionIcon />
                                Add Action
                            </Button>

                        }


                        {props.actionInfo && props.actionInfo.type === "audit" && permissions.visits.view && (
                            <Button
                                style={{
                                    backgroundColor: `${Colors.blue}`,
                                    width: isSmallScreen ? '100%' : 'auto',
                                    margin: '5px',
                                    marginBottom: isSmallScreen ? '10px' : 0,
                                }}
                                fullWidth
                                onClick={handleGotoVisit}
                            >
                                Go to Visit
                            </Button>
                        )}

                    </Stack>
                </DialogActions>
            </Dialog>
        </div>
    );
}