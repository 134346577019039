import { useContext, useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";

import { InputAdornment, TextField } from "@mui/material";
import { StyledTextField } from "./styled/styled.container";


export const InputFieldSelect = ({field, myVal, InputProps, inputProps, 
  padding, margin, error, variant, updateHandler, helperText, placeholder, 
  align, colour, size, weight, marginTop, lineHeight, letterSpacing, textTransform, 
  position, section_item_id, i, children}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if(myVal) { 
      setValue(myVal)
    }
  }, [])

  const debouncedRequest = useDebounce((value) => {
    
    if(section_item_id && i !== undefined)
    {
      //console.log("here id i", section_item_id, i, value)
      updateHandler(section_item_id, i, value)
    } else if(section_item_id)
    {
  
      updateHandler(section_item_id, value)
    } else {  
      //console.log("here value", value)
      updateHandler(value)
    }
    //setProfileInfo(prevState => ({...prevState, [field]: value}))
  })

  const onChange = (e) => {
    const value = e.target.value;

    setValue(value);

    debouncedRequest(value);
  }


    return <StyledTextField 
    
    select
    fullWidth
    variant={variant}
    
    placeholder={`${placeholder}`}
    value={value}
    error={error}
    helperText={helperText}
    onChange={onChange}
    InputProps={InputProps}
    inputProps={inputProps}>

    {children}
    
    </StyledTextField>
}

