import styled from "styled-components";
import { Colors } from "./styled.container";

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    
    
    
    
    background: rgba(255, 255, 255, 0.8);
    border-radius: 20px;

`

export const StyledFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
    
`;

export const StyledSubmitContainer = styled.div`
    width: 100%;
    
    margin-top: 20px;
    justify-content: flex-end;
    margin-right: 20px;
    display: flex;
    align-items: center;

`