import React from 'react'
import { HeroLogo, HeroLogoImg } from '../styled/styled.splash'
import LogoImageBlue from "../imgs/logo_blue.png";
import LogoImageDark from "../imgs/logo_dark.png";
import LogoImageLight from "../imgs/logo_white.png";

function LogoElement({ fontSize, logoColor, textColor }) {

    const LogoImage = logoColor === "blue" ? LogoImageBlue : logoColor === "dark" ? LogoImageDark : LogoImageLight;

    return (
        <HeroLogo fontSize={fontSize} color={textColor}>
            <HeroLogoImg src={LogoImage || LogoImageBlue} /> Storecall
        </HeroLogo>
    )
}

export default LogoElement