import styled, { keyframes, css } from "styled-components";
import { Colors } from "./styled.container";
import { CheckCircleOutline } from '@mui/icons-material';
import HeroBackgroundImg from "../imgs/hero_background.jpg"

const blink = keyframes`
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
`;

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(44, 140, 244, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(44, 140, 244, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(44, 140, 244, 0);

  }
`;

const wobble = keyframes`

  0% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
`

const movingGradient = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
`;

export const TypeWriter = styled.div`
  max-width: 400px;
  text-align: center;
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0.15em;
  
`;

export const SplashNavContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    background-color: ${Colors.blue};
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
`

export const SplashNavItem = styled.div`
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: white;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-right: 10px;
    color: ${Colors.blue};
`

export const SplashContainer = styled.div`
    width: 100%;
    background-color: #f8f7ff;
    display: flex;
    box-sizing: border-box !important;
    flex-direction: column;
    
`

export const HeroContainer = styled.div`
    

    align-items: center;
    gap: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 30px 20px;
    gap: 20px;
    

    @media (max-width: 1000px) {
      flex-direction: column;
      padding: 150px 5px;
      gap: 30px;
      text-align: center;
    }
`

export const HeroTextContainer = styled.div`

      font-size: 3rem;
      display: flex;
      width: 100%;
      max-width: 600px;
      position: relative;
      min-height: 300px;
      flex-direction: column;
      
      z-index: 400;

      * > span {
        font-size: 3rem;
        font-weight: 700;

        color: ${Colors.light_blue_highlight};

        @media(max-width: 1024px) {
          font-size: 2.5rem;
          text-align: center;
          align-self: center;
        }
      }

      @media (max-width: 1024px) {
        width: 100%;
        font-size: 2.5rem;
      }
    
`

export const HeroLogo = styled.div`
  font-size: ${props => props.fontSize ? props.fontSize : "1rem"};
  color: ${props => props.color ? props.color : `${Colors.white}`};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px;

    display: flex;
    align-items: center;
    
    border-radius: 10px;
`

export const HeroLogoImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  object-fit: contain;

`

export const HeroText = styled.div`

    font-weight: 700;
    
    font-size: 3rem;
    line-height: 1.4;
    letter-spacing: 0.9px;
    
    display: flex;
    color: ${Colors.light_blue_highlight};
    
    
    @media(max-width: 1024px) {
      font-size: 2.5rem;
      width: 100%;
    }

`

export const HeroLogoHeader = styled.div`
    font-size: 3.5rem;
    font-weight: 800;
    margin-top: 5px;
    border-radius: 10px;
    text-align: center;
    text-shadow: 0px 1px 1px ${Colors.light_blue_highlight};
    width: fit-content;
    justify-content: center;
    align-items: center;
    
    
    display: flex;
    align-self: center;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    background-color: ${Colors.white};
    color: ${Colors.blue_highlight};
    padding: 5px 30px;

    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
`

export const HeroSubText = styled.div`
    font-size: 1.6rem;
    margin-top: 20px;
    
    
   
    
    font-weight: 500;
    display: flex;
    letter-spacing: 0.9px;
    
    color: ${Colors.light_blue_highlight};

    

    @media(max-width: 1024px) {
      font-size: 1.4rem;
      width: 100%;
      text-align: center;
    }
`


const generateDots = (numDots) => {
  let dots = '';

  for (let i = 0; i < numDots; i++) {
    const x = Math.floor(Math.random() * 100);
    const y = Math.floor(Math.random() * 100);
    const size = Math.floor(Math.random() * 10) + 5;
    const opacity = Math.random() * 0.5 + 0.5;

    dots += `${x}% ${y}%, ${size}px ${size}px rgba(255, 255, 255, ${opacity}), `;
  }

  return dots.slice(0, -2);
};

const dotAnimation = keyframes`
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(0.8);
  }
  100% {
    opacity: 0.8;
    transform: scale(1);
  }
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box !important;
  align-items: center;
  //background: url(${HeroBackgroundImg});
  background-color: ${Colors.blue};
  background-position: center;
  background-size: cover;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding: 0px 20px;
  position: relative;
  

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #6356e5, #5433ff, rgba(0,0,0,0.1));
    

  }
  
  
`;

export const IntroHeaderContainer = styled.nav`
  
  width: 100%;
  background-color: ${Colors.blue};
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;

`

export const IntroHeaderLogo = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${Colors.white};
`

export const IntroHeaderLogin = styled.div`
  background-color: ${Colors.white};
  color: ${Colors.dark_grey};
  font-size: 0.9rem;
  padding: 5px 40px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${Colors.light_blue_highlight};
  }
`

export const IntroHeroLeftContainer = styled.div`
  display: flex;
  flex: 1;
  z-index: 2;
  flex-direction: column;

  
  


  @media (max-width: 800px) {
    max-width: none;
    margin-right: 0;
    margin-top: 40px;

  }
`


export const IntroLeftContainer = styled.div`
  display: flex;
  
  z-index: 2;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;


  @media (max-width: 800px) {
    
    width: 100%;
    margin-right: 0;


  }
`

export const IntroLeftContainerText = styled.div`
  display: flex;
  flex: 1;
  z-index: 2;
  flex-direction: column;
  
  width: 100%;
  padding: 0px 30px;
  margin: auto;

  @media (max-width: 800px) {
    max-width: none;
    min-width: none;
    margin-right: 0;

  }


`

export const IntroLeftContainerSchedule = styled.div`
  display: flex;
  flex: 1;
  z-index: 2;
  margin-right: 40px;
  max-width: 700px;


  @media (max-width: 800px) {
    max-width: none;
    margin-right: 0;

  }
`

export const IntroRightContainerText = styled.div`
  display: flex;
  
  position: relative;
  flex-direction: column;
  padding: 0px 20px;
  

  @media (max-width: 800px) {
    margin-top: 30px;
    padding: 0px 30px;
    width: 100%;
    justify-content: flex-start;
    align-self: flex-start;
  }
`

export const IntroRightContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  padding: 0px 40px;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-top: 30px;
    padding: 0px 30px;
    width: 100%;
    justify-content: flex-start;
    align-self: flex-start;
  }

  @media (max-width: 500px) {
    padding: 0px 5px;
  }
`

export const CenteredContainer = styled.div`
  display: flex;
  
  position: relative;
  max-width: 800px;
  margin: auto;
  min-height: 400px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    min-height: 200px;
  }
`

export const WorkflowCard = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  box-sizing: border-box !important;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white;
  margin: 0px auto;
  min-height: 400px;

  @media (max-width: 800px) {
    flex-direction: ${props => props.reverse ? "column-reverse" : "column"};
  }
`

export const WorkflowCardElement = styled.div`
  display: flex;
  position: relative;
  padding: 20px 0px;
  max-width: 600px;
  flex-direction: column;
  flex: 1;
`

export const WorkflowCardElementCentered = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: auto;
  align-items: center;
  justify-content: center;
  
  padding: 30px;
`

export const WorkflowCardNumber = styled.div`
  font-size: 3rem;
  font-weight: 400;
  align-self: flex-start;
  color: ${Colors.dark_grey};
  padding: 0px;
`

export const WorkflowCardTitle = styled.div`
  font-size: 2.5rem;
  align-self: flex-start;
  font-weight: 700;
  max-width: 400px;
  color: ${Colors.dark_grey};
  margin-bottom: 10px;
`

export const WorkflowCardText = styled.div`

  font-size: 1.2rem;
  align-self: flex-start;
  font-weight: 500;
  color: ${Colors.dark_grey};
  max-width: 500px;
  margin-top: 10px;
  margin-bottom: 10px;

  b {
    font-weight: 700;
    font-size: 1.2rem;
  }
`

export const WorkflowCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

`

export const CenteredImageContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 1220px;
  align-self: center;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  @media (max-width: 800px) {
    border-radius: 0px;
    margin: 0px;
  }
`

export const CenteredImage = styled.img`
  width: 100%;
  align-self: center;
  height: 100%;

`

export const CenteredText = styled.div`
  font-size: 1.7rem;
  font-weight: 500;
  
  line-height: 1.6;
  
  color: ${Colors.dark_grey_highlight};
  text-align: center;

  p {
    display: inline-block;
    font-size: 3rem;
    margin-bottom: 10px;
    
    padding: 0px 10px;
    border-radius: 5px;
    font-weight: 700;
    
    color: ${Colors.dark_grey_gradient};
    position: relative;

    &::after {
      content: "";
      
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    font-size: 1.6rem;
    margin: 10px 30px;
    font-weight: 500;

    p {
      font-size: 2rem;
      &::after {
        bottom: 0px;
      }
    }
  }

`

export const IntroContainerFeatures = styled.div`
  display: flex;
  flex: 1;

  position: relative;
  
  border-radius: 20px;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 0px 20px;
    padding-top: 30px;
    width: 100%;
    justify-content: flex-start;
    align-self: flex-start;
  }
`

export const SplashLogo = styled.img`
  width: 70px;
  height: 70px;
  margin-right: 10px;
  object-fit: contain;

  @media (max-width: 600px) {
    width: 50px;
    height: 50px;
  }
`

export const LogoNameImg = styled.img`
  width: 200px;
  height: 50px;
  object-fit: contain;
`

export const TextContainer = styled.div`
  color: ${props => props.color ? props.color : `${Colors.white}`};

  width: 100%;
  font-size: 4rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-weight: 700;

  @media (max-width: 800px) {
    font-size: 3rem;
  }

  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-self: flex-start;

`

export const ImageContainerLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  border-radius: 20px;
  padding: 0px 10px;
  max-width: 600px;

`

export const ImageContainerLeftSchedule = styled.div`
  width: 100%;
  height: 100%;
  
  display: flex;
  
  
  padding: 5px 5px;
  max-width: 600px;

  @media (max-width: 800px) {
    margin-top: 40px;
  }

`

export const ImageContainerRight = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  min-height: 400px;
  max-width: 800px;
  
  

  display: flex;


  @media (max-width: 800px) {
    margin-top: 0px;
    min-height: auto;
    
  }

`

export const ImageHeroHolder = styled.div`
  display: flex;
  position: relative;
`

export const ImageHeroContainer = styled.div`
  align-self: center;
  max-width: 900px;
  flex: 1;

`

export const ImageHero = styled.img`
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: contain;
`

export const ImageEffectContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${props => props.rounded ? "10px" : "0px"};
  display: flex;
  
  
  max-width : ${props => props.width ? props.width : "none"};
`

export const TickContainer = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 0px;
    margin-bottom: 30px;
  }
  
`

export const TickItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 10px;

`

export const TickImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;

`

export const TickNumber = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${Colors.blue};
  font-size: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-weight: 700;
  color: ${Colors.white};
  text-align: center;
`

export const TickText = styled.div`
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  flex: 1;
  margin-left: 30px;
  max-width: 600px;
  color: ${Colors.dark_grey};

  @media (max-width: 800px) {
    font-size: 0.9rem;
  }
`



export const FeatureContainerAudits = styled.div`
  width: 100%;
  margin-bottom: 60px;
  align-self: center;
  box-sizing: border-box !important;
  justify-content: center;
  border-radius: 20px;
  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  background-color: ${props => props.background ? props.background : `${Colors.white}`};
  background: ${props => props.backgroundGradient ? props.backgroundGradient : ""};


  @media (max-width: 800px) {
    flex-direction: column;
  }

  
`

export const FeatureContainerSchedule = styled.div`
  width: 90%;
  margin: 40px 0px;
  align-self: center;
  justify-content: center;
  border-radius: 20px;
  box-sizing: border-box !important;
  min-height: 500px;
  
  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  background-color: ${props => props.background ? props.background : `${Colors.light_grey}`};
  background: ${props => props.backgroundGradient ? props.backgroundGradient : ""};


  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }

  
`


export const FeatureContainer = styled.div`
  width: 90%;

  margin: 40px auto;
  border-radius: 20px;
  box-sizing: border-box !important;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  min-height: 500px;
  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  
  background-color: ${props => props.background ? props.background : `${Colors.light_grey}`};
  background: ${props => props.backgroundGradient ? props.backgroundGradient : ""};
  

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0px 0px;
    padding-top: 20px;
  }

  
`

export const FeatureContainerReports = styled.div`
  width: 90%;
  margin: 40px 0px;
  align-items: center;
  box-sizing: border-box !important;
  align-self: center;
  border-radius: 20px;
  
  
  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  
  background-color: ${props => props.background ? props.background : `${Colors.light_grey}`};
  background: ${props => props.backgroundGradient ? props.backgroundGradient : ""};
  padding: 0px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 60px 30px;
  }

  
`

export const FeatureContainerHero = styled.div`
  width: 90%;
  align-self: center;
  border-radius: 30px;
  box-sizing: border-box !important;
  margin: 60px 0px;
  padding: 50px 30px;
  align-items: center;
  justify-content: center;

  background-color: ${Colors.white};

  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;

  min-height: 500px;
  
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 60px 30px;
  }

  @media (max-width: 500px) {
    margin: 30px 0px;
    width: 100%;
    padding: 40px 20px;
  }

`

export const AccordionWrapper = styled.div`
  width: 90%;
  margin: 60px auto;
  padding: 50px 30px;

  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 60px 30px;
  }

  @media (max-width: 500px) {
    margin: 30px 0px;
    width: 100%;
    padding: 40px 20px;
  }
`

export const AccordionHolder = styled.div`
  width: 90%;
  
  border-radius: 30px;
  
  margin: 60px auto;
  padding: 50px 30px;
  

  background-color: ${Colors.white};

  //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  

  min-height: 500px;
  
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 60px 30px;
  }

  @media (max-width: 500px) {
    margin: 30px 0px;
    width: 100%;
    padding: 40px 20px;
  }

`

export const FeatureHolder = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  min-width: 400px;
  display: flex;

  @media (max-width: 800px) {
    min-width: 200px;
  }
  
  
`

export const FeatureTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: auto;
  

`

export const LogoText = styled.div`
  margin-bottom: 20px;
  font-size: 1rem;
  max-width: 250px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${Colors.dark_grey};
  

  ${({ large }) => large && `
    font-size: 1rem;
    color: ${Colors.white};
    padding: 10px 60px;
    border-radius: 30px;
    background: ${Colors.button_purple_gradient};
  `}
`

export const FeatureTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 700;
  max-width: 600px;
  color: ${props => props.color ? props.color : `${Colors.dark_grey}`} ;
  margin-bottom: 10px;
  border-left: ${props => props.left_border && `8px solid ${Colors.dark_grey}`};
  padding-left: ${props => props.left_border && "10px"};
  
  p {
    display: inline-block;
    font-size: 2.5rem;
    color: ${props => props.color ? props.color : `${Colors.light_grey}`} ;
    position: relative;

    &::after {
      content: "";
      border-bottom: 3px solid ${Colors.light_grey};
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    font-size: 2rem;
  }
`

export const FeatureSubtitle = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  max-width: 600px;
  line-height: 1.5;
  border-radius: 10px;
  margin-top: 10px;
  color: ${props => props.color ? props.color : `${Colors.dark_grey}`} ;
  margin-bottom: 40px;
  

  .forgotten-actions {
    font-weight: 700;
    font-size: 2.1rem;
    text-decoration: wavy;
    line-height: 1.6;
    text-decoration-color: ${Colors.blue};
    text-decoration-thickness: 4px;
    border-radius: 10px;
    background-color: ${Colors.blue};
    color: ${Colors.white};
    border-radius: 10px;
    padding: 5px 10px;
    display: inline-block;
  }

  @media (max-width: 800px) {
    font-size: 1.2rem;

    .forgotten-actions {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`

export const FeatureGraphImage = styled.img`
  width: 300px;
  margin-right: 50px;
  object-fit: contain;
  
  @media (max-width: 800px) {
    display: none;
  }
`

export const SplashIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`

export const FeatureBox = styled.div`
    
    display: flex;
    margin-top: 20px;
    min-height: 400px;
    

   
    
    @media (max-width: 500px) {
        margin-top: 20px;
        padding: 10px 20px;
    }
`

export const FeatureList = styled.ul`
  padding: 10px 0px;
  
`

export const StyledIcon = styled(CheckCircleOutline)`
  color: ${Colors.blue};
  margin-right: 20px;
`;

export const FeatureItem = styled.li`
  margin: 5px 0px;
  font-size: 1rem;
  padding-bottom: 10px;
  display: flex;
  font-weight: 400;
  align-items: center;

  @media (max-width: 500px) {
        font-size: 0.9rem;
    }
  
`

export const ActionContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    justify-content: center;
    min-height: 300px;
    background-color: ${Colors.light_grey};
`

export const ActionHolder = styled.div`
    display: flex;
    width: 80%;
    justify-content: space-around;
    flex-direction: column;
`

export const ActionSignup = styled.div`
    background-color: ${Colors.white};
    border-radius: 10px;
    margin-right: 10px;
    padding: 20px 30px;
    flex: 1;
    align-self: center;
    max-width: 400px;
    cursor: pointer;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    color: ${Colors.blue};
    font-weight: 700;
    font-size: 1rem;
    border: 2px solid ${Colors.blue};
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    &:hover {
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        background-color: ${Colors.card_background};
    }
`

export const ActionLogin = styled.div`
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    color: ${Colors.dark_grey};
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
`

export const UseCaseHolder = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
    align-items: center;
    padding: 30px 0px;
    background: linear-gradient(to right, #f5f5f5, #f8f8f8, #fafafa);
    
`

export const UseCaseContainer = styled.div`
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 10px;
    
`

export const UseCaseTitle = styled.div`
    margin-bottom: 20px;
    display: flex;
    width: 90%;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    
`

export const UseCaseTitleText = styled.div`
    font-size: 3rem;

    max-width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 1.1px;
    margin-right: 20px;
    
    color: ${Colors.dark_grey};
    font-weight: 700;

    p {
      display: block;
      font-size: 5rem;
      margin: 0px 10px;
      color: ${Colors.blue};

    }

    @media (max-width: 800px) {
      font-size: 2rem;
      flex-wrap: wrap;
      p {
        font-size: 3rem;

      }
    }
`;

export const UseCaseItemHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 120px;
    height: 120px;
    
    justify-content: center;
    margin: 10px 20px;
`
export const UseCaseItemIcon = styled.img`
  width: 60px;
  height: 60px;
  background-color: ${Colors.blue};
  padding: 10px;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1) rotate(2deg);
    animation: ${wobble} 0.2s ease-in-out infinite;
  }
`;

export const UseCaseItemText = styled.div`
    margin-top: 15px;
    font-weight: 700;

    width: 100%;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: ${Colors.dark_blue};
    text-align: center;
    font-size: 0.9rem;
`

export const StepsContainer = styled.div`
    width: 100%;
    align-self: center;
    align-items: center;
    box-sizing: border-box !important;
    display: flex;
    background: linear-gradient(to right, #f5f5f5, #f8f8f8, #fafafa);
    border-radius: 10px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;


    
    @media(min-width: 1024px) {
      padding: 80px 0px;
      padding-top: 20px;
    }
`

export const StepsItem = styled.div`
    padding: 10px 20px;
    display: flex;
    margin: 15px 10px;
    flex-direction: column;
    
    min-width: 200px;
    max-width: 200px;
    cursor: ${props => props.assist ? "pointer" : "normal"};
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${Colors.light_grey};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: ${props => props.assist === true && "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;"};
    }

    &::before {
    content: ${({ completed, number }) => completed ? "'\\2713'" : `"${number}"`};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    color: ${Colors.white};
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    height: 50px;
    border-radius: 50%;
    background: ${({ completed }) =>
    completed
      ? `${Colors.button_red_gradient}`
      : `${Colors.button_blue_gradient}`};
    font-size: 1.2rem;
    font-weight: bold;
    animation: ${({ completed }) => completed ? "none" : css`${pulse} 2s linear infinite`};
  }
`


export const StepsText = styled.div`
    font-size: 0.8rem;
    text-align: center;
    display: flex;
    color: ${Colors.dark_grey};
    align-self: center;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 500;
`

export const ReportsContainer = styled.div`
  width: 90%;
  margin-bottom: 40px;
  align-self: center;
  background: linear-gradient(to right, #f5f5f5, #f8f8f8, #fafafa);
  border-radius: 10px;
  display: flex;
  padding: 40px 20px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }

  @media(min-width: 1024px) {
      padding: 80px 20px;
    }
`

export const CardsWrapper = styled.div`
  width: 90%;
  align-self: center;
  border-radius: 10px;
  background: ${Colors.button_orange_gradient};
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding: 20px 30px;
  box-sizing: border-box !important;
  margin: 40px 0px;
  margin-bottom: 50px;

  @media (min-width: 800px) {
    padding: 40px 20px;
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
`;

export const CardsHeading = styled.div`
  font-size: 2.5rem;
  max-width: 600px;
  font-weight: 700;
  color: ${Colors.dark_grey};
  margin-top: 20px;
  margin-bottom: 40px;

  margin-left: 20px;

  @media (max-width: 800px) {
    font-size: 2rem;
  }
`

export const CardItem = styled.div`
  min-width: 300px;
  min-height: 150px;
  margin: 5px 10px;
  max-width: 250px;
  max-height: 120px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: ${Colors.card_background};
  position: relative;
  
  .still-using {
    position: absolute;
    top: 10px;
    display: flex;
    align-items: center;
    right: 10px;
    font-size: 12px;
    font-weight: bold;
    color: ${Colors.dark_grey};
    text-transform: uppercase;
    letter-spacing: 1px;
  }

`;

export const CardImage = styled.img`
  width: 20px;
  margin-left: 10px;
  height: 20px;
  object-fit: contain;
`

export const CardTitleImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  object-fit: contain;
`

export const CardTitle = styled.div`
  font-size: 1rem;
  margin-top: 20px;
  color: ${Colors.dark_grey};
  display: flex;

  align-items: center;
  font-weight: 600;
  margin-bottom: 5px;
`

export const CardSubtitle = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${Colors.dark_grey_highlight};
`

export const ActionsContainer = styled.div`
  width: 90%;
  margin: 40px 0px;
  border-radius: 10px;
  align-self: center;
  position: relative;
  display: flex;
  box-sizing: border-box !important;
  padding: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 400px;
  background: #8E2DE2;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0px;
  }


`

export const HowCanHolder = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box !important;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 60px 30px;
`

export const HowCanCardHolder = styled.div`
  width: 100%;
  margin-top: 60px;
  display: flex;
  max-width: 1024px;
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const HowCanCard = styled.div`
  display: flex;
  padding: 25px 25px;
  background-color: ${Colors.white};
  border-radius: 20px;
  flex-direction: column;
  margin: 10px;
  min-width: 250px;
  max-width: 300px;
  flex: 1;
`

export const HowCanCardTitle = styled.div`
  font-size: 0.9rem;
  color: ${Colors.dark_grey};
  margin-bottom: 5px;
  font-weight: 600;
`

export const HowCanCardText = styled.div`
  font-size: 0.9rem;
  max-width: 300px;
  color: ${Colors.dark_grey_highlight};
`

export const HowCanCardIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  object-fit: contain;
`

export const PricingHolder = styled.div`
  width: 100%;
  display: flex;
  min-height: 90vh;
  box-sizing: border-box !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  margin-top: 30px;
  padding: 20px 10px;
`

export const PricingCardHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
`

export const PricingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  margin: 10px;
  justify-content: space-between;
  min-height: 250px;
  min-width: 300px;
  padding: 30px 15px;
  border-radius: 20px;
  background: ${props => props.background ? props.background : `${Colors.button_blue_gradient}`}
`

export const PricingCardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  
  ${({ middle_item }) => (middle_item && `
    
  `)}

`

export const PricingIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  object-fit: contain;

`

export const PricingTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.white};
`

export const PricingSubTitle = styled.div`
  font-size: 0.9rem;
  color: ${Colors.light_blue_highlight};
  margin-bottom: 10px;
  font-weight: 600;
  
`

export const PricingText = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin-top: 10px;
  max-width: 200px;
  text-align: center;
  color: ${Colors.white};
  margin-bottom: 5px;
`

export const PricingSubText = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  
  color: ${Colors.light_grey_blue};
`

export const PricingButton = styled.div`
  width: 100%;
  background: ${Colors.light_grey_blue};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px 0px;
  margin-top: 30px;
  text-transform: uppercase;
  color: ${Colors.dark_grey_highlight};
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 600;
`



