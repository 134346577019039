import React, { useState } from "react";
import {
    Page,
    Text,
    View,
    Document,
    Image,
    StyleSheet,
    Font,
    Link
} from "@react-pdf/renderer";

import moment from "moment";
import { Colors } from "./styled/styled.container";
import Poppins from "./fonts/Poppins-Regular.ttf"
import PoppinsBold from "./fonts/Poppins-Bold.ttf";

Font.register({
    family:
        'Poppins', fonts: [
            { src: Poppins }, // font-style: normal, font-weight: normal
            { src: PoppinsBold, fontWeight: 'bold' },

        ]
});

function checkIfImage(description) {
    // Get the file extension
    const extension = description.split('.').pop().toLowerCase();

    // Define an array of image extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];

    // Check if the extension is in the array of image extensions
    if (imageExtensions.includes(extension)) {
        return true;
    } else {
        return false;
    }
}

const styles = StyleSheet.create({
    page: { backgroundColor: 'white', fontFamily: "Poppins" },
    container: {
        flexDirection: 'column',
        fontFamily: "Poppins",
        flexWrap: 'wrap'
    },
    headerSection: {
        color: `${Colors.dark_grey}`,
        backgroundColor: `${Colors.card_background}`,
        textAlign: 'center',
        padding: 15
    },
    headerTitle: {
        fontFamily: 'Poppins', // use the Poppins font family
        fontWeight: 'bold', // specify the font weight as bold
        fontSize: 18,
        marginBottom: 2,
        textTransform: 'uppercase'
    },
    headerOrganisation: {
        fontFamily: 'Poppins', // use the Poppins font family
        fontWeight: 'bold', // specify the font weight as bold
        fontSize: 20,
        marginBottom: 8,
        textTransform: 'uppercase'
    },
    headerSubTitle: {
        fontFamily: 'Poppins',
        fontSize: 18,
        marginBottom: 5,
        fontWeight: 'bold',
    },
    divider: {
        marginTop: 5,
        marginBottom: 10,
        borderBottom: "1px solid black"
    },
    sectionAction: {
        fontSize: 12,
        color: `${Colors.dark_grey}`,
        backgroundColor: `${Colors.lighter_grey_tone}`,
        fontWeight: 600,
        marginRight: 5,
    },
    sectionActionStatusComplete: {
        
        fontSize: 12,
        paddingTop: 5,
        borderRadius: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 5,
        marginRight: 5,
        color: `${Colors.green}`,
        backgroundColor: `${Colors.lighter_grey_tone}`
    },
    sectionActionStatusIncomplete: {
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 12,
        color: `${Colors.highlight}`,
        backgroundColor: `${Colors.lighter_grey_tone}`
    },
    sectionActionPriorityLow: {
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 12,
        color: `${Colors.green}`,
        backgroundColor: `${Colors.lighter_grey_tone}`
    },
    sectionActionPriorityMedium: {

        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 12,
        color: `${Colors.orange}`,
        backgroundColor: `${Colors.lighter_grey_tone}`
    },
    sectionActionPriorityHigh: {

        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 12,
        color: `${Colors.highlight}`,
        backgroundColor: `${Colors.lighter_grey_tone}`
    },
    sectionActionDueDate: {

        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 12,
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        color: `${Colors.dark_grey}`,
        backgroundColor: `${Colors.lighter_grey_tone}`
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        margin: 15,
        padding: 20,
        color: `${Colors.dark_grey}`,
        backgroundColor: `${Colors.card_background}`,
        fontSize: 18,
        fontWeight: 500,
        borderRadius: 10,
    },
    headerItemRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 12
    },
    scoreItemRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 10,
        color: `${Colors.dark_grey_highlight}`,
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        fontSize: 13
    },
    headerDivider: {
        marginTop: 10,
        marginBottom: 10,
        borderBottom: `1px dashed ${Colors.dark_grey}`
    },
    sectionDivider: {
        marginTop: 10,
        marginBottom: 5,
        borderBottom: "1px solid black"
    },
    section: {
        padding: 10,
        color: "black",
    },
    sectionTitle: {
        color: `${Colors.dark_grey_highlight}`,
        backgroundColor: `${Colors.card_background}`,
        fontSize: 16,
        fontFamily: 'Poppins',
        textTransform: "uppercase",
        textAlign: "center",
        fontWeight: 'bold',
        borderRadius: 5,
        padding: 10
    },
    sectionItem: {
        backgroundColor: `${Colors.card_background}`,
        padding: 20,
        borderRadius: 10,
        borderBottom: `4px solid ${Colors.blue}`,
        marginTop: 10,
        display: "flex",
        flexDirection: "column",

    },
    sectionItemRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 15,
        fontSize: 13
    },
    sectionItemRowFieldDetail: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        marginBottom: 5,
        fontSize: 13,
        fontWeight: 600
    },
    sectionItemProductCount: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "space-between",
        marginBottom: 10,
        fontSize: 13,
        padding: 5,
        backgroundColor: `${Colors.navbar}`,
        borderRadius: 5,
    },
    sectionItemProductDoubleCount: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
        minHeight: 100,
        fontSize: 13,
        padding: 5,
        backgroundColor: `${Colors.navbar}`,
        borderRadius: 5,
    },

    sectionItemProductHolder: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 10,
        fontSize: 13,
        padding: 5,
        backgroundColor: `${Colors.navbar}`,
        borderRadius: 5,
    },

    sectionItemProductCommentItem: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 10,
        fontSize: 13,

        borderRadius: 5
    },
    columnFlex: {
        flexDirection: "column"
    },

    sectionItemProductCommentName: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        fontSize: 13,
        padding: 5,
        backgroundColor: `${Colors.navbar}`,
        borderRadius: 5
    },

    sectionItemProductCheckboxComment: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: '5px 0px',
        fontSize: 13,
        padding: 5,
        backgroundColor: `${Colors.navbar}`,
    },

    sectionItemProductCommentComment: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 13,

        minWidth: '70px',
        padding: '5px 7px',
        color: `${Colors.dark_grey_highlight}`,
        
        borderRadius: 5,
    },

    sectionItemYes: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 13,
        minWidth: '70px',
        padding: '5px 7px',
        color: `${Colors.green}`,
        borderRadius: 5,
    },

    sectionItemNo: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 13,


        minWidth: '70px',
        padding: '5px 7px',
        color: `${Colors.highlight}`,
        borderRadius: 5,
    },

    sectionItemProductCommentCommentError: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 13,
        padding: 5,
        color: `${Colors.highlight}`,

        borderRadius: 5,
    },

    // checkboxContainer: {
    //     display: "flex",
    //     flexDirection: "row",
    //     flexWrap: "wrap",
    //     marginTop: 5,
    //     width: '100%',
    //     maxHeight: 200, // set a maximum height that works for your use case
    //     overflow: 'hidden' // add this line to hide any items that exceed the maximum height
    // },

    checkboxContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 5,
    },
    checkboxItemWrapper: {
        width: "100%", // or adjust as needed
    },
    checkboxItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
        fontSize: 13,
        padding: 5,
        backgroundColor: `${Colors.navbar}`,
        borderRadius: 5,
        flexWrap: 'wrap'
    },
    checkboxProductName: {
        flex: 1,
        marginRight: 10,
    },


    checkboxList: {
        flexDirection: "row",
        flexWrap: "wrap",
    },


    tick: {

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 13,
        padding: 5,
        width: 80,
        textAlign: 'center',
        fontWeight: 600,
        color: `${Colors.blue}`,

        borderRadius: 5,
    },
    cross: {

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontWeight: 600,
        textAlign: 'center',
        width: 80,
        fontSize: 13,
        padding: 5,
        color: `${Colors.highlight}`,
        borderRadius: 5,
    },

    sectionItemScorePass: {
        color: `${Colors.green}`,
        borderRadius: 10,
        padding: '5px 15px',
        fontWeight: 600,
        marginTop: 10
    },
    sectionItemScoreWarning: {
        color: `${Colors.orange}`,
        borderRadius: 10,
        padding: '5px 15px',
        fontWeight: 600,
        marginTop: 10
    },
    sectionItemScoreFail: {
        color: `${Colors.highlight}`,
        borderRadius: 10,
        padding: '5px 15px',
        fontWeight: 600,
        marginTop: 10
    },

    sectionFiles: {
        marginTop: 5,
        display: "flex",
        flexDirection: "row",
        flexWrap: 'wrap',
        fontSize: 13,
        fontWeight: 600
    },

    sectionFileItem: {
        fontSize: 13,
        margin: 5,
        fontWeight: 500,
        borderRadius: 10,
    },
    sectionFileText: {
        width: 150,
        borderRadius: 5,
        padding: 10,
        height: 150,
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flex: 1,
        overflow: 'hidden',
        backgroundColor: '#e4e4e4',
        fontSize: 12,
    },
    sectionFileComment: {
        fontSize: 13,
        marginTop: 5,
        padding: 3,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: `${Colors.navbar}`,
        color: `${Colors.dark_grey}`,
        textAlign: "center"
    },

    sectionComments: {
        marginTop: 5,
        fontSize: 13,
        fontWeight: 700
    },

    sectionCommentItem: {
        fontSize: 11,
        marginTop: 10,
        fontWeight: 500
    },
    LinkTitle: {
        display: "flex",
        flexGrow: 1,
        height: 50,
        flexDirection: 'row',
        fontSize: 18,
        textAlign: 'center',
        backgroundColor: '#e4e4e4',
        textTransform: 'uppercase',
    },
    GeneratedSection: {
        marginTop: 20,
        paddingTop: 20,
        paddingBottom: 20,
        fontSize: 14,
        textAlign: 'center',
        backgroundColor: '#e4e4e4',
    }
});

export function CreatePDF(props) {

    const { storeID, template, store, visitDate, visitEndDate, manager, region, user, actionsOutstanding, date,
        sections, scorable, score, visitType, visitStatus, visitClosedDate, duration, signature_required, signature_link } = props;
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const handleRender = () => {
        const remainingHeight = document.body.scrollHeight - (currentPage - 1) * 800 - document.body.clientHeight;
        if (remainingHeight < 200) {
            setNumberOfPages(currentPage + 1);
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <Document>
            {Array.from(Array(numberOfPages)).map((_, index) => {
                return (<Page key={index} size="A4">

                    {/* <View style={styles.container}> */}
                    <View style={styles.headerSection}>
                        <Text style={styles.headerOrganisation}>{storeID}</Text>
                        <Text style={styles.headerSubTitle}>{template}</Text>

                    </View>

                    <View style={styles.headerContainer}>
                        <View style={styles.headerItemRow}>
                            <Text>Location</Text>
                            <Text style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{store} ({region})</Text>
                        </View>


                        {manager && manager !== "Not set" && <View>
                            <View style={styles.headerDivider} />
                            <View style={styles.headerItemRow}>
                                <Text>Manager</Text>
                                <Text style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{manager}</Text>
                            </View>
                        </View>}

                        {/* <View style={styles.headerItemRow}>
                    <Text>Manager at visit</Text>
                    <Text>{manager ? manager : "Not Set"}</Text>
                </View> */}
                        

                        
                        {/* <View style={styles.headerDivider} />
                        <View style={styles.headerItemRow}>
                            <Text>Duration</Text>
                            <Text style={{fontWeight: 'bold'}}>{duration}</Text>
                        </View> */}
                        {/* <View style={styles.headerDivider} />
                        <View style={styles.headerItemRow}>
                            <Text>{visitType} Status</Text>
                            <Text style={{fontWeight: 'bold'}}>{visitStatus}</Text>
                        </View> */}

                        {/* <View style={styles.headerDivider} />
                        <View style={styles.headerItemRow}>
                            <Text>{visitType} Closed</Text>
                            <Text style={{fontWeight: 'bold'}}>{visitClosedDate === "" ? "No" : visitClosedDate}</Text>
                        </View> */}

                        {scorable === true &&
                            <View>
                                <View style={styles.headerDivider} />
                                <View style={styles.headerItemRow}>
                                    <Text>Score</Text>
                                    {scorable === true ? <Text style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{score}%</Text> : <Text>Not Scorable</Text>}
                                </View>
                            </View>}

                        {actionsOutstanding > 0 && <View>
                            <View style={styles.headerDivider} />
                            <View style={styles.headerItemRow}>
                                <Text>Actions Outstanding</Text>
                                <Text style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{actionsOutstanding}</Text>
                            </View>
                        </View>}

                        
                        <View style={styles.headerDivider} />
                        <View style={styles.headerItemRow}>
                            <Text>{visitType} Date</Text>
                            <Text style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{visitDate}</Text>
                        </View>

                        <View style={styles.headerDivider} />
                        <View style={styles.headerItemRow}>
                            <Text>{visitType} Completed</Text>
                            <Text style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{visitEndDate}</Text>
                        </View>

                        <View style={styles.headerDivider} />
                        <View style={styles.headerItemRow}>
                            <Text>Completed by</Text>
                            <Text style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{user}</Text>
                        </View>

                    </View>

                    {sections.map((section) => {
                        return (
                            <View key={section.section_id} style={styles.section}>

                                <Text wrap={false} style={styles.sectionTitle}>{section.scorable ? `${section.name} (${section.score}%)` : `${section.name}`}</Text>

                                {section.list.map((item, i) => {

                                    const score = (item.rating * 25);

                                    // 0 = 0% 1 = 25% 2 = 50% 3 = 75% 4 = 100%

                                    return (
                                        <View wrap={item.type === "Item Count" || item.type === "Item Double Count" ? true : false} key={i} style={styles.sectionItem}>

                                            <View wrap={false} style={styles.sectionItemRow} >
                                                <Text>{i + 1}. {item.name}</Text>
                                            </View>

                                            <View wrap={false} style={styles.sectionItemRow}>
                                                {item.type === "Selection" && <Text style={styles.sectionItemProductCommentComment}>{item.selectionOption}</Text>}
                                                {(item.type === "DateTime" || item.type === "Time") && <Text style={styles.sectionItemProductCommentComment}>{item.datetime === "" ? "Not Set" : item.datetime}</Text>}
                                                {item.type === "YesNo" && <Text style={item.yesno === "Yes" ? styles.sectionItemYes : styles.sectionItemNo}>{item.yesno === "Yes" ? "Yes" : "No"}</Text>}
                                                {item.type === "Comment" && <Text style={styles.sectionItemProductCommentComment}>{item.fieldText === "" ? "No Comment" : item.fieldText}</Text>}
                                                {item.type === "Number" && <Text style={styles.sectionItemProductCommentComment}>{item.fieldText === "" ? "Not Set" : item.fieldText}</Text>}
                                            </View>

                                            {item.type === "Item Selection" &&

                                                item.item_list_comment.length === 0 ?
                                                <Text wrap={false} style={styles.sectionItemProductCommentComment}>No items entered.</Text>
                                                :
                                                item.type === "Item Selection" && item.item_list_comment.map((element, listIndex) => {
                                                    return (
                                                        <View wrap={false} key={listIndex} style={styles.sectionItemProductCommentItem}>
                                                            <Text style={styles.sectionItemProductCommentComment}>{listIndex + 1}. {element.product}</Text>
                                                        </View>

                                                    )
                                                })

                                            }

                                            {item.type === "Item Selection Counts" &&

                                                item.item_list_comment.length === 0 ?
                                                <Text wrap={false} style={styles.sectionItemProductCommentComment}>No items entered.</Text>
                                                :
                                                item.type === "Item Selection Counts" && item.item_list_comment.map((element, listIndex) => {
                                                    return (
                                                        <View wrap={false} key={listIndex} style={styles.sectionItemProductCount}>
                                                            <Text style={styles.sectionItemProductCommentName}>{listIndex + 1}. {element.product}</Text>
                                                            <Text style={styles.sectionItemProductCommentComment}>{element.comment}</Text>
                                                        </View>

                                                    )
                                                })

                                            }

                                            {
                                                (item.type === "Item Count" && item.counts.length === 0) ?
                                                    <Text wrap={false} style={styles.sectionItemProductCommentComment}>No counts entered.</Text>
                                                    : (item.type === "Item Count" && item.counts.length > 0) ?
                                                        item.counts.map((element, itemCountIndex) => (
                                                            <View wrap={false} key={itemCountIndex} style={styles.sectionItemProductCount}>
                                                                <Text style={styles.sectionItemProductCommentName}>{itemCountIndex + 1}. {element.product}</Text>
                                                                <Text style={styles.sectionItemProductCommentComment}>{element.count.length === 0 ? "Not Set" : `${element.count[0].label} ${element.count[0].value}`}</Text>
                                                            </View>
                                                        ))
                                                        : (item.type === "Item Double Count" && item.counts.length === 0) ?
                                                            <Text wrap={false} style={styles.sectionItemProductCommentComment}>No counts entered.</Text>
                                                            : (item.type === "Item Double Count" && item.counts.length > 0) ?

                                                                item.counts.map((element, itemDoubleCountIndex) => (
                                                                    <View wrap={false} key={itemDoubleCountIndex} style={styles.sectionItemProductDoubleCount}>
                                                                        <Text style={styles.sectionItemProductCommentName}>{itemDoubleCountIndex + 1}. {element.product}</Text>
                                                                        <View key={itemDoubleCountIndex}>
                                                                            <Text style={[styles.sectionItemProductCommentComment, { marginBottom: '5px' }]}>{element.count.length === 0 ? "Not Set" : `${element.count[0].label} ${element.count[0].value}`}</Text>
                                                                            <Text style={styles.sectionItemProductCommentComment}>{element.count.length === 0 ? "Not Set" : `${element.count[1].label} ${element.count[1].value}`}</Text>
                                                                        </View>
                                                                    </View>
                                                                )) : null



                                            }

                                            {item.type === "Item List" &&

                                                item.item_list_comment.length === 0 ?
                                                <Text wrap={false} style={styles.sectionItemProductCommentComment}>No items entered.</Text>
                                                :
                                                item.type === "Item List" && item.item_list_comment.map((listElement, itemListIndex) => {
                                                    return (
                                                        <View wrap={false} key={itemListIndex} style={styles.sectionItemProductCommentItem}>
                                                            <Text style={styles.sectionItemProductCommentComment}>{itemListIndex + 1}. {listElement.product}</Text>
                                                        </View>
                                                    )
                                                })
                                            }



                                            {item.type === "List Checkbox" &&

                                                item.item_list_checkbox.length === 0 ?
                                                <Text wrap={false} style={styles.sectionItemProductCommentComment}>No items entered.</Text>
                                                :

                                                item.item_list_checkbox && item.item_list_checkbox.map((element, itemCheckIndex) => {
                                                    return (
                                                        <View wrap={false} key={itemCheckIndex} style={styles.sectionItemProductHolder} >
                                                            <View style={styles.sectionItemProductCount}>

                                                                <Text style={styles.checkboxProductName}>{itemCheckIndex + 1}. {element.product}</Text>
                                                                <Text style={element.checked ? styles.tick : styles.cross}>{element.checked === false ? `X` : `OK`}</Text>
                                                            </View>

                                                            {item.listCheckboxComments && (
                                                                <Text wrap={false} style={styles.sectionItemProductCheckboxComment}>
                                                                    {element.comment.length > 0 ? element.comment : "No comment entered."}
                                                                </Text>
                                                            )}

                                                        </View>


                                                    )
                                                })

                                            }

                                            {item.type === "Item Comment" &&

                                                item.item_list_comment.length === 0 ?
                                                <Text wrap={false} style={styles.sectionItemProductCommentComment}>No items entered.</Text>
                                                :
                                                item.type === "Item Comment" && item.item_list_comment.map((element, itemCommentIndex) => {
                                                    return (
                                                        <View wrap={false} key={itemCommentIndex} style={styles.sectionItemProductCommentItem}>
                                                            <Text style={styles.sectionItemProductCommentName}>{itemCommentIndex + 1}. {element.product}</Text>
                                                            <Text style={styles.sectionItemProductCommentComment}>{element.comment === "" ? "None" : element.comment}</Text>
                                                        </View>

                                                    )
                                                })

                                            }

                                            {item.scorable &&
                                            <View wrap={false} style={styles.sectionItemRowFieldDetail}>
                                                {item.scorable === true ? <Text style={score <= 50 ? styles.sectionItemScoreFail : score < 75 ? styles.sectionItemScoreWarning : styles.sectionItemScorePass}>Score {score}%</Text> : <Text></Text>}
                                            </View>}

                                            {item.actions.length > 0 && <Text style={{marginTop: 10, marginBottom: 5, fontSize: 12, fontFamily: "Poppins", fontWeight: 'bold'}}>Actions</Text>}

                                            {item.actions.map((actionItem, actionIndex) => {
                                                return (
                                                    <View wrap={false} key={actionIndex} style={styles.sectionItemRowFieldDetail}>
                                                        <Text style={styles.sectionAction}>{actionIndex+1}. Action by {actionItem.actionByName}</Text>
                                                        <Text style={styles.sectionActionDueDate}>Due {moment(actionItem.action_due_date).format("DD MMM YY")}</Text>
                                                        <Text style={actionItem.actionComplete ? styles.sectionActionStatusComplete : styles.sectionActionStatusIncomplete}>
                                                            {actionItem.actionComplete ? "Complete" : "Waiting"}
                                                        </Text>
                                                        <Text style={actionItem.action_priority === "Low" ? styles.sectionActionPriorityLow : actionItem.action_priority === "Medium" ? styles.sectionActionPriorityMedium : styles.sectionActionPriorityHigh}>
                                                            ({actionItem.action_priority})
                                                        </Text>
                                                        
                                                    </View>
                                                )
                                            })}

                                            {(item.files.length > 0 || item.newFiles.length > 0) &&
                                                <View style={styles.sectionFiles}>
                                                    {/* <Text>{item.files.length + item.newFiles.length} files added.</Text> */}
                                                    {item.files.map((file, imageIndex) => {
                                                        //console.log("file url", file.url)
                                                        if (checkIfImage(file.description)) {
                                                            return <View wrap={false} key={imageIndex} style={styles.sectionFileItem}>
                                                                <Image style={{ width: 150, height: 150, borderRadius: 5 }} src={file.url} />
                                                                <Text style={styles.sectionFileComment}>{file.comment.length > 0 ? file.comment : "No comment"}</Text>
                                                            </View>
                                                        } else {
                                                            return (<View wrap={false} key={imageIndex} style={styles.sectionFileItem}>
                                                                <Text style={styles.sectionFileText}>{file.description}</Text>
                                                                <Text style={styles.sectionFileComment}>{file.comment.length > 0 ? file.comment : "No comment"}</Text>
                                                            </View>)
                                                        }

                                                    })}
                                                    {item.newFiles.map((newFile, newImageIndex) => {

                                                        //const imageUrl = URL.createObjectURL(newFile.file);


                                                        if (checkIfImage(newFile.file.name)) {
                                                            //console.log("Orientation ", newFile.file || "none")
                                                            return <View wrap={false} key={newImageIndex} style={styles.sectionFileItem}>
                                                                <Image
                                                                    style={{ width: 150, height: 150, borderRadius: 5 }}
                                                                    src={newFile.url}
                                                                    rotate={-90}
                                                                />

                                                                <Text style={styles.sectionFileComment}>{newFile.comment.length > 0 ? newFile.comment : "No comment"}</Text>
                                                            </View>
                                                        } else {
                                                            return (<View wrap={false} key={newImageIndex} style={styles.sectionFileItem}>
                                                                <Text style={styles.sectionFileText}>{newFile.file.name}</Text>
                                                                <Text style={styles.sectionFileComment}>{newFile.comment.length > 0 ? newFile.comment : "No comment"}</Text>
                                                            </View>)
                                                        }

                                                    })}

                                                </View>
                                            }

                                            {item.comments.length > 0 &&
                                                <View style={styles.sectionComments}>
                                                    <Text style={{fontSize: 12, fontFamily: "Poppins", fontWeight: 'bold'}}>Comments</Text>
                                                    {item.comments.map((comment, commentsIndex) => {
                                                        return (
                                                            <Text wrap={false} key={commentsIndex} style={styles.sectionCommentItem}>{comment}</Text>
                                                        )
                                                    })}

                                                </View>}

                                        </View>
                                    )
                                })}
                            </View>)
                    })}

                    {signature_required && signature_link && <View wrap={false} style={styles.GeneratedSection}>
                        <Text>Authorized Signature</Text>
                        <Image style={{ width: 500, height: 200, objectFit: 'contain' }} src={signature_link} />
                    </View>}

                    <View style={styles.GeneratedSection}>
                        <Text>Report generated on {moment().format("DD/MM/YYYY HH:mm")}</Text>
                        <Text>Created with Storecall</Text>

                    </View>
                    {/* </View>  */}

                </Page>)
            }
            )}
        </Document>)

}