import React, { Fragment, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, Button, GroupHeader, GroupItems } from './styled/styled.container'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, where, getDocs, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chart from "react-apexcharts";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment/moment';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFilterContainer } from './styled/styled.templateform';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", hide: true },
  { field: 'store_name', headerName: 'Location', minWidth: 150, flex: 1 },
  { field: 'region', headerName: 'Location Group', minWidth: 150, flex: 1 },
  { field: 'sub_region', headerName: 'Sub Group', minWidth: 150, flex: 1 },
  { field: 'template_name', headerName: 'Template', minWidth: 150, flex: 1 },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 100,
    flex: 1

  },
  {
    field: "section_name",
    headerName: "Section",
    minWidth: 100,
    flex: 1
  },
  {
    field: "score", headerName: "Score",
    minWidth: 100,
    flex: 1

  },
];

function ReportsSectionPerformance(props) {
  const { storeDetails, stores, templates } = props;

  // holds selected stores and template names only
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState('');
  const [selectedSection, setSelectedSection] = useState()
  const [sections, setSections] = useState([]);

  // holds selected store name + cloud id
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [visits, setVisits] = useState([]);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [sectionItems, setSectionItems] = useState([]);
  const [gridItems, setGridItems] = useState([]);
  const [filters, setFilters] = useState(true);
  const [filterActions, setFilterActions] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
  const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
  const navigate = useNavigate();


  useEffect(() => {

    if (visits.length > 0) {

      // sort the list so that points are projected in order
      let visitDates = [...visits].sort((a, b) => {
        if (moment(a.date, "DD-MM-YYYY").isBefore(moment(b.date, "DD-MM-YYYY"))) return -1;
        else if (moment(a.date, "DD-MM-YYYY").isAfter(moment(b.date, "DD-MM-YYYY"))) return 1;
        else return 0;
      }).map(visit => visit.date)

      // remove duplicates from different visits -> templates
      // eg: Store Visit, Regional Visit and PS, Bizana

      // commented out below because want to show all visits even if duplicate days
      //visitDates = [...new Set(visitDates)]

      setSeries([])

      //visitDates = [...new Set(visitDates)]

      const sectionNamesAll = [];
      visits.forEach(visit => {
        visit.sections.forEach(section => {
          if (section.scorable)
            sectionNamesAll.push(section.name)
        })
      })

      // Get only unique section names of Template X
      const sectionNames = [...new Set(sectionNamesAll)]

      setSectionItems(sectionNames);

      // for each store
      selectedStoresInfo.forEach((storeElement, i) => {

        // find the visits related to that store
        const storeVisits = visits.filter(visit => visit.store_id === storeElement.cloud_id);


        // for each section that exists in all visits
        sectionNames.forEach(sectionName => {
          const sectionScores = [];

          // for each visit date in all visits
          visitDates.forEach(visitDate => {

            // get the visits that are related to that date and for that store
            const storeVisitDates = storeVisits.filter(storeVisit => storeVisit.date === visitDate);

            if (storeVisitDates.length > 0) {

              // for each visit that has occurred on that date
              storeVisitDates.forEach(visit => {

                // get the that visit and the section that we are looking at
                const visitSections = visit.sections.filter(section => section.name === sectionName);
                if (visitSections.length === 0) sectionScores.push(0)
                else {
                  // for each section that is called that section
                  visitSections.forEach(sectionElement => {
                    //const visitDateFound = visitDates.filter(x => x === visit.date)[0];
                    //if(visitDateFound)

                    sectionScores.push(sectionElement.score)

                    const gridItem = {
                      cloud_id: visit.cloud_id,
                      id: sectionElement.cloud_id + visit.startTime,
                      store_name: storeElement.name,
                      region: storeElement.region,
                      sub_region: storeElement.sub_region,
                      template_name: selectedTemplates[0].name,
                      date: visit.date,
                      key: visit.key,
                      section_name: sectionElement.name,
                      score: sectionElement.scorable ? `${sectionElement.score}%` : "Not Scorable"
                    }

                    if (!(gridItems.filter(item => item.id === gridItem.id).length > 0))
                      setGridItems(prevState => [...prevState, gridItem])

                  })
                }




                //}                              
              })

            } else sectionScores.push(0)

          })

          const sectionItem = {
            name: storeElement.name + "(" + sectionName + ")",
            data: sectionScores
          }


          setSeries(prevState => [...prevState, sectionItem])




        })



      })

      setOptions({
        chart: {
          id: 'line-chart',
          type: 'line',
        },
        plotOptions: {
          line: {
            horizontal: true,


          }
        },
        title: {
          text: selectedTemplates[0].name + " Section Performance"
        },
        xaxis: {
          categories: visitDates,

        },
        stroke: {
          curve: "smooth",
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetY: 5,
          offsetX: 1
        }
      })
    }

  }, [visits])

  async function getVisits() {

    if (selectedStores.length === 0 || selectedTemplates.length === 0)
      return;

    setVisits([])

    const templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x.name == template.name) >= 0)).map((value) => {
      return value;
    })
    const storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name == store.name) >= 0)).map((value) => {
      return value;
    })

    setSelectedStoresInfo(storeIDList);
    setSelectedTemplatesInfo(templateIDList);
    const dateTo = moment(filterDateTo).add("1", "days").toISOString();



    templateIDList.map(template => {

      storeIDList.forEach(async store => {

        const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"),
          orderBy("date", "asc"),
          where("template_id", "==", template.cloud_id),
          where("store_id", "==", store.cloud_id),
          where("scorable", "==", true),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo));

        const visitDocs = await getDocs(q);
        if (visitDocs.docs.length === 0) {
          setSeries([])
          setOptions({

            title: {
              text: selectedTemplates[0].name + " Section Performance"
            },
            xaxis: {
              categories: [],

            },
          })
        }
        visitDocs.docs.forEach(async visit => {
          const visitID = visit.id;
          const visitData = visit.data();

          const sectionQueryCollectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visit.id + "/sections"), orderBy("key", "asc"));
          const sectionDocs = await getDocs(sectionQueryCollectionRef);
          const sectionData = sectionDocs.docs.map(doc => {
            const docData = doc.data();
            return {

              cloud_id: doc.id,
              name: docData.name,
              scorable: docData.scorable,
              score: docData.score
            }
          })


          const visitElement = {
            ...visitData,
            cloud_id: visit.id,
            date: moment(visitData.date).format("DD-MM-YYYY HH:mm"),
            name: store.name,
            key: visitID,
            sections: sectionData
          }

          setVisits(prevState => [...prevState, visitElement])

        })

      })


    })


  }

  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleStoreChange = (event, values) => {

    //const storeNames = values.map(value => value.name);
    setSelectedStores(values);
  };

  function handleGroupLocationsClick(region) {
    const locationsGroup = stores.filter(store => store.region === region);
    setSelectedStores(locationsGroup);
  }

  const handleTemplateChange = async (event, value) => {
    if (value) {
      setSelectedTemplates([value])
      const visitItems = await getVisits();
      // const sectionNames = [];
      // visitItems.forEach(visit => {
      //   console.log("Visit")
      //   console.log(visit)
      //   visit.sections.forEach(section => {
      //     sectionNames.push(section.name)
      //   })
      // })
      // const uniqueSectionNames = [...new Set(sectionNames)]
      // console.log("Sections found")
      // console.log(uniqueSectionNames);
      // setSections(uniqueSectionNames)

    }
    else {
      setSelectedTemplates([]);
      setSections([])
    }
  };

  const handleRowClick = (params) => {
    navigate("/app/storevisit/report/" + params.row.cloud_id);
  };

  function handleSearchFilter() {
    setGridItems([]);
    getVisits();
  }

  return (
    <Fragment>

      <TemplateFormFieldRow align="right" style={{ margin: '10px 0px' }}>
        <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>
      </TemplateFormFieldRow>

      {filters &&
        <TemplateFilterContainer style={{ marginTop: '5px' }}>
          {stores && stores.length > 0 &&
            <Autocomplete
              multiple
              id="store-tags"
              options={[...stores].sort((a, b) => {


                const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                if (groupSort && groupSort === 0) {
                  return sortRegions(a, b);
                }
                return groupSort;
              })}
              groupBy={groupByRegion}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              value={selectedStores}
              onChange={handleStoreChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a location"
                  placeholder="Add a location"
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>

                  <GroupHeader onClick={() => handleGroupLocationsClick(params.group)}>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />}

          {templates && templates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              id="template-tags"
              options={[...templates].sort((a, b) => {
                const groupA = a.categoryName;
                const groupB = b.categoryName;
                if (groupA === groupB) {
                  return a.name.localeCompare(b.name);
                }
                return groupA.localeCompare(groupB);
              })}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              groupBy={(option) => option.categoryName}
              onChange={handleTemplateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a template"
                  placeholder="Add a template"
                />
              )}
            />}

          <TemplateFormFieldDateContainer>
            <TextField
              sx={{ 'marginRight': '10px' }}
              label="Period from"
              size='small'
              type="date"
              value={filterDateFrom}
              onChange={(e) => setFilterDateFrom(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Period to"
              type="date"
              size='small'
              fullWidth
              value={filterDateTo}
              onChange={(e) => setFilterDateTo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />


          </TemplateFormFieldDateContainer>

          <Button disabled={selectedStores.length === 0 || selectedTemplates.length === 0} highlighted onClick={handleSearchFilter} style={{ margin: '10px 0px' }} fullWidth>Run Report</Button>

        </TemplateFilterContainer>}


      <Chart
        options={options}
        series={series}
        type="line"
        height="400" />

      {visits && visits.length > 0 &&
        <TableContainer>
          <DataGrid
            autoHeight
            sx={{ 'border': '0' }}
            components={{ Toolbar: GridToolbar }}
            rows={gridItems}
            onRowClick={handleRowClick}
            initialState={{
              sorting: {
                sortModel: [{ field: 'visit_date', sort: 'desc' }],
              },
            }}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={7}
            rowsPerPageOptions={[7]}
          />
        </TableContainer>
      }
    </Fragment>
  )
}

export default ReportsSectionPerformance