import React, { useEffect, useState } from 'react'
import { LoginPageContainer, LoginContainer, LoginItemHolder, LoginText, LoginInput, LoginItemContainer, LoginButtonContainer, LoginButton, LoginLink, LoginHeader } from './styled/styled.login'
import { auth, registerAdminWithEmailAndPassword, createStoreDB, logout } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { ShowPasswordIcon, HidePasswordIcon } from './styled/styled.login';
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, setDoc, deleteDoc, addDoc, getDoc, updateDoc } from "firebase/firestore";
import Loader from './Loader';
import { ColumnContainer, Container, ErrorText, HeaderTitle, PageContainer } from './styled/styled.container';
import { TextField, InputAdornment, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LogoImg from "./imgs/logo_name_black.png"
import { LogoNameImg, SplashLogo, TextContainer } from './styled/styled.splash';
import moment from 'moment-timezone';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Register() {

  const [storeID, setStoreID] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const [selectedTimezone, setSelectedTimezone] = useState('');

  useEffect(() => {
    if (loading) {
      setShowLoading(true)
      return;
    }
    if (user) navigate("/app");
  }, [user, loading]);

  useEffect(() => {
    setShowLoading(false);
  }, [error])

  const timezones = moment.tz.names();

  // Handle the change event when the user selects a timezone
  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  const handleClick = () => {
    setOpenBar(true);
  };

  const handleClose = (event, reason) => {

    setOpenBar(false);
    setShowLoading(false)
    if (barMessage.result === "success") navigate("/app")
  };

  function handleShowPassword() {
    setShowPassword(prevState => !prevState);
  }

  function checkFields() {
    if (storeID.length === 0) return false;
    if (name.length === 0) return false;
    if (email.length === 0 || (email.indexOf("@") === -1)) return false;
    if (password.length === 0 || confirmPassword.length === 0) return false;
    if(selectedTimezone === '') return false;

    return true;
  }

  function checkPasswords() {
    if (password !== confirmPassword) return false;
    return true;
  }

  function backToLogin() {
    navigate("/login");
  }

  async function createOrganisation(e) {
    e.preventDefault();

    if (!checkFields()) {
      setBarMessage({
        type: "error",
        message: "Please check that all fields have been filled.",
        status: "error"
      })
      setOpenBar(true)
      return;
    }

    if (!checkPasswords()) {
      setBarMessage({
        type: "error",
        message: "Error - your passwords do not match",
        status: "error"
      })
      setOpenBar(true)
      return;
    }

    setShowLoading(true);

    const trimmedStoreID = storeID.trim().toLowerCase();
    const trimmedEmail = email.trim().toLowerCase();

    // const storeRootRef = doc(db, "stores", trimmedStoreID);

    // // first check if organisation already exists
    // const storeDoc = await getDoc(storeRootRef);

    // if(storeDoc.exists()) {

    //   setBarMessage({
    //     type: "error",
    //     message: "This organisation already exists.",
    //     status: "error"
    //   })
    //   setOpenBar(true)
    //     return;
    // }

    // ADD PAYMENT CHECK HERE
    // ONLY IF PAYMENT RESULT IS OK THEN CREATE ORGANISATION

    try {

      const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/create-organisation', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: trimmedEmail,
          password: password,
          name: name,
          storeid: trimmedStoreID,
          timezone: selectedTimezone
        })
      })

      if (result.status === 200) {
        localStorage.setItem("store", storeID.toLowerCase());
        setBarMessage({
          type: "info",
          message: "Organisation has been created successfully.",
          status: "success"
        })
        setOpenBar(true)

      }
      else if (result.status === 500) {
        setBarMessage({
          type: "error",
          message: "Your admin email address is already in use.",
          status: "error"
        })
        setOpenBar(true)
      }
      else if (result.status === 600) {
        setBarMessage({
          type: "error",
          message: "Error - password entered is too weak."
        })
        setOpenBar(true)
      }
      else if (result.status === 700) {
        setBarMessage({
          type: "error",
          message: "This organisation already exists."
        })
        setOpenBar(true)
      }
      else {
        setBarMessage({
          type: "error",
          message: "Error creating your organisation.",
          status: "error"
        })
        setOpenBar(true)
      }

      setShowLoading(false)
    } catch (error) {
      setBarMessage({
        type: "error",
        message: "There was an error creating this organisation."
      })
      setOpenBar(true)
    }

  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <LoginPageContainer style={{ margin: 'auto' }}>
        <Loader show={showLoading} />
        <Snackbar open={openBar} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
            {barMessage.message}
          </Alert>
        </Snackbar>
        <ColumnContainer style={{ display: 'flex', justifyContent: 'center' }}>
          <LogoNameImg style={{ alignSelf: 'center', 'marginBottom': '10px' }} src={LogoImg} />
          <LoginHeader>Sign up Organisation</LoginHeader>
        </ColumnContainer>


        <LoginContainer onSubmit={createOrganisation}>
          <LoginItemContainer>
            <LoginItemHolder>

              <TextField variant='standard' fullWidth size='small' label='Organisation name' value={storeID} onChange={e => setStoreID(e.target.value)}></TextField>
            </LoginItemHolder>

            <LoginItemHolder>
              <TextField variant='standard' fullWidth size='small' label='Admin username' value={name} onChange={e => setName(e.target.value)}></TextField>
            </LoginItemHolder>
            <LoginItemHolder>
              <TextField variant='standard' fullWidth size='small' label='Admin email' value={email} onChange={e => setEmail(e.target.value)}></TextField>
            </LoginItemHolder>
            <LoginItemHolder>
              <TextField fullWidth variant='standard' label='Admin password' sx={{ flex: 1 }} size='small' type={showPassword ? "text" : "password"}
                value={password} onChange={e => setPassword(e.target.value)}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                }}
              >

              </TextField>
            </LoginItemHolder>
            <LoginItemHolder>
              <TextField variant='standard' fullWidth label='Confirm password' size='small' type={showPassword ? "text" : "password"} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}></TextField>
            </LoginItemHolder>

            <LoginItemHolder style={{marginTop: '10px'}}>
              <FormControl sx={{width: '100%'}}>
                
                <InputLabel sx={{'marginBottom': '10px'}} id="timezone-label">Select Timezone</InputLabel>
                <Select
                  labelId="timezone-label"
                  id="timezone-select"
                  label="Select Timezone"
                  fullWidth
                  value={selectedTimezone}
                  onChange={handleTimezoneChange}
                >
                  {timezones.map((timezone) => (
                    <MenuItem key={timezone} value={timezone}>
                      {timezone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </LoginItemHolder>

            {error && <ErrorText>{error}</ErrorText>}
          </LoginItemContainer>

          <LoginButtonContainer>
            <LoginButton type="submit">Sign Up</LoginButton>
            <LoginLink centered onClick={() => backToLogin()}>Already a member? Click here to login</LoginLink>
          </LoginButtonContainer>

        </LoginContainer>
      </LoginPageContainer>
    </Container>

  )
}

export default Register