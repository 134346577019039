import React, { useState, useEffect } from 'react'
import { Button, Colors, StyledAccountIcon, StyledAddOptionIcon, StyledLoginIcon, StyledRegisterIcon, StyledUpArrowIcon, Text } from './styled/styled.container';
import { HeroContainer, Image, HeroText, HeroTextContainer, ImageContainer, IntroContainer, SplashContainer, TextContainer, TypeWriter, FeatureContainer, FeatureHolder, FeatureTitle, FeatureSubtitle, FeatureBox, FeatureList, FeatureItem, ActionContainer, ActionHolder, ActionSignup, ActionLogin, SplashNavContainer, SplashNavItem, FeatureTextHolder, FeatureGraphImage, ButtonContainer, IntroLeftContainer, IntroRightContainer, SplashIcon, UseCaseContainer, UseCaseItemHolder, UseCaseItemIcon, UseCaseItemText, UseCaseHolder, UseCaseTitle, UseCaseTitleText, StepsContainer, StepsItem, StepsText, ReportsContainer, CardsContainer, CardItem, CardImage, CardTitle, CardSubtitle, CardTitleImg, CardsWrapper, CardsHeading, Graph, ActionsContainer, ImageEffectContainer, SplashLogo, HeroSubText, ImageHeroHolder, ImageHeroContainer, ImageHero, TickContainer, TickItem, TickImage, TickText, FeatureContainerHero, ImageContainerLeft, ImageContainerRight, LogoText, IntroContainerFeatures, FeatureContainerSchedule, IntroLeftContainerSchedule, IntroLeftContainerText, FeatureContainerAudits, IntroRightContainerText, ImageContainerLeftSchedule, FeatureContainerReports, HowCanCardHolder, HowCanCard, HowCanCardIcon, HowCanCardTitle, HowCanCardText, HowCanHolder, IntroHeaderContainer, IntroHeaderLogo, IntroHeaderLogin, IntroHeroLeftContainer, PricingHolder, PricingCardHolder, PricingCard, PricingTitle, PricingSubTitle, PricingText, PricingSubText, PricingButton, PricingCardItem, HeroLogo, HeroLogoImg, CenteredContainer, CenteredText, CenteredImageContainer, CenteredImage, TickNumber, PricingIcon, HeroLogoHeader, WorkflowCard, WorkflowCardNumber, WorkflowCardTitle, WorkflowCardText, WorkflowCardElement, WorkflowCardImage, WorkflowCardElementCentered, AccordionHolder, AccordionWrapper } from './styled/styled.splash'

import DashboardImg from "./imgs/dashboard.png"

import { useNavigate } from 'react-router-dom';
import { auth } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import LaptopImg from "./imgs/laptop.png"
import ActionImg from "./imgs/actions.png"
import ScheduleImg from "./imgs/scheduler_preview.png"
import AuditImg from "./imgs/audit.png"

import RocketImg from "./imgs/rocket.png"

import RetailIcon from "./imgs/retail.png"
import ManufacturingIcon from "./imgs/manufacturing.png"
import LogisticsIcon from "./imgs/logistics.png"
import FoodIcon from "./imgs/food.png"
import HealthIcon from "./imgs/healthcare.png"
import SafetyIcon from "./imgs/safety.png"
import RightArrowImg from "./imgs/right-arrow.png"
import CrossImg from "./imgs/cross.png"
import ComplianceIcon from "./imgs/compliance.png"
import ActionIcon from "./imgs/issue_management.png"
import CollaborationIcon from "./imgs/collaboration.png"
import AnalysisIcon from "./imgs/analysis.png"
import EquipmentIcon from "./imgs/equipment.png"
import HealthSafetyIcon from "./imgs/health.png"

import StarterPackageIcon from "./imgs/starter_icon.png"
import GrowthPackageIcon from "./imgs/growth_icon.png"
import EnterprisePackageIcon from "./imgs/enterprise_icon.png"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@mui/material';
import SplashNav from './components/SplashNav';
import DemoForm from './components/DemoForm';

import SplashTemplatesImage from "./imgs/splash_templates.jpg"
import SplashGroupChatImage from "./imgs/splash_groupchat.jpg"

import Typed from "react-typed";
import 'react-typed/dist/animatedCursor.css';



function AccordionFeatures() {
  return (
    <AccordionWrapper id="FAQ">
      <Typography variant="h3" sx={{ color: `${Colors.dark_grey_highlight}`, textAlign: 'center', fontSize: { sm: "2.5rem", md: "2rem", lg: "3rem" }, fontWeight: 700 }}>Frequently Asked Questions</Typography>
      <AccordionHolder>
        <Accordion sx={{ 'padding': '5px 0px', boxShadow: "none", width: "100%" }} defaultExpanded={false} id="item1">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ 'color': `${Colors.dark_grey_highlight}`, fontSize: { sm: "1.2rem", md: "1.3rem", lg: "1.3rem" } }} variant="h4">What are the main uses of Storecall?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 500, lineHeight: '1.5' }} variant="h5">
              Storecall refines the art of operations management, offering you and your team dynamic operational audits
              with customizable templates. It facilitates efficient action management and systematic scheduling of operational checks.
              Acting as your digital ally, Storecall empowers you to uphold superior oversight and bolster productivity across
              your business landscape.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ 'padding': '5px 0px', boxShadow: "none", width: "100%" }} defaultExpanded={false} id="item2">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ 'color': `${Colors.dark_grey_highlight}`, fontSize: { sm: "1.2rem", md: "1.3rem", lg: "1.3rem" } }} variant="h4">Which industries can benefit from using Storecall?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 500, lineHeight: '1.5' }} variant="h5">
              Storecall is versatile and can benefit a multitude of industries including retail, manufacturing, real estate,
              hospitality, healthcare, and any sector that requires effective operational management. Its tools are crafted to
              adapt to the unique needs of each industry by providing a customizable template solution that can be applied to almost any use case.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ 'padding': '5px 0px', boxShadow: "none", width: "100%" }} defaultExpanded={false} id="item4">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ 'color': `${Colors.dark_grey_highlight}`, fontSize: { sm: "1.2rem", md: "1.3rem", lg: "1.3rem" } }} variant="h4">How does Storecall simplify daily operations?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 500, lineHeight: '1.5' }} variant="h5">
              Storecall streamlines daily operations with a suite of features designed for efficiency.
              Our structured templates lay the groundwork for consistent processes, while our action management system,
              equipped with daily reminders, automates routine tasks. Clear communication channels facilitate teamwork,
              and our analytics provide the insights needed for informed decision-making. This synergy of tools diminishes
              manual labor, freeing managers to concentrate on strategic initiatives.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ 'padding': '5px 0px', boxShadow: "none", width: "100%" }} defaultExpanded={false} id="item5">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ 'color': `${Colors.dark_grey_highlight}`, fontSize: { sm: "1.2rem", md: "1.3rem", lg: "1.3rem" } }} variant="h4">Can you provide examples of templates that can be created?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 500, lineHeight: '1.5' }} variant="h5">
              Certainly! Storecall can assist you with operational templates designed for a wide range of specific industry needs.
              For retail, implement store audit checklists to ensure that visual merchandising, safety protocols, and customer service
              standards are met. In the fast food sector, create templates that include daily sales and waste logs to track performance
              and efficiency. Manufacturing industries can take advantage of preventive maintenance schedules and quality control
              checklists, while hospitality businesses can benefit from room inspection templates and service quality assessments.
              These targeted templates help streamline operations and maintain high standards across all facets of your business.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ 'padding': '5px 0px', boxShadow: "none", width: "100%" }} defaultExpanded={false} id="item3">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ 'color': `${Colors.dark_grey_highlight}`, fontSize: { sm: "1.2rem", md: "1.3rem", lg: "1.3rem" } }} variant="h4">Is Storecall a mobile app?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 500, lineHeight: '1.5' }} variant="h5">
              Storecall is a versatile web application that delivers the same robust functionality you'd expect from a mobile app,
              with the added convenience of universal accessibility. As a web app, it allows you to manage operations from any
              device with a web browser, without the need to download anything or concern yourself with device compatibility or
              updates. Moreover, you can enjoy the latest features of StoreCall instantly, as updates are applied seamlessly and
              universally on the web platform.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ 'padding': '5px 0px', boxShadow: "none", width: "100%" }} defaultExpanded={false} id="item5">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ 'color': `${Colors.dark_grey_highlight}`, fontSize: { sm: "1.2rem", md: "1.3rem", lg: "1.3rem" } }} variant="h4">What support options are available for Storecall to get me going?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 500, lineHeight: '1.5' }} variant="h5">
              We offer comprehensive support to all Storecall users, including a personal and guided tour through the app, phone
              and email support, together with an on-going resource library full of ready made professional templates for your use. Our dedicated team is
              always on standby to assist you.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ 'padding': '5px 0px', boxShadow: "none", width: "100%" }} defaultExpanded={false} id="item5">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ 'color': `${Colors.dark_grey_highlight}`, fontSize: { sm: "1.2rem", md: "1.3rem", lg: "1.3rem" } }} variant="h4">Is there a trial period, and how can I sign up?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontWeight: 500, lineHeight: '1.5' }} variant="h5">
              Yes, we offer a free trial period for 30 days for you to experience the full capabilities of Storecall without any commitment. Simply sign up below, and start optimizing your business operations today.
            </Typography>
          </AccordionDetails>
        </Accordion>

      </AccordionHolder>
    </AccordionWrapper>

  )
}

function Splash() {

  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/app")

  }, [user, loading]);


  const styles = {
    typedStyle: {
      marginTop: '20px',
      color: `${Colors.light_blue_highlight}`,
      fontSize: '3.5rem',
      fontWeight: 500
    }
  }


  function handleScroll(element) {



    const elementToScrollTo = document.getElementById(element);
    elementToScrollTo.scrollIntoView({ behavior: "smooth" });

    if (element === "Login") {
      setShowLogin(true);
      return;
    }
  }

  return (
    <SplashContainer>


      <IntroContainer id="Login">
        <SplashNav scrollTo={handleScroll} />

        <HeroContainer>
          <HeroTextContainer>


            <Typed
              strings={["Optimize", "Grow", "Streamline"]}
              typeSpeed={100}
              loop
              
              backSpeed={40}
              cursorChar="|"
              showCursor={true}
            />

            <HeroText>


              your business with operational audits and inspections.
            </HeroText>
            <HeroSubText>Embrace Storecall for a Smarter, Streamlined, Insight-Driven Business Operation.</HeroSubText>
          </HeroTextContainer>


          <ImageHeroContainer >
            <CenteredImageContainer>
              <CenteredImage src={DashboardImg} />
            </CenteredImageContainer>
            

          </ImageHeroContainer>
        </HeroContainer>



      </IntroContainer>


      <WorkflowCard>
        <WorkflowCardElement>
          <WorkflowCardElementCentered>

            <WorkflowCardTitle>Running a busy operation?</WorkflowCardTitle>
            <WorkflowCardText>Build, customize, and schedule structured workflows using custom templates for almost every use case. Assign actions and attach images for automatic reminders.</WorkflowCardText>
          </WorkflowCardElementCentered>

        </WorkflowCardElement>

        <WorkflowCardElement>
          <WorkflowCardImage src={SplashTemplatesImage} />
        </WorkflowCardElement>

      </WorkflowCard>

      <WorkflowCard reverse>
        <WorkflowCardElement>
          <WorkflowCardImage src={SplashGroupChatImage} />
        </WorkflowCardElement>

        <WorkflowCardElement>
          <WorkflowCardElementCentered>

            <WorkflowCardTitle>It's about having the right tools at your disposal</WorkflowCardTitle>
            <WorkflowCardText>Giving your team the tools that they need for operations will improve your business. Let <b>Storecall</b> do the heavy lifting so you can focus on what matters.</WorkflowCardText>
          </WorkflowCardElementCentered>

        </WorkflowCardElement>



      </WorkflowCard>



      <FeatureContainerHero id="Features">
        <IntroLeftContainerText>
          <FeatureHolder>
            <FeatureTextHolder>


              <FeatureTitle color={`${Colors.dark_grey_highlight}`}>Kickstart your journey towards an optimized operation workflow.</FeatureTitle>
              <FeatureSubtitle color={`${Colors.dark_grey}`}>
                Revolutionize audits, simplify issue management, and elevate compliance to experience seamless workflows, enhanced productivity, and unmatched performance.
              </FeatureSubtitle>

            </FeatureTextHolder>

          </FeatureHolder>

        </IntroLeftContainerText>

        <IntroContainerFeatures>
          <TickContainer>
            <TickItem>
              <TickNumber>1</TickNumber>
              <TickText>Create custom template checklists for location audits, job inspections or any use case you can think of.</TickText>
            </TickItem>
            <TickItem>
              <TickNumber>2</TickNumber>
              <TickText>Use your templates to carry out audits against your locations and assess performance.</TickText>
            </TickItem>
            <TickItem>
              <TickNumber>3</TickNumber>
              <TickText>Assign actions on the field to instantly notify your team members.</TickText>
            </TickItem>
            <TickItem>
              <TickNumber>4</TickNumber>
              <TickText>Group users into teams and keep everyone in the know.</TickText>
            </TickItem>

            <TickItem>
              <TickNumber>5</TickNumber>
              <TickText>Invite 3rd party suppliers to be notified of issues and corrective measures.</TickText>
            </TickItem>
            <TickItem>
              <TickNumber>6</TickNumber>
              <TickText>Schedule team audits in advance for better planning.</TickText>
            </TickItem>
            <TickItem>
              <TickNumber>7</TickNumber>
              <TickText>Access Storecall reports for quick insights into your business performance.</TickText>
            </TickItem>
          </TickContainer>

        </IntroContainerFeatures>

      </FeatureContainerHero>

      <FeatureContainerAudits>

        <IntroLeftContainer>
          <ImageContainerLeft>
            <Image src={AuditImg} />
          </ImageContainerLeft>


        </IntroLeftContainer>
        <IntroRightContainerText>
          <FeatureHolder>
            <FeatureTextHolder>
              <FeatureTitle>Inspect locations on the go. <br />Log issues that need attention.</FeatureTitle>
              <FeatureSubtitle>
                Storecall helps you to design and customize your checklists so that you can capture the critical information that your business needs for a smoother operation.
              </FeatureSubtitle>

            </FeatureTextHolder>

          </FeatureHolder>
        </IntroRightContainerText>

      </FeatureContainerAudits>

      <FeatureContainer backgroundGradient={Colors.button_purple_gradient}>
        <IntroLeftContainerText>

          <FeatureHolder>
            <FeatureTextHolder>
              <FeatureTitle color={Colors.white}>Assign actions for instant notifications and daily reminders.</FeatureTitle>
              <FeatureSubtitle color={`${Colors.light_blue_highlight}`}>
                Make notes, attach images, set priorities and due dates. Storecall will notify your team members instantly.
              </FeatureSubtitle>

            </FeatureTextHolder>

          </FeatureHolder>

        </IntroLeftContainerText>

        <ImageContainerRight>
          <Image src={ActionImg} />
        </ImageContainerRight>


      </FeatureContainer>


      <FeatureContainerSchedule>

        <IntroLeftContainer>
          <ImageContainerLeftSchedule>
            <Image src={ScheduleImg} />
          </ImageContainerLeftSchedule>


        </IntroLeftContainer>
        <IntroRightContainerText>
          <FeatureHolder>
            <FeatureTextHolder>
              <FeatureTitle>Schedule your team's audits in advance.</FeatureTitle>
              <FeatureSubtitle>
                Help your team make better decisions by scheduling audits in advance, viewing upcoming events, and managing their deadlines.
              </FeatureSubtitle>

            </FeatureTextHolder>

          </FeatureHolder>
        </IntroRightContainerText>

      </FeatureContainerSchedule>

      <FeatureContainerReports background={`${Colors.light_blue_highlight}`}>
        <IntroLeftContainerText>
          <FeatureTextHolder>
            <FeatureTitle color={`${Colors.dark_grey}`}>Intelligent analytics at your fingertips.</FeatureTitle>
            <FeatureSubtitle>
              With all the valuable business audits that have been gathered, turn them into meaningful insights with Storecall Reports at the click of a button.
            </FeatureSubtitle>
          </FeatureTextHolder>
        </IntroLeftContainerText>

        <ImageContainerRight>
          <Image rounded src={LaptopImg} />
        </ImageContainerRight>

      </FeatureContainerReports>

      <CardsWrapper>
        <CardsHeading>Why does your business need Storecall?</CardsHeading>
        <CardsContainer>
          <CardItem>

            <div className="still-using"><CardTitleImg src={CrossImg} /> Still using</div>
            <CardTitle>
              Manual Audits
              <CardImage src={RightArrowImg} />
            </CardTitle>
            <CardSubtitle>Poor business performance and difficult to standardize.</CardSubtitle>
          </CardItem>
          <CardItem>

            <div className="still-using"><CardTitleImg src={CrossImg} /> Still using</div>
            <CardTitle>
              Spreadsheets with Emails
              <CardImage src={RightArrowImg} />
            </CardTitle>
            <CardSubtitle>Difficult to keep track of on-going issues.</CardSubtitle>
          </CardItem>
          <CardItem>

            <div className="still-using"><CardTitleImg src={CrossImg} /> Still using</div>
            <CardTitle>
              Chaotic Group Chats
              <CardImage src={RightArrowImg} />
            </CardTitle>
            <CardSubtitle>Lost links between issues and team member accountability.</CardSubtitle>
          </CardItem>
          <CardItem>

            <div className="still-using"><CardTitleImg src={CrossImg} /> Still using</div>
            <CardTitle>
              Emailed Actions
              <CardImage src={RightArrowImg} />
            </CardTitle>
            <CardSubtitle>Busy inboxes lead to missed emails and forgotten actions.</CardSubtitle>
          </CardItem>

        </CardsContainer>
      </CardsWrapper>


      <UseCaseHolder>
        <LogoText large={true}>Storecall</LogoText>

        <UseCaseContainer>
          <UseCaseItemHolder>
            <UseCaseItemIcon src={RetailIcon} />
            <UseCaseItemText>Retail</UseCaseItemText>
          </UseCaseItemHolder>

          <UseCaseItemHolder>
            <UseCaseItemIcon src={ManufacturingIcon} />
            <UseCaseItemText>Production</UseCaseItemText>
          </UseCaseItemHolder>

          <UseCaseItemHolder>
            <UseCaseItemIcon src={LogisticsIcon} />
            <UseCaseItemText>Logistics</UseCaseItemText>
          </UseCaseItemHolder>

          <UseCaseItemHolder>
            <UseCaseItemIcon src={FoodIcon} />
            <UseCaseItemText>Food</UseCaseItemText>
          </UseCaseItemHolder>

          <UseCaseItemHolder>
            <UseCaseItemIcon src={HealthIcon} />
            <UseCaseItemText>Healthcare</UseCaseItemText>
          </UseCaseItemHolder>

          <UseCaseItemHolder>
            <UseCaseItemIcon src={SafetyIcon} />
            <UseCaseItemText>Compliance</UseCaseItemText>
          </UseCaseItemHolder>

        </UseCaseContainer>
      </UseCaseHolder>

      <StepsContainer>
        <StepsItem number="1">
          <StepsText>Design your custom audit templates</StepsText>
        </StepsItem>
        <StepsItem number="2">
          <StepsText>Create locations and user groups</StepsText>
        </StepsItem>
        <StepsItem number="3">
          <StepsText>Conduct digital audits and log issues</StepsText>
        </StepsItem>
        <StepsItem number="4">
          <StepsText>Add actions for your team members</StepsText>
        </StepsItem>
        <StepsItem number="5">
          <StepsText>Track performance and recurring issues</StepsText>
        </StepsItem>
        <StepsItem number="6">
          <StepsText>Watch business performance improve</StepsText>
        </StepsItem>
      </StepsContainer>

      <PricingHolder id="Pricing">
        <LogoText large={true}>Storecall</LogoText>

        <UseCaseTitleText>Flexible plans <br />for all types of teams</UseCaseTitleText>
        <PricingCardHolder>
          <PricingCard background={`${Colors.subtle_blue_gradient}`}>
            <PricingCardItem>
              <PricingIcon src={StarterPackageIcon} />
              <PricingTitle>Starter Plan</PricingTitle>
              <PricingSubTitle>From 1 - 6 users</PricingSubTitle>
            </PricingCardItem>
            <PricingCardItem middle_item={true}>
              <PricingText>R400</PricingText>
              <PricingSubText>Per user / per month</PricingSubText>
            </PricingCardItem>

            <PricingButton onClick={() => handleScroll("Free Demo")}>Get started</PricingButton>
          </PricingCard>
          <PricingCard background={`${Colors.subtle_purple_gradient}`}>
            <PricingCardItem>
              <PricingIcon src={GrowthPackageIcon} />
              <PricingTitle>Growth</PricingTitle>
              <PricingSubTitle>7 - 12 users</PricingSubTitle>
            </PricingCardItem>
            <PricingCardItem middle_item={true}>
              <PricingText>R320</PricingText>
              <PricingSubText>Per user / per month</PricingSubText>
              <PricingSubText>First 6 users billed at starter package</PricingSubText>
            </PricingCardItem>

            <PricingButton onClick={() => handleScroll("Free Demo")}>Get started</PricingButton>
          </PricingCard>
          <PricingCard background={`${Colors.subtle_red_gradient}`}>
            <PricingCardItem>
              <PricingIcon src={EnterprisePackageIcon} />
              <PricingTitle>Enterprise</PricingTitle>
              <PricingSubTitle>13+ users</PricingSubTitle>
            </PricingCardItem>
            <PricingCardItem middle_item={true}>
              <PricingText>Contact Us Today</PricingText>
              <PricingSubText></PricingSubText>
            </PricingCardItem>

            <PricingButton onClick={() => handleScroll("Free Demo")}>Contact Us</PricingButton>
          </PricingCard>
        </PricingCardHolder>

      </PricingHolder>

      <HowCanHolder>
        <UseCaseTitleText>Built for any workflow and the everyday user</UseCaseTitleText>
        <HowCanCardHolder>
          <HowCanCard>
            <HowCanCardIcon src={ComplianceIcon} />
            <HowCanCardTitle>Location Compliance</HowCanCardTitle>
            <HowCanCardText>Ensure standardized compliance across locations with Storecall's measurement tool.</HowCanCardText>
          </HowCanCard>

          <HowCanCard>
            <HowCanCardIcon src={ActionIcon} />
            <HowCanCardTitle>Issue Management</HowCanCardTitle>
            <HowCanCardText>Efficiently report and assign actions for issue resolution using Storecall's logging system.</HowCanCardText>
          </HowCanCard>

          <HowCanCard>
            <HowCanCardIcon src={CollaborationIcon} />
            <HowCanCardTitle>3rd Party Collaboration</HowCanCardTitle>
            <HowCanCardText>Connect and communicate requirements with 3rd party suppliers seamlessly through Storecall.</HowCanCardText>
          </HowCanCard>

          <HowCanCard>
            <HowCanCardIcon src={AnalysisIcon} />
            <HowCanCardTitle>Promotion Analysis</HowCanCardTitle>
            <HowCanCardText>Conduct daily feedback on in-house promotions and execution for improved analysis.</HowCanCardText>
          </HowCanCard>

          <HowCanCard>
            <HowCanCardIcon src={EquipmentIcon} />
            <HowCanCardTitle>Security and Equipment</HowCanCardTitle>
            <HowCanCardText>Promptly request assistance for critical security and equipment issues with Storecall.</HowCanCardText>
          </HowCanCard>

          <HowCanCard>
            <HowCanCardIcon src={HealthSafetyIcon} />
            <HowCanCardTitle>Health & Safety</HowCanCardTitle>
            <HowCanCardText>Streamline health and safety reporting to maintain standards across locations.</HowCanCardText>
          </HowCanCard>


        </HowCanCardHolder>
      </HowCanHolder>

      <AccordionFeatures />


      <ActionsContainer id="Free Demo">
        <IntroLeftContainer>
          <FeatureHolder>
            <FeatureTextHolder>
              <LogoText large={true}>Storecall</LogoText>
              <FeatureTitle color="white">Interested to know more?</FeatureTitle>
              <FeatureSubtitle color={`${Colors.lighter_grey_tone}`}>Sign up for a free 30 day trial, and see for yourself how Storecall can help your business improve its workflows and performance.</FeatureSubtitle>

              <DemoForm />

            </FeatureTextHolder>

          </FeatureHolder>

        </IntroLeftContainer>
        <IntroRightContainer>
          <ImageEffectContainer effect={true}>
            <Image src={RocketImg} />
          </ImageEffectContainer>
        </IntroRightContainer>
      </ActionsContainer>

    </SplashContainer>
  )
}

export default Splash