import React, { useState, useEffect, useContext } from 'react'
import { CardContainer, Button, Colors, StyledUserIcon } from './styled/styled.container'
import { SettingsItemHolder, SettingsText } from './styled/styled.settings'
import { doc, query, setDoc, updateDoc, collection, getDocs, where } from 'firebase/firestore';
import { db, logout } from "./firebase";
import { TableContainer } from './styled/styled.container';
import { DataGrid } from '@mui/x-data-grid';
import { TemplateFormButtonContainer, TemplateFormFieldRow } from './styled/styled.templateform';
import { SettingsButton } from './styled/styled.settings';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import AuthContext from './AuthContext';
import { TemplateFormButtonStack } from './styled/styled.templateform';

function SettingsManageUsers(props) {

  const { storeDetails } = useContext(AuthContext)
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [showLoading, setShowLoading] = useState(false)
  const [pageSize, setPageSize] = useState(25); //[10, 25, 50
  const navigate = useNavigate();

  useEffect(() => {
    setUsers([]);

    async function getUsers() {

      if (storeDetails.user.type !== "admin") navigate("/app/404");
      else {
        setShowLoading(true)
        await fetchUsers();
        setShowLoading(false)
      }
    }

    if (storeDetails.rootID.length > 0) getUsers();
  }, [storeDetails])

  const columns = [
    { field: "uid", headerName: "uid", hide: true },
    { field: 'name', headerName: 'Name', minWidth: 100, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 170, flex: 1 },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 120,
      flex: 1
    },
    {
      field: "group",
      headerName: "User Group",
      minWidth: 120,
      flex: 1
    }
  ];

  const fetchUsers = async () => {
    try {

      setUsers([]) //

      const q = storeDetails.user.type === "admin" ?
        query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("active", "==", true)) :
        query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", storeDetails.user.uid), where("active", "==", true));
      //const q = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", storeDetails.user.uid));
      const doc = await getDocs(q);

      doc.docs.forEach(element => {
        const userDetails = element.data();

        const groupName = storeDetails.user_groups.find(group => group.id === userDetails.group).name;


        setUsers(prevState => ([...prevState, {
          ...userDetails,
          type: userDetails.type,
          group: groupName
        }]))
      });

    } catch (err) {
      console.error(err);
    }
  };

  const handleRowClick = (params) => {

    setSelectedUser(params.row);
    navigate("/app/settings/details/" + params.row.uid);
  };

  function handleNewUser() {
    navigate("/app/settings/details/new")
  }

  function handleUserGroups() {
    navigate("/app/settings/usergroups");
  }

  return (
    <>
      <CardContainer>
        <Loader show={showLoading} />
        <TableContainer style={{ 'marginBottom': '0px' }}>
          {users &&
            <DataGrid
              rows={users}
              sx={{ 'border': '0' }}
              autoHeight
              columns={columns}
              onRowClick={handleRowClick}
              sortModel={[
                {
                  field: 'name',
                  sort: 'asc',
                },
              
              ]}
              getRowId={(row) => row.uid}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}

            />}
        </TableContainer>


      </CardContainer>
      <TemplateFormFieldRow style={{ marginTop: '10px' }}>
        {storeDetails.user.admin &&
          <TemplateFormButtonStack align="right">

            <Button color={Colors.highlight} onClick={handleUserGroups}><StyledUserIcon />User Groups</Button>

            <Button color={Colors.blue} onClick={() => handleNewUser()}><StyledUserIcon />Add User</Button>


          </TemplateFormButtonStack>




        }
      </TemplateFormFieldRow>
    </>

  )
}

export default SettingsManageUsers