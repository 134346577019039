import styled from "styled-components";
import { Colors } from "./styled.container";

export const GroupTitle = styled.div`
    font-size: 1rem;
    font-weight: 500;
    margin: 10px 15px;
`

export const PermissionType = styled.div`
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    background-color: ${Colors.blue};
    padding: 10px;
    color: ${Colors.white};
    border-radius: 5px;
    text-transform: uppercase;
`