import React, { Fragment, useEffect, useState } from 'react'
import { LoginPageContainer, LoginContainer, LoginItemHolder, LoginText, LoginInput, LoginItemContainer, LoginButtonContainer, LoginButton, LoginLink, LoginHeader, ButtonTrial } from './styled/styled.login'
import { auth, logInWithEmailAndPassword} from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { Button, Container, ErrorText, HeaderTitle, PageContainer, StyledAccountIcon, StyledRegisterIcon, StyledTextField } from './styled/styled.container';
import { ShowPasswordIcon, HidePasswordIcon } from './styled/styled.login';
import Loader from './Loader';
import {doc, getDoc} from "firebase/firestore";
import { db } from "./firebase";
import { TextField, InputAdornment, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Colors } from './styled/styled.container';
import { ButtonContainer } from './styled/styled.splash';
import LogoImg from "./imgs/logo_name_black.png"
import { LogoNameImg, SplashLogo, TextContainer } from './styled/styled.splash';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {

  const [storeID, setStoreID] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const navigate = useNavigate();

  // useEffect(() => {
    
  //   const stored_store = localStorage.getItem("store");
  //   if (storeID) setStoreID(stored_store);

  // }, [])

  useEffect(() => {
    if (loading) {
      setShowLoading(true)
      return;
    }
    if (user) navigate("/app");
  }, [user, loading]);

  useEffect(() => {
    setShowLoading(false);
  }, [error])

  const handleClick = () => {
    setOpenBar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowLoading(false)
    setOpenBar(false);
  };

  function handleShowPassword() {
    setShowPassword(prevState => !prevState);
  }

  function handleForgotPassword() {
    navigate("/resetpassword")
  }

  function checkFields() {
    //if (storeID.length === 0) return false;
    if (email.length === 0 || (email.indexOf("@") === -1)) return false;
    if (password.length === 0) return false;

    return true;
  }

  async function handleLogin(e) {
    e.preventDefault();

    if (!checkFields()) {
      setError("Please fill in all fields before continuing.")
      return;
    }

    setShowLoading(true);

    //const trimmedStoreID = storeID //storeID.trim().toLowerCase();
    const trimmedEmail = email.trim().toLowerCase();

    //const storeRootRef = doc(db, "stores", trimmedStoreID);

    // first check if organisation already exists
    // const storeDoc = await getDoc(storeRootRef);
    // if (!storeDoc.exists()) {
    //   setBarMessage({
    //     type: "error",
    //     message: "Error - incorrect account details entered."
    //   })
    //   setOpenBar(true)
    //   return;
    // }

    const status = await logInWithEmailAndPassword(trimmedEmail, password)
    if(status) {
        if(status === "Firebase: Error (auth/wrong-password).")
        {
          setBarMessage({
            type: "error",
            message: "Error - incorrect account details entered."
          })
          setOpenBar(true)
        } else if(status === "Firebase: Error (auth/network-request-failed).")
        {
          setBarMessage({
            type: "error",
            message: "It appears you have no internet connectivity."
          })
          setOpenBar(true)
        }

        else setError(status)

        setShowLoading(false);
    }
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function sendEmail() {
    const email = 'imimoosa@gmail.com';
    const subject = 'Storecall Interest';
    const body = 'Hey there, I am interested in a free trial for my organisation.';
  
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    window.open(mailtoUrl);
  }

  return (
    <Container style={{justifyContent: 'center'}}>
      <LoginPageContainer>
      <Loader show={showLoading} />
      <Snackbar open={openBar} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
          {barMessage.message}
        </Alert>
        </Snackbar>

        <LogoNameImg style={{'marginBottom': '20px'}} src={LogoImg} />
      <LoginContainer onSubmit={handleLogin}>
        <LoginItemContainer>

          <LoginItemHolder>
            <StyledTextField fullWidth variant='outlined' label='Email' sx={{flex: 1}} size='small' value={email} onChange={e => setEmail(e.target.value)}></StyledTextField>
          </LoginItemHolder>
          <LoginItemHolder>

            <StyledTextField fullWidth variant='outlined' label='Password' sx={{flex: 1}} size='small' type={showPassword ? "text" : "password"} 
            value={password} onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
            >
            
            </StyledTextField>
            
          </LoginItemHolder>
          {error && <ErrorText>{error}</ErrorText>}
        </LoginItemContainer>

        <LoginButtonContainer>
          
          <Button type="submit" color={Colors.blue}><StyledAccountIcon/> Login</Button>
          <LoginLink bold centered onClick={() => handleForgotPassword()}>Forgot your password?</LoginLink>
          {/* <LoginLink bold centered onClick={() => navigate("/register")}>Sign up</LoginLink> */}
        </LoginButtonContainer>

      </LoginContainer>
            
      

      </LoginPageContainer>
    </Container>
    
  )
}

export default Login