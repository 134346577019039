import styled from "styled-components";

import { Link, NavLink } from "react-router-dom";
import { IoIosAddCircle, IoMdTrash, IoIosCopy } from "react-icons/io"
import { MdOutlineDeleteSweep, MdClose } from "react-icons/md"
import { IoMdArrowRoundBack } from "react-icons/io";
import ClearIcon from '@mui/icons-material/Clear';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircle from "@mui/icons-material/AddCircle";
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import AutoAwesomeMosaicRoundedIcon from '@mui/icons-material/AutoAwesomeMosaicRounded';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LoginIcon from '@mui/icons-material/Login';
import RecommendIcon from '@mui/icons-material/Recommend';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NfcIcon from '@mui/icons-material/Nfc';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import HomeIcon from '@mui/icons-material/Home';
import LockResetIcon from '@mui/icons-material/LockReset';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import StoreIcon from '@mui/icons-material/Store';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import ReportIcon from '@mui/icons-material/Report';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DescriptionIcon from '@mui/icons-material/Description';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from "@mui/icons-material/Filter"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import CampaignIcon from '@mui/icons-material/Campaign';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { TextField } from "@mui/material";


export const Colors = {
    blue: "#6356e5",
    blue_highlight: "#3e5be5",
    dark_blue: "#263746",
    light_blue_highlight: "#e3eaf5",
    light_grey: "#f8f9fa",
    light_grey_blue: "#e6ecf2",
    grey_shadow: "#f3f6fb",
    lighter_grey_tone: "#f5f5f5",
    logo_offwhite: "#f3f6f6",
    dark_grey: "#212529",
    dark_grey_highlight: "#31373d",
    white: "white",
    orange: "#FE9000",
    green: "#26C485",
    subtitle: "#f72585",
    transparent: "transparent",
    card_background: "#f8f9fa",
    highlight: "#f60975",
    highlight_highlight: "#f72585",
    disabled: "#ced4da",
    navbar: "#EBEFF2",
    blue_gradient: "linear-gradient(to right, #5433ff, #20bdff, #5433ff)",
    hero_blue_gradient: "linear-gradient(to right, #6356e5, #5433ff)",
    button_blue_gradient: "linear-gradient(45deg,#4099ff,#73b4ff)",
    button_green_gradient: "linear-gradient(45deg,#2ed8b6,#59e0c5)",
    button_orange_gradient: "linear-gradient(45deg,#FFB64D,#ffcb80)",
    button_red_gradient: "linear-gradient(45deg,#FF5370,#ff869a)",
    button_purple_gradient: "linear-gradient(to right, #4A00E0, #8E2DE2)",
    header_gradient: "linear-gradient(to right, #5433ff, #595cff, #5433ff)",
    white_blue_gradient: "linear-gradient(to right, #e4eaf1, #a0bbd0)",
    dark_grey_gradient: "linear-gradient(to right, #16222a, #3a6073)",
    light_grey_gradient: "linear-gradient(to right, #8e9eab, #eef2f3)",
    blue_purple_gradient: "linear-gradient( 135deg, #43CBFF 10%, #9708CC 100%);",
    scheduler_gradient_missed: "radial-gradient( circle 860px at 11.8% 33.5%,  rgba(240,30,92,1) 0%, rgba(244,49,74,1) 30.5%, rgba(249,75,37,1) 56.1%, rgba(250,88,19,1) 75.6%, rgba(253,102,2,1) 100.2% )",
    scheduler_gradient_waiting: "radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,145,83,1) 0%, rgba(255,145,83,1) 90% )",
    scheduler_gradient_done: "linear-gradient(109.6deg, rgb(102, 203, 149) 11.2%, rgb(39, 210, 175) 98.7%)",
    subtle_blue_gradient: "radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%);",
    subtle_purple_gradient: "linear-gradient(109.6deg, rgba(119, 44, 232, 0.68) 11.5%, rgb(119, 44, 232) 91.2%)",
    subtle_red_gradient: "linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43));",
    // background-image: radial-gradient( circle 420px at -10.9% -25.7%,  rgba(223,5,5,1) 0%, rgba(235,244,60,1) 90.5% );
    //background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% );

    blue_100: "#edf2fb"

}

export const TemplateTypes = [
    "Audit",
    "Log"
]

export const VisitStatuses = [
    "All",
    "Open",
    "Closed"
]

// used to map the different field types available
export const FieldTypes = [
    "None",
    "YesNo",
    "Comment",
    "Image",
    "Selection",
    "DateTime",
    "Time",
    "Number",
    "Item List",
    "Item Count",
    "Item Double Count",
    "Item Comment",
    "Item Selection",
    "Item Selection Counts",
    "List Checkbox",
]

// used to check if an element type = field type
export const FieldTypeObjects = {
    "None": "None",
    "YesNo": "YesNo",
    "Image": "Image",
    "Comment": "Comment",
    "Selection": "Selection",
    "DateTime": "DateTime",
    "Time": "Time",
    "Number": "Number",
    "Item List": "Item List",
    "Item Count": "Item Count",
    "Item Double Count": "Item Double Count",
    "Item Comment": "Item Comment",
    "Item Selection": "Item Selection",
    "Item Selection Counts": "Item Selection Counts",
    "List Checkbox": "List Checkbox"
}

export const AnswerTypes = [
    "Yes",
    "No"
]

export const UserTypes = [
    "admin",
    "user",
    "manager"
]



export const StyledAddIcon = styled(ControlPointRoundedIcon)`
    cursor: pointer;
    display: flex;

    transition: all 0.3s ease-in-out;


`

export const StyledEditIcon = styled(EditIcon)`
    cursor: pointer;
    
`

export const StyledDownloadIcon = styled(CloudDownloadIcon)`
    color: ${Colors.light_grey};
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.lighter_grey_tone};
    }
    
`

export const StyledShareIcon = styled(ShareIcon)`
    
    
`

export const StyledExportIcon = styled(InsertDriveFileRoundedIcon)`
    color: ${Colors.light_grey};
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.lighter_grey_tone};
    }
    
`

export const StyledSearchIcon = styled(SearchIcon)`
    
`

export const StyledFilterListIcon = styled(FilterListIcon)`
    display: flex;
    margin-right: 10px;
    
`

export const StyledAddOptionIcon = styled(AddCircle)`
    cursor: pointer;
    
    display: flex;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
`

export const StyledAlertRoundedIcon = styled(CampaignIcon)`
    cursor: pointer;

    display: flex;

    margin-right: 10px;
    justify-content: center;
    align-items: center;
`

export const StyledAddValueIcon = styled(AddCircleIcon)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.blue};
    }
`

export const StyledMinusValueIcon = styled(RemoveCircleIcon)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.blue};
    }
`


export const StyledFilterIcon = styled(FilterAltIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledRegisterIcon = styled(HowToRegIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledLoginIcon = styled(LoginIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledDescriptionIcon = styled(DescriptionIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    padding: 2px;

    border-radius: 50%;
    justify-content: center;
    align-items: center;
`

export const StyledTemplateIcon = styled(DynamicFormIcon)`
    cursor: pointer;
    margin-right: 10px;

    border-radius: 50%;
    padding: 1px;
    display: flex;
    
    justify-content: center;
    align-items: center;
`

export const StyledStoreIcon = styled(StoreIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledAlertIcon = styled(ReportIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;

    justify-content: center;
    align-items: center;
`

export const StyledScheduleIcon = styled(ScheduleIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledRegionIcon = styled(SouthAmericaIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledHomeIcon = styled(HomeIcon)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledPasswordIcon = styled(LockResetIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledUserIcon = styled(SupervisedUserCircleIcon)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledAccountIcon = styled(AccountCircleIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledStatusIcon = styled(NfcIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledTickIcon = styled(CheckCircleIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledActionIcon = styled(ElectricBoltIcon)`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledNewIcon = styled(ViewComfyIcon)`
    cursor: pointer;
    color: ${Colors.white};
    display: flex;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
`

export const StyledUpArrowIcon = styled(ArrowUpwardIcon)`
    cursor: pointer;
    display: flex;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }

`

export const StyledDownArrowIcon = styled(ArrowDownwardIcon)`
    cursor: pointer;
    display: flex;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }

`

export const StyledClearIcon = styled(ClearIcon)`
    cursor: pointer;
    display: flex;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: ${Colors.highlight};
    }
`



export const StyledUpdateIcon = styled(AutoAwesomeMosaicRoundedIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledDeleteIcon = styled(DeleteSweepRoundedIcon)`
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

`

export const StyledBackIcon = styled(IoMdArrowRoundBack)`
    cursor: pointer;
    display: flex;
    transition: all 0.4s ease-in-out;
    &:hover {
        transform: scale(0.8);
    }
`

export const StyledClosedIcon = styled(MdClose)`
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: ${Colors.highlight};
    }
`

export const StyledCopyIcon = styled(FileCopyRoundedIcon)`
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;


`

export const MainWrapper = styled.div`
    display: flex;
    
    flex-direction: column;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: black;

    flex: 1;
    min-height: 100vh;
    padding-bottom: 10px;
    background-color: ${Colors.light_grey};
`

export const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
`

export const FileUploadButton = styled.button`
    border: 0;
    //background-color: ${Colors.blue};
    background-color: ${Colors.light_grey};
    color: ${Colors.dark_grey};
    padding: 5px 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    

    font-size: 0.8rem;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`

export const ActionNewButton = styled.button`
    //background-color: ${Colors.blue};
    background-color: ${Colors.highlight};
    color: ${Colors.white};
    border: 0;
    outline: 0;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    

    font-size: 0.7rem;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`

export const AddOptionButton = styled.button`
    border: 0;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.blue}`}; 
    
    width: ${props => props.fullWidth ? "100%" : "fit-content"};
    display: flex;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    font-size: 0.8rem;
    min-width: 200px;
    font-weight: 500;
    cursor: pointer;
    color: ${Colors.white};
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        
    }
`

export const ActionButton = styled.button`
    border: 0;
    //background-color: ${Colors.blue};
    background-color: ${props => props.color ? props.color : `${Colors.blue}`};
    width: 100%;
    padding: 10px 30px;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    
    font-weight: 600;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    font-size: 0.9rem;
    cursor: pointer;
    color: ${Colors.white};
    transition: all 0.2s ease-in-out;

    ${({ disabled }) => disabled && `
        cursor: default;
        background-color: #cccccc;
        color: #666666;
        box-shadow: none;

    `}

    &:hover {
        ${({ disabled }) => disabled ? `
        box-shadow: none;`
        :
        `box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;`}
        
    }
`

export const Button = styled.button`
    border: 0;
    //background-color: ${Colors.blue};
    background-color: ${props => props.color ? props.color : `${Colors.blue}`};
    width: ${props => props.fullWidth ? "100%" : "fit-content"};
    padding: ${props => props.padding ? props.padding : "8px 25px"} ;
    text-align: center;
    letter-spacing: 1px;
    display: flex;
    min-width: 150px;
    align-items: center;
    justify-content: center;
    margin: ${props => props.margin ? props.margin : "0px"};
    font-weight: 400;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    font-size: ${props => props.fontSize ? props.fontSize : "0.8rem"};
    cursor: pointer;
    color: ${props => props.textColor ? props.textColor : `${Colors.white}`};
    transition: all 0.2s ease-in-out;
    ${({ highlighted }) => highlighted && `
        background-color: ${Colors.highlight};
    `}

    ${({ outlined }) => outlined && `
        background-color: inherit;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        color: ${Colors.dark_grey};
    `}

    
    ${({ disabled }) => disabled && `
        cursor: default;
        background-color: #cccccc;
        color: #666666;

    `}

    &:hover {
    
        ${({ highlighted }) =>
        highlighted && `background-color: ${Colors.highlight_highlight};`}

        ${({ outlined }) =>
        outlined && `color: ${Colors.blue}`
    }
    }

    @media (max-width: 600px) {
        flex: 1;
        width: 100%;
        font-size: 0.8rem;

    }
`

export const ButtonOutlined = styled.button`
    border: 0;
    //background-color: ${Colors.blue};
    background-color: ${props => props.selected ? `${Colors.blue}` : `${Colors.white}`};
    color: ${props => props.selected ? `${Colors.white}` : `${Colors.dark_grey}`};
    width: ${props => props.fullWidth ? "100%" : "fit-content"};
    padding: ${props => props.padding ? props.padding : "8px 25px"} ;
    text-align: center;
    letter-spacing: 1px;
    gap: '5px';
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${props => props.margin ? props.margin : "0px"};
    font-weight: 500;
    border-radius: 15px;
    
    border: 2px solid ${Colors.blue};
    font-size: ${props => props.fontSize ? props.fontSize : "0.8rem"};
    cursor: pointer;
    
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: ${Colors.blue};
        color: ${Colors.white};
    }
`

export const TemplateFieldButton = styled.div`

`

export const ButtonYesNo = styled.button`
    border: 0;
    //background-color: ${Colors.blue};
    background-color: ${props => props.color ? props.color : `${Colors.blue}`};
    width: ${props => props.fullWidth ? "100%" : "fit-content"};
    padding: 7px 30px;
    text-align: center;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-weight: 600;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    font-size: 0.8rem;
    cursor: pointer;
    color: ${props => props.textColor ? props.textColor : `${Colors.white}`};
    transition: all 0.2s ease-in-out;
    ${({ highlighted }) => highlighted && `
        background-color: ${Colors.highlight};
    `}

    ${({ outlined }) => outlined && `
        background-color: inherit;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        color: ${Colors.dark_grey};
    `}

    ${({ status }) => (status && status === "Open") ? `background-color: ${Colors.green};` : `background-color: ${Colors.gray};`}

    ${({ disabled }) => disabled && `
        cursor: default;
        background-color: #cccccc;
        color: #666666;

    `}

    &:hover {
    
        ${({ highlighted }) =>
        highlighted && `background-color: ${Colors.highlight_highlight};`}

        ${({ outlined }) =>
        outlined && `color: ${Colors.blue}`
    }
    }

    @media (max-width: 600px) {
        flex: 1;
        width: 100%;
        font-size: 0.8rem;

    }



    
`

export const StyledLink = styled(Link)`
    text-decoration: none;
    display: flex;
`

export const Text = styled.div`
    color: ${props => props.color ? props.color : `${Colors.dark_grey}`};
    font-size: ${props => props.fontSize ? props.fontSize : "0.8rem"};
    font-weight: ${props => props.fontWeight ? props.fontWeight : "500"};
    flex: 1;
`

export const TextBubble = styled.div`
    color: ${props => props.color ? props.color : `${Colors.dark_grey}`};
    font-size: ${props => props.fontSize ? props.fontSize : "0.8rem"};
    font-weight: ${props => props.fontWeight ? props.fontWeight : "400"};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.light_grey}`};
    border-radius: 10px;
    padding: 5px 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    
`

export const BlueContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 0.9rem;

    background: ${Colors.blue};
    color: ${Colors.white}
`

export const HeaderHolder = styled.div`
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    flex-direction: column;

    @media (max-width: 500px) {
        font-size: 0.9rem;
    }
`


export const HeaderTitle = styled.div`
    font-size: 1.1rem;
    font-weight: 700 !important;
    user-select: none;
    //background: #396afc;  /* fallback for old browsers */
    //background: -webkit-linear-gradient(to right, #2948ff, #396afc);  /* Chrome 10-25, Safari 5.1-6 */
    //background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 10px 10px;
    text-transform: capitalize;

    //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
    width: 100%;
    //border: 1px solid white;
    //border-radius: 5px;
    color: ${Colors.dark_grey};
    
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 500px) {
        font-size: 1rem;
    }
    
`

export const HeaderSubTitle = styled.div`
    font-size: 1rem;
    font-weight: 700;
    margin-left: 5px;
    user-select: none;
    color: ${Colors.dark_grey};
    margin-bottom: 15px;
`

export const PageHolder = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box !important;
    min-height: 100vh;
    
    flex-direction: column;

    padding: 20px;

    @media (max-width: 600px) {
        padding: 10px 15px;
    }
`

export const PageContainer = styled.div`
    display: flex;
    
    width: 100%;
    /* height: 100%;
    flex: 1; */
    
    

    // removed height 100% because on storevisitform margin bottom not working
    flex-direction: column;
    
    padding: 20px;
    box-shadow: ${props => props.empty_shadow === true ? "" : `rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;`} ;
    border-radius: 10px;
    margin-bottom: 10px;

    @media (max-width: 600px) {
        padding: 5px 10px;
    }
`

export const TableContainer = styled.div`
    width: 100%;
    margin-bottom: 10px;
    background-color: ${Colors.white};
    border-radius: 10px;
    padding: 10px;
    // removed margin top for settings - manage users
    cursor: pointer;
`



export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;
    
    margin-top: 10px;
    background-color: ${Colors.light_grey};
    border-radius: 10px;
    
    cursor: pointer;
`

export const CardRow = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    ${({ center_items }) => center_items && `
        align-items: center;
    `}

    ${({ space_between }) => space_between && `
        justify-content: space-between;
    `}
`

export const CardEmptyContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    padding-bottom: 10px;
    margin-top: 10px;
    background-color: ${Colors.light_grey};
    border-radius: 10px;
    padding: 30px 20px;
    flex-flow: wrap;
    margin-bottom: 40px;

`

export const Card = styled.div`
    padding: 20px 10px;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
    border-radius: 10px;
    border: ${props => props.selected ? "2px solid #4361ee" : 0};
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: ${Colors.blue};
        color: ${Colors.white};
        box-shadow: none;
    }
`

export const NewButton = styled.div`

    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
    background-color: ${Colors.highlight};
    padding: 15px;
    border-radius: 5px;
    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    font-size: 0.9rem;

    color: ${Colors.white};

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    ${({ highlighted }) => highlighted && `
        background-color: ${Colors.highlight};
    `}

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
`

export const EmptyContainer = styled.div`
    width: 100%;
    height: 200px;
    padding: 20px 40px;
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${Colors.light_grey};
`

export const EmptyContainerHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const EmptyContainerText = styled.p`
    font-size: 0.9rem;
    font-weight: 500;
    
`

export const EmptyContainerImage = styled.img`
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 15px;
`

export const ErrorContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    background-color: ${Colors.light_grey};
    align-items: center;
    z-index: 2;
    width: 100%;
    height: 100%;
`

export const ErrorContainerText = styled.p`
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
    color: ${Colors.dark_grey};
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
`

export const SelectType = styled.select`
    width: fit-content;
    flex: 1;
    padding: 5px 10px;
    margin-right: 10px;
`

export const SliderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
`
export const SlideContainer = styled.div`
    
`

export const SlideImage = styled.img`
    object-fit: contain;
    max-width: 800px;
    max-height: 600px;
`

export const IconImage = styled.img`
    width: 32px;
    height: 32px;
    object-fit: contain;
`

export const ErrorText = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 0.7rem;
    color: ${Colors.highlight};
`

export const Selection = styled.select`
    width: fit-content;
    flex: 1;
    border-radius: 10px;
    padding: 5px 15px;
`
export const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    display: flex;
    align-items: center;
    color: inherit;
    flex: 1;
    font-size: 0.8rem;

    @media (max-width: 500px) {
        font-size: 0.7rem;
    }
    
`

export const ActionCompleteDate = styled.div`
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 0.8rem;
    margin-top: 5px;
    color: white;
    background-color: ${Colors.green};
    text-align: center;
    
`

export const ActionItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
`

export const ActionItemBlockContainer = styled.div`
    display: flex;
    margin: 5px;
    width: 100%;
    color: ${Colors.dark_grey_highlight};
    align-items: center;
    
    font-size: 0.8rem;

    border-radius: 10px;
    padding: 5px 15px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

    /* background-color: ${({ priority, complete }) => {
        if (complete) return `${Colors.blue}`
        if (priority === 'High') return `${Colors.highlight}`;
        if (priority === 'Medium') return `${Colors.orange}`;
        return `${Colors.green}`;
    }}; */

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
`

export const ActionItemPriorityBubble = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ priority }) => {
        if (priority === 'High') return `${Colors.highlight}`;
        if (priority === 'Medium') return `${Colors.orange}`;
        return `${Colors.green}`;
    }};
`

export const ActionItemBlock = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.9rem;
    gap: 5px;
`

export const ActionItemColumn = styled.div`
    display: flex;
    width: 100%;
    margin-left: 10px;
    padding: 5px 0px;
    flex-direction: column;
`

export const ActionItemHolder = styled.div`
    margin-bottom: 5px;
    font-weight: ${props => props.bold ? "500" : "500"};

    font-size: ${({ size }) => {
        if (size === "small") return "0.7rem";
        if (size === "medium") return "0.8rem"
        if (size === "large") return "0.9rem"

        return "0.7rem"
    }};
`

export const RadioSelectRow = styled.div`
    display: inline-block;
`

export const RowHighlighted = styled.div`
  background-color: ${props => (props.isHighlighted ? `${Colors.blue}` : 'initial')};
`;

export const StyledSignatureHolder = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
`

export const StyledCanvasContainer = styled.div`
  width: 100%;
  height: 200px;
  

  canvas {
    width: 100%;
    height: 100%;
  }
`;

export const TextLabel = styled.div`
  font-size: 0.9rem;
`

export const LabelInfo = styled.div`
  font-size: 0.6rem;
  display: flex;
  letter-spacing: 1.1px;
  margin: 5px;
  text-transform: uppercase;
  color: ${Colors.white};
  padding: 5px 10px;
  align-self: flex-end;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.light_blue_highlight}`};
`

export const GroupHeader = styled.div`
  position: 'sticky';
  top: '-8px';
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  color: ${Colors.blue};
  background-color: ${Colors.light_grey};

  &:hover {
    background-color: ${Colors.light_grey_blue};
  }
`

export const GroupItems = styled.ul`
  padding: 0;
  margin: 0px 2px;
`

export const ColumnContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;

`

export const QRCodeContainer = styled.div`
    height: auto;
    margin-top: 10px;
    margin: 20px auto;
    max-width: 150px;
    padding: 20px;
    width: 100%;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`

export const StyledTextField = styled(TextField)`
border-radius: 20px;
 
 background-color: ${Colors.white};
 
 display: flex;
 font-weight: 500;
 font-size: 0.8rem;
 box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

 .MuiOutlinedInput-notchedOutline {
   border: 0;
 }

 .Mui-focused .MuiOutlinedInput-notchedOutline {
     border: 0;
     outline: 0;
   }

    .MuiOutlinedInput-root {
        border-radius: 20px;
        background-color: ${Colors.white};
    }

 
`