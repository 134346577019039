import React, {useState, useEffect, useRef, useCallback} from 'react'
import { CardContainer, Card, NewButton, Button, HeaderSubTitle, HeaderTitle, PageContainer, StyledClosedIcon, StyledAddOptionIcon, AddOptionButton, PageHolder, StyledUpdateIcon, StyledDeleteIcon, HeaderHolder, StyledCanvasContainer, Colors, StyledSignatureHolder, ColumnContainer, StyledUserIcon } from './styled/styled.container';
import { TemplateFormFieldContainer, TemplateFormFieldNewFieldButton, TemplateFormFieldNewField, TemplateFormField, TemplateFormFieldText, TemplateFormFieldType, TemplateFormFieldRow, TemplateFormFieldAssignable, TemplateFormFieldNumber, TemplateFormFieldLabel, TemplateFormFieldButton, TemplateFormTitle, TemplateFormEmptyContainer, TemplateFormEmptyContainerText, TemplateFormErrorContainer, TemplateFormErrorContainerText, TemplateFormFieldCompleted, TemplateFormFieldFiles, TemplateFormFieldInsertComment, TemplateFormFieldLink, TemplateFormFieldFilesContainer, TemplateSection, TemplateFormFieldComment, TemplateSectionHeader, TemplateCountdownContainer, TemplateFormFieldScoreContainer, TemplateSectionLastScore, TemplateFormFieldQuestion, TemplateFormFieldSignatureLabel, TemplateDescription, TemplateDraftUpdate, TemplateDraftNameUpdateHolder, TemplateFormButtonStack, DivHolder } from './styled/styled.templateform';
import { db, storage } from "./firebase";
import { getDocs, Timestamp } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import axios from 'axios';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment/moment';
import {collection, onSnapshot, query, doc, setDoc, where, deleteDoc, addDoc, getDoc, updateDoc, FieldValue, arrayUnion} from "firebase/firestore";
import { useNavigate, useParams } from 'react-router-dom';
import { orderBy } from "firebase/firestore";
import { Divider } from '@mui/material';  
import { makeStyles } from "@material-ui/core/styles";
import { VisitHeaderContainer, VisitHeaderItem, VisitHeaderItemContainer } from './styled/styled.storevisit';
import Compress from "browser-image-compression"
import Loader from './Loader';
import ErrorDialog from './ErrorDialog';
import StoreVisitCompleteDialog from './StoreVisitCompleteDialog';
import DeleteFileDialog from './DeleteFileDialog';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { StyledNavLink } from './styled/styled.container';
import AlertDialog from './AlertDialog';
import Section from './Section';
import { auth } from "./firebase"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Signature from './Signature';
//import Section from './Section';

import Header from './Header';
import { useContext } from "react";
import AuthContext from "./AuthContext";
import logEvent from './hooks/logEvent';
import { DashboardCard, DashboardContainer, DashboardItem } from './styled/styled.dashboard';
import { StyledCalendarIcon, StyledLocationIcon, StyledVisitIcon } from './styled/styled.icons';
/*

Still to do



*/

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  section: {
    background: "#4361ee",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));


function StorevisitForm(props) {

  const {store, template} = props;
  const {storeDetails} = useContext(AuthContext)
  const [sections, setSections] = useState([]);
  const [currentComment, setCurrentComment] = useState("");
  const [runningDraft, setRunningDraft] = useState(false)
  const [selectedStore, setSelectedStore] = useState()

  const params = useParams();
  
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [templateDescription, setTemplateDescription] = useState("")
  const [users, setUsers] = useState([]);
  const [visitScorable, setVisitScorable] = useState();
  const [visitType, setVisitType] = useState();
  const visitDateRef = useRef(moment().format("DD-MM-YYYY HH:mm"));
  const [visitDate, setVisitDate] = useState(visitDateRef.current);
  const [visitEndDate, setVisitEndDate] = useState();
  const [lastUpdatedDraft, setLastUpdatedDraft] = useState();
  const [visibleSections, setVisibleSections] = useState([]);
  const [prevSectionLength, setPrevSectionLength] = useState(0);
  const [previousActions, setPreviousActions] = useState(0);
  const [storeItem, setStoreItem] = useState("");
  const [defaultStatusClosed, setDefaultStatusClosed] = useState(false);
  const [timedVisit, setTimedVisit] = useState(false);
  const [timerAmount, setTimerAmount] = useState();
  const [timerProgress, setTimerProgress] = useState();
  const [visitScore, setVisitScore] = useState();
  const [visitDuration, setVisitDuration] = useState();
  const [locationRequired, setLocationRequired] = useState(false)
  const [currentLocation, setCurrentLocation] = useState();
  const [lastVisit, setLastVisit] = useState();
  const [showOutstandingActionsAlert, setShowOutstandingActionsAlert] = useState(false)
  const [visitRunning, setVisitRunning] = useState(true);
  const [signatureRequired, setSignatureRequired] = useState(false)
  const [fileSizeError, setFileSizeError] = useState(false);
  const [totalActions, setTotalActions] = useState(0)
  const [showLoading, setShowLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [visitID, setVisitID] = useState("");
  const [startReportTiming, setStartReportTiming] = useState();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorContent, setErrorContent] = useState({});
  const [actionsOutstanding, setActionsOustanding] = useState(0);
  const [showCompleteDialog, setShowCompleteDialog] = useState(false)
  const [visitResults, setVisitResults] = useState();
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(false);
  const [savingVisitLoader, setSavingVisitLoader] = useState(false)
    // used to store the section id and section item id for the DeleteFileDialog param
  const [selectedFile, setSelectedFile] = useState();
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const [signatureUrlLink, setSignatureUrlLink] = useState();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [visitFiles, setVisitFiles] = useState();
  const [selectedItemID, setSelectedItemID] = useState();
  const [showFieldError, setShowFieldError] = useState(false);

  const signatureRef = useRef(null);
  const [signature, setSignature] = useState(null);
  //const [signatureData, setSignatureData] = useState([]);
  const sectionsRef = useRef([]);
  const intervalRef = useRef(null);

  //const [pdfLink, setPDFLink] = useState();
  let templateQueryCollectionRef = "";
  let templateCollectionRef = "";
  let usersRef = "";

  //const dateStoreVisit = moment().format("DD-MM-YYYY");
  const navigate = useNavigate();
  const [receivedPDFLink, setReceivedPDFLink] = useState(false)
  const [pdfLink, setPDFLink] = useState();

  useEffect(() => {
    
  }, [sections])

  useEffect(() => {

    sectionsRef.current = sections;
    if (sections && sections.length > 0) {
      intervalRef.current = setInterval(() => {
        saveAuditDB(sections);
      }, 15000);
  
      return () => clearInterval(intervalRef.current);
    }
  }, [sections, showCompleteDialog]);
  
  useEffect(() => {
    if (showCompleteDialog) {
      clearInterval(intervalRef.current);
    }
  }, [showCompleteDialog]);

  const sectionsObserverRef = useRef([]);
  const observer = useRef(null);

  // Create an IntersectionObserver to monitor section visibility
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    if(sections.length === 0 || sections.length !== prevSectionLength) return;
  
    // Initialize the IntersectionObserver
    const obs = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {

        ///console.log("Entry ", entry.target, "Entries", entries)
        
        if (entry.isIntersecting) {
          const currentSectionIndex = sectionsObserverRef.current.findIndex(
            (ref) => {
              //console.log("Ref", ref, "Entry", entry.target)
              return ref === entry.target
            }
          );
  
          if (currentSectionIndex !== -1) {
            const nextSectionIndex = currentSectionIndex + 1;
            if (currentSectionIndex !== -1 && !visibleSections.includes(currentSectionIndex)) {
              setVisibleSections((prevVisible) => [...prevVisible, currentSectionIndex]);
            }
            
          }
        }
      });
    }, options);
  
    // Observe all section elements
    sectionsObserverRef.current.forEach((sectionRef) => {
      
      if (sectionRef) obs.observe(sectionRef);
    });
  
    // Clean up on unmount
    return () => {
      obs.disconnect();
    };
  }, [sections]);

// hello
  // useEffect(() => {
  //   if(showLoading) {
  //     const timeoutId = setTimeout(() => {
  //       setShowLoading(false);
  //     }, 50000);
  
  //     return () => {
  //       clearTimeout(timeoutId);
  //     };
  //   }
  // }, [showLoading])

  useEffect(() => {
    if(timedVisit) {

      const interval = setInterval(() => {

        if(timerProgress < 1 && visitRunning) {
          clearInterval(interval);
          handleSaveStoreVisit()
        } 
        else setTimerProgress(prevState => prevState - 1);
      }, 60000);

      return () => clearInterval(interval)
    }
  }, [timerProgress]);



  useEffect(() => {
    if(pdfLink && !receivedPDFLink && visitID && visitFiles) {
      
      async function saveVisitCloud() {
      
        const closeNow = checkFieldsCloseOnFill();
        const selectedItemIDAnswer = getSelectedItemIDAnswer();
        
        //const dateToday = moment().format("DD-MM-YYYY");

        //const visitRef = doc(collection(db, "stores/" + storeDetails.rootID + "/visits"));
       
        //setVisitID(visitRef.id);
        setLoaderMessage("Saving to the cloud")
        //console.log("Got visit files", visitFiles)

        //console.log("PDF Link", pdfLink)
        
        const linkRef = await uploadBlob(visitID, pdfLink);
        //const visitFiles = [];

        // const updatedList = await Promise.all(sections.map(async section => {
        //   return {...section,
        //     list: await Promise.all(section.list.map(async element => {
        //       if(element.newFiles.length > 0) { 
        //         setLoaderMessage("Uploading files")
        //         const updatedFileList = [];
        
        //         for (const fileElement of element.newFiles) {
                  
        //           const formData = new FormData();
        //           formData.append("idToken", idToken);
        //           formData.append('file', fileElement.file);
        //           formData.append("file_name", fileElement.file.name)
        //           formData.append('store_id', storeDetails.rootID);
        //           formData.append('visit_id', visitID);
        //           formData.append('section_id', section.section_id);
        //           formData.append('section_item_id', element.section_item_id);

        //           try {
        //             const response = await axios.post('https://us-central1-storecall.cloudfunctions.net/api/upload-file', formData);
        //             const result = response.data;
        //             const { url, size } = result;
                    
        //             visitFiles.push({
        //               file_description: fileElement.file.name,
        //               url: url,
        //             });
                    
        //             const newFileElement = {
        //               description: fileElement.file.name,
        //               date: dateToday,
        //               size: size,
        //               user: storeDetails.user.name,
        //               comment: fileElement.comment,
        //               url: url,
        //             };
                    
        //             //console.log('Uploaded', newFileElement)
                    
        //             updatedFileList.push(newFileElement);
        //           } catch (error) {
        //             console.error(error);
        //           }

        //         }
        
        //         return {...element, 
        //           files: updatedFileList,
        //           newFiles: []
        //         }
        //       }
        //       else return element;
        //     })),
        //     score: getSectionScore(section.section_id),
        //     scorable: isSectionScorable(section.section_id)
        //   }
        // }))
        //setLoaderMessage("Finalizing visit")
        setVisitEndDate(moment().toISOString());

        //console.log("Setting cloud to ", sections)

        await setDoc(doc(db, "stores/" + storeDetails.rootID + "/visits/" + visitID), {
            store_id: selectedStore.cloud_id,
            date: moment(visitDate, "DD-MM-YYYY HH:mm").toISOString(),
            template_id: selectedTemplate.cloud_id,
            score: visitScore,
            selected_id_answer: selectedItemIDAnswer || "",
            selected_id: selectedItemID || "",
            scorable: visitScorable,
            report_link: linkRef,
            attachments: visitFiles,
            visit_type: visitType,
            visit_status: closeNow ? "Closed" : "Open",
            visit_closed_date: closeNow ? moment().format("DD-MM-YYYY HH:mm") : "",
            location: locationRequired===true ? currentLocation : [],
            location_required: locationRequired,
            user: storeDetails.user.name,
            user_uid: storeDetails.user.uid,
            startTime: moment(visitDate, "DD-MM-YYYY HH:mm").toISOString(),
            manager: storeItem.manager_uid,
            signature_link: signatureUrlLink || "",
            endTime: moment().toISOString(),
            actions_total: totalActions,
            actions_outstanding: totalActions
        })

        await Promise.all(sections.map(section => {
          //console.log(section)
         
          return new Promise((resolve) => {
            resolve(setDoc(doc(db, "stores/" + storeDetails.rootID + "/visits/" + visitID + "/sections", section.section_id), section))
          }) 
        }))

        setShowLoading(false)

        if(runningDraft) await deleteAuditFromDB(params.storeid, params.templateid);
        else deleteAuditFromDB(selectedStore.cloud_id, selectedTemplate.cloud_id)
      
      }


      async function handleSave() {
        
        //setSavingVisitLoader(true)
        const result = await saveVisitCloud();
        
        setReceivedPDFLink(true)
      }

      handleSave();
      
    }
  }, [pdfLink, signatureUrlLink, visitID, sections, visitFiles])


  useEffect(() => {
    
    setSections([])
    setUsers([]);
    setShowLoading(false)

    async function getStoreDetails(selectedStore, selectedTemplate) {

      const storeRef = query(collection(db, "stores/" + storeDetails.rootID + "/stores"), where("name", "==", selectedStore.name));
      const storeDoc = await getDocs(storeRef);
      const storeData = storeDoc.docs[0].data();
      const storeManagerUID = storeData.manager;
      let storeManagerName = ""

      if(storeManagerUID && storeManagerUID.length > 0)
      {
        const storeManagerRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", storeManagerUID));
        const storeManagerDoc = await getDocs(storeManagerRef);
        const storeManagerData = storeManagerDoc.docs[0].data();
        storeManagerName = storeManagerData.name;
      }

      setStoreItem({
        store: selectedStore.name,
        manager: storeManagerName,
        manager_uid: storeManagerUID,
        region: selectedStore.region
      })

      // check if warning should be showed for space usage
      getStoreSpaceUsage();
    }
    
    async function getSections(cloud_id, isDraft) {
      if(cloud_id) {

        templateQueryCollectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/templates/" + cloud_id + "/sections"), orderBy("key", "asc"));
        templateCollectionRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + cloud_id);
        const templateDoc = await getDoc(templateCollectionRef)
        const template_id = templateDoc.id;
        const templateData = templateDoc.data();
        

        const location_required = templateData.location_required;
        const signature_required = templateData.signature_required;

        setTemplateDescription(templateData.description)
        setSelectedItemID(templateData.selected_item_id?.section_item_id);
        
        if(location_required !== undefined) setLocationRequired(location_required)
        if(signature_required !== undefined) setSignatureRequired(signature_required)

        setVisitScorable(templateData.scorable);
        setVisitType(templateData.template_type);
        
        setDefaultStatusClosed(templateData.default_status_closed)
        const permissionUsers = templateData.permission_users;
        
        // set users for action by to only users who have permission
        // for that template
        usersRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), 
        where("group", 'in', permissionUsers));
        
        const userDocs = await getDocs(usersRef);
        let userList = userDocs.docs.map(doc => {
          const userData = doc.data();

          if(userData.specific_templates) {
            const userTemplates = userData.specific_template_list;
            if(userTemplates.indexOf(template_id) > -1)
              return userData;

          } else {
              return userData

          }
        })

        const adminUsers = storeDetails.all_users.filter(user => user.type === "admin");
        const mergedUsers = [...userList, ...adminUsers]
        const uniqueUsers = [];
        mergedUsers.forEach(user => {
          if(!uniqueUsers.find(unique => unique.uid === user.uid)) uniqueUsers.push(user)
        })

        setUsers(uniqueUsers.filter(user => user.active === true))
        
        // setPermissionUsers(templateData.permission_users);
        
        if(templateData.timer) {
          setTimedVisit(templateData.timer)
          setTimerAmount(templateData.timer_mins);
          setTimerProgress(templateData.timer_mins);
        }

        const sectionElements = [];
        // if not running a draft get the blank sections from the template and fill fields
        if(!isDraft) {
          const templateDocs = await getDocs(templateQueryCollectionRef)
          if(templateDocs.docs.length > 0) {
            
            templateDocs.docs.map(item => {
              const sectionID = item.id;
                const sectionData = item.data();

                // get template list items and append with store visit items
                const updatedList = sectionData.list.map(element => {
                  return {...element,
                      section_id: sectionID,
                      fieldText: "",
                      actions: [],
                      rating: 0,
                      yesno: "",
                      selectionOption: "None",
                      datetime: "",
                      files: [],
                      newFiles: [], // will not be used in form - only in report to allow user to upload more files
                      showComments: false,
                      comments: []
                  }
                })

                const sectionItem = {
                    ...sectionData,
                    //template_id: templateID,
                    section_id: sectionID,
                    name: sectionData.name,
                    key: sectionData.key,
                    list: updatedList
                }

                sectionElements.push(sectionItem)
            })
          }
        }

        setPrevSectionLength(sectionElements.length);
        setSections(sectionElements)
      }

    }

    async function getUsers() {
      //const templatePermissionUsers = query(collection(db, "stores/" + storeDetails.rootID + "/templates"))
      

    }

    async function getLastVisitDetails(selectedTemplate, selectedStore) {
          
      
      const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"), where("template_id", "==", selectedTemplate.cloud_id), where("store_id", "==", selectedStore.cloud_id), orderBy("date", "desc"));
      const docs = await getDocs(q);

      if(docs.docs.length > 0) {
        const docData = docs.docs[0].data();
        const visitID = docs.docs[0].id;

          const sectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visitID + "/sections"));
          const sectionDocs = await getDocs(sectionRef);
          const sectionItems = sectionDocs.docs.map(doc => doc.data());

          const lastVisitInfo = {
          id: docs.docs[0].id,
          data: {...docData, 
            date: moment(docData.date).format("DD-MM-YYYY"),
            sections: sectionItems
          }
          }


          setLastVisit(lastVisitInfo);
      }

        //setLastVisit(lastVisitInfo);

    }

    async function getPreviousOutstandingActions() {
      const visitRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits"), where("store_id", "==", selectedStore.cloud_id), where("template_id", "==", selectedTemplate.cloud_id), where("actions_outstanding", ">", 0));
      const visitDocs = await getDocs(visitRef);
      if(visitDocs.docs.length > 0) {
        setPreviousActions(visitDocs.docs.length);
      }

    }

    async function handleGetInfo() {

        
        if(storeDetails.rootID.length > 0 && storeDetails.stores && storeDetails.templates && isOnline) {
          
          
          const sTemplate = storeDetails.user_specific_templates.find(template => template.cloud_id === params.templateid);
          const sStore = storeDetails.user_specific_stores.find(store => store.cloud_id === params.storeid);

          if(!sTemplate || !sStore) {
            navigate("/app")
            return;
          }

          setSelectedStore(sStore);
          setSelectedTemplate(sTemplate);

          //console.log("Selected store", selectedStore)
          //console.log("Selected template", selectedTemplate)
          const isDraft = (params.draft) || false;

          setShowLoading(true)
   
          await getSections(sTemplate.cloud_id, isDraft);

          await getUsers();
       
          await getStoreDetails(sStore, sTemplate);
          await getLastVisitDetails(sTemplate, sStore);
          //await getPreviousOutstandingActions();

          if(isDraft)
          {
            readAuditFromDB(params.storeid, params.templateid);
          }
          setShowLoading(false)
        //deleteDatabase();
        //saveAuditDB()
        }
    }

    handleGetInfo();

    //getSections();
    //getUsers();
    //createVisit();

    //setStartReportTiming(moment().format("DD-MM-YYYY HH:mm"))
    
  }, [storeDetails])

  const checkFieldsCloseOnFill = () => {
    if(defaultStatusClosed) return true;
    else {
      let closeVisit = true;

      for (let i = 0; i < sections.length; i++) {
        const sectionElements = sections[i].list;

        if (sectionElements.some(item => {
          if (item?.closeOnFill) {
            if (item.type === "Comment" || item.type === "Number") {
              return item.fieldText.length === 0;
            } else if (item.type === "Selection") {
              return item.selectionOption.length === 0;
            } else if (item.type === "List Checkbox") {
              return item.item_list_checkbox.length === 0;
            } else if (item.type === "Item Comment" || item.type === "Item List" || item.type === "Item Selection") {
              return item.item_list_comment.length === 0;
            } else if (item.type === "Item Count") {
              return item.counts.length === 0;
            } else if (item.type === "DateTime" || item.type === "Time") {
              return item.datetime.length === 0;
            } else if (item.type === "YesNo") {
              return item.yesno.length === 0;
            } 
          }
          return false;
        })) {
          closeVisit = false;
          break;
        }
      }

      return closeVisit;
    }
  }

  function getSelectedItemIDAnswer() {
    const selectedItem = sections.flatMap(section => section.list)
    .find(item => item.section_item_id === selectedItemID);

    if (!selectedItem) return "";

    switch (selectedItem.type) {
        case "Comment":
        case "Number":
            return selectedItem.fieldText;
        case "Selection":
            return selectedItem.selectionOption;
        case "DateTime":
        case "Time":
            return selectedItem.datetime;
        case "YesNo":
            return selectedItem.yesno;
        default:
            return "";
    }
}

    const handleSignatureEnd = () => {
      setSignature(signatureRef.current.toDataURL());
    }
    const clearSignature = () => {
      
      //signatureRef.current.clear();
      signatureRef.current.clearCanvas();
      setSignature(null);
    }

    // const handleSignatureDrawn = (data) => {
    //   //console.log("Saving signature")
    //   setSignatureData(data);
    // };

    function saveAuditDB(section_list) {
      console.log("Running save")
      const request = indexedDB.open("storecall_audits", 1);
      request.onerror = console.error;
  
      request.onupgradeneeded = function(event) {
          const db = event.target.result;
          //console.log("DB created");
          const audit = db.createObjectStore("audits", { keyPath: ['store_id', 'template_id', 'root_id'] });
          audit.createIndex("store_template", ['store_id', 'template_id', 'root_id'], { unique: true });
      };
  
      request.onsuccess = function(event) {
          const db = event.target.result;
          if (!db.objectStoreNames.contains("audits")) {
              console.error("Object store 'audits' not found in the database.");
              db.close();
              indexedDB.deleteDatabase("storecall_audits");
              return;
          }
  
          const transaction = db.transaction(["audits"], "readwrite");
          const objectStore = transaction.objectStore("audits");
  
          const key = { store_id: selectedStore.cloud_id, template_id: selectedTemplate.cloud_id, root_id: storeDetails.rootID };
          const data = { store_id: selectedStore.cloud_id, template_id: selectedTemplate.cloud_id, root_id: storeDetails.rootID, fileSize_Error: fileSizeError, visit_date: visitDate, visit_type: visitType, selectedStore: selectedStore, selectedTemplate: selectedTemplate, sections: section_list };
  
          const keyPath = objectStore.keyPath;
          if (keyPath.length !== Object.keys(key).length || !keyPath.every((prop) => key.hasOwnProperty(prop))) {
              console.error('Invalid key structure');
              return;
          }
  
          const getRequest = objectStore.get([selectedStore.cloud_id, selectedTemplate.cloud_id, storeDetails.rootID]);
          getRequest.onsuccess = function(event) {
              const existingData = event.target.result;
              if (existingData) {
                  // If data already exists for the store_id and template_id combination, overwrite it
                  const putRequest = objectStore.put(data);
                  console.log("Overwritten");
                  putRequest.onerror = console.error;
              } else {
                  // If data doesn't exist for the store_id and template_id combination, create new data
                  const addRequest = objectStore.add(data);
                  console.log("Saved");
                  addRequest.onerror = console.error;
              }

              setLastUpdatedDraft(moment().format("HH:mm"))
          };
  
          transaction.oncomplete = function() {
              db.close();
          };
      };
  }

    async function handleDeleteDraft() {
      await deleteAuditFromDB(params.storeid, params.templateid);
      navigate(-1)
    }

    function deleteAuditFromDB(storeId, templateId) {
      try {
      return new Promise((resolve, reject) => {
      const request = indexedDB.open('storecall_audits', 1);
      request.onerror = console.error;
      request.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction(['audits'], 'readwrite');
        const objectStore = transaction.objectStore('audits');
        const index = objectStore.index('store_template');
        //console.log("storeID", storeId, templateId, storeDetails.rootID)
        const keyRange = IDBKeyRange.only([storeId, templateId, storeDetails.rootID]);
        const deleteRequest = index.openCursor(keyRange);
    
        deleteRequest.onsuccess = function(event) {
          const cursor = event.target.result;
          if (cursor) {
            cursor.delete();
            console.log("Draft audit deleted")
            cursor.continue();
          }
          else resolve()
        };
    
        deleteRequest.onerror = reject;
    
        transaction.oncomplete = function() {
          //console.log('db closed');
          db.close();
        };
      };
    })
    }catch(err) {
      console.log(err)
    }
    }

    function readAuditFromDB(storeId, templateId) {
      //console.log("Reading", storeId, templateId)
      const request = indexedDB.open('storecall_audits', 1);
      request.onerror = console.error;
      request.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction(['audits'], 'readonly');
        const objectStore = transaction.objectStore('audits');
        const index = objectStore.index('store_template');
        const keyRange = IDBKeyRange.only([storeId, templateId, storeDetails.rootID]);
        const getRequest = index.get(keyRange);
        
    
        getRequest.onsuccess = function(event) {
          const audit = event.target.result;

          if(audit.sections) {
            //console.log("Setting sections to", audit.sections)
            //console.log("Audit", audit)
            setRunningDraft(true)
            setVisitDate(audit.visit_date)
            setSections(audit.sections)
          }
        };
    
        getRequest.onerror = console.error;
    
        transaction.oncomplete = function() {
          //console.log('db closed');
          db.close();
        };
      };
    }

    //readAuditFromDB(selectedStore.cloud_id, selectedTemplate.cloud_id)

    //const saveInterval = setInterval(saveAuditDB, 1 * 60 * 1000); // 5 minutes in milliseconds
    
    
    // used when saving visit to see if space maxed
    async function isStoreSpaceAvailable() {
      const token = await getIDToken();
      const encoded_token = encodeURIComponent(token)

      const response = await fetch(`https://us-central1-storecall.cloudfunctions.net/api/organisation-space-available?uid=${encoded_token}&storeid=${storeDetails.rootID}`)
      const response_data = await response.json();
      
      // ceck here
      return response_data;
    }

    function deleteDatabase() {
      const request = indexedDB.deleteDatabase("storecall_audits");
      request.onerror = console.error;
      request.onsuccess = function() {
        console.log("Database deleted successfully");
      };
    }

    async function getStoreSpaceUsage() {
      const token = await getIDToken();
      const encoded_token = encodeURIComponent(token)
  
      const response = await fetch(`https://us-central1-storecall.cloudfunctions.net/api/organisation-space-used?uid=${encoded_token}&storeid=${storeDetails.rootID}`)
      const response_data = await response.json();
      const used_space = response_data.used;
      const max_space = response_data.max_space;
      const percent_used = (used_space / max_space);

      //console.log("Checking space")
  
      if(percent_used >= 98) {
        setBarMessage({
          type: "error",
          message: `Organisation space usage is ${percent_used}%. This visit may not save correctly.`
        })

        setOpenBar(true)
        setShowLoading(false)
      }
      
    }

    async function getMyLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              //console.log("Lat ", latitude, "Lng ", longitude)
              resolve({lat: latitude, lng: longitude});
            },
            error => {
              if(error.code === error.PERMISSION_DENIED) resolve({error: "PERMISSION"})
              else reject(error);
            }
          );
        } else {
          reject("Geolocation is not supported by this browser.");
        }
      });
    }

    async function getSignatureImageUrl(visitRef_id, blob) {

      return new Promise((resolve, reject) => {
        const storageRef = ref(storage, `${storeDetails.rootID}/${visitRef_id}/signature.png`);

        const uploadTask = uploadBytesResumable(storageRef, blob);
        
        uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
        (error) => {
          console.log(error)
          reject(error)
        },
        () => {
        
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            //setUploadedFiles(prevState => [...prevState, downloadURL])
          });
        }
      );
      })

    }

    async function uploadBlob(visitRef_id, link) {
      //console.log("Rec Link", visitRef_id, link)

      const blob = await fetch(link).then(res => res.blob());
      return new Promise((resolve, reject) => {
        const storageRef = ref(storage, `${storeDetails.rootID}/${visitRef_id}/reports/${visitRef_id}.pdf`);

        const uploadTask = uploadBytesResumable(storageRef, blob);
        
        uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
        (error) => {
          console.log(error)
          reject(error)
        },
        () => {
        
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            //setUploadedFiles(prevState => [...prevState, downloadURL])
          });
        }
      );
      })
    }

  async function compressImage(file) {
    const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    if(!acceptedImageTypes.includes(file.type)) {
      return file
    }

    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true
    }


    const compressedBlob = await Compress(file, options);
    if(compressedBlob) {

      const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now()})
      return convertedBlobFile
    } 
    else return file;
  }



    const isLetters = (str) => /^[A-Za-z]*$/.test(str);




  function handleDeleteFileDialog(section_id, section_item_id, file) {
    //setSelectedSection(section_id);
    setSelectedFile({
      section_id: section_id,
      section_item_id: section_item_id,
      file: file
    })

    setShowDeleteFileDialog(true);
  }


  



  async function getIDToken() {
    const token = await auth.currentUser.getIdToken(true)
    return token;
  }

  async function removeFileCloud(section_id, section_item_id, file_name) {
        setShowLoading(true);

        const idToken = await getIDToken();

        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-file', {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          idToken: idToken,
          storeid: storeDetails.rootID,
          visitid: visitID,
          sectionid: section_id,
          sectionitemid: section_item_id,
          file_name: file_name
        })
        })

        if(result.status === 200) {
          setBarMessage({
            type: "info",
            message: "File has been successfully deleted.",
            result: "success"
          })

          setOpenBar(true)
          
        }
        else {
          setShowLoading(false)
          setBarMessage({
            type: "error",
            message: "There was an error deleting this file."
          })

          setOpenBar(true)
          
        }

  }

  async function handleRemoveFile(section_id, section_item_id, file) {

    // if the file is part of newfiles then remove from newfiles
    // else if file is part of files list -> remove from files, firebase and storage

      // remove file from list
        setSections(prevState => (prevState.map(el => {
        if(el.section_id === section_id) {
            return {...el, 
                list: el.list.map(item => {
                    if(item.section_item_id === section_item_id) {
                        const updatedFileList = item.files.filter(element => element.description !== file.description)
                        return {...item, files : updatedFileList}
                    } else return item; })
            }
        }
        else return el;
        })))
      

    setShowDeleteFileDialog(false);

  
  }


    async function getFileDownloadURLS(visit_id, section_id, section_item_id, files) {

      const dateToday = moment().format("DD-MM-YYYY");
      const uploadedFiles = [];
      
          const result = await Promise.all(files.map(file => {
              return new Promise(async (resolve, reject) => {
                  const currentFile = await compressImage(file.file);
                  
                  const storageRef = ref(storage, `${storeDetails.rootID}/${visit_id}/${section_id}/${section_item_id}/${currentFile.name}`);
                  const uploadTask = uploadBytesResumable(storageRef, currentFile);
                  
                  uploadTask.on("state_changed",
                  (snapshot) => {
                    const progress =
                      Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    
                  },
                  (error) => {
                    alert(error);
                  },
                  () => {
                  
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  
                      const fileElement = {
                          description: currentFile.name,
                          date: dateToday,
                          size: currentFile.size,
                          user: storeDetails.user.name,
                          comment: file.comment,
                          url: downloadURL
                      }
                      
                      uploadedFiles.push(fileElement);
                      resolve(fileElement);
                      //setUploadedFiles(prevState => [...prevState, downloadURL])
                    });
                  }
                );
              })
          }))

          return result;
            
    }



  async function getTotalActions() {
    const actionsTotal = sections.map(section => {
      return section.list.reduce((total, currentItem) =>  (currentItem.actions && currentItem.actions.length > 0) ?  total + currentItem.actions.length : total  + 0, 0 )
    }).reduce((total, item) => total + item)

    setActionsOustanding(actionsTotal);

    return actionsTotal;
  }

    async function handleSaveStoreVisit() {

      if(fileSizeError) {
        setErrorContent({
          title: "Large Files",
          content: "You have files that are too large to be uploaded to this store visit.",
          extra_content: "Maximum file size: 8mb."
        })
        setShowErrorDialog(true);
        return;
      }

      let location;
      if(locationRequired) {
        location = await getMyLocation()
        if(location.error && location.error === "PERMISSION") {
          setBarMessage({
            type: "error",
            message: "Your location needs to be enabled before completing this visit.",
          })

          setOpenBar(true)
          return;
        }
        
      }

      if(signatureRequired && signatureRef.current.isCanvasBlank())
      {
        setBarMessage({
          type: "error",
          message: "A signature is required before completing this visit."
        })
        setOpenBar(true)
        return;
      }

      const mandatoryFieldsMissing = await checkTemplateFieldsFilled();
      if(mandatoryFieldsMissing) {
        setShowFieldError(true)
        setBarMessage({
          type: "error",
          message: "You have mandatory fields that are missing information."
        })
        setOpenBar(true)
        
        return;
      }

      setLoaderMessage("Preparing");
      setShowLoading(true)

      const space_available = await isStoreSpaceAvailable();

        if(!space_available) {
          setShowLoading(false)
          setBarMessage({
            type: "error",
            message: "Your organisation space availability is maxed. Please upgrade your space limit."
          })

          setOpenBar(true)
          setShowLoading(false)
          return;
        }

      setVisitRunning(false);
      setCurrentLocation(location)
      const visitScore = await getVisitScore();
      const actions_total = await getTotalActions();
      
      setTotalActions(actions_total);
      setVisitScore(visitScore);
      

      const currentTime = moment().format("DD-MM-YYYY HH:mm");
      const reportTime = moment.duration(moment(visitDate, "DD-MM-YYYY HH:mm").diff(moment(currentTime, "DD-MM-YYYY HH:mm"))).humanize();
      setVisitDuration(reportTime)

      const visitRef = doc(collection(db, "stores/" + storeDetails.rootID + "/visits"));
      const idToken = await getIDToken();
      const visitFilesTemp = [];
      const dateToday = moment().format("DD-MM-YYYY");

      const updatedSections = await Promise.all(sections.map(async section => {
        const updatedList = await Promise.all(section.list.map(async element => {
          if (element.newFiles.length > 0) {
            setLoaderMessage("Uploading files");
            const updatedFileList = [];
            for (const fileElement of element.newFiles) {
              const formData = new FormData();
              formData.append("idToken", idToken);
              formData.append('file', fileElement.file);
              formData.append("file_name", fileElement.file.name)
              formData.append('store_id', storeDetails.rootID);
              formData.append('visit_id', visitRef.id);
              formData.append('section_id', section.section_id);
              formData.append('section_item_id', element.section_item_id);
              try {
                const response = await axios.post('https://us-central1-storecall.cloudfunctions.net/api/upload-file', formData);
                const result = response.data;
                const { url, size } = result;
                visitFilesTemp.push({
                  file_description: fileElement.file.name,
                  url: url,
                });
                const newFileElement = {
                  description: fileElement.file.name,
                  date: dateToday,
                  size: size,
                  user: storeDetails.user.name,
                  comment: fileElement.comment,
                  url: url,
                };
                updatedFileList.push(newFileElement);
              } catch (error) {
                console.error(error);
              }
            }
            return {
              ...element,
              files: updatedFileList,
              newFiles: []
            };
          } else {
            return element;
          }
        }));
        return {
          ...section,
          list: updatedList,
          score: getSectionScore(section.section_id),
          scorable: isSectionScorable(section.section_id),
          files_loaded: true
        };
      }));
      //console.log("Updated ", updatedSections)
      //console.log("Files uploaded")
      logEvent("Visit", `Completed - ${selectedStore.name} - ${selectedTemplate.name}`, storeDetails.user.email);
      setSections(updatedSections)
      if(visitFilesTemp.length > 0) setVisitFiles(visitFilesTemp)
      else setVisitFiles([])
      
      let signatureLink = "";
        if(signatureRequired) {
   
          const signatureBlob = await signatureRef.current.handleSaveClick();
          
          //console.log("SignatureBlob", signatureBlob)
          signatureLink = await getSignatureImageUrl(visitRef.id, signatureBlob)
          setSignatureUrlLink(signatureLink)
          //console.log("Sig Link", signatureLink)
        }
      //console.log("Signature link received")
      setVisitID(visitRef.id);

      //setReportTiming(moment.duration(moment(startTime, "DD-MM-YYYY HH:mm").diff(moment(endTime, "DD-MM-YYYY HH:mm"))).humanize())

      setShowCompleteDialog(true);

      
    }

    function handleCloseCompleteDialog() {
      navigate("/app/storevisit")
      setShowCompleteDialog(false)
    }

    function handleActionScreen() {
      
    }

    async function getVisitScore() {
      const scoreTotal = sections?.map(section => {
        const sectionScore = getSectionScore(section.section_id)
        
        return sectionScore
      }).reduce((total, currentItem) => total +  currentItem)

      console.log("All Section Scores", scoreTotal)

      const scorableSections = sections.filter(section => isSectionScorable(section.section_id));

      if(!scoreTotal || scoreTotal === 0) return 0;

      // work out the visit score for all the sections as a percentage where the getSectionScore returns the percentage score
      // so if there are 3 sections and each section has a score of 50% then the visit score is 50%
      // 150 / 3 = 50

      // 1 / 5 = 0.2 * 100 = 20%
      // 50 + 67 + 20 = 
      const visitScore = Math.abs(Math.round(scoreTotal / scorableSections.length));
      console.log("Total Visit Score", visitScore)

      return visitScore

    }

    function isSectionScorable(section_id) {
      const section = sections.filter(section => section.section_id === section_id);
      const scorableList = section[0].list.filter(item => item.scorable === true);
      if(scorableList.length > 0) return true;
      return false;
    }

    function getSectionScore(section_id) {
      
      // get section score based on scorable items of section items
      const section = sections.find(section => section.section_id === section_id);

      const scoreTotal = section.list.filter(listItem => listItem.scorable).reduce((total, currentItem) => total + currentItem.rating, 0 )

      console.log("Section Total Score", section.name, scoreTotal)

      const itemsTotal = section.list.filter(item => item.scorable)?.length;

      if(!itemsTotal) return 0;
      else if(scoreTotal === 0) return 0;

      // work out the section percentage score where the scoreTotal is a total of ratings where 0 = 0% and 4 = 100%
      // 12 / (6 * 4) * 100 = 50%
      const sectionScore = Math.abs(Math.round( (scoreTotal / (itemsTotal * 4))*100 ));
      console.log("Section score", section.name, sectionScore);
      return sectionScore ? sectionScore : 0;
    
      // const sectionScore = Math.abs(Math.round( (scoreTotal / (itemsTotal * 5))*100 ));
      // console.log("Section score", section.name, sectionScore)
      // return sectionScore ? sectionScore : 0;
    }

    async function checkTemplateFieldsFilled() {
      let bFailed = false;

      sections.filter(hideSections => !hideSections?.showOnComplete).forEach(section => {
          const mandatoryFields = section.list.filter(element => element.mandatory);
          

          mandatoryFields.forEach(item => {

            

              if(item.type === "None") bFailed = false
              if(item.type === "Image" && item.newFiles.length === 0) bFailed = true;

              if(item.type === "Selection" && item.selectionOption === "None") {
                console.log("Selection", item)
                bFailed = true;
              }

              else if(item.type === "Comment" && item.fieldText.length === 0)
              {
                
                bFailed = true;
              }
              else if (item.type === "YesNo" && item.yesno.length === 0) bFailed = true;
              
              else if((item.type === "DateTime" || item.type === "Time") && item.datetime.length === 0) {
                
                bFailed = true;
              } 
              // else if(item.type === "Number" && item.fieldText.length === 0) {
                
              //   bFailed = true;
              // }
              else if(item.type === "Item List" && item.item_list_comment.length === 0) bFailed = true;
              else if(item.type === "Item Comment" && item.item_list_comment.length === 0) bFailed = true;
              else if(item.type === "Item Selection" && item.options.length === 0) bFailed = true;
              else if(item.type === "List Checbox" && item.item_list_checkbox.length === 0) bFailed = true;
              else if(item.type === "Item Count" && item.counts.length === 0) bFailed = true;
              
              
           })

          
      })

      return bFailed;
    }

 

    const handleClose = (event, reason) => {
      
      setOpenBar(false);
      setShowLoading(false)
      //if(barMessage.result === "success") navigate("/storevisit")
    };

    function handleClearSignature() {
      clearSignature()
    }

    const handleUpdateSection = useCallback((sectionProp) => {
      const sectionIndex = sections.findIndex(section => section.section_id === sectionProp.section_id);
      setSections(prevSections => {
        const updatedSections = [...prevSections];
        updatedSections[sectionIndex] = sectionProp;

        return updatedSections;
      });
    }, [sections]);

    

    

    return (
      <PageHolder>
        <Header>
          <HeaderHolder>
            <TemplateDraftNameUpdateHolder>
            {selectedTemplate?.name} - {visitType}
            </TemplateDraftNameUpdateHolder>
             
            {templateDescription && templateDescription.length > 0 && <TemplateDescription>{templateDescription}</TemplateDescription>}
            {lastUpdatedDraft && <TemplateDraftUpdate>Changes last saved {lastUpdatedDraft}</TemplateDraftUpdate>}
          </HeaderHolder>
          
        </Header>

        <DashboardContainer>
          <DashboardCard background={Colors.button_blue_gradient}>
            
            <ColumnContainer style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem icon><StyledLocationIcon /></DashboardItem>
              <DashboardItem>Location</DashboardItem>
            </ColumnContainer>
            
            <ColumnContainer>
            <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{storeItem.store}</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>{storeItem.region}</DashboardItem>
            </ColumnContainer>
            
          </DashboardCard>
          
          <DashboardCard background={Colors.button_blue_gradient}>
          <ColumnContainer style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem icon><StyledUserIcon /></DashboardItem>
              <DashboardItem>Manager</DashboardItem>
            </ColumnContainer>

            <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{storeItem.manager ? storeItem.manager : "Not Set"}</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>Location</DashboardItem>
          </DashboardCard>

          <DashboardCard background={Colors.button_blue_gradient}>
            <ColumnContainer style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem icon><StyledCalendarIcon sx={{color: `${Colors.dark_grey}`}} /></DashboardItem>
              <DashboardItem>Started</DashboardItem>
            </ColumnContainer>

            <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{moment(visitDate, "DD-MM-YYYY HH:mm").format("DD MMMM YYYY")}</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>{moment(visitDate, "DD-MM-YYYY HH:mm").format("HH:mm")}</DashboardItem>
          </DashboardCard>

          <DashboardCard background={Colors.button_blue_gradient}>
          <ColumnContainer style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem icon><StyledVisitIcon sx={{color: `${Colors.dark_grey}`}} /></DashboardItem>
              <DashboardItem>Previous Visit</DashboardItem>
            </ColumnContainer>
            {lastVisit ?
            <ColumnContainer>
             <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{lastVisit.data.date}</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>{lastVisit.data.score}%</DashboardItem>
            </ColumnContainer> :
            <ColumnContainer>
              <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>None</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}></DashboardItem>
            </ColumnContainer>}
           
          </DashboardCard>
      </DashboardContainer>
        


      <PageContainer empty_shadow={true} style={{'padding': '0px'}}>
      <Loader show={showLoading} loaderMessage={loaderMessage} />
      <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
            <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
              {barMessage.message}
            </Alert>
      </Snackbar>

      <ErrorDialog
            open={showErrorDialog} setOpen={setShowErrorDialog}
            title={errorContent.title}
            content={errorContent.content} 
            extra_content={errorContent.extra_content}
            />

      <DeleteFileDialog
            open={showDeleteFileDialog} 
            setOpen={setShowDeleteFileDialog}
            title="Delete File"
            content="Are you sure you want to delete this file?"
            selectedFile={selectedFile}
            action={handleRemoveFile} />

      <AlertDialog
            open={showOutstandingActionsAlert}
            setOpen={setShowOutstandingActionsAlert}
            title="Outstanding Actions"
            content={`There are ${storeItem.actions_outstanding} outstanding actions from previous visits in this store`}
            extra_content="Would you like to action them before you start your visit?"
            action={handleActionScreen} />



      {showCompleteDialog &&
      <StoreVisitCompleteDialog
        title={`${selectedTemplate.name} ${visitType.toLowerCase()} completed in ${selectedStore.name}`}
        content=
        {visitScorable ? 
        `${visitType} score is ${visitScore}%. Completed in ${visitDuration}` :
        `${visitType} completed in ${visitDuration}.`}
        open={showCompleteDialog}
        newReport={true}
        storeID={storeDetails.rootID}
        setPDFLink={setPDFLink}
        showLoader={showCompleteDialog && showLoading}
        setShowLoader={setShowLoading}
        template={selectedTemplate.name}
        actionsOutstanding={actionsOutstanding}
        store={selectedStore.name}
        visitID={visitID}
        region={selectedStore.region}
        scorable={visitScorable}
        duration={visitDuration}
        manager={storeItem.manager.length > 0 ? storeItem.manager : "Not set"}
        score={visitScore}
        visitType={visitType}
        visitStatus={defaultStatusClosed ? "Closed" : "Open"}
        visitDate={visitDate}
        visitEndDate={visitEndDate ? visitEndDate : moment().format("DD-MM-YYYY HH:mm")}
        visitClosedDate={defaultStatusClosed ? moment().format("DD-MM-YYYY HH:mm") :""}
        user={storeDetails.user.name}
        sections={sections}
        signature_required={signatureRequired}
        signature_link={signatureUrlLink}
        action={handleCloseCompleteDialog} />}

        <TemplateFormFieldContainer>
          {sections && sections.filter(hideSections => !hideSections?.showOnComplete).map((section, i) => {

              // if (!visibleSections.includes(i)) {
              //   // This is a placeholder; you can return null or a loading spinner, or even leave it empty.
              //   return <div key={i} ref={assignRef(i)} style={{ height: '0px' }}></div>;
              // }
            
              return <Section 
                key={section.section_id}
                
                sectionProp={section}
                setFileSizeError={setFileSizeError}
                lastVisit={lastVisit}
                users={users}
                permissions={storeDetails.permissions}
                currentUser={storeDetails.user}
                fieldError={showFieldError}
                updateSection={handleUpdateSection} />
            
          })}
        </TemplateFormFieldContainer>
        {timedVisit && 
        <TemplateCountdownContainer>
          {timerProgress > 0 ? `${timerProgress} minutes to complete` : "Time is up"} 
        </TemplateCountdownContainer>}

        {signatureRequired &&
        <StyledSignatureHolder>
          <TemplateFormFieldSignatureLabel>
            Sign below to complete this visit
            <StyledClosedIcon style={{'marginLeft': '5px'}} onClick={handleClearSignature} fontSize="large" />
          </TemplateFormFieldSignatureLabel>
          
          <Signature ref={signatureRef} />
          
          {/* <Signature ref={signatureRef} signatureData={signatureData} onSignatureDrawn={handleSignatureDrawn} /> */}
        </StyledSignatureHolder>}

      </PageContainer>

      <TemplateFormButtonStack align="right">
      {runningDraft && <Button highlighted onClick={handleDeleteDraft}><StyledDeleteIcon />Delete Draft</Button>}
      <Button disabled={!isOnline} color={Colors.green} onClick={handleSaveStoreVisit}><StyledUpdateIcon />Complete Visit</Button>
      </TemplateFormButtonStack>

      
      </PageHolder>
      
    )
}

export default StorevisitForm