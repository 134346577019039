import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Colors } from './styled/styled.container';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const options = [
'Location Performance', 
'Location Section Performance', 
'Section Performance Comparison',
'Quantity Analysis', 
'No. Visits', 
'Outstanding Actions',
'Field Analysis',
'Template Analysis',
"Frequency Analysis"
];

export default function ReportButtonSelection(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const {selectedReport, setSelectedReport} = props;

  const handleClick = (event) => {
    //console.log("Clicked " + event.target.value);
    const index = options.indexOf(event.target.value)
    setSelectedReport(index)
    //console.info(`You clicked ${options[selectedIndex]}`);
    //setSelectedReport(selectedIndex)
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>

      <FormControl fullWidth  >
        <Select
          sx={{borderRadius: '5px'}}
          value={options[selectedReport]}
          onChange={(e) => handleClick(e)}
        >
          {options.map((option, i) => {
            return(
              <MenuItem value={option} key={i}>{option}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

    </React.Fragment>
  );
}