import React, { useContext } from 'react'
import { DashboardEmptyContainer, DashboardEmptyContainerText, DashboardEmptyContainerTitle } from './styled/styled.dashboard'
import HomeAssist from './HomeAssist'
import EmptyScreen from './EmptyScreen'
import AuthContext from './AuthContext'
import HelloImg from "./imgs/hello_img.png"
import PerformanceImg from "./imgs/performance_img.png"

function HelloScreen() {
    const {storeDetails} = useContext(AuthContext);

  return (
    <>
    <DashboardEmptyContainer visible={true}>
              <DashboardEmptyContainerTitle>Welcome to Storecall, {storeDetails.user.name}</DashboardEmptyContainerTitle>
              <DashboardEmptyContainerText>To get started, just follow the steps below</DashboardEmptyContainerText>
              <HomeAssist stores={storeDetails.stores && storeDetails.stores.length > 0} templates={ storeDetails.templates && storeDetails.templates.length > 0} />
            
    </DashboardEmptyContainer>
    {/* <EmptyScreen visible
        title="How does it work?"
        imgSrc={HelloImg}
        contents={"Designed so you can run template based checks against locations, and assess each locations performance."}
    />

    <EmptyScreen visible
        title="Why do this?"
        imgSrc={PerformanceImg}
        contents={"Using manual checks and other forms of non standardized assessments leads to inefficiencies, making your organisation more difficult to operate."}
    /> */}
    
    </>
  )
}

export default HelloScreen