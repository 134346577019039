import styled from "styled-components";
import { Colors } from "./styled.container";
import {IoIosCloseCircle} from "react-icons/io"

export const FilePreviewContainer = styled.div`
    width: 100%;

    margin-top: 10px;
    
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    
`

export const FilePreviewContainerHeading = styled.p`
    width: 100%;
    font-size: 0.7rem;
    font-weight: 600;
    margin-bottom: 10px;
`

export const FileDetails = styled.div`
    display: flex;

    flex-direction: column;
    padding-top: 10px;
    
`

export const FileItem = styled.div`
  position: relative;
  width: 100px;
  background-color: ${Colors.grey_shadow};
  height: 100px;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;

  /* Add a dark grey overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  /* Fade in the overlay on hover */
  &:hover::before {
    ${({ user }) =>
      user &&
      `
        opacity: 1;
      `}
  }

  /* Add the text to the overlay */
  &:hover::after {
  content: "";
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 0.6rem;
  z-index: 2;
  white-space: pre-line;
  ${({ user, date, size }) => user && `
    content: '${user} on ${date} (${size} mb)';
  `}
}

  /* Add the rest of the styling */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.card_background};
  font-size: 0.7rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  //box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

export const FileImageContainer = styled.div`
    display: flex;
    width: 100%;
    border-radius: 5px;
    
    height: 100%;
    flex-direction: column;
    flex: 1;
`

export const FilePreviewImage = styled.img`
    width: 100%;
    border-radius: 5px;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
`

export const FileItemDescription = styled.p`
    font-size: 0.7rem;
    font-weight: 600;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;

    ${({newFile}) => newFile && `
        
        color: ${Colors.highlight};
    `}
`

export const FileItemText = styled.p`
    font-size: 0.6rem;
    text-align: center;
    font-weight: 500;

    width: 100%;
    
    ${({largeFile}) => largeFile && `
        color: ${Colors.highlight};
        font-weight: 700;
    `}
`

export const FileItemComment = styled.input`
    font-size: 0.7rem;
    padding: 5px 0px;
    font-weight: 500;
    text-align: center;
    border-bottom-left-radius: 10px;
    //box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    //box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-bottom-right-radius: 10px;
    background-color: ${Colors.blue};
    outline: 0;
    width: 100%;
    height: 25px;
    display: flex;
    position: absolute;
    bottom: 0;
    z-index: 2;
    
    color: ${Colors.white};
    border: 0;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${Colors.white};
    }
    :-ms-input-placeholder {
     color: white;
    }

`

export const FileRemoveIcon = styled(IoIosCloseCircle)`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    margin-right: -7px;
    margin-top: -8px;
    color: ${Colors.blue};

    background-color: ${Colors.white};
    border-radius: 50%;
    
    
    right: 0;
    top: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${Colors.dark_grey};
    }
`
