import React, { useContext, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, CardContainer, Card, NewButton, EmptyContainer, EmptyContainerText, PageHolder, Button, StyledStoreIcon, StyledRegionIcon, EmptyContainerHolder, EmptyContainerImage, Colors, HeaderHolder } from './styled/styled.container'
import { db } from "./firebase";
import {collection, onSnapshot, query, doc, getDocs} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { where, getDoc} from 'firebase/firestore';
import { TemplateFormFieldRow, TemplateFormFieldButton, TemplateDescription } from './styled/styled.templateform';
import Loader from './Loader';
import AuthContext from './AuthContext';
import RegionImg from "./imgs/region.png"
import LocationImg from "./imgs/location_img.png"
import EmptyScreen from './EmptyScreen';
import Header from './Header';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const columns = [
  { field: "cloud_id", headerName: "ID", hide: true},
  { field: 'name', headerName: 'Location', minWidth: 150, flex: 1},
  { field: 'region', headerName: 'Location Group', minWidth: 120, flex: 1},
  { field: 'sub_region', headerName: 'Sub Group', minWidth: 120, flex: 1},
  {
    field: "manager_name",
    headerName: "Manager",
    minWidth: 100,
    flex: 1
  },
  {
    field: 'store_email',
    headerName: 'Email',
    minWidth: 100,
    flex: 1
  }
  
];

function StoresView(props) {

  
  const {storeDetails} = useContext(AuthContext)
  const [stores, setStores] = useState(storeDetails.stores || []);
  const [regions, setRegions] = useState(storeDetails.regions || []);
  const [showLoading, setShowLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const navigate = useNavigate();

  useEffect(() => {
  
    if(storeDetails.stores) {
       //getStores();

       console.log("Stores", storeDetails.stores)
       
       setStores(storeDetails.stores);
       //getRegions();
    }
    }, [storeDetails.stores])

  // useEffect(() => {
  //   setStores(prevState => prevState.map((store) => {
  //     return {...store, region: regions.find(region => region.cloud_id === store.region_id).name}
  //   }))
  // }, [regions])

  const handleRowClick = (params) => {
      if(storeDetails.permissions.stores.edit)
        navigate("/app/locations/modify/" + params.row.cloud_id);
  };


  return (
    <PageHolder>
      <Header>
        
        <HeaderHolder>
        Manage Your Locations
        <TemplateDescription>Select to manage a location or create something new.</TemplateDescription>
        </HeaderHolder>
        
      </Header>
      <PageContainer empty_shadow={true} style={{'padding': '0px'}}>
      <Loader show={showLoading} />

      {stores.length === 0 &&
          <EmptyScreen 
          visible={true} 
          imgSrc={LocationImg}
          title="Let's start creating locations" 
          contents={"Once you create a location, you can run visits using any template and start tracking its performance."} />
        }
      
      {stores.length > 0 &&
        <TableContainer style={{padding: '0px', 'marginBottom': '0px'}}>
        <DataGrid
            autoHeight
            sx={{'border': '0'}}
            
            rows={stores}
            columns={columns}
            onRowClick={handleRowClick}
            getRowId={(row) => row.cloud_id}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
        </TableContainer>
      }

    </PageContainer>

    {storeDetails.permissions.stores.add &&
        <TemplateFormFieldRow align="right" style={{'marginTop': '10px'}}>
            <Button color={Colors.green} style={{'marginRight': '10px'}} onClick={() => navigate("/app/locations/groups")}>
              <StyledRegionIcon />
              Location Groups
            </Button>
            <Button onClick={() => navigate("/app/locations/create")}>
              <StyledStoreIcon />
              New Location
            </Button>
        </TemplateFormFieldRow>}
    </PageHolder>
    
    
  )
}

export default StoresView