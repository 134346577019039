import React, { useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PageContainer, SlideContainer, SlideImage, SliderWrapper } from './styled/styled.container';

function StorevisitImages(props) {

    const {previewImages} = props;

    return (
        <PageContainer>
            <SliderWrapper>
            <Carousel showStatus={false} infiniteLoop useKeyboardArrows>
                {previewImages.map((file, i) => {
                    return(
                        <SlideContainer key={i}>
                            <SlideImage src={file} />
                        </SlideContainer>
                    )
                })}
            </Carousel>
            </SliderWrapper>
        </PageContainer>
    )
}

export default StorevisitImages