//import "./index.css"
import { BrowserRouter, Navigate, Routes, Route, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { Colors, Container } from './styled/styled.container';
import TemplateForm from './TemplateForm';
import React from "react";
import { Typography, createTheme, useTheme } from '@mui/material';
import Templates from './Templates';
import Storevisit from './Storevisit';
import Stores from './Stores';
import Home from './Home';
import Login from './Login';
import { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "./firebase";
import { query, collection, getDocs, where, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import Settings from './Settings';
import Reports from './Reports';
import Actions from "./Actions";
import Footer from "./Footer";
import ErrorRoute from "./ErrorRoute";
import Scheduler from "./Scheduler";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { onAuthStateChanged, updateProfile } from "firebase/auth";
import AuthContext from './AuthContext';
import OrganisationChoice from './OrganisationChoice';
import Loader from './Loader';
import PrivacyPolicy from './PrivacyPolicy';
import SideDrawer from './SideDrawer';

import { CssBaseline } from '@material-ui/core';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [rootID, setRootID] = useState(() => {
    return localStorage.getItem("store") || undefined
  });
  //const [rootID, setRootID] = useState();
  const [showHome, setShowHome] = useState(false);
  const [showLoading, setShowLoading] = useState(false)
  const [showOrganisations, setShowOrganisations] = useState(false);
  const [storeClaims, setStoreClaims] = useState();

  const [user, loading, error] = useAuthState(auth);
  const [displayName, setDisplayName] = useState("");
  const [showVisitType, setShowVisitType] = useState("All")
  const [organisationActive, setOrganisationActive] = useState(true)
  const [spaceAlert, setSpaceAlert] = useState(false)
  const navigate = useNavigate();


  const [storeDetails, setStoreDetails] = useState();
  const [templateCategories, setTemplateCategories] = useState();



  const authContextValue = {
    storeDetails,
    setStoreDetails: (newDetails) => {
      setStoreDetails(newDetails)
    },
  };

  useEffect(() => {
    if (showOrganisations) setShowLoading(false)
  }, [showOrganisations])

  // useEffect(() => {
  //   if(storeDetails) console.log(storeDetails)
  // }, [storeDetails])

  const handleClose = () => {
    logout()
  };

  const handleSpaceAlertClose = () => {
    setSpaceAlert(false)
  }

  // async function getPermissions(store, userGroup) {

  //   const permissionTable = {};
  //   //console.log(store, userGroup)
  //   const permissionRef = query(collection(db, "stores/" + store + "/permissions/" + userGroup + "/resources"));
  //   //console.log(permissionRef.path)
  //   const permissionDocs = await getDocs(permissionRef);

  //   //console.log(permissionDocs.docs.length);

  //   permissionDocs.docs.forEach(item => {
  //     const permissionItem = item.id;
  //     const permissionData = item.data();
  //     permissionTable[permissionItem] = permissionData;
  //   });

  //   return permissionTable;
  // }

  // const getStores = async (rootID) => {
  //   let storesRef = query(collection(db, "stores/" + rootID + "/stores"));

  //   const storesDocs = await getDocs(storesRef);
  //   const storesElements = await Promise.all(
  //     storesDocs.docs.map(async (item) => {
  //       const storeID = item.id;
  //       const storeData = item.data();

  //       const regionRef = doc(db, "stores/" + rootID + "/regions/" + storeData.region_id);
  //       const regionDoc = await getDoc(regionRef);
  //       const regionData = regionDoc.data();

  //       return {
  //         cloud_id: storeID,
  //         name: storeData.name,
  //         region_id: regionDoc.id,
  //         store_email: storeData.store_email,
  //         region: regionData.name,
  //       };
  //     })
  //   );

  //   return storesElements;
  // };


  async function getPermissions(store, userGroup) {
    const permissionTable = {};
    const permissionRef = query(collection(db, "stores/" + store + "/permissions/" + userGroup + "/resources"));

    const unsubscribe = onSnapshot(permissionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const permissionItem = change.doc.id;
        const permissionData = change.doc.data();

        if (change.type === "added" || change.type === "modified") {
          permissionTable[permissionItem] = permissionData;
        } else if (change.type === "removed") {
          delete permissionTable[permissionItem];
        }
      });

      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        permissions: permissionTable,
      }));
    });

    const initialSnapshot = await getDocs(permissionRef);
    initialSnapshot.docs.forEach((item) => {
      const permissionItem = item.id;
      const permissionData = item.data();
      permissionTable[permissionItem] = permissionData;
    });

    return { unsubscribe, permissionTable };
  }

  const getRegions = async (rootID, user, user_stores) => {

    const regionsRef = query(collection(db, "stores/" + rootID + "/regions"));

    // const regionsDocs = await getDocs(regionsRef);
    // const regionElements = await Promise.all(
    //   regionsDocs.docs.map(async (item) => {
    //     const regionID = item.id;
    //     const regionData = item.data();

    //     return {
    //       ...regionData,
    //       cloud_id: regionID,
    //     };
    //   })
    // );

    const unsubscribe = onSnapshot(regionsRef, async (snapshot) => {
      const updatedRegionsDocs = await getDocs(regionsRef);
      const regionElements = await Promise.all(
        updatedRegionsDocs.docs.map(async (item) => {
          const regionID = item.id;
          const regionData = item.data();

          return {
            ...regionData,
            cloud_id: regionID,
          };
        })
      );



      // const storesRef = query(collection(db, "stores/" + rootID + "/stores"));
      // const storesDocs = await getDocs(storesRef);

      // const storesData = storesDocs.docs.map(store => {
      //   const data = store.data();
      //   return { ...data, cloud_id: store.id }
      // })

      // const updatedStoresRegions = storesData.map(store => {
      //   return { ...store, region: regionElements.find(region => region.cloud_id === store.region_id).name }
      // })


      // //if(!updatedStoresRegions) return;

      // const user_specific = await getUserSpecificStores(user, updatedStoresRegions)


      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        // stores: updatedStoresRegions,
        // user_specific_stores: user_specific,
        regions: regionElements,
      }));

    });

    return { unsubscribe };

  }

  const getSubRegions = async (rootID, user) => {

    const regionsRef = query(collection(db, "stores/" + rootID + "/sub_regions"));

    // const regionsDocs = await getDocs(regionsRef);
    // const regionElements = await Promise.all(
    //   regionsDocs.docs.map(async (item) => {
    //     const regionID = item.id;
    //     const regionData = item.data();

    //     return {
    //       ...regionData,
    //       cloud_id: regionID,
    //     };
    //   })
    // );

    const unsubscribe = onSnapshot(regionsRef, async (snapshot) => {
      const updatedRegionsDocs = await getDocs(regionsRef);
      const regionElements = await Promise.all(
        updatedRegionsDocs.docs.map(async (item) => {
          const regionID = item.id;
          const regionData = item.data();


          return {
            ...regionData,
            cloud_id: regionID,
          };
        })
      );

      // const storesRef = query(collection(db, "stores/" + rootID + "/stores"));
      // const storesDocs = await getDocs(storesRef);

      // const storesData = storesDocs.docs.map(store => {
      //   const data = store.data();
      //   return { ...data, cloud_id: store.id }
      // })

      // const updatedStoresSubRegions = storesData.map(store => {
      //   const sub_region = regionElements.find(region => region.cloud_id === store.sub_region_id)
      //   if (!sub_region) return store;
      //   else return { ...store, sub_region: sub_region.name }
      // })


      //if(!updatedStoresRegions) return;

      //const user_specific = await getUserSpecificStores(user, updatedStoresSubRegions)

      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        // stores: updatedStoresSubRegions,
        // user_specific_stores: user_specific,
        sub_regions: regionElements,
      }));



    });

    return { unsubscribe };

  }

  const getStores = async (rootID, users) => {
    const storesRef = query(collection(db, "stores/" + rootID + "/stores"), where("active", "==", true));

    const storesDocs = await getDocs(storesRef);
    const storesElements = await Promise.all(
      storesDocs.docs.map(async (item) => {
        const storeID = item.id;
        const storeData = item.data();

        const storeManager = storeData.manager;
        let storeManagerName = "";
        if (storeManager && storeManager.length > 0) {
          storeManagerName = users.find(user => user.uid === storeManager).name;

        }

        const regionRef = doc(db, "stores/" + rootID + "/regions/" + storeData.region_id);
        const regionDoc = await getDoc(regionRef);
        const regionData = regionDoc.data();

        const store_sub_region = storeData.sub_region_id
        if (store_sub_region && store_sub_region.length > 0) {
          const sub_region = await getDoc(doc(db, "stores/" + rootID + "/sub_regions/" + store_sub_region));
          storeData.sub_region = sub_region.data().name;
        }
        else {
          storeData.sub_region = ""
        }

        const updatedStore = {
          ...storeData,
          cloud_id: storeID,
          manager_name: storeManagerName,
          name: storeData.name,
          region_id: regionDoc.id,
          store_email: storeData.store_email,
          region: regionData.name,
          sub_region: storeData.sub_region
        }




        return updatedStore;
      })
    );

    const user_specific = await getUserSpecificStores(user, storesElements);

    authContextValue.setStoreDetails((prevStoreDetails) => ({
      ...prevStoreDetails,
      stores: storesElements,
      user_specific_stores: user_specific,
    }));

    const unsubscribe = onSnapshot(storesRef, async (snapshot) => {
      const updatedStoresElements = await Promise.all(
        snapshot.docs.map(async (item) => {
          const storeID = item.id;
          const storeData = item.data();

          // Assuming `users` is already fetched and available in the scope
          const storeManager = storeData.manager;
          let storeManagerName = "";
          if (storeManager && storeManager.length > 0) {
            const user = users.find(user => user.uid === storeManager);
            storeManagerName = user ? user.name : ""; // Ensure user is found before accessing name
          }

          let regionName = "", subRegionName = "";
          try {
            const regionRef = doc(db, `stores/${rootID}/regions/${storeData.region_id}`);
            const regionDoc = await getDoc(regionRef);
            regionName = regionDoc.exists() ? regionDoc.data().name : "";

            if (storeData.sub_region_id && storeData.sub_region_id.length > 0) {
              const subRegionDoc = await getDoc(doc(db, `stores/${rootID}/sub_regions/${storeData.sub_region_id}`));
              subRegionName = subRegionDoc.exists() ? subRegionDoc.data().name : "";
            }
          } catch (error) {
            console.error("Error fetching region or sub-region:", error);
          }

          const storeElement = {
            ...storeData,
            cloud_id: storeID,
            manager_name: storeManagerName,
            region: regionName,
            sub_region: subRegionName,
          };



          return storeElement;
        })
      );

      try {
        const user_specific = await getUserSpecificStores(user, updatedStoresElements);

        authContextValue.setStoreDetails((prevStoreDetails) => ({
          ...prevStoreDetails,
          stores: updatedStoresElements,
          user_specific_stores: user_specific,
        }));
      } catch (error) {
        console.error("Error updating store details:", error);
      }
    });


    return { unsubscribe, storesElements };
  };

  const getUserSpecificStores = (user, storeElements) => {
    let elements;

    if (user.specific_store) {
      elements = storeElements.filter(store => user.specific_store_list.indexOf(store.cloud_id) > -1);

    } else elements = storeElements;

    return elements;
  }


  const getTemplateCategories = async (rootID) => {

    const templatesCollectionRef = collection(db, "stores/" + rootID + "/template_categories");

    const unsubscribe = onSnapshot(templatesCollectionRef, async (snapshot) => {
      const templateElements = snapshot.docs.map((item) => {
        const templateID = item.id;
        const templateData = item.data();

        const templateItem = {
          ...templateData,
          cloud_id: templateID,
        };


        return templateItem;
      });

      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        template_categories: templateElements
      }));
    });

    const initialSnapshot = await getDocs(templatesCollectionRef);
    const initialTemplateElements = initialSnapshot.docs.map((item) => {
      const templateID = item.id;
      const templateData = item.data();

      const templateItem = {
        ...templateData,
        cloud_id: templateID,
      };

      return templateItem;
    });

    return { unsubscribe, template_categories: initialTemplateElements };
  };

  const getTemplates = async (rootID, user) => {
    let templateRef;

    const templatesCollectionRef = collection(db, "stores/" + rootID + "/templates");

    if (user.type === "admin") {
      templateRef = templatesCollectionRef
    } else {
      templateRef = query(templatesCollectionRef, where("permission_users", "array-contains", user.group));
    }

    const unsubscribe = onSnapshot(templateRef, async (snapshot) => {
      //console.log("Running subscribe")
      const templateElements = await Promise.all(
        snapshot.docs.map(async (item) => {
          const templateID = item.id;
          const templateData = item.data();

          const tempCategoryDoc = await getDoc(doc(db, "stores/" + rootID + "/template_categories/" + templateData.category));
          const tempCategoryName = tempCategoryDoc.data().name || "None"

          const templateItem = {
            ...templateData,
            cloud_id: templateID,
            categoryName: tempCategoryName || "None",
            name: templateData.name,
            template_type: templateData.template_type,
          };

          //console.log("Item", templateItem)


          return templateItem;
        }));



      const user_specific = getUserSpecificTemplate(user, templateElements);

      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        templates: templateElements,
        user_specific_templates: user_specific
      }));
    });

    const initialSnapshot = await getDocs(templateRef);
    const initialTemplateElements = await Promise.all(
      initialSnapshot.docs.map(async (item) => {
        const templateID = item.id;
        const templateData = item.data();

        //const tempCategory = (await getDoc (doc(db, "stores/" + rootID + "/template_categories/" + templateData.category))).data().name;
        //console.log(tempCategory);
        const tempCategoryDoc = await getDoc(doc(db, "stores/" + rootID + "/template_categories/" + templateData.category));
        const tempCategoryName = tempCategoryDoc?.data()?.name || "None"

        const templateItem = {
          ...templateData,
          cloud_id: templateID,
          categoryName: tempCategoryName,
          name: templateData.name,
          template_type: templateData.template_type,
        };

        return templateItem;
      }));




    return { unsubscribe, templateElements: initialTemplateElements };
  };

  const getUserSpecificTemplate = (user, templateElements) => {

    if (user.specific_templates) {
      //console.log("Template Element", templateElements);
      const filteredTemplateElements = templateElements.filter(template => user.specific_template_list.indexOf(template.cloud_id) > -1);

      return filteredTemplateElements
    }

    else {
      //console.log(templateElements)
      return templateElements;
    }
  }

  const getUserDetails = async (store, user) => {
    const q = query(collection(db, "stores/" + store + "/users"), where("uid", "==", user.uid));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const document = snapshot.docs[0];
      const userData = document ? document.data() : null;

      const groupRef = doc(db, "stores/" + store + "/permissions/" + userData.group);
      const groupDoc = await getDoc(groupRef);
      const groupData = groupDoc.data();
      const groupName = groupData.name;

      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        user: {
          ...userData,
          group_name: groupName,
          admin: userData.type === "admin" || false,
        },
      }));
    });

    const initialSnapshot = await getDocs(q);
    const rawUserData = initialSnapshot.docs[0];
    const initialUser = rawUserData ? rawUserData.data() : null;

    const groupRef = doc(db, "stores/" + store + "/permissions/" + initialUser.group);
    const groupDoc = await getDoc(groupRef);
    const groupData = groupDoc.data();
    const groupID = groupDoc.id;
    const groupName = groupData.name;

    return {
      unsubscribe, groupID: groupID, user: {
        ...initialUser,
        group_name: groupName,
        admin: initialUser.type === "admin" || false
      }
    };
  };


  async function getUsers(rootID) {
    const q = query(collection(db, "stores/" + rootID + "/users"), where("active", "==", true));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const userData = snapshot.docs.map((doc) => doc.data());

      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        users: userData,
      }));
    });

    const initialSnapshot = await getDocs(q);
    const initialUserData = initialSnapshot.docs.map((doc) => doc.data());

    return { unsubscribe, userData: initialUserData };
  }


  async function getUserGroups(store) {
    const userGroups = [];
    const userGroupRef = query(collection(db, "stores/" + store + "/permissions"));

    const unsubscribe = onSnapshot(userGroupRef, (snapshot) => {
      const newGroups = []; // Clear the existing userGroups array

      snapshot.docs.forEach((group) => {
        newGroups.push({
          id: group.id,
          name: group.data().name,
        });
      });

      authContextValue.setStoreDetails((prevStoreDetails) => ({
        ...prevStoreDetails,
        user_groups: newGroups,
      }));
    });

    const initialSnapshot = await getDocs(userGroupRef);
    initialSnapshot.docs.forEach((group) => {
      userGroups.push({
        id: group.id,
        name: group.data().name,
      });
    });

    return { unsubscribe, userGroups };
  }

  async function getUsersGroup(users, currentUser) {
    // user = admin show all
    // user = manager show group members + self
    // user = user show only 
    //console.log("Current", currentUser);

    if (currentUser.type === "admin") return users;
    if (currentUser.type === "user") return [currentUser];
    // user is manager so filter and get users belonging to the group manager id list
    else {
      const filteredList = users.filter(user => currentUser.group_manager.includes(user.group));
      return filteredList
    }
  }

  const fetchUser = async (store) => {
    try {

      if (!user) return;

      const token = await auth.currentUser.getIdTokenResult()

      localStorage.setItem("store", store);

      const organisationRef = doc(db, "stores/" + store);
      const organisationDoc = await getDoc(organisationRef);
      const organisationData = organisationDoc.data();

      if (!organisationData.active) {
        setOrganisationActive(false)
      }
      else {

        const currentTimezone = organisationData?.timezone || "Africa/Johannesburg";

        // new feature so if existing organisations do not have add in
        if (organisationData.timezone === undefined) {
          await updateDoc(doc(db, "stores/" + store), {
            timezone: currentTimezone
          })
        }

        // const q = query(collection(db, "stores/" + store + "/users"), where("uid", "==", user.uid));
        // const doc = await getDocs(q);
        const user_details = (await getUserDetails(store, user));
        const userData = user_details.user; //doc.docs[0].data();

        const userGroupDetails = await getUserGroups(store);
        const userGroups = userGroupDetails.userGroups;

        // const userGroupRef = query(collection(db, "stores/" + store + "/permissions"));
        // const userGroupDocs = await getDocs(userGroupRef);
        // userGroupDocs.docs.forEach(group => userGroups.push({
        // id: group.id,
        // name : group.data().name

        // }));

        const userGroupID = user_details.groupID;

        const allUserDetails = (await getUsers(store))
        const all_users = allUserDetails.userData;
        const filtered_users = await getUsersGroup(all_users, userData);

        //console.log(userGroup)
        const permissionDetails = await getPermissions(store, userGroupID)
        const permissionTable = ((permissionDetails).permissionTable);

        const templateCategories = await getTemplateCategories(store);
        const templateCategoriesElements = templateCategories.template_categories;

        const groupTemplateDetails = (await getTemplates(store, userData))
        const group_templates = groupTemplateDetails.templateElements;
        //console.log("Group", group_templates)

        const user_templates = getUserSpecificTemplate(userData, group_templates);

        const groupStoreDetails = (await getStores(store, all_users))
        const group_stores = groupStoreDetails.storesElements;
        const user_stores = getUserSpecificStores(userData, group_stores);
        const regionsDetails = (await getRegions(store, userData, user_stores));
        const region_elements = regionsDetails.regionElements;
        const subRegionsDetails = (await getSubRegions(store, userData, user_stores));
        const sub_region_elements = subRegionsDetails.regionElements;

        onAuthStateChanged(auth, (user) => {
          if (!user) {
            //console.log("Unsubcribing from snapshots")
            user_details.unsubscribe();
            regionsDetails.unsubscribe();
            subRegionsDetails.unsubscribe();
            userGroupDetails.unsubscribe();
            permissionDetails.unsubscribe();
            groupTemplateDetails.unsubscribe();
            groupStoreDetails.unsubscribe();
            allUserDetails.unsubscribe();
            templateCategories.unsubscribe();

          }


        })

        setDisplayName(userData.name);

        authContextValue.setStoreDetails(prevStoreDetails => ({
          ...prevStoreDetails,
          rootID: store,
          timezone: currentTimezone,
          permissions: permissionTable,
          user_groups: userGroups,
          stores: group_stores,
          user_specific_stores: user_stores,
          regions: region_elements,
          sub_regions: sub_region_elements,
          templates: group_templates,
          template_categories: templateCategoriesElements,
          user_specific_templates: user_templates,
          all_users: all_users,
          filtered_users: filtered_users,
          user: userData
        }))

        await getStoreSpaceUsage()

      }

    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {


    setShowLoading(true)

    if (user) {
      //console.log("Root", rootID)
      const currentStore = localStorage.getItem("store")
      //console.log(currentStore)
      if (!currentStore) {
        setShowLoading(false)
        setShowOrganisations(true)
      }
      else getUser()
    }


  }, [user])


  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");


    async function getOrginizations() {
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      // Access the custom claim
      const stores = idTokenResult.claims.stores;

      setStoreClaims(stores)


      return stores;
    }

    if (user && !rootID) getOrginizations();
    else if (user && rootID) getUser()

  }, [user, loading, rootID]);

  async function getUser() {

    await fetchUser(rootID);
    setShowLoading(false)
  }

  async function getIDToken() {
    const token = await auth.currentUser.getIdToken(true)
    return token;
  }

  async function getStoreSpaceUsage() {
    const token = await getIDToken();
    const encoded_token = encodeURIComponent(token)
    //console.log(rootID);

    const response = await fetch(`https://us-central1-storecall.cloudfunctions.net/api/organisation-space-used?uid=${encoded_token}&storeid=${rootID}`)
    const response_data = await response.json();
    const used_space = response_data.used;
    const max_space = response_data.max_space;
    const percent_used = (used_space / max_space).toFixed(0);


    if (percent_used >= 90) {
      setSpaceAlert(true)
    }

  }

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  return (
    <AuthContext.Provider value={authContextValue}>

      <CssBaseline />

      {spaceAlert && (
        <Snackbar open={true} onClose={handleSpaceAlertClose}>
          <Alert onClose={handleSpaceAlertClose} severity="error" sx={{ width: '100%' }}>
            Your space usage is almost at capacity. Consider upgrading or deleting your old visits.
          </Alert>
        </Snackbar>
      )}

      {!isOnline && (
        <Snackbar open={true}>
          <Alert severity="error" sx={{ width: '100%' }}>
            You are currently offline. Please check your internet connectivity.
          </Alert>
        </Snackbar>
      )}

      {!organisationActive && (
        <Snackbar open={true} autoHideDuration={2500} onClose={handleClose}>
          <Alert severity="error" sx={{ width: '100%' }}>
            This organisation is not currently active.
          </Alert>
        </Snackbar>
      )}

      <Loader show={showLoading} />

      {storeDetails && storeDetails.rootID && user ?
        <SideDrawer storeDetails={storeDetails}>

          <Container>

            <Routes>
              <Route path='*' element={<Home storeDetails={storeDetails} setShowVisitType={setShowVisitType} />} />
              <Route path="/reports/*" element={<Reports storeDetails={storeDetails} />} />
              <Route path="/settings/*" element={<Settings storeDetails={storeDetails} />} />
              <Route path='/templates/*' element={<Templates storeDetails={storeDetails} />} />
              <Route path='/storevisit/*' element={<Storevisit storeDetails={storeDetails} setShowVisitType={setShowVisitType} showVisitType={showVisitType} />} />
              <Route path='/locations/*' element={<Stores storeDetails={storeDetails} />} />
              <Route path="/actions/*" element={<Actions storeDetails={storeDetails} />} />
              <Route path='/scheduler/*' element={<Scheduler storeDetails={storeDetails} />} />

              <Route path='/404/*' element={<ErrorRoute />} />
            </Routes>

          </Container>
          {storeDetails && storeDetails.rootID && user && <Footer />}
        </SideDrawer> : <OrganisationChoice storeClaims={storeClaims} rootID={rootID} setRootID={setRootID} />}




    </AuthContext.Provider>

  );
}

export default App;
