import styled, {css} from "styled-components";
import { Colors } from "./styled.container";
import { TextField} from "@mui/material";
import MuiInput from '@mui/material/Input';

export const TemplateFormTitle = styled.input`
    color: ${Colors.blue};
    display: flex;
    height: fit-content;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: ${props => props.error===true ? "2px solid blue" : ""};
    border-radius: 10px;
    margin-right: 10px;
    padding: 10px 10px;
    font-size: 0.9rem;
`

export const TemplateFormFieldNewFieldButton = styled.button`
    background-color: ${props => props.alert ? Colors.highlight : Colors.blue };
    padding: 15px;
    outline: 0;
    width: 100%;
    border: 0;
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;

    cursor: pointer;
    color: ${Colors.white};
`

export const TemplateFormFieldButton = styled.button`
    background-color: ${props => props.alert ? Colors.highlight : Colors.blue }; 
    padding: 10px 25px;
    outline: none;
    border: none;
    border-radius: 10px;
    width: ${props => props.fullWidth ? "100%" : "fit-content"};
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: 1px;
    transition: all 0.2s ease-in-out;

    cursor: pointer;
    color: ${Colors.white};

    &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    &:disabled {
        background-color: ${Colors.light_grey};
        color: ${Colors.disabled};
    }
`

export const Input = styled(MuiInput)`
  width: 100px;
`;

export const TemplateCountdownContainer = styled.div`
    width: 100%;
    position: sticky;
    bottom: 0;
    left:  0;
    margin-bottom: 20px;
    border: 1px solid white;
    font-size: 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 0px;
    font-weight: 600;
    background-color: ${Colors.highlight};
    color: ${Colors.white};
    display: flex;
    justify-content: center;
`

export const TemplateDescription = styled.div`
    color: ${Colors.dark_grey};
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: none;
    margin-top: 0px;
    
    @media (max-width: 500px) {
        font-size: 0.8rem;
    }

`

export const TemplateDraftNameUpdateHolder = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.2rem;


    @media (max-width: 500px) {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 0px;
    }
`

export const TemplateDraftUpdate = styled.div`
    color: ${Colors.white};
    font-size: 0.7rem;
    font-weight: 500;
    width: fit-content;
    background-color: ${Colors.blue};
    padding: 5px 10px;
    margin-top: 5px;
    margin-left: -5px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    
    @media (max-width: 500px) {
        font-size: 0.7rem;


    }

`

export const TemplateFormFieldContainer = styled.div`
    width: 100%;
    padding: 10px;
    gap: 5px;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    margin-bottom: 0px;
    margin-top: 10px;
`

export const TemplateFilterContainer = styled.div`
    width: 100%;
    padding: 20px 15px;
    border-radius: 5px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-bottom: 4px solid ${Colors.blue};
    flex-direction: column;
    margin: 20px 0px;
`

export const TemplateFormFieldDateContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    
    align-items: center;
    justify-content: space-evenly;
`

export const TemplateSection = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
    
    box-shadow: ${props => props.noShadow ? "" : "rgba(0, 0, 0, 0.04) 0px 3px 5px"};
    //box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 10px;
    //box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
`

export const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 5px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : Colors.white};
  margin: 10px 0px;
  padding: 15px 30px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

`;

export const TemplateFormField = styled.div`

    display: flex;
    flex-direction: column;
    border-radius: 10px;
    position: relative;
    background-color: ${Colors.white};
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    margin-bottom: 10px;

    @media (max-width: 500px) {
        padding: 20px 15px;
    }


`

export const TemplateFormFieldNumber = styled.p`
    font-weight: 700;
    font-size: 1rem;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid black;
    margin-right: 5px;
`

export const TemplateTimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    
`

export const TemplateComments = styled.div`
    background-color: ${Colors.light_grey};
    padding: 15px;
    padding-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    border-radius: 10px;
    margin-bottom: 10px;
`

export const DivHolder = styled.div`
  
  display: flex;
  margin: ${props => props.margin ? props.margin : "0px"};
  align-items: center;
  gap: 5px;
  position: relative;
  border-radius: ${props => props.borderRadius ? props.borderRadius : "0px"};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : `${Colors.transparent}`};
  transition: all 0.2s ease-in-out;

  @media (max-width: 600px) {
    ${props => props.wrap && css`
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    `}
  }
`

export const DivHolderSpaced = styled.div`
  
  display: flex;
  align-items: center;
  width: ${props => props.fullWidth ? '100%' : "100%"};
  position: relative;
  gap: 5px 8px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
  justify-content: space-between;
  border-radius: 20px;
  flex-wrap: wrap;

  @media (max-width: 600px) {

    ${props => props.wrap && css`
      flex-direction: column-reverse;
        align-items: flex-start;
    `}
  }

`

export const TemplateFormFieldRow = styled.div`
    display: flex;
    width: 100%;
    
    flex-direction: ${props => props.column ? "column" : "row"};
    margin-bottom: 15px;
    
    justify-content: ${props => props.align==="right" ? "flex-end" : props.align};
    align-items: ${props => props.align==="right" ? "flex-end" : "center"};

    /* ${({ align_items }) =>
    align_items
      ? `align-items: ${align_items};`
      : 'align-items: center;'} */

    @media (max-width: 600px) {
    ${({wrap}) => wrap && css`
        flex-direction: column;
        align-items: flex-start;
    `}
    }
`

export const TemplateDivHolder = styled.div`


    @media (max-width: 550px) {
        width: 100%;
        margin-top: 20px;
    }
`

export const TemplateCardContainer = styled.div`
    display: flex;
    margin: 10px 0px;
    flex-wrap: wrap;
`

export const TemplateCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 120px;
    height: 90px;
    background-image: linear-gradient(-225deg, #AC32E4 0%, #7918F2 48%, #4801FF 100%);
    border-radius: 5px;
    margin: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    justify-content: center;
    align-items: center;
`

export const TemplateCardHeader = styled.div`
    width: 100%;
    padding: 5px 10px;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 500;
    color: ${Colors.white};
    
`

export const TemplateCardValue = styled.div`
    width: 100%;
    padding: 5px 10px;
    font-size: 1.7rem;
    text-align: center;
    font-weight: 700;
    color: ${props => props.color ? props.color : `${Colors.white}`}
`

export const DivRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`

export const TemplateFormFieldIndexHolder = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${Colors.light_grey};
    display: flex;
    margin-right: 0px;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    color: ${Colors.dark_grey_highlight};
    font-weight: 700;
`

export const TemplateFormFieldActionContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-end;
    justify-content: flex-end;
`

export const TemplateFormButtonStack = styled.div`
    display: flex;
    width: 100%;
    
    flex-direction: ${props => props.column ? "column" : "row"};
    margin-bottom: 20px;
    align-items: center;
    justify-content: ${props => props.align==="right" ? "flex-end" : "flex-start"};
    gap: 10px;
    @media (max-width: 650px) {
        flex-direction: column-reverse;
    }
`

export const TemplateFormFieldQuestion = styled.div`
    border-radius: 5px;
    
    
    gap: 10px;
    width: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const TemplateFormFieldQuestionName = styled.div`
    font-size: 0.9rem;
    font-weight: 500;
    user-select: none;
    color: ${Colors.dark_grey};

    @media (max-width: 600px) {
        font-size: 0.8rem;
    }
`

export const TemplateFormCloseDiv = styled.div`
    position: absolute;
    right: 0;
    margin: 10px;
`

export const TemplateFormFieldScoreContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
    width: fit-content;
    padding: 10px 0px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    justify-content: ${props => props.align==="right" ? "flex-end" : "flex-start"};

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
    }

`

export const TemplateFormFieldWrapper = styled.div`
    
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    justify-content: ${props => props.align === "right" ? "flex-end" : "flex-start"};
`

export const TemplateFormFieldWrapperItem = styled.div`
    
`

export const TemplateFormFieldLabel = styled.label`
    display: inline-block;
    width: auto;
    overflow: hidden;
    align-items: center;
    color: ${Colors.dark_grey_highlight};
    letter-spacing: 1.1px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 15px;


`

export const TemplateFormFieldFilters = styled.p`
    display: inline-block;
    width: auto;
    overflow: hidden;
    align-items: center;
    border: 2px solid ${Colors.blue};
    padding: 7px 20px;
    font-size: 0.8rem;
    cursor: pointer;
    color: ${Colors.dark_grey};
    border-radius: 10px;
    font-weight: 600;
    
    margin-right: 5px;

    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: ${Colors.blue};
        color: white;
    }
`

export const TemplateFormFieldInsertComment = styled.p`
    display: inline-block;
    width: auto;
    overflow: hidden;
    align-items: center;
    font-size: 0.8rem;
    cursor: pointer;
    color: ${Colors.dark_grey};
    border-radius: 10px;
    font-weight: 600;
    
    margin-right: 5px;

`

export const TemplateFormFieldFilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const TemplateFormFieldLink = styled.p`
    display: inline-block;
    width: auto;
    overflow: hidden;
    margin-top: 2px;
    align-items: center;
    cursor: pointer;
    font-size: 0.7rem;
    cursor: pointer;
    color: ${Colors.highlight};
    font-weight: 700;
    margin-right: 15px;

`

export const TemplateFormFieldNewCommentContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`

export const TemplateFormFieldText = styled.input`
    display: flex;
    flex: 1;
    width: 100%;
    border-radius: 5px;
    background-color: inherit;
    border: 0;
    outline: 0;
    margin-left: 1px;
    margin-right: 10px;
    padding: 10px 10px;
    font-size: 0.8rem;
    max-width: 1024px;

    ${({outlined}) => outlined && `
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    `}
    
    ${({ comment_add }) => comment_add && `
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        color: ${Colors.blue};
        font-weight: 500;
        
        font-size: 0.8rem;
        

    `}

    ${({ comment }) => comment && `
        background-color: ${Colors.navbar}; 
        color: ${Colors.blue};
        font-size: 0.7rem;
        ::placeholder { color: ${Colors.white}; };
    `}
`

export const TemplateFormFieldComment = styled.div`
    display: flex;
    flex: 1;
    
    border-radius: 5px;
    border: 0;
    outline: 0;
    margin-left: 1px;
    justify-content: space-between;
    padding: 10px;
    font-size: 0.9rem;
    
    background-color: ${Colors.white};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-left: 4px solid ${Colors.blue};
    font-size: 0.7rem;
    ::placeholder { color: ${Colors.white}; };

`

export const TemplateFormFieldType = styled.select`
    width: fit-content;
    padding: 10px 10px;
    border-radius: 10px;
    margin-right: 10px;
`

export const TemplateFormFieldAssignable = styled.select`
    width: fit-content;
    padding: 5px 10px;
    margin-right: 10px;
`

export const TemplateFormFieldCompleted = styled.select`
    margin: 5px 0px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 0;
    outline: 0;
    padding: 10px 15px;
    margin-right: 10px;
`

export const TemplateFormFieldAllowComment = styled.input`
    padding: 5px;
    margin-right: 5px;
    font-size: 0.8rem;
`

export const TemplateFormFieldFiles = styled.input`
    padding: 10px 20px;
    font-size: 0.8rem;
`

export const TemplateFormErrorContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    background-color: ${Colors.light_grey};
    align-items: center;
    z-index: 2;
    width: 100%;
    height: 100%;
`

export const TemplateFormErrorContainerText = styled.p`
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
    color: ${Colors.dark_grey};
    margin-bottom: 20px;
`
export const TemplateFormButtonContainer = styled.div`
    padding: 10px 10px;
    padding-bottom: 20px;
    display: flex;
    
    margin-top: 10px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    @media (max-width: 550px) {
        flex-direction: column-reverse;
        width: fit-content;
        align-self: flex-end;

    }
`

export const TemplateFormButtonHolder = styled.div`
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`

export const TemplateFormFieldSignatureLabel = styled.div`
    
    align-self: center;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    text-align: center;
    padding: 10px 0px;
    border-radius: 5px;
    width: 100%;
    background: ${Colors.card_background};
    color: ${Colors.dark_grey};
    text-align: center;
    font-size: 1rem;
    font-weight: 600;

    @media (max-width: 700px) {
        font-size: 0.8rem;
    }
    
`

export const TemplateSectionHeader = styled.div`
    display: flex;
    flex-direction: ${props => props.row ? "row" : "column"};

    //box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.3) -6px -2px 16px 0px;
    //box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    //box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    
    font-weight: 700;
    background-color: ${(props) => props.backgroundColor || 'inherit'};
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: ${props => props.backgroundColor ? Colors.white : Colors.dark_grey_highlight};
    
    letter-spacing: 1.1px;
    
    width: 100%;
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border-bottom: 3px solid ${Colors.blue};
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    
    align-items: center;
    justify-content: center;

    .MuiInputBase-root MuiInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-multiline css-yklprf-MuiInputBase-root-MuiInput-root {
        padding: 0px;
    }

    @media (max-width: 600px) {
        font-size: 0.8rem;
        padding: 10px 15px;
    }


`

export const TemplateSectionLastScore = styled.div`
    font-size: 0.8rem;
    font-weight: 500;

    
    padding: 2px 20px;
    border-radius: 10px;
    font-weight: 600;
    text-transform: capitalize;

    @media (max-width: 600px) {
        font-size: 0.7rem;
    }
`