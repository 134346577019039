import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Divider } from '@mui/material';

export default function AlertDialog(props) {
  const {open, setOpen, title, content, extra_content, action, id} = props;

  const handleClose = () => {
    setOpen(false);
  };

  function handleAction() {
    if(id) action(id);
    else action();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
        <Typography>
        {content}
        </Typography>
        <Divider sx={{marginTop: '10px'}} />
        <Typography sx={{'marginTop': '10px', color: "blue"}}>{extra_content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleAction} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}