import React, {useEffect, useState} from 'react';
import { Button, Text } from '../styled/styled.container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from "@mui/material/Dialog"
import { usePDF } from '@react-pdf/renderer';
import { CreatePDF } from '../ReportPDF';
import moment from 'moment';

import Loader from '../Loader';
import CompleteIcon from "../imgs/success.png"
import LogoIcon from "../imgs/logo_blue.png"
import { IconImage } from '../styled/styled.container';
import { TemplateFormButtonStack, TemplateFormCloseDiv, TemplateFormFieldQuestion, TemplateFormFieldRow } from '../styled/styled.templateform';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PublicVisitCompleteDialog(props) {
  const {open, showLoader, setShowLoader, setPDFLink, storeID, visitDate, visitEndDate, user, manager, title, actionsOutstanding, content, 
    template, store, region, visitID, duration, scorable, score, visitType, 
    visitStatus, visitClosedDate, action, sections, signature_required, signature_link, newReport, loaderMessage} = props;
  const dateStoreVisit = moment().format("DD-MM-YYYY HH:mm");
  const [showLoading, setShowLoading] = useState(true);

  const navigate = useNavigate();

  const [instance, updateInstance] = usePDF({ 
    document: <CreatePDF 
      template={template} 
      storeID={storeID}
      store={store}
      region={region} 
      date={dateStoreVisit}
      manager={manager}
      user={user} 
      actionsOutstanding={actionsOutstanding}
      sections={sections}
      signature_required={signature_required}
      signature_link={signature_link}
      scorable={scorable}
      score={score}
      visitType={visitType}
      visitStatus={visitStatus}
      visitDate={visitDate}
      visitEndDate={visitEndDate}
      visitClosedDate={visitClosedDate}
      duration={duration}
      /> });

  const [link, setLink] = useState();

  useEffect(() => {

    updateInstance();
  }, [template, signature_link])

  useEffect(() => {
    async function checkLink() {
      //console.log("Sig ", signature_required)
      //console.log("Sig link", signature_link)
      //console.log((signature_required && (signature_link || false)))
      if(open && !instance.loading && instance.url)
      {
        //console.log("Got show", sections)
        
              //console.log("Sig not requied")
              setLink(instance.url);
              setShowLoading(false);
              if(setPDFLink) setPDFLink(instance.url)
            
      }
    }

    checkLink()
    
  }, [instance])


  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
        
    action()
  }

  function handleClick() {
    
    if(link) navigate("/")
    //action()
  }

  function handleViewReport() {
    if(link) window.open(link, '_blank', 'noopener,noreferrer');
  }


  return (
    <div>
      <Loader show={showLoading} />
      
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        BackdropProps={{
          onClick: () => {},
          onKeyDown: () => {}
        }}
      >

        {!showLoader && <div style={{position: 'relative', 'paddingBottom': '10px'}}>
          <TemplateFormCloseDiv>
            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
          </TemplateFormCloseDiv>

          
          <TemplateFormFieldRow style={{'padding': '10px 10px', paddingBottom: '0px'}}>
          <IconImage src={LogoIcon} style={{'margin': '10px 5px'}} />
          <Text fontSize={"1rem"} fontWeight={"600"}>{loaderMessage.title}</Text>
          
          </TemplateFormFieldRow>
          
          
        
        <DialogContent sx={{'margin': '0px', padding: '10px 25px'}}>
          <TemplateFormFieldQuestion><Text fontSize={"0.9rem"} fontWeight={"400"}>{loaderMessage.content}</Text></TemplateFormFieldQuestion>
            
        </DialogContent>
        <TemplateFormButtonStack align="right" style={{'padding': '0px 15px'}}>
            {/* <Button style={{'margin': '5px'}} disabled={!link } onClick={handleViewReport}>View Report</Button> */}
          
          <Button disabled={!link } onClick={handleClick}>Close</Button>
          
        </TemplateFormButtonStack>
        
        </div>}
       
      </Dialog>
    </div>
  );
}