import { useContext, useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import { debounce } from "lodash";
import { InputAdornment, TextField } from "@mui/material";
import { StyledTextField } from "./styled/styled.container";


export const InputField = ({field, myVal, type, InputProps, inputProps, 
  padding, margin, error, sx, variant, updateHandler, helperText, placeholder, 
  align, multiline, allowEditVisit, colour, size, weight, marginTop, lineHeight, letterSpacing, textTransform, 
  position, section_item_id, i, onFocus, label}) => {
    const [value, setValue] = useState(myVal || "");

  useEffect(() => {
    if(myVal) { 
      setValue(myVal)
    }
  }, [myVal])

  const debouncedRequest = useDebounce(() => {
    
    if(section_item_id && allowEditVisit !== undefined) {
      
      if(i !== undefined) {

        updateHandler(section_item_id, allowEditVisit, i, value)
      }
      else
      {
  
        updateHandler(section_item_id, allowEditVisit, value)
      }
    }
    else if(section_item_id && i !== undefined)
    {

      updateHandler(section_item_id, i, value)
    } else if(section_item_id)
    {

      updateHandler(section_item_id, value)
    } else  
      updateHandler(value)
    //setProfileInfo(prevState => ({...prevState, [field]: value}))
  })

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);

    debouncedRequest();
  }


    return <StyledTextField 
    
    size={size || "medium"}
    fullWidth
    label={label || ""}
    
    
    placeholder={placeholder || ""}
    value={value || ""}
    error={error}
    multiline={multiline || false}
    onFocus={onFocus}
    helperText={helperText}
    onChange={onChange}
    InputProps={InputProps}
    inputProps={inputProps}
    />
}

