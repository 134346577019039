import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import StorevisitReport from './StorevisitReport';
import StorevisitImages from './StorevisitImages';

function StorevisitReportRoute(props) {

    const {storeDetails} = props;
    const [previewImages, setPreviewImages] = useState([]);

  return (
    <Routes>
        <Route path='/' element={<StorevisitReport storeDetails={storeDetails} setPreviewImages={setPreviewImages} />} />
        <Route path='/images' element={<StorevisitImages previewImages={previewImages} />} />
    </Routes>
  )
}

export default StorevisitReportRoute