import React, { useContext } from 'react'
import { FooterContainer, FooterItem } from './styled/styled.footer'
import AuthContext from './AuthContext'

function Footer() {
  const {storeDetails} = useContext(AuthContext);

  return (
    <FooterContainer>
        <FooterItem>Storecall. All Rights Reserved. <a href="/privacy">Privacy Policy</a></FooterItem>
        <FooterItem style={{'marginTop': '3px'}} bold={true}>{storeDetails.rootID} -  {storeDetails.user.name} ({storeDetails.user.type})</FooterItem>
        
    </FooterContainer>
  )
}

export default Footer