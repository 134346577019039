import styled from "styled-components";
import { Colors } from "./styled.container";

export const SignatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const SignatureCanvas = styled.canvas`
  border: 1px solid black;
  width: 100%;
  height: 100px;
  touch-action: none; /* prevent touch events from scrolling the page */
`;

export const SignatureImageContainer = styled.div`
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
    
    min-height: 200px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin-bottom: 20px;

`

export const SignatureImage = styled.img`
    
    width: 100%;
    height: 100%;
    max-width: 600px;
    align-self: center;
    flex: 1;
    object-fit: contain;
`