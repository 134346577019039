import { useState, useEffect, useContext } from 'react';
import AuthContext from '../AuthContext';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import { v4 as uuid } from "uuid";

async function getEventStatus(template, store, start, storeDetails) {
    const date = new Date(start);
    date.setDate(date.getDate() + 1);
    const nextDay = date.toISOString();
    let eventStatus = "waiting";
    if (moment().endOf('day').isAfter(moment(start).endOf("day"))) {
        const visitRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits"),
            where("template_id", "==", template.cloud_id),
            where("store_id", "==", store.cloud_id), where("date", ">=", start),
            where("date", "<", nextDay)
        );
        const visitDocs = await getDocs(visitRef);
        if (visitDocs.docs.length === 0) eventStatus = "missed";
        else eventStatus = "done"

    }

    return eventStatus

}

function getUsersFromGroup(id, storeDetails) {
    if (id === "All") {
        //console.log(storeDetails.filtered_users)
        return storeDetails.filtered_users;
    }
    //console.log("All users", typeof(storeDetails.all_users), storeDetails.all_users)
    const filteredUsers = storeDetails.all_users.filter(user => user.group === id);
    //console.log("Filtered", filteredUsers);
    return filteredUsers;
}

async function getGroupSchedule(selectedGroup, storeDetails, filter) {
    const usersList = getUsersFromGroup(selectedGroup, storeDetails);
    const childEvents = [];
    const parentList = [];

    const weekStartDate = moment().startOf('week').format();
    const weekEndDate = moment().endOf('week').format();

    await Promise.all(usersList.map(async selectedUser => {
        let scheduleItemsRef = "";
        if(filter && filter === "week") {
            scheduleItemsRef = query(collection(db, "stores/" + storeDetails.rootID + "/scheduler/" + selectedUser.uid + "/list"), 
            where("start", ">=", weekStartDate), where("start", "<=", weekEndDate))
            
        }
        else {
            scheduleItemsRef = query(collection(db, "stores/" + storeDetails.rootID + "/scheduler/" + selectedUser.uid + "/list"))
        }

        const scheduleItemDocs = await getDocs(scheduleItemsRef)

        if (scheduleItemDocs.docs.length > 0) {
            await Promise.all(scheduleItemDocs.docs.map(async item => {
                const parentData = item.data();
                parentList.push(parentData)

                const [templateName, storeName] = parentData.title.split('\n');
                const template = {
                    name: templateName,
                    cloud_id: parentData.template_id
                }
                const store = {
                    name: storeName,
                    cloud_id: parentData.store_id
                }

                const weekEndDateEventList = moment().endOf('week').format("YYYY-MM-DDTHH:mm:ss");

                const eventsToAdd = await createEventList(selectedUser, template, store, parentData.id, parentData.start, weekEndDateEventList, parentData.repeatInterval, parentData.missed_dates, storeDetails)

                for (const event of eventsToAdd) {
                    childEvents.push(event);
                }
            }));
        }
    }));

    return { parentList, childEvents };
}

function useGetSchedule(selectedGroup, filter) {
    const [parentEvents, setParentEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const {storeDetails} = useContext(AuthContext)

    useEffect(() => {
        async function fetchData() {
            
            if(!storeDetails.rootID) return;
            setShowLoading(true)
            const { parentList, childEvents } = await getGroupSchedule(selectedGroup, storeDetails, filter);
            
            setParentEvents(parentList);
            setEvents(childEvents);
            setShowLoading(false)
        }
        fetchData();
    }, [selectedGroup, storeDetails]);

    return { parentEvents, events, showLoading };
}

async function createEventList(user, template, store, parentID, start, end, repeatInterval, missed_dates, storeDetails) {
    const eventsToAdd = [];
    let eventStatus = await getEventStatus(template, store, start, storeDetails)

    const endDate = end

    if (repeatInterval !== "None") {
        const currentDate = moment(start);

        while (currentDate.isBefore(endDate)) {

            const currentDateFormatted = moment(currentDate).format('YYYY-MM-DDTHH:mm:ss')

            const newEvent = {
                start: currentDateFormatted,
                end: currentDateFormatted,
                title: `${template.name}\n${store.name}`,
                id: uuid(),
                parentID: parentID,
                extendedProps: {
                    parentID: parentID,
                    type: "visit",
                    template_id: template.cloud_id,
                    store_id: store.cloud_id,
                    status: eventStatus,
                    user_id: user.uid,
                    name: user.name
                }
            }


            //console.log("Missed dates", missed_dates)

            if (missed_dates.length === 0 || missed_dates.findIndex(date => date === currentDateFormatted) === -1)
                eventsToAdd.push(newEvent)

            if (repeatInterval === 'Daily') {
                currentDate.add(1, 'day');
            } else if (repeatInterval === 'Weekly') {
                currentDate.add(1, 'week');
            } else if (repeatInterval === 'Monthly') {
                currentDate.add(1, 'month');
            }
        }

        // setEvents(prevEvents => [...prevEvents, ...eventsToAdd]);

    } else {
        const newEvent = {
            start: start,
            end: start,
            title: `${template.name}\n${store.name}`,
            id: uuid(),
            parentID: parentID,
            extendedProps: {
                parentID: parentID,
                type: "visit",
                template_id: template.cloud_id,
                store_id: store.cloud_id,
                status: eventStatus,
                user_id: user.uid,
                name: user.name
            }
        }
        //console.log("Event", start, eventStatus)
        eventsToAdd.push(newEvent)

    }


    return eventsToAdd
}

export default useGetSchedule;
