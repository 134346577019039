import React from 'react'
import { PrivacyContainer, PrivacyHeadingText, PrivacyListContainer, PrivacyListItem, PrivacyListItemExplanation, PrivacyParagraph, PrivacyTitle, PrivacyTitleContainer } from './styled/styled.privacy'
import { HeroText, LogoText, SplashLogo } from './styled/styled.splash'
import LogoImg from "./imgs/logo.png"
import { Button, Colors, PageContainer } from './styled/styled.container'
import { useNavigate } from 'react-router-dom'

function PrivacyPolicy() {
    const navigate = useNavigate();
  return (

        <PrivacyContainer>
            <PrivacyTitleContainer>
            <SplashLogo src={LogoImg} /><PrivacyTitle>Storecall</PrivacyTitle>
            </PrivacyTitleContainer>

            <PrivacyHeadingText>Privacy Policy</PrivacyHeadingText>
            <PrivacyParagraph>
            Thank you for using Storecall, a web audit app developed by Prolific Solutions. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, store, and protect your data when you use the Storecall app and associated services. By using Storecall, you consent to the practices described in this Privacy Policy.
            </PrivacyParagraph>
            
            <PrivacyListContainer>
                <PrivacyListItem>Information we collect</PrivacyListItem>
                <PrivacyListItemExplanation>Storecall does not collect personal information such as names, addresses, or contact details. However, we may collect certain events and logs, including but not limited to usage patterns, errors, and interactions within the app. This information is collected solely for the purpose of improving the overall performance and experience of our users.</PrivacyListItemExplanation>
                <PrivacyListItem>Data Storage and Security</PrivacyListItem>
                <PrivacyListItemExplanation>Storecall uses Firebase, a cloud-based platform provided by Google, to host all organization data. This includes audit data, configuration settings, and other relevant information related to your organization. Firebase adheres to industry-standard security measures to protect your data from unauthorized access, loss, misuse, or alteration.</PrivacyListItemExplanation>
                <PrivacyListItem>Access Control</PrivacyListItem>
                <PrivacyListItemExplanation>Storecall implements Firestore rules to ensure that only users belonging to a specific organization can access the data associated with that organization. These access control rules are enforced both on the client and server side to prevent unauthorized access. We have implemented strict security measures to protect against unauthorized access or data breaches.</PrivacyListItemExplanation>
                <PrivacyListItem>Prohibited Actions</PrivacyListItem>
                <PrivacyListItemExplanation>Storecall has implemented rules and permissions within the app to prevent users from engaging in prohibited actions. These actions may include unauthorized data access, modifying data beyond user permissions, or any other actions that could compromise the security and privacy of an organization. Users are strictly prohibited from attempting to bypass these security measures.</PrivacyListItemExplanation>
                <PrivacyListItem>Password Protection</PrivacyListItem>
                <PrivacyListItemExplanation>Users are responsible for protecting their account passwords. We strongly recommend using strong and unique passwords and refraining from sharing them with others. Weak passwords and password sharing can compromise the security and privacy of your organization. Storecall will never ask you to provide your password via email or any other means of communication.</PrivacyListItemExplanation>
                <PrivacyListItem>Third-Party Services</PrivacyListItem>
                <PrivacyListItemExplanation>Storecall may integrate with third-party services or websites for the purpose of enhancing the functionality or user experience. These third-party services may have their own privacy policies and data collection practices. We encourage you to review the privacy policies of these third-party services before using them in conjunction with Storecall.</PrivacyListItemExplanation>
                <PrivacyListItem>Data Retention</PrivacyListItem>
                <PrivacyListItemExplanation>Storecall retains the collected event and log data for a limited period, strictly for the purpose of improving the app's performance and user experience. This data is anonymized and aggregated to remove any personally identifiable information.</PrivacyListItemExplanation>
                <PrivacyListItem>User Group Assignment</PrivacyListItem>
                <PrivacyListItemExplanation>Storecall provides administrators of each organization with the ability to assign users to specific groups that align with their permission levels and roles within the organization. It is the responsibility of the organization's administrators to ensure that users are correctly assigned to groups that correspond to their intended access and permissions.</PrivacyListItemExplanation>
                
                <PrivacyListItem>Changes to the Privacy Policy</PrivacyListItem>
                <PrivacyListItemExplanation>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website or within the Storecall app. Your continued use of Storecall after any changes to the Privacy Policy constitutes your acceptance of the revised policy.</PrivacyListItemExplanation>
                <PrivacyListItem>Contact Us</PrivacyListItem>
                <PrivacyListItemExplanation>If you have any questions, concerns, or suggestions regarding this Privacy Policy or Storecall's privacy practices, please contact us at imraan@prolificsolutions.co.za.</PrivacyListItemExplanation>
            

            
            
            </PrivacyListContainer>

            <PrivacyParagraph>Please note that this Privacy Policy solely applies to the Storecall app and associated services. It does not govern the privacy practices of any third-party websites or services that may be linked within the app.</PrivacyParagraph>
            <PrivacyParagraph bold={700}>Storecall will not be liable for any unauthorized access or data breaches resulting from incorrect user group assignments or inadequate access control measures implemented by the organization's administrators.</PrivacyParagraph>
            <PrivacyParagraph bold={700}>By using Storecall, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this Privacy Policy.</PrivacyParagraph>
            <Button onClick={() => navigate(-1)} style={{'margin': '20px 0px'}} color={Colors.blue}>Return home</Button>
            
        </PrivacyContainer>

   
  )
}

export default PrivacyPolicy