import React, {useEffect, useState} from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Button, Text, HeaderTitle, HeaderSubTitle, Card, CardContainer, PageContainer, NewButton, EmptyContainer, EmptyContainerText } from './styled/styled.container'
import { db } from "./firebase";
import {collection, onSnapshot, query, doc} from "firebase/firestore";
import TemplateForm from './TemplateForm';
import TemplatesView from './TemplatesView';
import TemplatesViewCategories from './TemplatesViewCategories';
import TemplatesManagerCategories from './TemplatesManagerCategories';
import TemplatesLibrary from './TemplatesLibrary';
import TemplateLibraryForm from './TemplateLibraryForm';

function Templates(props) {

  
  const {storeDetails} = props;
  const navigate = useNavigate();

  useEffect(() => {

    if(!storeDetails.permissions.templates.view) navigate("/app")

  }, [storeDetails])

  return (
    <>
    <Routes>
      <Route path="/" element={<TemplatesViewCategories />} />
      <Route path='/:id' element={<TemplatesView storeDetails={storeDetails} />} />
      <Route path='/modify/:id' element={<TemplateForm storeDetails={storeDetails} />} />
      <Route path='/new' element={<TemplateForm storeDetails={storeDetails} />} />
      <Route path='/library' element={<TemplatesLibrary storeDetails={storeDetails} />} />
      <Route path='/library/:id' element={<TemplateLibraryForm storeDetails={storeDetails} />} />
      {/* <Route path="/categories" element={<TemplatesManagerCategories />} /> */}
    </Routes>

    </>
  )
}

export default Templates