import React, { useState, useEffect, Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { CardContainer, TableContainer, HeaderSubTitle, HeaderTitle, PageContainer, SelectType, Text, NewButton, StyledClearIcon, Button, RadioSelectRow, PageHolder, StyledAddIcon, StyledNewIcon, StyledFilterIcon, StyledDeleteIcon, HeaderHolder } from './styled/styled.container'
import { TemplateDescription, TemplateFilterContainer, TemplateFormButtonStack, TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFormFieldFilters, TemplateFormFieldLabel, TemplateFormFieldRow, TemplateFormFieldType } from "./styled/styled.templateform";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { auth, db } from "./firebase";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { collection, onSnapshot, query, doc, getDoc, where, getDocs, orderBy, startAfter, limit } from "firebase/firestore";
import { Card, CssBaseline, Typography, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from './Loader';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioSelect from './RadioSelect';
import { RowHighlighted } from './styled/styled.container';
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from './styled/styled.container';
import { TemplateTypes, VisitStatuses } from './styled/styled.container';
import CheckCircle from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Header from './Header';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteReportDialog from './DeleteReportDialog';
import AuthContext from './AuthContext';

const useStyles = makeStyles({
  highValue: {
    color: `${Colors.highlight}`,
    backgroundColor: `${Colors.light_grey}`
  },
  lowValue: {

  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const columns = [
  { field: "cloud_id", headerName: "ID", hide: true },
  {
    field: "status",
    headerName: "Status",
    valueGetter: (params) => {


      const score = params.row.score;
      const actions_outstanding = params.row.actions_outstanding;
      const visit_status = params.row.visit_status;

      if (visit_status === "Open") return "warning"

      if (score === "Not scorable" && actions_outstanding === 0) return "ok"
      else if (actions_outstanding > 0) return "warning"

      return score !== "Not Scorable" && score > 70 ? "ok" : "warning"
    },
    renderCell: (params) => {
      const status = params.value;
      return status === "ok" ? <CheckCircle style={{ color: `${Colors.blue}` }} /> : <WarningIcon style={{ color: `${Colors.highlight}` }} />;
    },
    minWidth: 70,
    flex: 1

  },
  { field: 'store_name', headerName: 'Location', minWidth: 150, flex: 1 },
  {
    field: 'region', headerName: 'Location Group', minWidth: 150, flex: 1
  },
  {
    field: 'sub_region', headerName: 'Sub Group', minWidth: 150, flex: 1
  },
  {
    field: 'template_name', headerName: 'Template', minWidth: 180, flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.green}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },
  },
  {
    field: 'visit_date',
    headerName: 'Date',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.blue}`
      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },

  },
  {
    field: "selected_id_answer", headerName: "Visit ID", minWidth: 150, flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.orange}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },
  },
  {
    field: "score", headerName: "Score",
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }) => value === "Not Scorable" ? "Not Scorable" : `${value}%`,

  },

  {
    field: "actions_outstanding",
    headerName: "Actions",
    minWidth: 80,
    flex: 1,
    renderCell: (params) => {

      let cellColor = "";
      const outstanding = parseInt(params.row.actions_outstanding);
      if (outstanding === 0) cellColor = `${Colors.green}`
      else if (outstanding > 0 && outstanding < 3) cellColor = `${Colors.orange}`
      else if (outstanding >= 3) cellColor = `${Colors.highlight}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {outstanding}
        </div>
      );
    },

  },
  {
    field: "visit_type",
    headerName: "Visit Type",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {

      let cellColor = ""
      const type = params.value;
      if (type === "Log") cellColor = `${Colors.green}`
      else if (type === "Audit") cellColor = `${Colors.blue}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {type}
        </div>
      );
    },
  },
  {
    field: "visit_status",
    headerName: "Status",
    minWidth: 80,
    flex: 1,
    renderCell: (params) => {

      let cellColor = ""
      const type = params.value;
      if (type === "Open") cellColor = `${Colors.green}`
      else if (type === "Closed") cellColor = `${Colors.highlight}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {type}
        </div>
      );
    },
  },
  {
    field: "visit_closed_date",
    headerName: "Closed",
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.blue}`
      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },
  },
  {
    field: "visit_by",
    headerName: "Visit by",
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.green}`
      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },
  }
];

const publicVisitColumns = [
  { field: "cloud_id", headerName: "ID", hide: true },

  {
    field: 'template_name', headerName: 'Template', minWidth: 180, flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.green}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },
  },
  {
    field: 'visit_date',
    headerName: 'Date',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.blue}`
      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },

  },
  {
    field: "selected_id_answer", headerName: "Visit ID", minWidth: 150, flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.orange}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },
  },
  {
    field: "score", headerName: "Score",
    minWidth: 100,
    flex: 1,
    valueFormatter: ({ value }) => value === "Not Scorable" ? "Not Scorable" : `${value}%`,

  },

  {
    field: "visit_type",
    headerName: "Visit Type",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => {

      let cellColor = ""
      const type = params.value;
      if (type === "Log") cellColor = `${Colors.green}`
      else if (type === "Audit") cellColor = `${Colors.blue}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {type}
        </div>
      );
    },
  },
  {
    field: "visit_status",
    headerName: "Status",
    minWidth: 80,
    flex: 1,
    renderCell: (params) => {

      let cellColor = ""
      const type = params.value;
      if (type === "Open") cellColor = `${Colors.green}`
      else if (type === "Closed") cellColor = `${Colors.highlight}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {type}
        </div>
      );
    },
  },
  {
    field: "visit_closed_date",
    headerName: "Closed",
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {

      let cellColor = `${Colors.blue}`
      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },
  },

];

function StorevisitView(props) {
  const { visitFilter, showVisitType, setShowVisitType } = props;
  const { storeDetails } = useContext(AuthContext)
  const [templates, setTemplates] = useState([]);
  const [stores, setStores] = useState(storeDetails.stores)
  const [filters, setFilters] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
  const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [filterSpecificUser, setFilterSpecificUser] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState("All");
  const [selectedVisitStatus, setSelectedVisitStatus] = useState("All");
  const [storeVisits, setStoreVisits] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [originalVisits, setOriginalVisits] = useState([]);
  // holds selected stores and template names only
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const [showDeleteReportDialog, setShowDeleteReportDialog] = useState(false)
  const [showDeleteDraftDialog, setShowDeleteDraftDialog] = useState(false)
  const [gridRefresh, setGridRefresh] = useState(false);
  const [draftGridRefresh, setDraftGridRefresh] = useState(false);
  const [selectedDraftRows, setSelectedDraftRows] = useState([])
  const params = useParams();
  const [isInternalTemplate, setIsInternalTemplate] = useState(true);

  // holds selected store name + cloud id
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [filterVisitOption, setFilterVisitOption] = useState("allVisits");
  const [selectedActionUser, setSelectedActionUser] = useState();
  const [actionUsers, setActionUsers] = useState([]);

  const [draftVisits, setDraftVisits] = useState([])

  const [pageSize, setPageSize] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);

  const navigate = useNavigate();
  const classes = useStyles();
  //const isAllStoresSelected = selectedStores.length === stores.length;
  //const isAllTemplatesSelected = selectedTemplates.length === templates.length;

  useEffect(() => {

    setStoreVisits([]);

    //if(storeDetails.user.type !== "admin") navigate("/templates");
    if (storeDetails.user) {

      getStoresTemplatesInfo();
      resetFilterDates();
    }

  }, [])

  useEffect(() => {
    if (visitFilter) {
      setFilterVisitOption(visitFilter)
    }
  }, [visitFilter])

  async function getStoresTemplatesInfo() {
    setShowLoading(true);
    setSelectedStores([])
    setSelectedTemplates([])

    await getUsers();

    let storeItem = []
    let templateItem = []

    const myStores = storeDetails.stores //await getStores();
    const myTemplates = await getTemplate();

    const chosenTemplate = myTemplates[0];
    if (chosenTemplate.isPublic) {
      setIsInternalTemplate(false);
      getPublicVisits(chosenTemplate);
      return;
    }

    //getDraftVisits(myStores, myTemplates);


    //const visitItems = await getVisits(myStores, myTemplates, [], []);


    if (params.store && params.store !== "all") {

      storeItem = myStores.filter(store => store.cloud_id === params.store)

      setSelectedStores(storeItem)
    }
    if (params.template && params.template !== "all") {

      templateItem = myTemplates.filter(template => template.cloud_id === params.template);

      setSelectedTemplates(templateItem)
    }

    await getVisits(myStores, myTemplates, storeItem, templateItem)

    if (params.actions === "show") {
      setFilters(true)
      setFilterVisitOption("outstandingActions")

    }

    if (showVisitType === "Log") {

      setShowVisitType("All") // reset visit type to all so that when navigate to home and back to visit it does not stick
      setFilters(true)

    }
    else if (showVisitType === "Audit") {

      setShowVisitType("All") // reset visit type to all so that when navigate to home and back to visit it does not stick

      setFilters(true)
    }

  }

  const getTemplate = async () => {
    //console.log(params.template_id);
    const templateRef = await getDoc(doc(db, "stores/" + storeDetails.rootID + "/templates/" + params.template_id));
    if (!templateRef.exists()) {
      navigate("/app/404")
    }
    else {
      const templateData = templateRef.data()

      const user_permissions = templateData.permission_users;
      if (!storeDetails.user.admin && user_permissions.indexOf(storeDetails.user.group) === -1) navigate("/app/404")
      else {

        const templateItem = {
          cloud_id: templateRef.id,
          name: templateData.name,
          description: templateData.description || "",
          template_type: templateData.template_type,
          isPublic: templateData.isPublic,
        }
        setTemplates([templateItem])
        return [templateItem]
      }
    }
  }

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };


  function generateRandomString() {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 10; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }



  async function getIDToken() {
    const token = await auth.currentUser.getIdToken(true)
    return token;
  }

  const handleDeleteSelectedVisits = async () => {
    if (selectedRows.length > 0) {
      setShowDeleteReportDialog(false);
      setShowLoading(true);
      const idToken = await getIDToken();

      const deletePromises = selectedRows.map((visitID) => {
        return fetch(
          'https://us-central1-storecall.cloudfunctions.net/api/delete-report',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              idToken: idToken,
              storeid: storeDetails.rootID,
              visitid: visitID,
            }),
          }
        );
      });

      Promise.all(deletePromises)
        .then(() => {
          setBarMessage({
            type: 'info',
            message: `Visits have been successfully deleted.`,
          });
          setOpenBar(true);
          setShowLoading(false);
          setSelectedRows([]); // clear selected rows after deletion
          getStoresTemplatesInfo();
        })
        .catch((error) => {
          setBarMessage({
            type: 'error',
            message: `Error deleting visits: ${error.message}`,
          });
          setOpenBar(true);
          setShowLoading(false);
        });
    }
  };

  async function getVisits(stores, templates, selectedStores, selectedTemplates, lastDoc) {

    setShowLoading(true)

    let templateIDList = [];
    let storeIDList = [];

    if ((!selectedStores) || (selectedStores.length === 0)) {

      if (selectedStores.length === 0) storeIDList = stores;
      else {
        storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name === store.name) >= 0)).map((value) => {
          return value;
        })
      }

    }

    else {

      storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name === store.name) >= 0)).map((value) => {
        return value;
      })
    }

    setSelectedStoresInfo(storeIDList);

    const dateTo = moment(filterDateTo).add("1", "days").toISOString();
    // added 1 day because when changing to ISO shows days before

    // const totalVisitItems = await Promise.all(templateIDList.map(async template => {
    //   const items = await Promise.all(storeIDList.map(async store => {
    //     let q;

    //     if(lastDoc) {
    //       console.log("Last doc", lastDoc)
    //       q = query(
    //         collection(db, `stores/${storeDetails.rootID}/visits`),
    //         orderBy("date", "desc"),
    //         where("template_id", "==", template.cloud_id),
    //         where("store_id", "==", store.cloud_id),
    //         where("date", ">=", filterDateFrom),
    //         where("date", "<=", dateTo),
    //         startAfter(lastDoc),
    //         limit(1)
    //       );

    //     }
    //     else {
    //       q = query(
    //         collection(db, `stores/${storeDetails.rootID}/visits`),
    //         orderBy("date", "desc"),
    //         where("template_id", "==", template.cloud_id),
    //         where("store_id", "==", store.cloud_id),
    //         where("date", ">=", filterDateFrom),
    //         where("date", "<=", dateTo),
    //         limit(1)
    //       );

    //     }

    //     const visitDocs = await getDocs(q);
    //     if (!visitDocs.empty) {
    //       setLastVisible(visitDocs.docs[visitDocs.docs.length - 1]);
    //     }
    //     const visitItems = await Promise.all(visitDocs.docs.map(async visit => {
    //       const visitItem = await getVisitItem(visit);


    //       return visitItem;
    //     }));

    //     return visitItems;
    //   }));

    //   return items.flat(); // flatten nested arrays
    // }));

    //const flattenedVisitItems = totalVisitItems.flat(); // flatten nested arrays again
    //const sortedVisits = flattenedVisitItems.sort((a, b) => moment(b.visit_date, "DD-MM-YYYY HH:mm").diff(moment(a.visit_date, "DD-MM-YYYY HH:mm")))

    let q;

    if (lastDoc) {

      if (selectedStores.length === 0) {
        console.log("Last doc all stores")
        q = query(
          collection(db, `stores/${storeDetails.rootID}/visits`),
          orderBy("date", "desc"),
          where("template_id", "==", templates[0].cloud_id),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo),
          startAfter(lastDoc),
          limit(10)
        );
      }
      else {
        console.log("Last doc filtered stores")
        q = query(
          collection(db, `stores/${storeDetails.rootID}/visits`),
          orderBy("date", "desc"),
          where("template_id", "==", templates[0].cloud_id),
          where("store_id", "in", storeIDList.map(store => store.cloud_id)),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo),
          startAfter(lastDoc),
          limit(10)
        );
      }
    }
    else {

      if (selectedStores.length === 0) {
        console.log("First run all stores")
        q = query(
          collection(db, `stores/${storeDetails.rootID}/visits`),
          orderBy("date", "desc"),
          where("template_id", "==", templates[0].cloud_id),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo),
          limit(10)
        );

      }
      else {
        console.log("First doc filtered stores")
        q = query(
          collection(db, `stores/${storeDetails.rootID}/visits`),
          orderBy("date", "desc"),
          where("template_id", "==", templates[0].cloud_id),
          where("store_id", "in", storeIDList.map(store => store.cloud_id)),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo),
          limit(10)
        );
      }
    }

    const visitDocs = await getDocs(q);
    if (!visitDocs.empty) {
      setLastVisible(visitDocs.docs[visitDocs.docs.length - 1]);
    }
    const visitItems = await Promise.all(visitDocs.docs.map(async visit => {
      const visitItem = await getVisitItem(visit);
      return visitItem;
    }));

    if (lastDoc) {
      setStoreVisits(preVisits => [...preVisits, ...visitItems])
      setOriginalVisits(prevVisits => [...prevVisits, ...visitItems])
    }
    else {
      setStoreVisits(visitItems)
      setOriginalVisits(visitItems)
    }
    setShowLoading(false)
  }

  async function getPublicVisits(template) {

    setStoreVisits([])
    setOriginalVisits([])
    setShowLoading(true)

    const dateTo = moment(filterDateTo).add("1", "days").toISOString();
    // added 1 day because when changing to ISO shows days before

    const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"),
      orderBy("date", "desc"),
      where("template_id", "==", template.cloud_id),
      where("date", ">=", filterDateFrom),
      where("date", "<=", dateTo)
    );

    const visitDocs = await getDocs(q);
    const visitItems = await Promise.all(visitDocs.docs.map(async visit => {
      const visitItem = await getVisitItem(visit);
      return visitItem;
    }));

    setStoreVisits(visitItems)
    setOriginalVisits(visitItems)

    setShowLoading(false)
  }

  async function getUser(uid) {
    const userRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", uid));
    const userDoc = await getDocs(userRef);
    if (userDoc.docs.length > 0) {
      const userData = userDoc.docs[0].data();
      return userData.name;
    }
    else return "Not found";
  }

  async function getVisitItem(visit) {
    const visitID = visit.id;
    const visitData = visit.data();


    const store_id = visitData.store_id;
    const template_id = visitData.template_id;

    const visitDate = moment(visitData.date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MM-YYYY HH:mm");
    const visitScore = visitData.scorable ? visitData.score : "Not scorable";
    const visit_type = visitData.visit_type;
    const visit_status = visitData.visit_status;
    const actions_total = visitData.actions_total;
    const actions_outstanding = visitData.actions_outstanding;
    const visit_closed_date = visitData.visit_closed_date;
    const visit_by_uid = visitData.user_uid;
    const selected_id_answer = visitData?.selected_id_answer;
    const visit_by = visit.isPublic ? "Public" : await getUser(visit_by_uid);

    const storeRootData = storeDetails.stores.filter(store => store.cloud_id === store_id)[0];
    const storeName = visit.isPublic ? "None" : storeRootData?.name;

    const region_id = storeRootData?.region_id;
    const regionName = storeRootData?.region;
    const subRegionName = storeRootData?.sub_region;

    // const templateRootDoc = await getDoc(templateRootRef)
    // const templateRootData = templateRootDoc.data();
    const templateRootData = storeDetails.templates.filter(template => template.cloud_id === template_id)[0];
    const templateName = templateRootData.name;

    const sectionQueryCollectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visit.id + "/sections"), orderBy("key", "asc"));
    const sectionQueryCollectionDocs = await getDocs(sectionQueryCollectionRef);
    const sectionData = sectionQueryCollectionDocs.docs.map(docData => {
      const data = docData.data();

      return {
        name: data.name,
        score: data.score,
        list: data.list
      }
    })


    const visitItem = {
      cloud_id: visitID,
      visit_date: visitDate,
      store_name: visit.isPublic ? "None" : storeName,
      region: visit.isPublic ? "None" : regionName,
      sub_region: subRegionName,
      sections: sectionData,
      store_id: store_id,
      template_id: template_id,
      template_name: templateName,
      selected_id_answer: selected_id_answer || "",
      score: visitScore,
      visit_type: visit_type,
      visit_status: visit_status,
      visit_closed_date: visit_closed_date,
      actions_total: actions_total,
      actions_outstanding: actions_outstanding,
      visit_by: visit.isPublic ? "Public" : visit_by
    }

    return visitItem;
  }

  async function getUsers() {
    let q = "";
    if (storeDetails.user.admin) {
      // q = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("active", "==", true));
      // const userRef = await getDocs(q);
      // const users = await Promise.all(userRef.docs.map(doc => {

      //     return new Promise((resolve) => {
      //         const docData = doc.data();
      //         resolve(docData)
      //     })
      // }));

      setActionUsers(storeDetails.all_users)

    } else {
      // q = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", storeDetails.user.uid));
      // const userRef = await getDocs(q);
      // const userRefData = userRef.docs[0].data();
      setActionUsers([storeDetails.user])
    }

    setSelectedActionUser(auth.currentUser.uid)
  }

  function handleSearchFilter() {

    getVisits(stores, templates, selectedStores, selectedTemplates);
  }

  const resetFilterDates = () => {
    const lastYear = moment().year() - 1;
    const dateFrom = moment().subtract("6", "months").format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD")

    setFilterDateFrom(dateFrom)
    setFilterDateTo(currentDate);
  }

  const handleRowClick = (params) => {
    //console.log("You clicked " + params.row.store_id + " on " + params.row.visit_date)
    navigate("/app/storevisit/report/" + params.row.cloud_id);
  };

  function handleStartNewStoreVisit() {
    navigate("/app/storevisit/new/" + templates[0].cloud_id);
  }


  const handleStoreChange = (event, values) => {

    //const storeNames = values.map(value => value.name);

    setSelectedStores(values);
  };

  const handleTemplateChange = (event, values) => {

    //const templateNames = values.map(value => value.name);
    setSelectedTemplates(values);
  };

  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };


  function handleFilterActionReports(userUID, bSpecificUser, actionFilter, reset, visit_type, visit_status) {
    //setFilterActions(reset)
    //console.log("Running handleFilterActionReports " + my_visits + " " + reset)
    setSelectedTemplateType(visit_type || "All")
    setSelectedVisitStatus(visit_status || "All")
    setFilterSpecificUser(bSpecificUser)

    if (reset) {
      setFilterVisitOption("allVisits")
      filterVisits(userUID, bSpecificUser, false, reset, visit_type, visit_status);

    } else {

      if (bSpecificUser && actionFilter) setFilterVisitOption("outstandingActionsUser")
      else if (!bSpecificUser && actionFilter) setFilterVisitOption("outstandingActions")

      filterVisits(userUID, bSpecificUser, actionFilter, reset, visit_type, visit_status);
    }



  }

  function filterVisits(userUID, bSpecificUser, actionFilter, reset, visit_type, visit_status) {

    //console.log(originalVisits);
    console.log("Calling filter visits")

    if (reset) {

      if (originalVisits === storeVisits) return;
      let filteredList = [...originalVisits];
      if (visit_type !== "All") filteredList = filteredList.filter(visit => visit.visit_type === visit_type)
      if (visit_status !== "All") filteredList = filteredList.filter(visit => visit.visit_status === visit_status)

      setStoreVisits(filteredList);
    }
    else {

      if (bSpecificUser) {

        let filteredList = [...originalVisits];
        //console.log("Original", filteredList)
        if (visit_type !== "All") filteredList = filteredList.filter(visit => visit.visit_type === visit_type)
        if (visit_status !== "All") filteredList = filteredList.filter(visit => visit.visit_status === visit_status)

        if (actionFilter) {
          //console.log("User uid", userUID)
          filteredList = filteredList.filter(visit => {

            const sectionList = visit.sections.map(section => {
              //console.log("Section", section)
              const items = section.list.filter(item => {
                const actionItems = item.actions.filter(action => action.actionBy === userUID)

                if (actionItems.length > 0) return item;

              })

              if (items.length > 0) {
                //console.log("Item", items)
                return true;
              }
              else return false;
            })

            //console.log("Section list valid", sectionList)

            const checkSections = sectionList.some(item => item === true);
            if (checkSections) return visit;
            //else return undefined

          });

          //console.log("Filtered", filteredList)
        }
        //console.log("Updating to", filteredList);

        setStoreVisits(filteredList)

      } else {

        let filteredList = [...originalVisits];
        if (visit_type !== "All") filteredList = filteredList.filter(visit => visit.visit_type === visit_type)
        if (visit_status !== "All") filteredList = filteredList.filter(visit => visit.visit_status === visit_status)


        if (actionFilter) {
          filteredList = filteredList.filter(visit => visit.actions_outstanding > 0);
        }

        setStoreVisits(filteredList)

      }
    }



  }

  const handleClose = (event, reason) => {

    setOpenBar(false);
    setShowLoading(false)
    setSelectedRows([])
    //if(barMessage.result === "success") navigate("/storevisit")
  };

  // const getRowClassName = (row) => {


  //     if(row.row.visit_type === "Log" && row.row.visit_status === "Open") return classes.highValue;

  //     if(row.row.score === "Not scorable") return classes.lowValue;

  //     const score = parseInt(row.row.score.replace("%", ""))
  //     return (score <= 70 || row.row.actions_outstanding > 0)  ? classes.highValue : classes.lowValue;
  //   };


  return (
    <PageHolder>
      <Header>
        <HeaderHolder>
          {templates[0]?.name} ({templates[0]?.template_type})
          {templates[0]?.description && templates[0]?.description.length > 0 && <TemplateDescription>{templates[0]?.description}</TemplateDescription>}
        </HeaderHolder>

      </Header>

      <TemplateFormFieldRow style={{ 'marginTop': '10px' }} align="right">

        <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>

      </TemplateFormFieldRow>


      <PageContainer style={{ 'padding': '0px' }}>
        <Loader show={showLoading} />
        <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
          <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
            {barMessage.message}
          </Alert>
        </Snackbar>

        <DeleteReportDialog
          open={showDeleteReportDialog}
          setOpen={setShowDeleteReportDialog}
          title="Delete Report"
          content="Are you sure you want to delete these visits?"
          action={handleDeleteSelectedVisits} />


        {filters &&
          <TemplateFilterContainer style={{ marginTop: '0px' }}>
            {stores && stores.length > 0 &&

              <Autocomplete
                multiple
                id="store-tags"

                getOptionLabel={(option) => option.name}
                value={selectedStores}
                isOptionEqualToValue={(option, value) => option.name === value.name}

                filterSelectedOptions

                onChange={handleStoreChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    label="Filter location"
                    placeholder="Add a location"
                  />
                )}
                options={[...stores].sort((a, b) => {


                  const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                  if (groupSort && groupSort === 0) {
                    return sortRegions(a, b);
                  }
                  return groupSort;
                })}
                groupBy={groupByRegion}
              />}

            <TemplateFormFieldRow wrap>
              <TextField
                sx={{ 'margin': '5px' }}
                label="Period from"
                type="date"
                size='small'
                value={filterDateFrom}
                onChange={(e) => setFilterDateFrom(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Period to"
                type="date"
                sx={{ 'margin': '5px' }}
                size='small'
                fullWidth
                value={filterDateTo}
                onChange={(e) => setFilterDateTo(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TemplateFormFieldRow style={{ 'margin': '5px' }}>
                <TextField
                  id="visit-type"
                  select
                  sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
                  size='small'
                  fullWidth
                  label="Visit type"
                  value={selectedTemplateType}
                  onChange={(e) => handleFilterActionReports(selectedActionUser, filterSpecificUser, false, false, e.target.value, selectedVisitStatus)}>
                  <MenuItem style={{ display: 'flex', alignItems: 'center' }} value={"All"}>All</MenuItem>
                  {TemplateTypes.map((type, i) => {
                    return <MenuItem key={i} style={{ display: 'flex', alignItems: 'center' }} value={type}>{type}</MenuItem>
                  })}
                </TextField>

                <TextField
                  id="visit-status"
                  select
                  sx={{ 'marginLeft': '10px' }}
                  size='small'
                  fullWidth
                  label="Visit Status"
                  value={selectedVisitStatus}
                  onChange={(e) => handleFilterActionReports(selectedActionUser, filterSpecificUser, false, false, selectedTemplateType, e.target.value)}>
                  {VisitStatuses.map((type, i) => {
                    return <MenuItem key={i} style={{ display: 'flex', alignItems: 'center' }} value={type}>{type}</MenuItem>
                  })}
                </TextField>

              </TemplateFormFieldRow>



            </TemplateFormFieldRow>

            <TemplateFormButtonStack style={{ 'marginBottom': '5px' }} align="right">
              <Button color={`${Colors.dark_grey_highlight}`} onClick={handleSearchFilter} style={{ margin: '10px 0px' }} >Search</Button>

            </TemplateFormButtonStack>


            <FormControl sx={{ marginTop: '10px', marginLeft: '5px' }}>
              <Typography sx={{ fontSize: '12px' }}>Filter actions</Typography>
              <RadioGroup
                value={filterVisitOption}
                name="radio-buttons-group"
              >
                <FormControlLabel onClick={() => handleFilterActionReports(selectedActionUser, false, true, true, selectedTemplateType, selectedVisitStatus)} value="allVisits" control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>Show all visits</Typography>} />
                <FormControlLabel onClick={() => handleFilterActionReports(selectedActionUser, false, true, false, selectedTemplateType, selectedVisitStatus)} value="outstandingActions" control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>Show visits with outstanding actions</Typography>} />
                <RadioSelectRow>
                  <FormControlLabel onClick={() => handleFilterActionReports(selectedActionUser, true, true, false, selectedTemplateType, selectedVisitStatus)} value="outstandingActionsUser" control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>Show visits with actions for </Typography>} />
                  <RadioSelect
                    handleFilterActionReports={handleFilterActionReports}
                    currentSelection={filterVisitOption}
                    actionUsers={actionUsers}
                    selectedActionUser={selectedActionUser}
                    setSelectedActionUser={setSelectedActionUser} /></RadioSelectRow>
              </RadioGroup>
            </FormControl>

            {/* <FormGroup>
        <FormControlLabel control={<Checkbox checked={filterActions} onChange={(e) => handleFilterActionReports(e.target.checked)} />} label="Filter reports with outstanding actions" />
        </FormGroup> */}

          </TemplateFilterContainer>}

        <Fragment>
          <TableContainer style={{ 'padding': '0px', 'marginBottom': '0px' }}>
            <DataGrid
              autoHeight
              sx={{ 'border': '0' }}
              key={gridRefresh}


              rows={storeVisits}
              //getRowClassName={getRowClassName}
              //rows={filterActions===true ? storeVisits.filter(visit => visit.actions_outstanding > 0) : storeVisits}
              columns={isInternalTemplate ? columns : publicVisitColumns}

              onRowClick={handleRowClick}
              getRowId={(row) => row.cloud_id}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 25, 50]}
              checkboxSelection

              onSelectionModelChange={handleSelectionModelChange}
              checkboxSelectionProps={{
                // add Checkbox component as the render function
                renderCell: (params) => (
                  <Checkbox
                    color="primary"
                    checked={params.value}
                    onChange={(event) => params.api.selectRow(params.id, event.target.checked)}
                  />
                ),
              }}

            />
          </TableContainer>
        </Fragment>

      </PageContainer>
      <TemplateFormButtonStack align="right" style={{ marginTop: '10px', marginBottom: '15px' }}>

        {storeDetails.permissions.visits.delete && selectedRows.length > 0 && <Button highlighted onClick={() => setShowDeleteReportDialog(true)}><StyledDeleteIcon />Delete Selected</Button>}
        <Button onClick={() => getVisits(stores, templates, selectedStores, selectedTemplates, lastVisible)}>Load More</Button>

        {storeDetails.permissions.visits.add && <Button onClick={() => handleStartNewStoreVisit()}><StyledNewIcon />Start visit</Button>}

      </TemplateFormButtonStack>
    </PageHolder>

  )
}

export default StorevisitView