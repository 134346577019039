import React, { useEffect, useState } from 'react'
import { LoginPageContainer, LoginContainer, LoginItemHolder, LoginText, LoginInput, LoginItemContainer, LoginButtonContainer, LoginButton, LoginLink, LoginHeader } from './styled/styled.login'
import { auth, sendPasswordReset} from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { ErrorText, HeaderTitle } from './styled/styled.container';
import { ShowPasswordIcon, HidePasswordIcon } from './styled/styled.login';
import Loader from './Loader';
import {doc, getDoc} from "firebase/firestore";
import { db } from "./firebase";
import { TextField } from '@mui/material';


function ResetPassword() {

  const [email, setEmail] = useState("");
  const [user, loading] = useAuthState(auth);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      setShowLoading(true)
      return;
    }
    if (user) navigate("/app");
  }, [user, loading]);

  useEffect(() => {
    setShowLoading(false);
  }, [error])

  function checkFields() {
    if (email.length === 0 || (email.indexOf("@") === -1)) return false;

    return true;
  }

  async function handlePasswordReset(e) {
    e.preventDefault();
    
    if (!checkFields()) {
      setError("Please fill in all fields before continuing.")
      return;
    }

    setShowLoading(true);

    const result = await sendPasswordReset(email)
    if(result) {
        setError(result.message);
        setShowLoading(false);
    }
    
  }

  return (
    <div style={{'display': 'flex', 'width': '100%', 'height': '100%', 'alignItems': 'center', 'justifyContent': 'center'}}>
    <LoginPageContainer style={{'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'padding': '20px', 'alignSelf': 'center'}}>
      <Loader show={showLoading} />
      <LoginHeader style={{'marginBottom': '20px'}}>Forgot Password</LoginHeader>

      <LoginContainer onSubmit={handlePasswordReset}>
        <LoginItemContainer>
          <LoginItemHolder>
            <TextField fullWidth label='Enter your email' variant='standard'
            
            
            size='small' value={email} 
            onChange={e => setEmail(e.target.value)}></TextField>
          </LoginItemHolder>
        </LoginItemContainer>
        {error && <ErrorText>{error}</ErrorText>}

        <LoginButtonContainer>
          <LoginButton type="submit">Reset Password</LoginButton>
          <LoginLink bold centered onClick={() => navigate("/login")}>Back to login</LoginLink>
        </LoginButtonContainer>

      </LoginContainer>

    </LoginPageContainer>
    </div>
  )
}

export default ResetPassword;