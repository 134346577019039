import React from 'react'
import TickIcon from "./imgs/checkbox.png"
import { AssistantCard, AssistantContainer, AssistantImage, AssistantTitle } from './styled/styled.dashboard'
import { StepsItem, StepsText } from './styled/styled.splash'
import { useNavigate } from 'react-router-dom'
import { Button } from './styled/styled.container'

function HomeAssist({stores, templates}) {
    const navigate = useNavigate();

    function handleStoresClick() {
        navigate("/app/locations")
    }

    function handleTemplatesClick() {
        navigate("/app/templates")
    }

    function handleVisitsClick() {
        navigate("/app/storevisit")
    }

  return (
   <AssistantContainer>
    <StepsItem assist number="1" completed={false} onClick={handleTemplatesClick}>
            <StepsText>Start customizing some of the templates that we created for you.</StepsText>
            <Button fullWidth>Customize</Button>
    </StepsItem>
    <StepsItem assist number="2" completed={false} onClick={handleStoresClick}>
            <StepsText>Create your locations to run your templates against</StepsText>
            <Button fullWidth>Create</Button>
    </StepsItem>
        
    <StepsItem assist number="3" completed={false} onClick={handleVisitsClick}>
            <StepsText>Run a visit using a template and watch your operation grow</StepsText>
            <Button fullWidth>Run</Button>
    </StepsItem>
   </AssistantContainer>
  )
}

export default HomeAssist