import styled from "styled-components";
import { Colors } from "./styled.container";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField } from "@mui/material";

export const DashboardChartHolder = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
`

export const DashboardChartRow = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;


  @media (max-width: 1200px) {
    flex-direction: column;

  }
  
`

export const DashboardContainer = styled.div`
    width: 100%;

    padding: 5px;
    flex-wrap: wrap;
    border-radius: 5px;
    
    display: flex;
    margin-bottom: 10px;
    
`

export const DashboardHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    //background: #396afc;  /* fallback for old browsers */
    //background: -webkit-linear-gradient(to right, #2948ff, #396afc);  /* Chrome 10-25, Safari 5.1-6 */
    //background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding: 10px 10px;

    //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
    width: 100%;
    //border: 1px solid white;

    color: ${Colors.dark_grey};
    
    margin-bottom: ${props => props.expanded === true ? "10px" : '5px'};
    transition: all 0.2s ease-in-out;
    overflow: hidden;
`

export const DashboardHeader = styled.div`
    
    display: flex;
    align-items: center;
    color: ${Colors.dark_blue};
    font-weight: 700;
    font-size: 1.1rem;
    justify-content: space-between;

    @media (max-width: 500px) {
      font-size: 1rem;
    }
`

export const StyledMoreIcon = styled(ExpandMoreIcon)`
  position: relative;
  padding: 2px;

  background-color: ${Colors.white};
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;

  transform-origin: center;

  &:hover {
    transform: scale(1.1);
  }
`;

export const DashboardOptionsContainer = styled.div`

    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 10px;
    background-color: ${Colors.white};
    padding: 10px 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-self: flex-start;
    
`

export const DashboardOptionText = styled.div`
    width: 100%;
    font-size: 0.9rem;
    font-weight: 500;
    color: ${Colors.dark_grey};
    margin-bottom: 5px;
    text-align: left;
`


export const DashboardCard = styled.div`
    padding: 10px 0px;
    flex: 1;
    flex-direction: column;
    border-radius: 10px;
    background-color: ${Colors.white};
    margin: 5px;
    display: flex;
    justify-content: space-between;
    color: ${Colors.dark_blue};
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    min-width: 150px;

    cursor: pointer;

    
    align-items: center;
    transition: all 0.2s ease-in-out;

    @media (max-width: 400px) {
      justify-content: space-between;
      
    }

`

export const DashboardItem = styled.div`


    display: flex;
    align-self: center;

    font-size: ${props => props.figure ? "1.4rem": "0.9rem"};
    font-weight: ${props => props.figure ? "700" : "600"};
  

    @media (max-width: 600px) {
        font-size: ${props => props.figure ? "1.1rem": "0.8rem"};
    }

    @media (max-width: 450px) {
        font-size: ${props => props.figure ? "0.9rem": "0.7rem"};
    }

    ${props => props.icon && `
        background-color: ${Colors.light_grey_blue};
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        padding: 5px;
    `}

`

export const DashboarChartContainer = styled.div`
    margin: 0px 0px;
    background-color: ${Colors.white};
    padding: 30px;
    border-radius: 10px;

    display: flex;

    align-items: center;
    
    margin: 0px 10px;
    margin-bottom: 10px;

    @media (max-width: 600px) {
      padding: 30px 5px;
    }
`

export const StyledAutoCompleteTextField = styled(TextField)`
  border-radius: 10px;
`

export const DashboardRadioGroupContainer = styled.div`
    display: flex;
    margin-top: 5px;
    
`

export const DashboardRadioGroup = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const DashboardRadioLabel = styled.label`
  font-size: 0.7rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DashboardRadioButton = styled.input`
  margin-right: 10px;
  appearance: none;
  cursor: pointer;
  
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 14px;
  font-size: 0.9rem;
  height: 14px;
  position: relative;
  outline: none;

  /* styling for the checked state */
  &:checked {
    background-color: ${Colors.blue_highlight};
    border: 0;
  }

`;

export const DashboardRadioOption = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0px;
`;

export const DashboardEmptyContainer = styled.div`
    width: 100%;
    
    display: ${props => props.visible ? "flex" : "none"};
    flex-direction: column;
    background-color: ${Colors.white};
    align-items: center;
    padding: 30px 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

    margin: 20px 0px;
    margin-top: 0px;
    border-radius: 10px;
    
    color: ${Colors.dark_grey}
`

export const DashboardButton = styled.div`
  background-color: ${Colors.white};
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  width: 50%;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  color: ${Colors.dark_grey};


  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  }
`

export const DashboardEmptyContainerTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 5px;

  @media (max-width: 800px) {
    font-size: 1rem;
  }
`

export const DashboardEmptyContainerText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  max-width: 80%;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: 2px 0px;

  @media (max-width: 800px) {
    font-size: 0.9rem;
  }

`

export const DashboardEmptyContainerImage = styled.img`
  max-width: 200px;
  display: flex;
  align-self: center;
  height: 100%;
  object-fit: contain;
  margin: 30px 0px;
`

export const AssistantContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
  padding: 10px;
`

export const AssistantCard = styled.div`
  border-radius: 10px;
  background-color: ${Colors.white};
  display: flex;
  margin: 10px;
  flex: 1;
  position: relative;
  cursor: pointer;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  min-width: 150px;
  
  min-height: 90px;
  padding: 10px;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(0.97);
  }

`

export const AssistantImage = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  top: -15px;
  margin-bottom: 10px;
  object-fit: contain;
`

export const AssistantTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  max-width: 90px;
  text-align: center;
  color: ${Colors.dark_grey};
  margin-bottom: 15px;
`

