import React, {useEffect} from 'react'
import { Routes, Route } from 'react-router-dom'
import ReportsHome from './ReportsHome';
import { useNavigate } from 'react-router-dom';

function Reports(props) {
    const {storeDetails} = props;
    const navigate = useNavigate()

    useEffect(() => {

      if(!storeDetails.permissions.reports.view) navigate("/app")
  
    }, [storeDetails])

  return (
    <Routes>
        <Route path='/' element={<ReportsHome storeDetails={storeDetails} />} />
    </Routes>
  )
}

export default Reports