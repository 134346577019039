import React, {useEffect, useState} from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Button, Text, HeaderTitle, HeaderSubTitle, Card, CardContainer, PageContainer, NewButton, EmptyContainer, EmptyContainerText, PageHolder, StyledTemplateIcon, HeaderHolder } from './styled/styled.container'
import { db } from "./firebase";
import {collection, onSnapshot, query, doc, where, getDocs} from "firebase/firestore";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { TableContainer } from './styled/styled.container'
import Loader from './Loader';
import { TemplateDescription, TemplateFormFieldRow } from './styled/styled.templateform';
import AuthContext from './AuthContext';
import { useContext } from 'react';
import Header from './Header';
import EmptyScreen from './EmptyScreen';
import EmptyAuditImg from "./imgs/audit_img.png"

function TemplatesLibrary(props) {

  const [templates, setTemplates] = useState();
  const [headerTitle, setHeaderTitle] = useState("");
  const {storeDetails} = useContext(AuthContext)
  const [showLoading, setShowLoading] = useState(false)
  const params = useParams();

  useEffect(() => {

    setShowLoading(true)
    if(storeDetails.rootID.length > 0) {

      const newList = [];

      async function getLibraryTemplates() {
        const libraryRef = query(collection(db, "stores/storecall/templates"));
        const libraryDocs = await getDocs(libraryRef)

        libraryDocs.docs.forEach(doc => {
          const templateData = doc.data();
          const templatesFromCloud = storeDetails.templates.filter(template => !template.store_created);
          //console.log(storeDetails.templates)
          const templateFound = storeDetails.templates.find(template => template.master_template_id === doc.id)
          if(!templateFound || templateFound === -1)
            newList.push({...templateData, cloud_id: doc.id})

        })

        setTemplates(newList)

      }

      setShowLoading(true)
      getLibraryTemplates();
      setShowLoading(false)
    }
  }, [])

  const navigate = useNavigate();

  const columns = [
    { field: "cloud_id", headerName: "ID", hide: true},
    { field: 'name', headerName: 'Template Name', minWidth: 300, flex: 1},
    { field: 'description', headerName: "Description", minWidth: 500, flex: 1},
    { field: 'template_type', headerName: 'Type', minWidth: 150, flex: 1}
  ];

  const handleRowClick = (params) => {
    if(storeDetails.permissions.templates.edit)
      navigate("/app/templates/library/" + params.row.cloud_id);
  };



  return (
    <PageHolder>
      <Header>
        <HeaderHolder>
        Storecall Template Library
        <TemplateDescription>Browse and download from our library of professionally designed templates catering to a wide range of purposes.</TemplateDescription>
        </HeaderHolder>
        
      </Header>
      
    <PageContainer style={{'padding': '0px'}}>
        <Loader show={showLoading} />

        
        {templates && templates.length > 0 &&
        <TableContainer style={{padding: '0px', 'marginBottom': '0px'}}>
        <DataGrid
            autoHeight
            sx={{'border': '0'}}

            rows={templates}
            columns={columns}
            onRowClick={handleRowClick}
            getRowId={(row) => row.cloud_id}
            pageSize={10}
            rowsPerPageOptions={[10]}
        />
        </TableContainer>
      }
      

        {templates && templates.length === 0 &&
          <EmptyScreen
          visible={true} 
          imgSrc={EmptyAuditImg}
          title="Congratulations!"
          contents={"Your template list is up to date with the latest Storecall templates."} />
        }

        
    </PageContainer>

    </PageHolder>
  )
}

export default TemplatesLibrary;