import React, {useState, useEffect, useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { db } from "./firebase";
import { v4 as uuid } from "uuid";
import {collection, onSnapshot, query, doc, getDoc, getDocs, where} from "firebase/firestore";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from "moment"
import Loader from './Loader';
import { TemplateFormFieldRow } from './styled/styled.templateform';
import MenuItem from '@mui/material/MenuItem';
import { Colors } from './styled/styled.container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import AuthContext from './AuthContext';
import { Alert, Snackbar } from '@mui/material';

export default function ActionDialog(props) {
    const priorityLevels = ["Low", "Medium", "High"];

    const {open, setOpen, i, my_uid, report, addAction, updateAction, deleteAction, completeAction, actionInfo, users} = props;
    const [actionDate, setActionDate] = useState(moment().format("YYYY-MM-DD"));
    const [actionText, setActionText] = useState("")
    const [actionPriority, setActionPriority] = useState("Low");
    const [showLoading, setShowLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null)
    const [showFieldError, setShowFieldError] = useState(false)
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {storeDetails} = useContext(AuthContext);
    const [barMessage, setBarMessage] = useState("");
    const [openBar, setOpenBar] = useState(false);

    useEffect(() => {
        if(actionInfo) {
            //console.log("Action", actionInfo)
            const actionBy = actionInfo.actionBy;

            const actionByDate = actionInfo.action_due_date || null;
            const actionPriority = actionInfo.action_priority || "Low"
            const actionText = actionInfo.section_item_name || "";
            if(actionBy) {
                const user = users.filter(user => user.uid === actionBy)[0]
                setSelectedUser(user)
            }
            if(actionByDate) {
                const formattedDate = moment(actionByDate).format("YYYY-MM-DD")
                //console.log(formattedDate)
                setActionDate(formattedDate)
            } 
            if(actionPriority) setActionPriority(actionPriority)
            setActionText(actionText)
        }
    }, [actionInfo, users])

    const sortedUsers = users.filter(user => user.active).sort((a, b) => a.type.localeCompare(b.type));

    const handleClose = () => {
        setOpen(false);
    };

    function checkFieldsFilled() {
        let bCheck = true;

        if(selectedUser === null) bCheck = false;
        if(actionText.length === 0) bCheck = false;

        if(!moment(actionDate, "YYYY-MM-DD", true).isValid()) {
            setBarMessage({
                type: "error",
                message: "Please select a valid action due date."
            })

            setOpenBar(true)
            return false;
        }

        if(!bCheck) setShowFieldError(true)
        return bCheck;
        
    }

    const handleUserChange = (event, values) => {
        setSelectedUser(values)
    }

    function handleCompleteAction() {
        const action = {...actionInfo,
            //section_id: actionInfo.section_id,
            //section_item_id: actionInfo.section_item_id,
            actionBy: selectedUser.uid,
            actionByName: selectedUser.name,
            actionComplete: !actionInfo.actionComplete,
            actionCompleteDate: actionInfo.actionComplete ? "" : moment().format("DD-MM-YYYY"),
            //action_due_date: moment(actionDate).format("DD-MM-YYYY"),
            //action_priority: actionPriority
        }

        //console.log("Info", actionInfo)

        completeAction(actionInfo.section_item_id, actionInfo.index, action);
        setSelectedUser(null)
        setOpen(false)
    }

    function handleEditAction()
    {

        if(!checkFieldsFilled()) return;

        const action = {...actionInfo,

            section_item_name: actionText,
            actionBy: selectedUser.uid,
            actionByName: selectedUser.name,
            actionComplete: false,
            actionCompleteDate: "",
            action_due_date: moment(actionDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ssZ"),
            action_priority: actionPriority,
            action_set_by: my_uid
        }

        updateAction(actionInfo.section_item_id, actionInfo.index, action);
        setSelectedUser(null)
        setOpen(false)
    }

    function handleDeleteAction() {
        deleteAction(actionInfo.section_item_id, actionInfo.index)
        setSelectedUser(null)
        setOpen(false)
    }
    function handleAddAction() {
      if(actionInfo.actionBy) {
          handleEditAction()
          return;
      }

      if(!checkFieldsFilled()) return;

      const action = { 
          section_id: actionInfo.section_id,
          section_item_id: actionInfo.section_item_id,
          section_item_name: actionText,
          actionBy: selectedUser.uid,
          actionByName: selectedUser.name,
          actionComplete: false,
          actionCompleteDate: "",
          action_due_date: moment(actionDate, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ssZ"),
          action_priority: actionPriority,
          action_set_by: my_uid,
          date: moment().format("DD-MM-YYYY")
      }

      setSelectedUser(null);
      addAction(actionInfo.section_item_id, action)
      setOpen(false)
  }

  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

    const sortTemplates = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };

  return (
      <div style={{'margin': '0px 0px'}}>
          <Loader show={showLoading} />

          <Snackbar open={openBar} autoHideDuration={2500} onClose={() => setOpenBar(false)}>
                        <Alert onClose={() => setOpenBar(false)} severity={barMessage.type} sx={{ width: '100%' }}>
                            {barMessage.message}
                        </Alert>
                        
                    </Snackbar>

          <Dialog
              fullWidth
              open={open}
              
              onClose={handleClose}
          >
              <DialogTitle>
                  Action
              </DialogTitle>
              <DialogContent>
                    <TextField
                      sx={{'margin': '10px 0px', 'fontWeight': '600 !important'}}
                      placeholder='What needs to be done?'
                      label={<Typography sx={{color: `${Colors.dark_grey}`, fontSize: '1.2rem', fontWeight: '400'}}>Enter an action</Typography>}
                      variant='outlined'
                      value={actionText}
                      
                      multiline
                      onChange={(e) => setActionText(e.target.value)}
                      fullWidth
                      error={showFieldError && actionText.length === 0}
                      helperText={showFieldError && actionText.length === 0 ? "Enter an action" : ""}
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />
                  <Autocomplete
                      sx={{'margin': '10px 0px'}}
                      id="user-list"
                      size='small'
                      options={sortedUsers}
                      groupBy={(option) => storeDetails.user_groups.find(group => group.id === option.group).name}
                      getOptionLabel={(option) => option.name}
                      value={selectedUser}
                      onChange={handleUserChange}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Action User"
                              error={showFieldError && selectedUser === null}
                              helperText={showFieldError && selectedUser === null ? "Select a user to complete this action" : ""}

                              InputProps={{
                                  ...params.InputProps,
                                  type: 'Action User',
                              }}
                          />
                      )}
                  />
                  <TextField
                      sx={{'margin': '10px 0px'}}
                      label="Due Date"
                      size='small'
                      type="date"
                      value={moment(actionDate).format("YYYY-MM-DD")}
                      onChange={(e) => setActionDate(e.target.value)}
                      fullWidth
                      InputLabelProps={{
                          shrink: true,
                      }}
                  />

                  <TemplateFormFieldRow style={{'marginTop': '20px'}}>
                      <TextField
                          id="priority-list"
                          select
                          size='small'
                          fullWidth
                          label="Priority"
                          value={actionPriority}
                          onChange={(e) => setActionPriority(e.target.value)}
                          variant="outlined"
                      >
                          {priorityLevels.map((option) => (
                              <MenuItem key={option} value={option}>
                                  {option}
                              </MenuItem>
                          ))}
                      </TextField>
                  </TemplateFormFieldRow>
              </DialogContent>
              <DialogActions sx={{ marginBottom: '20px', marginRight: '10px' }}>
                  <Stack sx={{ width: isSmallScreen ? '100%' : 'auto', flexDirection: isSmallScreen ? 'column-reverse' : 'row' }}>
                      {props.actionInfo && props.actionInfo.actionBy && (
                          <Button
                              sx={{
                                  backgroundColor: `${Colors.highlight}`,
                                  width: isSmallScreen ? '100%' : 'auto',
                                  margin: '5px',
                                  marginBottom: isSmallScreen ? '10px' : 0,
                              }}
                              variant="contained"
                              onClick={handleDeleteAction}
                          >
                              Delete
                          </Button>
                      )}
                      {props.actionInfo && props.actionInfo.actionBy && report === true && (
                          <Button
                              sx={{
                                  backgroundColor: `${Colors.green}`,
                                  width: isSmallScreen ? '100%' : 'auto',
                                  margin: '5px',
                                  marginBottom: isSmallScreen ? '10px' : 0,
                              }}
                              variant="contained"
                              onClick={handleCompleteAction}
                          >
                              {props.actionInfo.actionComplete ? 'Undo Complete' : 'Complete'}
                          </Button>
                      )}
                      <Button
                          sx={{
                              backgroundColor: `${Colors.blue}`,
                              width: isSmallScreen ? '100%' : 'auto',
                              margin: '5px',
                              marginBottom: isSmallScreen ? '10px' : 0,
                          }}
                          variant="contained"
                          
                          autoFocus
                          onClick={handleAddAction}
                      >
                          Update
                      </Button>
                      </Stack>
                </DialogActions>
            </Dialog>
        </div>
    );
}