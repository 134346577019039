import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react'
import { StyledAddOptionIcon, AddOptionButton, Button, ActionItemBlockContainer, StyledActionIcon, StyledAlertIcon, ActionNewButton, StyledAddIcon, StyledDeleteIcon, StyledClosedIcon, StyledAddValueIcon, StyledMinusValueIcon, LabelInfo, ActionItemColumn, Text, ActionItemPriorityBubble, StyledTextField } from './styled/styled.container';
import { TemplateFormField, TemplateFormFieldRow, TemplateFormFieldLabel, TemplateFormFieldCompleted, TemplateFormFieldInsertComment, TemplateFormFieldLink, TemplateFormFieldFilesContainer, TemplateSection, TemplateFormFieldComment, TemplateSectionHeader, TemplateCountdownContainer, TemplateFormFieldScoreContainer, TemplateSectionLastScore, TemplateFormFieldQuestion, TemplateFormFieldActionContainer, TemplateDivHolder, TemplateFormFieldWrapper, TemplateFormFieldWrapperItem, TemplateFormFieldIndexHolder, TemplateFormFieldQuestionName } from './styled/styled.templateform';

import FieldRating from './FieldRating';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalculateIcon from '@mui/icons-material/Calculate';
import SmsIcon from '@mui/icons-material/Sms';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FieldTypes, AnswerTypes, FieldTypeObjects, ActionItemContainer, ActionItemBlock, ActionItemHolder } from './styled/styled.container';
import FileUploader from './FileUploader';
import moment from 'moment/moment';
import { Colors } from './styled/styled.container';
import { TextField, InputAdornment, Divider, MenuItem, DateTime, IconButton, Checkbox } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilePreview from './FilePreview';
import { debounce } from 'lodash';

import { Fragment } from 'react';

import AddCircle from '@mui/icons-material/AddCircle';
import ActionDialog from './ActionDialog';
import { InputFieldSelect } from './InputFieldSelect';
import { InputField } from './InputField';
import { InputFieldCount } from './InputFieldCount';


const sectionTheme = createTheme({
  typography: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    myVariant: {
      fontSize: 13,
      fontFamily: [
        'Poppins',
        'sans-serif'
      ].join(','),
    },
  },
});

const Section = forwardRef(({ lastVisit, users, isVisitPublic, currentUser, permissions, updateSection, sectionProp, setFileSizeError, fieldError }, ref) => {

  const [section, setSection] = useState({ ...sectionProp })
  const [currentComments, setCurrentComments] = useState({});
  const dateStoreVisit = moment().format("DD-MM-YYYY");
  const [selectedAction, setSelectedAction] = useState({})
  const [showActionDialog, setShowActionDialog] = useState(false)


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));



  const debouncedUpdateSection = debounce(updateSection, 200);

  useEffect(() => {
    if (section) {

      debouncedUpdateSection(section);
    }
  }, [section]);


  const handleUpdateFieldText = (section_item_id, value) => {

    const updatedSection = section.list.map((item) =>
      item.section_item_id === section_item_id ? { ...item, fieldText: value } : item
    );



    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
    //updateSection({ ...section, list: updatedSection });
  };

  const debouncedhandleUpdateFieldText = handleUpdateFieldText;


  function handleUpdateFieldTemperature(section_item_id, value) {

    //if(!isLetters(value)) return
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, fieldText: value }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
    //updateSection({ ...section, list: updatedSection });
  }

  const debouncedhandleUpdateFieldTemperature = debounce(handleUpdateFieldTemperature, 300);


  function handleAnswerUpdate(section_item_id, answer) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, yesno: answer }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
    //updateSection({ ...section, list: updatedSection });


  }


  function handleRemoveNewFile(section_item_id, i) {
    setSection(prevState => {
      const index = prevState.list.findIndex(item => item.section_item_id === section_item_id);
      if (index === -1) return prevState; // item not found, return previous state
      const updatedItem = {
        ...prevState.list[index],
        newFiles: prevState.list[index].newFiles.filter((_, index) => index !== i)
      };
      const updatedList = [...prevState.list];
      updatedList[index] = updatedItem;
      return { ...prevState, list: updatedList };
    });
  }

  function handleAddFileComment(section_item_id, i, comment) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, newFiles: item.newFiles.map((item, index) => {

            if (index === i) return { ...item, comment: comment }
            else return item;
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }


  function handleDateTimeUpdate(section_item_id, value) {


    const formattedDateTime = moment(value).format("YYYY-MM-DD HH:mm")

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, datetime: formattedDateTime }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


    // setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, yesno: answer } : el)) 
    // ))
  }

  function handleTimeUpdate(section_item_id, value) {


    const formattedTime = moment(value, "HH:mm").format("HH:mm")

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, datetime: formattedTime }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


  }

  function updatedFormFieldSelectionOption(section_item_id, value) {


    const updateList = (prevList) => {
      const updated = prevList.map(item => {
        if (item.section_item_id === section_item_id) {
          return { ...item, selectionOption: value }
        } else {
          return item;
        }
      });


      return updated;
    };

    setSection(prevState => {
      const updatedList = updateList(prevState.list);
      return { ...prevState, list: updatedList }
    });

  }

  const debouncedUpdatedFormFieldSelectionOption = debounce(updatedFormFieldSelectionOption, 50);

  function addFormFieldCount(section_item_id, product) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(item => {
        if (item.section_item_id === section_item_id) {
          const updatedCounts = [
            ...item.counts,
            {
              product: "",
              count: [
                { label: "Value", value: 0 },
                { label: "Value", value: 0 }
              ]
            }
          ];
          return { ...item, counts: updatedCounts };
        } else {
          return item;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  function handleDeleteFormFieldItemCount(section_item_id, item_index) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(sectionItem => {
        if (sectionItem.section_item_id === section_item_id) {
          const updatedItemListComment = sectionItem.counts.filter((item, index) => index !== item_index);
          return { ...sectionItem, counts: updatedItemListComment };
        } else {
          return sectionItem;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  // function updateFormFieldProductCountValue(section_item_id, index, countIndex, newValue) {
  //   const updatedSection = section.list.map(item => {
  //       if(item.section_item_id === section_item_id) {
  //           return {
  //               ...item, 
  //               counts: item.counts.map((product, i) => {
  //                   if(i === index) {
  //                       // Clone the existing count array to avoid direct mutations
  //                       const updatedCount = [...product.count];
  //                       // Update the value for the specified count
  //                       updatedCount[countIndex].value = newValue;
  //                       return {...product, count: updatedCount};
  //                   }
  //                   return product;
  //               })
  //           };
  //       } else {
  //           return item; 
  //       }
  //   });

  //   console.log(updatedSection)

  //   setSection(prevState => {
  //       return {...prevState, list: updatedSection};
  //   });
  // }

  function updateFormFieldProductCountValue(section_item_id, index, countIndex, newValue) {

    if (!newValue) return;



    setSection(prevState => {
      const updatedList = prevState.list.map(item => {
        if (item.section_item_id === section_item_id) {
          const updatedCounts = item.counts.map((product, i) => {
            if (i === index) {
              const updatedCount = product.count.map((c, j) => {
                if (j === countIndex) {
                  return { ...c, value: newValue };
                }
                return c;
              });
              return { ...product, count: updatedCount };
            }
            return product;
          });
          return { ...item, counts: updatedCounts };
        }
        return item;
      });

      return { ...prevState, list: updatedList };
    });
  }

  const debouncedUpdateFormFieldProductCountValue = debounce(updateFormFieldProductCountValue, 1000);


  function updatedFormFieldProductCount(section_item_id, index, countIndex, fieldType, value) {
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item,
          counts: item.counts.map((product, i) => {
            if (i === index) {
              const updatedCount = [...product.count];
              updatedCount[countIndex][fieldType] = value;
              return { ...product, count: updatedCount };
            }
            return product;
          })
        };
      } else {
        return item;
      }
    });

    setSection(prevState => {
      return { ...prevState, list: updatedSection };
    });
  }

  // function updatedFormFieldProductCount(section_item_id, index, value) {

  //   const updatedSection = section.list.map(item => {
  //       if(item.section_item_id === section_item_id) {
  //           return {...item, counts : item.counts.map((product, i) => {
  //               if(i === index) return {...product, count: value}
  //               else return product
  //             })}
  //       } else 
  //       return item; 
  //   })

  //   setSection(prevState => {
  //       return {...prevState, list: updatedSection}
  //   } );

  // }


  const debouncedupdatedFormFieldProductCount = debounce(updatedFormFieldProductCount, 300);


  function updatedFormFieldProductName(section_item_id, index, value) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, counts: item.counts.map((product, i) => {
            if (i === index) return { ...product, product: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  const debouncedupdatedFormFieldProductName = debounce(updatedFormFieldProductName, 300);


  function addFormFieldItemComment(section_item_id, item) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(sectionItem => {
        if (sectionItem.section_item_id === section_item_id) {
          const updatedItemListComment = [
            ...sectionItem.item_list_comment,
            {
              product: "",
              comment: ""
            }
          ];
          return { ...sectionItem, item_list_comment: updatedItemListComment };
        } else {
          return sectionItem;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  function handleDeleteFormFieldItemComment(section_item_id, item_index) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(sectionItem => {
        if (sectionItem.section_item_id === section_item_id) {
          const updatedItemListComment = sectionItem.item_list_comment.filter((item, index) => index !== item_index);
          return { ...sectionItem, item_list_comment: updatedItemListComment };
        } else {
          return sectionItem;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }


  function updatedFormFieldItemCommentProductName(section_item_id, index, value) {


    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_comment: item.item_list_comment.map((product, i) => {
            if (i === index) {

              return { ...product, product: value }
            }
            else return product
          })
        }
      } else
        return item;
    })

    //console.log(updatedSection);

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  const debouncedFormFieldItemCommentProductName = debounce(updatedFormFieldItemCommentProductName, 300);


  function updatedFormFieldItemCommentProductComment(section_item_id, index, value) {

    console.log("Value", value)

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item,
          item_list_comment: item.item_list_comment.map((product, i) => {
            if (i === index) {
              return { ...product, comment: value };
            } else {
              return product;
            }
          }),
        };
      } else {
        return item;
      }
    });


    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  const debouncedFormFieldItemCommentProductComment = debounce(updatedFormFieldItemCommentProductComment, 200);


  // function updatedFormFieldItemCommentProductComment(section_item_id, index, value) {

  //   const updatedSection = section.list.map(item => {
  //       if(item.section_item_id === section_item_id) {
  //           return {...item, item_list_comment : item.item_list_comment.map((product, i) => {
  //               if(i === index) return {...product, comment: value}
  //               else return product
  //             })}
  //       } else 
  //       return item; 
  //   })

  //   setSection(prevState => {
  //       return {...prevState, list: updatedSection}
  //   } );

  // } 

  function addFormFieldItemListCheckbox(section_item_id, item) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(sectionItem => {
        if (sectionItem.section_item_id === section_item_id) {
          const updatedItemListComment = [
            ...sectionItem.item_list_checkbox,
            {
              product: "",
              checked: false,
              comment: "",
            }
          ];
          //console.log(updatedItemListComment)
          return { ...sectionItem, item_list_checbox: updatedItemListComment };
        } else {
          return sectionItem;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  function handleDeleteFormFieldItemListCheckbox(section_item_id, item_index) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(sectionItem => {
        if (sectionItem.section_item_id === section_item_id) {
          const updatedItemListChecbox = sectionItem.item_list_checkbox.filter((item, index) => index !== item_index);
          return { ...sectionItem, item_list_checkbox: updatedItemListChecbox };
        } else {
          return sectionItem;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }


  function updatedFormFieldItemListCheckboxName(section_item_id, index, value) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_checkbox: item.item_list_checkbox.map((product, i) => {
            if (i === index) return { ...product, product: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  const debouncedupdatedFormFieldItemListCheckboxName = debounce(updatedFormFieldItemListCheckboxName, 300);


  function updatedFormFieldItemListChecboxValue(section_item_id, index, value) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_checkbox: item.item_list_checkbox.map((product, i) => {
            if (i === index) return { ...product, checked: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function updatedFormFieldItemListChecboxComment(section_item_id, index, value) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_checkbox: item.item_list_checkbox.map((product, i) => {
            if (i === index) return { ...product, comment: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  const debouncedupdatedFormFieldItemListCheckboxComment = debounce(updatedFormFieldItemListChecboxComment, 300);


  // function handleActionBy(section_item_id, user) {

  //   //console.log(users)
  //   const userIndex = users.findIndex(element => element.uid === user.target.value);
  //   //console.log(users[userIndex])
  //   const userName = users[userIndex].name;

  //   const updatedSection = section.list.map(item => {
  //     if (item.section_item_id === section_item_id) {
  //       return {
  //         ...item,
  //         actionBy: user.target.value === "0" ? 0 : user.target.value,
  //         actionByName: userName
  //       }
  //     } else
  //       return item;
  //   })

  //   setSection(prevState => {
  //     return { ...prevState, list: updatedSection }
  //   });

  // }



  // working
  async function handleFilesUpload(section_item_id, newFiles) {
    const imageExtensions = /\.(gif|jpe?g|png|webp)$/i;
    const updatedSection = section.list.map(async (item) => {
      if (item.section_item_id === section_item_id) {
        const newFilesWithUrls = [];
        for (const newFile of newFiles) {
          if (imageExtensions.test(newFile.name)) {
            const imageUrl = await createPreviewImage(newFile);
            newFilesWithUrls.push({ file: newFile, comment: "", url: imageUrl });
          } else {
            const url = URL.createObjectURL(newFile);
            newFilesWithUrls.push({ file: newFile, comment: "", url: url });
          }
        }
        return { ...item, newFiles: [...item.newFiles, ...newFilesWithUrls] };
      } else {
        return item;
      }
    });
    const updatedSectionItems = await Promise.all(updatedSection);
    setSection((prevState) => {
      const updatedList = prevState.list.map((item, index) => {
        return { ...item, ...updatedSectionItems[index] };
      });
      return { ...prevState, list: updatedList };
    });

  }

  function handleShowElementComments(section_item_id) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(item => {
        if (item.section_item_id === section_item_id) {
          return { ...item, showComments: !item.showComments }
        } else {
          return item;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  function handleDeleteComment(section_item_id, comment_index) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(item => {
        if (item.section_item_id === section_item_id) {
          const updatedComments = item.comments.filter((comment, index) => index !== comment_index);
          return { ...item, comments: updatedComments };
        } else {
          return item;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  const updateComment = (section_item_id, comment) => {
    setCurrentComments({
      ...currentComments,
      [section_item_id]: comment,
    });
  };

  function handleAddComment(section_item_id) {
    const comment = currentComments[section_item_id];
    if (comment.length === 0) return;

    const datedCommentTime = dateStoreVisit + " @ " + moment().format("HH:mm");

    const datedComment = datedCommentTime + " " + currentUser.name + " - " + comment;

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, comments: [...item.comments, datedComment] }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


    setCurrentComments(prevState => {
      return { ...prevState, [section_item_id]: "" }
    })
  }



  function removeFiles(section_item_id) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, newFiles: [] }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function handleRatingUpdate(section_item_id, rating) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, rating: rating }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


    // setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, rating: rating } : el)) 
    // ))
  }

  function getLastVisitScore() {
    const lastVisitItem = lastVisit?.data.sections.filter(lastVisitSection => lastVisitSection.section_id === section.section_id)
    if (lastVisitItem?.length > 0) {

      if (lastVisitItem[0].scorable) return lastVisitItem[0].score + "%"
      else return "Not Scorable"
    }
    else return "Not Found"
  }

  function handleNewActionDialog(section_id, section_item_id) {
    //console.log("Showing action")
    const actionItem = {
      section_id: section_id,
      section_item_id: section_item_id
    }
    setSelectedAction(actionItem)
    setShowActionDialog(true)
  }

  function handleEditActionDialog(section_id, section_item_id, index, action) {

    const actionItem = {
      section_id: section_id,
      section_item_id: section_item_id,
      section_item_name: action.section_item_name,
      actionBy: action.actionBy,
      action_due_date: action.action_due_date,
      action_priority: action.action_priority,
      index: index
    }

    setSelectedAction(actionItem)

    setShowActionDialog(true)
  }

  function handleAddAction(section_item_id, actionInfo) {

    const sectionItemActionUserExists = section.list.filter(item => item.section_item_id === section_item_id)[0]
      .actions
      .filter(action => action.actionBy === actionInfo.actionBy)

    if (sectionItemActionUserExists && sectionItemActionUserExists.length > 0) return;

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, actions: [...item.actions, actionInfo] }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  function handleEditAction(section_item_id, index, actionInfo) {
    //console.log(actionInfo)
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, actions: item.actions.map((action, i) => {
            if (i === index) return actionInfo
            else return action
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  function handleDeleteAction(section_item_id, index) {
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, actions: item.actions.filter((action, i) => i !== index) }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  const handleWheel = (e) => {
    console.log("Handling wheel")
    e.preventDefault(); // Prevent the default scroll behavior
  };

  function createPreviewImage(file) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = 200;
        const MAX_HEIGHT = 200;
        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, width, height);

        const previewUrl = canvas.toDataURL(file.type);
        resolve(previewUrl);
      };
      image.onerror = reject;
      image.src = URL.createObjectURL(file);
    });
  }

  return (
    <TemplateSection ref={ref} key={section.section_id} noShadow={true}>

      {showActionDialog && !isVisitPublic && <ActionDialog
        open={showActionDialog}
        setOpen={setShowActionDialog}
        addAction={handleAddAction}
        updateAction={handleEditAction}
        deleteAction={handleDeleteAction}
        actionInfo={selectedAction}
        my_uid={currentUser.uid}
        users={users} />}

      <TemplateSectionHeader>
        {section.name}
        {!isVisitPublic ?
          lastVisit && lastVisit.data && lastVisit.data.scorable &&
          <TemplateSectionLastScore>
            {`Previous Score: ${getLastVisitScore()}`}

          </TemplateSectionLastScore>
          : null}
      </TemplateSectionHeader>

      {section.list.map((element, itemIndex) => {



        return (
          <TemplateFormField style={{ 'paddingBottom': '5px' }} key={element.section_item_id}>

            <TemplateFormFieldRow>
              <TemplateFormFieldQuestion>
                
                <TemplateFormFieldQuestionName>{itemIndex+1}. {element.name}</TemplateFormFieldQuestionName>
                {element.mandatory && <LabelInfo backgroundColor={Colors.blue}>Mandatory</LabelInfo>}

              </TemplateFormFieldQuestion>


            </TemplateFormFieldRow>


            {element.type === FieldTypeObjects.Comment &&

              <TemplateFormFieldRow>
                <InputField
                  multiline
                  variant='outlined'
                  
                  placeholder={"Enter something here"}
                  inputProps={{
                    style: {
                      fontFamily: "Poppins"
                    }
                  }}
                  helperText={fieldError && element.mandatory && element.fieldText.length === 0 ?
                    <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}
                  InputProps={{
                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SmsIcon fontSize='small' />
                      </InputAdornment>
                    ),

                  }}
                  myVal={element.fieldText}
                  section_item_id={element.section_item_id}
                  updateHandler={handleUpdateFieldText} />
              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects.Number &&

              <TemplateFormFieldRow>

                <InputField
                  sx={{
                    'marginTop': isSmallScreen ? '10px' : '0px',
                    textAlign: 'center',
                    '& .MuiIconButton-root': {
                      width: '40px' // Set a fixed width for the IconButton components
                    }
                  }}

                  fullWidth
                  onFocus={event => {
                    event.target.select();
                  }}

                  type="number"
                  placeholder={"0"}
                  inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                  InputProps={{
                    startAdornment: (
                      <IconButton onClick={() => handleUpdateFieldTemperature(element.section_item_id, parseInt(element.fieldText || 0) - 1)}>
                        <StyledMinusValueIcon fontSize='small' />
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton onClick={() => handleUpdateFieldTemperature(element.section_item_id, parseInt(element.fieldText || 0) + 1)}>
                        <StyledAddValueIcon fontSize='small' />
                      </IconButton>
                    ),

                  }}

                  myVal={element.fieldText}
                  section_item_id={element.section_item_id}

                  updateHandler={handleUpdateFieldTemperature}
                />

                {/* <TextField 
                          type="number"
                          inputProps={{ type: 'number', style: { textAlign: 'center', fontFamily: "Poppins" } }}
                          InputProps={{
                            startAdornment: (
                              <IconButton onClick={() => handleUpdateFieldTemperature(element.section_item_id, parseInt(element.fieldText || 0) -1)}>
                                <StyledMinusValueIcon />
                              </IconButton>
                            ),
                            endAdornment: (
                              <IconButton onClick={() => handleUpdateFieldTemperature(element.section_item_id, parseInt(element.fieldText || 0) + 1)}>
                                <StyledAddValueIcon />
                              </IconButton>
                            ),
                            onFocus: (event) => {
                              event.target.select();
                            }
                          }}

                            
                          
                          
                          fullWidth variant='outlined' label='Number'
                          value={element.fieldText} 
                          onChange={(e) => handleUpdateFieldTemperature(element.section_item_id, e.target.value)} /> */}
              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects.Selection &&
              <TemplateFormFieldRow>

                <InputFieldSelect

                  placeholder='Enter a new option'
                  helperText={fieldError && element.mandatory && element.selectionOption === "None" ?
                    <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}
                  inputProps={{
                    style: {
                      margin: '0px',
                      padding: '0px',
                      fontFamily: `Poppins`,
                      fontSize: `0.9rem`,

                    },
                  }}
                  InputProps={{
                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <RadioButtonCheckedIcon fontSize='small' />
                      </InputAdornment>)
                  }}
                  fullWidth
                  
                  myVal={element.selectionOption}
                  section_item_id={element.section_item_id}

                  updateHandler={debouncedUpdatedFormFieldSelectionOption}>

                  <MenuItem key={-1} value={"None"}>None</MenuItem>
                  {element.options.map((type, i) => {
                    return <MenuItem key={i} value={type.product}>{type.product}</MenuItem>
                  })}

                </InputFieldSelect>

              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects['Item Selection'] &&
              <Fragment>

                {element.item_list_comment.map((item, commentIndex) => {
                  return (

                    <TemplateFormFieldRow key={commentIndex}>

                      <InputFieldSelect

                        placeholder='Enter a new option'
                        inputProps={{
                          style: {
                            margin: '0px',
                            padding: '0px',
                            fontFamily: `Poppins`,
                            fontSize: `0.9rem`,

                          },
                        }}
                        InputProps={{
                          style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                          startAdornment: (
                            <InputAdornment position="start">
                              <RadioButtonCheckedIcon fontSize='medium' />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            element.additionalOptions && <InputAdornment position="end">

                              <IconButton
                                sx={{ 'marginRight': '10px' }}
                                onClick={() => handleDeleteFormFieldItemComment(element.section_item_id, commentIndex)}
                                size="large"
                              >
                                <StyledClosedIcon fontSize='medium' />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        fullWidth
                        variant="standard"
                        myVal={item.product}
                        helperText={fieldError && element.mandatory && element.options?.length === 0 ?
                          <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}
                        section_item_id={element.section_item_id}
                        i={commentIndex}
                        updateHandler={updatedFormFieldItemCommentProductName}>

                        {element.options.map((type, i) => {
                          return <MenuItem key={i} value={type.product}>{type.product}</MenuItem>
                        })}

                      </InputFieldSelect>

                    </TemplateFormFieldRow>

                  )
                })}
                {element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}
              </Fragment>

            }

            {element.type === FieldTypeObjects['Item Selection Counts'] &&
              <Fragment>

                {element.item_list_comment.map((item, commentIndex) => {
                  return (

                    <TemplateFormFieldRow wrap="true" align_items="flex-end" key={commentIndex}>

                      <InputFieldSelect


                        placeholder='Enter a new option'
                        inputProps={{
                          style: {
                            margin: '0px',
                            padding: '0px',
                            fontFamily: `Poppins`,
                            fontSize: `0.9rem`,

                          },
                        }}
                        InputProps={{

                          style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                          startAdornment: (
                            <InputAdornment position="start">
                              <RadioButtonCheckedIcon fontSize='medium' />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            element.additionalOptions && <InputAdornment position="end">

                              <IconButton
                                sx={{ 'marginRight': '10px' }}
                                onClick={() => handleDeleteFormFieldItemComment(element.section_item_id, commentIndex)}
                                size="large"
                              >
                                <StyledClosedIcon fontSize='medium' />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        fullWidth
                        variant="standard"
                        myVal={item.product}
                        section_item_id={element.section_item_id}
                        i={commentIndex}
                        updateHandler={updatedFormFieldItemCommentProductName}>

                        {element.options.map((type, i) => {
                          return <MenuItem key={i} value={type.product}>{type.product}</MenuItem>
                        })}

                      </InputFieldSelect>


                      <InputField
                        type="number"
                        sx={{
                          'marginTop': isSmallScreen ? '10px' : '0px',
                          marginLeft: isSmallScreen ? '0px' : '10px',
                          width: isSmallScreen ? "100%" : "fit-content",
                          textAlign: 'center',
                          '& .MuiIconButton-root': {
                            width: '40px' // Set a fixed width for the IconButton components
                          }
                        }}

                        fullWidth
                        onFocus={event => {
                          event.target.select();
                        }}


                        inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                        InputProps={{
                          startAdornment: (
                            <IconButton onClick={() => updatedFormFieldItemCommentProductComment(element.section_item_id, commentIndex, parseInt(item.comment) - 1)}>
                              <StyledMinusValueIcon fontSize='small' />
                            </IconButton>
                          ),
                          endAdornment: (
                            <IconButton onClick={() => updatedFormFieldItemCommentProductComment(element.section_item_id, commentIndex, parseInt(item.comment) + 1)}>
                              <StyledAddValueIcon fontSize='small' />
                            </IconButton>
                          ),

                        }}

                        myVal={parseInt(item.comment) || 0}
                        section_item_id={element.section_item_id}
                        i={commentIndex}
                        updateHandler={updatedFormFieldItemCommentProductComment}
                      />

                    </TemplateFormFieldRow>

                  )
                })}
                {element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}
              </Fragment>

            }

            {element.type === FieldTypeObjects['Item Count'] &&
              <Fragment>
                {element.counts.map((item, countIndex) => {
                  return (
                    <TemplateFormFieldRow wrap="true" align_items="flex-end" key={countIndex}>

                      <InputField
                        variant="outlined"
                        placeholder={"Enter an item"}

                        inputProps={{
                          style: {
                            fontFamily: "Poppins"
                          }
                        }}
                        InputProps={{
                          readOnly: !element.additionalOptions,
                          style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                          startAdornment: (
                            <InputAdornment position="start">
                              <InventoryIcon fontSize='small' />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            element.additionalOptions && <InputAdornment position="end">

                              <IconButton
                                onClick={() => handleDeleteFormFieldItemCount(element.section_item_id, countIndex)}
                                size="large"
                              >
                                <StyledClosedIcon fontSize='medium' />
                              </IconButton>
                            </InputAdornment>
                          )

                        }}


                        fullWidth
                        onFocus={event => {
                          event.target.select();
                        }}


                        i={countIndex}
                        myVal={item.product || ""}
                        section_item_id={element.section_item_id}
                        helperText={fieldError && element.mandatory && element.counts?.length === 0 ?
                          <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}

                        updateHandler={updatedFormFieldProductName}
                      />

                      <InputFieldCount
                        variant='standard'
                        type="number"
                        sx={{
                          'marginTop': isSmallScreen ? '10px' : '0px',
                          marginLeft: isSmallScreen ? '0px' : '10px',
                          width: isSmallScreen ? "100%" : "fit-content",
                          textAlign: 'center',
                          '& .MuiIconButton-root': {
                            width: '40px' // Set a fixed width for the IconButton components
                          }
                        }}
                        label={item.count[0]?.label?.length > 0 ? item.count[0].label : "Value"}
                        onFocus={event => {
                          event.target.select();
                        }}
                        inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                        myVal={item.count[0]?.value || ""}
                        i={countIndex}
                        countIndex={0}
                        section_item_id={element.section_item_id}
                        updateHandler={updateFormFieldProductCountValue}
                        InputProps={{
                          startAdornment: (
                            <IconButton onClick={() => updateFormFieldProductCountValue(element.section_item_id, countIndex, 0, parseInt(item.count[0].value) - 1)}>
                              <StyledMinusValueIcon />
                            </IconButton>
                          ),
                          endAdornment: (
                            <IconButton onClick={() => updateFormFieldProductCountValue(element.section_item_id, countIndex, 0, parseInt(item.count[0].value) + 1)}>
                              <StyledAddValueIcon />
                            </IconButton>
                          ),

                        }}

                      />

                    </TemplateFormFieldRow>
                  )
                })}

                {element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldCount(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}

              </Fragment>
            }

            {element.type === FieldTypeObjects['Item Double Count'] &&
              <Fragment>
                {element.counts.map((item, countIndex) => {
                  return (
                    <TemplateFormFieldRow wrap="true" align_items="flex-end" key={countIndex}>

                      <InputField
                        variant="outlined"
                        placeholder={"Enter an item"}

                        inputProps={{
                          style: {
                            fontFamily: "Poppins"
                          }
                        }}
                        InputProps={{
                          readOnly: !element.additionalOptions,
                          style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                          startAdornment: (
                            <InputAdornment position="start">
                              <InventoryIcon fontSize='small' />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            element.additionalOptions && <InputAdornment position="end">

                              <IconButton
                                onClick={() => handleDeleteFormFieldItemCount(element.section_item_id, countIndex)}
                                size="large"
                              >
                                <StyledClosedIcon fontSize='medium' />
                              </IconButton>
                            </InputAdornment>
                          )

                        }}


                        fullWidth
                        onFocus={event => {
                          event.target.select();
                        }}


                        i={countIndex}
                        myVal={item.product}
                        section_item_id={element.section_item_id}

                        updateHandler={updatedFormFieldProductName}
                      />

                      <InputFieldCount
                        variant='standard'


                        sx={{
                          'marginTop': isSmallScreen ? '10px' : '0px',
                          marginLeft: isSmallScreen ? '0px' : '10px',
                          width: isSmallScreen ? "100%" : "fit-content",
                          textAlign: 'center',
                          '& .MuiIconButton-root': {
                            width: '40px' // Set a fixed width for the IconButton components
                          }
                        }}
                        label={item.count[0].label?.length > 0 ? item.count[0].label : "Value"}
                        onFocus={event => {
                          event.target.select();
                        }}

                        inputProps={{

                          type: "number",
                          style: { textAlign: 'center' }
                        }}


                        myVal={item.count[0].value}
                        i={countIndex}
                        countIndex={0}
                        section_item_id={element.section_item_id}

                        updateHandler={debouncedUpdateFormFieldProductCountValue}
                        InputProps={{
                          startAdornment: (
                            <IconButton onClick={() => updateFormFieldProductCountValue(element.section_item_id, countIndex, 0, parseInt(item.count[0].value) - 1)}>
                              <StyledMinusValueIcon />
                            </IconButton>
                          ),
                          endAdornment: (
                            <IconButton onClick={() => updateFormFieldProductCountValue(element.section_item_id, countIndex, 0, parseInt(item.count[0].value) + 1)}>
                              <StyledAddValueIcon />
                            </IconButton>
                          ),

                        }}
                      />

                      <InputFieldCount
                        variant='standard'
                        type="number"
                        sx={{
                          'marginTop': isSmallScreen ? '10px' : '0px',
                          marginLeft: isSmallScreen ? '0px' : '10px',
                          width: isSmallScreen ? "100%" : "fit-content",
                          textAlign: 'center',
                          '& .MuiIconButton-root': {
                            width: '40px' // Set a fixed width for the IconButton components
                          }
                        }}
                        label={item.count[1].label?.length > 0 ? item.count[1].label : "Value"}
                        onFocus={event => {
                          event.target.select();
                        }}
                        inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                        myVal={item.count[1].value}
                        i={countIndex}
                        countIndex={1}

                        section_item_id={element.section_item_id}
                        updateHandler={updateFormFieldProductCountValue}
                        InputProps={{
                          startAdornment: (
                            <IconButton onClick={() => updateFormFieldProductCountValue(element.section_item_id, countIndex, 1, parseInt(item.count[1].value) - 1)}>
                              <StyledMinusValueIcon />
                            </IconButton>
                          ),
                          endAdornment: (
                            <IconButton onClick={() => updateFormFieldProductCountValue(element.section_item_id, countIndex, 1, parseInt(item.count[1].value) + 1)}>
                              <StyledAddValueIcon />
                            </IconButton>
                          ),

                        }}
                      />

                    </TemplateFormFieldRow>
                  )
                })}

                {element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldCount(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}

              </Fragment>
            }

            {element.type === FieldTypeObjects['Item Comment'] &&
              <Fragment>
                {element.item_list_comment.map((item, commentIndex) => {
                  return (
                    <div key={commentIndex}>
                      <TemplateFormFieldRow style={{ 'marginBottom': '15px' }}>
                        <InputField
                          variant="standard"

                          inputProps={{
                            style: {
                              fontFamily: "Poppins"
                            }
                          }}

                          InputProps={{
                            readOnly: !element.additionalOptions,
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            tabIndex: `${-commentIndex}`,
                            startAdornment: (
                              <InputAdornment position="start">
                                <InventoryIcon fontSize='small' />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              element.additionalOptions && <InputAdornment position="end">

                                <IconButton
                                  onClick={() => handleDeleteFormFieldItemComment(element.section_item_id, commentIndex)}
                                  size="large"
                                >
                                  <StyledClosedIcon fontSize='medium' />
                                </IconButton>
                              </InputAdornment>
                            )

                          }}


                          placeholder={"Enter an item"}
                          myVal={item.product}
                          i={commentIndex}
                          section_item_id={element.section_item_id}
                          updateHandler={updatedFormFieldItemCommentProductName}
                          helperText={fieldError && element.mandatory && element.item_list_comment?.length === 0 ?
                            <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}

                        />

                      </TemplateFormFieldRow>
                      <TemplateFormFieldRow>
                        <InputField
                          variant='outlined'
                          onFocus={event => {
                            event.target.select();
                          }}
                          inputProps={{
                            style: {
                              fontFamily: "Poppins"
                            }
                          }}
                          InputProps={{
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            startAdornment: (
                              <InputAdornment position="start">
                                <SmsIcon fontSize='small' />
                              </InputAdornment>
                            )
                          }}

                          myVal={item.comment || ""}
                          placeholder={"Enter a comment for this item"}
                          section_item_id={element.section_item_id}
                          i={commentIndex}
                          updateHandler={updatedFormFieldItemCommentProductComment}
                        />
                        {/* <TextField
                              variant='outlined'
                              fullWidth
                              multiline
                              label="Comment"
                              onFocus={event => {
                                event.target.select();
                              }}
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins"
                                }
                              }} 
                              InputProps={{
                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                startAdornment: (
                                  <InputAdornment position="start">
                                      <SmsIcon fontSize='small' />
                                  </InputAdornment>
                              )}}
                              
                              defaultValue={item.comment}
                              onChange={e => debouncedFormFieldItemCommentProductComment(element.section_item_id, commentIndex, e.target.value)}
                               /> */}
                      </TemplateFormFieldRow>

                    </div>

                  )
                })}
                {element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}

              </Fragment>

            }

            {element.type === FieldTypeObjects['Item List'] &&
              <Fragment>
                {element.item_list_comment.map((item, commentIndex) => {
                  return (
                    <div key={commentIndex}>
                      <TemplateFormFieldRow style={{ 'marginBottom': '15px' }}>
                        <InputField
                          variant="standard"



                          inputProps={{
                            style: {
                              fontFamily: "Poppins"
                            }
                          }}

                          InputProps={{
                            readOnly: !element.additionalOptions,
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            tabIndex: `${-commentIndex}`,
                            startAdornment: (
                              <InputAdornment position="start">
                                <InventoryIcon fontSize='small' />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              element.additionalOptions && <InputAdornment position="end">

                                <IconButton
                                  onClick={() => handleDeleteFormFieldItemComment(element.section_item_id, commentIndex)}
                                  size="large"
                                >
                                  <StyledClosedIcon fontSize='medium' />
                                </IconButton>
                              </InputAdornment>
                            )

                          }}

                          myVal={item.product}
                          i={commentIndex}
                          section_item_id={element.section_item_id}
                          helperText={fieldError && element.mandatory && element.item_list_comment?.length === 0 ?
                            <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}
                          updateHandler={updatedFormFieldItemCommentProductName}
                        />
                        {/* <TextField
                                variant="standard"
                                multiline
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins"
                                  }
                                }} 
                                label={`${commentIndex+1}. Item`}
                                InputProps={{
                                  
                                  style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                  tabIndex: `${-commentIndex}`,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                        <InventoryIcon fontSize='small' />
                                    </InputAdornment>
                                  ),
                                   endAdornment: (
                                    element.additionalOptions && <InputAdornment position="end">
                                      
                                      <IconButton
                                        onClick={() => handleDeleteFormFieldItemComment(element.section_item_id, commentIndex)}
                                        size="large"
                                      >
                                        <StyledClosedIcon fontSize='medium' />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                  
                                }}
      
                                fullWidth
                                
                                defaultValue={item.product}
                                onChange={(e) => debouncedFormFieldItemCommentProductName(element.section_item_id, commentIndex, e.target.value)}
                                /> */}
                      </TemplateFormFieldRow>

                    </div>

                  )
                })}
                {element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}

              </Fragment>

            }

            {element.type === FieldTypeObjects['List Checkbox'] &&
              <Fragment>

                {element.item_list_checkbox.map((checkItem, checkIndex) => {

                  return (
                    <div key={checkIndex} style={element.listCheckboxComments ? { 'marginBottom': '10px' } : { 'marginBottom': '15px' }}>
                      <TemplateFormFieldRow style={{ 'margin': '0px 0px' }}>
                        <InputField
                          variant="standard"

                          inputProps={{
                            style: {
                              fontFamily: "Poppins"
                            }
                          }}
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                            style: { outline: 0, border: 0, color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            tabIndex: `${-checkIndex}`,
                            startAdornment: (
                              <InputAdornment position="start">
                                {checkIndex + 1}.
                              </InputAdornment>
                            ),
                            // endAdornment: (
                            //   element.additionalOptions && <InputAdornment position="end">

                            //     <IconButton
                            //       onClick={() => handleDeleteFormFieldItemListCheckbox(element.section_item_id, checkIndex)}
                            //       size="large"
                            //     >
                            //       <StyledClosedIcon fontSize='medium' />
                            //     </IconButton>
                            //   </InputAdornment>
                            // )

                          }}

                          myVal={checkItem.product || ""}
                          i={checkIndex}
                          section_item_id={element.section_item_id}
                          helperText={fieldError && element.mandatory && element.item_list_checkbox?.length === 0 ?
                            <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}
                          updateHandler={updatedFormFieldItemListCheckboxName}
                        />
                        {/* <TextField
                                variant="standard"
                                size='small'
                                multiline
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins"
                                  }
                                }} 
                                InputProps={{
                                  disableUnderline: true,
                                  readOnly: true,
                                  style: { outline: 0, border: 0, color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                  tabIndex: `${-checkIndex}`,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                        {checkIndex+1}.
                                    </InputAdornment>
                                  ),
                                  // endAdornment: (
                                  //   element.additionalOptions && <InputAdornment position="end">
                                      
                                  //     <IconButton
                                  //       onClick={() => handleDeleteFormFieldItemListCheckbox(element.section_item_id, checkIndex)}
                                  //       size="large"
                                  //     >
                                  //       <StyledClosedIcon fontSize='medium' />
                                  //     </IconButton>
                                  //   </InputAdornment>
                                  // )
                                  
                                }}
      
                                
                                
                                defaultValue={checkItem.product}
                                onChange={(e) => debouncedupdatedFormFieldItemListCheckboxName(element.section_item_id, checkIndex, e.target.value)}
                              /> */}
                        <Checkbox

                          onChange={(e) => updatedFormFieldItemListChecboxValue(element.section_item_id, checkIndex, e.target.checked)}
                          inputProps={{ 'aria-label': 'controlled' }} />

                      </TemplateFormFieldRow>
                      {element.listCheckboxComments &&
                        <TextField fullWidth
                          size="small"
                          placeholder='Enter a comment for this item'
                          defaultValue={checkItem.comment}
                          onChange={(e) => debouncedupdatedFormFieldItemListCheckboxComment(element.section_item_id, checkIndex, e.target.value)}
                        />}

                    </div>

                  )
                })}
                {/* {element.additionalOptions && <TemplateFormFieldRow style={{'marginTop' : '10px'}}>
                          <AddOptionButton onClick={() => addFormFieldItemListCheckbox(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{'marginRight': '5px'}} /> Add option</AddOptionButton>
                          </TemplateFormFieldRow>} */}

              </Fragment>

            }

            {element.type === FieldTypeObjects.DateTime &&
              <TemplateFormFieldRow>
                <StyledTextField
                  label="Date Time"
                  type="datetime-local"
                  onChange={(e) => handleDateTimeUpdate(element.section_item_id, e.target.value)}
                  value={element.datetime}
                  fullWidth
                  inputProps={{
                    style: {
                      fontFamily: "Poppins"
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects.Time &&
              <TemplateFormFieldRow>
                <StyledTextField
                  label="Time"
                  type="time"
                  onChange={(e) => handleTimeUpdate(element.section_item_id, e.target.value)}
                  value={element.datetime}
                  fullWidth
                  inputProps={{
                    style: {
                      fontFamily: "Poppins"
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects.YesNo &&
              <TemplateFormFieldRow>
                <StyledTextField
                  select
                  fullWidth
                  helperText={fieldError && element.mandatory && element.yesno?.length === 0 ?
                    <Text fontWeight={600} color={Colors.highlight}>Please fill in an answer.</Text> : ""}
                  inputProps={{
                    style: {
                      fontFamily: "Poppins"
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RadioButtonCheckedIcon fontSize='small' />
                      </InputAdornment>
                    )
                  }}
                  value={element.yesno === "" ? "" : element.yesno === "Yes" ? "Yes" : "No"}
                  onChange={(e) => handleAnswerUpdate(element.section_item_id, e.target.value)}
                >
                  {AnswerTypes.map((type, i) => {
                    return <MenuItem key={i} value={type}>{type}</MenuItem>
                  })}
                </StyledTextField>
              </TemplateFormFieldRow>
            }

            {element.scorable &&
            <TemplateFormFieldWrapper align="right">
              <TemplateFormFieldScoreContainer>
                <FieldRating prevRating={element.rating} section_item_id={element.section_item_id} updateRating={handleRatingUpdate} />
              </TemplateFormFieldScoreContainer>
              </TemplateFormFieldWrapper>}

            <TemplateFormFieldWrapper align="right">


              {!isVisitPublic && element.filesAllowed &&
                <TemplateFormFieldWrapperItem>
                  <FileUploader canEdit={true} size={element.newFiles?.length} allowMultipleFiles section_item_id={element.section_item_id} handleFilesUpload={handleFilesUpload} />

                </TemplateFormFieldWrapperItem>
              }
              {!isVisitPublic ? element.actionAllowed && permissions.actions.add &&
                <TemplateFormFieldWrapperItem>
                  <ActionNewButton onClick={() => handleNewActionDialog(section.section_id, element.section_item_id)}><StyledActionIcon /></ActionNewButton>
                </TemplateFormFieldWrapperItem>
                : null
              }


            </TemplateFormFieldWrapper>



            {element.newFiles?.length > 0 && !isVisitPublic && <TemplateFormFieldRow>
              <FilePreview
                setFileSizeError={setFileSizeError}
                section_item_id={element.section_item_id}
                files={element.files}
                newFiles={element.newFiles}
                user={currentUser}
                handleAddFileComment={handleAddFileComment}
                handleRemoveNewFile={handleRemoveNewFile}
              />
            </TemplateFormFieldRow>}


            <TemplateFormFieldActionContainer>

              {element.actionAllowed && !isVisitPublic && element.actions?.length > 0 && <ActionItemContainer>
                <Text fontSize="0.9rem" fontWeight={600} style={{ 'margin': '5px 10px' }}>Actions Assigned</Text>

                {element.actions.map((action, i) => {
                  return (
                    <ActionItemBlockContainer onClick={() => handleEditActionDialog(section.section_id, element.section_item_id, i, action)} key={i}>
                      <ActionItemPriorityBubble priority={action.action_priority} />
                      <ActionItemColumn>
                        <ActionItemBlock style={{ 'marginBottom': '2px' }}>{action.section_item_name}</ActionItemBlock>
                        <ActionItemBlock>
                          <ActionItemHolder size="medium">{action.actionByName} </ActionItemHolder>
                          <ActionItemHolder size="medium" bold> on {moment(action.action_due_date).format("DD MMM YY")}</ActionItemHolder>
                        </ActionItemBlock>
                      </ActionItemColumn>

                    </ActionItemBlockContainer>
                  )
                })}
              </ActionItemContainer>}
            </TemplateFormFieldActionContainer>

            {element.allowComments && !isVisitPublic &&
              <TemplateFormFieldRow align="right" style={{ 'marginTop': '0px' }}>
                <TemplateFormFieldInsertComment onClick={() => handleShowElementComments(element.section_item_id)}>{element.showComments ? `Hide Comments (${element.comments.length})` : `Show Comments (${element.comments.length})`}</TemplateFormFieldInsertComment>
              </TemplateFormFieldRow>}

            {element.allowComments && !isVisitPublic && element.showComments &&
              <>
                <TemplateFormFieldRow align="right">
                  <TextField

                    fullWidth
                    size='small'
                    placeholder='Enter a new comment'
                    type="text"
                    value={currentComments[element.section_item_id] || ""}
                    onChange={e => updateComment(element.section_item_id, e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <StyledAddOptionIcon style={{ 'margin': '0px' }} fontSize="medium" onClick={() => handleAddComment(element.section_item_id)} />
                        </InputAdornment>
                      ),

                    }}
                  />
                </TemplateFormFieldRow>
                {element.comments.map((comment, commentIndex) => {
                  return (
                    <TemplateFormFieldRow key={commentIndex}>
                      <TemplateFormFieldComment>
                        {comment}
                        <StyledClosedIcon onClick={() => handleDeleteComment(element.section_item_id, commentIndex)} style={{ 'marginLeft': '5px' }} />
                      </TemplateFormFieldComment>

                    </TemplateFormFieldRow>)
                })}
              </>
            }


          </TemplateFormField>

        )
      })}

    </TemplateSection>
  )
})

export default React.memo(Section)