import React from 'react'
import { CenteredContainer } from '../styled/styled.splash'
import { StyledEmptyIcon } from '../styled/styled.icons'
import { Colors, Text } from '../styled/styled.container'

function EmptyContainer({text}) {
  return (
    <div style={{padding: '10px 0px', minHeight: "100px", display: 'flex', gap: '10px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <StyledEmptyIcon color="#AAAFB4" style={{ fontSize: "2rem" }}/>
        <Text color={"#AAAFB4"} fontSize={"1rem"}>{text}</Text>
    </div>
  )
}

export default EmptyContainer