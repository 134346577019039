import React from 'react'
import { Colors, ColumnContainer, HeaderTitle, TableContainer, Text } from './styled/styled.container';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { DashboarChartContainer } from './styled/styled.dashboard';

// store_name: actionItem.store_name,
//           template_name: actionItem.template_name,
//           region: store.region || "None",
//           sub_region: store.sub_region || "None",
//           action_due_date: actionItem.action_due_date,
//           actionBy: actionItem.actionByName,
//           actionPriority: actionItem.actionPriority,
//           section_item_name: actionItem.section_item_name

const columns = [
    { field: "action_id", headerName: "ID", hide: true },

    {
        field: "section_item_name",
        headerName: "Action",
        minWidth: 300,
        flex: 1
    },
    {
        field: "actionPriority",
        headerName: "Priority",

        renderCell: (params) => {

            let cellColor = ""; // Default color
            const action_priority = params.row.actionPriority;

            if (action_priority === "Low") {
                cellColor = `${Colors.green}`;
            }
            else if (action_priority === "Medium") {
                cellColor = `${Colors.orange}`; // Set color to red for "Overdue"
            }
            else {
                cellColor = `${Colors.highlight}`;
            }

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {action_priority}
                </div>
            );
        },
        minWidth: 70,
        flex: 1

    },
    {
        field: "action_due_date",
        headerName: "Due",
        minWidth: 100,
        flex: 1,

        renderCell: (params) => {

            let cellColor = ""; // Default color

            const action_due_date = moment(params.value);

            if (moment().startOf('day').isSameOrAfter(action_due_date)) {
                cellColor = `${Colors.highlight}`; // Set color to green for "Today or Past"
            }
            else if (action_due_date.isBefore(moment())) {
                cellColor = `${Colors.blue}`; // Set color to blue for "Before Current Date"
            }
            else {
                cellColor = `${Colors.orange}`;
            }

            return (
                <div style={{ minWidth: '80px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {moment(params.value).format("DD MMM YY")}
                </div>
            );
        },
        
    },
    {
        field: "ageing",
        headerName: "Ageing",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = "" // Default color
            let ageing = "";

            if (params.value === "Not Due") ageing = "Not Due"
            else ageing = parseInt(params.value);


            //console.log(ageing)
            if (ageing === "Not Due") cellColor = `${Colors.green}`
            else if (ageing === 0) cellColor = `${Colors.blue}`
            else if (ageing < 0) cellColor = `${Colors.green}` // Set color to red for "Overdue"
            else if (ageing > 0 && ageing < 3) cellColor = `${Colors.orange}`
            else if (ageing >= 3) cellColor = `${Colors.highlight}`

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {ageing}
                </div>
            );
        },
    },
    {
        field: "actionBy",
        headerName: "Action by",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = `${Colors.blue}` // Default color

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {params.value}
                </div>
            );
        },
    },
    { field: 'store_name', headerName: 'Location', minWidth: 170, flex: 1 },
    { field: 'region', headerName: 'Location Group', minWidth: 120, flex: 1 },
    { field: 'sub_region', headerName: 'Sub Group', minWidth: 120, flex: 1 },
    { field: 'template_name', headerName: 'Template', minWidth: 170, flex: 1 },





];



function HomeCardActionsDue({ actionItems }) {

    return (
        <ColumnContainer>
            <Text style={{'marginBottom': '5px'}} fontSize={"0.9rem"} fontWeight={700}>Actions Outstanding</Text>
        
            <DashboarChartContainer style={{padding: '0px', 'margin': '10px 0px'}}>
            <DataGrid
                autoHeight
                sx={{ 'border': '0' }}
                rows={actionItems}
                columns={columns}
                getRowId={(row) => row.action_id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                

            />
        </DashboarChartContainer>
        </ColumnContainer>
        
    )
}

export default HomeCardActionsDue