import React, {useState, useEffect} from 'react'
import { NavContainer, NavInnerContainer, Header, Name, NavLeftContainer, NavRightIconContainer, NavRightContainer, NavLogoHolder, NavItemContainer, NavItem, NavExpandedContainer, NavExpandedItemContainer, NavExpandedItem, NavQuoteContainer, NavQuoteItem, NavLeftContainerText, NavMainContainer, NavMenuContainer, NavMenuItem, NavLeftContainerImage} from "./styled/styled.navbar";
import { useLocation } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "./firebase";
import { useNavigate, Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { Colors } from './styled/styled.container';
import { Typography } from '@mui/material';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 350;

// const NavMenuContainer = styled.div`
//   flex-grow: 1;
// `;


const Type = styled(Typography)`
  margin-bottom: 8px;
`;

const styles = {
  color: '#0000008a',
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: `${Colors.blue}`,
    color: `${Colors.white}`,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '2px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1.2)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.7)',
      opacity: 0,
    },
  },
}));

function Navbar(props) {

const [showExpanded, setShowExpanded] = useState(false);
const [user, loading, error] = useAuthState(auth);
const [menuValue, setMenuValue] = useState(0);
const [anchorEl, setAnchorEl] = React.useState(null);
const {storeDetails, toggleDrawer} = props;
const navigate = useNavigate();
const open = Boolean(anchorEl);
const {pathname} = useLocation();
const theme = useTheme();
const isSmallScreen = true; //useMediaQuery(theme.breakpoints.down('sm'));
const [openDrawer, setOpenDrawer] = useState(false);

useEffect(() => {

  if(pathname.includes("storevisit")) setMenuValue(1)
  else if(pathname.includes("actions")) setMenuValue(2)
  else if(pathname.includes("scheduler")) setMenuValue(3)
  else if(pathname.includes("reports")) setMenuValue(4)
  else if(pathname.includes("templates")) setMenuValue(5)
  else if(pathname.includes("stores")) setMenuValue(6)
  else setMenuValue(0);

}, [pathname])



const styles = {
  fontFamily: 'Poppins',
  fontSize: 11,
  fontWeight: 600,

};


const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
};

const handleClose = () => {
    setAnchorEl(null);
};

function handleScroll(item) {
    
  const violation = document.getElementById(item); 
  violation.scrollIntoView({ behavior: 'smooth' });

  setShowExpanded(false);
}

function handleLogout() {
  if(user) logout();
}

function handleMenuChange(event, newValue) {
  setMenuValue(newValue)
  //console.log(newValue)
  // if(newValue == 0) handlePage("")
  // else if(newValue == 1) handlePage("storevisit")
  // else if(newValue == 2) handlePage("reports")
  // else if(newValue == 3) handlePage("templates");
  // else if(newValue == 4) handlePage("stores")
}

function stringAvatar(name) {

  if(name) {
    return {
      children: `${name ? name.split(' ')[0][0] : "U"}`,
    }
}
}

function handlePage(page) {
  handleClose();
  //navigate("/" + page)
}

function handleSettings() {
  handleClose();
  if(user) navigate("/app/settings");
}

function returnHome() {
  navigate("/app");
}

const drawer = (
  <>
    <Header>
      <Typography variant="h6">{storeDetails.rootID}</Typography>
      <Name>{storeDetails.user.name}</Name>
      <Type>{storeDetails.user.type}</Type>
    </Header>
    <List>
      <ListItemButton
        key="home"
        selected={menuValue === 0}
        onClick={() => setMenuValue(0)}
      >
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton
        key="visits"
        selected={menuValue === 1}
        onClick={() => setMenuValue(1)}
      >
        <ListItemText primary="Visits" />
      </ListItemButton>
      <ListItemButton
        key="actions"
        selected={menuValue === 2}
        onClick={() => setMenuValue(2)}
      >
        <ListItemText primary="Actions" />
      </ListItemButton>
      <ListItemButton
        key="schedule"
        selected={menuValue === 3}
        onClick={() => setMenuValue(3)}
      >
        <ListItemText primary="Schedule" />
      </ListItemButton>
      <ListItemButton
        key="reports"
        selected={menuValue === 4}
        onClick={() => setMenuValue(4)}
      >
        <ListItemText primary="Reports" />
      </ListItemButton>
      <ListItemButton
        key="templates"
        selected={menuValue === 5}
        onClick={() => setMenuValue(5)}
      >
        <ListItemText primary="Templates" />
      </ListItemButton>
      <ListItemButton
        key="stores"
        selected={menuValue === 6}
        onClick={() => setMenuValue(6)}
      >
        <ListItemText primary="Locations" />
      </ListItemButton>
    </List>
  </>
);


  return (
    <NavMainContainer>
      <NavContainer>
              <NavInnerContainer>
                <NavLeftContainer>
                
                <NavLeftContainerText onClick={returnHome}><NavLeftContainerImage src="/logo_blue.png" /> Storecall</NavLeftContainerText>
                </NavLeftContainer>
                
                <NavRightContainer>
                  
                  <NavRightIconContainer
                    onClick={handleClick}>
                      {storeDetails.rootID}
                      <StyledBadge
                      sx={{marginLeft: '10px'}}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <Avatar size="small" sx={{color: `${Colors.blue}`, backgroundColor: `${Colors.light_blue_highlight}`}} {...stringAvatar(storeDetails.user.name)} />
                    </StyledBadge>
                      
                  </NavRightIconContainer>

                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleDrawer}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                  >
                    <MenuIcon sx={{'color': `${Colors.white}`}} />
                  </IconButton>

                  {/* <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleSettings}>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu> */}
                  
                
                </NavRightContainer>

              </NavInnerContainer>

    </NavContainer>

    {/* {isSmallScreen ? 
      <NavMenuContainer>
      <Tabs
       value={menuValue} onChange={handleMenuChange} 
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          >
          <Tab sx={{'fontSize': '0.8rem'}} index={0} label={<Typography style={styles}>Home</Typography>} LinkComponent={Link} to={'/app'} />
          <Tab sx={{'fontSize': '0.8rem'}} index={1} label={<Typography style={styles}>Visits</Typography>} disabled={storeDetails && !storeDetails.permissions.visits.view} LinkComponent={Link} to={"/app/storevisit"} />
          <Tab sx={{'fontSize': '0.8rem'}} index={2} label={<Typography style={styles}>Actions</Typography>} disabled={storeDetails && !storeDetails.permissions.actions.view} LinkComponent={Link} to={"/app/actions"} />
          <Tab sx={{'fontSize': '0.8rem'}} index={3} label={<Typography style={styles}>Calendar</Typography>} disabled={storeDetails && !storeDetails.permissions.schedule.view} LinkComponent={Link} to={"/app/scheduler"} />
          <Tab sx={{'fontSize': '0.8rem'}} index={4} label={<Typography style={styles}>Reports</Typography>} disabled={storeDetails && !storeDetails.permissions.reports.view}  LinkComponent={Link} to={"/app/reports"} />
          <Tab sx={{'fontSize': '0.8rem'}} index={5} label={<Typography style={styles}>Templates</Typography>} disabled={storeDetails && !storeDetails.permissions.templates.view} LinkComponent={Link} to={'/app/templates'} />
          <Tab sx={{'fontSize': '0.8rem'}} index={6} label={<Typography style={styles}>Locations</Typography>} disabled={storeDetails && !storeDetails.permissions.stores.view} LinkComponent={Link} to={'/app/locations'} />
        </Tabs>
      </NavMenuContainer>
      :
          <>
            <Drawer variant="permanent" sx={{ width: 600 }}>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
              {drawer}
            </Drawer>
            <NavMenuContainer sx={{ marginLeft: openDrawer ? 0 : -drawerWidth }}>
              <IconButton onClick={toggleDrawer}>
                {openDrawer ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </NavMenuContainer>
          </>
        
    } */}
      </NavMainContainer>
    
    
    
  )
}

export default Navbar