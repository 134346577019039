import React, { Fragment, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, Button, GroupHeader, GroupItems } from './styled/styled.container'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, where, getDocs, orderBy, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFilterContainer } from './styled/styled.templateform';
import Checkbox from '@mui/material/Checkbox';
import Chart from "react-apexcharts";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment/moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from './Loader';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const columns = [
  { field: "id", headerName: "ID", hide: true },
  { field: "cloud_id", headerName: "Cloud_ID", hide: true },
  { field: 'storeName', headerName: 'Location Name', minWidth: 150, flex: 1 },
  { field: 'region', headerName: 'Location Group', minWidth: 150, flex: 1 },
  { field: 'sub_region', headerName: 'Sub Group', minWidth: 150, flex: 1 },
  { field: 'templateName', headerName: 'Template', minWidth: 150, flex: 1 },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 100,
    flex: 1

  },
  {
    field: "section", headerName: "Section",
    minWidth: 150,
    flex: 1
  },
  {
    field: "field", headerName: "Field",
    minWidth: 200,
    flex: 1

  },
  {
    field: "field_value", headerName: "Field Value",
    minWidth: 200,
    flex: 1
  },
  {
    field: "additional_field_value", headerName: "Additional",
    minWidth: 150,
    flex: 1
  },
  {
    field: "analysis_field", headerName: "Analysis Field",
    minWidth: 200,
    flex: 1
  },
  {
    field: "analysis_value", headerName: "Analysis Value",
    minWidth: 200,
    flex: 1
  },
  {
    field: "additional_analysis_value", headerName: "Additional Analysis",
    minWidth: 150,
    flex: 1
  }
];

function ReportQuantityAnalysis(props) {
  const { storeDetails, stores, templates } = props;

  // holds selected stores and template names only
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [targetFields, setTargetFields] = useState([])
  const [targetSeperators, setTargetSeperators] = useState([]);
  const [selectedTargetFields, setSelectedTargetFields] = useState([])
  const [selectedTargetSeperator, setSelectedTargetSeperator] = useState([]);
  const [analysisFields, setAnalysisFields] = useState([])
  const [selectedAnalysisFields, setSelectedAnalysisFields] = useState([])
  // holds selected store name + cloud id
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [visits, setVisits] = useState();
  const [series, setSeries] = useState([]);

  const [options, setOptions] = useState({});
  const [filters, setFilters] = useState(true);
  const [filterActions, setFilterActions] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
  const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
  const navigate = useNavigate();

  // useEffect(() => {

  //   if(visits && visits.length > 0) {

  //       // sort the list so that points are projected in order
  //       let visitDates = [...visits].sort((a,b) => {
  //         //console.log(a.date, b.date, moment(a.date).isBefore(moment(b.date)))
  //         //console.log(moment(a.date).isValid())
  //         if(moment(a.date, "DD-MM-YYYY").isBefore(moment(b.date, "DD-MM-YYYY"))) return -1;
  //         else if(moment(a.date, "DD-MM-YYYY").isAfter(moment(b.date, "DD-MM-YYYY"))) return 1;
  //         else return 0;
  //       }).map(visit => visit.date)

  //       // remove duplicates from different visits -> templates
  //       // eg: Store Visit, Regional Visit and PS, Bizana
  //       visitDates = [...new Set(visitDates)]

  //       setSeries([])
  //       selectedTemplatesInfo.forEach(templateElement => {
  //           selectedStoresInfo.forEach((storeElement, i) => {

  //               const visitScores = visitDates.map(date => {
  //                   const scoreElement = visits.filter(visit => visit.store_id === storeElement.cloud_id && visit.date === date && visit.template_id === templateElement.cloud_id)

  //                   if(scoreElement.length > 0) return scoreElement[0].score;
  //                   else return 0;
  //               })

  //               // check if any valid score for that template -> store
  //               const scoresEmpty = visitScores.every(element => element === null);
  //               if(!scoresEmpty) {
  //                   const element = {
  //                       name: storeElement.name + " (" + templateElement.name + ")",
  //                       data: visitScores
  //                   }

  //                   setSeries(prevState => [...prevState, element])

  //               }

  //           })
  //       })

  //       const visitOptions = {
  //         chart: { id: 'bar-chart'},

  //           xaxis: {
  //               categories: visitDates
  //           },
  //           stroke: {
  //             curve: "smooth",
  //           },
  //           title: {
  //             text: "Field Analysis"
  //           },
  //       }

  //       setOptions(visitOptions)
  //       // const sortedCategories = visitOptions.xaxis.categories.sort();
  //       // setOptions({
  //       //   ...visitOptions,
  //       //   xaxis: {
  //       //     ...visitOptions.xaxis,
  //       //     categories: sortedCategories,
  //       //   },
  //       // })
  //   }

  // }, [visits])

  useEffect(() => {

    async function getTemplateFields() {
      if (selectedTemplates.length > 0) {

        const targetFields_list = [];
        const targetSeperator_list = [];
        const analysisFields_list = [];

        const template = templates.filter(template => template.name === selectedTemplates[0])[0];

        const templateSectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/templates/" + template.cloud_id + "/sections"))
        const sectionDocs = await getDocs(templateSectionRef);
        sectionDocs.docs.forEach(section => {
          const sectionData = section.data();
          sectionData.list.forEach(item => {

            if (item.type === "Selection") {
              item.options.forEach(option => {
                targetSeperator_list.push({
                  type: "selectionOption",
                  section_item_id: item.section_item_id,
                  section: sectionData.name,
                  field: option
                })
              })
            }
            else if (item.type === "Number") {
              //console.log(item)
              targetSeperator_list.push({
                type: "fieldText",
                section_item_id: item.section_item_id,
                section: sectionData.name,
                field: item.name
              })

            }

            if (item.type === "Number" || item.type === "Item Count") {
              targetFields_list.push({
                type: "fieldText",
                section_item_id: item.section_item_id,
                section: sectionData.name,
                field: item.name
              })

            }
            if (item.type !== "None") {
              analysisFields_list.push({
                section: sectionData.name,
                field: item.name
              })
            }


            // for now only allow analysis on number fields
            // if it works adapt for other fields

            // if(item.type==="Item Comment" || item.type === "Item List") {
            //     item.item_list_comment.forEach(element => {
            //         analysisFields.push(element.product)
            //     })
            // }
            // else if(item.type === "Item Count") {
            //     item.counts.forEach(element => {
            //         analysisFields.push(element.product)
            //     })
            // }
            // else analysisFields.push(item.name)
          })
        })


        setTargetFields(targetFields_list)
        setTargetSeperators(targetSeperator_list);
        setAnalysisFields(analysisFields_list)
      }
    }

    getTemplateFields();

  }, [selectedTemplates])

  async function getVisits() {
    //console.log("Running report")
    let templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x === template.name) >= 0)).map((value) => {
      return value.cloud_id;
    })

    let storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name == store.name) >= 0)).map((value) => {
      return value;
    })

    setVisits([]);

    setSelectedStoresInfo(storeIDList);
    setSelectedTemplatesInfo(templateIDList);
    const dateTo = moment(filterDateTo).add("1", "days").toISOString();

    const elements = [];
    const visitItems = [];

    const visitsTotal = await Promise.all(
      storeIDList.map(store => {
        return Promise.all(
          templateIDList.map(template => {
            return new Promise(async (resolve) => {
              const visitRef = query(
                collection(db, "stores/" + storeDetails.rootID + "/visits"),
                where("template_id", "==", template),
                where("store_id", "==", store.cloud_id),
                where("date", ">=", filterDateFrom),
                where("date", "<=", dateTo)
              );

              const visitDocs = await getDocs(visitRef);
              const mainArray = await Promise.all(
                visitDocs.docs.map(async visit => {
                  const visitData = visit.data();

                  const templateName = templates.find(template => template.cloud_id === visitData.template_id)?.name;
                  const storeName = stores.find(store => store.cloud_id === visitData.store_id)?.name;
                  const regionName = stores.find(store => store.cloud_id === visitData.store_id)?.region;
                  const subRegionName = stores.find(store => store.cloud_id === visitData.store_id)?.sub_region;

                  const visitDate = moment(visitData.date).format("DD-MM-YYYY HH:mm");

                  const sectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visit.id + "/sections"));
                  const sectionDocs = await getDocs(sectionRef);
                  const sectionData = sectionDocs.docs.map(section => section.data());

                  const visitElement = {
                    ...visitData,
                    id: visit.id,
                    templateName: templateName || '',
                    storeName: store.name,
                    region: store.region,
                    sub_region: store.sub_region,
                    visitDate: visitDate,
                    sections: sectionData
                  };

                  return visitElement;
                })
              );

              resolve(mainArray);
            });
          })
        );
      })
    );

    const allVisits = visitsTotal.flat().flat();


    const visitElement = [];

    selectedTargetSeperator.forEach((targetItem) => {

      //console.log("Target", targetItem);


      const filteredVisits = allVisits.filter(visit => {

        let sectionFound;


        sectionFound = visit.sections.find((section) => {
          //console.log("Section", section);
          if (targetItem.type === "selectionOption")
            return section.list.find((field) => (field.section_item_id === targetItem.section_item_id) && field.selectionOption === targetItem.field)
          else if (targetItem.type === "fieldText")
            return section.list.find((field) => (field.section_item_id === targetItem.section_item_id) && field.name === targetItem.field)
        })

        if (sectionFound) return true;
        else return false;

      })

      //console.log("filtered", filteredVisits)

      filteredVisits.forEach(visit => {
        const sectionData = visit.sections;

        const templateName = visit.templateName;
        const visitDate = visit.visitDate;

        const store = stores.find(store => store.cloud_id === visit.store_id);

        selectedTargetFields.forEach((fieldName) => {


          // Find the section that contains this field
          const section = sectionData.find((section) => section.list.some((field) => {
            return field.name === fieldName.field
          }));

          // If no section was found, skip this field
          if (!section) {
            //console.log("Did not find section")
            return;

          }

          // Find the list item that contains this field
          const listItem = section.list.find((field) => field.name === fieldName.field);

          // If no list item was found, skip this field
          if (!listItem) {
            // console.log("Did not find item")
            return;
          }

          //console.log("Found list item", listItem)

          // If the field type is "selection", use the selectionOption field
          let fieldValue = ""

          if (listItem.type === "Number") fieldValue = listItem.fieldText;

          if (listItem.type === "Number") {
            visitElement.push({
              cloud_id: visit.id,
              storeName: store.name,
              region: store.region,
              sub_region: store.sub_region,
              templateName: templateName,
              date: visitDate,
              target_field: fieldValue
            })

            if (selectedAnalysisFields.length === 0) {

              elements.push({
                storeName: store.name,
                region: store.region,
                sub_region: store.sub_region,
                templateName: templateName,
                date: visitDate,
                target_field: fieldValue,
                target_seperator: selectedTargetSeperator[0].field
              })

              visitItems.push({
                id: moment().valueOf() * Math.floor(Math.random() * 1000),
                cloud_id: visit.id,
                storeName: store.name,
                region: store.region,
                sub_region: store.sub_region,
                templateName: templateName,
                date: visitDate,
                section: section.name,
                field: listItem.name,
                field_value: fieldValue
              })
            } else {
              selectedAnalysisFields.forEach(analysisField => {
                const analysisSection = sectionData.find((section) => section.list.some((field) => {
                  return field.name === analysisField.field
                }));

                // If no section was found, skip this field
                if (!analysisSection) {
                  return;
                }

                // Find the list item that contains this field
                const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);

                // If no list item was found, skip this field
                if (!analysisField) {
                  return;
                }

                let analysisValue = ""
                if (analysisItem.type === "YesNo") analysisValue = analysisItem.yesno === true ? "Yes" : "No";
                else if (analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                else if (analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                else if (analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                if (analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                  analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time") {

                  visitItems.push({
                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                    cloud_id: visit.id,
                    storeName: store.name,
                    region: store.region,
                    sub_region: store.sub_region,
                    templateName: templateName,
                    date: visitDate,
                    section: section.name,
                    field: listItem.name,
                    field_value: fieldValue,
                    analysis_field: analysisItem.name,
                    analysis_value: analysisValue
                  })
                }
                else if (analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                  analysisItem.item_list_comment.forEach(newItem => {
                    visitItems.push({
                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                      cloud_id: visit.id,
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: templateName,
                      date: visitDate,
                      section: section.name,
                      field: listItem.name,
                      field_value: fieldValue,
                      analysis_field: analysisItem.name,
                      analysis_value: newItem.product,
                      additional_analysis_value: newItem.comment ? newItem.comment : ""
                    })
                  })
                }
                else if (analysisItem.type === "Item Count") {
                  analysisItem.counts.forEach(newItem => {
                    visitItems.push({
                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                      cloud_id: visit.id,
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: templateName,
                      date: visitDate,
                      section: section.name,
                      field: listItem.name,
                      field_value: fieldValue,
                      analysis_field: analysisItem.name,
                      analysis_value: newItem.product,
                      additional_analysis_value: newItem.count ? newItem.count[0].value : ""
                    })
                  })
                }
                else if (analysisItem.type === "Item Double Count") {
                  analysisItem.counts.forEach(newItem => {
                    visitItems.push({
                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                      cloud_id: visit.id,
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: templateName,
                      date: visitDate,
                      section: section.name,
                      field: listItem.name,
                      field_value: fieldValue,
                      analysis_field: analysisItem.name,
                      analysis_value: newItem.product,
                      additional_analysis_value: newItem.count ? `(${newItem.count[0].value}, ${newItem.count[1].value})` : ""
                    })
                  })
                }

                if (analysisItem.type === "Number") {
                  elements.push({
                    cloud_id: visit.id,
                    storeName: store.name,
                    region: store.region,
                    sub_region: store.sub_region,
                    templateName: templateName,
                    date: visitDate,
                    target_field: fieldValue,
                    analysis_field: analysisValue
                  })
                } else {
                  elements.push({
                    cloud_id: visit.id,
                    storeName: store.name,
                    region: store.region,
                    sub_region: store.sub_region,
                    templateName: templateName,
                    date: visitDate,
                    target_field: fieldValue
                  })
                }
              })
            }

          }

          else if (listItem.type === "Item Count") {
            listItem.counts.forEach(item => {
              visitElement.push({
                cloud_id: visit.id,
                storeName: store.name,
                region: store.region,
                sub_region: store.sub_region,
                templateName: templateName,
                date: visitDate,
                target_field: item.count[0].value
              })


              if (selectedAnalysisFields.length === 0) {

                elements.push({
                  storeName: store.name,
                  region: store.region,
                  sub_region: store.sub_region,
                  templateName: item.product,
                  date: visitDate,
                  target_field: item.count
                })

                visitItems.push({
                  id: moment().valueOf() * Math.floor(Math.random() * 1000),
                  cloud_id: visit.id,
                  storeName: store.name,
                  region: store.region,
                  sub_region: store.sub_region,
                  templateName: templateName,
                  date: visitDate,
                  section: section.name,
                  field: listItem.name,
                  field_value: item.product,
                  additional_field_value: item.count ? item.count[0].value : ""
                })
              } else {
                selectedAnalysisFields.forEach(analysisField => {
                  const analysisSection = sectionData.find((section) => section.list.some((field) => {
                    return field.name === analysisField.field
                  }));

                  // If no section was found, skip this field
                  if (!analysisSection) {
                    return;
                  }

                  // Find the list item that contains this field
                  const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);

                  // If no list item was found, skip this field
                  if (!analysisField) {
                    return;
                  }

                  let analysisValue = ""
                  if (analysisItem.type === "YesNo") analysisValue = analysisItem.yesno === true ? "Yes" : "No";
                  else if (analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                  else if (analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                  else if (analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                  if (analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                    analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time") {
                    visitItems.push({
                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                      cloud_id: visit.id,
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: templateName,
                      date: visitDate,
                      section: section.name,
                      field: listItem.name,
                      field_value: item.product,
                      additional_field_value: item.count ? item.count[0].value : "",
                      analysis_field: analysisItem.name,
                      analysis_value: analysisValue
                    })
                  }
                  else if (analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                    analysisItem.item_list_comment.forEach(newItem => {
                      visitItems.push({
                        id: moment().valueOf() * Math.floor(Math.random() * 1000),
                        cloud_id: visit.id,
                        storeName: store.name,
                        region: store.region,
                        sub_region: store.sub_region,
                        templateName: templateName,
                        date: visitDate,
                        section: section.name,
                        field: listItem.name,
                        field_value: item.product,
                        additional_field_value: item.count ? item.count : "",
                        analysis_field: analysisItem.name,
                        analysis_value: newItem.product,
                        additional_analysis_value: newItem.comment ? newItem.comment : ""
                      })
                    })
                  }
                  else if (analysisItem.type === "Item Count") {
                    analysisItem.counts.forEach(newItem => {
                      visitItems.push({
                        id: moment().valueOf() * Math.floor(Math.random() * 1000),
                        cloud_id: visit.id,
                        storeName: store.name,
                        region: store.region,
                        sub_region: store.sub_region,
                        templateName: templateName,
                        date: visitDate,
                        section: section.name,
                        field: listItem.name,
                        field_value: item.product,
                        additional_field_value: item.comment ? item.comment : "",
                        analysis_field: analysisItem.name,
                        analysis_value: newItem.product,
                        additional_analysis_value: newItem.count ? newItem.count[0].value : ""
                      })
                    })
                  }
                  else if (analysisItem.type === "Item Double Count") {
                    analysisItem.counts.forEach(newItem => {
                      visitItems.push({
                        id: moment().valueOf() * Math.floor(Math.random() * 1000),
                        cloud_id: visit.id,
                        storeName: store.name,
                        region: store.region,
                        sub_region: store.sub_region,
                        templateName: templateName,
                        date: visitDate,
                        section: section.name,
                        field: listItem.name,
                        field_value: item.product,
                        additional_field_value: item.comment ? item.comment : "",
                        analysis_field: analysisItem.name,
                        analysis_value: newItem.product,
                        additional_analysis_value: newItem.count ? `(${item.count[0].value}, ${item.count[1].value})` : ""
                      })
                    })
                  }

                  if (analysisItem.type === "Number") {
                    elements.push({
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: item.product,
                      date: visitDate,
                      target_field: item.count[0].value,
                      analysis_field: analysisValue
                    })
                  }
                  else {
                    elements.push({
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: item.product,
                      date: visitDate,
                      target_field: item.count[0].value
                    })
                  }
                })
              }
            })
          }

          else if (listItem.type === "Item Double Count") {
            listItem.counts.forEach(item => {
              visitElement.push({
                cloud_id: visit.id,
                storeName: store.name,
                region: store.region,
                sub_region: store.sub_region,
                templateName: templateName,
                date: visitDate,
                target_field: `(${item.count[0].value}, ${item.count[1].value})`
              })


              if (selectedAnalysisFields.length === 0) {

                elements.push({
                  storeName: store.name,
                  region: store.region,
                  sub_region: store.sub_region,
                  templateName: item.product,
                  date: visitDate,
                  target_field: `(${item.count[0].value}, ${item.count[1].value})`
                })

                visitItems.push({
                  id: moment().valueOf() * Math.floor(Math.random() * 1000),
                  cloud_id: visit.id,
                  storeName: store.name,
                  region: store.region,
                  sub_region: store.sub_region,
                  templateName: templateName,
                  date: visitDate,
                  section: section.name,
                  field: listItem.name,
                  field_value: item.product,
                  additional_field_value: item.count ? `(${item.count[0].value}, ${item.count[1].value})` : ""
                })
              } else {
                selectedAnalysisFields.forEach(analysisField => {
                  const analysisSection = sectionData.find((section) => section.list.some((field) => {
                    return field.name === analysisField.field
                  }));

                  // If no section was found, skip this field
                  if (!analysisSection) {
                    return;
                  }

                  // Find the list item that contains this field
                  const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);

                  // If no list item was found, skip this field
                  if (!analysisField) {
                    return;
                  }

                  let analysisValue = ""
                  if (analysisItem.type === "YesNo") analysisValue = analysisItem.yesno === true ? "Yes" : "No";
                  else if (analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                  else if (analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                  else if (analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                  if (analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                    analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time") {
                    visitItems.push({
                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                      cloud_id: visit.id,
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: templateName,
                      date: visitDate,
                      section: section.name,
                      field: listItem.name,
                      field_value: item.product,
                      additional_field_value: item.count ? `(${item.count[0].value}, ${item.count[1].value})` : "",
                      analysis_field: analysisItem.name,
                      analysis_value: analysisValue
                    })
                  }
                  else if (analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                    analysisItem.item_list_comment.forEach(newItem => {
                      visitItems.push({
                        id: moment().valueOf() * Math.floor(Math.random() * 1000),
                        cloud_id: visit.id,
                        storeName: store.name,
                        region: store.region,
                        sub_region: store.sub_region,
                        templateName: templateName,
                        date: visitDate,
                        section: section.name,
                        field: listItem.name,
                        field_value: item.product,
                        additional_field_value: item.count ? `(${item.count[0].value}, ${item.count[1].value})` : "",
                        analysis_field: analysisItem.name,
                        analysis_value: newItem.product,
                        additional_analysis_value: newItem.comment ? newItem.comment : ""
                      })
                    })
                  }
                  else if (analysisItem.type === "Item Count") {
                    analysisItem.counts.forEach(newItem => {
                      visitItems.push({
                        id: moment().valueOf() * Math.floor(Math.random() * 1000),
                        cloud_id: visit.id,
                        storeName: store.name,
                        region: store.region,
                        sub_region: store.sub_region,
                        templateName: templateName,
                        date: visitDate,
                        section: section.name,
                        field: listItem.name,
                        field_value: item.product,
                        additional_field_value: item.comment ? item.comment : "",
                        analysis_field: analysisItem.name,
                        analysis_value: newItem.product,
                        additional_analysis_value: newItem.count ? newItem.count[0].value : ""
                      })
                    })
                  }
                  else if (analysisItem.type === "Item Double Count") {
                    analysisItem.counts.forEach(newItem => {
                      visitItems.push({
                        id: moment().valueOf() * Math.floor(Math.random() * 1000),
                        cloud_id: visit.id,
                        storeName: store.name,
                        region: store.region,
                        sub_region: store.sub_region,
                        templateName: templateName,
                        date: visitDate,
                        section: section.name,
                        field: listItem.name,
                        field_value: item.product,
                        additional_field_value: item.comment ? item.comment : "",
                        analysis_field: analysisItem.name,
                        analysis_value: newItem.product,
                        additional_analysis_value: newItem.count ? `(${item.count[0].value}, ${item.count[1].value})` : ""
                      })
                    })
                  }

                  if (analysisItem.type === "Number") {
                    elements.push({
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: item.product,
                      date: visitDate,
                      target_field: `(${item.count[0].value}, ${item.count[1].value})`,
                      analysis_field: analysisValue
                    })
                  }
                  else {
                    elements.push({
                      storeName: store.name,
                      region: store.region,
                      sub_region: store.sub_region,
                      templateName: item.product,
                      date: visitDate,
                      target_field: `(${item.count[0].value}, ${item.count[1].value})`
                    })
                  }
                })
              }
            })
          }

        });
      })
    })

    const sortedData = [...elements].sort((a, b) => new Date(a.date) - new Date(b.date));
    const uniqueDates = [...new Set(sortedData.map((d) => d.date))];

    const targetFieldData = {};
    const analysisFieldData = {};
    const series = [];
    const targetFieldSeperatorData = selectedTargetSeperator.map(target => target.field);

    targetFieldSeperatorData.forEach((targetFieldSeperator) => {
      const targetFieldData = {};

      sortedData.forEach((curr) => {
        //console.log(curr);
        const key = `${curr.storeName}-${curr.templateName}-${targetFieldSeperator}`;

        if (!targetFieldData[key]) {
          targetFieldData[key] = {};
        }

        targetFieldData[key][curr.date] = Number(curr.target_field);
      });

      Object.keys(targetFieldData).forEach((key) => {
        const targetData = uniqueDates.map((date) => ({
          x: date,
          y: targetFieldData[key][date] || null,
        }));

        series.push({
          name: `${key} (${targetFieldSeperator})`,
          data: targetData,
        });
      });
    });

    //console.log("Series", series)
    const totalValue = series[0].data.reduce((acc, current) => {
      //console.log("Curr", current.y)
      return acc + current.y
    }, 0)
    const avgValue = Math.round(totalValue / series[0].data.length)
    const avgSeries = series[0].data.map((item, i) => {
      return { x: item.x, y: avgValue }
    })
    //console.log("Avg Series", avgSeries);
    series.push({
      name: "Average",
      data: avgSeries
    })


    const analysisDates = Object.keys(analysisFieldData)
      .map((key) => Object.keys(analysisFieldData[key]))
      .reduce((acc, curr) => acc.concat(curr), [])
      .filter((value, index, self) => self.indexOf(value) === index);

    if (analysisDates.length > 0) {
      const analysisData = analysisDates.map((date) => {
        const value = Object.keys(analysisFieldData).reduce((acc, key) => {
          if (analysisFieldData[key][date] !== undefined && acc === null) {
            acc = analysisFieldData[key][date];
          }
          return acc;
        }, null);
        return { x: date, y: value };
      });
      series.push({
        name: 'Analysis Field',
        data: analysisData,
        dashStyle: 'Dash',
      });
    }

    const options = {
      chart: {
        type: 'line',
        height: 350,
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: 'Target Field',
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      plotOptions: {
        line: {
          dashArray: [5],
        },
        series: {
          dashArray: [0, 5],
        },
      },
    };

    setOptions(options)
    setSeries(series)
    setVisits(visitItems)


  }

  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleStoreChange = (event, values) => {

    //const storeNames = values.map(value => value.name);
    setSelectedStores(values);
  };

  const handleTemplateChange = (event, values) => {

    const templateNames = values.map(value => value.name);
    setSelectedTemplates(templateNames);
  };

  function handleGroupLocationsClick(region) {
    const locationsGroup = stores.filter(store => store.region === region);
    setSelectedStores(locationsGroup);
  }

  const handleTargetFieldChange = (event, values) => {

    //const templateNames = values.map(value => value.name);
    setSelectedTargetFields([values]);
  };

  const handleTargetSeperatorChange = (event, values) => {

    //const templateNames = values.map(value => value.name);
    setSelectedTargetSeperator([values]);
  };

  const handleAnalysisFieldChange = (event, values) => {

    //const templateNames = values.map(value => value.name);
    setSelectedAnalysisFields(values);
  };

  const handleRowClick = (params) => {
    //console.log(params.row)
    navigate("/app/storevisit/report/" + params.row.cloud_id);
  };

  function handleSearchFilter() {
    getVisits();
  }

  return (
    <Fragment>

      <TemplateFormFieldRow align="right" style={{ margin: '10px 0px', marginBottom: '5px' }}>
        <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>
      </TemplateFormFieldRow>

      {filters &&
        <TemplateFilterContainer style={{ marginTop: '5px' }}>
          {stores && stores.length > 0 &&
            <Autocomplete
              multiple

              id="store-tags"
              options={[...stores].sort((a, b) => {


                const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                if (groupSort && groupSort === 0) {
                  return sortRegions(a, b);
                }
                return groupSort;
              })}
              groupBy={groupByRegion}
              isOptionEqualToValue={(option, value) => option.name === value.name}

              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              value={selectedStores}
              onChange={handleStoreChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a location"
                  placeholder="Add a location"
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>

                  <GroupHeader onClick={() => handleGroupLocationsClick(params.group)}>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />}

          {templates && templates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
              multiple

              id="template-tags"
              options={[...templates].sort((a, b) => {
                const groupA = a.categoryName;
                const groupB = b.categoryName;
                if (groupA === groupB) {
                  return a.name.localeCompare(b.name);
                }
                return groupA.localeCompare(groupB);
              })}
              isOptionEqualToValue={(option, value) => option.name === value.name}

              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              groupBy={(option) => option.categoryName}
              onChange={handleTemplateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a template"
                  placeholder="Add a template"
                />
              )}
            />}

          {selectedTemplates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}


              id="target-tags"
              options={targetFields}
              isOptionEqualToValue={(option, value) => option.field === value.field}

              getOptionLabel={(option) => option.field}
              filterSelectedOptions
              groupBy={(option) => option.section}
              onChange={handleTargetFieldChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Select target fields"
                  placeholder="Target fields"
                />
              )}
            />}

          {selectedTemplates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}


              id="target-selection"
              options={targetSeperators}
              isOptionEqualToValue={(option, value) => option.field === value.field}

              getOptionLabel={(option) => option.field}
              filterSelectedOptions
              groupBy={(option) => option.section}
              onChange={handleTargetSeperatorChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Select target seperator"
                  placeholder="Target seperator"
                />
              )}
            />}

          {selectedTemplates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
              multiple

              id="analysis-tags"
              options={analysisFields}
              isOptionEqualToValue={(option, value) => option.field === value.field}

              getOptionLabel={(option) => option.field}
              filterSelectedOptions
              groupBy={(option) => option.section}
              onChange={handleAnalysisFieldChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Select analysis fields"
                  placeholder="Analysis fields"
                />
              )}
            />}


          <TemplateFormFieldDateContainer>
            <TextField
              sx={{ 'marginRight': '10px' }}
              label="Period from"
              size='small'
              type="date"
              value={filterDateFrom}
              onChange={(e) => setFilterDateFrom(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Period to"
              type="date"
              size='small'
              fullWidth
              value={filterDateTo}
              onChange={(e) => setFilterDateTo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />


          </TemplateFormFieldDateContainer>

          <Button disabled={selectedStores.length === 0 || selectedTemplates.length === 0} highlighted onClick={handleSearchFilter} style={{ margin: '10px 0px' }} fullWidth>Run Report</Button>

        </TemplateFilterContainer>}

      <Chart
        options={options}
        series={series}
        width="100%"
        height="400"
      />

      {visits && visits.length > 0 &&
        <TableContainer style={{ 'marginTop': '20px' }}>
          <DataGrid
            autoHeight
            sx={{ 'border': '0' }}
            components={{ Toolbar: GridToolbar }}
            rows={visits}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'asc' }],
              },
            }}
            onRowClick={handleRowClick}
            getRowId={(row) => row.id}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </TableContainer>


      }
    </Fragment>
  )
}

export default ReportQuantityAnalysis;