import React, {useEffect, useState} from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Button, Text, HeaderTitle, HeaderSubTitle, Card, CardContainer, PageContainer, NewButton, EmptyContainer, EmptyContainerText, PageHolder, StyledTemplateIcon, HeaderHolder } from './styled/styled.container'
import { db } from "./firebase";
import {collection, onSnapshot, query, doc, where, getDocs} from "firebase/firestore";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { TableContainer } from './styled/styled.container'
import Loader from './Loader';
import { TemplateDescription, TemplateFormFieldRow } from './styled/styled.templateform';
import AuthContext from './AuthContext';
import { useContext } from 'react';
import Header from './Header';
import EmptyScreen from './EmptyScreen';
import EmptyAuditImg from "./imgs/audit_img.png"
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import { TemplateShareDialog } from './components/TemplateShareDialog';

function TemplatesView(props) {

  const [templates, setTemplates] = useState([]);
  const [headerTitle, setHeaderTitle] = useState("");
  const {storeDetails} = useContext(AuthContext)
  const [showLoading, setShowLoading] = useState(false)
  const [showShareDialog, setShowShareDialog] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const params = useParams();

  useEffect(() => {
    const id = params.id;
    const categories = storeDetails.template_categories;

    const categoryItem = categories.find(category => category.cloud_id === id)

    if(!categoryItem)
      navigate(-1);
    else
      setHeaderTitle(categoryItem.name)

  }, [params])

  useEffect(() => {

    setShowLoading(true)
    if(storeDetails.rootID.length > 0) {

 
      let templateList = storeDetails.user_specific_templates;
      if(templateList.length > 0) {
        templateList = templateList.filter(template => template.category === params.id)
        setTemplates(templateList)
      }
      //console.log(templateList);
      
      setShowLoading(false)
    }
  }, [storeDetails])

  const navigate = useNavigate();

  const columns = [
    { field: "cloud_id", headerName: "ID", hide: true},
    { field: 'name', headerName: 'Template Name', minWidth: 300, flex: 1},
    { field: 'description', headerName: "Description", minWidth: 300, flex: 1},
    { field: 'template_type', headerName: 'Type', width: 150},
    {
      field: 'actions',
      headerName: '',
      minWidth: 150,
      renderCell: (params) => {
          return (
              <div>
                  <IconButton sx={{'padding': '5px', marginRight: '10px', }} onClick={() => handleRowClick(params)}>
                  <EditIcon style={{ cursor: 'pointer' }}  />
                  </IconButton>
                  <IconButton sx={{'padding': '5px'}} onClick={(e) => handleShareRowClick(e, params)}>
                  <ShareIcon style={{ cursor: 'pointer' }}  />
                  </IconButton>
                  
              </div>
          );
      }
  }
    
  ];

  const handleShareRowClick = (event, params) => {
    event.stopPropagation();
    //console.log(params.row);
    setSelectedTemplate({
      id: params.row.cloud_id,
      type: params.row.isPublic ? "public" : "internal",
      templateId: params.row.cloud_id,
      name: params.row.name 
    
    })

    setShowShareDialog(true)
  }

  const handleRowClick = (params) => {
    if(storeDetails.permissions.templates.edit)
      navigate("/app/templates/modify/" + params.row.cloud_id);
  };

  async function handleAddNewTemplates() {
    const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/add-new-templates', {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        storeid: "storecall"
      })
    })

    if(result.status === 200) {
      console.log("Added successfull!")
    } else {
      console.log("Error adding")
    }
  }

  return (
    <PageHolder>

      {showShareDialog && 
      <TemplateShareDialog 
      open={showShareDialog} 
      setOpen={setShowShareDialog} 
      store={storeDetails.rootID} 
      templateDetails={selectedTemplate}
      />}

      <Header>
        <HeaderHolder>
        {headerTitle} templates
        <TemplateDescription>Select a template to customize or start creating something new.</TemplateDescription>
        </HeaderHolder>
        
      </Header>
      
    <PageContainer style={{'padding': '0px'}}>
        <Loader show={showLoading} />

        
        {templates.length > 0 &&
        <TableContainer style={{padding: '0px', 'marginBottom': '0px'}}>
        <DataGrid
            autoHeight
            sx={{'border': '0'}}
            
            rows={templates}
            columns={columns}
            onRowClick={handleRowClick}
            getRowId={(row) => row.cloud_id}
            pageSize={10}
            rowsPerPageOptions={[10]}
        />
        </TableContainer>
      }
      

        {templates.length === 0 &&
          <EmptyScreen
          visible={true} 
          imgSrc={EmptyAuditImg}
          title="Looks like this template category is empty"
          contents={"Start adding templates to this category to view its templates."} />
        }

        
    </PageContainer>

    {storeDetails.permissions.templates.add &&
        <TemplateFormFieldRow align="right">
          <Button style={{'marginTop': '10px'}} onClick={() => navigate("/app/templates/new")}>
          <StyledTemplateIcon />
          New template
        </Button>
        </TemplateFormFieldRow>
        }
    </PageHolder>
  )
}

export default TemplatesView;