import styled from "styled-components";
import { Colors } from "./styled.container";

export const FieldText = styled.div`
    width: 100%;
    display: flex;
    padding: 10px;
    background-color: ${Colors.light_grey};
    border-radius: 10px;
`

export const FieldTypeComment = styled.input`
    border: 0;
    outline: 0;
    width: 100%;
    padding: 10px;
    color: ${Colors.blue};
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin: 0px 10px;
    border-radius: 10px;
    background-color: ${Colors.white};

    &::placeholder {
        color: ${Colors.blue};
        font-weight: 600;
    }
`

export const VisitToolbar = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
`

export const ToolbarItem = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const VisitHeaderContainer = styled.div`
    border-radius: 10px;
    display: flex;
    background-color: ${Colors.light_grey};
    background: #396afc;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    flex-direction: column;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    width: 100%;
    margin-bottom: 20px;
`

export const VisitHeaderItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    
    
`

export const VisitHeaderItem = styled.div`
    font-size: 0.8rem;
    margin: 7px 0px;
    display: flex;

    align-items: center;
    ${props => props.cursor && `cursor: pointer;`}
    
    font-weight: 500;
    //background-color: ${props => props.title ? `${Colors.white}` : "inherit"} ;
    padding: 0px 10px;
    border-radius: 10px;
    
    color: ${props => props.title ? `${Colors.white}` :  `${Colors.white}`} ;
    text-align: ${props => props.title && "right"};
    
    @media (max-width: 500px) {
        font-size: 0.7rem
    }
`

export const VisitActionsContainer = styled.div`

    padding: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    border-radius: 30px;

    color: ${Colors.dark_grey};


    text-align: center;
    cursor: pointer;
    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px;
    transition: all 0.3s ease-in-out;

    &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

`

export const VisitActionsText = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-right: 10px;
    font-size: 0.8rem;
    justify-content: center;


`

export const VisitCardContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px;
    gap: 10px;
    margin-bottom: 20px;
    background-color: ${Colors.white};
`

