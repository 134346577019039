
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderTitle, StyledBackIcon } from './styled/styled.container';
import { TemplateFormFieldRow } from './styled/styled.templateform';

function Header({children}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showBack, setShowBack] = useState(true);
  
  useEffect(() => {

    if(location.pathname === "/")
      setShowBack(false)
  }, [location])

  function goBack() {
    navigate(-1);
  }

  return (
    <TemplateFormFieldRow style={{marginTop: '0px', marginBottom: '5px'}}>
        
        <HeaderTitle style={{marginBottom: '5px'}}>
        {showBack &&
        <StyledBackIcon style={{marginRight: '10px'}} size={32} onClick={goBack} />}
          
          {children}
        </HeaderTitle>
    </TemplateFormFieldRow>
    
  )
}

export default Header