import React, {useRef, useState} from 'react'
//import { Button } from '@mui/material';
import { Button, Colors, FileUploadButton } from './styled/styled.container';
import { TemplateFormFieldFiles } from './styled/styled.templateform'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function FileUploader(props) {

    const hiddenFileInput = useRef(null);
    const [files, setFiles] = useState([]);
    const {section_item_id, handleFilesUpload, allowMultipleFiles, size, canEdit} = props;
    const [inputKey, setInputKey] = useState(0)
  
    const handleClick = event => {
        if(!canEdit) return;
        
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const filesUploaded = event.target.files;
        handleFilesUpload(section_item_id, Array.from(filesUploaded))
        setInputKey(prevState => prevState + 1)
    };

  return (
    <div >
    
    <FileUploadButton onClick={handleClick}><CloudUploadIcon /></FileUploadButton>
    <TemplateFormFieldFiles 
        ref={hiddenFileInput}
        multiple={allowMultipleFiles}
        onChange={handleChange} 
        style={{display:'none'}}
        key={inputKey} 
        type="file" />
    </div>
  )
}

export default FileUploader