import React, {useEffect, useState} from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';

import StorevisitStart from './StorevisitStart';
import StorevisitForm from './StorevisitForm';
import StorevisitView from './StorevisitView';
import StorevisitReportRoute from './StorevisitReportRoute';
import StorevisitReport from "./StorevisitReport"
import ErrorVisit from './ErrorVisit';
import StorevisitViewTemplates from './StorevisitViewTemplates';
import StorevisitViewUnpublished from './StorevisitViewUnpublished';

function Storevisit(props) {

  const {storeDetails, showVisitType, setShowVisitType} = props;
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedStore, setSelectedStore] = useState();
  const navigate = useNavigate();

  useEffect(() => {

    if(!storeDetails.permissions.visits.view) navigate("/app")

  }, [storeDetails])

  useEffect(() => {

    if(selectedStore)
    {
      const jsonItem = JSON.stringify(selectedStore);
      localStorage.setItem("selected_store", jsonItem)
    }
    if(selectedTemplate)
    {
      const jsonItem = JSON.stringify(selectedTemplate)
      localStorage.setItem("template", jsonItem)
    }
  }, [selectedStore, selectedTemplate])

  function updateSelectedStore(store) {
    setSelectedStore(store)
  }

  function updateSelectedTemplate(template) {
    setSelectedTemplate(template)
  }

  function handleStoreVisit() {
    navigate("/app/report");
  }

  return (
    <>
    <Routes>
      <Route path='/' element={<StorevisitViewTemplates storeDetails={storeDetails} showVisitType={showVisitType} updateSelectedStore={updateSelectedStore} selectedTemplate={selectedTemplate} updateSelectedTemplate={updateSelectedTemplate} />} />
      <Route path='/unpublished' element={<StorevisitViewUnpublished storeDetails={storeDetails} showVisitType={showVisitType} setShowVisitType={setShowVisitType} />} />
      <Route path='/:template_id' element={<StorevisitView storeDetails={storeDetails} showVisitType={showVisitType} setShowVisitType={setShowVisitType} />} />
      <Route path='/:actions' element={<StorevisitView storeDetails={storeDetails} showActions={true} />} />
      <Route path='/:store/:template/:actions'  element={<StorevisitView storeDetails={storeDetails} showActions={true} />} />
      <Route path='/new' element={<StorevisitStart storeDetails={storeDetails} selectedStore={selectedStore} updateSelectedStore={updateSelectedStore} selectedTemplate={selectedTemplate} updateSelectedTemplate={updateSelectedTemplate} />} />
      <Route path='/new/:templateid' element={<StorevisitStart storeDetails={storeDetails} selectedStore={selectedStore} updateSelectedStore={updateSelectedStore} selectedTemplate={selectedTemplate} updateSelectedTemplate={updateSelectedTemplate} />} />
      <Route path="/report/:id/*" element={<StorevisitReportRoute storeDetails={storeDetails} />} />
      <Route path='/start' element={<StorevisitForm storeDetails={storeDetails} store={selectedStore} template={selectedTemplate} />} />
      <Route path='/start/:storeid/:templateid' element={<StorevisitForm storeDetails={storeDetails} store={selectedStore} template={selectedTemplate} />} />
      <Route path='/start/:storeid/:templateid/:draft' element={<StorevisitForm storeDetails={storeDetails} store={selectedStore} template={selectedTemplate} />} />
      <Route path='/error' element={<ErrorVisit />} />
    </Routes>

    </>
  )
}

export default Storevisit