import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { HeaderSubTitle, HeaderTitle, Button, StyledCopyIcon, Colors, AddOptionButton, StyledAddOptionIcon, PageHolder, StyledUpdateIcon, StyledTemplateIcon, StyledDescriptionIcon, StyledEditIcon, ButtonOutlined, Text, ColumnContainer, StyledTextField } from './styled/styled.container'
import { TemplateFormFieldContainer, Input, TemplateFormButtonHolder, TemplateFormFieldNewFieldButton, TemplateFormFieldNewField, TemplateFormField, TemplateFormFieldText, TemplateFormFieldType, TemplateFormFieldRow, TemplateFormFieldAssignable, TemplateFormFieldNumber, TemplateFormFieldLabel, TemplateFormFieldButton, TemplateFormTitle, TemplateFormEmptyContainer, TemplateFormEmptyContainerText, TemplateFormErrorContainer, TemplateFormErrorContainerText, TemplateFormFieldAllowComment, TemplateSection, TemplateFormButtonContainer, TemplateTimerContainer, TemplateFormFieldFilters, BubbleContainer } from './styled/styled.templateform';
import { StyledClosedIcon, PageContainer, EmptyContainer, EmptyContainerText } from './styled/styled.container';
import { auth, db } from "./firebase";
import { collection, onSnapshot, query, doc, setDoc, orderBy, deleteDoc, addDoc, getDoc, updateDoc, getDocs, where, arrayUnion } from "firebase/firestore";
import { FieldTypes, TemplateTypes } from './styled/styled.container';
import { TextField, InputAdornment, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, IconButton, ListSubheader, ButtonGroup } from '@mui/material';
import AlertDialog from "./AlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import Loader from './Loader';
import { TemplateSectionHeader } from './styled/styled.templateform';
import { StyledAddIcon, StyledDeleteIcon, StyledUpArrowIcon, StyledDownArrowIcon } from './styled/styled.container';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InventoryIcon from '@mui/icons-material/Inventory';
import ErrorDialog from './ErrorDialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Header from "./Header"
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
//import { UserTypes } from './styled/styled.container';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import InfoDialog from './InfoDialog';
import { useTheme } from '@material-ui/core/styles';
import SectionTemplate from './SectionTemplate';
import { useContext } from "react";
import AuthContext from "./AuthContext";
import { InputField } from './InputField';
import moment from 'moment';
import TemplatesManagerCategories from './TemplatesManagerCategories';
import logEvent from './hooks/logEvent';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    section: {
        "& .MuiFilledInput-root": {
            backgroundColor: "rgb(232, 241, 250)"
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "rgb(250, 232, 241)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: "rgb(232, 241, 250)"
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "rgb(250, 241, 232)"
        }
    }
}));




function TemplateForm(props) {

    const classes = useStyles();

    const [sections, setSections] = useState([]);
    const [templateID, setTemplateID] = useState("");

    const [templateName, setTemplateName] = useState("")
    const [templateDescription, setTemplateDescription] = useState("")
    const [selectedGroups, setSelectedGroups] = useState([]); // group selection
    const [selectedUsers, setSelectedUsers] = useState([]) // selected users based on group selection
    const [selectedTemplateCategory, setSelectedTemplateCategory] = useState("")
    const [isTemplateInternal, setIsTemplateInternal] = useState(true);
    const [publicTemplateDateRange, setPublicTemplateDateRange] = useState({ start: moment().format("YYYY-MM-DD"), end: null })
    const [savedUsersEmail, setSavedUsersEmail] = useState(); // current saved email user uids
    const [timedTemplate, setTimedTemplate] = useState(false);
    const [defaultStatusClosed, setDefaultStatusClosed] = useState(false);
    const [requireSignature, setRequireSignatue] = useState(false)
    const [selectedTemplateType, setSelectedTemplateType] = useState("Audit");
    const [emailStore, setEmailStore] = useState(false)
    const [locationRequired, setLocationRequired] = useState(false)
    const [timerValue, setTimerValue] = useState(30);
    const [templateHeader, setTemplateHeader] = useState("Create new template");
    const [templateSubTitle, setTemplateSubtitle] = useState("Choose the form fields that you would like to create")
    const [errorMessage, setErrorMessage] = useState("");
    const [errorContent, setErrorContent] = useState({});
    const [showError, setShowError] = useState(false);
    const [showFieldError, setShowFieldError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedItemID, setSelectedItemID] = useState(null);
    const [showInfoDialog, setShowInfoDialog] = useState(false)
    const [dialogContents, setDialogContents] = useState({
        title: '',
        contents: '',
        extra_content: ''
    })
    const { storeDetails, setStoreDetails } = useContext(AuthContext)
    const [availableGroups, setAvailableGroups] = useState(useState(storeDetails.user_groups))
    // const [availableGroups, setAvailableGroups] = useState(() => {
    //     return(
    //         UserTypes.filter(users => users !== "admin")
    //     )
    // })
    const [userList, setUserList] = useState([])
    const [availableUsers, setAvailableUsers] = useState([])
    const [newUsers, setNewUsers] = useState([])

    const [showDeleteSectionDialog, setShowDeleteSectionDialog] = useState(false);
    const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [selectedSection, setSelectedSection] = useState();
    const [openBar, setOpenBar] = React.useState(false);
    const [barMessage, setBarMessage] = useState({})
    const theme = useTheme();

    const [showTemplateCategoryDialog, setShowTemplateCategoryDialog] = useState(false);

    const params = useParams();
    const navigate = useNavigate();
    let templateQueryCollectionRef = "";
    let templateCollectionRef = "";
    let templateRootRef = "";

    useEffect(() => {


        async function getUserList() {
            
            const user_list = [...storeDetails.all_users].map(user => {
                return { ...user, group_name: storeDetails.user_groups.find(group => group.id === user.group).name }
            })

            const sortedUsers = [...user_list].sort((a, b) => a.type.localeCompare(b.type));
            
            setUserList(sortedUsers);
        }

        if (storeDetails.rootID.length > 0) {
            //console.log(storeDetails.permissions)
            if (!storeDetails.permissions.templates.edit && !storeDetails.permissions.templates.add) {
                navigate("/app/templates");
            }
            else getUserList();
        }

    }, [storeDetails])

    useEffect(() => {

        async function getSections() {
            setSections([])

            const template_id = params.id;
            if (template_id) {

                setShowLoading(true)

                setTemplateHeader("Edit your template")
                setTemplateSubtitle("Choose the form fields that you would like to modify")
                setTemplateID(template_id);

                templateRootRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + template_id);

                const result = await getDoc(templateRootRef);
                if (result.exists()) {
                    const data = result.data();
                    setTemplateName(data.name);
                    setTemplateDescription(data.description || "");

                    const groups = data.permission_users;
                    const users = data.email_users;
                    const email_store = data.email_store;
                    console.log("DB internal", data.isPublic)
                    const template_internal = data.isPublic === undefined ? true : !data.isPublic;
                    const public_template_start = data.public_template_start === undefined ? moment().format("YYYY-MM-DD") : data.public_template_start;
                    const public_template_end = data.public_template_end === undefined ? null : data.public_template_end;
                    const template_type = data.template_type;
                    const default_status_closed = data.default_status_closed;
                    const location_required = data.location_required;
                    const selected_id = data.selected_item_id;
                    const signature_required = data.signature_required;

                    if (signature_required !== undefined) setRequireSignatue(signature_required);
                    if (selected_id !== undefined && selected_id !== "none") setSelectedItemID(selected_id);

                    if (location_required !== undefined) setLocationRequired(location_required);

                    if (template_type) setSelectedTemplateType(template_type);
                    if (default_status_closed) setDefaultStatusClosed(true);

                    if (email_store) setEmailStore(email_store);
                    else setEmailStore(false);

                    if (users) {
                        setSavedUsersEmail(users);
                    }

                    const templateCategory = data.category;
                    const templateCategoryElement = storeDetails.template_categories.find(category => category.cloud_id === templateCategory);

                    setSelectedTemplateCategory(templateCategoryElement.cloud_id);
                    
                    setPublicTemplateDateRange({ start: public_template_start, end: public_template_end })
                    setIsTemplateInternal(template_internal);

                    
                    setSelectedGroups(groups);

                    const isTimed = data.timer;
                    if (isTimed) {
                        setTimedTemplate(isTimed);
                        setTimerValue(data.timer_mins);
                    }
                }

                templateQueryCollectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/templates/" + template_id + "/sections"), orderBy("key"));
                templateCollectionRef = collection(db, "stores/" + storeDetails.rootID + "/templates/" + template_id + "/sections");

                const templateDocs = await getDocs(templateQueryCollectionRef);
                const sectionItems = await Promise.all(templateDocs.docs.map(item => {
                    return new Promise((resolve) => {
                        const sectionID = item.id;
                        const sectionData = item.data();

                        // to allow for updates on the model list item keys
                        // this will allow for future updates
                        // ammend the fieldObject and it will append the keys that do not exist
                        const listCheckAmend = sectionData.list.map(element => {
                            return checkUpdateListItemModel(element)
                        })

                        const sectionItem = {
                            ...sectionData,
                            //template_id: templateID,
                            section_id: sectionID,
                            name: sectionData.name,
                            list: listCheckAmend,
                            uploaded: true
                        }

                        resolve(sectionItem)
                    })

                }))

                setSections(sectionItems);
                setShowLoading(false)
            }
        }

        if (storeDetails.rootID.length > 0) getSections();

    }, [])

    useEffect(() => {

        async function getAvailableUsers() {

            if (userList.length > 0) {

                let filteredGroups = [...availableGroups];

                let availableUserList = [...userList].filter(user => {
                    if (user.type === "admin") return true;
                    else return selectedGroups.some(group => group === user.group)
                })

                
                // if there is a saved list of emails already
                if (savedUsersEmail && savedUsersEmail.length > 0) {

                    // go through the saved emails and check if email belongs to available list of users
                    const usersAdd = savedUsersEmail.map(savedUser => {

                        if (availableUserList.filter(availableUser => availableUser.uid === savedUser).length > 0) {

                            //const existingUser = availableUserList.filter(user => user.email === savedUser)[0]
                            //console.log(existingUser)
                            const exisiting = availableUserList.filter(user => user.uid === savedUser)[0];

                            return exisiting;
                        } else {

                            // email does not belong to available users but user added as 3rd party to email
                            const newUser = {
                                uid: savedUser,
                                name: savedUser,
                                email: savedUser,
                                type: "New"
                            }
                            return newUser
                        }
                    })


                    setSelectedUsers(usersAdd)

                }

                setAvailableUsers(availableUserList);
            }
        }

        getAvailableUsers();

    }, [availableGroups, userList, savedUsersEmail, selectedGroups])

    useEffect(() => {

        if (selectedUsers.length > 0) {
            const updatedSelectedUsers = selectedUsers.filter(user => (selectedGroups.indexOf(user.type).length > -1) || user.admin);

            setSelectedUsers(updatedSelectedUsers)
            //setSelectedUsers(prevState => prevState.filter(user => (selectedGroups.indexOf(user.type).length > -1) || user.type === "admin"))
        }


    }, [selectedGroups])

    const assignableTypes = [
        "Yes", "No"
    ]

    const fieldObject = {
        section_item_id: 0,
        name: "",
        type: "Comment",
        allowComments: true,
        filesAllowed: true,
        actionAllowed: true,
        editAllowed: false,
        additionalOptions: true,
        listCheckboxComments: false,
        scorable: true,
        options: [],
        counts: [],
        item_list_comment: [],
        item_list_checkbox: []
    }

    function checkUpdateListItemModel(list) {
        for (const key in fieldObject) {
            if (!list.hasOwnProperty(key)) {
                list[key] = fieldObject[key]
            }
        }
        return list
    }

    const styles = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: 400,
        marginTop: 5,
        marginBottom: 5,

    };

    const handleSelectedUserGroupChange = (event, value) => {
        setSelectedGroups(value.map((option) => option.id));
    };

    const handleSelectedCategoryChange = (event) => {

        setSelectedTemplateCategory(event.target.value);
    };

    //   const handleUpdateSection = useCallback((sectionProp) => {
    //     const sectionIndex = sections.findIndex(section => section.section_id === sectionProp.section_id);
    //     setSections(prevSections => {
    //       const updatedSections = [...prevSections];
    //       updatedSections[sectionIndex] = sectionProp;
    //       return updatedSections;
    //     });
    //   }, [sections]);
    const handleUpdateSection = useCallback((updatedSection) => {
        // Update the sections array in the main component
        // based on the updatedSection passed from the section component.
        const updatedSections = sections.map(section =>
            section.section_id === updatedSection.section_id ? updatedSection : section
        );

        setSections(updatedSections);
    }, [sections]);

    const handleClick = () => {
        setOpenBar(true);
    };

    const handleClose = (event, reason) => {

        setOpenBar(false);
        setShowLoading(false)
        if (barMessage.result === "success") navigate("/app/templates")
    };

    const handleGroupChange = (event) => {
        const {
            target: { value },
        } = event;

        setSelectedGroups(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    const handleUserChange = (event, values) => {
        const lastElement = values[values.length - 1]

        if (values === '') return;

        if (typeof lastElement === 'string') {
            if (isValidEmail(lastElement)) {

                // if the manually entered email already exists don't enter
                if (selectedUsers.filter(user => user.email === lastElement).length > 0) {
                    return;

                }

                const newUser = {
                    uid: lastElement,
                    name: lastElement,
                    email: lastElement,
                    type: "New"
                }
                setSelectedUsers([...selectedUsers, newUser])

                if (availableUsers.filter(user => user.email === lastElement).length === 0) {
                    setDialogContents({
                        title: 'Email User',
                        contents: `${lastElement} does not belong to the user groups that you have selected, and will not be able to access this template on Storecall.`,
                        extra_content: 'If this was intended, you may continue with only sending an email to the new user.'
                    })
                    setShowInfoDialog(true)
                }

            }
        }

        else setSelectedUsers(values);
    };

    const handleTimerSliderChange = (event, newValue) => {
        if (newValue < 1) setTimerValue(1);
        else setTimerValue(newValue);
    };

    const handleTimerInputChange = (event) => {
        setTimerValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleTimerCheck = () => {
        if (timerValue < 1) {
            setTimerValue(1);
        } else if (timerValue > 122) {
            setTimerValue(120);
        }
    };

    function addNewField(section_id) {

        const newField = {
            ...fieldObject,
            "section_item_id": (Math.floor(Date.now() + Math.random()) + '')
        };

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return { ...el, list: [...el.list, newField] }
            }
            else return el;
        })))
    }

    function addNewSection() {
        setSections(prevState => ([...prevState, {
            section_id: (Math.floor(Date.now() + Math.random()) + ''),
            name: "",
            list: [],
            uploaded: false
        }]))
    }

    function duplicateSection(section, i) {

        const new_section = {
            ...section,
            uploaded: false,
            section_id: (Math.floor(Date.now() + Math.random()) + '')
        };

        const updatedSections = sections.slice(0);
        updatedSections.splice(i, 0, new_section);

        setSections(updatedSections);
    }

    function updateSectionName(section_id, text) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return { ...el, name: text }
            }
            else return el;
        })))

    }

    function updateFormFieldName(section_id, section_item_id, text) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, name: text }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function addFormFieldOption(section_id, section_item_id, text) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {

                            return { ...item, options: [...item.options, text] }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function addFormFieldCount(section_id, section_item_id, product) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {

                            return {
                                ...item, counts: [...item.counts,
                                {
                                    product: product, count: 0
                                }]
                            }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function updateFormFieldCount(section_id, section_item_id, index, product) {
        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {

                            return {
                                ...item, counts: item.counts.map((option, i) => {
                                    if (i === index) return { product: product, count: 0 }
                                    else return option
                                })
                            }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function removeFormFieldCount(section_id, section_item_id, i) {
        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, counts: item.counts.filter((option, index) => index !== i) }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function addFormFieldItemComment(section_id, section_item_id, product) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {

                            return {
                                ...item, item_list_comment: [...item.item_list_comment,
                                {
                                    product: "", comment: ""
                                }]
                            }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function updateFormFieldItemComment(section_id, section_item_id, index, product) {
        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {

                            return {
                                ...item, item_list_comment: item.item_list_comment.map((option, i) => {
                                    if (i === index) return { product: product, comment: "" }
                                    else return option
                                })
                            }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function removeFormFieldItemComment(section_id, section_item_id, i) {
        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, item_list_comment: item.item_list_comment.filter((option, index) => index !== i) }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function updateFormFieldOption(section_id, section_item_id, index, text) {
        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {

                            return {
                                ...item, options: item.options.map((option, i) => {
                                    if (i === index) return text
                                    else return option
                                })
                            }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function removeFormFieldOption(section_id, section_item_id, i) {
        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, options: item.options.filter((option, index) => index !== i) }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function updatedFormFieldType(section_id, section_item_id, selection) {
        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            // reset option list on change of type
                            return { ...item, type: selection, options: [] }
                        } else return item;
                    })
                }
            }
            else return el;
        })))
    }

    function updatedFormFieldAssignable(section_id, section_item_id, selection) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, assignable: selection === "Yes" ? true : false }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function updatedFormFieldAllowComments(section_id, section_item_id, selection) {


        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, allowComments: selection }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function updatedFormFieldScorable(section_id, section_item_id, scorable) {



        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, scorable: scorable }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function updateFormFieldFilesAllowed(section_id, section_item_id, files_allowed) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, filesAllowed: files_allowed }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function updateFormFieldActionAllowed(section_id, section_item_id, action_allowed) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, actionAllowed: action_allowed }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function updateFormFieldEditAllowed(section_id, section_item_id, edit_allowed) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, editAllowed: edit_allowed }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function updateFormFieldAdditionalOptionsAllowed(section_id, section_item_id, additional_options_allowed) {

        setSections(prevState => (prevState.map(el => {
            if (el.section_id === section_id) {
                return {
                    ...el,
                    list: el.list.map(item => {
                        if (item.section_item_id === section_item_id) {
                            return { ...item, additionalOptions: additional_options_allowed }
                        } else return item;
                    })
                }
            }
            else return el;
        })))

    }

    function deleteFormField(section_id, section_item_id) {

        if (templateID.length > 0) {
            const sectionItemRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections/" + section_id + "/list/" + section_item_id);
            deleteDoc(sectionItemRef).then(result => {

            })
        }

        setSections(prevState => (prevState.map(el => {
            //console.log(el);
            if (el.section_id === section_id) {
                return { ...el, list: el.list.filter(item => item.section_item_id !== section_item_id) };
            }
            else return el;
        })));

    }

    function handleMoveFieldUp(section_id, currentIndex) {
        if (currentIndex === 0) return;

        setSections(prevState => prevState.map(el => {
            if (el.section_id === section_id) {
                const newList = [...el.list];
                const element = newList.splice(currentIndex, 1)[0];
                newList.splice(currentIndex - 1, 0, element);

                return { ...el, list: newList }
            }
            else return el;
        }))

    }

    function handleMoveFieldDown(section_id, currentIndex, maxIndex) {
        if (currentIndex === maxIndex) return;

        setSections(prevState => prevState.map(el => {
            if (el.section_id === section_id) {
                const newList = [...el.list];
                const element = newList.splice(currentIndex, 1)[0];
                newList.splice(currentIndex + 1, 0, element);

                return { ...el, list: newList }
            }
            else return el;
        }))

    }

    // const handleUpdateSection = useCallback((sectionProp) => {
    //     const sectionIndex = sections.findIndex(section => section.section_id === sectionProp.section_id);
    //     setSections(prevSections => {
    //       const updatedSections = [...prevSections];
    //       updatedSections[sectionIndex] = sectionProp;
    //       return updatedSections;
    //     });
    //   }, [sections]);

    const handleMoveSectionUp = useCallback((section_id) => {
        const currentIndex = sections.findIndex(section => section.section_id === section_id)
        if (currentIndex === 0) return;

        const newSections = [...sections];
        //console.log(currentIndex)

        const section = newSections.splice(currentIndex, 1)[0];
        newSections.splice(currentIndex - 1, 0, section);
        //console.log(newSections)        
        setSections(newSections)
    }, [sections])

    function handleMoveSectionDown(section_id) {
        const currentIndex = sections.findIndex(section => section.section_id === section_id)

        if (currentIndex === sections.length - 1) return;

        const newSections = [...sections];

        const section = newSections.splice(currentIndex, 1)[0];
        newSections.splice(currentIndex + 1, 0, section);

        setSections(newSections)

    }

    function handleDeleteTemplateDialog() {
        setShowDeleteTemplateDialog(true);
    }

    function handleDeleteSectionDialog(section_id) {
        setSelectedSection(section_id);
        setShowDeleteSectionDialog(true);
    }

    function deleteSection(section_id) {
        setShowDeleteSectionDialog(false);

        if (templateID.length > 0) {
            const sectionItemRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections/" + section_id);

            deleteDoc(sectionItemRef).then(result => {

            })
        }

        setSections(prevState => (prevState.filter(section => section.section_id !== section_id)));
    }

    function handleCloseDeleteTemplateDialog() {
        setShowDeleteTemplateDialog(false)
    }

    async function getIDToken() {
        const token = await auth.currentUser.getIdToken(true)
        return token;
    }

    async function deleteTemplate() {

        setShowDeleteTemplateDialog(false)
        setShowLoading(true);

        const idToken = await getIDToken();

        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-template', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken,
                templateid: templateID,
                storeid: storeDetails.rootID
            })
        })

        if (result.status === 200) {

            logEvent("Template", `Deleted - ${templateName}`, storeDetails.user.email);
            setBarMessage({
                type: "info",
                message: "Template has been successfully deleted.",
                result: "success"
            })
            setOpenBar(true)
            //setSections([]);
            //setTemplateID("");
        }
        else if (result.status === 300) {
            setShowLoading(false)
            setShowDeleteTemplateDialog(false)
            setBarMessage({
                type: "error",
                message: "You cannot delete a template with store visits linked to it."
            })
            setOpenBar(true)
        }
        else {
            setShowLoading(false)
            setShowDeleteTemplateDialog(false)
            setBarMessage({
                type: "error",
                message: "There was an error deleting this template."
            })
            setOpenBar(true)
        }

        setShowLoading(false)
    }

    function checkTemplateFieldsFilled() {
        let bCheck = true;

        if (templateName.length === 0 || selectedTemplateCategory.length === 0 || templateDescription.length === 0) {
            console.log("Template", templateName, selectedTemplateCategory, templateDescription)
            bCheck = false;
        }

        if (isTemplateInternal && selectedGroups.length === 0) bCheck = false;

        if (!isTemplateInternal && (publicTemplateDateRange.start === null || publicTemplateDateRange.end === null)) bCheck = false;

        if (bCheck) {
            sections.forEach(element => {

                if (element.name.length == 0) {

                    bCheck = false;
                }

                element.list.forEach(item => {
                    if (item.name.length === 0) bCheck = false;
                })
            })
        }

        if (!bCheck) setShowFieldError(true)

        return bCheck;
    }

    function isTemplateScorable() {
        let bCheck = false;
        sections.forEach(section => {
            bCheck = section.list.filter(listItem => listItem.scorable).length > 0;
        })

        return bCheck;
    }

    async function handleCreateTemplate() {


        if (!checkTemplateFieldsFilled()) {

            setBarMessage({
                type: "error",
                message: "Please check that all fields have been filled before saving.",
            })
            setOpenBar(true)
            return;
        }

        setShowLoading(true);
        createTemplate().then((result) => {

            if (result) {
                setBarMessage({
                    type: "info",
                    message: "Template has been successfully created.",
                    result: "success"
                })
                setOpenBar(true)
            }
            else {
                setBarMessage({
                    type: "error",
                    message: "There was an error creating your template.",

                })
                setOpenBar(true)
            }

            setShowLoading(false);

        }).catch(err => {
            setShowLoading(false);
        })
    }

    async function handleSaveTemplate() {
        //console.log("Updating template")
        if (!checkTemplateFieldsFilled()) {

            setBarMessage({
                type: "error",
                message: "Please check that all fields have been filled before saving.",
            })
            setOpenBar(true)
            return;
        }

        setShowLoading(true);

        saveTemplate().then((result) => {
            if (result) {
                setBarMessage({
                    type: "info",
                    message: "Template has been successfully updated.",
                    result: "success"
                })
                setOpenBar(true)
            }
            else {
                setBarMessage({
                    type: "error",
                    message: "There was an error updating your template.",

                })
                setOpenBar(true)
            }

            setShowLoading(false)

        }).catch(err => {
            setShowLoading(false);
        })
    }

    async function createTemplate() {

        try {
            const templateScorable = isTemplateScorable();

            const templateCollectionRef = collection(db, `stores/${storeDetails.rootID}/templates`);
            const tempRef = doc(templateCollectionRef);

            const updatedSectionsWithInternalCheck = !isTemplateInternal ? [...sections].map(section => {
                return {
                    ...section,
                    list: section.list.map(item => {
                        return {
                            ...item,
                            actionAllowed: false,
                            allowComments: false
                        }
                    })
                }
            }) : [...sections];

            // add the template
            await setDoc(tempRef, {
                name: templateName,
                description: templateDescription,
                scorable: templateScorable,
                template_type: selectedTemplateType,
                isPublic: !isTemplateInternal,
                public_template_start: publicTemplateDateRange?.start || "",
                public_template_end: publicTemplateDateRange?.end || "",
                category: selectedTemplateCategory,
                default_status_closed: defaultStatusClosed,
                timer: timedTemplate,
                store_created: storeDetails.rootID === "storecall" ? false : true,
                master_template_id: tempRef.id,
                timer_mins: timerValue,
                location_required: isTemplateInternal ? locationRequired : false,
                signature_required: isTemplateInternal ? requireSignature : false,
                selected_item_id: selectedItemID ? selectedItemID : "none",
                permission_users: selectedGroups,
                email_users: selectedUsers.map(user => user.uid),
                email_store: emailStore
            }, { merge: true });

            const templateID = tempRef.id;
            logEvent("Template", `Created - ${templateName}`, storeDetails.user.email);

            //await createTemplatePermissions(tempRef.id);

            // add template name
            //await setDoc(doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID), { name: templateName });

            updatedSectionsWithInternalCheck.forEach(async (element, i) => {
                const sectionRef = doc(collection(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections"));
                const idElement = {
                    ...element,
                    section_id: sectionRef.id,
                    key: i
                };

                await setDoc(doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections", sectionRef.id), idElement, { merge: true });
            })

            // if(storeDetails.user.specific_templates) {

            //     await setDoc(doc(db, "stores/" + storeDetails.rootID + "/users/" + storeDetails.user.key), { specific_template_list: arrayUnion(templateID)  }, {merge: true})
            //     setStoreDetails({...storeDetails, user: {...storeDetails.user, specific_template_list: [...storeDetails.user.specific_template_list, templateID]}})
            // }

            setTemplateID(templateID);
            // console.log("Template created successfully " + templateID)
            return true;
        } catch {
            return false;
        }

    }

    // async function createTemplatePermissions(template_id) {
    //     const permissionUsers = ["admin", ...selectedUsers];
    //     const q = doc(db, "stores/" + storeDetails.rootID + "/template_permissions/" + template_id);
    //     await addDoc(collection(db, "stores/" + storeDetails.rootID + "/templates"), {
    //         permission_users: selectedUsers
    //     });

    // }

    // async function updateTemplatePermissions() {
    //     const permissionUsers = ["admin", ...selectedUsers];
    //     const q = doc(db, "stores/" + storeDetails.rootID + "/template_permissions/" + templateID);
    //     await updateDoc(q, {
    //         users: permissionUsers
    //     });
    //     console.log("Template permissions updated");
    // }

    async function saveTemplate() {

        const templateScorable = isTemplateScorable();

        const q = doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID);

        await updateDoc(q, {
            name: templateName,
            description: templateDescription,
            scorable: templateScorable,
            category: selectedTemplateCategory,
            template_type: selectedTemplateType,
            isPublic: !isTemplateInternal,
            public_template_start: publicTemplateDateRange?.start || "",
            public_template_end: publicTemplateDateRange?.end || "",
            default_status_closed: defaultStatusClosed,
            signature_required: isTemplateInternal ? requireSignature : false,
            selected_item_id: selectedItemID ? selectedItemID : "none",
            timer: timedTemplate,
            store_created: true,
            master_template_id: q.id,
            timer_mins: timerValue,
            location_required: isTemplateInternal ? locationRequired : false,
            permission_users: selectedGroups,
            email_users: selectedUsers.map(user => user.uid),
            email_store: emailStore
        });


        const modTempSections = !isTemplateInternal ? [...sections].map(section => {
            return {
                ...section,
                list: section.list.map(item => {
                    return {
                        ...item,
                        actionAllowed: false,
                        allowComments: false
                    }
                })
            }
        }) : [...sections];
        const promises = []; // create an array to hold the promises

        for (const [i, section] of modTempSections.entries()) {
            if (section.uploaded) {
                console.log(section)
                const sectionRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections", section.section_id);
                const idElement = { ...section, key: i };
                promises.push(setDoc(sectionRef, idElement, { merge: true }));
            } else {
                console.log(section)
                //console.log("not uploaded")
                const sectionRef = doc(collection(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections"));
                const idElement = { ...section, section_id: sectionRef.id, key: i };
                promises.push(setDoc(doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections", sectionRef.id), idElement, { merge: true }));
            }
        }

        logEvent("Template", `Updated - ${templateName}`, storeDetails.user.email);

        await Promise.all(promises); // wait for all promises to complete
        return true;
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return emailRegex.test(email);
    };

    function handleUpdateDefaultStatusClosed(val) {
        //console.log("Updating", val)
        setDefaultStatusClosed(val);
    }

    function getSectionItems() {
        const items = [];
        sections.forEach(section => {
            section.list.forEach(item => {
                items.push({
                    section: section.name,
                    section_id: section.section_id,
                    section_item_id: item.section_item_id,
                    name: item.name
                });
            });
        });
        return items;
    }

    function handleSelectedItemIDChange(event, values) {

        setSelectedItemID(values)
    }

    function handleUpdateTemplateName(value) {
        console.log(value)
        setTemplateName(value)
    }

    function handleSetTemplatePublic() {
        setIsTemplateInternal(false);
        setLocationRequired(false)
        setEmailStore(false)
        //setSelectedGroups(setSelectedGroups(storeDetails.user_groups.map((group) => group.id)));
    }

    return (
        <PageHolder>
            <Header>{templateHeader}</Header>
            <Loader show={showLoading} />




            <PageContainer empty_shadow={true} style={{ 'padding': '0px' }}>

                <AlertDialog
                    open={showDeleteSectionDialog} setOpen={setShowDeleteSectionDialog}
                    title="Delete Section"
                    content="Are you sure you want to remove this section and its contents?"
                    id={selectedSection}
                    action={deleteSection} />

                <AlertDialog
                    open={showDeleteTemplateDialog} setOpen={setShowDeleteTemplateDialog}
                    title="Delete Template"
                    content="Are you sure you want to delete this template and its contents?"
                    action={deleteTemplate} />

                <ErrorDialog
                    open={showErrorDialog} setOpen={setShowErrorDialog}
                    title={errorContent.title}
                    content={errorContent.content} />

                <InfoDialog
                    open={showInfoDialog}
                    setOpen={setShowInfoDialog}
                    title={dialogContents.title}
                    content={dialogContents.contents}
                    extra_content={dialogContents.extra_content} />

                {showTemplateCategoryDialog && <TemplatesManagerCategories open={showTemplateCategoryDialog} setOpen={setShowTemplateCategoryDialog} />}

                <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
                        {barMessage.message}
                    </Alert>
                </Snackbar>




                <TemplateTimerContainer style={{ 'marginBottom': '10px' }}>

                    <BubbleContainer>
                    <Text style={{margin: '10px 0px'}} fontSize={"1rem"} fontWeight={600} color={Colors.blue}>Template details</Text>
                    
                    <ColumnContainer style={{gap: '10px'}}>
                    <InputField
                        error={showFieldError && templateName.length === 0}
                        helperText={showFieldError && templateName.length === 0 ? 'Enter a template name' : ''}

                        placeholder='Enter a new template name'
                        size="small"
                        
                        
                        fullWidth

                        myVal={templateName}
                        updateHandler={handleUpdateTemplateName}
                    />

                    <InputField
                        error={showFieldError && templateDescription === 0}
                        helperText={showFieldError && templateDescription.length === 0 ? 'Enter a template description' : ''}
                        margin={'10px 0px'}
                        placeholder='Enter a description for the template'
                        sx={{
                            marginTop: '10px',

                        }}
                        size="small"
                        fullWidth
                        variant="outlined"
                        myVal={templateDescription}
                        updateHandler={setTemplateDescription}
                    />
                    </ColumnContainer>
                    
                    
                    <TemplateFormFieldRow wrap style={{'margin': '10px 0px', padding: '0px'}} align="space-between">
                    <TextField
                        id="template-category"
                        select
                        size='small'
                        sx={{margin: '7px 4px'}}
                        inputProps={{
                            style: {
                                
                                fontFamily: "Poppins",
                                fontSize: '0.8rem'

                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowTemplateCategoryDialog(true)} style={{ 'marginRight': '10px' }}>
                                    <StyledEditIcon />
                                </IconButton>
                            ),
                            style: {
                                color: `${Colors.dark_grey}`,
                                fontWeight: '400',

                                fontSize: '0.9rem',
                                fontFamily: "Poppins",
                                borderRadius: '20px',


                            }
                        }}
                        fullWidth
                        error={showFieldError && selectedTemplateCategory.length === 0}
                        
                        label="Template Category"

                        value={selectedTemplateCategory}
                        onChange={handleSelectedCategoryChange}>
                        {storeDetails.template_categories.map((category, i) => {
                            return <MenuItem key={category.cloud_id} style={{ display: 'flex', alignItems: 'center' }} value={category.cloud_id}>{category.name}</MenuItem>
                        })}
                    </TextField>

                    <TextField
                        id="field-type"
                        select
                        size='small'
                        sx={{margin: '7px 4px'}}
                        inputProps={{
                            style: {
                                fontFamily: "Poppins",

                            }
                        }}
                        InputProps={{
                            style: {
                                color: `${Colors.dark_grey}`,
                                fontWeight: '400',
                                
                                fontSize: '0.9rem',
                                fontFamily: "Poppins",
                                borderRadius: '20px',


                            }
                        }}
                        fullWidth
                        label="Template Type"
                        
                        value={selectedTemplateType}
                        onChange={(e) => setSelectedTemplateType(e.target.value)}>
                        {TemplateTypes.map((type, i) => {
                            return <MenuItem key={i} style={{ display: 'flex', alignItems: 'center' }} value={type}>{type}</MenuItem>
                        })}
                    </TextField>
                    </TemplateFormFieldRow>
                    </BubbleContainer>
                    
                    <BubbleContainer>
                    <Text style={{margin: '10px 0px'}} fontSize={"1rem"} fontWeight={600} color={Colors.blue}>Template access</Text>
                    <Typography sx={{fontWeight: 400}}>Set template as internal or public to control who has access to it</Typography>
                    <FormControl style={{marginBottom: '10px'}}>
                        <ButtonGroup sx={{ 'margin': '10px 0px', gap: '5px' }}>
                            <ButtonOutlined selected={isTemplateInternal} onClick={() => setIsTemplateInternal(true)}>Internal Team</ButtonOutlined>
                            <ButtonOutlined selected={!isTemplateInternal} onClick={handleSetTemplatePublic}>Public</ButtonOutlined>
                        </ButtonGroup>




                        {!isTemplateInternal &&
                            <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                                <Typography>Select a date range for this template to be available to the public</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        marginTop: '10px',
                                        gap: '5px',

                                        '& > *': {
                                            my: 1,
                                            
                                        },
                                    }}
                                >
                                    <Box sx={{ display: 'flex', flex: 1 }}>

                                        <TextField
                                            type="date"
                                            
                                            fullWidth
                                            InputProps={{
                                                style: {
                                                    borderRadius: '10px !important'
                                                }
                                            }}
                                            size="small"
                                            value={publicTemplateDateRange.start || ""}
                                            onChange={(e) => setPublicTemplateDateRange(prevState => ({ ...prevState, start: e.target.value }))}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Start Date"
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', flex: 1 }}>

                                        <TextField
                                            type="date"
                                            
                                            fullWidth
                                            size="small"
                                            value={publicTemplateDateRange.end || ""}
                                            onChange={(e) => setPublicTemplateDateRange(prevState => ({ ...prevState, end: e.target.value }))}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="End Date"
                                        />
                                    </Box>

                                </Box>
                            </Box>
                        }

                    </FormControl>

                    
                    <Typography>Internal user groups with access to the template</Typography>
                    <Autocomplete
                        sx={{ 'margin': '5px 0px' }}
                        multiple
                        fullWidth
                        id="manager-tags"
                        
                        options={[{ id: 'all', name: 'All' }, ...storeDetails.user_groups]}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        value={selectedGroups.map((id) =>
                            storeDetails.user_groups.find((group) => group.id === id)
                        )}
                        isOptionEqualToValue={(option, value) =>
                            selectedGroups.includes(option.id)
                        }
                        onChange={(event, newValue) => {
                            if (newValue.some((option) => option.id === 'all')) {
                                setSelectedGroups(storeDetails.user_groups.map((group) => group.id));
                            } else {
                                setSelectedGroups(newValue.map((option) => option.id));
                            }
                        }}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                size='small'
                                fullWidth
                                error={showFieldError && selectedGroups.length === 0}
                                
                                placeholder="Add User Group"
                            />
                        )}
                    />
                    </BubbleContainer>
                    
                    <BubbleContainer>
                    <Text style={{margin: '10px 0px'}} fontSize={"1rem"} fontWeight={600} color={Colors.blue}>Submissions</Text>
                    <FormControl>
                        <Typography>Users to email on submission</Typography>

                        <Autocomplete
                            multiple
                            freeSolo
                            size='small'
                            sx={{ marginTop: '10px' }}
                            id="user-tags"
                            options={availableUsers.concat(newUsers)}
                            groupBy={(option) => option.group_name}
                            getOptionLabel={(option) => option.name}
                            value={selectedUsers}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                                // Check if a group was selected
                                if (newValue.some((option) => option.group_name === event.target.innerText)) {
                                    // Add all options in the group
                                    const groupOptions = availableUsers.concat(newUsers).filter((option) => option.group_name === event.target.innerText);
                                    setSelectedUsers([...selectedUsers, ...groupOptions]);
                                } else {
                                    setSelectedUsers(newValue);
                                }
                            }}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    placeholder="Select users to instantly notify on submissions"
                                    
                                />
                            )}
                            renderGroup={(params) => [

                                <ListSubheader
                                    key={params.key}
                                    component="div"
                                    onClick={() => {
                                        const groupOptions = availableUsers.concat(newUsers).filter((option) => option.group_name === params.group);
                                        setSelectedUsers([...selectedUsers, ...groupOptions]);
                                    }}
                                >
                                    {params.group}
                                </ListSubheader>,
                                params.children,
                            ]}
                        />


                    </FormControl>

                    <FormControl>
                        <Typography sx={{ 'marginTop': '10px' }}
                        >
                            Select an identifier question for each visit (Optional)
                        </Typography>
                        <Autocomplete
                            freeSolo
                            size='small'
                            sx={{ marginTop: '10px' }}
                            id="user-tags"
                            options={getSectionItems() || []}
                            groupBy={(option) => option.section}
                            getOptionLabel={(option) => option.name}
                            value={selectedItemID}
                            filterSelectedOptions
                            onChange={handleSelectedItemIDChange}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    placeholder="Select one of your questions as an identifier for each visit"
                                />
                            )}

                        />
                    </FormControl>

                    <FormGroup sx={{ marginTop: '10px' }}>
                        <FormControlLabel control={<Checkbox disabled={!isTemplateInternal} checked={emailStore && isTemplateInternal} onChange={(e) => setEmailStore(prevState => !prevState)} />}
                            label={
                                <>
                                    <Typography>
                                        Send report to location email
                                    </Typography>

                                </>

                            }
                        />
                        
                        <FormControlLabel control={<Checkbox disabled={!isTemplateInternal} checked={locationRequired && isTemplateInternal} onChange={(e) => setLocationRequired(prevState => !prevState)} />}
                            label={
                                <Typography>
                                    Save the user's location
                                </Typography>
                            }
                        />
                        
                        <FormControlLabel control={<Checkbox checked={defaultStatusClosed} onChange={(e) => handleUpdateDefaultStatusClosed(e.target.checked)} />}
                            label={
                                <Typography>
                                    Set the default status to closed
                                </Typography>
                            }
                        />
                        
                        <FormControlLabel control={<Checkbox disabled={!isTemplateInternal} checked={requireSignature && isTemplateInternal} onChange={(e) => setRequireSignatue(e.target.checked)} />}
                            label={
                                <Typography>
                                    Require a signature before submission
                                </Typography>
                            }
                        />
                        {/* <Divider />
                        <FormControlLabel control={<Checkbox checked={timedTemplate} onChange={(e) => setTimedTemplate(e.target.checked)} />}
                            label={
                                <Typography>
                                    Add a time limit to complete
                                </Typography>
                            }
                        /> */}
                    </FormGroup>

                    </BubbleContainer>

                    
                    {/* {timedTemplate &&
                        <TemplateFormFieldRow style={{ 'marginTop': '10px' }}>
                            <Box sx={{ width: '100%' }}>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <AccessTimeFilledIcon />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider
                                            value={typeof timerValue === 'number' ? timerValue : 30}
                                            onChange={handleTimerSliderChange}
                                            max={120}
                                            aria-labelledby="input-slider"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Input
                                            value={timerValue}
                                            size="small"
                                            onChange={handleTimerInputChange}
                                            onBlur={handleTimerCheck}
                                            inputProps={{
                                                step: 10,
                                                min: 1,
                                                max: 120,
                                                type: 'number',
                                                'aria-labelledby': 'input-slider',
                                            }}
                                        />
                                        <Typography sx={{ fontSize: 14 }}>minute(s)</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                        </TemplateFormFieldRow>} */}
                </TemplateTimerContainer>

                <TemplateFormFieldContainer>
                    {sections && sections.map((section, i) => {

                        return (
                            <SectionTemplate key={section.section_id}
                                sectionProp={section}
                                showFieldError={showFieldError}
                                templateID={templateID}
                                isTemplateInternal={isTemplateInternal}
                                handleMoveSectionUp={handleMoveSectionUp}
                                addNewSection={addNewSection}
                                handleMoveSectionDown={handleMoveSectionDown}
                                updateSection={handleUpdateSection}
                                duplicateSection={duplicateSection}
                                handleDeleteSectionDialog={handleDeleteSectionDialog}
                                rootID={storeDetails.rootID}
                            />
                        )
                    })}

                    


                </TemplateFormFieldContainer>

            </PageContainer>
            
                <TemplateFormFieldRow wrap={"true"} align="right" style={{ gap: '5px' }}>
                    <Button onClick={addNewSection}><StyledAddIcon style={{marginRight: '10px'}} /> New Section</Button>
                    {templateID.length > 0 && storeDetails.permissions.templates.delete && <Button highlighted alert onClick={handleDeleteTemplateDialog}><StyledDeleteIcon /> Delete Template</Button>}
                    {templateID.length > 0 && storeDetails.permissions.templates.edit && <Button color={Colors.green} onClick={handleSaveTemplate}><StyledTemplateIcon /> Update Template</Button>}
                    
                </TemplateFormFieldRow>
            
            
            {templateID.length === 0 && sections.length > 0 &&
                <TemplateFormFieldRow style={{ 'marginTop': '5px' }}>
                    {storeDetails.permissions.templates.add && <Button padding={'20px 0px'} fullWidth color={Colors.green} onClick={() => handleCreateTemplate()}><StyledUpdateIcon /> Create template</Button>}
                </TemplateFormFieldRow>}
            {showError &&
                <TemplateFormErrorContainer>
                    <TemplateFormErrorContainerText>{errorMessage}</TemplateFormErrorContainerText>
                    <Button onClick={() => setShowError(false)}>OK</Button>
                </TemplateFormErrorContainer>}
        </PageHolder>

    )
}

export default TemplateForm