import React, { useEffect, useState, Fragment } from 'react'
import { PageContainer, HeaderTitle, TableContainer, Button, PageHolder, StyledActionIcon, StyledAlertIcon, StyledAddIcon, StyledDeleteIcon, StyledTickIcon, HeaderHolder, Text } from './styled/styled.container'
import { TemplateFormFieldContainer, TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFilterContainer, TemplateFormButtonStack, TemplateDescription, TemplateCardContainer, TemplateCard, TemplateCardHeader, TemplateCardValue } from "./styled/styled.templateform"
import { Card, CssBaseline, FormControlLabel, FormGroup, Typography } from '@mui/material';
import Loader from './Loader';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { db } from "./firebase";
import { useParams } from 'react-router-dom';
import { collection, onSnapshot, query, doc, getDoc, where, getDocs, orderBy } from "firebase/firestore";
import moment from 'moment';
import { Colors } from './styled/styled.container';
import ActionNewDialog from './ActionNewDialog';
import { auth } from "./firebase"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import DeleteReportDialog from './DeleteReportDialog';
import EmptyActionImg from "./imgs/action_img.png"
import EmptyScreen from './EmptyScreen';
import Header from './Header';
import axios from 'axios';

import logEvent from './hooks/logEvent';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const columns = [
    { field: "visit_id", headerName: "ID", hide: true },

    {
        field: "section_item_name",
        headerName: "Action",
        minWidth: 300,
        flex: 1
    },
    {
        field: "status",
        headerName: "Priority",

        renderCell: (params) => {

            let cellColor = ""; // Default color
            const action_priority = params.row.action_priority;

            if (action_priority === "Low") {
                cellColor = `${Colors.green}`;
            }
            else if (action_priority === "Medium") {
                cellColor = `${Colors.orange}`; // Set color to red for "Overdue"
            }
            else {
                cellColor = `${Colors.highlight}`;
            }

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {action_priority}
                </div>
            );
        },
        minWidth: 70,
        flex: 1

    },
    {
        field: "action_due_date",
        headerName: "Due",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = ""; // Default color

            const action_due_date = moment(new Date(params.value));

            if (moment().startOf('day').isSameOrAfter(action_due_date)) {
                cellColor = `${Colors.highlight}`; // Set color to green for "Today or Past"
            }
            else if (action_due_date.isBefore(moment())) {
                cellColor = `${Colors.blue}`; // Set color to blue for "Before Current Date"
            }
            else {
                cellColor = `${Colors.orange}`;
            }

            return (
                <div style={{ minWidth: '80px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {action_due_date.format("DD-MM-YYYY")}
                </div>
            );
        },
    },
    {
        field: "ageing",
        headerName: "Ageing",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = "" // Default color
            let ageing = "";

            if (params.value === "Not Due") ageing = "Not Due"
            else ageing = parseInt(params.value);


            //console.log(ageing)
            if (ageing === "Not Due") cellColor = `${Colors.green}`
            else if (ageing === 0) cellColor = `${Colors.blue}`
            else if (ageing < 0) cellColor = `${Colors.green}` // Set color to red for "Overdue"
            else if (ageing > 0 && ageing < 3) cellColor = `${Colors.orange}`
            else if (ageing >= 3) cellColor = `${Colors.highlight}`

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {ageing}
                </div>
            );
        },
    },
    {
        field: "actionByName",
        headerName: "Action by",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = `${Colors.blue}` // Default color

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {params.value}
                </div>
            );
        },
    },
    // {
    //     field: "priority",
    //     headerName: "Priority",
    //     minWidth: 100,
    //     flex: 1
    // },
    { field: 'store_name', headerName: 'Location', minWidth: 170, flex: 1 },
    { field: 'region', headerName: 'Location Group', minWidth: 120, flex: 1 },
    { field: 'sub_region', headerName: 'Sub Group', minWidth: 120, flex: 1 },
    { field: 'template_name', headerName: 'Template', minWidth: 170, flex: 1 },
    {
        field: "section_name", headerName: "Section",
        minWidth: 150,
        flex: 1

    },
    {
        field: 'date',
        headerName: 'Created',
        minWidth: 110,
        flex: 1

    },
    {
        field: 'action_set_by_name',
        headerName: 'Set by',
        minWidth: 100,
        flex: 1

    },


    {
        field: "section_item_id",
        headerName: "Section Item ID",
        hide: true
    },



];

const columnsComplete = [
    { field: "visit_id", headerName: "ID", hide: true },

    {
        field: "section_item_name",
        headerName: "Action",
        minWidth: 300,
        flex: 1
    },
    {
        field: "status",
        headerName: "Priority",

        renderCell: (params) => {

            let cellColor = ""; // Default color
            const action_priority = params.row.action_priority;

            if (action_priority === "Low") {
                cellColor = `${Colors.green}`;
            }
            else if (action_priority === "Medium") {
                cellColor = `${Colors.orange}`; // Set color to red for "Overdue"
            }
            else {
                cellColor = `${Colors.highlight}`;
            }

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {action_priority}
                </div>
            );
        },
        minWidth: 70,
        flex: 1

    },
    {
        field: "action_due_date",
        headerName: "Due",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = Colors.blue; // Default color

            return (
                <div style={{ minWidth: '80px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {moment(new Date(params.value)).format("DD-MM-YYYY")}
                </div>
            );
        },
    },
    {
        field: "actionCompleteDate",
        headerName: "Completed",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = Colors.green; // Default color

            const action_complete_date = moment(params.value, "DD-MM-YYYY");

            return (
                <div style={{ minWidth: '80px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {action_complete_date.format("DD-MM-YYYY")}
                </div>
            );
        },
    },
    {
        field: "ageing",
        headerName: "Days",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = "" // Default color

            let dueDate = moment(new Date(params.row.action_due_date));
            const actionCompleteDate = moment(params.row.actionCompleteDate, "DD-MM-YYYY");
            const ageing = Math.max(0, actionCompleteDate.diff(dueDate, "days"));

            //console.log(ageing)
            if (ageing === 0) cellColor = `${Colors.green}`
            else if (ageing > 0 && ageing < 3) cellColor = `${Colors.orange}`
            else if (ageing >= 3) cellColor = `${Colors.highlight}`

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {ageing}
                </div>
            );
        },
    },
    {
        field: "actionByName",
        headerName: "Action by",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {

            let cellColor = `${Colors.blue}` // Default color

            return (
                <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
                    {params.value}
                </div>
            );
        },
    },
    // {
    //     field: "priority",
    //     headerName: "Priority",
    //     minWidth: 100,
    //     flex: 1
    // },
    { field: 'store_name', headerName: 'Location', minWidth: 170, flex: 1 },
    { field: 'region', headerName: 'Location Group', minWidth: 120, flex: 1 },
    { field: 'sub_region', headerName: 'Sub Group', minWidth: 120, flex: 1 },
    { field: 'template_name', headerName: 'Template', minWidth: 170, flex: 1 },
    {
        field: "section_name", headerName: "Section",
        minWidth: 150,
        flex: 1

    },
    {
        field: 'date',
        headerName: 'Created',
        minWidth: 110,
        flex: 1

    },
    {
        field: 'action_set_by_name',
        headerName: 'Set by',
        minWidth: 100,
        flex: 1

    },


    {
        field: "section_item_id",
        headerName: "Section Item ID",
        hide: true
    },



];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ActionsHome(props) {
    const [showLoading, setShowLoading] = useState(false);
    const { storeDetails } = props;
    const [visits, setVisits] = useState([])
    const [visitsComplete, setVisitsComplete] = useState([])
    const [originalVisits, setOriginalVisits] = useState([])
    const [actionUsers, setActionUsers] = useState([]);
    const [users, setUsers] = useState(storeDetails.all_users)
    const [filters, setFilters] = useState(false)
    const [stores, setStores] = useState(storeDetails.stores)
    const [templates, setTemplates] = useState(storeDetails.user_specific_templates);
    const [selectedStores, setSelectedStores] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([])
    const [showActionDialog, setShowActionDialog] = useState(false)
    const [selectedAction, setSelectedAction] = useState({})
    const [openBar, setOpenBar] = React.useState(false);
    const [barMessage, setBarMessage] = useState({})
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedCompleteRows, setSelectedCompleteRows] = useState([])
    const [showCompleteActions, setShowCompleteActions] = useState(false);
    const [showDeleteActionDialog, setShowDeleteActionDialog] = useState(false)
    const [showEmptyScreen, setShowEmptyScreen] = useState(false);

    const [pageSizeActions, setPageSizeActions] = useState(10);
    const [pageSizeActionsComplete, setPageSizeActionsComplete] = useState(10);


    const params = useParams();
    //console.log(params)

    const navigate = useNavigate();

    useEffect(() => {
        setVisits([])
        setVisitsComplete([])
        setActionUsers([])

        const getUsers = async () => {

            setUsers(storeDetails.all_users);
        }

        async function getDetails() {
            setShowLoading(true);
            const storeItems = storeDetails.user_specific_stores; //await getStores();
            const templateItems = storeDetails.user_specific_templates;

            const userVisits = await getUsersVisits();


            await getUsers();

            setStores(storeItems)


            const actionItems = await getVisits(userVisits);

            const paramStores = [];
            const paramTemplates = [];
            const paramUsers = [];


            if (params.store && params.store !== "all") {
                //console.log("Param store")
                const storeItem = storeItems.find(store => store.cloud_id === params.store)
                //console.log("Store", storeItem)
                if (storeItem) {
                    paramStores.push(storeItem);
                    //console.log("Param Stores", storeItem)
                    setSelectedStores([storeItem])
                }
            }

            if (params.template && params.template !== "all") {
                //console.log("Param template")
                const templateItem = templateItems.find(template => template.cloud_id === params.template)
                if (templateItem) {
                    paramTemplates.push(templateItem);
                    setSelectedTemplates([templateItem])
                }
            }

            if (params.user && params.user !== "all") {

                const userItem = userVisits.filter(user => user.uid === params.user)[0]
                //console.log("User item", userItem);
                if (userItem) {
                    paramUsers.push(userItem);
                    setSelectedUsers([userItem])
                }
            }

            if (params.store || params.template || params.user) {
                setFilters(true)
                //console.log("Filtering", paramStores, paramTemplates, paramUsers)
                handleSearchFilter(actionItems, storeItems, templateItems, userVisits, paramStores, paramTemplates, paramUsers)
            }
        }

        // error occuring here

        if (storeDetails.user && storeDetails.stores && storeDetails.regions && storeDetails.sub_regions) getDetails()

    }, [storeDetails.stores, storeDetails.regions, storeDetails.sub_regions])

    // useEffect(() => {
    //     if(visits.length > 0) setShowEmptyScreen(false)
    //     else setShowEmptyScreen(true)
    // }, [visits])

    function getActionAgeing(actionDueData) {

        if (moment(new Date(actionDueData)).isAfter(moment().startOf('day'))) {
            //console.log("Not Due", actionDueData)
            return "Not Due";
        }
        else {
            const dueFormatted = moment(new Date(actionDueData))
            const diff = moment().diff(dueFormatted, "days")
            return diff
        }
    }

    function getActionCompleteAgeing(actionDueData, actionCompleteDate) {

        const dueFormatted = moment(new Date(actionDueData))
        const diff = moment(actionCompleteDate).diff(dueFormatted, "days")
        if (diff < 0) return 0;

        return diff
    }

    async function getVisits(userData) {
        setVisits([])
        setVisitsComplete([])
        setOriginalVisits([])
        setSelectedStores([])
        setSelectedTemplates([])
        setSelectedUsers([])

        console.log("Getting actions...")

        const actionsRef = collection(db, "stores/" + storeDetails.rootID + "/actions");
        const actionDocs = [];
        const actionCompleteDocs = [];

        if (storeDetails.user.admin) {
            const query1 = query(actionsRef);
            const snapshot = await getDocs(query1);

            if (snapshot.docs.length === 0) {
                setShowEmptyScreen(true)
                setShowLoading(false)
                return;
            }

            snapshot.docs.forEach((doc) => {
                const actionData = doc.data();
                
                if (actionData.actionComplete) {
                    
                    actionCompleteDocs.push({
                        id: doc.id,
                        data: actionData
                    })
                } else {
                    actionDocs.push({
                        id: doc.id,
                        data: doc.data()
                    })
                }

            })
        }
        else if (storeDetails.user.type === "manager") {
            //console.log(storeDetails.user.group_manager)
            //console.log(actionUsers.map((user) => user.group))
            const groupUsers = userData.filter(user => storeDetails.user.group_manager.includes(user.group))
            //console.log(groupUsers)
            const groupUserUID = groupUsers.map(user => user.uid);

            const query1 = query(actionsRef, where("action_set_by", "==", storeDetails.user.uid));
            const query2 = query(actionsRef, where("actionBy", "==", storeDetails.user.uid));
            const query3 = query(actionsRef, where("actionComplete", "==", false), where("actionBy", "in", groupUserUID));
            const query4 = query(actionsRef, where("actionComplete", "==", false), where("action_set_by", "in", groupUserUID));

            const querySnapshot1 = await getDocs(query1);
            const querySnapshot2 = await getDocs(query2);
            const querySnapshot3 = await getDocs(query3);
            const querySnapshot4 = await getDocs(query4);

            const mergedDocs = [...querySnapshot1.docs, ...querySnapshot2.docs, ...querySnapshot3.docs, ...querySnapshot4.docs];

            if (mergedDocs.length === 0) {
                setShowLoading(false)
                setShowEmptyScreen(true)
                return;
            }

            mergedDocs.forEach(doc => {

                const data = doc.data();
                if (data.actionComplete) {
                    if (!actionCompleteDocs.find(item => item.data.action_id === data.action_id)) {
                        actionCompleteDocs.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    }
                }
                else {
                    if (!actionDocs.find(item => item.data.action_id === data.action_id)) {
                        actionDocs.push({
                            id: doc.id,
                            data: doc.data()
                        })
                    }
                }

            })

            //console.log(actionDocs);


        }
        else {
            const query1 = query(actionsRef, where("action_set_by", "==", storeDetails.user.uid));
            const query2 = query(actionsRef, where("actionBy", "==", storeDetails.user.uid));

            const querySnapshot1 = await getDocs(query1);
            const querySnapshot2 = await getDocs(query2);
            const mergedDocs = [...querySnapshot1.docs, ...querySnapshot2.docs];

            if (mergedDocs.length === 0) {
                setShowLoading(false)
                setShowEmptyScreen(true)
                return;
            }

            mergedDocs.forEach(doc => {
                const actionData = doc.data();
                if (actionData.actionComplete) {
                    const existingDocIndex = actionCompleteDocs.findIndex((existingDoc) => existingDoc.id === doc.id);
                    if (existingDocIndex === -1) {
                        actionCompleteDocs.push({
                            id: doc.id,
                            data: doc.data()
                        });
                    }
                }
                else {
                    const existingDocIndex = actionDocs.findIndex((existingDoc) => existingDoc.id === doc.id);
                    if (existingDocIndex === -1) {
                        actionDocs.push({
                            id: doc.id,
                            data: doc.data()
                        });
                    }
                }
            })


        }



        const actionItems = await Promise.all(actionDocs.map(action => {
            return new Promise(async (resolve) => {
                const actionData = action.data;

                const actionID = action.id;
                const user = storeDetails.all_users.filter(user => user.uid === actionData.actionBy)[0];
                //console.log("User", user)


                //console.log(actionData)
                if (actionData.type === "solo") {
                    const actionItem = {
                        action_id: action.id,
                        visit_id: "",
                        store_name: actionData.store_name || "Not Set",
                        region: storeDetails?.stores.find(store => store.name === actionData.store_name)?.region || "Not Set",
                        sub_region: storeDetails?.stores?.find(store => store.name === actionData.store_name)?.sub_region || "Not Set",
                        template_name: "Solo Action",
                        visit_date: "",
                        section_name: "",
                        section_item_name: actionData.section_item_name,
                        files: actionData.files,
                        section_item_id: "",
                        actionBy: user.uid,
                        type: actionData.type,
                        actionByName: user.name,
                        action_due_date: actionData.action_due_date,
                        action_priority: actionData.action_priority,
                        actionComplete: actionData.actionComplete,
                        action_set_by: actionData.action_set_by,
                        action_set_by_name: storeDetails.all_users.filter(user => user.uid === actionData.action_set_by)[0].name,
                        date: actionData.date,
                        ageing: getActionAgeing(actionData.action_due_date)
                    }
                    resolve(actionItem)
                }
                else {
                    const visit_id = actionData.visit_id;
                    const section_id = actionData.section_id;
                    const section_item_id = actionData.section_item_id;

                    const visitRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + visit_id);
                    const visitDoc = await getDoc(visitRef);
                    const visitData = visitDoc.data();


                    const store_id = visitData.store_id;
                    const template_id = visitData.template_id;
                    //const visit_date = moment(visitData.date).format("DD-MM-YYYY");

                    const storeData = storeDetails.stores.filter(store => store.cloud_id === store_id)[0];
                    const storeName = storeData.name;

                    const region = storeData.region;
                    const sub_region = storeData.sub_region;

                    const templateData = storeDetails.templates.filter(template => template.cloud_id === template_id)[0];
                    const templateName = templateData.name;

                    const sectionRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + visit_id + "/sections/" + section_id);
                    const sectionDoc = await getDoc(sectionRef);
                    const sectionData = sectionDoc.data();

                    const sectionName = sectionData.name;

                    const sectionItemData = sectionData.list.filter(item => item.section_item_id === section_item_id)[0];

                    const sectionItemName = sectionItemData.name;
                    const actionItem = {
                        ...actionData,
                        action_id: action.id,
                        visit_id: visit_id,
                        store_name: storeName || "Not Set",
                        region: region || "Not Set",
                        sub_region: sub_region || "Not Set",
                        template_name: templateName,
                        files: actionData.files,
                        section_name: sectionName,
                        section_item_name: actionData.section_item_name || sectionItemName,
                        actionBy: user.uid,
                        actionByName: user.name,
                        type: actionData.type,
                        action_due_date: actionData.action_due_date,
                        action_set_by_name: userData.filter(user => user.uid === actionData.action_set_by)[0].name,
                        date: actionData.date,
                        ageing: getActionAgeing(actionData.action_due_date)
                    }

                    resolve(actionItem)
                }
            })
        }))



        const actionCompleteItems = await Promise.all(actionCompleteDocs.map(action => {
            return new Promise(async (resolve) => {
                const actionData = action.data;
                const actionID = action.id;
                const user = storeDetails.all_users.filter(user => user.uid === actionData.actionBy)[0];
                //console.log("User", user)

                //console.log(actionData)
                if (actionData.type === "solo") {

                    const region = storeDetails?.stores.find(store => store.name === actionData.store_name)?.region || "Not Set";
                    const sub_region = storeDetails?.stores?.find(store => store.name === actionData.store_name)?.sub_region || "Not Set";

                    const actionItem = {
                        action_id: action.id,
                        visit_id: "",
                        store_name: actionData.store_name || "Not Set",
                        region: region,
                        sub_region: sub_region,
                        template_name: "Solo Action",
                        visit_date: "",
                        section_name: "",
                        section_item_name: actionData.section_item_name,
                        section_item_id: "",
                        actionBy: user.uid,
                        files: actionData.files,
                        type: actionData.type,
                        actionByName: user.name,
                        action_due_date: actionData.action_due_date,
                        action_priority: actionData.action_priority,
                        actionComplete: actionData.actionComplete,
                        actionCompleteDate: actionData.actionCompleteDate,
                        action_set_by: actionData.action_set_by,
                        action_set_by_name: storeDetails.all_users.filter(user => user.uid === actionData.action_set_by)[0].name,
                        date: moment(actionData.date, "DD-MM-YYYY").format("DD-MM-YYYY"),
                        ageing: getActionCompleteAgeing(actionData.action_due_date, actionData.actionCompleteDate)
                    }
                    resolve(actionItem)
                }
                else {
                    const visit_id = actionData.visit_id;
                    const section_id = actionData.section_id;
                    const section_item_id = actionData.section_item_id;

                    const visitRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + visit_id);
                    const visitDoc = await getDoc(visitRef);
                    const visitData = visitDoc.data();

                    const store_id = visitData.store_id;
                    const template_id = visitData.template_id;
                    
                    // const storeRef = doc(db, "stores/" + storeDetails.rootID + "/stores/" + store_id);
                    // const storeDoc = await getDoc(storeRef);
                    // const storeData = storeDoc.data();
                    const storeData = storeDetails.stores.filter(store => store.cloud_id === store_id)[0];
                    const storeName = storeData.name;

                    // const templateRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + template_id);
                    // const templateDoc = await getDoc(templateRef);
                    // const templateData = templateDoc.data();
                    const templateData = storeDetails.templates.filter(template => template.cloud_id === template_id)[0];
                    const templateName = templateData.name;

                    const actionItem = {
                        ...actionData,
                        action_id: action.id,
                        visit_id: visit_id,
                        store_name: storeName,
                        region: storeDetails?.stores.find(store => store.name === storeName).region || "Not Set",
                        sub_region: storeDetails?.stores?.find(store => store.name === storeName).sub_region || "Not Set",
                        files: actionData.files,
                        template_name: templateName,
                        section_name: actionData.section_name,
                        section_item_name: actionData.section_item_name,
                        actionBy: user.uid,
                        actionByName: user.name,
                        type: actionData.type,
                        action_due_date: actionData.action_due_date,
                        action_set_by_name: userData.filter(user => user.uid === actionData.action_set_by)[0].name,
                        
                        ageing: getActionCompleteAgeing(actionData.action_due_date, actionData.actionCompleteDate)
                    }

                    resolve(actionItem)
                }
            })
        }))

        if (actionItems.length === 0) {
            console.log("No actions")
            setShowLoading(false)
            setShowEmptyScreen(true)
        }

        const sortedActionItems = actionItems.sort((a, b) => moment(new Date(a.action_due_date)).diff(moment(new Date(b.action_due_date))))
        const sortedActionCompleteItems = actionCompleteItems.sort((a, b) => moment(a.action_due_date).diff(moment(b.action_due_date)))

        //console.log("Sorted", sortedActionItems)

        setVisits(sortedActionItems)
        setVisitsComplete(sortedActionCompleteItems)
        setOriginalVisits(sortedActionItems)

        setShowLoading(false)
        return actionItems;

    }

    async function getUsersVisits() {
        // let q = "";
        //     q = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("active", "==", true));
        //     const userRef = await getDocs(q);
        //     const userData = await Promise.all(userRef.docs.map(doc => {
        //         return new Promise((resolve) => {
        //             const docData = doc.data();

        //             resolve(docData)
        //         })
        //     }))
        const all_users = storeDetails.all_users;
        //console.log("All users", all_users)
        setActionUsers(all_users)
        return all_users;
    }

    const handleStoreChange = (event, values) => {

        //const storeNames = values.map(value => value.name);
        setSelectedStores(values);
    };

    const handleTemplateChange = (event, values) => {

        //const templateNames = values.map(value => value.name);
        setSelectedTemplates(values);
    };

    const handleUserChange = (event, values) => {



        //const userNames = values.map(value => value.name);
        setSelectedUsers(values);
    };

    function handleSearchFilter(visits, stores, templates, actionUsers, selectedStores, selectedTemplates, selectedUsers) {

        // console.log("Visits", visits)
        // console.log("Stores Selected", selectedStores)
        // console.log("Selected templates", selectedTemplates)



        const filterStores = selectedStores.length > 0;
        const filterTemplates = selectedTemplates.length > 0;
        const filterUsers = selectedUsers.length > 0;
        let filteredList = originalVisits.length > 0 ? [...originalVisits] : [...visits];


        let selectedStoresInfo = [];
        let selectedTemplatesInfo = [];
        let selectedUsersInfo = [];

        if (!filterStores && !filterTemplates && !filterUsers) {
            //console.log("Empty filter")
            setVisits([...originalVisits]);
            return;
        }

        if (filterStores) selectedStoresInfo = selectedStores;
        else selectedStoresInfo = stores;



        if (filterTemplates) selectedTemplatesInfo = selectedTemplates;
        else selectedTemplatesInfo = templates; //templates.map(template => template.name)

        if (filterUsers) selectedUsersInfo = selectedUsers;
        else selectedUsersInfo = actionUsers //actionUsers.map(user => user.name);



        if (filterStores) {
            //console.log("Filtering stores")
            filteredList = filteredList.filter(visit => selectedStoresInfo.some((storeInfo) => {
                return (storeInfo.name === visit.store_name)
            }));
        }

        if (filterTemplates) {
            //console.log("Filtering templates")
            filteredList = filteredList.filter(visit => selectedTemplatesInfo.some((template) => template.name === visit.template_name));
        }

        if (filterUsers) {
            //console.log("Filtering users")
            filteredList = filteredList.filter(visit => selectedUsersInfo.some((user) => {
                //console.log("Info", user)
                //console.log("visit", visit)
                return (user.uid === visit.actionBy)
            }));
        }


        //console.log("Updated", filteredList);



        setVisits(filteredList);

    }

    function handleClearFilters() {
        setVisits(originalVisits)
        setSelectedStores([])
        setSelectedTemplates([])
        setSelectedUsers([])
    }

    const handleClose = (event, reason) => {

        setOpenBar(false);
        setShowLoading(false)
    };

    function handleNewActionDialog() {
        //console.log("Showing action")

        setSelectedAction({})
        setShowActionDialog(true)
    }

    function handleEditActionDialog(action_id) {

        const actionIndex = visits.findIndex(visit => visit.action_id === action_id);

        setSelectedAction(visits[actionIndex])
        setShowActionDialog(true)
    }

    function handleEditActionCompleteDialog(action_id) {

        const actionIndex = visitsComplete.findIndex(visit => visit.action_id === action_id);

        setSelectedAction(visitsComplete[actionIndex])
        setShowActionDialog(true)
    }

    async function getIDToken() {
        const token = await auth.currentUser.getIdToken(true)
        return token;
    }

    function removeActionFile(actionId, fileId) {
        const actionIndex = visits.findIndex(action => action.action_id === actionId);
        const fileIndex = visits[actionIndex].files.findIndex(file => file.file_id === fileId);

        const updatedList = visits.map((item, i) => {
            if (i === actionIndex) {
                const newFiles = [...item.files];
                newFiles.splice(fileIndex, 1);
                return { ...item, files: newFiles }
            }
            else return item
        })

        setVisits(updatedList)
    }

    async function handleAddAction(actionInfo) {

        const files = actionInfo.files;
        const idToken = await getIDToken();
        const updatedFileList = [];
        setShowLoading(true)

        if (actionInfo.files.length > 0) {


            for (const fileElement of files) {
                const formData = new FormData();
                formData.append("idToken", idToken);
                formData.append('file', fileElement.file);
                formData.append("file_name", fileElement.file.name)
                formData.append('store_id', storeDetails.rootID);
                formData.append('visit_id', actionInfo.action_id);
                formData.append('section_id', actionInfo.action_id);
                formData.append('section_item_id', actionInfo.action_id);
                try {

                    const response = await axios.post('https://us-central1-storecall.cloudfunctions.net/api/upload-file', formData);
                    const result = response.data;
                    const { url, size } = result;

                    const newFileElement = {
                        description: fileElement.file.name,
                        date: moment().format("DD/MM/YYYY"),
                        size: size,
                        file_id: moment().format('YYYYMMDDHHmmssSSS'),
                        user: storeDetails.user.name,
                        comment: fileElement.comment,
                        url: url,
                    };
                    updatedFileList.push(newFileElement);
                } catch (error) {
                    console.error(error);
                }
            }
        }

        // add action to list of actions
        // add action to db actions collection
        const actionItem = {
            action_id: actionInfo.action_id,
            type: "solo",
            visit_id: actionInfo.action_id,
            store_name: actionInfo.store_name || "Not Set",
            template_name: "Solo Action",
            visit_date: "",
            files: updatedFileList || [],
            section_name: "",
            section_item_name: actionInfo.section_item_name,
            section_id: actionInfo.action_id,
            section_item_id: actionInfo.action_id,
            date: moment().format("DD-MM-YYYY"),
            actionBy: actionInfo.actionBy,
            actionByName: actionInfo.actionByName,
            action_due_date: actionInfo.action_due_date,
            action_priority: actionInfo.action_priority,
            actionComplete: false,
            actionCompleteDate: "",
            ageing: getActionAgeing(actionInfo.action_due_date),
            action_set_by: actionInfo.action_set_by,
            action_set_by_name: users.filter(user => user.uid === actionInfo.action_set_by)[0].name
        }


        //setShowLoading(true)


        fetch('https://us-central1-storecall.cloudfunctions.net/api/add-action', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken,
                storeid: storeDetails.rootID,
                actionObject: actionItem
            })
        }).then(async (result) => {
            if (result.status !== 200) {
                setShowLoading(false)
                setBarMessage({
                    type: "error",
                    message: "There was an error adding this action"
                })

                setOpenBar(true)

            } else {

                const resultJsonData = await result.json();

                const actionId = resultJsonData.id;
                const updatedActionObject = {
                    ...actionItem,
                    action_id: actionId,
                    visit_id: actionId,
                    section_id: actionId,
                    section_item_id: actionId,
                    files: updatedFileList || []
                }
                
                setVisits(prevState => [...prevState, updatedActionObject])
                if(showEmptyScreen) setShowEmptyScreen(false)
                setBarMessage({
                    type: "info",
                    message: "Action added successfully."
                })

                logEvent(storeDetails.rootID, "Action", `Added - ${updatedActionObject.section_item_name}`, storeDetails.user.email);
                setShowLoading(false)
            }
        })

    }

    async function handleUndoCompleteAction(updatedActionObject) {
        setShowLoading(true)
        const idToken = await getIDToken();
        
        //console.log("Completing", selectedRows);

        // setVisits(prevState => [...prevState, actionItem])
        //     setVisitsComplete(prevState => prevState.filter(action => action.action_id !== actionItem.action_id))

        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/edit-action', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken,
                storeid: storeDetails.rootID,
                actionObject: updatedActionObject
            })
        })

        setShowLoading(false);
        if (result.status === 200) {
            setBarMessage({
                type: "info",
                message: "Action has been marked as incomplete."
            })

            setOpenBar(true)
            if(showEmptyScreen) setShowEmptyScreen(false)

            setVisits(prevState => [...prevState, updatedActionObject])
            setVisitsComplete(prevState => prevState.filter(action => action.action_id !== updatedActionObject.action_id))
            logEvent(storeDetails.rootID, "Action", `Marked as incomplete - ${updatedActionObject.section_item_name}`, storeDetails.user.email);
        } else {
            setBarMessage({
                type: "error",
                message: "There was an error completing this action"
            })

            setOpenBar(true)
        }
    }



    async function handleEditAction(actionItem) {

        const files = actionItem.files;
        const updatedFileList = [];
        const idToken = await getIDToken();

        if (actionItem.files.length > 0) {


            for (const fileElement of files) {
                const formData = new FormData();
                formData.append("idToken", idToken);
                formData.append('file', fileElement.file);
                formData.append("file_name", fileElement.file.name)
                formData.append('store_id', storeDetails.rootID);
                formData.append('visit_id', actionItem.action_id);
                formData.append('section_id', actionItem.action_id);
                formData.append('section_item_id', actionItem.action_id);
                try {

                    const response = await axios.post('https://us-central1-storecall.cloudfunctions.net/api/upload-file', formData);
                    const result = response.data;
                    const { url, size } = result;

                    const newFileElement = {
                        description: fileElement.file.name,
                        date: moment().format("DD/MM/YYYY"),
                        size: size,
                        file_id: moment().format('YYYYMMDDHHmmssSSS'),
                        user: storeDetails.user.name,
                        comment: fileElement.comment,
                        url: url,
                    };

                    
                    updatedFileList.push(newFileElement);
                } catch (error) {
                    console.error(error);
                }
            }
        }


        const actionIndex = visits.findIndex(action => action.action_id === actionItem.action_id);
        const actionObjectFiles = visits[actionIndex]?.files || [];
        const updatedActionObject = { ...actionItem, files: [...actionObjectFiles, ...updatedFileList] }
        // const updatedActionObject = { ...actionItem, files: [...visits[actionIndex].files, ...updatedFileList] }

        const updatedList = visits.map((item, i) => {
            if (i === actionIndex) {

                return updatedActionObject
            }
            else return item
        })

        setVisits(updatedList)


        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/edit-action', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken,
                storeid: storeDetails.rootID,
                actionObject: updatedActionObject
            })
        })

        if (result.status !== 200) {
            setShowLoading(false)
            setBarMessage({
                type: "error",
                message: "There was an error editing this action"
            })

            setOpenBar(true)

            // const filteredVisits = visits.filter(
            //     (visit) => visit.action_id !== actionInfo.action_id);
            // setVisits(filteredVisits)

            return false;

        }
        else {
            logEvent(storeDetails.rootID, "Action", `Edited - ${updatedActionObject.section_item_name}`, storeDetails.user.email);
        }
    }

    async function completeAction(actionID) {
        setShowLoading(true);

        const idToken = await getIDToken();

        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/complete-action', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken,
                storeid: storeDetails.rootID,
                actionid: actionID
            })
        })

        setShowLoading(false);
        if (result.status === 200) {
            setBarMessage({
                type: "info",
                message: "Action(s) have been completed."
            })

            setOpenBar(true)

            const actionItem = visits.find(action => action.action_id === actionID);
            const updatedActionItem = { ...actionItem, actionComplete: true, actionCompleteDate: moment().format("DD-MM-YYYY") }

            setVisits(prevState => prevState.filter(action => action.action_id !== actionID))
            setVisitsComplete(prevState => [...prevState, updatedActionItem])
            logEvent(storeDetails.rootID, "Action", `Completed - ${updatedActionItem.section_item_name}`, storeDetails.user.email);
        } else {
            setBarMessage({
                type: "error",
                message: "There was an error completing this action"
            })

            setOpenBar(true)
        }
    }

    async function undoCompleteAction(action) {

    }

    async function handleCompleteAction() {

        setShowLoading(true)
        const idToken = await getIDToken();
        //console.log("Action", actionItem)
        //console.log("Completing", selectedRows);

        const itemsComplete = selectedRows.filter(item => {
            return item.actionBy === storeDetails.user.uid || item.action_set_by === storeDetails.user.uid || storeDetails.user.admin
        })

        const deletedPromises = itemsComplete.map(async action => {

            return await fetch('https://us-central1-storecall.cloudfunctions.net/api/complete-action', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    idToken: idToken,
                    storeid: storeDetails.rootID,
                    actionid: action.action_id
                })
            })

        })

        Promise.all(deletedPromises).then(() => {
            setShowLoading(false)
            setBarMessage({
                type: "info",
                message: "Action(s) have been completed."
            })

            setOpenBar(true)

            const completedVisitItems = visits.filter(action => selectedRows.some(row => row.action_id === action.action_id)).map(action => {
                logEvent(storeDetails.rootID, "Action", `Completed - ${action.section_item_name}`, storeDetails.user.email);
                return { ...action, actionComplete: true, actionCompleteDate: moment().format("DD-MM-YYYY") }
            })

            setVisits(prevState => prevState.filter(action => !selectedRows.some(row => row.action_id === action.action_id)));
            setVisitsComplete(prevState => [...prevState, ...completedVisitItems])


        }).catch((error) => {
            setShowLoading(false)
            setBarMessage({
                type: "error",
                message: "There was an error editing this action"
            })

            setOpenBar(true)
        })

        setSelectedRows([])


    }

    async function handleDeleteAction() {

        if (selectedRows.length > 0) {
            setShowDeleteActionDialog(false)
            setShowLoading(true)

            const idToken = await getIDToken();

            const deletedPromises = selectedRows.filter(item => item.action_set_by === storeDetails.user.uid || storeDetails.user.admin).map(async action => {
                logEvent(storeDetails.rootID, "Action", `Deleted - ${action.section_item_name}`, storeDetails.user.email);
                return await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-action', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        idToken: idToken,
                        storeid: storeDetails.rootID,
                        actionid: action.action_id
                    })
                })
            })

            Promise.all(deletedPromises).then(() => {
                setBarMessage({
                    type: "info",
                    message: "Action(s) deleted successfully.",
                })

                setOpenBar(true)
                setVisits(prevState => prevState.filter(action => !selectedRows.some(row => row.action_id === action.action_id)));
                
                setShowLoading(false)
            }).catch(error => {
                setBarMessage({
                    type: "error",
                    message: "There was an error deleting these action(s)"
                })

                setOpenBar(true)
                setShowLoading(false)
                return false;
            })

        }

        if (selectedCompleteRows.length > 0) {
            setShowDeleteActionDialog(false)
            setShowLoading(true)

            const idToken = await getIDToken();

            const deletedPromises = selectedCompleteRows.filter(item => item.action_set_by === storeDetails.user.uid || storeDetails.user.admin).map(async action => {
                return await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-action', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        idToken: idToken,
                        storeid: storeDetails.rootID,
                        actionid: action.action_id
                    })
                })
            })

            Promise.all(deletedPromises).then(() => {
                setBarMessage({
                    type: "info",
                    message: "Completed action(s) deleted successfully.",
                })

                setOpenBar(true)

                setVisitsComplete(prevState => prevState.filter(action => !selectedCompleteRows.some(row => row.action_id === action.action_id)));


                setShowLoading(false)
            }).catch(error => {
                setBarMessage({
                    type: "error",
                    message: "There was an error deleting these action(s)"
                })

                setOpenBar(true)
                setShowLoading(false)
                return false;
            })

        }

        setSelectedRows([])

    }

    const handleSelectionModelChange = (selectionModel) => {

        const selectedActions = visits.filter(action =>
            selectionModel.some(item => item === action.action_id) &&
            (action.actionBy === storeDetails.user.uid || action.action_set_by === storeDetails.user.uid || storeDetails.user.admin))

        //console.log(selectedActions)

        setSelectedRows(selectedActions);
    };

    const handleSelectionModelCompleteChange = (selectionModel) => {

        const selectedActions = visitsComplete.filter(action =>
            selectionModel.some(item => item === action.action_id) &&
            (action.actionBy === storeDetails.user.uid || action.action_set_by === storeDetails.user.uid || storeDetails.user.admin))

        //.log(selectedActions)


        setSelectedCompleteRows(selectedActions);
    };

    return (
        <PageHolder>
            <Loader show={showLoading} />
            <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
                <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
                    {barMessage.message}
                </Alert>
            </Snackbar>

            <DeleteReportDialog
                open={showDeleteActionDialog}
                setOpen={setShowDeleteActionDialog}
                title="Delete Action"
                content="Are you sure you want to delete the selected action(s)?"
                extra_content="If the action is linked to a visit it will remove the action from the visit."
                action={handleDeleteAction} />

            <Header>
                <HeaderHolder>
                    Actions Outstanding
                    <TemplateDescription>Manage and create actions for your team</TemplateDescription>
                </HeaderHolder>

            </Header>

            {showActionDialog && <ActionNewDialog
                open={showActionDialog}
                setOpen={setShowActionDialog}
                addAction={handleAddAction}
                updateAction={handleEditAction}
                completeAction={completeAction}
                undoCompleteAction={handleUndoCompleteAction}
                deleteAction={handleDeleteAction}
                removeActionFile={removeActionFile}
                actionInfo={selectedAction}
                my_uid={storeDetails.user.uid}
                permissions={storeDetails.permissions}
                stores={stores}
                users={users} />}

            <PageContainer empty_shadow={true} style={{ 'padding': '0px' }}>


                {showEmptyScreen && <EmptyScreen
                    title="Hey, you're all caught up!"
                    contents={"Looks like you don't have any actions that are outstanding."}
                    visible={true}
                    imgSrc={EmptyActionImg} />}


                {!showEmptyScreen && <>
                    {/* <TemplateCardContainer>
                        <TemplateCard>
                            <TemplateCardHeader>Overdue</TemplateCardHeader>
                            <TemplateCardValue>5</TemplateCardValue>
                        </TemplateCard>

                        <TemplateCard>
                            <TemplateCardHeader>Complete</TemplateCardHeader>
                            <TemplateCardValue>10</TemplateCardValue>
                        </TemplateCard>
                    </TemplateCardContainer> */}



                    {visits &&
                        <Fragment>
                            <PageContainer empty_shadow={true} style={{ 'padding': '0px', 'marginTop': '10px' }}>
                                <TemplateFormFieldRow align="space-between">
                                    <Text fontSize={'1.2rem'} fontWeight={"700"}></Text>
                                    <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>

                                </TemplateFormFieldRow>
                                {filters &&
                                    <TemplateFilterContainer style={{ marginTop: '0px' }}>
                                        {stores && stores.length > 0 &&
                                            <Autocomplete
                                                multiple
                                                id="store-tags"
                                                options={[...stores].sort((a, b) => a.region.localeCompare(b.region))}
                                                groupBy={(option) => option.region}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedStores}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}

                                                filterSelectedOptions

                                                onChange={handleStoreChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size='small'
                                                        label="Filter location"
                                                        placeholder="Add a location"
                                                    />
                                                )}
                                            />}

                                        {templates && templates.length > 0 &&
                                            <Autocomplete
                                                sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
                                                multiple
                                                id="template-tags"
                                                options={[...templates].sort((a, b) => a.template_type.localeCompare(b.template_type))}
                                                groupBy={(option) => option.template_type}
                                                getOptionLabel={(option) => option.name}
                                                value={selectedTemplates}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}

                                                filterSelectedOptions
                                                onChange={handleTemplateChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size='small'
                                                        label="Filter by template(s)"
                                                        placeholder="Add a template"
                                                    />
                                                )}

                                            />}

                                        {actionUsers && actionUsers.length > 0 &&
                                            <Autocomplete
                                                sx={{ 'marginTop': '5px', 'marginBottom': '10px' }}
                                                multiple
                                                id="user-tags"
                                                options={actionUsers}
                                                value={selectedUsers}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                getOptionLabel={(option) => option.name}
                                                filterSelectedOptions

                                                onChange={handleUserChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size='small'
                                                        label="Filter users"
                                                        placeholder="Filter users"
                                                    />
                                                )}

                                            />}

                                        <TemplateFormButtonStack style={{ 'marginBottom': '10px' }} align="right">
                                            <Button color={`${Colors.highlight}`} onClick={handleClearFilters}>Clear filters</Button>
                                            <Button color={`${Colors.dark_grey_highlight}`} onClick={() => handleSearchFilter(visits, stores, templates, actionUsers, selectedStores, selectedTemplates, selectedUsers)}>Search</Button>

                                        </TemplateFormButtonStack>

                                        {/* <FormGroup>
        <FormControlLabel control={<Checkbox checked={filterActions} onChange={(e) => handleFilterActionReports(e.target.checked)} />} label="Filter reports with outstanding actions" />
        </FormGroup> */}

                                    </TemplateFilterContainer>}
                                <TableContainer style={{ padding: '0px', 'marginBottom': '0px' }}>

                                    <DataGrid
                                        
                                        autoHeight
                                        sx={{ 'border': '0' }}
                                        pageSize={pageSizeActions}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        onPageSizeChange={(newPageSize) => setPageSizeActions(newPageSize)}
                                        rows={visits}
                                        columns={columns}
                                        onRowClick={(params) => handleEditActionDialog(params.row.action_id)}
                                        getRowId={(row) => row.action_id}
                                        sortModel={[
                                            {
                                                field: 'action_due_date',
                                                sort: 'asc',
                                            },
                                        
                                        ]}
                                        checkboxSelection
                                        onSelectionModelChange={handleSelectionModelChange}
                                        checkboxSelectionProps={{
                                            renderCell: (params) => (
                                                <Checkbox
                                                    color="primary"
                                                    checked={params.value}
                                                    onChange={(event) => params.api.selectRow(params.action_id, event.target.checked)}
                                                />
                                            ),
                                        }}
                                    />
                                </TableContainer>
                            </PageContainer>



                        </Fragment>}

                </>}

                <TemplateFormFieldRow style={{ 'marginTop': '20px' }} align="right">



                    <TemplateFormButtonStack align="right">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={showCompleteActions} onChange={(e) => setShowCompleteActions(e.target.checked)} />} label={<Typography style={{ fontWeight: "500" }} variant="h7">Show actions complete</Typography>} />
                        </FormGroup>
                        {storeDetails.permissions.actions.delete && selectedRows.length > 0 && <Button onClick={() => setShowDeleteActionDialog(true)} highlighted><StyledActionIcon /> Delete Action</Button>}
                        {storeDetails.permissions.actions.complete && selectedRows.length > 0 && <Button color={`${Colors.green}`} onClick={handleCompleteAction} ><StyledTickIcon /> Complete</Button>}
                        {storeDetails.permissions.actions.add && <Button onClick={handleNewActionDialog}><StyledActionIcon style={{ 'marginRight': '5px' }} /> Add Action</Button>}
                    </TemplateFormButtonStack>

                </TemplateFormFieldRow>

                {showCompleteActions &&
                    <PageContainer empty_shadow={true} style={{ 'padding': '0px', 'marginTop': '0px' }}>
                        <HeaderTitle>Actions Complete</HeaderTitle>
                        <TableContainer style={{ padding: '0px' }}>


                            <DataGrid
                                autoHeight
                                sx={{ 'border': '0' }}

                                rows={visitsComplete}
                                //rows={filterActions===true ? storeVisits.filter(visit => visit.actions_outstanding > 0) : storeVisits}
                                columns={columnsComplete}
                                onRowClick={(params) => handleEditActionCompleteDialog(params.row.action_id)}
                                getRowId={(row) => row.action_id}
                                pageSize={pageSizeActionsComplete}
                                rowsPerPageOptions={[10, 25, 50]}
                                onPageSizeChange={(newPageSize) => setPageSizeActionsComplete(newPageSize)}
                                
                                checkboxSelection
                                onSelectionModelChange={handleSelectionModelCompleteChange}
                                checkboxSelectionProps={{
                                    // add Checkbox component as the render function
                                    renderCell: (params) => (
                                        <Checkbox
                                            color="primary"
                                            checked={params.value}
                                            onChange={(event) => params.api.selectRow(params.action_id, event.target.checked)}
                                        />
                                    ),
                                }}

                            />
                        </TableContainer>
                    </PageContainer>}

            </PageContainer>

            <TemplateFormButtonStack align="right">

                {storeDetails.permissions.actions.delete && selectedCompleteRows.length > 0 && <Button color={Colors.highlight} onClick={() => setShowDeleteActionDialog(true)}><StyledDeleteIcon /> Delete Completed</Button>}
            </TemplateFormButtonStack>

        </PageHolder>

    )
}

export default ActionsHome