import React, { Fragment, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, Button } from './styled/styled.container'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, where, getDocs, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFilterContainer } from './styled/styled.templateform';

import Chart from "react-apexcharts";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment/moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", hide: true },
  { field: 'store_name', headerName: 'Location Name', minWidth: 150, flex: 1 },
  { field: 'template_name', headerName: 'Template', minWidth: 150, flex: 1 },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 100,
    flex: 1

  },
  {
    field: "score", headerName: "Score",
    minWidth: 100,
    flex: 1

  },
  {
    field: "startTime", headerName: "Start Time",
    minWidth: 100,
    flex: 1
  },
  {
    field: "endTime", headerName: "End Time",
    minWidth: 100,
    flex: 1
  },
  {
    field: "time", headerName: "Time",
    minWidth: 100,
    flex: 1
  },
  {
    field: "actions_outstanding",
    headerName: "Outstanding Actions",
    minWidth: 170,
    flex: 1
  }
];

function ReportsOutstandingActions(props) {
  const { storeDetails, stores, templates } = props;

  // holds selected stores and template names only
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  // holds selected store name + cloud id
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [visits, setVisits] = useState([]);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [sectionItems, setSectionItems] = useState([]);
  const [filters, setFilters] = useState(true);
  const [filterActions, setFilterActions] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
  const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
  const navigate = useNavigate();


  useEffect(() => {

    // sort the list so that points are projected in order
    // let visitDates = visits.sort((a,b) => {
    //     if(a.date < b.date) return 1;
    //     else if(a.date > b.date) return -1;
    //     else return 0;
    // }).map(visit => visit.date)

    setSeries([])

    selectedTemplatesInfo.forEach(template => {
      const outstandingQty = [];
      selectedStoresInfo.forEach(store => {
        const visitList = visits.filter(visit => visit.store_id === store.cloud_id && visit.template_id === template.cloud_id);
        const outstandingActions = visitList.map(visit => visit.actions_outstanding).reduce((total, current) => total + current, 0);
        outstandingQty.push(outstandingActions)
      })

      const visitItem = {
        name: template.name,
        data: outstandingQty
      }


      setSeries(prevState => [...prevState, visitItem])


    })

    setOptions({
      chart: {
        id: 'bar-chart',
        type: 'bar',
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }


          }
        }
      },
      title: {
        text: "Outstanding Actions"
      },
      xaxis: {
        categories: selectedStoresInfo.map(store => store.name),
        labels: {
          formatter: function (val) {
            return ""
          }
        }

      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 50
      }
    })


  }, [visits])

  async function getVisits() {

    let templateIDList = [];
    let storeIDList = [];
    setVisits([]);

    if (selectedStores.length == 0 || selectedTemplates.length == 0) {

      if (selectedStores.length == 0) storeIDList = stores;
      else {
        storeIDList = stores.filter(store => (selectedStores.findIndex(x => x === store.name) >= 0)).map((value) => {
          return value;
        })
      }
      if (selectedTemplates.length == 0) templateIDList = templates;
      else {
        templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x === template.name) >= 0)).map((value) => {
          return value;
        })
      }

    }

    else {
      templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x === template.name) >= 0)).map((value) => {
        return value;
      })
      storeIDList = stores.filter(store => (selectedStores.findIndex(x => x === store.name) >= 0)).map((value) => {
        return value;
      })
    }

    setSelectedStoresInfo(storeIDList);
    setSelectedTemplatesInfo(templateIDList);
    const dateTo = moment(filterDateTo).add("1", "days").toISOString();


    templateIDList.forEach(template => {
      storeIDList.forEach(async store => {

        const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"),
          where("template_id", "==", template.cloud_id),
          where("store_id", "==", store.cloud_id),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo));

        const visitDocs = await getDocs(q);
        visitDocs.docs.forEach(async visit => {
          const visitData = visit.data();


          const sectionQueryCollectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visit.id + "/sections"), orderBy("key", "asc"));
          const sectionDocs = await getDocs(sectionQueryCollectionRef);
          const sectionData = sectionDocs.docs.map(doc => {
            const docData = doc.data();
            return {
              name: docData.name,
              score: docData.score
            }
          })

          setVisits(prevState => [...prevState, {
            ...visitData,
            cloud_id: visit.id,
            id: visit.id + visit.startTime,
            name: store.name,
            date: moment(visitData.date).format("DD-MM-YYYY"),
            sections: sectionData,
            store_name: store.name,
            template_name: template.name,
            startTime: visitData.startTime,
            endTime: visitData.endTime,
            time: moment.duration(moment(visitData.startTime, "DD-MM-YYYY HH:mm").diff(moment(visitData.endTime, "DD-MM-YYYY HH:mm"))).humanize()
          }])
        })
      })
    })
  }

  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleStoreChange = (event, values) => {

    const storeNames = values.map(value => value.name);
    setSelectedStores(storeNames);
  };

  const handleTemplateChange = (event, values) => {

    const templateNames = values.map(value => value.name);
    setSelectedTemplates(templateNames);
  };

  const handleRowClick = (params) => {
    navigate("/app/storevisit/actions/");
  };

  function handleSearchFilter() {
    getVisits();
  }

  return (
    <Fragment>

      <TemplateFormFieldRow align="right" style={{ margin: '10px 0px' }}>
        <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>
      </TemplateFormFieldRow>

      {filters &&
        <TemplateFilterContainer style={{ marginTop: '5px' }}>
          {stores && stores.length > 0 &&
            <Autocomplete
              multiple
              id="store-tags"
              options={[...stores].sort((a, b) => {


                const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                if (groupSort && groupSort === 0) {
                  return sortRegions(a, b);
                }
                return groupSort;
              })}
              groupBy={groupByRegion}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={handleStoreChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a location"
                  placeholder="Add a location"
                />
              )}
            />}

          {templates && templates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
              multiple
              id="template-tags"
              options={[...templates].sort((a, b) => {
                const groupA = a.categoryName;
                const groupB = b.categoryName;
                if (groupA === groupB) {
                  return a.name.localeCompare(b.name);
                }
                return groupA.localeCompare(groupB);
              })}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              groupBy={(option) => option.categoryName}
              onChange={handleTemplateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a template"
                  placeholder="Add a template"
                />
              )}
            />}

          <TemplateFormFieldDateContainer>
            <TextField
              sx={{ 'marginRight': '10px' }}
              label="Period from"
              size='small'
              type="date"
              value={filterDateFrom}
              onChange={(e) => setFilterDateFrom(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Period to"
              size='small'
              type="date"
              fullWidth
              value={filterDateTo}
              onChange={(e) => setFilterDateTo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />


          </TemplateFormFieldDateContainer>

          <Button disabled={selectedStores.length === 0 || selectedTemplates.length === 0} highlighted onClick={handleSearchFilter} style={{ margin: '10px 0px' }} fullWidth>Run Report</Button>

        </TemplateFilterContainer>}

      <Chart
        options={options}
        series={series}
        type="bar"

        height="400" />

      {visits && visits.length > 0 &&
        <TableContainer>
          <DataGrid
            autoHeight
            sx={{ 'border': '0' }}
            components={{ Toolbar: GridToolbar }}
            rows={visits.filter(visit => visit.actions_outstanding > 0)}
            onRowClick={handleRowClick}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'store_name', sort: 'desc' }],
              },
            }}
            getRowId={(row) => row.id}
            pageSize={7}
            rowsPerPageOptions={[7]}
          />
        </TableContainer>
      }
    </Fragment>
  )
}

export default ReportsOutstandingActions