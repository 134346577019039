import React, {useEffect, useState} from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom';

import ActionsHome from './ActionsHome';

function Actions(props) {

  const {storeDetails} = props;

  useEffect(() => {

    if(!storeDetails.permissions.actions.view) navigate("/app")

  }, [storeDetails])

  const navigate = useNavigate();
  

  return (
    <>
    <Routes>
      <Route path='/' element={<ActionsHome storeDetails={storeDetails} />} />
      <Route path='/:store/:template' element={<ActionsHome storeDetails={storeDetails} />} />
      <Route path="/:user" element={<ActionsHome storeDetails={storeDetails} />} />
    </Routes>

    </>
  )
}

export default Actions