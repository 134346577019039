import React, { useState, useEffect, useReducer, useCallback } from 'react'
import { CardContainer, Card, NewButton, HeaderSubTitle, HeaderTitle, PageContainer, ActionCompleteDate, AddOptionButton, StyledAddOptionIcon, PageHolder, StyledUpdateIcon, StyledDeleteIcon, StyledStatusIcon, HeaderHolder, StyledDownloadIcon, StyledExportIcon, Colors, Text, ColumnContainer } from './styled/styled.container';
import { TemplateFormFieldContainer, TemplateFormFieldNewCommentContainer, TemplateFormFieldComment, TemplateFormFieldNewFieldButton, TemplateFormFieldNewField, TemplateFormField, TemplateFormFieldText, TemplateFormFieldType, TemplateFormFieldRow, TemplateFormFieldAssignable, TemplateFormFieldNumber, TemplateFormFieldLabel, TemplateFormFieldButton, TemplateFormTitle, TemplateFormEmptyContainer, TemplateFormEmptyContainerText, TemplateFormErrorContainer, TemplateFormErrorContainerText, TemplateFormFieldCompleted, TemplateFormFieldFiles, TemplateSectionLastScore, TemplateFormFieldInsertComment, TemplateFormFieldLink, TemplateSectionHeader, TemplateComments, TemplateFormFieldScoreContainer, TemplateFormFieldQuestion, TemplateFormFieldSignatureLabel, TemplateDescription, TemplateFormButtonStack, DivHolder } from './styled/styled.templateform';
import { db, storage } from "./firebase";
import { where, getDocs } from 'firebase/firestore';
import { arrayUnion } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { FieldText, FieldTypeComment, ToolbarItem, VisitHeaderItemContainer, VisitToolbar } from './styled/styled.storevisit';
import axios from 'axios';
import { useParams, useNavigate, Prompt } from 'react-router-dom';
import Compress from "browser-image-compression"
import moment from 'moment/moment';
import { collection, onSnapshot, query, doc, setDoc, deleteDoc, addDoc, getDoc, updateDoc } from "firebase/firestore";
import { orderBy } from "firebase/firestore";
import { TextField, InputAdornment, Divider, IconButton, Tooltip } from '@mui/material';
import { Button } from './styled/styled.container';
import { TemplateSection, TemplateFormFieldFilesContainer } from './styled/styled.templateform';
import FilePreview from './FilePreview';
import { VisitHeaderContainer, VisitHeaderItem } from './styled/styled.storevisit';
import { makeStyles } from "@material-ui/core/styles";
import Loader from './Loader';
import ActionCompleteButton from './ActionCompleteButton';
import DeleteFileDialog from './DeleteFileDialog';
import { StyledNavLink } from './styled/styled.container';
import { Fragment } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import StoreVisitCompleteDialog from './StoreVisitCompleteDialog';
import DeleteReportDialog from './DeleteReportDialog';
import Header from './Header';
import ErrorDialog from './ErrorDialog';

import { auth } from "./firebase"

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocationMap from './LocationMap';
import { SignatureImage, SignatureImageContainer } from './styled/styled.signature';
import SectionReport from './SectionReport';
import { useContext } from "react";
import AuthContext from "./AuthContext";
import logEvent from './hooks/logEvent';
import { DashboardCard, DashboardContainer, DashboardItem } from './styled/styled.dashboard';
import { StyledVisitIcon } from './styled/styled.icons';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialState = {
  inputText: ''
};

function comment_reducer(state, action) {
  switch (action.type) {
    case 'SET_COMMENT_TEXT':
      return {
        ...state,
        inputText: action.payload
      };
    case 'CLEAR_COMMENT_TEXT':
      return {
        ...state,
        inputText: ''
      };
    default:
      return state;
  }
}


/*

Still to do

Show modal once loading complete

*/

const useStyles = makeStyles((theme) => ({
  section: {
    background: "#4361ee",
    color: "white",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));


function StorevisitReport(props) {

  const classes = useStyles();
  const { selectedStore, selectedTemplate } = props;
  const { storeDetails } = useContext(AuthContext);
  const [sections, setSections] = useState([]);
  const [currentComment, setCurrentComment] = useReducer(comment_reducer, initialState);
  const [users, setUsers] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorContent, setErrorContent] = useState({});
  const [reportStatusOpen, setReportStatusOpen] = useState(false)
  const [selectedItemID, setSelectedItemID] = useState();

  const [location, setLocation] = useState();
  const [visitDetails, setVisitDetails] = useState({
    storeName: "",
    templateName: "",
    templateDescription: "",
    regionName: "",
    manager: "",
    visitDate: "",
    visitID: "",
    visitBy: "",
    reportTiming: "",
    visitScore: "",
    visitScorable: false,
    visitType: "",
    visitStatus: "",
    visitClosedDate: "",
    location_required: "",
    totalActions: "",
    outstandingActions: ""
  })

  const [templateID, setTemplateID] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  const [lastVisit, setLastVisit] = useState();
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [showDeleteReportDialog, setShowDeleteReportDialog] = useState(false);
  const [images, setPreviewImages] = useState([]);
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})

  let visitQueryCollectionRef = "";
  let visitCollectionRef = "";

  const params = useParams();
  const navigate = useNavigate();
  const canEdit = storeDetails.permissions.visits.edit;

  useEffect(() => {
    setSections([])
    setUsers([]);

    async function checkUserHasReportPermission() {
      const visitRootRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + params.id);
      const visitDoc = await getDoc(visitRootRef);
      if (visitDoc.exists()) {
        const visitData = visitDoc.data();
        const store_id = visitData.store_id;
        const template_id = visitData.template_id;

        const userSpecificStoreList = storeDetails.user.specific_store_list;
        const userSpecificTemplateList = storeDetails.user.specific_template_list;

        if (userSpecificStoreList && userSpecificStoreList.length > 0) {

          if (!userSpecificStoreList.includes(store_id)) return false;
        }
        if (userSpecificTemplateList && userSpecificTemplateList.length > 0) {

          if (!userSpecificTemplateList.includes(template_id)) return false;
        }

        return true;
      }

    }

    async function getPermissionUsers() {

      const visitRootRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + params.id);
      const visitDoc = await getDoc(visitRootRef);
      const visitData = visitDoc.data();
      const template_id = visitData.template_id;

      const templateCollectionRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + template_id);
      const templateDoc = await getDoc(templateCollectionRef)
      const templateData = templateDoc.data();
      const permissionUsers = templateData.permission_users;

      // set users for action by to only users who have permission
      // for that template
      const usersRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"),
        where("group", 'in', permissionUsers));

      const userDocs = await getDocs(usersRef);
      let userList = userDocs.docs.map(doc => {
        const userData = doc.data();

        if (userData.specific_templates) {
          const userTemplates = userData.specific_template_list;
          if (userTemplates.indexOf(template_id) > -1)
            return userData;

        } else {
          return userData

        }
      })

      const adminUsers = storeDetails.all_users.filter(user => user.type === "admin");
      const mergedUsers = [...userList, ...adminUsers]
      const uniqueUsers = [];
      mergedUsers.forEach(user => {
        if (!uniqueUsers.find(unique => unique.uid === user.uid)) uniqueUsers.push(user)
      })

      setUsers(uniqueUsers)

    }

    async function getVisit() {
      let template_id = "";

      if (params.id) {
        //setVisitID(params.id);

        // first get the visit information (storeID, templateID, date visited)
        const visitRootRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + params.id);
        await getDoc(visitRootRef).then(async result => {
          if (result.exists()) {

            const reportData = result.data();
            
            const store_id = reportData.store_id;
            template_id = reportData.template_id;
            const visit_date = moment(reportData.date).format("DD-MM-YYYY HH:mm");
            const visit_end_date = moment(reportData.endTime).format("DD-MM-YYYY HH:mm")
            const visit_score = reportData.score;
            const selected_id = reportData.selected_id;
            const visit_by = reportData.user;
            const visit_by_uid = reportData.user_uid;
            const visit_scorable = reportData.scorable;
            const actions_total = reportData.actions_total;
            const actions_outstanding = reportData.actions_outstanding;
            const startTime = reportData.startTime;
            const endTime = reportData.endTime;
            const manager_uid = reportData.manager;
            const visit_type = reportData.visit_type
            const visit_status = reportData.visit_status;
            const visit_closed_date = reportData.visit_closed_date
            const location_required = reportData.location_required
            const location = reportData.location
            const signature_link = reportData.signature_link

            setLocation(location)

            if (visit_status === "Open") setReportStatusOpen(true)

            const reportTiming = moment.duration(moment(startTime, "DD-MM-YYYY HH:mm").diff(moment(endTime, "DD-MM-YYYY HH:mm"))).humanize();

            let visitByUser = {
              uid: "",
              name: ""
            };

            if (!reportData.isPublic) {

              getLastVisitDetails();

              const visitUserRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"),
                where("uid", "==", visit_by_uid));
              const visitUserDoc = await getDocs(visitUserRef);
              const visitUserData = visitUserDoc.docs[0].data();
              if (visitUserData) {
                visitByUser = {
                  uid: visitUserData.uid,
                  name: visitUserData.name
                }
              }
              else {
                visitByUser = {
                  uid: visit_by_uid,
                  name: visit_by
                }
              }
            }
            else {

              getLastPublicVisitDetails();

              visitByUser = {
                uid: "",
                name: "Public"
              }
            }

            // const storeRootRef = doc(db, "stores/" + storeDetails.rootID + "/stores/" + store_id);
            const templateRootRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + template_id);

            // const storeRootRefDoc = await getDoc(storeRootRef);
            // const storeRootRefData = storeRootRefDoc.data();
            // const storeName = storeRootRefData.name;

            const store = storeDetails.stores.find(store => store.cloud_id === store_id);
            const storeName = store?.name;

            const regionName = store?.region;
            const subRegionsName = store?.sub_region;

            // const regionRef = doc(db, "stores/" + storeDetails.rootID + "/regions/" + storeRootRefData.region_id);
            // const regionDoc = await getDoc(regionRef);
            // const regionData = regionDoc.data();
            // const regionName = regionData.name;
            const templateDoc = await getDoc(templateRootRef);
            const templateData = templateDoc.data();
            const templateName = templateData.name;
            const templateDescription = templateData.description || "";
            let storeManager = ""

            if (manager_uid && manager_uid.length > 0) {
              const storeManagerRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", manager_uid));
              const storeManagerDoc = await getDocs(storeManagerRef);
              if (storeManagerDoc.docs.length > 0) {
                const storeManagerData = storeManagerDoc.docs[0].data();
                storeManager = storeManagerData.name
              }
              else {
                storeManager = "Not set"
              }
            } else storeManager = "Not set"

            
            setVisitDetails({
              storeName: reportData.isPublic ? "None" : storeName,
              regionName: reportData.isPublic ? "None" : regionName,
              subRegionsName: reportData.isPublic ? "None" : subRegionsName,
              templateName: templateName,
              templateDescription: templateDescription,
              manager: reportData.isPublic ? "None" : storeManager,
              visitDate: visit_date,
              selected_id: selected_id,
              visitEndDate: visit_end_date,
              visitID: params.id,
              visitBy: visitByUser,
              reportTiming: reportTiming,
              visitScore: visit_score,
              visitScorable: visit_scorable,
              visitType: visit_type,
              visitStatus: visit_status,
              visitClosedDate: visit_closed_date ? visit_closed_date : "",
              totalActions: actions_total,
              outstandingActions: actions_outstanding,
              location_required: location_required,
              location: location,
              signature_link: signature_link
            })

            setSections([])

            visitQueryCollectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + params.id + "/sections"), orderBy("key", "asc"));
            visitCollectionRef = collection(db, "stores/" + storeDetails.rootID + "/visits/" + params.id + "/sections");

            const visitCollectionData = await getDocs(visitCollectionRef);
            const mySections = await Promise.all(visitCollectionData.docs.map(async item => {

              const sectionDetails = item.data();

              const sectionData = await Promise.all(sectionDetails.list.map(async element => {

                const elementActions = element.actions;
                if (elementActions.length > 0) {
                  const updatedActions = await Promise.all(elementActions.map(async action => {
                    const actionID = action.action_id;
                    const actionRef = doc(db, "stores/" + storeDetails.rootID + "/actions/" + actionID);
                    const actionDoc = await getDoc(actionRef);
                    const actionData = actionDoc.data();
                    const actionByRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", actionData.actionBy));
                    const actionByDoc = await getDocs(actionByRef);
                    const actionByData = actionByDoc.docs[0].data();
                    //console.log("ActionByName", actionByData.name)

                    return {
                      ...actionData,
                      action_id: actionID,
                      actionByName: actionByData.name
                    }
                  }))

                  //console.log("UpdatedActions", updatedActions)

                  return {
                    ...element, actions: updatedActions
                  }
                }
                else return element;

              }))


              const updatedSection = { ...sectionDetails, list: sectionData }

              return updatedSection

            }))

            //console.log("MySection", mySections)

            const sortedSections = mySections.sort((a, b) => {
              if (a.key < b.key) return -1;
              else if (a.key > b.key) return 1;
              else return 0
            })

            setSections(sortedSections)

          }
          else {
            // navigate("/app/storevisit/error")
          }
        })
      }

      return template_id;
    }

    async function getLastVisitDetails() {

      const visitRootRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + params.id);
      const visitDoc = await getDoc(visitRootRef);
      const visitData = visitDoc.data();

      const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"), where("template_id", "==", visitData.template_id), where("store_id", "==", visitData.store_id), orderBy("date", "desc"));
      const docs = await getDocs(q);

      if (docs.docs.length > 1) {

        const currentVisitIndex = docs.docs.findIndex(doc => doc.id === params.id);

        // if the current visit is not the last visit
        if (currentVisitIndex !== docs.docs.length - 1) {
          const docData = docs.docs[currentVisitIndex + 1].data();

          const visitID = docs.docs[currentVisitIndex + 1].id;

          const sectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visitID + "/sections"));
          const sectionDocs = await getDocs(sectionRef);
          const sectionItems = sectionDocs.docs.map(doc => doc.data());

          const lastVisitInfo = {
            id: docs.docs[0].id,
            data: {
              ...docData,
              date: moment(docData.date).format("DD-MM-YYYY"),
              sections: sectionItems
            }
          }

          setLastVisit(lastVisitInfo);
        }
      }
      // if only 1 visit for that template
      else if (docs.docs.length === 1) {
        //console.log("Only 1 visit with template")
        const docData = docs.docs[0].data();
        const lastVisitInfo = {
          id: docs.docs[0].id,
          data: docData
        }
        //setLastVisit(lastVisitInfo);
      }

    }

    async function getLastPublicVisitDetails() {

      const visitRootRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + params.id);
      const visitDoc = await getDoc(visitRootRef);
      const visitData = visitDoc.data();

      const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"), where("template_id", "==", visitData.template_id), orderBy("date", "desc"));
      const docs = await getDocs(q);

      if (docs.docs.length > 1) {

        const currentVisitIndex = docs.docs.findIndex(doc => doc.id === params.id);

        // if the current visit is not the last visit
        if (currentVisitIndex !== docs.docs.length - 1) {
          const docData = docs.docs[currentVisitIndex + 1].data();

          const visitID = docs.docs[currentVisitIndex + 1].id;

          const sectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visitID + "/sections"));
          const sectionDocs = await getDocs(sectionRef);
          const sectionItems = sectionDocs.docs.map(doc => doc.data());

          const lastVisitInfo = {
            id: docs.docs[0].id,
            data: {
              ...docData,
              date: moment(docData.date).format("DD-MM-YYYY"),
              sections: sectionItems
            }
          }

          setLastVisit(lastVisitInfo);
        }
      }
      // if only 1 visit for that template
      else if (docs.docs.length === 1) {
        //console.log("Only 1 visit with template")
        const docData = docs.docs[0].data();
        const lastVisitInfo = {
          id: docs.docs[0].id,
          data: docData
        }
        //setLastVisit(lastVisitInfo);
      }

    }

    async function getDetails() {
      setShowLoading(true)
      const reportPermission = await checkUserHasReportPermission();
      if (!reportPermission) navigate("/app/404");
      else {
        await getVisit();
        
        await getPermissionUsers();
        setShowLoading(false)
      }
    }

    if (storeDetails.rootID.length > 0) getDetails();


  }, [storeDetails])

  const checkFieldsCloseOnFill = () => {
    if (visitDetails.visitStatus === "Closed") return true;
    else {
      let closeVisit = true;

      for (let i = 0; i < sections.length; i++) {
        const sectionElements = sections[i].list;

        if (sectionElements.some(item => {
          if (item?.closeOnFill) {
            if (item.type === "Comment" || item.type === "Number") {
              return item.fieldText.length === 0;
            } else if (item.type === "Selection") {
              return item.selectionOption.length === 0;
            } else if (item.type === "List Checkbox") {
              return item.item_list_checkbox.length === 0;
            } else if (item.type === "Item Comment" || item.type === "Item List" || item.type === "Item Selection") {
              return item.item_list_comment.length === 0;
            } else if (item.type === "Item Count") {
              return item.counts.length === 0;
            } else if (item.type === "DateTime" || item.type === "Time") {
              return item.datetime.length === 0;
            } else if (item.type === "YesNo") {
              return item.yesno.length === 0;
            }
          }
          return false;
        })) {
          closeVisit = false;
          break;
        }
      }

      return closeVisit;
    }
  }


  const handleClick = () => {
    setOpenBar(true);
  };

  const handleCommentChange = (event) => {
    setCurrentComment({
      type: 'SET_COMMENT_TEXT',
      payload: event.target.value
    });
  };

  const handleClose = (event, reason) => {

    setOpenBar(false);
    setShowLoading(false)
    if (barMessage.result === "success") navigate(-1);
  };

  async function removeFileCloud(section_id, section_item_id, index, file_name) {
    setShowLoading(true);

    const idToken = await getIDToken();

    const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-file', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idToken: idToken,
        storeid: storeDetails.rootID,
        visitid: visitDetails.visitID,
        sectionid: section_id,
        sectionitemid: section_item_id,
        file_index: index,
        file_name: file_name
      })
    })

    if (result.status === 200) {
      setBarMessage({
        type: "info",
        message: "File has been successfully deleted.",
      })

      setOpenBar(true)
      return true;

    }
    else {
      setShowLoading(false)
      setBarMessage({
        type: "error",
        message: "There was an error deleting this file."
      })

      setOpenBar(true)
      return false;

    }

  }

  function removeFiles(section_id, section_item_id) {


    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, newFiles: [] }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function handleFilesUpload(section_id, section_item_id, newFiles) {

    // const selectedElementFiles = sections.filter(element => element.id === id);
    // let currentFiles = [...selectedElementFiles[0].files];

    // const updatedFiles = [...currentFiles, ...newFiles];
    // console.log(updatedFiles);
    //uploadFiles(visitID, section_id, section_item_id, newFiles);


    const updatedSections = sections.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return {
                ...item, newFiles: [...item.newFiles, ...newFiles.map(newFile => {

                  return { file: newFile, comment: "" }
                })]
              }
            } else return item;
          })
        }
      }
      else return el;
    })

    setSections(updatedSections)

    // setTemplateFields(prevState => (
    //   prevState.map(el => (el.id === id ? { ...el, files: updatedFiles } : el)) 
    // ))
  }

  function handleShowElementComments(section_id, section_item_id) {

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, showComments: !item.showComments }
            } else return item;
          })
        }
      }
      else return el;
    })))

  }

  function handleAddComment(section_id, section_item_id, comment) {


    const dateStoreVisit = moment().format("DD-MM-YYYY");
    const datedComment = "(" + dateStoreVisit + ") " + storeDetails.user.name + ": " + comment;

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, comments: [...item.comments, datedComment] }
            } else return item;
          })
        }
      }
      else return el;
    })))


    setCurrentComment({
      type: 'CLEAR_COMMENT_TEXT'
    });
  }

  function handlePreviewImages(id) {
    const element = sections.filter(element => element.id === id);
    const images = element[0].files;
    setPreviewImages(() => images)
    navigate(`/app/storevisit/report/${visitDetails.visitID}/images`)
  }

  function goBack() {
    navigate("/app/storevisit")
  }

  function handleCompleteItem(section_id, section_item_id) {


    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              const isComplete = !item.actionComplete;
              if (isComplete) {
                return {
                  ...item,
                  actionCompleteDate: moment().format("DD-MM-YYYY HH:mm"),
                  actionComplete: isComplete
                }
              } else {
                return {
                  ...item,
                  actionCompleteDate: "",
                  actionComplete: isComplete
                }
              }

            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function handleUpdateFieldText(section_id, section_item_id, value) {

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, fieldText: value }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function handleUpdateFieldTemperature(section_id, section_item_id, value) {

    //if(!isLetters(value)) return

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, fieldText: value }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function updatedFormFieldProductName(section_id, section_item_id, index, value) {
    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return {
                ...item, counts: item.counts.map((product, i) => {
                  if (i === index) return { ...product, product: value }
                  else return product
                })
              }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function updatedFormFieldProductCount(section_id, section_item_id, index, value) {
    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return {
                ...item, counts: item.counts.map((product, i) => {
                  if (i === index) return { ...product, count: value }
                  else return product
                })
              }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function addFormFieldCount(section_id, section_item_id, product) {

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {

              return {
                ...item, counts: [...item.counts,
                {
                  product: "",
                  count: 0
                }]
              }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function updatedFormFieldItemCommentProductName(section_id, section_item_id, index, value) {
    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return {
                ...item, item_list_comment: item.item_list_comment.map((product, i) => {
                  if (i === index) return { ...product, product: value }
                  else return product
                })
              }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function updatedFormFieldItemCommentProductComment(section_id, section_item_id, index, value) {
    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return {
                ...item, item_list_comment: item.item_list_comment.map((product, i) => {
                  if (i === index) return { ...product, comment: value }
                  else return product
                })
              }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function addFormFieldItemComment(section_id, section_item_id, item) {

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {

              return {
                ...item, item_list_comment: [...item.item_list_comment,
                {
                  product: "", comment: ""
                }]
              }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function handleDateTimeUpdate(section_id, section_item_id, value) {


    const formattedDateTime = moment(value).format("YYYY-MM-DD HH:mm")

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, datetime: formattedDateTime }
            } else return item;
          })
        }
      }
      else return el;
    })))

    // setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, yesno: answer } : el)) 
    // ))
  }

  function handleTimeUpdate(section_id, section_item_id, value) {


    const formattedTime = moment(value).format("HH:mm")

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, datetime: formattedTime }
            } else return item;
          })
        }
      }
      else return el;
    })))

    // setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, yesno: answer } : el)) 
    // ))
  }

  function updatedFormFieldSelectionOption(section_id, section_item_id, value) {
    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, selectionOption: value }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function handleAnswerUpdate(section_id, section_item_id, answer) {

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, yesno: answer }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  async function compressImage(file) {
    const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true
    }

    if (!acceptedImageTypes.includes(file.type)) {
      return file
    }

    const compressedBlob = await Compress(file, options);
    if (compressedBlob) {

      const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })
      return convertedBlobFile
    }
    else return file;
  }



  async function getFileDownloadURLS(visit_id, section_id, section_item_id, files) {

    const dateToday = moment().format("DD-MM-YYYY");
    const uploadedFiles = [];
    const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];


    const result = await Promise.all(files.map(file => {
      return new Promise(async (resolve, reject) => {

        let currentFile = await compressImage(file.file);
        //console.log(currentFile)

        const storageRef = ref(storage, `${storeDetails.rootID}/${visit_id}/${section_id}/${section_item_id}/${currentFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, currentFile);

        uploadTask.on("state_changed",
          (snapshot) => {
            const progress =
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

          },
          (error) => {
            alert(error);
          },
          () => {

            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

              const fileElement = {
                description: currentFile.name,
                date: dateToday,
                size: currentFile.size,
                user: storeDetails.user.name,
                comment: file.comment,
                url: downloadURL
              }

              uploadedFiles.push(fileElement);
              resolve(fileElement);
              //setUploadedFiles(prevState => [...prevState, downloadURL])
            });
          }
        );
      })
    }))

    //console.log(uploadedFiles)
    //console.log(uploadedFiles)

    return result;

  }

  function handleVisitStatus() {

    if (storeDetails.user.admin || canEdit) {

    } else return;

    const currentStatus = visitDetails.visitStatus;

    if (currentStatus === "Open") setReportStatusOpen(false)
    else setReportStatusOpen(true)

    const newStatus = currentStatus === "Open" ? "Closed" : "Open";
    setVisitDetails({
      ...visitDetails,
      visitStatus: newStatus
    })




  }

  function handleDeleteFileDialog(section_id, section_item_id, i, file) {
    //setSelectedSection(section_id);

    setSelectedFile({
      section_id: section_id,
      section_item_id: section_item_id,
      file: file,
      index: i
    })

    setShowDeleteFileDialog(true);
  }

  function handleRemoveNewFile(section_id, section_item_id, i) {
    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return { ...item, newFiles: item.newFiles.filter((item, index) => index !== i) }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function handleAddNewFileComment(section_id, section_item_id, i, comment) {

    setSections(prevState => (prevState.map(el => {

      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return {
                ...item, newFiles: item.newFiles.map((item, index) => {

                  if (index === i) return { ...item, comment: comment }
                  else return item;
                })
              }
            } else return item;
          })
        }
      }
      else return el;
    })))
  }



  async function handleRemoveFile(section_id, section_item_id, index, file) {

    setShowDeleteFileDialog(false);
    const removeFileStatus = await removeFileCloud(section_id, section_item_id, index, file.description);
    if (!removeFileStatus) return;

    // if the file is part of newfiles then remove from newfiles
    // else if file is part of files list -> remove from files, firebase and storage

    setDeletedFiles(prevState => [...prevState,
    {
      section_id: section_id,
      section_item_id: section_item_id,
      description: file.description
    }]);

    // remove file from list
    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              const updatedFileList = item.files.filter(element => element.description !== file.description)
              return { ...item, files: updatedFileList }
            } else return item;
          })
        }
      }
      else return el;
    })))





  }

  function handleCloseReportDialog() {
    //navigate("/storevisit/")
    setShowReportDialog(false)
  }

  async function getIDToken() {
    const token = await auth.currentUser.getIdToken(true)
    return token;
  }

  async function handleDeleteReport() {

    setShowDeleteReportDialog(false)
    setShowLoading(true);

    // moved the delete files function to the back end
    // await Promise.all(sections.map(async section => {
    //   await Promise.all(section.list.map(async item => {
    //     if(item.files.length > 0) {

    //       await Promise.all(item.files.map(async file => {
    //         return new Promise((resolve) => {
    //           const storageRef = ref(storage, `${storeDetails.rootID}/${visitID}/${section.section_id}/${item.section_item_id}/${file.description}`);

    //           resolve(deleteObject(storageRef))
    //         })

    //       }))
    //     }
    //   }))
    // }))

    const idToken = await getIDToken();

    const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-report', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idToken: idToken,
        storeid: storeDetails.rootID,
        visitid: visitDetails.visitID,
      })
    })

    if (result.status === 200) {
      logEvent("Visit", `Deleted - ${visitDetails.name} ${visitDetails.date}`, storeDetails.user.name);
      setBarMessage({
        type: "info",
        message: "Visit report has been successfully deleted.",
        result: "success"
      })
      setOpenBar(true)

    }
    else {
      setShowLoading(false)
      setBarMessage({
        type: "error",
        message: "There was an error trying to delete this visit report."
      })
      setOpenBar(false)
      // setErrorContent({
      //   title: "Error",
      //   content: "There was an error trying to delete this store visit.",
      // })

      // setShowErrorDialog(true);

      return;
    }




  }

  //   await deleteDoc(doc(db, "stores/" + storeDetails.rootID + "/visits/" + visitID)).then(() => {
  //     navigate("/storevisit");
  //   })
  //   //
  // }

  async function handleSaveStoreVisit() {

    if (fileSizeError) {
      setErrorContent({
        title: "Large Files",
        content: "You have files that are too large to be uploaded to this store visit.",
        extra_content: "Maximum file size: 8mb."
      })
      setShowErrorDialog(true);
      return;
    }


    setShowLoading(true)

    await saveStoreVisit().then(() => {
      setShowLoading(false);

      setBarMessage({
        type: "info",
        message: "Visit has been successfully updated.",
        result: "success"
      })
      setOpenBar(true)
    }).catch(err => {

      console.log(err)


      setBarMessage({
        type: "error",
        message: "There was an error updating this visit report."
      })
      setOpenBar(true)
      // setErrorContent({
      //   title: "Error",
      //   content: "There was an error trying to update this store visit.",
      // })

      // setShowErrorDialog(true);
    })

  }

  async function getTotalActions() {
    const actionsTotal = sections.map(section => {
      return section.list.reduce((total, currentItem) => (currentItem.actions && currentItem.actions.length > 0) ? total + currentItem.actions.length : total + 0, 0)
    }).reduce((total, item) => total + item)

    return actionsTotal;
  }

  async function getOutstandingActions() {
    let actionsIncomplete = 0;

    for (const section of sections) {
      for (const item of section.list) {
        for (const action of item.actions) {
          if (!action.actionComplete) {
            actionsIncomplete++;
          }
        }
      }
    }

    return actionsIncomplete;
  }

  function handleActionBy(section_id, section_item_id, user) {

    // const userValue = user.target.value;
    // let userDoc;
    // if(userValue !== "0")
    //   userDoc = users.filter(user => user.uid === user.target.value)[0];

    setSections(prevState => (prevState.map(el => {
      if (el.section_id === section_id) {
        return {
          ...el,
          list: el.list.map(item => {
            if (item.section_item_id === section_item_id) {
              return {
                ...item,
                actionBy: user.target.value === "0" ? 0 : user.target.value,

              }

            } else return item;
          })
        }
      }
      else return el;
    })))
  }

  function getLastVisitSectionScore(section_id) {

    const section = lastVisit.data.sections.filter(lastVisitSection => lastVisitSection.section_id === section_id)[0];
    if (section.scorable) return `${section.score}%`
    else return "Not Scorable"
  }

  const handleUpdateSection = useCallback((sectionProp) => {
    const sectionIndex = sections.findIndex(section => section.section_id === sectionProp.section_id);
    setSections(prevSections => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex] = sectionProp;
      return updatedSections;
    });
  }, [sections]);

  async function getIDToken() {
    const token = await auth.currentUser.getIdToken(true)
    return token;
  }

  function getSelectedItemIDAnswer() {
    if (!visitDetails?.selected_id) return "";

    for (let x = 0; x < sections.length; x++) {
      const sectionItems = sections[x].list;
      for (let y = 0; y < sectionItems.length; y++) {
        const item = sectionItems[y];
        if (item.section_item_id === visitDetails.selected_id) return item.fieldText;
      }
    }
  }


  async function saveStoreVisit() {


    const totalActions = await getTotalActions();
    const outstandingActions = await getOutstandingActions();
    const idToken = await getIDToken();
    const dateToday = moment().format("DD-MM-YYYY");
    const closeNow = checkFieldsCloseOnFill();
    const selectedItemIDAnswer = getSelectedItemIDAnswer();
    //console.log("Stat", visitDetails.visitStatus)


    // delete all files from storage that have been removed
    // files should only be removed at this point because if it happens
    // before the user saves it could lead to errors if update store visit not done
    // deletedFiles.forEach(async file => {
    //   const storageRef = ref(storage, `${storeDetails.rootID}/${visitID}/${file.section_id}/${file.section_item_id}/${file.description}`);
    //   await deleteObject(storageRef)
    // });

    await updateDoc(doc(db, "stores/" + storeDetails.rootID + "/visits/" + visitDetails.visitID), {
      actions_total: totalActions,
      actions_outstanding: outstandingActions,
      visit_status: closeNow ? "Closed" : "Open",
      selected_id_answer: selectedItemIDAnswer,
      visit_closed_date: closeNow ? moment().format("DD-MM-YYYY HH:mm") : ""
    }, { merge: true })

    const updatedSections = await Promise.all(sections.map(async section => {
      return {
        ...section,
        list: await Promise.all(section.list.map(async element => {
          if (element.newFiles.length > 0) {
            //setLoaderMessage("Uploading files")
            const updatedFileList = [...element.files];

            for (const fileElement of element.newFiles) {

              const formData = new FormData();
              formData.append("idToken", idToken);
              formData.append('file', fileElement.file);
              formData.append("file_name", fileElement.file.name)
              formData.append('store_id', storeDetails.rootID);
              formData.append('visit_id', visitDetails.visitID);
              formData.append('section_id', section.section_id);
              formData.append('section_item_id', element.section_item_id);

              try {
                const response = await axios.post('https://us-central1-storecall.cloudfunctions.net/api/upload-file', formData);
                const result = response.data;
                const { url, size } = result;

                // visitFiles.push({
                //   file_description: fileElement.file.name,
                //   url: url,
                // });

                const newFileElement = {
                  description: fileElement.file.name,
                  date: dateToday,
                  size: size,
                  user: storeDetails.user.name,
                  comment: fileElement.comment,
                  url: url,
                };

                //console.log('Uploaded', newFileElement)

                updatedFileList.push(newFileElement);
              } catch (error) {
                console.error(error);
              }

            }

            return {
              ...element,
              files: updatedFileList,
              newFiles: []
            }

            //   //await getFileDownloadURLS(visitRef.id, section.section_id, element.section_item_id, element.files);
            //   const updatedFileList = [...element.files];
            //   const newFileList = await getFileDownloadURLS(visitDetails.visitID, section.section_id, element.section_item_id, element.newFiles);
            //   newFileList.forEach(newFile => {
            //     updatedFileList.push(newFile);
            //   })

            //   return {...element, 
            //     files: updatedFileList,
            //     newFiles: []
            //  }

          }
          else return element;
        }))
      }
    }))

    logEvent("Visit", `Updated - ${visitDetails.name} ${visitDetails.date}`, storeDetails.user.name);

    await Promise.all(updatedSections.map(section => {
      return new Promise((resolve) => {
        const sectionRef = doc(db, "stores/" + storeDetails.rootID + "/visits/" + visitDetails.visitID + "/sections", section.section_id);
        //console.log(section)
        resolve(updateDoc(sectionRef, section));
      })
    }))


  }

  return (
    <PageHolder>

      <Header>
        <HeaderHolder>

          {visitDetails.templateName} - {visitDetails.visitType}
          {visitDetails.templateDescription && visitDetails.templateDescription.length > 0 && <TemplateDescription>{visitDetails.templateDescription}</TemplateDescription>}
        </HeaderHolder>

      </Header>

      <DashboardContainer>
          <DashboardCard background={Colors.button_blue_gradient}>
            
            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem>Location</DashboardItem>
            </DivHolder>
            
            <ColumnContainer>
            <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{visitDetails.storeName}</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>{visitDetails.regionName}</DashboardItem>
            </ColumnContainer>
            
          </DashboardCard>
          
          <DashboardCard background={Colors.button_blue_gradient}>
            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem>Date</DashboardItem>
            </DivHolder>

            <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{moment(visitDetails.visitDate, "DD-MM-YYYY HH:mm").format("DD MMMM YYYY")}</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>{moment(visitDetails.visitDate, "DD-MM-YYYY HH:mm").format("HH:mm")}</DashboardItem>
          </DashboardCard>

          {visitDetails?.visitScorable &&
          <DashboardCard background={Colors.button_blue_gradient}>
            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem>Score</DashboardItem>
              
            </DivHolder>

            <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{visitDetails.visitScore}%</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>Total</DashboardItem>
          </DashboardCard>}

          <DashboardCard background={Colors.button_blue_gradient}>
            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <DashboardItem>Actions</DashboardItem>
            </DivHolder>

            <DashboardItem style={{fontWeight: 700, fontSize: '0.8rem'}}>{visitDetails.outstandingActions}</DashboardItem>
            <DashboardItem style={{fontSize: '0.7rem'}}>Outstanding</DashboardItem>
          </DashboardCard>
      </DashboardContainer>


      {/* <VisitHeaderContainer>
        <VisitHeaderItemContainer>
          <VisitHeaderItem>Location</VisitHeaderItem>
          <VisitHeaderItem title="true">{visitDetails.storeName} ({visitDetails.regionName}) <StoreMallDirectoryIcon style={{ 'marginLeft': '10px' }} fontSize='small' /></VisitHeaderItem>

        </VisitHeaderItemContainer>
        <Divider />
        <VisitHeaderItemContainer>
          <VisitHeaderItem>Manager at visit</VisitHeaderItem>
          <VisitHeaderItem title="true">{visitDetails.manager} <PersonIcon style={{ 'marginLeft': '10px' }} fontSize='small' /></VisitHeaderItem>
        </VisitHeaderItemContainer>
        <Divider />
        <VisitHeaderItemContainer>
          <VisitHeaderItem>{visitDetails.visitType} Date</VisitHeaderItem>
          <VisitHeaderItem title="true">{visitDetails.visitDate} <CalendarTodayIcon style={{ 'marginLeft': '10px' }} fontSize='small' /></VisitHeaderItem>
        </VisitHeaderItemContainer>
        <Divider />
        <VisitHeaderItemContainer>
          <VisitHeaderItem>{visitDetails.visitType} Ended</VisitHeaderItem>
          <VisitHeaderItem title="true">{visitDetails.visitEndDate} <CalendarTodayIcon style={{ 'marginLeft': '10px' }} fontSize='small' /></VisitHeaderItem>
        </VisitHeaderItemContainer>
        <Divider />
        <VisitHeaderItemContainer>

          <VisitHeaderItem>Time to complete</VisitHeaderItem>
          <VisitHeaderItem title="true">{visitDetails.reportTiming} <AccessTimeIcon style={{ 'marginLeft': '10px' }} fontSize='small' /></VisitHeaderItem>
        </VisitHeaderItemContainer>

        <Divider />
        <VisitHeaderItemContainer>
          <VisitHeaderItem>{visitDetails.visitType} Closed</VisitHeaderItem>
          <VisitHeaderItem title="true">{visitDetails.visitClosedDate === "" ? "Not yet" : visitDetails.visitClosedDate}<CalendarTodayIcon style={{ 'marginLeft': '10px' }} fontSize='small' /></VisitHeaderItem>
        </VisitHeaderItemContainer>
        <Divider />
        <VisitHeaderItemContainer>
          <VisitHeaderItem>Score</VisitHeaderItem>
          <VisitHeaderItem title="true">{`${visitDetails.visitScorable ? `${visitDetails.visitScore}%` : "Not scorable"}`} <CreditScoreIcon fontSize='small' style={{ 'marginLeft': '10px' }} /></VisitHeaderItem>
        </VisitHeaderItemContainer>
        <Divider />
        <VisitHeaderItemContainer>
          <VisitHeaderItem>Outstanding actions</VisitHeaderItem>
          {visitDetails.outstandingActions > 0 &&
            <VisitHeaderItem title="true">{visitDetails.outstandingActions} of {visitDetails.totalActions} <AssignmentIcon fontSize='small' style={{ 'marginLeft': '10px' }} /></VisitHeaderItem>
          }
          {(visitDetails.outstandingActions == 0 || !visitDetails.totalActions) &&
            <VisitHeaderItem title="true">None <AssignmentIcon fontSize='small' style={{ 'marginLeft': '10px' }} /></VisitHeaderItem>
          }
        </VisitHeaderItemContainer>
        <Divider />
        <VisitHeaderItemContainer>
          <VisitHeaderItem>Visit by</VisitHeaderItem>
          <VisitHeaderItem title="true">{visitDetails.visitBy.name} <AccountCircleIcon style={{ 'marginLeft': '10px' }} fontSize='small' /></VisitHeaderItem>
        </VisitHeaderItemContainer>
        <Divider />

        <VisitHeaderItemContainer>
          <VisitHeaderItem>Previous visit</VisitHeaderItem>
          {lastVisit ?
            <VisitHeaderItem title="true">
              <StyledNavLink reloadDocument to={`/app/storevisit/report/${lastVisit.id}`}>
                {lastVisit.data.date} ({lastVisit.data.score}%) <LinkIcon fontSize='small' style={{ 'marginLeft': '10px' }} />
              </StyledNavLink>
            </VisitHeaderItem>
            :
            <VisitHeaderItem title="true">None <LinkIcon fontSize='small' style={{ 'marginLeft': '10px' }} /></VisitHeaderItem>
          }
        </VisitHeaderItemContainer>
        <Divider />

        <VisitHeaderItemContainer>
          <VisitHeaderItem>Download Report</VisitHeaderItem>
          <VisitHeaderItem>
            <VisitToolbar>

              <ToolbarItem>
                <Tooltip title="Download report" arrow>
                  <IconButton onClick={() => setShowReportDialog(true)}><StyledDownloadIcon size='small' /></IconButton>
                </Tooltip>

              </ToolbarItem>

            </VisitToolbar>
          </VisitHeaderItem>

        </VisitHeaderItemContainer>

      </VisitHeaderContainer> */}

      

      <PageContainer empty_shadow={true} style={{ 'padding': '0px' }}>

        <ErrorDialog
          open={showErrorDialog} setOpen={setShowErrorDialog}
          title={errorContent.title}
          content={errorContent.content}
          extra_content={errorContent.extra_content}
        />

        <DeleteFileDialog
          open={showDeleteFileDialog}
          setOpen={setShowDeleteFileDialog}
          title="Delete File"
          content="Are you sure you want to delete this file?"
          selectedFile={selectedFile}
          action={handleRemoveFile} />

        <DeleteReportDialog
          open={showDeleteReportDialog}
          setOpen={setShowDeleteReportDialog}
          title="Delete Report"
          content="Are you sure you want to delete this report?"
          action={handleDeleteReport} />

        {showReportDialog &&
          <StoreVisitCompleteDialog
            title={`${visitDetails.templateName} Report`}
            content={`Download ${visitDetails.visitType.toLowerCase()} for ${visitDetails.storeName}`}
            open={showReportDialog}
            newReport={false}
            storeID={storeDetails.rootID}
            visitID={visitDetails.visitID}
            actionsOutstanding={visitDetails.outstandingActions}
            template={visitDetails.templateName}
            store={visitDetails.storeName}
            visitType={visitDetails.visitType}
            visitDate={visitDetails.visitDate}
            visitEndDate={visitDetails.visitEndDate}

            visitStatus={visitDetails.visitStatus}
            visitClosedDate={visitDetails.visitClosedDate}
            manager={visitDetails.manager}
            region={visitDetails.regionName}
            user={visitDetails.visitBy.name}
            scorable={visitDetails.visitScorable}
            duration={visitDetails.reportTiming}
            score={visitDetails.visitScore}
            sections={sections}
            signature_required={visitDetails.signature_link && visitDetails.signature_link.length > 0}
            signature_link={visitDetails.signature_link}
            action={handleCloseReportDialog} />}

        <Loader show={showLoading} />
        <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
          <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
            {barMessage.message}
          </Alert>
        </Snackbar>




        {visitDetails.location_required && <LocationMap center={location} />}

        <TemplateFormFieldRow align="right" style={{'marginTop': '15px'}}>
            <Text style={{alignSelf: 'center', flex: "none", marginRight: '10px'}} fontSize={"0.8rem"} fontWeight={"500"} color={`${Colors.dark_grey_highlight}`}>{`${visitDetails.visitType} status is ${visitDetails.visitStatus === "Open" ? "open" : "closed"}`}</Text>
            <Button style={{'flex': "none", width: "fit-content"}} padding="5px 15px" color={`${visitDetails.visitStatus === "Open" ? `${Colors.highlight}` : `${Colors.dark_grey}`}`} onClick={handleVisitStatus}>{visitDetails.visitStatus === "Open" ? `Close ${visitDetails.visitType}` : `Open ${visitDetails.visitType}`}</Button>
      </TemplateFormFieldRow>


        <TemplateFormFieldContainer>

          {sections && sections.map((section, i) => {
            return (
              <SectionReport key={i}
                sectionProp={section}
                lastVisit={lastVisit}
                reportStatusOpen={reportStatusOpen}
                users={users}
                canEdit={canEdit}
                visitBy={visitDetails.visitBy}
                currentUser={storeDetails.user}
                setDeletedFiles={setDeletedFiles}
                removeFileCloud={removeFileCloud}
                setFileSizeError={setFileSizeError}
                updateSection={handleUpdateSection} />

            )
          })}

          {visitDetails && visitDetails.signature_link &&
            <SignatureImageContainer>
              <TemplateFormFieldSignatureLabel>Visit Signature</TemplateFormFieldSignatureLabel>
              <SignatureImage src={visitDetails.signature_link} />
            </SignatureImageContainer>}

        </TemplateFormFieldContainer>

      </PageContainer>
      <TemplateFormButtonStack align="right" style={{ 'marginTop': '10px' }}>
        {(storeDetails.permissions.visits.delete) && <Button highlighted onClick={() => setShowDeleteReportDialog(true)}><StyledDeleteIcon />Delete {visitDetails.visitType}</Button>}


        {(reportStatusOpen || (storeDetails.permissions.visits.edit || storeDetails.permissions.visits.add_comments)) && <Button variant="contained" onClick={handleSaveStoreVisit}><StyledUpdateIcon />Update {visitDetails.visitType}</Button>}

      </TemplateFormButtonStack>
    </PageHolder>

  )
}

export default StorevisitReport