import React, {useContext, useEffect, useState} from 'react'
import { Button, HeaderHolder, HeaderTitle, PageContainer, PageHolder } from './styled/styled.container'
import { db } from "./firebase";
import {collection, onSnapshot, query, doc, where, getDoc, getDocs, orderBy} from "firebase/firestore";

import ReportButtonSelection from './ReportButtonSelection';
import ReportsVisitScores from './ReportsVisitScores';
import ReportsSectionScores from './ReportsSectionScores';
import ReportsSectionPerformance from './ReportsSectionPerformance';
import ReportsNoVisits from './ReportsNoVisits';
import ReportsOutstandingActions from './ReportsOutstandingActions';
import ReportQuantityAnalysis from './ReportQuantityAnalysis';
import ReportFieldAnalysis from './ReportFieldAnalysis';
import ReportsLocationAnalysis from './ReportsLocationAnalysis';
import ReportsFrequencyAnalysis from './ReportsFrequencyAnalysis';
import Loader from './Loader';
import AuthContext from './AuthContext';
import Header from './Header';
import { TemplateDescription, TemplateFormButtonStack } from './styled/styled.templateform';
import EmptyScreen from './EmptyScreen';
import ReportImg from "./imgs/report_img.png"
import { useNavigate } from 'react-router-dom';

function ReportsHome(props) {
    const {storeDetails} = useContext(AuthContext)
    const [selectedReport, setSelectedReport] = useState(0);
    const [stores, setStores] = useState(storeDetails.stores);
    const [templates, setTemplates] = useState(storeDetails.user_specific_templates);
    const [showLoading, setShowLoading] = useState(false)
    const [visitsEmpty, setVisitsEmpty] = useState()
    const navigate = useNavigate();

    useEffect(() => {

      async function getDetails() {
        const visitRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits"));
        const visitDocs = await getDocs(visitRef)
        
        visitDocs.docs.length > 0 ? setVisitsEmpty(false) : setVisitsEmpty(true)
      }

      getDetails()
      
    }, [])

  return (
    <PageHolder>
        
        <Header>
        <HeaderHolder>
        Reports
        <TemplateDescription>Select and run a report to assess the performance of your locations</TemplateDescription>
        </HeaderHolder>
        </Header>
        
        {visitsEmpty ? <>
        <EmptyScreen 
        visible
        imgSrc={ReportImg}
        title={`Hi, ${storeDetails.rootID.toUpperCase()}`} 
        contents={"Before you run a report and assess your location performance, you need to run a visit."}
        />
        <TemplateFormButtonStack>
          
          <Button onClick={() => navigate("/app/storevisit")}>Start Visit</Button>
        </TemplateFormButtonStack>
        </> 

        :

        <PageContainer empty_shadow={true} style={{'padding': '0px', 'marginBottom': '0px'}}>
        <Loader show={showLoading} />
        
        <ReportButtonSelection selectedReport={selectedReport} setSelectedReport={setSelectedReport} />
        {selectedReport==0 && <ReportsVisitScores storeDetails={storeDetails} stores={stores} templates={templates} />}
        {selectedReport==1 && <ReportsSectionScores storeDetails={storeDetails} stores={stores} templates={templates} />}
        {selectedReport==2 && <ReportsSectionPerformance storeDetails={storeDetails} stores={stores} templates={templates} />}
        {selectedReport==3 && <ReportQuantityAnalysis storeDetails={storeDetails} stores={stores} templates={templates} />}
        {selectedReport==4 && <ReportsNoVisits storeDetails={storeDetails} stores={stores} templates={templates} />} 
        {selectedReport==5 && <ReportsOutstandingActions storeDetails={storeDetails} stores={stores} templates={templates} />} 
        {selectedReport==6 && <ReportFieldAnalysis storeDetails={storeDetails} stores={stores} templates={templates} />} 
        {selectedReport==7 && <ReportsLocationAnalysis storeDetails={storeDetails} stores={stores} templates={templates} />}
        {selectedReport==8 && <ReportsFrequencyAnalysis storeDetails={storeDetails} stores={stores} templates={templates} />} 
        </PageContainer>}
        
    </PageHolder>
    
  )
}

export default ReportsHome