import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { StyledForm, StyledFormWrapper, StyledSubmitContainer } from '../styled/styled.demoform';
import { ButtonTrial } from '../styled/styled.login';
import { Colors } from '../styled/styled.container';
import { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Snackbar, useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '90%',
            '& input': {
                fontFamily: 'Poppins, sans-serif',
            },
            '& label': {
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500,
            },
            '& .MuiFormHelperText-root': {
                color: `${Colors.highlight}`,
                fontFamily: 'Poppins, sans-serif',
            }
        },
    },
}));

function DemoForm() {
    const classes = useStyles();
    const [signupError, setSignupError] = React.useState(false);
    const captureref = React.useRef(null);
    const [barMessage, setBarMessage] = useState("")
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [showSubmitButton, setShowSubmitButton] = useState(false)
    const [demoRequestSent, setDemoRequestSent] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:700px)');

    const [formDetails, setFormDetails] = useState({
        name: "",
        company: "",
        about: "",
        users: "",
        contact: "",
        email: "",

    })

    function changeHandler(field, e) {
        setFormDetails({
            ...formDetails,
            [field]: e.target.value
        })
    }

    function onChange(value) {
        try {
            if(value) {
                setShowSubmitButton(true)
            }
            else {
                setShowSubmitButton(false)
            }
            
        }catch(err) {
            console.log(err)
        }
        
    }

    async function handleSubmit(e) {
        e.preventDefault()
        
        captureref.current.execute()
        
    }

    async function sendEmail() {

        if (formDetails.name.length === 0 || formDetails.company.length === 0 || formDetails.about.length === 0 || formDetails.users.length === 0 || formDetails.contact.length === 0 || formDetails.email.length === 0) {
            setSignupError(true)
            return;
        }
        
        const validEmail = formDetails.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!validEmail) {
            setBarMessage("Please enter a valid email address.")
            setShowSnackbar(true)
            return;
        }

        const validContact = formDetails.contact.match(/^[0-9]+$/);
        if (!validContact) {
            setBarMessage("Please enter a valid contact number.")
            setShowSnackbar(true)
            return;
        }

        setSignupError(false)

        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/signup-request', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                name: formDetails.name,
                company: formDetails.company,
                about: formDetails.about,
                users: formDetails.users,
                contact: formDetails.contact,
                email: formDetails.email,
            })
        })

        if(result.status !== 200) {
            setBarMessage("Something went wrong. Please try again later.")
            setShowSnackbar(true)
            return;
        } else {
            setDemoRequestSent(true)
            setBarMessage("Your request has been sent. We will get back to you soon.")
            setShowSnackbar(true)
        }
        
        
    }

    const asyncScriptOnLoad = () => {

        //console.log("scriptLoad - reCaptcha Ref-", captureref.current);
      };

    return (
        <>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={showSnackbar}
            onClose={() => setShowSnackbar(false)}
            message={barMessage}
            key={'bottomright'}
            autoHideDuration={2500}
        />

        <StyledForm className={classes.root} noValidate autoComplete="off">
            <StyledFormWrapper onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    required
                    helperText={signupError ? "Please specify your name." : ""}
                    id="name"
                    onChange={(e) => changeHandler("name", e)}
                    label="Name"
                    inputProps={{ maxLength: 30 }}
                />
                <TextField
                    required
                    id="company-name"
                    label="Company Name"
                    onChange={(e) => changeHandler("company", e)}
                    helperText={signupError ? "Please specify your organisation name." : ""}
                    inputProps={{ maxLength: 30 }}
                />
                <TextField
                    required
                    multiline
                    id="about-organisation"
                    onChange={(e) => changeHandler("about", e)}
                    label="About Organisation"
                    helperText={signupError ? "Briefly describe your organisation." : ""}
                    inputProps={{ maxLength: 150 }}
                />
                <TextField
                    select
                    required
                    id="number-of-users"
                    label="Number of Users"
                    defaultValue={""}
                    onChange={(e) => changeHandler("users", e)}
                    helperText={signupError ? "Please select the number of users" : ""}
                >
                    <MenuItem value="1-6">1-6</MenuItem>
                    <MenuItem value="7-12">7-12</MenuItem>
                    <MenuItem value="13+">13+</MenuItem>
                </TextField>
                <TextField
                    required
                    id="contact-number"
                    label="Contact Number"
                    onChange={(e) => changeHandler("contact", e)}
                    helperText={signupError ? "Please specify your contact number." : ""}
                    inputProps={{ maxLength: 20 }}
                />
                <TextField
                    required
                    id="email-address"
                    label="Email Address"
                    onChange={(e) => changeHandler("email", e)}
                    helperText={signupError ? "Please specify your email." : ""}
                    inputProps={{ maxLength: 30 }}
                />

                <StyledSubmitContainer>
                    <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        sitekey="6LfyRscoAAAAAM-2r9AlzwpWvC0JAVIp3WG0wfCW"
                        onChange={onChange}
                        size={isSmallScreen ? "compact" : "normal"}
                        ref={captureref}
                        asyncScriptOnLoad={asyncScriptOnLoad}
                        
                    />,
                    

                </StyledSubmitContainer>


            </StyledFormWrapper>


        </StyledForm>
        {showSubmitButton ? <ButtonTrial disabled={demoRequestSent} onClick={() => sendEmail()}>Request free trial</ButtonTrial> : null}
                    
        </>
        
    );
}

export default DemoForm