import React from 'react'

import { HeaderTitle, PageContainer, PageHolder } from './styled/styled.container'
import { DashboardButton, DashboardEmptyContainer, DashboardEmptyContainerText, DashboardEmptyContainerTitle } from './styled/styled.dashboard'
import { useNavigate } from 'react-router-dom'



function ErrorVisit() {

    const navigate = useNavigate();

    function returnHome() {
        navigate("/app")
    }
    
  return (
    <PageHolder>
      <PageContainer>
        <HeaderTitle>Ooops! Visit does not exist</HeaderTitle>
        <DashboardEmptyContainer>
            <DashboardEmptyContainerTitle>
            There seems to be an issue
            </DashboardEmptyContainerTitle>
            <DashboardEmptyContainerText>It seems that this visit no longer exists.</DashboardEmptyContainerText>
            <DashboardEmptyContainerText style={{'marginTop': '5px', 'fontWeight': '600'}}>Please contact your administrator for more information.</DashboardEmptyContainerText>
            <DashboardButton onClick={returnHome}>Return Home</DashboardButton>
        </DashboardEmptyContainer>
    </PageContainer>
    </PageHolder>
    
    
  )
}

export default ErrorVisit