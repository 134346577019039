import { Radio, Typography } from '@mui/material'
import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { RadioSelectRow } from './styled/styled.container'

function RadioSelect(props) {
    const [age, setAge] = React.useState('');
    const {actionUsers, selectedActionUser, currentSelection, setSelectedActionUser, handleFilterActionReports} = props;

  const handleChange = (event) => {
    
    //setFilterRadioOption();
    if(currentSelection === "outstandingActionsUser") 
    {
      const selectedUser = event.target.value;
      const user = actionUsers.filter(user => user.uid === selectedUser);
      handleFilterActionReports(user[0].uid, true, false);
      setSelectedActionUser(user[0].uid)
    }
    
  };

  return (
    <RadioSelectRow>
        <Select
          size="small"
          fullWidth
          sx={{minWidth: 200, flex: 1}}
          onChange={handleChange}
          value={selectedActionUser}
          
        >
          {actionUsers.map((user, i) => {
            return(
                <MenuItem key={i} value={user.uid}>{user.name}</MenuItem>
            )
          })}
        </Select>
    </RadioSelectRow>
    
  )
}

export default RadioSelect