import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { HeaderSubTitle, HeaderTitle, Button, PageHolder, StyledStoreIcon, StyledDeleteIcon, StyledUpdateIcon, StyledClearIcon, StyledAddIcon, StyledEditIcon, Text, StyledTextField } from './styled/styled.container'
import { StoreFormFieldContainer, StoreFormField, StoreFormFieldText, StoreFormFieldRow, StoreFormTitle, StoreFormEmptyContainer, StoreFormEmptyContainerText, StoreFormErrorContainer, StoreFormErrorContainerText, StoreFormFieldNumber, StoreFormFieldButton } from './styled/styled.stores';
import { StyledClosedIcon, PageContainer, EmptyContainer, EmptyContainerText } from './styled/styled.container';
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, setDoc, deleteDoc, addDoc, where, getDoc, updateDoc, getDocs, orderBy } from "firebase/firestore";
import { TemplateFormFieldContainer, TemplateFormFieldRow, TemplateSection } from './styled/styled.templateform';
import { TextField, InputAdornment, MenuItem, IconButton } from '@mui/material';
import Header from './Header';
import { auth } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";
import Loader from './Loader';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AuthContext from './AuthContext';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StoresForm(props) {

    const [storeFields, setStoreFields] = useState([]);
    const [storeID, setStoreID] = useState("");
    const [storeName, setStoreName] = useState("");
    const [regions, setRegions] = useState([]);
    const [subRegions, setSubRegions] = useState([])
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedSubRegion, setSelectedSubRegion] = useState("")
    const [userManagers, setUserManagers] = useState([]);
    const [selectedManager, setSelectedManager] = useState("");
    const [storeEmail, setStoreEmail] = useState("");
    const [storeHeader, setstoreHeader] = useState("Create Location");
    const [showLoading, setShowLoading] = useState(false);
    const [storeSubTitle, setstoreSubtitle] = useState("Choose the form fields that you would like to create")
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const { storeDetails } = useContext(AuthContext)
    const [user, loading] = useAuthState(auth);
    const [openBar, setOpenBar] = React.useState(false);
    const [barMessage, setBarMessage] = useState({})
    const [showFieldError, setShowFieldError] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    let storeRootRef = "";

    useEffect(() => {
        setStoreFields([])
        setUserManagers([]);
        const store_id = params.id;

        
        if (store_id) {
            
            if (!storeDetails.permissions.stores.edit) navigate(-1);

            setstoreHeader("Edit location details")
            setstoreSubtitle("Choose the form fields that you would like to modify")
            setStoreID(store_id)
        } else {
            if (!storeDetails.permissions.stores.add) navigate(-1);
        }

        async function getStoreDetails() {

            const selectedStore = storeDetails.stores.filter(store => store.cloud_id === store_id)[0];
            if (!selectedStore) return;

            setStoreName(selectedStore.name);
            setSelectedManager(selectedStore.manager);
            setSelectedRegion(selectedStore.region);
            setSelectedSubRegion(selectedStore.sub_region);
            setStoreEmail(selectedStore.store_email);

            // storeRootRef = doc(db, "stores/" + storeDetails.rootID + "/stores/" + store_id);

            // getDoc(storeRootRef).then(async result => {
            //     if(result.exists()) {
            //         const data = result.data();

            //         setStoreName(data.name);

            //         const storeEmailValue = data.store_email;
            //         const storeManagerUID = data.manager;

            //         if(storeManagerUID) {

            //             const userRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("uid", "==", storeManagerUID));

            //             const userDoc = await getDocs(userRef);
            //             const userData = userDoc.docs[0].data();
            //             setSelectedManager(userData.name);
            //         }

            //         if(storeEmailValue)
            //             setStoreEmail(storeEmailValue)

            //         const storeRegion = regions.filter(region => region.cloud_id === data.region_id)[0];

            //         if(storeRegion) setSelectedRegion(prevState => storeRegion.name);

            //         const storeSubRegion = subRegions.filter(sub_region => sub_region.cloud_id === data.sub_region_id)[0];
            //         if(storeSubRegion) setSelectedSubRegion(prevState => storeSubRegion.name);
            //     }
            // })
        }

        async function getUserManagers() {
            const userRootRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("active", "==", true));
            const userDocs = await getDocs(userRootRef);
            if (userDocs.docs.length > 0) {
                userDocs.docs.forEach(user => {

                    setUserManagers(prevState => [...prevState, user.data()])
                })
            }
            else
                setUserManagers([])
        }



        async function getDetails() {

            //if(!storeDetails.rootID || storeDetails.regions.length === 0) return;


            if (!storeDetails.permissions.stores.view) navigate("/404");
            else {

                setShowLoading(true);
                try {
                    //console.log("Sub regions", storeDetails)
                    //setRegions(storeDetails.regions)
                    //setSubRegions(storeDetails.sub_regions)
                    await getStoreDetails();

                    await getUserManagers();
                } finally {
                    setShowLoading(false);
                }
            }
        }

        // console.log(storeDetails)

        // if (storeDetails.regions.length > 0 && storeDetails.sub_regions.length > 0) {
            
            
        //     //getRegions();
        //     //getSubRegions();
        // }

        setRegions(storeDetails.regions)
        setSubRegions(storeDetails.sub_regions)
        getDetails();


    }, [storeDetails.stores, storeDetails.regions, storeDetails.sub_regions])

    const getRegions = () => {

        const regionRef = query(collection(db, "stores/" + storeDetails.rootID + "/regions"), orderBy("name"));

        const unsub = onSnapshot(regionRef, async (snapshot) => {
            setRegions([]);
            const promises = snapshot.docs.map(async (item) => {
                const regionID = item.id;
                const regionData = item.data();

                const regionItem = {
                    cloud_id: regionID,
                    name: regionData.name,
                };

                return regionItem;
            });

            const resolvedItems = await Promise.all(promises);

            console.log("Regions", resolvedItems)

            setRegions(resolvedItems);
        });

        return () => unsub();
    }

    const getSubRegions = () => {
        const subRegionRef = query(collection(db, "stores/" + storeDetails.rootID + "/sub_regions"), orderBy("name"));

        const unsub = onSnapshot(subRegionRef, async (snapshot) => {
            setSubRegions([]);
            const list = await Promise.all(snapshot.docs.map(item => {
                const regionID = item.id;
                const regionData = item.data();
                const regionItem = {
                    cloud_id: regionID,
                    name: regionData.name,
                };
                return regionItem;
            }));
            console.log("Sub regions", list)
            setSubRegions(list);
        });

        return () => unsub();
    }


    const handleClick = () => {
        setOpenBar(true);
    };

    const handleClose = (event, reason) => {

        setOpenBar(false);
        setShowLoading(false)
        if (barMessage.result === "success") navigate("/app/locations")
    };

    function updateFormFieldName(id, text) {
        setStoreFields(prevState => (
            prevState.map(el => (el.id === id ? { ...el, name: text } : el))
        ))
    }

    async function deleteStore() {

        setShowLoading(true);
        const idToken = await getIDToken();

        const response = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-store', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken, // uid of current user
                storeid: storeDetails.rootID, // uid of user being queried (if admin querying other user)
                storekey: params.id
            })
        });

        if (response.status === 200) {
            setBarMessage({
                type: "info",
                message: "Store has been successfully deleted.",
                result: "success"
            })
            setOpenBar(true)
        }
        else {
            setShowLoading(false)
            setBarMessage({
                type: "error",
                message: "There was an error trying to delete this store."
            })
            setOpenBar(true)
            return;
        }

        // const storeDocRef = doc(db, "stores/" + storeDetails.rootID + "/stores/" + storeID);
        // deleteDoc(storeDocRef).then(result => {
        //     console.log("Store deleted successfully")
        //     setStoreFields([]);
        //     setStoreID("");
        // })

        // navigate("/locations")
    }

    function checkStoreFieldsFilled() {
        let bCheck = true;

        if (storeName.length === 0) bCheck = false;
        //if(selectedManager.length === 0) return false;
        if (selectedRegion.length === 0) bCheck = false;

        if (!bCheck) setShowFieldError(true)
        return bCheck;
    }

    async function getIDToken() {
        const token = await auth.currentUser.getIdToken(true)
        return token;
    }

    async function createStore() {


        if (!checkStoreFieldsFilled()) {
            return;
        }

        setShowLoading(true);

        const managerid = userManagers.find(user => user.name === selectedManager)?.uid || "";

        const regionID = regions.filter(region => region.name === selectedRegion)[0].cloud_id;
        const subRegionID = subRegions.filter(sub_region => sub_region.name === selectedSubRegion)[0]?.cloud_id || "";
        const idToken = await getIDToken();

        const response = await fetch('https://us-central1-storecall.cloudfunctions.net/api/create-store', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken, // uid of current user
                storeid: storeDetails.rootID, // uid of user being queried (if admin querying other user)
                storeName: storeName,
                regionid: regionID,
                subregionid: subRegionID,
                storeemail: storeEmail,
                manageruid: managerid

            })
        });



        if (response.status === 200) {
            setBarMessage({
                type: "info",
                message: "Location has been successfully created.",
                result: "success"
            })
            setOpenBar(true)

        }
        else {
            setShowLoading(false)
            setBarMessage({
                type: "error",
                message: "There was an error creating this location.",
            })
            setOpenBar(true)
            return;
        }


        // addDoc(collection(db, "stores/" + storeDetails.rootID + "/stores"), {}).then(result => {
        //     const storeID = result.id;

        //     setDoc(doc(db, "stores/" + storeDetails.rootID + "/stores/" + storeID), 
        //     {

        //     name: storeName,
        //     region_id: regionID,
        //     store_email: storeEmail,
        //     manager: userManagers.filter(users => users.name === selectedManager)[0].uid 

        //     });

        //     setStoreID(storeID);
        //     console.log("Store created successfully " + storeID)
        //     navigate("/stores")
        // })
    }

    async function saveStore() {

        if (!checkStoreFieldsFilled()) return;

        setShowLoading(true);

        const regionID = regions.filter(region => region.name === selectedRegion)[0].cloud_id;
        const subRegionID = selectedSubRegion ? subRegions.filter(sub_region => sub_region.name === selectedSubRegion)[0].cloud_id : "";

        const idToken = await getIDToken();
        let manager_uid;
        if (userManagers.length > 0 && selectedManager.length > 0) {
            const manager = userManagers.filter(user => user.name === selectedManager);
            if (manager.length > 0) manager_uid = manager[0].uid;
            else manager_uid = "";
        } else manager_uid = "";

        const response = await fetch('https://us-central1-storecall.cloudfunctions.net/api/update-store', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                idToken: idToken, // uid of current user
                storeid: storeDetails.rootID, // uid of user being queried (if admin querying other user)
                storekey: params.id,
                storeName: storeName,
                regionid: regionID,
                subregionid: subRegionID,
                storeemail: storeEmail,
                manageruid: manager_uid

            })
        });

        if (response.status === 200) {
            setBarMessage({
                type: "info",
                message: "Location has been successfully updated.",
                result: "success"
            })
            setOpenBar(true)
        }
        else {
            setShowLoading(false)
            //setErrorMessage("Error updating store.")
            setBarMessage({
                type: "error",
                message: "There was an error updating this location.",
            })
            setOpenBar(true)
            return;
        }

        // const q = doc(db, "stores/" + storeDetails.rootID + "/stores/" + storeID);

        // setDoc(q, { 
        //     name: storeName,
        //     region_id: regionID,
        //     store_email: storeEmail,
        //     manager: userManagers.length > 0 ? userManagers.filter(users => users.name === selectedManager)[0].uid : "" 
        // });

        // navigate("/stores")
    }

    function handleManagerChange(e) {
        const manager = e.target.value;
        if (manager.length > 0) setSelectedManager(manager);
    }

    function handleClearManager() {
        setSelectedManager("")
    }

    return (
        <PageHolder>
            <Header>{storeHeader}</Header>

            <PageContainer>
                <Loader show={showLoading} />
                <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
                        {barMessage.message}
                    </Alert>
                </Snackbar>


                <TemplateFormFieldContainer>
                    
                    <TemplateFormFieldRow>
                        <StyledTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            error={showFieldError && storeName.length === 0}
                            
                            
                            value={storeName}
                            onChange={e => setStoreName(e.target.value)}
                        />
                    </TemplateFormFieldRow>

                    <TemplateFormFieldRow>
                        <StyledTextField
                            select
                            fullWidth
                            size="small"
                            
                            error={showFieldError && selectedRegion?.length === 0}
                            
                            value={selectedRegion || ""}
                            onChange={(e) => setSelectedRegion(e.target.value)}
                            InputProps={{
                                endAdornment: (<IconButton onClick={() => navigate("/app/locations/groups")} style={{ 'marginRight': '15px' }}>
                                    <StyledEditIcon />
                                </IconButton>)
                            }}

                        >
                            {regions && regions?.map((region, i) => {
                                return <MenuItem key={i} value={region?.name}>{region?.name}</MenuItem>
                            })}
                        </StyledTextField>
                    </TemplateFormFieldRow>

                    <TemplateFormFieldRow>
                        <StyledTextField
                            select
                            fullWidth
                            label="Location Sub Group (Optional)"
                            size="small"
                            value={selectedSubRegion || ""}
                            onChange={(e) => setSelectedSubRegion(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <IconButton style={{ 'padding': '0' }} onClick={() => setSelectedSubRegion("")}><StyledClearIcon /></IconButton>
                                        <IconButton onClick={() => navigate("/app/locations/groups")} style={{ 'marginRight': '15px' }}>
                                            <StyledEditIcon />
                                        </IconButton>
                                    </>
                                )
                            }}

                        >
                            {subRegions && subRegions.map((sub_region, i) => {
                                return <MenuItem key={i} value={sub_region?.name}>{sub_region?.name}</MenuItem>
                            })}
                        </StyledTextField>
                    </TemplateFormFieldRow>

                    <TemplateFormFieldRow>
                        <StyledTextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder='Enter an email linked to this location'
                            value={storeEmail}
                            onChange={e => setStoreEmail(e.target.value)}
                        />
                    </TemplateFormFieldRow>

                    <TemplateFormFieldRow>
                        <StyledTextField
                            select
                            fullWidth

                            label="Location Manager (Optional)"
                            value={selectedManager.length > 0 ? selectedManager : ""}
                            onChange={(e) => handleManagerChange(e)}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    selectedManager.length > 0 && (
                                        <IconButton
                                            sx={{ 'marginRight': '10px' }}
                                            onClick={() => handleClearManager()}
                                            edge="end"
                                            aria-label="clear manager"
                                        >
                                            <StyledClearIcon />
                                        </IconButton>
                                    )
                                ),
                            }}

                        >
                            {userManagers.map((user, i) => {
                                if (!user.active) return <MenuItem hidden key={i} value={user.name}>{user.name}</MenuItem>
                                else return <MenuItem key={i} value={user.name}>{user.name}</MenuItem>
                            })}
                        </StyledTextField>
                    </TemplateFormFieldRow>

                </TemplateFormFieldContainer>

                <StoreFormFieldContainer>
                    {storeFields.map((element, i) => {
                        return (
                            <StoreFormField key={i}>

                                <TemplateFormFieldRow>

                                    <TextField variant="standard"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {i + 1}
                                                </InputAdornment>
                                            )
                                        }}
                                        fullWidth
                                        placeholder='Enter your location name here'
                                        value={element.name}
                                        onChange={(e) => updateFormFieldName(element.id, e.target.value)}
                                    />
                                </TemplateFormFieldRow>

                            </StoreFormField>
                        )
                    })}
                </StoreFormFieldContainer>

                {storeID.length > 0 &&
                    <StoreFormFieldRow align="right">
                        <Button style={{ 'marginRight': '10px' }} highlighted onClick={() => deleteStore()}>
                            <StyledStoreIcon />
                            Delete store
                        </Button>
                        <Button onClick={() => saveStore()}>
                            <StyledUpdateIcon />
                            Update
                        </Button>
                    </StoreFormFieldRow>
                }

                {storeID.length === 0 &&
                    <StoreFormFieldRow align="right">
                        <Button alert onClick={() => createStore()}>
                            <StyledStoreIcon />
                            Create Location
                        </Button>
                    </StoreFormFieldRow>}
            </PageContainer>
            {showError &&
                <StoreFormErrorContainer>
                    <StoreFormErrorContainerText>{errorMessage}</StoreFormErrorContainerText>
                    <Button onClick={() => setShowError(false)}>OK</Button>
                </StoreFormErrorContainer>}

        </PageHolder>

    )
}

export default StoresForm