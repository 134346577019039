import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container, Typography, useMediaQuery } from '@mui/material';
import { PublicVisitHeaderContainer, PublicVisitStoreName, TemplateErrorContainer } from './styled/styled.publictemplate';
import LogoElement from './components/LogoElement';

import { CardContainer, Card, NewButton, Button, HeaderSubTitle, HeaderTitle, PageContainer, StyledClosedIcon, StyledAddOptionIcon, AddOptionButton, PageHolder, StyledUpdateIcon, StyledDeleteIcon, HeaderHolder, StyledCanvasContainer, Colors, StyledSignatureHolder } from './styled/styled.container';
import { TemplateFormFieldContainer, TemplateFormFieldNewFieldButton, TemplateFormFieldNewField, TemplateFormField, TemplateFormFieldText, TemplateFormFieldType, TemplateFormFieldRow, TemplateFormFieldAssignable, TemplateFormFieldNumber, TemplateFormFieldLabel, TemplateFormFieldButton, TemplateFormTitle, TemplateFormEmptyContainer, TemplateFormEmptyContainerText, TemplateFormErrorContainer, TemplateFormErrorContainerText, TemplateFormFieldCompleted, TemplateFormFieldFiles, TemplateFormFieldInsertComment, TemplateFormFieldLink, TemplateFormFieldFilesContainer, TemplateSection, TemplateFormFieldComment, TemplateSectionHeader, TemplateCountdownContainer, TemplateFormFieldScoreContainer, TemplateSectionLastScore, TemplateFormFieldQuestion, TemplateFormFieldSignatureLabel, TemplateDescription, TemplateDraftUpdate, TemplateDraftNameUpdateHolder, TemplateFormButtonStack } from './styled/styled.templateform';
import { db, storage } from "./firebase";
import { getDocs, Timestamp } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import axios from 'axios';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment/moment';
import { collection, onSnapshot, query, doc, setDoc, where, deleteDoc, addDoc, getDoc, updateDoc, FieldValue, arrayUnion } from "firebase/firestore";
import { useNavigate, useParams } from 'react-router-dom';
import { orderBy } from "firebase/firestore";
import { Divider } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { VisitHeaderContainer, VisitHeaderItem, VisitHeaderItemContainer } from './styled/styled.storevisit';
import Compress from "browser-image-compression"
import Loader from './Loader';
import ErrorDialog from './ErrorDialog';
import StoreVisitCompleteDialog from './StoreVisitCompleteDialog';
import DeleteFileDialog from './DeleteFileDialog';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { StyledNavLink } from './styled/styled.container';
import AlertDialog from './AlertDialog';
import Section from './Section';
import { auth } from "./firebase"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Signature from './Signature';
//import Section from './Section';

import Header from './Header';
import { useContext } from "react";
import AuthContext from "./AuthContext";
import { FooterContainer, FooterItem } from './styled/styled.footer';
import PublicVisitCompleteDialog from './components/PublicVisitCompleteDialog';
import { StyledSubmitContainer } from './styled/styled.demoform';
import ReCAPTCHA from 'react-google-recaptcha';

function TemplateError() {
    return (

        <TemplateErrorContainer>
            <LogoElement fontSize="1rem" logoColor="blue" textColor={`${Colors.blue}`} />
            <Typography sx={{ 'marginBottom': '15px' }} variant="h2">Hello from Storecall 👋</Typography>
            <Typography variant="h5">The survey that you are looking for does not exist or has ended.</Typography>
        </TemplateErrorContainer>

    )
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PublicVisit() {

    const params = useParams();
    const [templateDetails, setTemplateDetails] = useState();
    const [templateError, setTemplateError] = useState();
    const [sections, setSections] = useState([]);

    const [visitScorable, setVisitScorable] = useState();
    const [visitType, setVisitType] = useState();
    const visitDateRef = useRef(moment().format("DD-MM-YYYY HH:mm"));

    const [visitDate, setVisitDate] = useState(visitDateRef.current);

    const [timedVisit, setTimedVisit] = useState(false);
    const [timerAmount, setTimerAmount] = useState();
    const [timerProgress, setTimerProgress] = useState();
    const [visitScore, setVisitScore] = useState();
    const [visitDuration, setVisitDuration] = useState();
    const [visitRunning, setVisitRunning] = useState(true);
    const [signatureRequired, setSignatureRequired] = useState(false)
    const [fileSizeError, setFileSizeError] = useState(false);

    const [showLoading, setShowLoading] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState("");
    const [visitID, setVisitID] = useState("");
    const [startReportTiming, setStartReportTiming] = useState();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorContent, setErrorContent] = useState({});

    const [showCompleteDialog, setShowCompleteDialog] = useState(false)
    const [visitResults, setVisitResults] = useState();
    const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(false);
    const [savingVisitLoader, setSavingVisitLoader] = useState(false)
    const [reportSubmitted, setReportSubmitted] = useState(false)
    // used to store the section id and section item id for the DeleteFileDialog param
    const [selectedFile, setSelectedFile] = useState();
    const [openBar, setOpenBar] = React.useState(false);
    const [barMessage, setBarMessage] = useState({})

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [visitFiles, setVisitFiles] = useState();
    const [selectedItemID, setSelectedItemID] = useState();
    const [showFieldError, setShowFieldError] = useState(false);
    //const [defaultStatusClosed, setDefaultStatusClosed] = useState(false);

    const [receivedPDFLink, setReceivedPDFLink] = useState(false)
    const [pdfLink, setPDFLink] = useState();

    const signatureRef = useRef(null);
    const [signature, setSignature] = useState(null);
    //const [signatureData, setSignatureData] = useState([]);
    const sectionsRef = useRef([]);
    const intervalRef = useRef(null);

    const [showSubmitButton, setShowSubmitButton] = useState(false)
    const captureref = React.useRef(null);
    const isSmallScreen = useMediaQuery('(max-width:700px)');

    //const dateStoreVisit = moment().format("DD-MM-YYYY");
    const navigate = useNavigate();

    useEffect(() => {
        //console.log("Request", params.store, params.template)

        if (!reportSubmitted) getTemplateDetails();

    }, [params])

    useEffect(() => {
        if (timedVisit) {

            const interval = setInterval(() => {

                if (timerProgress < 1 && visitRunning) {
                    clearInterval(interval);
                    handleSaveStoreVisit()
                }
                else setTimerProgress(prevState => prevState - 1);
            }, 60000);

            return () => clearInterval(interval)
        }
    }, [timerProgress]);

    useEffect(() => {
        if (pdfLink) {

            async function saveVisitCloud() {

                setShowLoading(true)
                //console.log("Saving to cloud", pdfLink)
                const closeNow = checkFieldsCloseOnFill();
                const selectedItemIDAnswer = getSelectedItemIDAnswer();

                setLoaderMessage({
                    status: "submitting",
                    title: "Submitting your response",
                    content: `Please wait while we submit your response to ${params.store}.`
                })

                const pdfLinkToFile = await fetch(pdfLink).then(res => res.blob());
                const pdfLinkBlob = new File([pdfLinkToFile], `${templateDetails.name} ${moment().format("DD/MM/YYY HH.mm")}.pdf`, { type: "application/pdf", lastModified: Date.now() })
                //console.log("File", pdfLinkBlob)




                const formData = new FormData();

                const fileName = `${templateDetails.name} ${moment().format("DD/MM/YY HH:mm")}.pdf`;

                formData.append('file', pdfLinkBlob);
                formData.append("file_name", fileName)
                formData.append('store_id', templateDetails.store_id);
                formData.append("email_users", JSON.stringify(templateDetails.email_users));
                formData.append("template_name", templateDetails.name);
                formData.append('date', moment(visitDate, "DD-MM-YYYY HH:mm").toISOString());
                formData.append('template_id', templateDetails.template_id);
                formData.append('score', visitScore);
                formData.append('selected_id_answer', selectedItemIDAnswer || "");
                formData.append('selected_id', selectedItemID || "");
                formData.append('scorable', templateDetails.scorable);
                // formData.append('report_link', pdfLink);
                // formData.append('attachments', visitFiles);
                formData.append('visit_type', templateDetails.template_type);
                formData.append('visit_status', closeNow ? "Closed" : "Open");
                formData.append('visit_closed_date', closeNow ? moment().format("DD-MM-YYYY HH:mm") : "");
                formData.append('location', []);
                formData.append('location_required', false);
                formData.append('user', "Public");
                formData.append('user_uid', "");
                formData.append('startTime', moment(visitDate, "DD-MM-YYYY HH:mm").toISOString());
                formData.append('manager', "");
                formData.append('signature_link', "");
                formData.append('endTime', moment().toISOString());
                formData.append('actions_total', 0);
                formData.append('actions_outstanding', 0);
                formData.append("sections", JSON.stringify([...sections]));


                try {
                    const response = await axios.post('https://us-central1-storecall.cloudfunctions.net/api/save_public_visit', formData);
                    const result = response.message;
                    const resultStatus = response.status;

                    if (resultStatus === 200) {
                        setLoaderMessage({
                            status: "success",
                            title: "Submission Complete",
                            content: `Your submission to ${params.store} for the ${templateDetails.name} has been successfully submitted.`
                        })
                        setReportSubmitted(true)
                    }
                    else {
                        setLoaderMessage({
                            status: "error",
                            title: "Submission Error",
                            content: `There was an error submitting your response to ${params.store} for the ${templateDetails.name}. Please try again later.`
                        })
                        setReportSubmitted(false)
                    }

                    //console.log("Result", resultStatus, result)
                } catch (error) {
                    console.error(error);
                }



                // await setDoc(doc(db, "stores/" + templateDetails.store_id + "/visits/" + visitID), {
                //     store_id: templateDetails.store_id,
                //     date: moment().toISOString(),
                //     template_id: templateDetails.template_id,
                //     score: visitScore,
                //     selected_id_answer: selectedItemIDAnswer || "",
                //     selected_id: selectedItemID || "",
                //     scorable: visitScorable,
                //     report_link: linkRef,
                //     attachments: visitFiles,
                //     visit_type: visitType,
                //     visit_status: closeNow ? "Closed" : "Open",
                //     visit_closed_date: closeNow ? moment().format("DD-MM-YYYY HH:mm") : "",
                //     location: [],
                //     location_required: false,
                //     user: "Public",
                //     user_uid: "",
                //     startTime: moment(visitDate, "DD-MM-YYYY HH:mm").toISOString(),
                //     manager: "",
                //     signature_link: "",
                //     endTime: moment().toISOString(),
                //     actions_total: 0,
                //     actions_outstanding: 0
                // })

                // await Promise.all(sections.map(section => {
                //     //console.log(section)

                //     return new Promise((resolve) => {
                //         resolve(setDoc(doc(db, "stores/" + templateDetails.store_id + "/visits/" + visitID + "/sections", section.section_id), section))
                //     })
                // }))

                setShowLoading(false)
            }


            async function handleSave() {

                //setSavingVisitLoader(true)
                if (!reportSubmitted) await saveVisitCloud();

                setReceivedPDFLink(true)
            }

            handleSave();

        }
    }, [pdfLink])

    async function getTemplateDetails() {

        setShowLoading(true)
        const templateRequest = await fetch(`https://us-central1-storecall.cloudfunctions.net/api/public_template?storeid=${params.store}&templateid=${params.template}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (templateRequest.status !== 200) {
            console.log("Error", templateRequest.status)
            setShowLoading(false)
            setTemplateError(true);
            return;
        }

        const templateDataDetails = await templateRequest.json();
        //console.log("Template Details", templateDataDetails)
        setTemplateDetails({
            store_id: params.store,
            template_id: params.template,
            ...templateDataDetails
        });
        //console.log("Sections", templateDataDetails.sections)
        const updatedSections = templateDataDetails.sections.map(section => {
            return {
                ...section,
                list: section.list.map(item => {
                    return {
                        ...item,
                        fieldText: "",
                        actions: [],
                        rating: 0,
                        yesno: "",
                        selectionOption: "None",
                        datetime: "",
                        files: [],
                        newFiles: [], // will not be used in form - only in report to allow user to upload more files
                        showComments: false,
                        comments: []
                    }
                })
            }
        }).sort((a, b) => a.key - b.key)

        setSelectedItemID(templateDataDetails.selected_item_id?.section_item_id)

        setSections(updatedSections)

        setShowLoading(false);

    }


    const checkFieldsCloseOnFill = () => {
        if (templateDetails.default_status_closed) return true;
        else {
            let closeVisit = true;

            for (let i = 0; i < sections.length; i++) {
                const sectionElements = sections[i].list;

                if (sectionElements.some(item => {
                    if (item?.closeOnFill) {
                        if (item.type === "Comment" || item.type === "Number") {
                            return item.fieldText.length === 0;
                        } else if (item.type === "Selection") {
                            return item.selectionOption.length === 0;
                        } else if (item.type === "List Checkbox") {
                            return item.item_list_checkbox.length === 0;
                        } else if (item.type === "Item Comment" || item.type === "Item List" || item.type === "Item Selection") {
                            return item.item_list_comment.length === 0;
                        } else if (item.type === "Item Count") {
                            return item.counts.length === 0;
                        } else if (item.type === "DateTime" || item.type === "Time") {
                            return item.datetime.length === 0;
                        } else if (item.type === "YesNo") {
                            return item.yesno.length === 0;
                        }
                    }
                    return false;
                })) {
                    closeVisit = false;
                    break;
                }
            }

            return closeVisit;
        }
    }

    function getSelectedItemIDAnswer() {
        const selectedItem = sections.flatMap(section => section.list)
            .find(item => item.section_item_id === selectedItemID);

        if (!selectedItem) return "";

        switch (selectedItem.type) {
            case "Comment":
            case "Number":
                return selectedItem.fieldText;
            case "Selection":
                return selectedItem.selectionOption;
            case "DateTime":
            case "Time":
                return selectedItem.datetime;
            case "YesNo":
                return selectedItem.yesno;
            default:
                return "";
        }
    }

    const handleSignatureEnd = () => {
        setSignature(signatureRef.current.toDataURL());
    }
    const clearSignature = () => {

        //signatureRef.current.clear();
        signatureRef.current.clearCanvas();
        setSignature(null);
    }


    // used when saving visit to see if space maxed
    // async function isStoreSpaceAvailable() {
    //     const token = await getIDToken();
    //     const encoded_token = encodeURIComponent(token)

    //     const response = await fetch(`https://us-central1-storecall.cloudfunctions.net/api/organisation-space-available?uid=${encoded_token}&storeid=${templateDetails.store_id}`)
    //     const response_data = await response.json();

    //     // ceck here
    //     return response_data;
    // }



    // async function getStoreSpaceUsage() {
    //     const token = await getIDToken();
    //     const encoded_token = encodeURIComponent(token)

    //     const response = await fetch(`https://us-central1-storecall.cloudfunctions.net/api/organisation-space-used?uid=${encoded_token}&storeid=${templateDetails.store_id}`)
    //     const response_data = await response.json();
    //     const used_space = response_data.used;
    //     const max_space = response_data.max_space;
    //     const percent_used = (used_space / max_space);

    //     //console.log("Checking space")

    //     if (percent_used >= 98) {
    //         setBarMessage({
    //             type: "error",
    //             message: `Organisation space usage is ${percent_used}%. This visit may not save correctly.`
    //         })

    //         setOpenBar(true)
    //         setShowLoading(false)
    //     }

    // }



    // async function getSignatureImageUrl(visitRef_id, blob) {

    //     return new Promise((resolve, reject) => {
    //         const storageRef = ref(storage, `${templateDetails.store_id}/${visitRef_id}/signature.png`);

    //         const uploadTask = uploadBytesResumable(storageRef, blob);

    //         uploadTask.on("state_changed",
    //             (snapshot) => {
    //                 const progress =
    //                     Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

    //             },
    //             (error) => {
    //                 console.log(error)
    //                 reject(error)
    //             },
    //             () => {

    //                 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //                     resolve(downloadURL);
    //                     //setUploadedFiles(prevState => [...prevState, downloadURL])
    //                 });
    //             }
    //         );
    //     })

    // }

    async function uploadBlob(visitRef_id, link) {
        //console.log("Rec Link", visitRef_id, link)

        const blob = await fetch(link).then(res => res.blob());
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `${templateDetails.store_id}/${visitRef_id}/reports/${visitRef_id}.pdf`);

            const uploadTask = uploadBytesResumable(storageRef, blob);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                },
                (error) => {
                    console.log(error)
                    reject(error)
                },
                () => {

                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                        //setUploadedFiles(prevState => [...prevState, downloadURL])
                    });
                }
            );
        })
    }

    async function compressImage(file) {
        const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        if (!acceptedImageTypes.includes(file.type)) {
            return file
        }

        const options = {
            maxSizeMB: 0.5,
            useWebWorker: true
        }


        const compressedBlob = await Compress(file, options);
        if (compressedBlob) {

            const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })
            return convertedBlobFile
        }
        else return file;
    }



    const isLetters = (str) => /^[A-Za-z]*$/.test(str);




    function handleDeleteFileDialog(section_id, section_item_id, file) {
        //setSelectedSection(section_id);
        setSelectedFile({
            section_id: section_id,
            section_item_id: section_item_id,
            file: file
        })

        setShowDeleteFileDialog(true);
    }






    // async function getIDToken() {
    //     const token = await auth.currentUser.getIdToken(true)
    //     return token;
    // }

    // async function removeFileCloud(section_id, section_item_id, file_name) {
    //     setShowLoading(true);

    //     const idToken = await getIDToken();

    //     const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-file', {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //         body: JSON.stringify({
    //             idToken: idToken,
    //             storeid: templateDetails.store_id,
    //             visitid: visitID,
    //             sectionid: section_id,
    //             sectionitemid: section_item_id,
    //             file_name: file_name
    //         })
    //     })

    //     if (result.status === 200) {
    //         setBarMessage({
    //             type: "info",
    //             message: "File has been successfully deleted.",
    //             result: "success"
    //         })

    //         setOpenBar(true)

    //     }
    //     else {
    //         setShowLoading(false)
    //         setBarMessage({
    //             type: "error",
    //             message: "There was an error deleting this file."
    //         })

    //         setOpenBar(true)

    //     }

    // }

    // async function handleRemoveFile(section_id, section_item_id, file) {

    //     // if the file is part of newfiles then remove from newfiles
    //     // else if file is part of files list -> remove from files, firebase and storage

    //     // remove file from list
    //     setSections(prevState => (prevState.map(el => {
    //         if (el.section_id === section_id) {
    //             return {
    //                 ...el,
    //                 list: el.list.map(item => {
    //                     if (item.section_item_id === section_item_id) {
    //                         const updatedFileList = item.files.filter(element => element.description !== file.description)
    //                         return { ...item, files: updatedFileList }
    //                     } else return item;
    //                 })
    //             }
    //         }
    //         else return el;
    //     })))


    //     setShowDeleteFileDialog(false);


    // }


    // async function getFileDownloadURLS(visit_id, section_id, section_item_id, files) {

    //     const dateToday = moment().format("DD-MM-YYYY");
    //     const uploadedFiles = [];

    //     const result = await Promise.all(files.map(file => {
    //         return new Promise(async (resolve, reject) => {
    //             const currentFile = await compressImage(file.file);

    //             const storageRef = ref(storage, `${templateDetails.store_id}/${visit_id}/${section_id}/${section_item_id}/${currentFile.name}`);
    //             const uploadTask = uploadBytesResumable(storageRef, currentFile);

    //             uploadTask.on("state_changed",
    //                 (snapshot) => {
    //                     const progress =
    //                         Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

    //                 },
    //                 (error) => {
    //                     alert(error);
    //                 },
    //                 () => {

    //                     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

    //                         const fileElement = {
    //                             description: currentFile.name,
    //                             date: dateToday,
    //                             size: currentFile.size,
    //                             user: "Public",
    //                             comment: file.comment,
    //                             url: downloadURL
    //                         }

    //                         uploadedFiles.push(fileElement);
    //                         resolve(fileElement);
    //                         //setUploadedFiles(prevState => [...prevState, downloadURL])
    //                     });
    //                 }
    //             );
    //         })
    //     }))

    //     return result;

    // }





    async function handleSaveStoreVisit() {

        if (fileSizeError) {
            setErrorContent({
                title: "Large Files",
                content: "You have files that are too large to be uploaded to this store visit.",
                extra_content: "Maximum file size: 8mb."
            })
            setShowErrorDialog(true);
            return;
        }


        if (signatureRequired && signatureRef.current.isCanvasBlank()) {
            setBarMessage({
                type: "error",
                message: "A signature is required before completing this visit."
            })
            setOpenBar(true)
            return;
        }

        const mandatoryFieldsMissing = await checkTemplateFieldsFilled();
        if (mandatoryFieldsMissing) {
            setShowFieldError(true)
            setBarMessage({
                type: "error",
                message: "You have mandatory fields that are missing information."
            })
            setOpenBar(true)

            return;
        }

        setLoaderMessage("Preparing your submission...");
        setShowLoading(true)

        // const space_available = await isStoreSpaceAvailable();

        // if (!space_available) {
        //     setShowLoading(false)
        //     setBarMessage({
        //         type: "error",
        //         message: "The account space availability is maxed. Please contact your admin to upgrade your account."
        //     })

        //     setOpenBar(true)
        //     setShowLoading(false)
        //     return;
        // }

        setVisitRunning(false);

        const visitScore = await getVisitScore();
        setVisitScore(visitScore);

        const currentTime = moment().format("DD-MM-YYYY HH:mm");
        const reportTime = moment.duration(moment(visitDate, "DD-MM-YYYY HH:mm").diff(moment(currentTime, "DD-MM-YYYY HH:mm"))).humanize();
        setVisitDuration(reportTime)

        //const visitRef = doc(collection(db, "stores/" + templateDetails.store_id + "/visits"));
        //const idToken = await getIDToken();
        const visitFilesTemp = [];
        const dateToday = moment().format("DD-MM-YYYY");

        const updatedSections = await Promise.all(sections.map(async section => {
            // const updatedList = await Promise.all(section.list.map(async element => {
            //     if (element.newFiles.length > 0) {
            //         setLoaderMessage("Uploading files");
            //         const updatedFileList = [];
            //         for (const fileElement of element.newFiles) {
            //             const formData = new FormData();
            //             formData.append("idToken", idToken);
            //             formData.append('file', fileElement.file);
            //             formData.append("file_name", fileElement.file.name)
            //             formData.append('store_id', templateDetails.store_id);
            //             formData.append('visit_id', visitRef.id);
            //             formData.append('section_id', section.section_id);
            //             formData.append('section_item_id', element.section_item_id);
            //             try {
            //                 const response = await axios.post('https://us-central1-storecall.cloudfunctions.net/api/upload-file', formData);
            //                 const result = response.data;
            //                 const { url, size } = result;
            //                 visitFilesTemp.push({
            //                     file_description: fileElement.file.name,
            //                     url: url,
            //                 });


            //                 const newFileElement = {
            //                     description: fileElement.file.name,
            //                     date: dateToday,
            //                     size: size,
            //                     user: "Public",
            //                     comment: fileElement.comment,
            //                     url: url,
            //                 };
            //                 updatedFileList.push(newFileElement);
            //             } catch (error) {
            //                 console.error(error);
            //             }
            //         }
            //         return {
            //             ...element,
            //             files: updatedFileList,
            //             newFiles: []
            //         };
            //     } else {
            //         return element;
            //     }
            // }));
            return {
                ...section,
                score: getSectionScore(section.section_id),
                scorable: isSectionScorable(section.section_id),
                files_loaded: true
            };
        }));
        //console.log("Updated ", updatedSections)
        //console.log("Files uploaded")
        //console.log("Final Sections", updatedSections);
        setSections(updatedSections)

        //console.log("Signature link received")
        //setVisitID(visitRef.id);

        //setReportTiming(moment.duration(moment(startTime, "DD-MM-YYYY HH:mm").diff(moment(endTime, "DD-MM-YYYY HH:mm"))).humanize())

        setShowCompleteDialog(true);


    }

    function handleCloseCompleteDialog() {
        navigate("/app/storevisit")
        setShowCompleteDialog(false)
    }


    async function getVisitScore() {
        const scoreTotal = sections?.map(section => {
            const sectionScore = getSectionScore(section.section_id)

            return sectionScore
        }).reduce((total, currentItem) => total + currentItem)

        const scorableSections = sections.filter(section => isSectionScorable(section.section_id));

        if (!scoreTotal || scoreTotal === 0) return 0;

        // work out the visit score for all the sections as a percentage where the getSectionScore returns the percentage score
        // so if there are 3 sections and each section has a score of 50% then the visit score is 50%
        // 150 / 3 = 50

        // 1 / 5 = 0.2 * 100 = 20%
        // 50 + 67 + 20 = 
        const visitScore = Math.abs(Math.round(scoreTotal / scorableSections.length));
        //console.log("Total Visit Score", visitScore)

        return visitScore

    }

    function isSectionScorable(section_id) {
        const section = sections.filter(section => section.section_id === section_id);
        const scorableList = section[0].list.filter(item => item.scorable === true);
        if (scorableList.length > 0) return true;
        return false;
    }

    function getSectionScore(section_id) {

        // get section score based on scorable items of section items
        const section = sections.find(section => section.section_id === section_id);

        const scoreTotal = section.list.filter(listItem => listItem.scorable).reduce((total, currentItem) => total + currentItem.rating, 0)

        const itemsTotal = section.list.filter(item => item.scorable)?.length;

        if (!itemsTotal) return 0;
        else if (scoreTotal === 0) return 0;

        // work out the section percentage score where the scoreTotal is a total of ratings where 0 = 0% and 4 = 100%
        // 12 / (6 * 4) * 100 = 50%
        const sectionScore = Math.abs(Math.round((scoreTotal / (itemsTotal * 4)) * 100));
        //console.log("Section score", section.name, sectionScore);
        return sectionScore ? sectionScore : 0;

        // const sectionScore = Math.abs(Math.round( (scoreTotal / (itemsTotal * 5))*100 ));
        // console.log("Section score", section.name, sectionScore)
        // return sectionScore ? sectionScore : 0;
    }

    async function checkTemplateFieldsFilled() {
        let bFailed = false;

        sections.forEach(section => {
            const mandatoryFields = section.list.filter(element => element.mandatory);


            mandatoryFields.forEach(item => {
                if (item.type === "None") bFailed = false
                //if (item.type === "Image" && item.newFiles.length === 0) bFailed = true;

                if (item.type === "Selection" && item.selectionOption === "None") {
                    bFailed = true;
                }

                else if (item.type === "Comment" && item.fieldText.length === 0) {

                    bFailed = true;
                }
                else if (item.type === "YesNo" && item.yesno.length === 0) bFailed = true;

                else if ((item.type === "DateTime" || item.type === "Time") && item.datetime.length === 0) {

                    bFailed = true;
                }
                // else if(item.type === "Number" && item.fieldText.length === 0) {

                //   bFailed = true;
                // }
                else if (item.type === "Item List" && item.item_list_comment.length === 0) bFailed = true;
                else if (item.type === "Item Comment" && item.item_list_comment.length === 0) bFailed = true;
                else if (item.type === "Item Selection" && item.options.length === 0) bFailed = true;
                else if (item.type === "List Checbox" && item.item_list_checkbox.length === 0) bFailed = true;
                else if (item.type === "Item Count" && item.counts.length === 0) bFailed = true;


            })


        })

        return bFailed;
    }



    const handleClose = (event, reason) => {

        setOpenBar(false);
        setShowLoading(false)
        //if(barMessage.result === "success") navigate("/storevisit")
    };

    function handleClearSignature() {
        clearSignature()
    }

    const handleUpdateSection = useCallback((sectionProp) => {

        const sectionIndex = sections.findIndex(section => section.section_id === sectionProp.section_id);
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            updatedSections[sectionIndex] = sectionProp;

            return updatedSections;
        });
    }, [sections]);

    function onChange(value) {
        try {
            if(value) {
                setShowSubmitButton(true)
            }
            else {
                setShowSubmitButton(false)
            }
            
        }catch(err) {
            console.log(err)
        }
        
    }

    async function handleSubmit(e) {
        e.preventDefault()
        
        captureref.current.execute()
        
    }

    if (templateError) {
        return (
            <Container style={{ minHeight: '100vh' }}>
                <PageHolder style={{ minHeight: '100vh' }}>
                    {showLoading && <Loader show={showLoading} />}
                    {templateError && <TemplateError />}
                </PageHolder>
            </Container>
        )
    }

    if (!templateDetails) {
        return (
            <Container style={{ minHeight: '100vh' }}>
                <PageHolder style={{ minHeight: '100vh' }}>
                    {showLoading && <Loader show={showLoading} />}
                </PageHolder>
            </Container>
        )
    }

    return (
        <>
            <Container sx={{ minHeight: '100vh' }}>
                <PageHolder style={{ minHeight: '100vh' }}>
                    {showLoading && <Loader show={showLoading} />}

                    {showCompleteDialog &&
                        <PublicVisitCompleteDialog
                            title={`Thank you for your submission.`}
                            content={`Your submission to ${params.store} for the ${templateDetails.name} has been submitted!`}
                            open={showCompleteDialog}
                            newReport={true}
                            storeID={params.store}
                            setPDFLink={setPDFLink}
                            showLoader={showCompleteDialog && showLoading}
                            loaderMessage={loaderMessage}
                            setShowLoader={setShowLoading}
                            template={templateDetails.name}
                            actionsOutstanding={0}
                            store={params.store}
                            visitID={visitID}
                            region={""}
                            scorable={visitScorable}
                            duration={visitDuration}
                            manager={"N/A"}
                            score={visitScore}
                            visitType={visitType}
                            visitStatus={templateDetails.default_status_closed ? "Closed" : "Open"}
                            visitDate={visitDate}
                            visitEndDate={moment().format("DD-MM-YYYY HH:mm")}
                            visitClosedDate={templateDetails.default_status_closed ? moment().format("DD-MM-YYYY HH:mm") : ""}
                            user={"Public"}
                            sections={sections}
                            signature_required={false}
                            signature_link={""}
                            action={handleCloseCompleteDialog} />}

                    <PublicVisitHeaderContainer>
                        <Container>
                            <PublicVisitStoreName>{params.store}</PublicVisitStoreName>
                            <Typography sx={{ textAlign: 'center', marginBottom: '10px', textTransform: 'capitalize' }} variant="h5">{templateDetails.name}</Typography>
                            <Typography sx={{ textAlign: 'center', marginBottom: '10px', fontWeight: 400, fontSize: window.innerWidth < 600 ? '0.7rem' : '0.9rem' }} variant="h6">{templateDetails.description}</Typography>
                        </Container>

                    </PublicVisitHeaderContainer>

                    <PageContainer empty_shadow={true} style={{ 'padding': '0px' }}>
                        <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
                                {barMessage.message}
                            </Alert>
                        </Snackbar>

                        <TemplateFormFieldContainer>
                            {sections && sections.map((section, i) => {

                                // if (!visibleSections.includes(i)) {
                                //   // This is a placeholder; you can return null or a loading spinner, or even leave it empty.
                                //   return <div key={i} ref={assignRef(i)} style={{ height: '0px' }}></div>;
                                // }

                                return <Section
                                    key={section.section_id}
                                    users={templateDetails.users}
                                    permissions={templateDetails.permissions}
                                    sectionProp={section}
                                    setFileSizeError={setFileSizeError}
                                    isVisitPublic={templateDetails.isPublic}
                                    fieldError={showFieldError}
                                    updateSection={handleUpdateSection} />

                            })}
                        </TemplateFormFieldContainer>
                        {timedVisit &&
                            <TemplateCountdownContainer>
                                {timerProgress > 0 ? `${timerProgress} minutes to complete` : "Time is up"}
                            </TemplateCountdownContainer>}

                        {signatureRequired &&
                            <StyledSignatureHolder>
                                <TemplateFormFieldSignatureLabel>
                                    Sign below to complete this visit
                                    <StyledClosedIcon style={{ 'marginLeft': '5px' }} onClick={handleClearSignature} fontSize="large" />
                                </TemplateFormFieldSignatureLabel>

                                <Signature ref={signatureRef} />

                                {/* <Signature ref={signatureRef} signatureData={signatureData} onSignatureDrawn={handleSignatureDrawn} /> */}
                            </StyledSignatureHolder>}
                    </PageContainer>

                    <StyledSubmitContainer>
                    <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        sitekey="6LfyRscoAAAAAM-2r9AlzwpWvC0JAVIp3WG0wfCW"
                        onChange={onChange}
                        size={isSmallScreen ? "compact" : "normal"}
                        ref={captureref}
                        
                        
                    />
                    </StyledSubmitContainer>
                    
                    <TemplateFormButtonStack align="right">
                        <Button disabled={!isOnline || !showSubmitButton} color={Colors.dark_grey_highlight} style={{ 'margin': '5px' }} onClick={handleSaveStoreVisit}><StyledUpdateIcon />Submit</Button>
                    </TemplateFormButtonStack>


                </PageHolder>

            </Container>
            <FooterContainer>
                <FooterItem>Powered by <b>Storecall</b>. All Rights Reserved</FooterItem>
                <FooterItem>By submitting any information to <b>{params.store}</b> you are agreeing to our <a href="/privacy">Privacy Policy</a></FooterItem>
            </FooterContainer>
        </>


    )


}

export default PublicVisit