import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "./firebase";
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import Splash from './Splash';
import PrivacyPolicy from './PrivacyPolicy';
import { ThemeProvider } from '@mui/material/styles';

import Footer from './Footer';
import { TextField } from '@material-ui/core';
import { Typography, createTheme } from '@mui/material';

import { Colors } from './styled/styled.container';
import PublicVisit from './PublicVisit';

const root = ReactDOM.createRoot(document.getElementById('root'));

function AuthenticatedRoute({ element: Component}) {
  const [user, loading, error] = useAuthState(auth);

  

  // Render the protected component if the user is authenticated
  return <Route element={<Component />} />;
}

const themeSettings = () => {
  return ({
      palette: {
          primary: {
              main: Colors.blue,
          },
          secondary: {
              main: Colors.dark_blue,
          },
          
         
      },
      typography: {
          fontFamily: ["Poppins", "sans-serif"].join(","),
          fontWeightLight: 400,
          fontWeightRegular: 500,
          fontWeightMedium: 600,
          fontWeightBold: 700,
          letterSpacing: 1,
          fontSize: 12,

          h1: {
              fontFamily: ["Poppins", "sans-serif"].join(","),
              fontWeight: 600,
              fontSize: 40,
          },
          h2: {
              fontFamily: ["Poppins", "sans-serif"].join(","),
              fontWeight: 600,
              fontSize: 32,
          },
          h3: {
              fontFamily: ["Poppins", "sans-serif"].join(","),
              fontWeight: 500,
              fontSize: 24,
          },
          h4: {
              fontFamily: ["Poppins", "sans-serif"].join(","),
              fontWeight: 500,
              fontSize: 20,
          },
          h5: {
              fontFamily: ["Poppins", "sans-serif"].join(","),
              fontWeight: 500,
              fontSize: 16,
          },
          h6: {
              fontFamily: ["Poppins", "sans-serif"].join(","),
              fontWeight: 300,
              color: Colors.red,
              fontSize: 14,
          },
      },
      components: {

        MuiButton: {
          styleOverrides: {
            root: {
              fontSize: 14,
              letterSpacing: 1,
            },
          },
        },

        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: 12,
              fontWeight: 400
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
          
            row: { // Targeting the row
              '&:hover': {
                backgroundColor: "#f8f7ff", // Set your hover color here
                // Add more styles here as needed
              },
              style: {
                cursor: 'pointer'
              }
            },
            columnHeader: {
              backgroundColor: `${Colors.blue}`, // Your desired background color
              color: `${Colors.white}`,
              // Add more styles here as needed
            },
            
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
              root: {
                  minWidth: 0,
              },
          },
      
      }
      },

  });
}

const theme = createTheme(themeSettings());

root.render(
    <ThemeProvider theme={theme}>
  
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/app/*" element={<App />} />
        <Route path="/survey/:store/:template" element={<PublicVisit />} />
        <Route path='/privacy/*' element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
    
    </ThemeProvider>

);

serviceWorkerRegistration.unregister();

