import React, { useContext, useEffect, useState } from 'react'
import { Button, CardContainer, Colors, ColumnContainer, Text } from './styled/styled.container'
import moment from 'moment-timezone';
import { FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import { TemplateFormButtonStack, TemplateFormFieldRow } from './styled/styled.templateform';
import AuthContext from './AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';
import logEvent from './hooks/logEvent';

function SettingsTimeZone() {

    const [selectedTimezone, setSelectedTimezone] = useState('');
    const {storeDetails} = useContext(AuthContext);
    const [showLoading, setShowLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [barMessage, setBarMessage] = useState('');

    useEffect(() => {
        if(storeDetails.timezone !== undefined) setSelectedTimezone(storeDetails.timezone);
    }, [storeDetails])

    // Create an array of timezones
    const timezones = moment.tz.names();

    // Handle the change event when the user selects a timezone
    const handleTimezoneChange = (event) => {
        setSelectedTimezone(event.target.value);
    };

    async function handleSaveTimezone() {

        if(selectedTimezone === '') {
            setBarMessage('Please select a timezone before saving.');
            setShowSnackbar(true);
            return;
        }

        setShowLoading(true);
        await updateDoc(doc(db, "stores", storeDetails.rootID), {
            timezone: selectedTimezone
        })
        logEvent("Settings", `Timezone changed to ${selectedTimezone}`, storeDetails.user.email)
        setShowLoading(false);
    }


    return (
        <CardContainer>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={2500}
                onClose={() => setShowSnackbar(false)}
                message={barMessage} />

            <ColumnContainer style={{'marginBottom': '10px'}}> 
                <Text style={{flex: 'none', width: 'fit-content', margin: '5px', marginRight: '10px'}} fontWeight={600} fontSize={"0.9rem"} color={Colors.dark_grey_highlight}>Select your timezone</Text>
                <Select
                        labelId="timezone-label"
                        id="timezone-select"
                        sx={{ flex: 1, margin: '5px'}}
                        value={selectedTimezone}
                        size="small"
                        onChange={handleTimezoneChange}
                    >
                        {timezones.map((timezone) => (
                            <MenuItem key={timezone} value={timezone}>
                                {timezone}
                            </MenuItem>
                        ))}
                    </Select>
            </ColumnContainer>

            <TemplateFormButtonStack align="right">
                <Button onClick={handleSaveTimezone} style={{margin: '5px'}}>Save</Button>
            </TemplateFormButtonStack>

        </CardContainer>
    )
}

export default SettingsTimeZone