import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { CardContainer, TableContainer, HeaderSubTitle, Card, HeaderTitle, PageContainer, StyledLink, BlueContainer, Button, PageHolder, Text, StyledScheduleIcon, ColumnContainer, StyledActionIcon } from './styled/styled.container'
import { useNavigate, Link } from 'react-router-dom'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "./firebase";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { collection, getDocs, onSnapshot, query, doc, where, getDoc, orderBy } from "firebase/firestore";
import moment from 'moment/moment';
import { DashboarChartContainer, DashboardHeader, DashboardCard, DashboardContainer, DashboardItem, StyledMoreIcon, DashboardOptionsContainer, DashboardOptionText, DashboardHeaderContainer, DashboardRadioGroup, DashboardRadioOption, DashboardRadioButton, DashboardRadioLabel, DashboardRadioGroupContainer, DashboardChartHolder, DashboardEmptyContainer, DashboardEmptyContainerTitle, DashboardEmptyContainerText, DashboardChartRow, StyledAutoCompleteTextField } from './styled/styled.dashboard';
import Chart from "react-apexcharts";
import Header from './Header';
import Loader from "./Loader"
import { Colors } from './styled/styled.container';
import { Autocomplete, CssBaseline, Divider, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { RowHighlighted } from './styled/styled.container';
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import AuthContext from "./AuthContext";
import HomeAssist from './HomeAssist';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import EmptyScreen from './EmptyScreen';
import HelloScreen from './HelloScreen';
import HomeCardActionsDue from './HomeCardActionsDue';
import { StyledLogIcon, StyledReportsIcon, StyledScoreIcon, StyledVisitIcon } from './styled/styled.icons';
import { DivHolder, TemplateDivHolder, TemplateFormFieldRow } from './styled/styled.templateform';
import useGetSchedule from './hooks/useGetSchedule';
import { useMemo } from 'react';
import HomeWeekEvents from './components/HomeWeekEvents';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  highValue: {
    color: `${Colors.dark_grey}`,
    backgroundColor: `${Colors.light_grey}`
  },
  lowValue: {

  }
});

const columns = [
  { field: "visit_id", headerName: "ID", hide: true },
  { field: 'store_name', headerName: 'Location', minWidth: 150, flex: 1 },
  { field: 'template_name', headerName: 'Template', minWidth: 150, flex: 1 },
  {
    field: 'visit_date',
    headerName: 'Date',
    minWidth: 150,
    flex: 1
  },
  {
    field: "score", headerName: "Score",
    minWidth: 80,
    flex: 1

  },
  {
    field: "actions_outstanding",
    headerName: "Actions",
    minWidth: 100,
    flex: 1

  },
  {
    field: "visit_type",
    headerName: "Visit Type",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "visit_status",
    headerName: "Status",
    minWidth: 100,
    flex: 1
  }
];

function Home(props) {

  const { setShowVisitType } = props;
  const { storeDetails } = useContext(AuthContext);

  const navigate = useNavigate();
  const [seriesVisits, setSeriesVisits] = useState([]);
  const [optionsVisits, setOptionsVisits] = useState({});

  const [seriesTemplatesScore, setSeriesTemplatesScore] = useState([]);
  const [optionsTemplatesScore, setOptionsTemplatesScore] = useState({})

  const [seriesVisitsScores, setSeriesVisitsScores] = useState([])
  const [optionVisitsScores, setOptionVisitsScores] = useState({});
  const [seriesStores, setSeriesStores] = useState([]);
  const [optionsStores, setOptionsStores] = useState({});
  const [seriesTemplates, setSeriesTemplates] = useState([]);
  const [optionsTemplates, setOptionsTemplates] = useState({})
  const [seriesActions, setSeriesActions] = useState([])
  const [optionsActions, setOptionsActions] = useState()
  const [stores, setStores] = useState(storeDetails.stores);
  const [templates, setTemplates] = useState(storeDetails.user_specific_templates);
  const [visits, setVisits] = useState([]);
  const [auditsCompleted, setAuditsCompleted] = useState(0)
  const [logsOpen, setLogsOpen] = useState(0);
  const [recentVisits, setRecentVisits] = useState([])
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [outstandingActions, setOutstandingActions] = useState(0);
  const [myActions, setMyActions] = useState(0);
  const [actionItemsDue, setActionItemsDue] = useState([])
  const [globalScore, setGlobalScore] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [showDashboardOptions, setShowDashboardOptions] = useState(false)
  const [emptyVisits, setEmptyVisits] = useState(false);
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const classes = useStyles();

  const [locationCategories, setLocationCategories] = useState();
  const [selectedLocationCategory, setSelectedLocationCategory] = useState("All");

  const [selectedDashboardPeriod, setSelectedDashboardPeriod] = useState(0);
  const { events } = useGetSchedule("All", "week");

  //console.log(events)
  // dashboard period 6 = last 6 months
  // dashboard period 1 = current year
  // dashboard period 0 = all time


  useEffect(() => {

    setVisits([])

    async function getDetails() {


      if (!storeDetails.user_specific_stores || !storeDetails.user_specific_templates) return;
      // is this working
      const storeElements = storeDetails.stores; //await getStores();
      setLocationCategories([...new Set(storeDetails.user_specific_stores.map(store => store.region))]);
      setVisits([])
      await getVisits(storeElements, storeDetails.user_specific_templates, selectedDashboardPeriod);

      setShowLoading(false)

    }

    setShowLoading(true)
    if (storeDetails.user) getDetails();

  }, [])

  // useEffect(() => {
  // rest



  //     if(stores && templates) getVisits();

  // }, [stores, templates])

  useEffect(() => {

    setSeriesVisits([])
    setSeriesStores([]);
    setSeriesTemplates([])
    setRecentVisits([])

    if (visits.length > 0) {

      const visitsScorable = visits.filter(visit => visit.scorable);
      const score = visits && visitsScorable.map(visit => visit.score).reduce((total, current) => total + current, 0);

      const globalScore = Math.round(score / visitsScorable.length);
      if (visits && globalScore) setGlobalScore(globalScore);
      else setGlobalScore(0)



      // store performance chart
      let storePerformanceList = []
      let storePerformanceScore = [];
      selectedStoresInfo.filter(store => {
        if (selectedLocationCategory === "All") return true;
        return selectedLocationCategory === store.region
      }).forEach(store => {
        let total = 0;
        let noVisits = 0;



        selectedTemplatesInfo.forEach(template => {
          const visitList = visits.filter(visit => visit.store_id === store.cloud_id && visit.template_id === template.cloud_id && visit.scorable);

          if (visitList && visitList.length > 0) noVisits += 1;

          const storeScoreTotal = visitList.map(visitItem => visitItem.score).reduce((total, current) => total + current, 0);

          if (visits && visitList.length > 0)
            total = Math.round(total + (storeScoreTotal / visitList.length));
          else
            total = Math.round(total + storeScoreTotal)
        })

        if (noVisits > 0) {


          storePerformanceList.push(store.name)

          storePerformanceScore.push(`${Math.round((total / noVisits))}%`);
          setSeriesStores(prevState => [...prevState, {
            name: "Score",
            data: prevState.data ? [...prevState.data, `${Math.round((total / noVisits))}%`] : [total / noVisits]
          }]);
          // setSeriesStores(prevState => [...prevState, 
          // {
          //     data: prevState.data ? [...prevState.data, visitItem] : [visitItem]
          // }])
        }

      })

      setOptionsStores({
        chart: {
          type: 'bar',
          height: 350
        },
        grid: {
          padding: {
            top: 0,
            bottom: 0
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,

          }
        },
        noData: {
          text: 'Create scored visits to view this report',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Poppins, sans-serif'
          }
        },

        xaxis: {
          categories: storePerformanceList,
          tickPlacement: 'between',
          style: {
            fontSize: '14px',
            fontFamily: 'Poppins, sans-serif'
          }
        },

        dataLabels: {

          enabled: true,
          style: {
            fontFamily: "Poppins"
          },

          formatter: function (text, op) {
            return [Math.round(text) + "%"]
          },

        },

        legend: {
          show: false
        }




      })

      // template performance chart
      //     selectedTemplatesInfo.forEach(template => {
      //         let total = 0;
      //         let noVisits = 0;
      //         selectedStoresInfo.forEach(store => {
      //             const visitList = visits.filter(visit => visit.store_id === store.cloud_id && visit.template_id === template.cloud_id && visit.scorable);
      //             if(visitList && visitList.length > 0) noVisits += 1;

      //             const storeScoreTotal = visitList.map(visitItem => visitItem.score).reduce((total, current) => total+current, 0);

      //             if(visits && visitList.length > 0)
      //                 total = total + Math.round(storeScoreTotal / visitList.length);
      //             else
      //                 total = total + storeScoreTotal
      //         })

      //         if(noVisits > 0) {
      //             const visitItem = {
      //                 x: template.name,
      //                 y: total / noVisits
      //             }

      //             setSeriesTemplates(prevState => [...prevState, 
      //             {
      //                 data: prevState.data ? [...prevState.data, visitItem] : [visitItem]
      //             }])
      //         }

      //     })



      // setOptionsTemplates({
      //     chart: {

      //     type: 'treemap',
      //     dropShadow: {
      //       enabled: true,
      //       color: '#4361ee',
      //       top: 0,
      //       left: 0,
      //       blur: 3,
      //       opacity: 0.3
      //     }
      //     },
      //   title: {
      //     text: 'Template Performance',
      //     style: {
      //       fontSize:  '16px',
      //       fontWeight:  'bold',
      //       fontFamily:  'Poppins, sans-serif',

      //     },
      //   },
      //   noData: {
      //     text: 'Start generating visits to see this report',
      //     align: 'center',
      //     verticalAlign: 'middle',
      //     offsetX: 0,
      //     offsetY: 0,
      //     style: {
      //       fontSize: '14px',
      //       fontFamily: 'Poppins, sans-serif'
      //     }
      //   },
      //   dataLabels: {
      //     enabled: true,

      //     style: {

      //       fontSize: '13px',
      //     },
      //     formatter: function(text, op) {
      //       return [text, `${op.value}%`]
      //     },
      //     offsetY: -2
      //   },
      //   plotOptions: {
      //     treemap: {
      //       enableShades: true,
      //       shadeIntensity: 0.5,
      //       reverseNegativeShade: true,
      //       colorScale: {
      //         ranges: [
      //           {
      //             from: 1,
      //             to: 6,
      //             color: '#516be4'
      //           },
      //           {
      //             from: 7,
      //             to: 20,
      //             color: '#4361ee'
      //           }
      //         ]
      //       }
      //     }
      //   },
      //   legend: {
      //     show: false
      //   }
      // })

      async function getRecentVisits() {
        const dateLastWeek = moment().subtract("7", "d");

        selectedStoresInfo.forEach(store => {
          selectedTemplatesInfo.forEach(template => {
            const visitList = visits.filter(visit => visit.template_id === template.cloud_id
              && visit.store_id === store.cloud_id &&
              moment(visit.date).isAfter(dateLastWeek));

            if (visitList.length > 0) {
              visitList.forEach(visitItem => {

                const visitObject = {
                  visit_id: visitItem.id,
                  store_name: store.name,
                  key: visitItem.key,
                  template_name: template.name,
                  visit_date: moment(visitItem.date).format("DD-MM-YYYY"),
                  score: visitItem.scorable ? visitItem.score + "%" : "Not scorable",
                  visit_type: visitItem.visit_type,
                  visit_status: visitItem.visit_status,
                  actions_outstanding: visitItem.actions_outstanding
                }

                setRecentVisits(prevState => [...prevState, visitObject])
              })

            }


          })
        })
      }

      async function getActionInfo() {
        const actionDocs = await getActions();
        const userActions = getUserActions(actionDocs);
        getActionsDueCard(actionDocs);

      }

      getTemplatesUsedChart()
      getTemplatesScoreChart();
      getActionInfo()
      //getOutstandingActions();
      //getUserActions();
      getRecentVisits();
    }

  }, [visits, selectedTemplatesInfo, selectedLocationCategory])

  const handleClose = (event, reason) => {

    setOpenBar(false);
    setShowLoading(false)
  };


  function handleDashboardPeriodChange(value) {
    setSelectedDashboardPeriod(value)
    getVisits(stores, templates, value)
  }

  async function getVisits(stores, templates, period) {

    let templateIDList = [];
    let storeIDList = [];
    setVisits([]);
    setLogsOpen(0)
    setAuditsCompleted(0)
    console.log("Running get visits")

    if (selectedStores.length == 0 || selectedTemplates.length == 0) {

      if (selectedStores.length == 0) storeIDList = stores;
      else {
        storeIDList = stores.filter(store => (selectedStores.findIndex(x => x === store.name) >= 0)).map((value) => {
          return value;
        })
      }
      if (selectedTemplates.length == 0) templateIDList = templates;
      else {
        templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x === template.name) >= 0)).map((value) => {
          return value;
        })
      }

    }

    else {
      templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x === template.name) >= 0)).map((value) => {
        return value;
      })
      storeIDList = stores.filter(store => (selectedStores.findIndex(x => x === store.name) >= 0)).map((value) => {
        return value;
      })
    }

    setSelectedStoresInfo(storeIDList);
    setSelectedTemplatesInfo(templateIDList);
    //console.log("StoreID", storeIDList)
    //console.log("TemplateID", templateIDList)

    const currentDate = moment().add("1", "days").toISOString();
    let dateBefore = "";

    if (period === 0) {
      dateBefore = moment("2022-01-01").toISOString();
    } else if (period === 1) {
      dateBefore = moment().startOf('year').toISOString();

    } else if (period === 6) {
      dateBefore = moment().subtract("6", "months").toISOString();
    } else if (period === 7) {
      dateBefore = moment().subtract("8", "d").toISOString();
    }

    Promise.all(templateIDList.map(async template => {
      const promises = storeIDList.map(async store => {

        const q = query(
          collection(db, "stores/" + storeDetails.rootID + "/visits"),
          where("template_id", "==", template.cloud_id),
          where("store_id", "==", store.cloud_id),
          where("date", ">=", dateBefore),
          where("date", "<=", currentDate)
        );
        const visitDocs = await getDocs(q);
        return visitDocs.docs.map(async visit => {
          const visitData = visit.data();

          if (visitData.visit_type === "Audit") {
            setAuditsCompleted(prevState => prevState + 1);
          } else if (visitData.visit_type === "Log" && visitData.visit_status === "Open") {
            setLogsOpen(prevState => prevState + 1);
          }
          const sectionQueryCollectionRef = query(
            collection(db, "stores/" + storeDetails.rootID + "/visits/" + visit.id + "/sections"),
            orderBy("key", "asc")
          );
          const sectionDocs = await getDocs(sectionQueryCollectionRef);
          const sectionData = sectionDocs.docs.map(doc => {
            const docData = doc.data();
            return {
              name: docData.name,
              score: docData.score,
              list: docData.list
            };
          });
          setVisits(prevState => [
            ...prevState,
            {
              ...visitData,
              id: visit.id + visitData.startTime,
              name: store.name,
              key: visit.id,
              sections: sectionData,
              store_name: store.name,
              template_name: template.name,
              template_type: template.template_type,
              startTime: visitData.startTime,
              endTime: visitData.endTime,
              time: moment
                .duration(moment(visitData.startTime, "DD-MM-YYYY HH:mm").diff(moment(visitData.endTime, "DD-MM-YYYY HH:mm")))
                .humanize()
            }
          ]);
        });
      });
      const visits = await Promise.all(promises.flat());
      return visits.flat();
    })).then(visits => {
      const flattenedVisits = visits.flat();
      if (flattenedVisits.length === 0) {
        setEmptyVisits(true);
      }
    }).catch(error => {
      console.error(error);
    });
  }

  async function getTemplatesUsedChart() {

    // get the top 5 visits using the template_name from visits where the x is the template name and the y is the number of times the template was used

    const templatesUsed = visits.map(visit => visit.template_name);
    const templateList = [...new Set(templatesUsed)];
    const templateCount = templateList.map(template => {
      return {
        x: template,
        y: templatesUsed.filter(item => item === template).length
      };
    });
    const sortedTemplateCount = templateCount.sort((a, b) => b.y - a.y);
    const top5Templates = sortedTemplateCount.slice(0, 5);

    const top5TemplateNameArray = top5Templates.map(template => template.x);
    const top5TemplateCountArray = top5Templates.map(template => template.y);

    setSeriesVisits([{ data: top5TemplateCountArray }]);
    setOptionsVisits({
      chart: {
        type: 'bar',
        height: 400,
        width: '100%',
        fontFamily: "Poppins",
        fontWeight: "bold",
        fontSize: 12
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B'],
      },

      xaxis: {
        categories: top5TemplateNameArray,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins, sans-serif'
        }
      },
      noData: {
        text: 'You have not yet completed any visits.',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins, sans-serif'
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Poppins"
        },

      },



      tooltip: {
        x: {
          show: false, // Hide the default X-axis tooltip
        },
        y: {
          formatter: function (value) {
            return "Visits " + value.toFixed(0); // Format Y-axis tooltip labels for number of visits
          },
          title: {
            text: 'Number of Visits',
          },
        },
        marker: {
          show: true,
        },
        // Customize the series name (label) in the tooltip
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          // add the number of times the template was used to the tooltip

          return (
            '<div class="arrow_box">' +
            "<span>" +

            "Visits Complete: " + top5TemplateCountArray[dataPointIndex] +
            "</span>" +
            "</div>"
          );
        },
      },
    })


    // Generate a list of random colors
    // const colorList = Array.from({length: selectedTemplatesInfo.length}, getRandomColor);

    // // Map each template to a data point with a unique color
    // const visitData = selectedTemplatesInfo
    // .filter(template => {
    //     const visitList = visits.filter(visit => visit.template_id === template.cloud_id);
    //     const visitQty = visitList.length;
    //     return visitQty > 0;
    // })
    // .map((template, index) => {
    //     const visitList = visits.filter(visit => visit.template_id === template.cloud_id);
    //     const visitScores = visits.filter(visit => visit.template_id === template.cloud_id).map(item => item.score);
    //     const visitQty = visitList.length;
    //     let templateAvgScore = Math.round(visitScores.reduce((total, current) => total+current, 0) / visitQty);
    //     if(!templateAvgScore) templateAvgScore = 0;

    //     return {
    //       x: template.name + " " + templateAvgScore + "%",
    //       y: visitQty,
    //        // assign a unique color from the list
    //     };
    // });

    // setOptionsVisits({
    //   chart: {
    //     type: 'treemap'
    //   },
    //   title: {
    //     text: 'Visits Completed',
    //     style: {
    //       fontSize: '14px',
    //       fontFamily: 'Poppins, sans-serif',
    //     },
    //   },

    //   dataLabels: {
    //     enabled: true,
    //     style: {
    //       fontFamily: "Poppins",
    //       fontSize: '12px',

    //       fontWeight: '600',

    //     },
    //     formatter: function(text, op) {
    //       return [text, op.value];
    //     },
    //   },
    //   plotOptions: {
    //     treemap: {
    //       distributed: true,
    //       colorByPoint: true, // Enable colorByPoint
    //       colorOptions: {
    //         gradientToColors: [`${Colors.button_blue_gradient}`], // Specify the gradient color for the background
    //       },
    //     },
    //   },
    //   noData: {
    //     text: 'Start generating visits to see this report',
    //     align: 'center',
    //     verticalAlign: 'middle',
    //     offsetX: 0,
    //     offsetY: 0,
    //     style: {

    //       fontFamily: 'Poppins, sans-serif',
    //     },
    //   },
    // });

    // setSeriesVisits([{data: visitData}]);


  }

  async function getTemplatesScoreChart() {
    // get the top 5 visits using the template_name from visits where the x is the template name and the y is the number of times the template was used

    const templatesUsed = visits.filter(visit => visit.scorable).map(visit => visit.template_name);
    const templateList = [...new Set(templatesUsed)];
    const templateCount = templateList.map(template => {

      const visitsTemplate = visits.filter(visit => visit.template_name === template && visit.scorable);
      const templateScoreAverage = visitsTemplate.map(visit => visit.score).reduce((total, current) => total + current, 0) / visitsTemplate.length;
      const templateScoreAverageRounded = Math.round(templateScoreAverage);

      return {
        x: template,
        y: templateScoreAverageRounded
      };
    });
    const sortedTemplateCount = templateCount.sort((a, b) => b.y - a.y);
    const top5Templates = sortedTemplateCount.slice(0, 5);

    const top5TemplateNameArray = top5Templates.map(template => template.x);
    const top5TemplateCountArray = top5Templates.map(template => template.y);


    setSeriesTemplatesScore(top5TemplateCountArray)
    setOptionsTemplatesScore({
      chart: {
        width: 400,
        type: 'pie',
      },
      labels: top5TemplateNameArray,
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        }
      },
      pie: {
        offsetY: 50
      },
      dataLabels: {
        position: 'center',
        formatter(val, opts) {

          const name = opts.w.globals.labels[opts.seriesIndex]
          return ["Avg Score", top5TemplateCountArray[opts.seriesIndex] + "%"]
        },
        dropShadow: false,
        style: {
          fontSize: '12',
          fontFamily: 'Poppins, sans-serif'
        },

      },
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%"
        },
        position: 'bottom',
      },
      fill: {
        type: 'gradient',
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      noData: {
        text: 'You do not have any templates completed that are score based.',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins, sans-serif'
        }
      },



    })
  }

  async function getActionsDueCard(actionDocs) {
    const actionData = actionDocs.map(action => action.data);


    const actionItems = [];
    for (const actionItem of actionData) {

      const store = storeDetails.stores.find(store => store.name === actionItem.store_name)

      actionItems.push({
        action_id: actionItem.action_id,
        store_name: actionItem.store_name,
        template_name: actionItem.template_name,
        region: store?.region || "None",
        sub_region: store?.sub_region || "None",
        action_due_date: actionItem.action_due_date,
        actionBy: actionItem.actionByName,
        ageing: moment().diff(moment(actionItem.action_due_date), "days"),
        actionPriority: actionItem.action_priority,
        section_item_name: actionItem.section_item_name
      })
    }

    setActionItemsDue(actionItems);

  }



  async function getUserActions(actionDocs) {
    let userList = [];
    let actionList = [];

    actionDocs.forEach(obj => {
      const name = obj.data.name;


      // Check if the name already exists in the userList
      const index = userList.indexOf(name);
      if (index === -1) {
        // If the name doesn't exist, add it to userList and initialize the count in actionList
        userList.push(name);
        actionList.push(1);
      } else {
        // If the name already exists, increment the count in actionList at the corresponding index
        actionList[index]++;
      }
    });

    const meIndex = userList.indexOf(storeDetails.user.name);
    if (meIndex != -1) {
      const myCount = actionList[meIndex];
      if (myCount > 0) {
        setBarMessage({
          type: "error",
          message: `You have ${myCount} action(s) due.`,

        })
        setOpenBar(true)
      }
    }


    if (userList.length > 0) {
      setSeriesActions(actionList);
      setOptionsActions({
        chart: {

          type: 'donut',



        },
        stroke: {
          width: 0,
        },
        plotOptions: {
          pie: {
            offsetY: 50,

            donut: {
              labels: {
                show: true,

                total: {
                  showAlways: true,
                  show: true
                }
              }
            }
          }
        },

        dataLabels: {

          offsetY: 0,
          position: "bottom",
          style: {
            fontSize: '13px',
            fontFamily: 'Poppins, sans-serif'
          }
        },
        labels: userList,
        noData: {
          text: 'There are no actions outstanding.',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Poppins, sans-serif'
          }
        },



        title: {
          text: "Actions Outstanding",

          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Poppins, sans-serif',

          },

        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]


      })
    }


  }

  async function getActions() {

    const actionsRef = collection(db, "stores/" + storeDetails.rootID + "/actions");
    const actionDocs = [];
    let actionCount = 0;
    let actionsDue = 0;

    if (storeDetails.user.admin) {
      const query1 = query(actionsRef, where("actionComplete", "==", false), where("action_due_date", "<=", new Date().toISOString()));
      const snapshot = await getDocs(query1);
      actionCount = snapshot.docs.length;

      snapshot.docs.forEach(doc => {
        const data = doc.data();

        actionDocs.push({
          id: doc.id,
          data: { ...data, name: storeDetails.all_users.find(user => user.uid === data.actionBy).name }
        })
        actionsDue++;
      })
    }
    else if (storeDetails.user.type === "manager") {
      //const userData = storeDetails.filtered_users;
      const groupUsers = storeDetails.filtered_users //userData.filter(user => storeDetails.user.group_manager.includes(user.group))
      const groupUserUID = groupUsers.map(user => user.uid);

      //const query1 = query(actionsRef, where("action_set_by", "==", storeDetails.user.uid), where("actionComplete", "==", false));
      const query1 = query(actionsRef, where("actionBy", "==", storeDetails.user.uid), where("actionComplete", "==", false), where("action_due_date", "<=", new Date().toISOString()));
      const query2 = query(actionsRef, where("actionComplete", "==", false), where("actionBy", "in", groupUserUID), where("action_due_date", "<=", new Date().toISOString()));
      //const query4 = query(actionsRef, where("actionComplete", "==", false), where("action_set_by", "in", groupUserUID));

      //const querySnapshot1 = await getDocs(query1);
      const querySnapshot1 = await getDocs(query1);
      const querySnapshot2 = await getDocs(query2);

      const mergedDocs = [...querySnapshot1.docs, ...querySnapshot2.docs];

      mergedDocs.forEach((doc) => {
        const data = doc.data();

        if (!actionDocs.find((item) => item.data.action_id === data.action_id)) {
          actionCount++;
          actionDocs.push({
            id: doc.id,
            data: {
              ...data,
              name: storeDetails.all_users.find((user) => user.uid === data.actionBy).name,
            },
          });
          actionsDue++;
        }
      });


    }
    else {
      const query1 = query(actionsRef, where("action_set_by", "==", storeDetails.user.uid), where("actionComplete", "==", false), where("action_due_date", "<=", new Date().toISOString()));
      const query2 = query(actionsRef, where("actionBy", "==", storeDetails.user.uid), where("actionComplete", "==", false), where("action_due_date", "<=", new Date().toISOString()));

      const querySnapshot1 = await getDocs(query1);
      const querySnapshot2 = await getDocs(query2);

      const mergedDocs = [...querySnapshot1.docs, ...querySnapshot2.docs];

      mergedDocs.forEach((doc, i) => {

        const data = doc.data();

        if (actionDocs.findIndex(action => action.data.action_id === data.action_id) === -1) {
          actionCount++;
          actionDocs.push({
            id: doc.id,
            data: { ...data, name: storeDetails.all_users.find(user => user.uid === data.actionBy).name }
          })
          actionsDue++;
        }
      })
    }

    setMyActions(actionCount)
    setOutstandingActions(actionsDue);

    return actionDocs;
  }




  function DataGridTitle() {
    return (
      <BlueContainer><StyledVisitIcon style={{ 'marginRight': '10px' }} sx={{ 'color': `${Colors.white}` }} /> Recent visit overview</BlueContainer>
    )
  }

  const handleRowClick = (params) => {
    // console.log("You clicked " + params.row.store_id + " on " + params.row.visit_date)
    navigate("/app/storevisit/report/" + params.row.key);
  };

  function navigateVisitsActions() {
    navigate("/app/actions")
  }

  function navigateAudits() {
    setShowVisitType("Audit")
    navigate("/app/storevisit")
  }

  function navigateLogs() {
    setShowVisitType("Log");
    navigate("/app/storevisit")
  }

  function navigateReports() {
    navigate("/app/reports")
  }



  const getRowClassName = (row) => {

    if (row.row.visit_type === "Log" && row.row.visit_status === "Open") return classes.highValue;

    if (row.row.score === "Not scorable") return classes.lowValue;

    const score = parseInt(row.row.score.replace("%", ""))
    return (score <= 70 || row.row.actions_outstanding > 0) ? classes.highValue : classes.lowValue;
  };

  const handleLocationCategoryChange = (event, value) => {

    setSelectedLocationCategory(event.target.value);
  };

  const actionButton = (
    <Button onClick={() => navigate("/app/actions/" + storeDetails.user.uid)} color="white" textColor="black">View</Button>
  );

  return (
    <PageHolder>
      <Snackbar open={openBar} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          action={actionButton}
          severity={barMessage.type}
          sx={{ width: '100%', display: 'flex', 'alignItems': 'center' }}>
          {barMessage.message}
        </Alert>
      </Snackbar>
      <DashboardHeaderContainer expanded={showDashboardOptions}>
        <DashboardHeader>
          {storeDetails.user.name ? `${storeDetails.user.name}'s Dashboard` : ""}
          <StyledMoreIcon fontSize='large' onClick={() => setShowDashboardOptions(prevState => !prevState)} />
        </DashboardHeader>
        {showDashboardOptions &&
          <DashboardOptionsContainer>
            <DashboardRadioGroupContainer>
              <DashboardRadioGroup>
                <DashboardRadioOption>

                  <DashboardRadioLabel>
                    <DashboardRadioButton onChange={() => handleDashboardPeriodChange(0)} checked={selectedDashboardPeriod === 0} type="radio" name="time-period" value={0} />
                    All time

                  </DashboardRadioLabel>
                </DashboardRadioOption>


                <DashboardRadioOption>

                  <DashboardRadioLabel>
                    <DashboardRadioButton onChange={() => handleDashboardPeriodChange(1)} checked={selectedDashboardPeriod === 1} type="radio" name="time-period" value={1} />
                    This year

                  </DashboardRadioLabel>
                </DashboardRadioOption>


                <DashboardRadioOption>

                  <DashboardRadioLabel>
                    <DashboardRadioButton onChange={() => handleDashboardPeriodChange(6)} checked={selectedDashboardPeriod === 6} type="radio" name="time-period" value={6} />
                    Last 6 months

                  </DashboardRadioLabel>
                </DashboardRadioOption>


                <DashboardRadioOption>

                  <DashboardRadioLabel>
                    <DashboardRadioButton onChange={() => handleDashboardPeriodChange(7)} checked={selectedDashboardPeriod === 7} type="radio" name="time-period" value={7} />
                    Last 7 days

                  </DashboardRadioLabel>
                </DashboardRadioOption>




              </DashboardRadioGroup>
            </DashboardRadioGroupContainer>


          </DashboardOptionsContainer>}
      </DashboardHeaderContainer>
      <PageContainer empty_shadow={true} style={{ 'padding': '0px' }}>
        <Loader show={showLoading} />

        <DashboardContainer>
          <DashboardCard background={Colors.button_blue_gradient} onClick={navigateAudits}>
            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <StyledVisitIcon fontSize="large" style={{ backgroundColor: `${Colors.blue_100}`, borderRadius: '50%', padding: '5px' }} color={Colors.dark_blue} />
              <DashboardItem figure>{auditsCompleted}</DashboardItem>
            </DivHolder>

            <DashboardItem style={{fontSize: '0.7rem'}}>Audits Completed</DashboardItem>
          </DashboardCard>

          <DashboardCard background={Colors.button_orange_gradient} onClick={navigateLogs}>

            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <StyledLogIcon style={{ fontSize: '2rem', backgroundColor: `${Colors.blue_100}`, borderRadius: '50%', padding: '5px' }} color={Colors.dark_blue} />
              <DashboardItem figure>{logsOpen}</DashboardItem>
            </DivHolder>


            <DashboardItem style={{fontSize: '0.7rem'}}>Logs Open</DashboardItem>
          </DashboardCard>

          <DashboardCard background={Colors.button_red_gradient} onClick={navigateVisitsActions}>

            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <StyledActionIcon style={{ fontSize: '2rem', backgroundColor: `${Colors.blue_100}`, borderRadius: '50%', padding: '5px' }} color={Colors.dark_blue} />
              <DashboardItem figure>{myActions}</DashboardItem>
            </DivHolder>


            <DashboardItem style={{fontSize: '0.7rem'}}>Actions Outstanding</DashboardItem>
          </DashboardCard>

          <DashboardCard background={Colors.button_green_gradient} onClick={navigateReports}>
            <DivHolder style={{ gap: '10px', marginBottom: '5px' }}>
              <StyledScoreIcon style={{ fontSize: '2rem', backgroundColor: `${Colors.blue_100}`, borderRadius: '50%', padding: '5px' }} color={Colors.dark_blue} />
              <DashboardItem figure>{globalScore}%</DashboardItem>
            </DivHolder>

            <DashboardItem style={{fontSize: '0.7rem'}}>Score Average</DashboardItem>
          </DashboardCard>
        </DashboardContainer>

        {/* <DashboarChartContainer>
              <Chart
              type="bar"
              series={seriesVisitsScores}
              options={optionVisitsScores}
              height="400" />
            </DashboarChartContainer> */}
        {visits && visits.length > 0 &&
          <DashboardChartHolder>

            <DashboardChartRow style={{ margin: '20px 0px' }}>
              <ColumnContainer>

                <Text style={{ 'marginBottom': '5px' }} fontSize={"0.9rem"} fontWeight={700}>Frequently Used Templates</Text>
                <DashboarChartContainer style={{  'margin': '10px 0' }}>

                  <Chart
                    type="bar"
                    height="400"
                    style={{ flex: 1 }}

                    options={optionsVisits}
                    series={seriesVisits} />
                </DashboarChartContainer>
              </ColumnContainer>

              <ColumnContainer>
                <Text style={{ height: 'fit-content', 'flex': 'none', 'marginBottom': '5px' }} fontSize={"0.9rem"} fontWeight={700}>This Weeks Schedule</Text>
                <DashboarChartContainer style={{ alignItems: 'flex-start', flex: 1, padding: '0px', 'margin': '0px' }}>
                  <HomeWeekEvents events={events} storeDetails={storeDetails} />
                </DashboarChartContainer>
              </ColumnContainer>



            </DashboardChartRow>

            <HomeCardActionsDue actionItems={actionItemsDue} />

            {recentVisits &&
              <ColumnContainer style={{marginTop: '10px'}}>
                <Text style={{ height: 'fit-content', 'flex': 'none', 'margin': '5px 0px' }} fontSize={"0.9rem"} fontWeight={700}>Recently Completed</Text>
                <DashboarChartContainer style={{ padding: '0px', 'margin': '10px 0px' }}>

                  <DataGrid
                    autoHeight

                    disableSelectionOnClick
                    sx={{ 'border': '0' }}


                    components={{

                      body: {
                        row: ({ row }) => (
                          <RowHighlighted isHighlighted={row.row.score === '0%'}>
                            {row.cells}
                          </RowHighlighted>
                        ),
                      },
                    }}
                    rows={recentVisits}
                    //rows={filterActions===true ? storeVisits.filter(visit => visit.actions_outstanding > 0) : storeVisits}
                    columns={columns}
                    onRowClick={handleRowClick}
                    getRowClassName={getRowClassName}

                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'visit_date', sort: 'desc' }],
                      },
                    }}
                    getRowId={(row) => row.visit_id}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                  />
                </DashboarChartContainer>
              </ColumnContainer>
            }

            <DashboardChartRow style={{ 'marginTop': '20px' }}>
              <ColumnContainer>
                <DivHolder>
                <Text style={{ height: 'fit-content', 'flex': 'none'}} fontSize={"0.9rem"} fontWeight={700}>Location Performance</Text>
                <Select
                    labelId="location-category-label"
                    id="location-category"
                    fullWidth
                    sx={{ maxWidth: '500px' }}
                    size='small'
                    value={selectedLocationCategory}
                    onChange={handleLocationCategoryChange}

                    style={{
                      fontFamily: "Poppins",
                      borderRadius: '10px',
                      borderColor: `${Colors.blue}`,
                      borderWidth: '1px',
                      boxShadow: `rgba(0, 0, 0, 0.05) 0px 0px 0px 1px`
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {locationCategories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </DivHolder>
                
                {/* <TemplateFormFieldRow style={{ marginBottom: '10px' }}>
                  <Text fontWeight="500" fontSize="0.8rem" style={{ 'flex': 'none', 'margin': '0px', marginRight: '10px', minWidth: '100px' }}>Select a location group</Text>

                  

                </TemplateFormFieldRow> */}

                <DashboarChartContainer style={{ padding: '5px', 'margin': '10px 0px' }}>



                  <Chart
                    type="bar"
                    height="400"
                    style={{ flex: 1 }}

                    options={optionsStores}
                    series={seriesStores} />
                </DashboarChartContainer>
              </ColumnContainer>
            </DashboardChartRow>

            {optionsTemplatesScore &&
              <ColumnContainer>
                <Text style={{ height: 'fit-content', 'flex': 'none', 'marginBottom': '5px' }} fontSize={"0.9rem"} fontWeight={700}>Template Average Scores</Text>
                <DashboarChartContainer style={{ padding: '0px', 'margin': '10px 0px' }}>
                  <Chart
                    type="pie"
                    height="400"
                    style={{ flex: 1 }}
                    options={optionsTemplatesScore}
                    series={seriesTemplatesScore} />
                </DashboarChartContainer>
              </ColumnContainer>

            }




            {/* <DashboarChartContainer>
            <Chart
            type="treemap"
            height="400"
            options={optionsTemplates}
            series={seriesTemplates} />
            </DashboarChartContainer> */}
          </DashboardChartHolder>}

      </PageContainer>
      {emptyVisits && storeDetails.user.admin &&
        <HelloScreen />}


    </PageHolder>


  )
}

export default Home