import styled from "styled-components";
import { Colors } from "./styled.container";

export const SettingsItemHolder = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    position: relative;
`

export const SettingsText = styled.p`
    font-size: 0.7rem;
    margin-right: 40px;
    flex: 1;
    text-align: left;
`

export const SettingsInput = styled.input`
    border: 0;
    outline: 0;
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background-color: ${Colors.white};
    font-size: 0.8rem;
`

export const SettingsButton = styled.button`
    background-color: ${Colors.blue};
    width: fit-content;
    outline: 0;
    border: 0;
    padding: 10px 30px;
    text-align: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: inline-block;
    font-size: 0.8rem;
    cursor: pointer;
    
    color: ${Colors.white};
   
    ${({greyed}) => greyed && `
        background-color: ${Colors.light_grey};
        color: ${Colors.dark_grey};
        cursor: not-allowed;
    `}
`

export const SettingsCheckGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const SettingsCheckLabel = styled.label`
  font-size: 0.9rem;
  
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SettingsCheckButton = styled.input`
  margin-right: 10px;
  width: 16px;
  height: 16px;
  font-size: 0.8rem;
  cursor: pointer;
  

`;

export const SettingsCheckOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const SettingsTemplateContainer = styled.div`
    padding: 10px 0px;
    width: 100%;
`