import React, {Fragment, useEffect, useState} from 'react'
import { HeaderTitle, PageContainer, TableContainer, Button, GroupHeader, GroupItems } from './styled/styled.container'
import { db } from "./firebase";
import {collection, onSnapshot, query, doc, where, getDocs, orderBy, getDoc} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFilterContainer } from './styled/styled.templateform';
import Checkbox from '@mui/material/Checkbox';
import Chart from "react-apexcharts";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment/moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from './Loader';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const columns = [
  { field: "id", headerName: "ID", hide: true},
  { field: "cloud_id", headerName: "Cloud_ID", hide: true},
  { field: 'storeName', headerName: 'Location', minWidth: 150, flex: 1},
  { field: 'region', headerName: 'Location Group', minWidth: 150, flex: 1},
  { field: 'sub_region', headerName: 'Sub Group', minWidth: 150, flex: 1},
  { field: 'templateName', headerName: 'Template', minWidth: 150, flex: 1},
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 100,
    flex: 1

  },
  {
    field: "section", headerName: "Section",
    minWidth: 150,
    flex: 1
  },
  {
      field: "field", headerName: "Field",
      minWidth: 200,
      flex: 1

  },
  {
    field: "field_value", headerName: "Field Value",
    minWidth: 200,
    flex: 1
  },
  {
    field: "additional_field_value", headerName: "Additional",
    minWidth: 150,
    flex: 1
  },
  {
    field: "analysis_field", headerName: "Analysis Field",
    minWidth: 200,
    flex: 1
  },
  {
    field: "analysis_value", headerName: "Analysis Value",
    minWidth: 200,
    flex: 1
  },
  {
    field: "additional_analysis_value", headerName: "Additional Analysis",
    minWidth: 150,
    flex: 1
  }
];

function ReportFieldAnalysis(props) {
    const {storeDetails, stores, templates} = props;

    // holds selected stores and template names only
    const [selectedStores, setSelectedStores] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [targetFields, setTargetFields] = useState([])
    const [selectedTargetFields, setSelectedTargetFields] = useState([])
    const [analysisFields, setAnalysisFields] = useState([])
    const [selectedAnalysisFields, setSelectedAnalysisFields] = useState([])
    // holds selected store name + cloud id
    const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
    const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
    const [visits, setVisits] = useState();
    const [series, setSeries] = useState([]);
    
    const [options, setOptions] = useState({});
    const [filters, setFilters] = useState(true);
    const [filterActions, setFilterActions] = useState(false);
    const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
    const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
    const navigate = useNavigate();

    const [chartData, setChartData] = useState({
      options: {},
      series: [],
    });

      // useEffect(() => {

      //   if(visits && visits.length > 0) {

      //       // sort the list so that points are projected in order
      //       let visitDates = [...visits].sort((a,b) => {
      //         //console.log(a.date, b.date, moment(a.date).isBefore(moment(b.date)))
      //         //console.log(moment(a.date).isValid())
      //         if(moment(a.date, "DD-MM-YYYY").isBefore(moment(b.date, "DD-MM-YYYY"))) return -1;
      //         else if(moment(a.date, "DD-MM-YYYY").isAfter(moment(b.date, "DD-MM-YYYY"))) return 1;
      //         else return 0;
      //       }).map(visit => visit.date)

      //       // remove duplicates from different visits -> templates
      //       // eg: Store Visit, Regional Visit and PS, Bizana
      //       visitDates = [...new Set(visitDates)]

      //       setSeries([])
      //       selectedTemplatesInfo.forEach(templateElement => {
      //           selectedStoresInfo.forEach((storeElement, i) => {

      //               const visitScores = visitDates.map(date => {
      //                   const scoreElement = visits.filter(visit => visit.store_id === storeElement.cloud_id && visit.date === date && visit.template_id === templateElement.cloud_id)

      //                   if(scoreElement.length > 0) return scoreElement[0].score;
      //                   else return 0;
      //               })

      //               // check if any valid score for that template -> store
      //               const scoresEmpty = visitScores.every(element => element === null);
      //               if(!scoresEmpty) {
      //                   const element = {
      //                       name: storeElement.name + " (" + templateElement.name + ")",
      //                       data: visitScores
      //                   }

      //                   setSeries(prevState => [...prevState, element])

      //               }

      //           })
      //       })

      //       const visitOptions = {
      //         chart: { id: 'bar-chart'},

      //           xaxis: {
      //               categories: visitDates
      //           },
      //           stroke: {
      //             curve: "smooth",
      //           },
      //           title: {
      //             text: "Field Analysis"
      //           },
      //       }

      //       setOptions(visitOptions)
      //       // const sortedCategories = visitOptions.xaxis.categories.sort();
      //       // setOptions({
      //       //   ...visitOptions,
      //       //   xaxis: {
      //       //     ...visitOptions.xaxis,
      //       //     categories: sortedCategories,
      //       //   },
      //       // })
      //   }

      // }, [visits])

      useEffect(() => {

        async function getTemplateFields() {
            if(selectedTemplates.length > 0) {

                const targetFields_list = [];
                const analysisFields_list = [];

                const template = templates.filter(template => template.name === selectedTemplates[0])[0];

                const templateSectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/templates/" + template.cloud_id + "/sections")) 
                const sectionDocs = await getDocs(templateSectionRef);
                sectionDocs.docs.forEach(section => {
                    const sectionData = section.data();
                    sectionData.list.forEach(item => {

                        if(item.type !== "None") {
                          targetFields_list.push({
                              section: sectionData.name,
                              field: item.name
                          })

                        }

                        // for now only allow analysis on number fields
                        // if it works adapt for other fields
   
                        // if(item.type==="Item Comment" || item.type === "Item List") {
                        //     item.item_list_comment.forEach(element => {
                        //         analysisFields.push(element.product)
                        //     })
                        // }
                        // else if(item.type === "Item Count") {
                        //     item.counts.forEach(element => {
                        //         analysisFields.push(element.product)
                        //     })
                        // }
                        // else analysisFields.push(item.name)
                    })
                })

                setTargetFields(targetFields_list)
                setAnalysisFields(targetFields_list)
            }
        }

        getTemplateFields();
        
      }, [selectedTemplates])

      async function getVisits() {
        //console.log("Running report")
        let templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x === template.name) >= 0)).map((value) =>
        {
            return value.cloud_id;
        })
        
        let storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name === store.name) >= 0)).map((value) =>
        {
            return value;
        })
        setVisits([]);

          setSelectedStoresInfo(storeIDList);
          setSelectedTemplatesInfo(templateIDList);
          const dateTo = moment(filterDateTo).add("1", "days").toISOString();

          //console.log("StoreID", storeIDList)
          //console.log("TemplateID", templateIDList)

          const elements = [];
          const visitItems = [];

          await Promise.all(storeIDList.map(store => {
            return Promise.all(templateIDList.map(template => {
              return new Promise(async (resolve) => {
                const visitRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits"), 
              where("template_id", "==", template),
              where("store_id", "==", store.cloud_id),
              where("date", ">=", filterDateFrom),
                where("date", "<=", dateTo));

                const visitDocs = await getDocs(visitRef);
                const mainArray = await Promise.all(
                  visitDocs.docs.map(async visit => {
                    return new Promise(async (resolve, reject) => {
                      const visitData = visit.data();
                      
                      const templateName = templates.filter(template => template.cloud_id === visitData.template_id)[0].name;
                      //const storeName = stores.filter(store => store.cloud_id === visitData.store_id)[0].name;
                      const visitDate = moment(visitData.date).format("DD-MM-YYYY HH:mm");
          
                      const sectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visit.id + "/sections"))
                      const sectionDocs = await getDocs(sectionRef)
                      const sectionData = sectionDocs.docs.map(section => {
                        const item = section.data();
                        return item;
                      })

                      //console.log("Section", sectionData)
          
                      const visitElement = []
          
                      selectedTargetFields.forEach((fieldName) => {
                        
                        // Find the section that contains this field
                        const section = sectionData.find((section) => section.list.some((field) => {

                          return field.name === fieldName.field 
                        }));
                        
                        // If no section was found, skip this field
                        if (!section) {
                          console.log("Did not find section")
                          return;
                          
                        }
                  
                        // Find the list item that contains this field
                        const listItem = section.list.find((field) => field.name === fieldName.field);
                  
                        // If no list item was found, skip this field
                        if (!listItem) {
                          console.log("Did not find item")
                          return;
                        }

                        //console.log("Found list item", listItem)
                  
                        // If the field type is "selection", use the selectionOption field
                        let fieldText = "";
                        let fieldValue = ""

                        if(listItem.type === "YesNo") 
                        {
                          fieldText = listItem.name;
                          
                          fieldValue = listItem.yesno;
                        }
                        else if(listItem.type === "Comment") {
                          fieldText = listItem.fieldText;
                          fieldValue = listItem.fieldText;
                        }
                        else if(listItem.type === "Number") {
                          fieldText = visitDate;
                          fieldValue = listItem.fieldText;
                        } else if(listItem.type === "Selection") {
                          fieldText = listItem.fieldText;
                          fieldValue = listItem.selectionOption
                        }else if(listItem.type === "DateTime" || listItem.type === "Time") {
                          fieldText = visitDate;
                          fieldValue = 1;
                        }

                        if(listItem.type === "YesNo" || listItem.type === "Comment" || listItem.type === "Selection" ||
                        listItem.type === "DateTime" || listItem.type === "Time" || listItem.type === "Number") {
                          visitElement.push({
                            storeName: store.name,
                            region: store.region,
                            sub_region: store.sub_region,
                            templateName: templateName,
                            date: visitDate,
                            target_field: fieldValue
                          })

                          elements.push({
                            storeName: store.name,
                            region: store.region,
                            sub_region: store.sub_region,
                            templateName: templateName,
                            date: visitDate,
                            target_field: fieldText,
                            additional_field_value: parseInt(fieldValue) || 1
                          })

                          if(selectedAnalysisFields.length === 0) {

                            visitItems.push({
                              id: moment().valueOf() * Math.floor(Math.random() * 1000),
                              cloud_id: visit.id,
                              storeName: store.name,
                              region: store.region,
                              sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              section: section.name,
                              field: listItem.name,
                              field_value: fieldValue
                            })
                          } else {
                            selectedAnalysisFields.forEach(analysisField => {
                              const analysisSection = sectionData.find((section) => section.list.some((field) => {
                                return field.name === analysisField.field 
                              }));
                              
                              // If no section was found, skip this field
                              if (!analysisSection) {
                                return;
                              }
                        
                              // Find the list item that contains this field
                              const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);
                        
                              // If no list item was found, skip this field
                              if (!analysisField) {
                                return;
                              }

                              let analysisValue = ""
                              if(analysisItem.type === "YesNo") analysisValue = analysisItem.yesno;
                              else if(analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                              else if(analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                              else if(analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                              if(analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                                analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time")
                              {
                                visitItems.push({
                                  id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                  cloud_id: visit.id,
                                  storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                  templateName: templateName,
                                  date: visitDate,
                                  section: section.name,
                                  field: listItem.name,
                                  field_value: fieldValue,
                                  analysis_field: analysisItem.name,
                                  analysis_value: analysisValue
                                })
                              }
                              else if(analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                                analysisItem.item_list_comment.forEach(newItem => {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: fieldValue,
                                    analysis_field: analysisItem.name,
                                    analysis_value: newItem.product,
                                    additional_analysis_value: newItem.comment ? newItem.comment : ""
                                  })
                                })
                              }
                              else if(analysisItem.type === "List Checkbox") {
                                analysisItem.item_list_checkbox.forEach(newItem => {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: fieldValue,
                                    analysis_field: analysisItem.name,
                                    analysis_value: newItem.product,
                                    additional_analysis_value: newItem.checked ? "Yes" : "No"
                                  })
                                })
                              }
                              else if(analysisItem.type === "Item Count") {
                                analysisItem.counts.forEach(newItem => {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: fieldValue,
                                    analysis_field: analysisItem.name,
                                    analysis_value: newItem.product,
                                    additional_analysis_value: newItem.count[0] ? newItem.count[0].value : ""
                                  })
                                })
                              }
                              else if(analysisItem.type === "Item Double Count") {
                                analysisItem.counts.forEach(newItem => {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: fieldValue,
                                    analysis_field: analysisItem.name,
                                    analysis_value: newItem.product,
                                    additional_analysis_value: newItem.count ? `(${newItem.count[0].value}, ${newItem.count[1].value})` : ""
                                  })
                                })
                              }

                              // if(analysisItem.type === "Number") {
                              //   elements.push({
                              //     storeName: storeName,
                              //     templateName: templateName,
                              //     date: visitDate,
                              //     target_field: fieldValue,
                              //     analysis_field: analysisValue
                              //   })
                              // }
                              // else {
                              //   elements.push({
                              //     storeName: storeName,
                              //     templateName: templateName,
                              //     date: visitDate,
                              //     target_field: fieldValue
                              //   })
                              // }
                            })
                          }
                        }
                        else if(listItem.type === "Item List" || listItem.type === "Item Selection" || listItem.type === "Item Comment") {
                          listItem.item_list_comment.forEach(item => {
                            visitElement.push({
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: item.product,
                              target_field_count: 1
                            })

                            elements.push({
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: item.product,
                              additional_field_value: 1
                            })
                
                            if(selectedAnalysisFields.length === 0) 
                            {
                              

                              visitItems.push({
                                id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                cloud_id: visit.id,
                                storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                templateName: templateName,
                                date: visitDate,
                                section: section.name,
                                field: listItem.name,
                                field_value: item.product,
                                additional_field_value: item.comment ? item.comment : ""
                              })
                            } else {
                              selectedAnalysisFields.forEach(analysisField => {
                                const analysisSection = sectionData.find((section) => section.list.some((field) => {
                                  return field.name === analysisField.field 
                                }));
                                
                                // If no section was found, skip this field
                                if (!analysisSection) {
                                  return;
                                }
                          
                                // Find the list item that contains this field
                                const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);
                          
                                // If no list item was found, skip this field
                                if (!analysisField) {
                                  return;
                                }
  
                                let analysisValue = ""
                                if(analysisItem.type === "YesNo") analysisValue = analysisItem.yesno;
                                else if(analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                                else if(analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                                else if(analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                                if(analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                                analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time")
                                {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: item.product,
                                    additional_field_value: item.comment ? item.comment : "",
                                    analysis_field: analysisItem.name,
                                    analysis_value: analysisValue
                                  })
                                }
                                else if(analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                                  analysisItem.item_list_comment.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.comment ? item.comment : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.comment ? newItem.comment : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.comment ? item.comment : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? newItem.count[0].value : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Double Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.comment ? item.comment : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? `(${newItem.count[0].value}, ${newItem.count[1].value})` : ""
                                    })
                                  })
                                }

                                // if(analysisItem.type === "Number") {
                                //   elements.push({
                                //     storeName: storeName,
                                //     templateName: templateName,
                                //     date: visitDate,
                                //     target_field: item.product,
                                //     analysis_field: analysisValue 
                                //   })
                                // }
                                // else {
                                //   elements.push({
                                //     storeName: storeName,
                                //     templateName: templateName,
                                //     date: visitDate,
                                //     target_field: item.product
                                //   })
                                // }
                              })
                            }
                          })
                        }
                        else if(listItem.type === "Item Count") {
                          listItem.counts.forEach(item => {
                            visitElement.push({
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: item.count[0].value
                            })
                            
                            elements.push({
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: item.product,
                              additional_field_value: item.count[0].value
                            })

                            if(selectedAnalysisFields.length === 0) 
                            {
                              //console.log("Item", item.product, item.count);
                              if(item.count && item.count > 0) {
                                
  
                                visitItems.push({
                                  id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                  cloud_id: visit.id,
                                  storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                  templateName: templateName,
                                  date: visitDate,
                                  section: section.name,
                                  field: listItem.name,
                                  field_value: item.product,
                                  additional_field_value: item.count ? item.count[0].value : ""
                                })
                              }
                              
                            } 
                            else 
                            {
  
                              selectedAnalysisFields.forEach(analysisField => {
                                const analysisSection = sectionData.find((section) => section.list.some((field) => {
                                  return field.name === analysisField.field 
                                }));
                                
                                // If no section was found, skip this field
                                if (!analysisSection) {
                                  return;
                                }
                          
                                // Find the list item that contains this field
                                const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);
                          
                                // If no list item was found, skip this field
                                if (!analysisField) {
                                  return;
                                }
  
                                let analysisValue = ""
                                if(analysisItem.type === "YesNo") analysisValue = analysisItem.yesno;
                                else if(analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                                else if(analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                                else if(analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                                if(analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                                analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time")
                                {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: item.product,
                                    additional_field_value: item.count ? item.count : "",
                                    analysis_field: analysisItem.name,
                                    analysis_value: analysisValue
                                  })
                                }
                                else if(analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                                  analysisItem.item_list_comment.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.count ? item.count : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? newItem.count : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.count ? item.count : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? newItem.count[0].value : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Double Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.count ? item.count : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? `(${item.count[0].value}, ${item.count[1].value})` : ""
                                    })
                                  })
                                }

                                // if(analysisItem.type === "Number") {
                                //   elements.push({
                                //     storeName: storeName,
                                //     templateName: item.product,
                                //     date: visitDate,
                                //     target_field: item.count,
                                //     analysis_field: analysisValue
                                //   })
                                // }
                                // else {
                                //   elements.push({
                                //     storeName: storeName,
                                //     templateName: item.product,
                                //     date: visitDate,
                                //     target_field: item.count
                                //   })
                                // }
                              })
                            }
                          })
                        }
                        else if(listItem.type === "Item Double Count") {
                          listItem.counts.forEach(item => {
                            visitElement.push({
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: `(${item.count[0].value}, ${item.count[1].value})`
                            })
                            
                            elements.push({
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: item.product,
                              additional_field_value: `(${item.count[0].value}, ${item.count[1].value})`
                            })

                            if(selectedAnalysisFields.length === 0) 
                            {
                              //console.log("Item", item.product, item.count);
                              if(item.count && item.count > 0) {
                                
  
                                visitItems.push({
                                  id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                  cloud_id: visit.id,
                                  storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                  templateName: templateName,
                                  date: visitDate,
                                  section: section.name,
                                  field: listItem.name,
                                  field_value: item.product,
                                  additional_field_value: item.count ? `(${item.count[0].value}, ${item.count[1].value})` : ""
                                })
                              }
                              
                            } 
                            else 
                            {
  
                              selectedAnalysisFields.forEach(analysisField => {
                                const analysisSection = sectionData.find((section) => section.list.some((field) => {
                                  return field.name === analysisField.field 
                                }));
                                
                                // If no section was found, skip this field
                                if (!analysisSection) {
                                  return;
                                }
                          
                                // Find the list item that contains this field
                                const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);
                          
                                // If no list item was found, skip this field
                                if (!analysisField) {
                                  return;
                                }
  
                                let analysisValue = ""
                                if(analysisItem.type === "YesNo") analysisValue = analysisItem.yesno;
                                else if(analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                                else if(analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                                else if(analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                                if(analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                                analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time")
                                {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: item.product,
                                    additional_field_value: item.count ? `(${item.count[0].value}, ${item.count[1].value})` : "",
                                    analysis_field: analysisItem.name,
                                    analysis_value: analysisValue
                                  })
                                }
                                else if(analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                                  analysisItem.item_list_comment.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.count ? item.count : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? newItem.count : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.count ? item.count[0].value : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? newItem.count[0].value : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Double Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.count ? `(${item.count[0].value}, ${item.count[1].value})` : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? `(${item.count[0].value}, ${item.count[1].value})` : ""
                                    })
                                  })
                                }

                                // if(analysisItem.type === "Number") {
                                //   elements.push({
                                //     storeName: storeName,
                                //     templateName: item.product,
                                //     date: visitDate,
                                //     target_field: item.count,
                                //     analysis_field: analysisValue
                                //   })
                                // }
                                // else {
                                //   elements.push({
                                //     storeName: storeName,
                                //     templateName: item.product,
                                //     date: visitDate,
                                //     target_field: item.count
                                //   })
                                // }
                              })
                            }
                          })
                        }
                        else if(listItem.type === "List Checkbox") {
                          listItem.item_list_checkbox.forEach(item => {
                            visitElement.push({
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: item.product,
                              target_field_count: 1
                            })


                            const elementItem = {
                              storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                              templateName: templateName,
                              date: visitDate,
                              target_field: `${item.product} ${item.checked ? "Yes":"No"}`,
                              additional_field_value: 1
                            }

                            //console.log("Element", elementItem);
                            elements.push(elementItem)
                            

                            if(selectedAnalysisFields.length === 0) 
                            {
                              

                              visitItems.push({
                                id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                cloud_id: visit.id,
                                storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                templateName: templateName,
                                date: visitDate,
                                section: section.name,
                                field: listItem.name,
                                field_value: item.product,
                                additional_field_value: item.checked ? "Yes" : "No"
                              })
                            } 
                            else {
                              selectedAnalysisFields.forEach(analysisField => {
                                const analysisSection = sectionData.find((section) => section.list.some((field) => {
                                  return field.name === analysisField.field 
                                }));
                                
                                // If no section was found, skip this field
                                if (!analysisSection) {
                                  return;
                                }
                          
                                // Find the list item that contains this field
                                const analysisItem = analysisSection.list.find((field) => field.name === analysisField.field);
                          
                                // If no list item was found, skip this field
                                if (!analysisField) {
                                  return;
                                }
  
                                let analysisValue = ""
                                if(analysisItem.type === "YesNo") analysisValue = analysisItem.yesno;
                                else if(analysisItem.type === "Comment" || analysisItem.type === "Number") analysisValue = analysisItem.fieldText;
                                else if(analysisItem.type === "Selection") analysisValue = analysisItem.selectionOption
                                else if(analysisItem.type === "DateTime" || analysisItem.type === "Time") analysisValue = analysisItem.datetime;

                                if(analysisItem.type === "YesNo" || analysisItem.type === "Comment" || analysisItem.type === "Number" ||
                                analysisItem.type === "Selection" || analysisItem.type === "DateTime" || analysisItem.type === "Time")
                                {
                                  visitItems.push({
                                    id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                    cloud_id: visit.id,
                                    storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                    templateName: templateName,
                                    date: visitDate,
                                    section: section.name,
                                    field: listItem.name,
                                    field_value: item.product,
                                    additional_field_value: item.comment ? item.comment : "",
                                    analysis_field: analysisItem.name,
                                    analysis_value: analysisValue
                                  })
                                }
                                else if(analysisItem.type === "Item List" || analysisItem.type === "Item Selection" || analysisItem.type === "Item Comment") {
                                  analysisItem.item_list_comment.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.comment ? item.comment : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.comment ? newItem.comment : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.comment ? item.comment : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? newItem.count[0].value : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "Item Double Count") {
                                  analysisItem.counts.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.comment ? item.comment : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.count ? `(${item.count[0].value}, ${item.count[1].value})` : ""
                                    })
                                  })
                                }
                                else if(analysisItem.type === "List Checkbox") {
                                  analysisItem.item_list_checkbox.forEach(newItem => {
                                    visitItems.push({
                                      id: moment().valueOf() * Math.floor(Math.random() * 1000),
                                      cloud_id: visit.id,
                                      storeName: store.name,
                                  region: store.region,
                                  sub_region: store.sub_region,
                                      templateName: templateName,
                                      date: visitDate,
                                      section: section.name,
                                      field: listItem.name,
                                      field_value: item.product,
                                      additional_field_value: item.comment ? item.comment : "",
                                      analysis_field: analysisItem.name,
                                      analysis_value: newItem.product,
                                      additional_analysis_value: newItem.checked ? "Yes" : "No"
                                    })
                                  })
                                }

                              })
                            }
                          })
                        }
                        

                      });

                      resolve(visitElement)
          
                    })
                
                }))

                resolve(mainArray)
              })
              
            }))
          }))


          // const result = elements.reduce((acc, item) => {
          //   const {additional_field_value, target_field} = item;
            
          //   acc[target_field] = (acc[additional_field_value] || 0) + 1;

          //   return acc;
          // }, {});
          const result = elements.reduce((acc, item) => {
            const { additional_field_value, target_field } = item;
            //console.log(target_field, additional_field_value)
            const intValue = additional_field_value ? parseInt(additional_field_value) : 0;
            if (acc.hasOwnProperty(target_field)) {
              acc[target_field] += (intValue);
            
            } else {
              acc[target_field] = (intValue);
            }
          
            return acc;
          }, {});

          //console.log("Result", result);
          
          const data = Object.keys(result).map(key => ({ [key]: result[key] }));

          const keys = data.reduce((acc, obj) => {
            Object.keys(obj).forEach((key) => {
              if (!acc.includes(key)) {
                acc.push(key);
              }
            });
            return acc;
          }, []);

          //console.log("data", data)

          const series = keys.map((key) => ({
            name: key,
            data: [
              data.reduce((acc, obj) => {
                return obj[key] ? obj[key] : acc;
              }, 0),
            ],
          }));

          //console.log("series", series)

          const sortedSeries = series.sort((a, b) => {
            if(a.data[0] < b.data[0]) return 1
            else if(a.data[0] > b.data[0]) return -1;
            else return 0
          })

     
          setOptions({
            chart: { 
                type: 'bar',
                height: 400
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                horizontal: true,
                barHeight: "100%"
              }
            },
            dataLabels: {
              enabled: true
            },
            title: {
                text: selectedTargetFields.map(field => field.field)
              },
            xaxis: {
                categories: sortedSeries.map(s => s.name),

            },

            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                
              }
        })
          setSeries(sortedSeries)

          setVisits(visitItems)

          
      }

      const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

      const handleStoreChange = (event, values) => {

        //const storeNames = values.map(value => value.name);
        setSelectedStores(values);
      };

      function handleGroupLocationsClick(region) {
        const locationsGroup = stores.filter(store => store.region === region);
        setSelectedStores(locationsGroup);
      }

      const handleTemplateChange = (event, values) => {

        const templateNames = values.map(value => value.name);
        setSelectedTemplates(templateNames);
      };

      const handleTargetFieldChange = (event, values) => {

        //const templateNames = values.map(value => value.name);
        setSelectedTargetFields(values);
      };

      const handleAnalysisFieldChange = (event, values) => {

        //const templateNames = values.map(value => value.name);
        setSelectedAnalysisFields(values);
      };

      const handleRowClick = (params) => {
        navigate("/app/storevisit/report/" + params.row.cloud_id);
      };

      function handleSearchFilter() {
        getVisits();
      }

  return (
    <Fragment>

        <TemplateFormFieldRow align="right" style={{margin: '10px 0px', marginBottom: '5px'}}>
            <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters==true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>
        </TemplateFormFieldRow>

        {filters &&
            <TemplateFilterContainer style={{marginTop: '5px'}}>
                {stores && stores.length > 0 &&
            <Autocomplete
            multiple

            id="store-tags"
            options={[...stores].sort((a, b) => {


              const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
              if (groupSort && groupSort === 0) {
                return sortRegions(a, b);
              }
              return groupSort;
            })}
            groupBy={groupByRegion}
            value={selectedStores}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            onChange={handleStoreChange}
            renderInput={(params) => (
            <TextField
                {...params}
                size='small'
                label="Choose a location"
                placeholder="Add a location"
            />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                
                <GroupHeader onClick={() => handleGroupLocationsClick(params.group)}>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            />}

            {templates && templates.length > 0 &&
            <Autocomplete
            sx={{'marginTop': '10px', 'marginBottom': '10px'}}
            multiple

            id="template-tags"
            options={[...templates].sort((a, b) => {
              const groupA = a.categoryName;
              const groupB = b.categoryName;
              if (groupA === groupB) {
                return a.name.localeCompare(b.name);
              }
              return groupA.localeCompare(groupB);
            })}
            isOptionEqualToValue={(option, value) => option.name === value.name}

            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            groupBy={(option) => option.categoryName}
            onChange={handleTemplateChange}
            renderInput={(params) => (
            <TextField
                {...params}
                size='small'
                label="Choose a template"
                placeholder="Add a template"
            />
            )}
            />}

            {selectedTemplates.length > 0 &&
            <Autocomplete
            sx={{'marginTop': '10px', 'marginBottom': '10px'}}
            multiple

            id="target-tags"
            options={targetFields}
            isOptionEqualToValue={(option, value) => option.field === value.field}

            getOptionLabel={(option) => option.field}
            filterSelectedOptions
            groupBy={(option) => option.section}
            onChange={handleTargetFieldChange}
            renderInput={(params) => (
            <TextField
                {...params}
                size='small'
                label="Select target fields"
                placeholder="Target fields"
            />
            )}
            />}

            {selectedTemplates.length > 0 &&
            <Autocomplete
            sx={{'marginTop': '10px', 'marginBottom': '10px'}}
            multiple

            id="analysis-tags"
            options={analysisFields}
            isOptionEqualToValue={(option, value) => option.field === value.field}

            getOptionLabel={(option) => option.field}
            filterSelectedOptions
            groupBy={(option) => option.section}
            onChange={handleAnalysisFieldChange}
            renderInput={(params) => (
            <TextField
                {...params}
                size='small'
                label="Select analysis fields"
                placeholder="Analysis fields"
            />
            )}
            />}


            <TemplateFormFieldDateContainer>
            <TextField
                        sx={{'marginRight': '10px'}}
                        label="Period from"
                        size='small'
                        type="date"
                        value={filterDateFrom}
                        onChange={(e) => setFilterDateFrom(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
            />
            <TextField
                        label="Period to"
                        type="date"
                        size='small'
                        fullWidth
                        value={filterDateTo}
                        onChange={(e) => setFilterDateTo(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
            />


            </TemplateFormFieldDateContainer>

            <Button disabled={selectedStores.length === 0 || selectedTemplates.length === 0} highlighted onClick={handleSearchFilter} style={{margin: '10px 0px'}} fullWidth>Run Report</Button>

            </TemplateFilterContainer>}
            
            <Chart
            options={options}
            series={series}
            type="bar"
            width="100%"
            height="400"
            />

      {visits && visits.length > 0 &&
              <TableContainer style={{'marginTop': '20px'}}>
              <DataGrid
                autoHeight
                sx={{'border': '0'}}
                components={{ Toolbar: GridToolbar }}
                rows={visits}
                columns={columns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'asc' }],
                  },
                }}
                onRowClick={handleRowClick}
                getRowId={(row) => row.id}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
             </TableContainer>


      }
    </Fragment>
  )
}

export default ReportFieldAnalysis;