import React, { useState, useEffect, useReducer, useContext, useCallback, useMemo } from 'react'
import { StyledAddOptionIcon, AddOptionButton, Button, ActionItemBlockContainer, StyledAlertIcon, ActionItemBlock, ActionItemHolder, ActionItemContainer, StyledTickIcon, ActionNewButton, StyledClosedIcon, StyledMinusValueIcon, StyledAddValueIcon, ActionItemColumn, StyledActionIcon, Text, ActionItemPriorityBubble, StyledTextField } from './styled/styled.container';
import { TemplateFormField, TemplateFormFieldRow, TemplateFormFieldLabel, TemplateFormFieldCompleted, TemplateFormFieldNewCommentContainer, TemplateComments, TemplateFormFieldInsertComment, TemplateFormFieldLink, TemplateFormFieldFilesContainer, TemplateSection, TemplateFormFieldComment, TemplateSectionHeader, TemplateCountdownContainer, TemplateFormFieldScoreContainer, TemplateSectionLastScore, TemplateFormFieldQuestion, TemplateFormFieldActionContainer, TemplateDivHolder, TemplateFormFieldIndexHolder, TemplateFormFieldWrapper, TemplateFormFieldWrapperItem, DivHolderSpaced, DivHolder, TemplateFormFieldQuestionName } from './styled/styled.templateform';

import FieldRating from './FieldRating';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalculateIcon from '@mui/icons-material/Calculate';
import SmsIcon from '@mui/icons-material/Sms';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FieldTypes, AnswerTypes, FieldTypeObjects } from './styled/styled.container';
import FileUploader from './FileUploader';
import moment from 'moment/moment';
import DeleteFileDialog from './DeleteFileDialog';
import { TextField, InputAdornment, Divider, MenuItem, DateTime, Checkbox, IconButton, Snackbar } from '@mui/material';
import AuthContext from './AuthContext';
import FilePreview from './FilePreview';
import ActionCompleteButton from './ActionCompleteButton';
import { Fragment } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddCircle from '@mui/icons-material/AddCircle';
import ActionDialog from './ActionDialog';
import { Colors } from './styled/styled.container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { db } from "./firebase";
import { collection, doc, updateDoc } from "firebase/firestore";
import { InputField } from './InputField';
import { InputFieldCount } from './InputFieldCount';
import { debounce } from 'lodash';

const SectionReport = React.memo((
  {
    lastVisit,
    users,
    currentUser,
    updateSection,
    sectionProp,
    reportStatusOpen,
    canEdit,
    visitBy,
    setDeletedFiles,
    removeFileCloud,
    setFileSizeError
  }) => {

  const [section, setSection] = useState(sectionProp)
  const [currentComments, setCurrentComments] = useState({})
  const dateStoreVisit = moment().format("DD-MM-YYYY");
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(false);
  // used to store the section id and section item id for the DeleteFileDialog param
  const [selectedFile, setSelectedFile] = useState();
  const [showActionDialog, setShowActionDialog] = useState(false)
  const [selectedAction, setSelectedAction] = useState({})
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { storeDetails } = useContext(AuthContext);
  const [barMessage, setBarMessage] = useState("")
  const [showSnackbar, setShowSnackbar] = useState(false);

  const debouncedUpdateSection = debounce(updateSection, 200);

  useEffect(() => {

    if (section) {
      debouncedUpdateSection(section)
    }

  }, [section])

  async function handleRemoveFile(section_id, section_item_id, allowEditVisit, index, file) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    setShowDeleteFileDialog(false);
    const removeFileStatus = await removeFileCloud(section_id, section_item_id, index, file.description);
    if (!removeFileStatus) return;

    // if the file is part of newfiles then remove from newfiles
    // else if file is part of files list -> remove from files, firebase and storage

    setDeletedFiles(prevState => [...prevState,
    {
      section_id: section_id,
      section_item_id: section_item_id,
      description: file.description
    }]);

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        const updatedFileList = item.files.filter(element => element.description !== file.description)
        return { ...item, files: updatedFileList }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


  }

  function handleUpdateFieldText(section_item_id, allowEditVisit, value) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, fieldText: value }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

    // setSection(prevState => prevState.list.map(item => {
    //     if(item.section_item_id === section_item_id) {
    //         return {...item, fieldText : value}
    //     } else 
    //     return item; }))
  }


  function handleUpdateFieldTemperature(section_item_id, allowEditVisit, value) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    //if(!isLetters(value)) return
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, fieldText: value }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function handleAddNewFileComment(section_item_id, allowEditVisit, i, comment) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, newFiles: item.newFiles.map((item, index) => {

            if (index === i) return { ...item, comment: comment }
            else return item;
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


  }

  function handleDeleteFileDialog(section_item_id, allowEditVisit, i, file) {
    //setSelectedSection(section_id);

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    setSelectedFile({
      section_id: section.section_id,
      section_item_id: section_item_id,
      file: file,
      index: i
    })

    setShowDeleteFileDialog(true);
  }

  function handleAnswerUpdate(section_item_id, allowEditVisit, answer) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, yesno: answer === "Yes" ? true : false }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


  }


  function handleRemoveNewFile(section_item_id, i) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, newFiles: item.newFiles.filter((item, index) => index !== i) }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function handleAddFileComment(section_item_id, i, comment) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, newFiles: item.newFiles.map((item, index) => {

            if (index === i) return { ...item, comment: comment }
            else return item;
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }


  function handleDateTimeUpdate(section_item_id, allowEditVisit, value) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const formattedDateTime = moment(value).format("YYYY-MM-DD HH:mm")

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, datetime: formattedDateTime }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


    // setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, yesno: answer } : el)) 
    // ))
  }

  function handleTimeUpdate(section_item_id, allowEditVisit, value) {


    //console.log(fieldEditAllowed)
    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const formattedTime = moment(value, "HH:mm").format("HH:mm")

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, datetime: formattedTime }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


  }

  function updatedFormFieldSelectionOption(section_item_id, allowEditVisit, value) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, selectionOption: value }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


  }

  //   function addFormFieldCount(section_item_id, allowEditVisit, product) {

  //     if(canEdit) {}
  //     else if(!allowEditVisit) {
  //       return
  //     } 

  //     const updatedSection = section.list.map(item => {
  //         if(item.section_item_id === section_item_id) {
  //             return { ...item, counts: [...item.counts, 
  //                 {
  //                     product: "", 
  //                     count: 0
  //                 }] }
  //         } else 
  //         return item; 
  //     })

  //     setSection(prevState => {
  //         return {...prevState, list: updatedSection}
  //     } );

  //  }

  function addFormFieldCount(section_item_id, allowEditVisit, product) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    setSection(prevState => {
      const updatedSection = prevState.list.map(item => {
        if (item.section_item_id === section_item_id) {
          const updatedCounts = [
            ...item.counts,
            {
              product: "",
              count: [
                { label: "Value", value: 0 },
                { label: "Value", value: 0 }
              ]
            }
          ];
          return { ...item, counts: updatedCounts };
        } else {
          return item;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  // function updatedFormFieldProductCount(section_item_id, allowEditVisit, index, value) {

  //   if(canEdit) {}
  //   else if(!allowEditVisit) {
  //     return
  //   } 

  //   const updatedSection = section.list.map(item => {
  //       if(item.section_item_id === section_item_id) {
  //           return {...item, counts : item.counts.map((product, i) => {
  //               if(i === index) return {...product, count: value}
  //               else return product
  //             })}
  //       } else 
  //       return item; 
  //   })

  //   setSection(prevState => {
  //       return {...prevState, list: updatedSection}
  //   } );

  // }

  function updateFormFieldProductCountValue(allowEditVisit, section_item_id, index, countIndex, newValue) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item,
          counts: item.counts.map((product, i) => {
            if (i === index) {
              // Clone the existing count array to avoid direct mutations
              const updatedCount = [...product.count];
              // Update the value for the specified count
              updatedCount[countIndex].value = newValue;
              return { ...product, count: updatedCount };
            }
            return product;
          })
        };
      } else {
        return item;
      }
    });

    setSection(prevState => {
      return { ...prevState, list: updatedSection };
    });
  }

  function updatedFormFieldProductName(section_item_id, allowEditVisit, index, value) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, counts: item.counts.map((product, i) => {
            if (i === index) return { ...product, product: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });



  }

  function addFormFieldItemComment(section_item_id, allowEditVisit, item) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_comment: [...item.item_list_comment,
          {
            product: "", comment: ""
          }]
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


  }

  function updatedFormFieldItemCommentProductName(section_item_id, allowEditVisit, index, value) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_comment: item.item_list_comment.map((product, i) => {
            if (i === index) return { ...product, product: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function updatedFormFieldItemCommentProductComment(section_item_id, allowEditVisit, index, value) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_comment: item.item_list_comment.map((product, i) => {
            if (i === index) return { ...product, comment: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function addFormFieldItemListCheckbox(section_item_id, item) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(sectionItem => {
        if (sectionItem.section_item_id === section_item_id) {
          const updatedItemListComment = [
            ...sectionItem.item_list_checkbox,
            {
              product: "",
              checked: false
            }
          ];
          return { ...sectionItem, item_list_checkbox: updatedItemListComment };
        } else {
          return sectionItem;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }

  function handleDeleteFormFieldItemListCheckbox(section_item_id, item_index) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(sectionItem => {
        if (sectionItem.section_item_id === section_item_id) {
          const updatedItemListChecbox = sectionItem.item_list_checkbox.filter((item, index) => index !== item_index);
          return { ...sectionItem, item_list_checkbox: updatedItemListChecbox };
        } else {
          return sectionItem;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }


  function updatedFormFieldItemListCheckboxName(section_item_id, allowEditVisit, index, value) {


    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_checkbox: item.item_list_checkbox.map((product, i) => {
            if (i === index) return { ...product, product: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function updatedFormFieldItemListCheckboxValue(section_item_id, allowEditVisit, index, value) {


    if (!reportStatusOpen) return;
    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_checkbox: item.item_list_checkbox.map((product, i) => {
            if (i === index) return { ...product, checked: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function updatedFormFieldItemListCheckboxComment(section_item_id, allowEditVisit, index, value) {

    if (!reportStatusOpen) return;
    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, item_list_checkbox: item.item_list_checkbox.map((product, i) => {
            if (i === index) return { ...product, comment: value }
            else return product
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function handleActionBy(section_item_id, user) {

    //console.log(users)
    const userIndex = users.findIndex(element => element.uid === user.target.value);
    //console.log(users[userIndex])
    const userName = users[userIndex].name;

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item,
          actionBy: user.target.value === "0" ? 0 : user.target.value,
          actionByName: userName
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


    // setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, actionBy: user.uid } : el)) 
    // ))
  }

  function handleFilesUpload(section_item_id, newFiles) {


    // const selectedElementFiles = sections.filter(element => element.id === id);
    // let currentFiles = [...selectedElementFiles[0].files];

    // const updatedFiles = [...currentFiles, ...newFiles];
    // console.log(updatedFiles);
    //uploadFiles(visitID, section_id, section_item_id, newFiles);
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, newFiles: [...item.newFiles, ...newFiles.map(newFile => {
            const imageUrl = URL.createObjectURL(newFile);
            return { file: newFile, comment: "", url: imageUrl }
          })]
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


    // setTemplateFields(prevState => (
    //   prevState.map(el => (el.id === id ? { ...el, files: updatedFiles } : el)) 
    // ))
  }

  function handleShowElementComments(section_item_id) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, showComments: !item.showComments }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

    //   setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, showComments: !el.showComments } : el)) 
    // ))
  }

  const updateComment = (section_item_id, comment) => {
    setCurrentComments({
      ...currentComments,
      [section_item_id]: comment,
    });
  };

  function handleAddComment(section_item_id) {
    const comment = currentComments[section_item_id];
    if (comment.length === 0) return;

    if (!reportStatusOpen) {
      setBarMessage("You cannot add a comment on a closed report.")
      setShowSnackbar(true)
      return;
    }

    // not sure why this is here this permission does not exist

    // console.log(storeDetails.permissions)

    // if (!storeDetails.permissions.visits.add_comments) {
    //   setBarMessage("You do not have permission to add comments.")
    //   setShowSnackbar(true)
    //   return;
    // }

    const datedCommentTime = dateStoreVisit + " @ " + moment().format("HH:mm");

    const datedComment = datedCommentTime + " " + currentUser.name + " - " + comment;
    // const element = templateFields.filter(element => element.id === id);
    // let updatedComments = [...element[0].comments, datedComment];

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        const updatedComments = { ...item, comments: [...item.comments, datedComment] }
        return updatedComments
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });




    // setTemplateFields(prevState => (
    //   prevState.map(el => (el.id === id ? 
    //     { ...el, comments: updatedComments 
    //     } : el)) 
    // ))

    setCurrentComments(prevState => ({
      ...prevState,
      [section_item_id]: ""
    }));
  }

  function handleDeleteComment(section_item_id, comment_index) {
    setSection(prevState => {
      const updatedSection = prevState.list.map(item => {
        if (item.section_item_id === section_item_id) {
          const updatedComments = item.comments.filter((comment, index) => index !== comment_index);
          return { ...item, comments: updatedComments };
        } else {
          return item;
        }
      });
      return { ...prevState, list: updatedSection };
    });
  }



  function removeFiles(section_item_id) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, newFiles: [] }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function handleCompleteItem(section_item_id) {

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        const isComplete = !item.actionComplete;
        if (isComplete) {
          return {
            ...item,
            actionCompleteDate: moment().format("DD-MM-YYYY HH:mm"),
            actionComplete: isComplete
          }
        } else {
          return {
            ...item,
            actionCompleteDate: "",
            actionComplete: isComplete
          }
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });

  }

  function handleRatingUpdate(section_item_id, allowEditVisit, rating) {

    if (canEdit) { }
    else if (!allowEditVisit) {
      return
    }

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, rating: rating }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });


    // setTemplateFields(prevState => (
    //     prevState.map(el => (el.id === id ? { ...el, rating: rating } : el)) 
    // ))
  }

  function getLastVisitScore() {
    const lastVisitItem = lastVisit?.data?.sections.filter(lastVisitSection => lastVisitSection.section_id === section.section_id)
    if (lastVisitItem?.length > 0) {

      if (lastVisitItem[0].scorable) return lastVisitItem[0].score + "%"
      else return ""
    }
    else return ""
  }

  function handleNewActionDialog(section_item_id) {
    //console.log("Showing action")
    const actionItem = {
      section_id: section.section_id,
      section_item_id: section_item_id
    }
    setSelectedAction(actionItem)
    setShowActionDialog(true)
  }

  async function updateActionCloud(action) {

    const actionRef = doc(db, "stores/" + storeDetails.rootID + "/actions/" + action.action_id)
    //console.log(actionRef.path);
    updateDoc(actionRef, action)
  }

  function handleEditActionDialog(section_item_id, index, action, visitByUID) {


    // if a user who the action is not meant for OR the user is not an admin
    if (action.actionBy !== currentUser.uid && currentUser.type !== "admin") return;

    // if the action is for the user and the user is not an admin and the user is not the visit by user
    // complete/uncomplete the action and exit
    if (action.actionBy === currentUser.uid && currentUser.type !== "admin" && action.actionBy !== visitByUID) {
      const updatedSection = section.list.map(item => {
        if (item.section_item_id === section_item_id) {
          return {
            ...item, actions: item.actions.map((action, i) => {
              if (i === index) {
                const newDate = action.actionComplete ? "" : moment().format("DD-MM-YYYY")
                const updatedAction = {
                  ...action,

                  actionComplete: !action.actionComplete,
                  actionCompleteDate: newDate
                }

                updateActionCloud(updatedAction)
                return updatedAction


              }
              else return action
            })
          }
        } else
          return item;
      })

      setSection(prevState => {
        return { ...prevState, list: updatedSection }
      });

      return;
    }
    // if action by is same as current user and click ----> mark as completed or not completed
    // if user is admin or visit by user then on click ----> open dialog and show complete button

    const actionItem = {
      ...action,
      //section_id: section.section_id,
      //section_item_id: section_item_id,
      actionBy: action.actionBy,
      section_item_name: action.section_item_name,
      action_due_date: action.action_due_date,
      actionComplete: action.actionComplete,
      action_priority: action.action_priority,
      index: index
    }

    setSelectedAction(actionItem)

    setShowActionDialog(true)
  }

  function handleAddAction(section_item_id, actionInfo) {

    //console.log("Action info", actionInfo)

    const sectionItemActionUserExists = section.list.filter(item => item.section_item_id === section_item_id)[0]
      .actions
      .filter(action => action.actionBy === actionInfo.actionBy)

    if (sectionItemActionUserExists && sectionItemActionUserExists.length > 0) return;

    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, actions: [...item.actions, actionInfo] }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  function handleEditAction(section_item_id, index, actionInfo) {
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return {
          ...item, actions: item.actions.map((action, i) => {
            if (i === index) return actionInfo
            else return action
          })
        }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  function handleDeleteAction(section_item_id, index) {
    const updatedSection = section.list.map(item => {
      if (item.section_item_id === section_item_id) {
        return { ...item, actions: item.actions.filter((action, i) => i !== index) }
      } else
        return item;
    })

    setSection(prevState => {
      return { ...prevState, list: updatedSection }
    });
  }

  function handleCloseBar() {
    setShowSnackbar(false);
  }

  function isFieldReadOnly(element) {

    if (!reportStatusOpen) return true;

    if (!element.allowEditVisit) return true;
    return false;

  }

  const lastVisitScore = useMemo(() => getLastVisitScore(), [lastVisit, section]);

  return (
    <TemplateSection noShadow={true}>

      {showActionDialog && <ActionDialog
        open={showActionDialog}
        setOpen={setShowActionDialog}
        addAction={handleAddAction}
        report={true}
        updateAction={handleEditAction}
        deleteAction={handleDeleteAction}
        completeAction={handleEditAction}
        actionInfo={selectedAction}
        my_uid={currentUser.uid}
        users={users} />}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseBar}
        message={barMessage}
        action={null}
      />
      <DeleteFileDialog
        open={showDeleteFileDialog}
        setOpen={setShowDeleteFileDialog}
        title="Delete File"
        content="Are you sure you want to delete this file?"
        selectedFile={selectedFile}
        action={handleRemoveFile} />

      <TemplateSectionHeader>
        {section.name} {section.scorable ? `(${section.score}%)` : ""}

        {lastVisitScore && lastVisitScore.length > 0 &&
          <TemplateSectionLastScore>
            {`Previous Score: ${getLastVisitScore()}`}

          </TemplateSectionLastScore>
        }
      </TemplateSectionHeader>



      {section.list.map((element, itemIndex) => {


        return (
          <TemplateFormField key={itemIndex} style={{ 'paddingBottom': '5px', marginBottom: '15px' }}>

            <TemplateFormFieldRow style={{ 'marginBottom': '20px' }}>
              <TemplateFormFieldQuestion>
                <TemplateFormFieldQuestionName>{itemIndex+1}. {element.name}</TemplateFormFieldQuestionName>
              </TemplateFormFieldQuestion>


            </TemplateFormFieldRow>


            {element.type === FieldTypeObjects.Comment &&

              <TemplateFormFieldRow>

                <InputField
                  multiline
                  variant='outlined'

                  
                  InputProps={{

                    // readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                    readOnly: isFieldReadOnly(element),
                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SmsIcon fontSize='small' />
                      </InputAdornment>
                    ),

                  }}
                  myVal={element?.fieldText.length > 0 ? element.fieldText : ""}
                  section_item_id={element.section_item_id}
                  placeholder={"Please enter a comment"}
                  allowEditVisit={element.allowEditVisit}
                  updateHandler={handleUpdateFieldText}
                />
              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects.Number &&

              <TemplateFormFieldRow>

                <InputField

                  
                  myVal={element.fieldText}
                  inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                  InputProps={{
                    type: 'number',
                    
                    //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                    // reportEditClosed = true AND canEdit then allow edit
                    // false and true
                    readOnly: isFieldReadOnly(element),

                    startAdornment: reportStatusOpen && (
                      <IconButton onClick={() => handleUpdateFieldTemperature(element.section_item_id, element.allowEditVisit, parseInt(element.fieldText || 0) - 1)}>
                        <StyledMinusValueIcon />
                      </IconButton>
                    ),
                    endAdornment: reportStatusOpen && (
                      <IconButton onClick={() => handleUpdateFieldTemperature(element.section_item_id, element.allowEditVisit, parseInt(element.fieldText || 0) + 1)}>
                        <StyledAddValueIcon />
                      </IconButton>
                    )
                  }}

                  placeholder="0"
                  variant='outlined'
                  section_item_id={element.section_item_id}
                  allowEditVisit={element.allowEditVisit}
                  updateHandler={handleUpdateFieldTemperature} />
              </TemplateFormFieldRow>
            }



            {element.type === FieldTypeObjects['Item Comment'] &&
              <Fragment>
                {element.item_list_comment.length > 0 ?
                  element.item_list_comment.map((item, i) => {
                    return (
                      <div key={i}>
                        <TemplateFormFieldRow style={{ 'marginBottom': '15px' }}>
                          <InputField
                            variant="standard"
                            
                            multiline
                            
                            InputProps={{
                              //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                              readOnly: isFieldReadOnly(element),

                              startAdornment: (
                                <InputAdornment position="start">
                                  <InventoryIcon fontSize='small' />
                                </InputAdornment>
                              ),

                            }}


                            myVal={item.product}
                            section_item_id={element.section_item_id}
                            i={i}
                            allowEditVisit={element.allowEditVisit}
                            updateHandler={updatedFormFieldItemCommentProductName} />
                        </TemplateFormFieldRow>
                        <TemplateFormFieldRow>

                          <InputField
                            variant='outlined'
                            multiline
                            label="Item Comment"
                            onFocus={event => {
                              event.target.select();
                            }}
                            InputProps={{
                              //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                              readOnly: isFieldReadOnly(element),
                            }}

                            myVal={item.comment}
                            section_item_id={element.section_item_id}
                            i={i}
                            allowEditVisit={element.allowEditVisit}
                            updateHandler={updatedFormFieldItemCommentProductComment}

                          />
                        </TemplateFormFieldRow>

                      </div>

                    )
                  }) :
                  <TemplateFormFieldRow>
                    <TextField fullWidth multiline
                      variant='outlined' label='Comment'

                      InputProps={{
                        //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                        readOnly: !reportStatusOpen,
                        style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                        startAdornment: (

                          <InputAdornment position="start">
                            <SmsIcon fontSize='small' />
                          </InputAdornment>
                        ),

                      }}
                      value="No items entered."
                    />
                  </TemplateFormFieldRow>
                }

                {reportStatusOpen && element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, element.allowEditVisit, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}
              </Fragment>

            }

            {element.type === FieldTypeObjects['Item Selection'] &&
              <Fragment>
                {element.item_list_comment.length > 0 &&
                  element.item_list_comment.map((item, commentIndex) => {
                    return (
                      <div key={commentIndex}>
                        <TemplateFormFieldRow style={{ 'marginBottom': '10px' }}>
                          <StyledTextField
                            select
                            fullWidth
                            
                            InputProps={{
                              //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                              readOnly: isFieldReadOnly(element),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <RadioButtonCheckedIcon fontSize='small' />
                                </InputAdornment>
                              ),

                            }}
                            value={item.product}
                            onChange={(e) => updatedFormFieldItemCommentProductName(element.section_item_id, element.allowEditVisit, commentIndex, e.target.value)}>
                            {element.options.map((type, i) => {
                              return <MenuItem key={i} value={type.product}>{type.product}</MenuItem>
                            })}
                          </StyledTextField>
                        </TemplateFormFieldRow>
                      </div>
                    )
                  })}
                {element.additionalOptions && reportStatusOpen && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, element.allowEditVisit, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}
              </Fragment>

            }

            {element.type === FieldTypeObjects['Item Selection Counts'] &&
              <Fragment>
                {element.item_list_comment.length > 0 &&
                  element.item_list_comment.map((item, commentIndex) => {
                    return (

                      <TemplateFormFieldRow wrap="true" align_items="flex-end" key={commentIndex}>
                        <StyledTextField
                          select
                          
                          fullWidth
                          InputProps={{
                            //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                            readOnly: isFieldReadOnly(element),
                            startAdornment: (
                              <InputAdornment position="start">
                                <RadioButtonCheckedIcon fontSize='small' />
                              </InputAdornment>
                            ),

                          }}
                          value={item.product}
                          onChange={(e) => updatedFormFieldItemCommentProductName(element.section_item_id, element.allowEditVisit, commentIndex, e.target.value)}>
                          {element.options.map((type, i) => {
                            return <MenuItem key={i} value={type.product}>{type.product}</MenuItem>
                          })}
                        </StyledTextField>

                        <InputField
                          variant='standard'
                          

                          sx={{
                            'marginTop': isSmallScreen ? '10px' : '0px',
                            marginLeft: isSmallScreen ? '0px' : '10px',
                            width: isSmallScreen ? "100%" : "fit-content"
                          }}
                          label="Value"
                          onFocus={event => {
                            event.target.select();
                          }}

                          inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                          InputProps={{
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            readOnly: !reportStatusOpen,
                            type: 'number',

                            startAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updatedFormFieldItemCommentProductComment(element.section_item_id, element.allowEditVisit, commentIndex, parseInt(item.comment) - 1)}>
                                <StyledMinusValueIcon />
                              </IconButton>
                            ),
                            endAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updatedFormFieldItemCommentProductComment(element.section_item_id, element.allowEditVisit, commentIndex, parseInt(item.comment) + 1)}>
                                <StyledAddValueIcon />
                              </IconButton>
                            )
                          }}
                          myVal={parseInt(item.comment) || 0}
                          section_item_id={element.section_item_id}
                          i={commentIndex}
                          allowEditVisit={element.allowEditVisit}
                          updateHandler={updatedFormFieldItemCommentProductComment}

                        />
                      </TemplateFormFieldRow>

                    )
                  })}
                {element.additionalOptions && reportStatusOpen && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, element.allowEditVisit, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}
              </Fragment>

            }

            {element.type === FieldTypeObjects['Item Count'] &&
              <Fragment>
                {element.counts.length > 0 ?
                  element.counts.map((item, i) => {
                    return (
                      <TemplateFormFieldRow wrap="true" align_items="flex-end" key={i}>
                        <StyledTextField
                          variant="outlined"
                          
                          
                          InputProps={{
                            //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                            readOnly: isFieldReadOnly(element),
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            startAdornment: (
                              <InputAdornment position="start">
                                <InventoryIcon fontSize='small' />
                              </InputAdornment>
                            ),

                          }}

                          placeholder={"Item is empty"}
                          multiline

                          myVal={item.product}
                          section_item_id={element.section_item_id}
                          i={i}
                          allowEditVisit={element.allowEditVisit}
                          updateHandler={updatedFormFieldProductName}

                        />

                        <InputFieldCount
                          variant='standard'
                          
                          placeholder='Value is empty'
                          sx={{
                            'marginTop': isSmallScreen ? '10px' : '0px',
                            marginLeft: isSmallScreen ? '0px' : '10px',
                            width: isSmallScreen ? "100%" : "fit-content"
                          }}
                          label={item.count[0].label.length > 0 ? item.count[0].label : "Value"}
                          onFocus={event => {
                            event.target.select();
                          }}

                          inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                          InputProps={{
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            readOnly: !reportStatusOpen,
                            type: 'number',

                            startAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updateFormFieldProductCountValue(element.allowEditVisit, element.section_item_id, i, 0, parseInt(item.count[0].value) - 1)}>
                                <StyledMinusValueIcon />
                              </IconButton>
                            ),
                            endAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updateFormFieldProductCountValue(element.allowEditVisit, element.section_item_id, i, 0, parseInt(item.count[0].value) + 1)}>
                                <StyledAddValueIcon />
                              </IconButton>
                            )
                          }}
                          myVal={item.count[0].value}
                          section_item_id={element.section_item_id}
                          i={i}
                          countIndex={0}
                          allowEditVisit={element.allowEditVisit}
                          updateHandler={updateFormFieldProductCountValue}

                        />
                      </TemplateFormFieldRow>
                    )
                  }) :
                  <TemplateFormFieldRow>
                    <StyledTextField fullWidth multiline
                      variant='outlined' label='Comment'

                      InputProps={{
                        readOnly: true,
                        style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SmsIcon fontSize='small' />
                          </InputAdornment>
                        ),

                      }}
                      value="No items entered."
                    />
                  </TemplateFormFieldRow>
                }

                {reportStatusOpen && element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldCount(element.section_item_id, element.allowEditVisit, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}

              </Fragment>
            }

            {element.type === FieldTypeObjects['Item Double Count'] &&
              <Fragment>
                {element.counts.length > 0 ?
                  element.counts.map((item, i) => {
                    return (
                      <TemplateFormFieldRow wrap="true" align_items="flex-end" key={i}>
                        <StyledTextField
                          variant="outlined"
                          
                          
                          InputProps={{
                            //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                            readOnly: isFieldReadOnly(element),
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            startAdornment: (
                              <InputAdornment position="start">
                                <InventoryIcon fontSize='small' />
                              </InputAdornment>
                            ),

                          }}


                          multiline
                          myVal={item.product}
                          section_item_id={element.section_item_id}
                          i={i}
                          updateHandler={updatedFormFieldProductName}
                          value={item.product}

                        />

                        <InputFieldCount
                          variant='standard'
                          
                          placeholder='Value is empty'
                          sx={{
                            'marginTop': isSmallScreen ? '10px' : '0px',
                            marginLeft: isSmallScreen ? '0px' : '10px',
                            width: isSmallScreen ? "100%" : "fit-content"
                          }}
                          label={item.count[0].label.length > 0 ? item.count[0].label : "Value"}
                          onFocus={event => {
                            event.target.select();
                          }}

                          inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                          InputProps={{
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            readOnly: isFieldReadOnly(element),
                            type: 'number',

                            startAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updateFormFieldProductCountValue(element.allowEditVisit, element.section_item_id, i, 0, parseInt(item.count[0].value) + 1)}>
                                <StyledMinusValueIcon />
                              </IconButton>
                            ),
                            endAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updateFormFieldProductCountValue(element.allowEditVisit, element.section_item_id, i, 0, parseInt(item.count[0].value) + 1)}>
                                <StyledAddValueIcon />
                              </IconButton>
                            )
                          }}
                          myVal={item.count[0].value}
                          section_item_id={element.section_item_id}
                          i={i}
                          countIndex={0}
                          allowEditVisit={element.allowEditVisit}
                          updateHandler={updateFormFieldProductCountValue}
                          value={item.count[0].value}

                        />

                        <InputFieldCount
                          variant='standard'
                          

                          sx={{
                            'marginTop': isSmallScreen ? '10px' : '0px',
                            marginLeft: isSmallScreen ? '0px' : '10px',
                            width: isSmallScreen ? "100%" : "fit-content"
                          }}
                          label={item.count[1].label.length > 0 ? item.count[1].label : "Value"}
                          onFocus={event => {
                            event.target.select();
                          }}

                          inputProps={{ type: 'number', style: { textAlign: 'center' } }}
                          InputProps={{
                            style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                            readOnly: isFieldReadOnly(element),
                            type: 'number',

                            startAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updateFormFieldProductCountValue(element.allowEditVisit, element.section_item_id, i, 1, parseInt(item.count[1].value) - 1)}>
                                <StyledMinusValueIcon />
                              </IconButton>
                            ),
                            endAdornment: reportStatusOpen && (
                              <IconButton onClick={() => updateFormFieldProductCountValue(element.allowEditVisit, element.section_item_id, i, 1, parseInt(item.count[1].value) + 1)}>
                                <StyledAddValueIcon />
                              </IconButton>
                            )
                          }}
                          placeholder='Value is empty'
                          myVal={item.count[1].value}
                          section_item_id={element.section_item_id}
                          i={i}
                          countIndex={1}
                          allowEditVisit={element.allowEditVisit}
                          updateHandler={updateFormFieldProductCountValue}

                        />
                      </TemplateFormFieldRow>
                    )
                  }) :
                  <TemplateFormFieldRow>
                    <StyledTextField fullWidth multiline
                      variant='outlined' label='Comment'

                      InputProps={{
                        readOnly: true,
                        style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SmsIcon fontSize='small' />
                          </InputAdornment>
                        ),

                      }}
                      value="No items entered."
                    />
                  </TemplateFormFieldRow>
                }

                {reportStatusOpen && element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldCount(element.section_item_id, element.allowEditVisit, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}

              </Fragment>
            }

            {element.type === FieldTypeObjects['Item List'] &&
              <Fragment>
                {element.item_list_comment.length > 0 ?
                  element.item_list_comment.map((item, i) => {
                    return (
                      <div key={i}>
                        <TemplateFormFieldRow style={{ 'marginBottom': '15px' }}>
                          <InputField
                            variant="standard"
                            multiline
                            
                            
                            InputProps={{
                              style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                              //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                              readOnly: isFieldReadOnly(element),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <InventoryIcon fontSize='small' />
                                </InputAdornment>
                              ),

                            }}

                            fullWidth
                            myVal={item.product}
                            section_item_id={element.section_item_id}
                            allowEditVisit={element.allowEditVisit}
                            i={i}
                            updateHandler={updatedFormFieldItemCommentProductName}

                          />
                        </TemplateFormFieldRow>

                      </div>

                    )
                  }) :
                  <TemplateFormFieldRow>
                    <StyledTextField fullWidth multiline
                      variant='outlined' label='Comment'

                      InputProps={{
                        readOnly: true,
                        style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SmsIcon fontSize='small' />
                          </InputAdornment>
                        ),

                      }}
                      value="No items entered."
                    />
                  </TemplateFormFieldRow>}

                {reportStatusOpen && element.additionalOptions && <TemplateFormFieldRow>
                  <AddOptionButton onClick={() => addFormFieldItemComment(element.section_item_id, element.allowEditVisit, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</AddOptionButton>
                </TemplateFormFieldRow>}
              </Fragment>
            }

            {element.type === FieldTypeObjects['List Checkbox'] &&
              <Fragment>
                {element.item_list_checkbox.length > 0 ?
                  element.item_list_checkbox.map((item, i) => {
                    return (
                      <div key={i} style={element.listCheckboxComments ? { 'marginBottom': '10px' } : { 'marginBottom': '15px' }}>
                        <TemplateFormFieldRow style={{ 'margin': '0px 0px' }}>
                          <InputField
                            variant="standard"
                            
                            multiline

                            InputProps={{
                              disableUnderline: true,
                              style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                              readOnly: isFieldReadOnly(element),
                              //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,

                              startAdornment: (
                                <InputAdornment position="start">
                                  {i + 1}.
                                </InputAdornment>
                              ),

                            }}

                            fullWidth
                            myVal={item.product}
                            section_item_id={element.section_item_id}
                            i={i}
                            allowEditVisit={element.allowEditVisit}
                            updateHandler={updatedFormFieldItemListCheckboxName}

                          />

                          <Checkbox
                            checked={item.checked}

                            inputProps={{
                              //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true
                              readOnly: isFieldReadOnly(element),
                              'aria-label': 'controlled'
                            }}
                            onChange={(e) => updatedFormFieldItemListCheckboxValue(element.section_item_id, element.allowEditVisit, i, e.target.checked)} />
                        </TemplateFormFieldRow>
                        {element.listCheckboxComments &&
                          <InputField
                            fullWidth
                            
                            InputProps={{
                              style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                              readOnly: !reportStatusOpen,
                            }}
                            placeholder='No comment entered'
                            myVal={item.comment}
                            section_item_id={element.section_item_id}
                            i={i}
                            allowEditVisit={element.allowEditVisit}
                            updateHandler={updatedFormFieldItemListCheckboxComment}

                          />}

                      </div>

                    )
                  }) :
                  <TemplateFormFieldRow>
                    <StyledTextField fullWidth multiline
                      variant='outlined' label=''

                      InputProps={{
                        readOnly: true,
                        style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SmsIcon fontSize='small' />
                          </InputAdornment>
                        ),

                      }}
                      value="No items entered."
                    />
                  </TemplateFormFieldRow>}

                {/* {reportEditClosed && element.editAllowed && element.additionalOptions && <TemplateFormFieldRow>
                          <AddOptionButton onClick={() => addFormFieldItemListCheckbox(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{'marginRight': '5px'}} /> Add option</AddOptionButton>
                          </TemplateFormFieldRow>} */}
              </Fragment>
            }

            {element.type === FieldTypeObjects.DateTime &&
              <TemplateFormFieldRow>
                <StyledTextField
                  label="Date Time"
                  
                  type="datetime-local"
                  value={element.datetime}
                  onChange={(e) => handleDateTimeUpdate(element.section_item_id, element.allowEditVisit, e.target.value)}
                  InputProps={{
                    //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                    readOnly: isFieldReadOnly(element),
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon fontSize='small' />
                      </InputAdornment>
                    ),

                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects.Time &&
              <TemplateFormFieldRow>
                <StyledTextField
                  label="Time"
                  type="time"
                  
                  value={element.datetime}
                  onChange={(e) => handleTimeUpdate(element.section_item_id, element.allowEditVisit, e.target.value)}
                  InputProps={{
                    //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                    readOnly: isFieldReadOnly(element),
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon fontSize='small' />
                      </InputAdornment>
                    ),

                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </TemplateFormFieldRow>
            }


            {element.type === FieldTypeObjects.Selection &&
              <TemplateFormFieldRow>
                <StyledTextField
                  select
                  
                  fullWidth
                  InputProps={{
                    //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                    readOnly: isFieldReadOnly(element),
                    startAdornment: (
                      <InputAdornment position="start">
                        <RadioButtonCheckedIcon fontSize='small' />
                      </InputAdornment>
                    ),

                  }}
                  value={element.selectionOption}
                  onChange={(e) => updatedFormFieldSelectionOption(element.section_item_id, element.allowEditVisit, e.target.value)}>
                  <MenuItem key={-1} value={"None"}>None</MenuItem>
                  {element.options.map((type, i) => {
                    return <MenuItem key={i} value={type.product}>{type.product}</MenuItem>
                  })}
                </StyledTextField>
              </TemplateFormFieldRow>
            }

            {element.type === FieldTypeObjects.YesNo &&
              <TemplateFormFieldRow>
                <StyledTextField
                  select
                  
                  fullWidth
                  InputProps={{
                    //readOnly: reportEditClosed && 'editAllowed' in element ? !element.editAllowed : true,
                    readOnly: isFieldReadOnly(element),
                    startAdornment: (
                      <InputAdornment position="start">
                        <RadioButtonCheckedIcon fontSize='small' />
                      </InputAdornment>
                    )
                  }}
                  value={element.yesno === "Yes" ? "Yes" : "No"}
                  onChange={(e) => handleAnswerUpdate(element.section_item_id, element.allowEditVisit, e.target.value)}
                >
                  {AnswerTypes.map((type, i) => {
                    return <MenuItem key={i} value={type}>{type}</MenuItem>
                  })}
                </StyledTextField>
              </TemplateFormFieldRow>
            }

            <TemplateFormFieldWrapper>

              <DivHolderSpaced wrap={"true"} fullWidth>

                <DivHolder >
                {reportStatusOpen && element.actionAllowed && (canEdit) // currentUser.type === "admin" || currentUser.uid === visitBy.uid
                    &&
                    <TemplateFormFieldWrapperItem>

                      <ActionNewButton onClick={() => handleNewActionDialog(element.section_item_id)}><StyledActionIcon /></ActionNewButton>
                    </TemplateFormFieldWrapperItem>}

                  {element.filesAllowed &&
                    
                      <FileUploader canEdit={!isFieldReadOnly(element)} allowMultipleFiles section_item_id={element.section_item_id} handleFilesUpload={handleFilesUpload} />


                    }

                  
                </DivHolder>
                {element.scorable &&
                  <TemplateFormFieldWrapper align="right">
                    <TemplateFormFieldScoreContainer>
                      <FieldRating updateRating={handleRatingUpdate} prevRating={element.rating} readOnly={!reportStatusOpen && canEdit} />
                    </TemplateFormFieldScoreContainer>
                  </TemplateFormFieldWrapper>
                }
              </DivHolderSpaced>




            </TemplateFormFieldWrapper>

            {(element.files.length > 0 || element.newFiles.length > 0) && <TemplateFormFieldRow style={{ marginTop: '10px' }}>

              <FilePreview

                section_item_id={element.section_item_id}
                setFileSizeError={setFileSizeError}
                files={element.files}
                canEdit={reportStatusOpen && canEdit}
                newFiles={element.newFiles}
                user={currentUser}
                handleAddFileComment={handleAddNewFileComment}
                handleRemoveNewFile={handleRemoveNewFile}
                handleRemoveFile={handleDeleteFileDialog} />

            </TemplateFormFieldRow>}

            <TemplateFormFieldActionContainer>


              {element.actionAllowed && element.actions && element.actions.length > 0 && <ActionItemContainer>
                <Text fontSize="0.9rem" fontWeight={"500"} style={{ 'margin': '5px 10px' }}>Actions</Text>
                {element.actions.map((action, i) => {

                  return (
                    <ActionItemBlockContainer
                      onClick={() => handleEditActionDialog(element.section_item_id, i, action, visitBy.uid)}
                      key={i}
                      priority={action.action_priority}
                      complete={action.actionComplete}>
                      {action.actionComplete ? <StyledTickIcon fontSize="large" /> : <ActionItemPriorityBubble priority={action.action_priority} />}
                      <ActionItemColumn>
                        <ActionItemBlock style={{ 'marginBottom': '2px' }}>{action.section_item_name}</ActionItemBlock>
                        <ActionItemBlock>
                          <ActionItemHolder size="medium">Action by {action.actionByName}</ActionItemHolder>
                          {action.actionComplete ?
                            <ActionItemHolder size="medium" bold> - Complete {action.actionCompleteDate}</ActionItemHolder>
                            :
                            <ActionItemHolder size="medium" bold> - Due {moment(action.action_due_date).format("DD MMM YY")}</ActionItemHolder>
                          }

                        </ActionItemBlock>
                      </ActionItemColumn>

                    </ActionItemBlockContainer>
                  )
                })}
              </ActionItemContainer>}
            </TemplateFormFieldActionContainer>

            {/* // if action not complete and user is an admin
                        // or the user is the one who did the visit
                        // allow the action by to be changed */}
            {/* {element.actionAllowed && !element.actionComplete 
                        && (currentUser.type === "admin" || currentUser.uid === visitBy.uid) 
                        &&
                        <TemplateFormFieldRow align="right">
                        <TemplateFormFieldLabel>Action by</TemplateFormFieldLabel>
                        <TemplateFormFieldCompleted value={element.actionBy} onChange={(e) => handleActionBy(element.section_item_id, e)}>
                          <option key={-1} value={0}>None</option>
                          {users && users.map((user, i) => {
                              if(!user.active) return <option hidden key={i} value={user.uid}>{user.name}</option>
                              else return <option key={i} value={user.uid}>{user.name}</option>
                          })}
                        </TemplateFormFieldCompleted>
                      </TemplateFormFieldRow>} */}

            {element.allowComments &&
              <TemplateFormFieldRow align="right">
                <TemplateFormFieldInsertComment onClick={() => handleShowElementComments(element.section_item_id)}>{element.showComments ? `Hide Comments (${element.comments.length})` : `Show Comments (${element.comments.length})`}</TemplateFormFieldInsertComment>
              </TemplateFormFieldRow>}

            {element.allowComments && element.showComments &&
              <>
                <TemplateFormFieldRow>
                  <TemplateFormFieldNewCommentContainer>
                    <StyledTextField

                      fullWidth
                      
                      placeholder='Enter a new comment'
                      type="text"
                      value={currentComments[element.section_item_id]}
                      onChange={e => updateComment(element.section_item_id, e.target.value)}

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledAddOptionIcon style={{ 'margin': '0px' }} fontSize="medium" onClick={() => handleAddComment(element.section_item_id)} />
                          </InputAdornment>
                        ),

                      }}
                    />
                  </TemplateFormFieldNewCommentContainer>

                </TemplateFormFieldRow>
                {element.comments.map((comment, commentIndex) => {
                  return (
                    <TemplateFormFieldRow key={commentIndex}>
                      <TemplateFormFieldComment>
                        {comment}
                        <StyledClosedIcon onClick={() => handleDeleteComment(element.section_item_id, commentIndex)} style={{ 'marginLeft': '5px' }} />
                      </TemplateFormFieldComment>

                    </TemplateFormFieldRow>)
                })}
              </>

            }

            {/* {element.actionAllowed && <ActionCompleteButton
                      
                      action={() => handleCompleteItem(element.section_item_id)} element={element} 
                      actionComplete={element.actionComplete}
                      actionCompleteData={element.actionCompleteDate}
                      currentUser={currentUser} 
                    />} */}

          </TemplateFormField>)
      })}

    </TemplateSection>
  )
})

export default SectionReport