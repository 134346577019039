import React, {useEffect} from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { HeaderTitle } from './styled/styled.container'
import StoresView from './StoresView'
import StoresForm from "./StoresForm"
import Regions from './Regions'

function Stores(props) {
  const {storeDetails} = props;
  const navigate = useNavigate();

  useEffect(() => {

    if(!storeDetails.permissions.stores.view) navigate("/app")

  }, [storeDetails])

  return (
        <Routes>
            <Route exact path="/" element={<StoresView storeDetails={storeDetails} />} />
            <Route path='/modify/:id' element={<StoresForm storeDetails={storeDetails} />} />
            <Route path='/create' element={<StoresForm storeDetails={storeDetails} />} />
            <Route path="/groups" element={<Regions storeDetails={storeDetails} />} />
        </Routes>
  )
}

export default Stores