import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageContainer, HeaderSubTitle, HeaderTitle, Button, CardContainer, Card, NewButton, ErrorContainer, ErrorContainerText, CardEmptyContainer, PageHolder, StyledNewIcon, StyledSearchIcon, StyledFilterListIcon, StyledAlertRoundedIcon, HeaderHolder, GroupHeader, GroupItems } from './styled/styled.container'
import { TemplateCardContainer, TemplateCard } from './styled/styled.templates'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, getDoc, getDocs, where } from "firebase/firestore";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Header from './Header';
import { VisitActionsContainer, VisitActionsText } from './styled/styled.storevisit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from './Loader';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AuthContext from './AuthContext';
import { InputAdornment } from '@mui/material';
import { Colors } from './styled/styled.container';
import { TemplateDescription, TemplateFormButtonStack } from './styled/styled.templateform';
import EmptyScreen from './EmptyScreen';
import AuditImg from "./imgs/audit_img.png"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StorevisitStart(props) {


  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  //const {selectedStore, updateSelectedStore, selectedTemplate, updateSelectedTemplate} = props;
  const [outstandingActions, setOutstandingActions] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(false)
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const params = useParams();

  const { updateSelectedStore, updateSelectedTemplate } = props;
  const { storeDetails } = useContext(AuthContext)
  const [templates, setTemplates] = useState(storeDetails.user_specific_templates);
  const [stores, setStores] = useState(storeDetails.user_specific_stores)
  const navigate = useNavigate();
  useEffect(() => {


    setOutstandingActions(0)
    if (storeDetails.rootID.length > 0 && storeDetails.user_specific_stores.length > 0 && storeDetails.user_specific_templates.length > 0) {

      setTemplates(storeDetails.user_specific_templates)
      setStores(storeDetails.user_specific_stores)

      const sTemplate = storeDetails.user_specific_templates.find(item => item.cloud_id === params.templateid)
      
      if (sTemplate) {

        setSelectedTemplate(sTemplate)
        updateSelectedTemplate(sTemplate)
      }
      //console.log(storeDetails.templates)
      //console.log(templates);
      //setStores(storeDetails.user_specific_stores)
      //setTemplates(storeDetails.user_specific_templates);
      //getStores();
      //getTemplates();
    }

  }, [storeDetails])

  useEffect(() => {

    async function getActions() {

      if (selectedStore && selectedTemplate) {

        setShowLoading(true)
        let actionsCount = 0;

        setOutstandingActions(0)
        const visitRef = query(collection(db, "stores/" + storeDetails.rootID + "/actions"), where("store_name", "==", selectedStore.name), where("template_name", "==", selectedTemplate.name), where("actionComplete", "==", false));
        const visitDocs = await getDocs(visitRef);
        if (storeDetails.user.admin) actionsCount = visitDocs.docs.length;
        else {
          visitDocs.docs.forEach(item => {
            const itemData = item.data();
            if (itemData.actionBy === storeDetails.user.uid || itemData.action_set_by === storeDetails.user.uid) actionsCount++;
          })
        }

        setOutstandingActions(actionsCount);

        setShowLoading(false)

      }
    }

    getActions();

  }, [selectedStore, selectedTemplate])

  function goBack() {
    navigate("/app/storevisit")
  }

  const handleClose = (event, reason) => {

    setOpenBar(false);
    setShowLoading(false)
  };

  const handleStoreChange = (event, values) => {

    setSelectedStore(values)
    updateSelectedStore(values)
  };

  const handleTemplateChange = (event, values) => {

    
    setSelectedTemplate(values)
    console.log(values)
    updateSelectedTemplate(values)
  };

  function checkIfStoreSelected(store) {
    if (selectedStore && selectedStore.name === store) return true;
    return false;
  }

  function checkIfTemplateSelected(template) {
    if (selectedTemplate && selectedTemplate.name === template) return true;
    return false;
  }

  function handleActionVisits(store_id, template_id) {

    navigate("/app/actions/" + store_id + "/" + template_id)
  }

  const groupByTemplateType = (option) => option.categoryName;
  //const groupByRegion = (option) => option.region;
  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortTemplates = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  function handleStartStoreVisit() {

    if (!selectedStore || !selectedTemplate) {
      setBarMessage({
        type: "error",
        message: "Select a store and template before starting.",
      })

      setOpenBar(true)
      return true;
    }

    if (selectedStore.name.length > 0 && selectedTemplate.name.length > 0) {
      navigate("/app/storevisit/start/" + selectedStore.cloud_id + "/" + selectedTemplate.cloud_id)
    }
    else {
      setErrorMessage("Select a template and store before starting a visit");
      setShowError(true)
    }
  }


  return (
    <PageHolder>
      <Header>
        <HeaderHolder>
          Start New Visit
          <TemplateDescription>Choose a location and template to start a visit</TemplateDescription>
        </HeaderHolder>

      </Header>

      {(storeDetails?.stores?.length === 0 || storeDetails?.templates?.length === 0) ?
        <>
          <EmptyScreen
            visible={true}
            imgSrc={AuditImg}
            title="Hey, it looks like you still need to do something"
            contents={"Try creating your first template and location before starting a visit."} />

          <TemplateFormButtonStack align="right">
            {storeDetails.stores.length === 0 && <Button  onClick={() => navigate("/app/locations")}>Create location</Button>}
            {storeDetails.templates.length === 0 && <Button >Create template</Button>}
          </TemplateFormButtonStack>
        </>

        :

        <PageContainer empty_shadow={true}>
          <Loader show={showLoading} />

          <Snackbar open={openBar} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
              {barMessage.message}
            </Alert>
          </Snackbar>

          <Autocomplete

            sx={{
              marginTop: '10px',
              marginBottom: '10px',
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
              },
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                }
              }
            }}
            id="stores-list"
            options={[...stores].sort((a, b) => {

              
              const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
              if (groupSort && groupSort === 0) {
                return sortTemplates(a, b);
              }
              return groupSort;
            })}
            groupBy={groupByRegion}

            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={selectedStore}
            onChange={handleStoreChange}
            

            getOptionLabel={(option) => option.name}

            renderGroup={(params) => (
              <li key={params.key}>

                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            

            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Choose a location"
                sx={{
                  borderRadius: '30px',
                  backgroundColor: `${Colors.white}`,
                  padding: '2px 5px',
                  outline: 'none',
                  boxShadow: `rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px;`

                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledSearchIcon />
                    </InputAdornment>
                  ),
                  type: 'Choose a location',
                  style: { 'fontSize': '0.8rem', 'fontWeight': '400', 'fontFamily': 'Poppins' }
                }}
              />
            )}
          />

          <Autocomplete
            sx={{
              marginTop: '5px',
              marginBottom: '10px',
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none"
              },
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none"
                }
              }
            }}
            id="templates-list"
            options={[...templates]?.sort((a, b) => {
              const groupSort = groupByTemplateType(a).localeCompare(groupByTemplateType(b));
              if (groupSort === 0) {
                return sortTemplates(a, b);
              }
              return groupSort;
            })}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={handleTemplateChange}
            groupBy={groupByTemplateType}
            value={selectedTemplate}
            getOptionLabel={(option) => option.name}
            renderGroup={(params) => (
              <li key={params.key}>

                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Choose a template"
                sx={{
                  borderRadius: '30px',
                  backgroundColor: `${Colors.white}`,
                  padding: '2px 5px',
                  outline: 'none',
                  boxShadow: `rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px;`
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledFilterListIcon fontSize='small' />
                    </InputAdornment>
                  ),
                  type: 'Choose a template',
                  style: { 'fontSize': '0.8rem', 'fontWeight': '400', 'fontFamily': 'Poppins' }
                }}
                key={params.inputProps.id} // Add the key here
              />
            )}
          />

          {outstandingActions > 0 &&
            <VisitActionsContainer onClick={() => handleActionVisits(selectedStore.cloud_id, selectedTemplate.cloud_id)}>
              <StyledAlertRoundedIcon fontSize='large' />
              <VisitActionsText>
                There are {outstandingActions} action(s) waiting to be completed on this template.

              </VisitActionsText>


            </VisitActionsContainer>}

          <TemplateFormButtonStack align="right">
            {storeDetails.permissions.visits.add && <Button align="right" style={{ 'marginTop': '20px' }} onClick={() => handleStartStoreVisit()}><StyledNewIcon />Start Visit</Button>}

          </TemplateFormButtonStack>


        </PageContainer>}


    </PageHolder>
  )
}

export default StorevisitStart