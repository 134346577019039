import React, { Fragment, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, Button, Colors, GroupHeader, GroupItems } from './styled/styled.container'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, where, getDocs, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFilterContainer, TemplateFormButtonStack } from './styled/styled.templateform';
import Checkbox from '@mui/material/Checkbox';
import Chart from "react-apexcharts";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment/moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from './Loader';
import styled from 'styled-components';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const columns = [
  { field: "id", headerName: "ID", hide: true },
  { field: 'store_name', headerName: 'Location', minWidth: 150, flex: 1 },
  { field: 'region', headerName: 'Location Group', minWidth: 150, flex: 1 },
  { field: 'sub_region', headerName: 'Sub Group', minWidth: 150, flex: 1 },

  { field: 'template_name', headerName: 'Template', minWidth: 150, flex: 1 },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 150,
    flex: 1

  },
  {
    field: "score", headerName: "Score",
    minWidth: 100,
    flex: 1

  },
  {
    field: "startTime", headerName: "Start Time",
    minWidth: 150,
    flex: 1
  },
  {
    field: "endTime", headerName: "End Time",
    minWidth: 150,
    flex: 1
  },
  {
    field: "time", headerName: "Time",
    minWidth: 150,
    flex: 1
  },
  {
    field: "actions_outstanding",
    headerName: "Outstanding Actions",
    minWidth: 170,
    flex: 1
  }
];

function ReportsVisitScores(props) {
  const { storeDetails, stores, templates } = props;


  // holds selected stores and template names only
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  // holds selected store name + cloud id
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [visits, setVisits] = useState();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [filters, setFilters] = useState(true);
  const [filterActions, setFilterActions] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
  const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [aggregateLocations, setAggregateLocations] = useState(true)
  const navigate = useNavigate();


  async function generateVisitChart(visits, templateIdList, storeIdList) {

    if (visits && visits.length > 0) {


      // sort the list so that points are projected in order
      let visitDates = [...visits].sort((a, b) => {
        //console.log(a.date, b.date, moment(a.date).isBefore(moment(b.date)))
        //console.log(moment(a.date).isValid())

        if (moment(a.date, "DD-MM-YYYY").isBefore(moment(b.date, "DD-MM-YYYY"))) return -1;
        else if (moment(a.date, "DD-MM-YYYY").isAfter(moment(b.date, "DD-MM-YYYY"))) return 1;
        else return 0;
      }).map(visit => visit.date)

      // remove duplicates from different visits -> templates
      // eg: Store Visit, Regional Visit and PS, Bizana
      visitDates = [...new Set(visitDates)]


      // format my moment visit.date into this format 2018-09-19T00:00:00.000Z
      //const visitDatesGraphLabels = visitDates.map(date => moment(date, "DD-MM-YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
      //console.log(visitDatesGraphLabels)

      setSeries([])
      const elementList = [];


      await Promise.all(templateIdList.map(async (templateElement) => {
        await Promise.all(storeIdList.map(async (storeElement) => {

          const visitScores = visitDates.map((date, i) => {
            const scoreElement = visits.filter(visit => visit.store_id === storeElement.cloud_id && visit.date === date && visit.template_id === templateElement.cloud_id)

            return {
              x: moment(date, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY HH:mm"), // convert to JavaScript Date object
              y: scoreElement[0]?.score || null
            }
          })

          // check if any valid score for that template -> store
          const scoresEmpty = visitScores.every(element => element === null);
          if (!scoresEmpty) {
            const element = {
              name: storeElement.name + " (" + templateElement.name + ")",
              data: visitScores
            }

            elementList.push(element);

          }

        }))
      }))

      //console.log("Element list", elementList)
      setSeries(elementList);

      const visitOptions = {
        chart: {
          type: 'area',

          height: 400,
          width: "100%",

        },
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true
        },
        xaxis: {

          type: "datetime",
          tickPlacement: 'on',
          labels: {
            show: true,

          }
        },
        grid: {
          padding: {
            left: 20,
            right: 20
          }
        },
        yaxis: {
          title: {
            text: 'Avg Score'
          },
        },
        tooltip: {
          followCursor: true,
          x: {
            format: "DD-MM-YYYY HH:mm",
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              
              return w.globals.categoryLabels[value - 1]
              },
          }
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0,
            opacityTo: 0.75,
            stops: [0, 90, 100]
          },
        },
        title: {
          text: "Location Performance"
        },
      };

      setOptions(visitOptions);

    }
  }

  async function getVisits() {

    let templateIDList = [];
    let storeIDList = [];
    setVisits([]);

    if (selectedStores.length == 0 || selectedTemplates.length == 0) {

      if (selectedStores.length == 0) storeIDList = stores;
      else {
        storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name === store.name) >= 0)).map((value) => {
          return value;
        })
      }
      if (selectedTemplates.length == 0) templateIDList = templates;
      else {
        templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x.name === template.name) >= 0)).map((value) => {
          return value;
        })
      }

    }

    else {
      templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x.name === template.name) >= 0)).map((value) => {
        return value;
      })
      storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name === store.name) >= 0)).map((value) => {
        return value;
      })
    }


    setSelectedStoresInfo(storeIDList);
    setSelectedTemplatesInfo(templateIDList);

    const dateTo = moment(filterDateTo).add("1", "days").toISOString();
    
    const visitList = []

    const promises = [];
    templateIDList.forEach(template => {
      storeIDList.forEach(async store => {
        const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"),

          where("template_id", "==", template.cloud_id),
          where("store_id", "==", store.cloud_id),
          where("scorable", "==", true),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo));

        const promise = getDocs(q).then(visitDocs => {
          const visitItems = visitDocs.docs.map(visit => {
            const visitID = visit.id;
            const visitData = visit.data();
            
            return {
              ...visitData,
              score: visitData.score,
              date: moment(visitData.date).format("DD-MM-YYYY HH:mm"),
              id: visitID,
              store_name: store.name,
              region: store.region,
              sub_region: store.sub_region,
              template_name: template.name,
              startTime: moment(visitData.startTime).format("DD/MM/YYYY HH:mm"),
              endTime: moment(visitData.endTime).format("DD/MM/YYYY HH:mm"),
              time: moment.duration(moment(visitData.startTime, "DD-MM-YYYY HH:mm").diff(moment(visitData.endTime, "DD-MM-YYYY HH:mm"))).humanize()
            }
          })
          visitList.push(...visitItems);
        });
        promises.push(promise);
      })
    })
    await Promise.all(promises);


    const sortedVisitByDateTime = [...visitList].sort((a, b) => {
      if (moment(a.date, "DD-MM-YYYY HH:mm").isBefore(moment(b.date, "DD-MM-YYYY HH:mm"))) return -1;
      else if (moment(a.date, "DD-MM-YYYY HH:mm").isAfter(moment(b.date, "DD-MM-YYYY HH:mm"))) return 1;
      else return 0;
    })

    setVisits(sortedVisitByDateTime)
    generateVisitChart(visitList, templateIDList, storeIDList);
  }

  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleStoreChange = (event, values) => {

    //const storeNames = values.map(value => value.name);
    setSelectedStores(values);
  };

  const handleTemplateChange = (event, values) => {

    //const templateNames = values.map(value => value.name);
    //console.log(templateNames);
    setSelectedTemplates(values);
  };

  function handleGroupLocationsClick(region) {
    const locationsGroup = stores.filter(store => store.region === region);
    setSelectedStores(locationsGroup);
  }

  function handleGroupTemplateClick(category) {
    // no need for group templates

    // const templatesGroup = templates.filter(template => template.categoryName === category);
    // setSelectedTemplates(templatesGroup);

  }

  const handleRowClick = (params) => {
    navigate("/app/storevisit/report/" + params.row.id);
  };

  function handleSearchFilter() {
    getVisits();
  }

  return (
    <Fragment>

      <TemplateFormFieldRow align="right" style={{ margin: '10px 0px', marginBottom: '5px' }}>
        <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>
      </TemplateFormFieldRow>

      {filters &&
        <TemplateFilterContainer style={{ marginTop: '5px' }}>
          {stores && stores.length > 0 &&
            <Autocomplete
              multiple
              value={selectedStores}
              id="store-tags"
              options={[...stores].sort((a, b) => {


                const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                if (groupSort && groupSort === 0) {
                  return sortRegions(a, b);
                }
                return groupSort;
              })}
              groupBy={groupByRegion}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={handleStoreChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a location"
                  placeholder="Add a location"
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>

                  <GroupHeader onClick={() => handleGroupLocationsClick(params.group)}>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />}

          {/* <TemplateFormFieldRow align="right">
            <FormControlLabel
              control={<Checkbox checked={aggregateLocations} name="aggregateLocations" />}
              label="Aggregate Locations"
            />
          </TemplateFormFieldRow> */}

          {templates && templates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
              multiple
              value={selectedTemplates}

              id="template-tags"
              options={[...templates].sort((a, b) => {
                const groupA = a.categoryName;
                const groupB = b.categoryName;
                if (groupA === groupB) {
                  return a.name.localeCompare(b.name);
                }
                return groupA.localeCompare(groupB);
              })}
              isOptionEqualToValue={(option, value) => option.name === value.name}

              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              groupBy={(option) => option.categoryName}
              onChange={handleTemplateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a template"
                  placeholder="Add a template"
                />
              )}

              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader onClick={() => handleGroupTemplateClick(params.group)}>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />}

          <TemplateFormFieldDateContainer>
            <TextField
              sx={{ 'marginRight': '10px' }}
              label="Period from"
              size='small'
              type="date"
              value={filterDateFrom}
              onChange={(e) => setFilterDateFrom(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Period to"
              type="date"
              size='small'
              fullWidth
              value={filterDateTo}
              onChange={(e) => setFilterDateTo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />


          </TemplateFormFieldDateContainer>
          <TemplateFormButtonStack align="right">
          <Button disabled={selectedStores.length === 0 || selectedTemplates.length === 0} highlighted onClick={handleSearchFilter} style={{ margin: '10px 0px' }} >Run Report</Button>

          </TemplateFormButtonStack>
          
        </TemplateFilterContainer>}

      <Chart
        options={options}
        series={series}
        type="area"
        height="350" />

      {visits && visits.length > 0 &&
        <TableContainer>
          <DataGrid
            autoHeight
            sx={{ 'border': '0' }}
            components={{ Toolbar: GridToolbar }}
            rows={visits}
            columns={columns}
            onRowClick={handleRowClick}
            getRowId={(row) => row.id}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </TableContainer>


      }
    </Fragment>
  )
}

export default ReportsVisitScores