import React, { Fragment, useContext, useEffect, useState } from 'react'
import Header from './Header';
import Loader from './Loader';
import { Button, HeaderHolder, HeaderTitle, PageContainer, PageHolder, StyledDeleteIcon, StyledNewIcon } from './styled/styled.container';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { auth, db} from "./firebase";
import { TableContainer } from './styled/styled.container';
import {collection, onSnapshot, query, doc, getDoc, where, getDocs, orderBy} from "firebase/firestore";
import moment from 'moment';
import { TemplateDescription, TemplateFormButtonStack, TemplateFormFieldRow } from './styled/styled.templateform';
import { useNavigate } from 'react-router-dom';
import CheckCircle from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Colors } from './styled/styled.container';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteReportDialog from './DeleteReportDialog';
import AuthContext from './AuthContext';
import EmptyScreen from './EmptyScreen';
import EmptyAuditImg from "./imgs/empty_drafts.png"
import LocationImg from "./imgs/location_img.png"

const columns = [
    { field: "cloud_id", headerName: "ID", hide: true},
    { 
        field: "status", 
        headerName: "Status",
        valueGetter: (params) => {
            
            const actions_outstanding = params.row.actions_outstanding;
            const avg_score = params.row.avg_score;
            //console.log(params.row.template_type, params.row.visit_status)
            if(params.row.template_type === "Log" && params.row.visit_status === "Open") return "warning"
            
            //if(avg_score !== "Not Scorable" && avg_score <= 70) return "warning"

            if(actions_outstanding === 0) return "ok"
            else return "warning"

        },
        renderCell: (params) => {
            const status = params.value;
            return status === "ok" ? <CheckCircle style={{ color: `${Colors.blue}` }} /> : <WarningIcon style={{ color: `${Colors.highlight}` }} />;
        },
        minWidth: 70,
        
        
    },
    { field: 'name', headerName: 'Template', minWidth: 200, flex: 1},
    {
      field: "no_visits", 
      headerName: "Visits Complete", 
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
      
        let cellColor = "" // Default color
        let noCompleted = parseInt(params.row.no_visits)
        if(noCompleted === 0) cellColor = `${Colors.blue}`
        else if(noCompleted > 0 && noCompleted < 3) cellColor = `${Colors.orange}`
        else if(noCompleted >= 3) cellColor = `${Colors.highlight}`

        return (
          <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
            {noCompleted}
          </div>
        );
      },
  },
    { field: "template_type", headerName: "Type", minWidth: 100, flex: 1,
    renderCell: (params) => {
      
      let cellColor = ""
      const type = params.row.template_type;
      if(type === "Log") cellColor = `${Colors.green}`
      else if(type === "Audit") cellColor = `${Colors.blue}`

      return (
        <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {type}
        </div>
      );
    },
    },
    {
    field: 'last_visit_date',
    headerName: 'Last Visit',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {
      
      let cellColor = `${Colors.dark_grey_highlight}`
      return (
        <div style={{ minWidth: '80px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
          {params.value}
        </div>
      );
    },

    },
    
    {
        field: "avg_score", headerName: "Avg Score", 
        minWidth: 100,
        flex: 1,
        valueFormatter: ({ value }) => value === "Not Scorable" ? "Not Scorable" : `${value}%`,
    
    },
    {
        field: "actions_outstanding",
        headerName: "Actions Due",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
      
          let cellColor = "";
          const outstanding = parseInt(params.row.actions_outstanding);
          if(outstanding === 0) cellColor = `${Colors.green}`
          else if(outstanding > 0 && outstanding < 3) cellColor = `${Colors.orange}`
          else if(outstanding >= 3) cellColor = `${Colors.highlight}`

          return (
            <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
              {outstanding}
            </div>
          );
        },
    }
];

const draftColumns = [
    { field: "id", headerName: "ID", hide: true},
    { field: 'store_name', headerName: 'Location', minWidth: 150, flex: 1},
    { field: 'template_name', headerName: 'Template', minWidth: 200, flex: 1},
    {
    field: 'visit_date',
    headerName: 'Date',
    minWidth: 150,
    flex: 1
    },
    {
        field: "visit_type",
        headerName: "Visit Type",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => {
      
          let cellColor = ""
          const type = params.row.visit_type;
          if(type === "Log") cellColor = `${Colors.green}`
          else if(type === "Audit") cellColor = `${Colors.blue}`
    
          return (
            <div style={{ minWidth: '60px', textAlign: 'center', fontSize: '0.7rem', color: "white", backgroundColor: cellColor, padding: '5px 10px', borderRadius: '10px' }}>
              {type}
            </div>
          );
        },
    },
    ];


const logFilterModel = {
    items: [{
        columnField: "template_type",
        operatorValue: "equals",
        value: "Log"
    }]
  };

  const auditFilterModel = {
    items: [{
        columnField: "template_type",
        operatorValue: "equals",
        value: "Audit"
    }]
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function StorevisitViewUnpublished(props) {
    const {showVisitType, updateSelectedStore, updateSelectedTemplate} = props;
    const {storeDetails} = useContext(AuthContext)
    const [templates, setTemplates] = useState(storeDetails.user_specific_templates);
    const [stores, setStores] = useState(storeDetails.stores);
    const [storeVisits, setStoreVisits] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [filterModel, setFilterModel] = useState({items: []});
    const [draftVisits, setDraftVisits] = useState([])
    const [filterShowLogs, setFilterShowLogs] = useState(false)
    const [filterShowAudits, setFilterShowAudits] = useState(false)
    const [selectedDraftRows, setSelectedDraftRows] = useState([])
    const [openBar, setOpenBar] = React.useState(false);
    const [barMessage, setBarMessage] = useState({})
    const [draftGridRefresh, setDraftGridRefresh] = useState(false);
    const [showDeleteReportDialog, setShowDeleteReportDialog] = useState(false)
    const [showEmptyScreen, setShowEmptyScreen] = useState(false);

    const [pageSize, setPageSize] = useState(10);

    const navigate = useNavigate()

    // useEffect(() => {
    //     if(storeVisits.length > 0) {
    //         if (showVisitType && showVisitType === 'Log') setFilterModel(logFilterModel)
    //         else if (showVisitType && showVisitType === 'Audit') setFilterModel(auditFilterModel);
    //     }
    //   }, [showVisitType, storeVisits]);

    useEffect(() => {

        
        async function getDetails() {
            setShowLoading(true)
            const template_items = storeDetails.user_specific_templates //await getTemplates();
            const store_items = storeDetails.stores //await getStores();
            
            getDraftVisits(store_items, template_items);
            //getAllDraftVisits()
            setShowLoading(false)
        }

        if(storeDetails.rootID.length > 0) getDetails()

    }, [storeDetails])


    async function getDraftVisits(store_items, template_items) {
        //console.log("Reading draft visits");
        setDraftVisits([]);
        const request = indexedDB.open("storecall_audits", 1);
      
        request.onerror = function(event) {
          setShowEmptyScreen(true)
          console.log("Database error: " + event.target.errorCode);
        }
      
        request.onsuccess = function(event) {
          const db = event.target.result;
      
          if (db.objectStoreNames.contains("audits")) {
            const transaction = db.transaction(["audits"], "readonly");
            const objectStore = transaction.objectStore("audits");

            const draftList = [];
      
            objectStore.openCursor().onsuccess = function(event) {
              const cursor = event.target.result;

              if (cursor) {
                //console.log(cursor.value); // log the object
                if (cursor.value.root_id === storeDetails.rootID) {
                  const store_id = cursor.value.store_id;
                  const template_id = cursor.value.template_id;
                  const visit_date = cursor.value.visit_date;
                  const visit_type = cursor.value.visit_type;
                  //const sections = cursor.value.sections;
                  //console.log("sections", sections)

                  //console.log("Store items", store_items)
                  //console.log("Template items", template_items)
      
                  const store = store_items.find(store => store.cloud_id === store_id);
                  const template = template_items.find(template => template.cloud_id === template_id);

                  //console.log("Found store", store)
                  //console.log("Found template", template)
                  //console.log("Looking for template", template_id)
                  let storeName = "";
                  let templateName = "";
      
                  storeName = store_items.find(store => store.cloud_id === store_id)?.name;
                  templateName = template_items.find(template => template.cloud_id === template_id)?.name;

                  if(storeName && templateName) {
                    const visitElement = {
                      id: generateRandomString(),
                      store_id: store_id,
                      template_id: template_id,
                      store_name: storeName,
                      template_name: templateName,
                      visit_date: visit_date,
                      visit_type: visit_type
                    };
    
                    draftList.push(visitElement);
                    //setDraftVisits(prevState => [...prevState, visitElement])
                  }

                  cursor.continue();

                    
      
                } else cursor.continue(); 
              } else {

                transaction.oncomplete = function() {
                  db.close();

                  if(draftList.length > 0) {
                    const sortedDraftList = draftList.sort((a, b) => {
                      return moment(b.visit_date).diff(moment(a.visit_date))
                    });
                    setDraftVisits(sortedDraftList)
                  }
                  else {
                    setShowEmptyScreen(true)
                  }


                };
              }
            };
            objectStore.openCursor().onerror = console.error;
          } else {
            console.log("Audits db not found");
            setShowEmptyScreen(true)
          }
        };
      }


    function generateRandomString() {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = 0; i < 10; i++) {
          result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
      }



    const handleSelectionModelChange = (selectionModel) => {
        
        if(selectionModel.length > 0) {
            const selectedItems = selectionModel.map(element => {
                const draftVisit = draftVisits.filter(visit => visit.id === element)[0]
                return draftVisit
            })

            setSelectedDraftRows(selectedItems)
        }
        else setSelectedDraftRows([]);
      };

    const handleDeleteSelectedDraftVisits = async () => {
        if (selectedDraftRows.length > 0) {
          setShowDeleteReportDialog(false);
          setShowLoading(true);
          
          const deletePromises = selectedDraftRows.map(async (draftVisit) => {
            return await deleteAuditFromDB(draftVisit.store_id, draftVisit.template_id)
          });
      
          Promise.all(deletePromises)
            .then(() => {
              setBarMessage({
                type: 'info',
                message: `Draft(s) have been successfully deleted.`,
              });
              setOpenBar(true);
              
              const filteredDraftVisits = draftVisits.filter(
                (visit) => !selectedDraftRows.some((selected) => selected.id === visit.id)
              );

              setDraftVisits(filteredDraftVisits)
              setSelectedDraftRows([]); // clear selected rows after deletion
              setShowLoading(false);
              
              if(filteredDraftVisits.length === 0) setShowEmptyScreen(true)
              
              //getStoresTemplatesInfo();
            })
            .catch((error) => {
              setBarMessage({
                type: 'error',
                message: `Error deleting drafts: ${error.message}`,
              });
              setOpenBar(true);
              setShowLoading(false);
            });
        }
      };

      function deleteAuditDB() {
        const request = indexedDB.deleteDatabase("storecall_audits");
    
        request.onsuccess = function() {
            console.log("Database deleted successfully");
        };
    
        request.onerror = console.error;
    }

      function deleteAuditFromDB(storeId, templateId) {
        return new Promise((resolve, reject) => {
        const request = indexedDB.open('storecall_audits', 1);
        request.onerror = console.error;
        request.onsuccess = function(event) {
          const db = event.target.result;
          const transaction = db.transaction(['audits'], 'readwrite');
          const objectStore = transaction.objectStore('audits');
          const index = objectStore.index('store_template');
          //console.log("storeID", storeId, templateId, storeDetails.rootID)
          const keyRange = IDBKeyRange.only([storeId, templateId, storeDetails.rootID]);
          const deleteRequest = index.openCursor(keyRange);
      
          deleteRequest.onsuccess = function(event) {
            const cursor = event.target.result;
            if (cursor) {
              cursor.delete();
              console.log("Draft audit deleted")
              cursor.continue();
            }
            else resolve()
          };
      
          deleteRequest.onerror = reject;
      
          transaction.oncomplete = function() {
            //console.log('db closed');
            db.close();
          };
        };
      })
      }

    const handleDraftRowClick = (params) => {
        const store = stores.filter(store => store.cloud_id === params.row.store_id)[0];
        const template = templates.filter(template => template.cloud_id === params.row.template_id)[0];
        //updateSelectedStore(store)
        //updateSelectedTemplate(template)
        
        //console.log("You clicked " + params.row.store_id + " on " + params.row.visit_date)
        navigate("/app/storevisit/start/" + params.row.store_id + "/" + params.row.template_id + "/draft");
    };

    const handleClose = (event, reason) => {
      
        setOpenBar(false);
        setShowLoading(false)
        setSelectedDraftRows([])
        //if(barMessage.result === "success") navigate("/storevisit")
      };


    return (
        <PageHolder>

              <Header>
                  <HeaderHolder>
                    Unpublished Visits
                    <TemplateDescription>Each time you run a visit, it will be saved here until you publish it</TemplateDescription>
                  </HeaderHolder>
                </Header>
            
            <Loader show={showLoading} />
            <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
            <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
              {barMessage.message}
            </Alert>
            </Snackbar>

            <DeleteReportDialog
            open={showDeleteReportDialog}
            setOpen={setShowDeleteReportDialog}
            title="Delete Draft(s)"
            content="Are you sure you want to delete these unpublished visits?"
            action={handleDeleteSelectedDraftVisits} />

            {storeDetails.stores.length === 0 ?
            <>
            <EmptyScreen 
            visible={true} 
            imgSrc={LocationImg}
            title="Hey, it looks like you still need to create a location" 
            contents={"Once you create a location and run a visit, you can find your unpublished visits here."} />
            <TemplateFormButtonStack align="right">
            <Button onClick={() => navigate("/app/locations/create")}>Create Location</Button>
            </TemplateFormButtonStack>
           
            </>
            
            :

            showEmptyScreen && <EmptyScreen 
              title="It looks like you don't have any unpublished visits."
              contents={"Each time you run a visit, it will be saved here until you publish it"}
              visible={true} 
              imgSrc={EmptyAuditImg} />}

            {!showEmptyScreen && storeVisits.length > 0 && <Fragment>
                
            
            </Fragment>}

            {draftVisits && draftVisits.length > 0 &&
            <Fragment>

            <PageContainer empty_shadow={true} style={{'padding': '0px', 'marginTop': '0px'}}>
            
            <TableContainer style={{'margin': '0px'}}>
                <DataGrid
                    autoHeight
                    sx={{'border': '0'}}
                    
                    key={draftGridRefresh}
                    rows={draftVisits}                    
                    //getRowClassName={getRowClassName}
                    //rows={filterActions===true ? storeVisits.filter(visit => visit.actions_outstanding > 0) : storeVisits}
                    columns={draftColumns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'visit_date', sort: 'desc' }],
                        },
                        }}
                    onRowClick={handleDraftRowClick}
                    getRowId={(row) => row.id}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    checkboxSelection
                    onSelectionModelChange={handleSelectionModelChange}
                    checkboxSelectionProps={{
                        // add Checkbox component as the render function
                        renderCell: (params) => (
                        <Checkbox
                            color="primary"
                            checked={params.value}
                            onChange={(event) => params.api.selectRow(params.id, event.target.checked)}
                        />
                        ),
                    }}
                />
            </TableContainer>
            {selectedDraftRows.length > 0 && 
            <TemplateFormFieldRow align="right" style={{'marginTop': '10px'}}>
                
            </TemplateFormFieldRow>}
            </PageContainer>
            </Fragment>}

            <TemplateFormFieldRow align="right" style={{marginTop: '10px', marginBottom: '15px'}}>
                
                {storeDetails.permissions.visits.add && selectedDraftRows.length > 0 &&
                <Button highlighted style={{'marginRight': '10px'}} onClick={() => setShowDeleteReportDialog(true)}><StyledDeleteIcon /> Delete Draft</Button>}
            </TemplateFormFieldRow>
            
        </PageHolder>
        

    )
}

export default StorevisitViewUnpublished