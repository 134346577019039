import React, { Fragment, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, Button } from './styled/styled.container'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, where, getDocs, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

import Chart from "react-apexcharts";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from './Loader';
import { TemplateFormFieldRow, TemplateFormFieldFilters, TemplateFormFieldContainer, TemplateFormFieldDateContainer, TemplateFilterContainer } from './styled/styled.templateform';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", hide: true },
  { field: 'store_name', headerName: 'Location Name', minWidth: 150, flex: 1 },
  { field: 'region', headerName: 'Location Group', minWidth: 150, flex: 1 },
  { field: 'sub_region', headerName: 'Sub Group', minWidth: 150, flex: 1 },
  { field: 'template_name', headerName: 'Template', minWidth: 150, flex: 1 },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 100,
    flex: 1

  },
  {
    field: "section_name",
    headerName: "Section",
    minWidth: 100,
    flex: 1
  },
  {
    field: "score", headerName: "Score",
    minWidth: 100,
    flex: 1

  },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function ReportsSectionScores(props) {
  const { storeDetails, stores, templates } = props;

  // holds selected stores and template names only
  const [selectedStores, setSelectedStores] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState('');

  // holds selected store name + cloud id
  const [selectedStoresInfo, setSelectedStoresInfo] = useState([]);
  const [selectedTemplatesInfo, setSelectedTemplatesInfo] = useState([]);
  const [visits, setVisits] = useState([]);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [error, setError] = useState(false);
  const [sectionItems, setSectionItems] = useState([]);
  const [gridItems, setGridItems] = useState([]);
  const [filters, setFilters] = useState(true);
  const [filterActions, setFilterActions] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState((moment().year() - 1) + "-01-01");
  const [filterDateTo, setFilterDateTo] = useState(moment().format("YYYY-MM-DD"));
  const navigate = useNavigate();


  useEffect(() => {

    if (visits.length > 0) {

      // sort the list so that points are projected in order
      let visitDates = [...visits].sort((a, b) => {
        if (moment(a.date, "DD-MM-YYYY").isBefore(moment(b.date, "DD-MM-YYYY"))) return -1;
        else if (moment(a.date, "DD-MM-YYYY").isAfter(moment(b.date, "DD-MM-YYYY"))) return 1;
        else return 0;
      }).map(visit => visit.date)

      // const sortedVisits = [...visits].sort((a,b) => {
      //   if(a.date < b.date) return 1;
      //   else if(a.date > b.date) return -1;
      //   else return 0;
      // })

      // remove duplicates from different visits -> templates
      // eg: Store Visit, Regional Visit and PS, Bizana

      // commented out below because want to show all visits even if duplicate days
      //visitDates = [...new Set(visitDates)]

      setSeries([])

      // get all section names even if duplicates
      // this is to allow if visit 1 has 2 sections of Template X
      // but visit 2 has 3 sections of Template X (template was ammended)
      const sectionNamesAll = [];
      visits.forEach(visit => {
        visit.sections.forEach(section => {

          if (section.scorable)
            sectionNamesAll.push(section.name)
        })
      })



      // Get only unique section names of Template X
      const sectionNames = [...new Set(sectionNamesAll)]


      //const sectionNames = visits[0].sections.map(section => section.name);
      setSectionItems(sectionNames);

      sectionNames.forEach(sectionName => {
        const sectionScores = [];
        visits.forEach(visit => {

          const sectionElementArray = visit.sections.filter(section => section.name === sectionName);

          if (sectionElementArray.length > 0) {
            const sectionElement = sectionElementArray[0];


            sectionScores.push(sectionElement.score)

            const gridItem = {
              cloud_id: visit.cloud_id,
              id: sectionElement.cloud_id + visit.date + Math.random() * 10000,
              store_name: selectedStores[0].name,
              region: selectedStores[0].region,
              sub_region: selectedStores[0].sub_region,
              template_name: selectedTemplates[0].name,
              date: visit.date,
              section_name: sectionElement.name,
              score: sectionElement.score
            }

            if (!(gridItems.filter(item => item.id === gridItem.id).length > 0))
              setGridItems(prevState => [...prevState, gridItem])
          } else sectionScores.push(null);

        })
        const sectionItem = {
          name: sectionName,
          data: sectionScores
        }

        setSeries(prevState => [...prevState, sectionItem])

      })


      setOptions({
        chart: {
          id: 'bar-chart',
          type: 'bar',
          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              dataLabels: {
                average: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                }
              }


            }
          }
        },
        title: {
          text: "Location Section Performance"
        },
        xaxis: {
          categories: visitDates,
          labels: {
            formatter: function (val) {
              return ""
            }
          }

        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 50
        }
      })
    }

  }, [visits])


  const groupByRegion = (store) => `${store.region} (${store.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  function handleCloseError() {
    setError();
  }

  async function getVisits() {

    let templateIDList = [];
    let storeIDList = [];
    setVisits([]);



    if (selectedStores.length == 0 || selectedTemplates.length == 0) {
      setError("Choose a location and template first.")
      return;
    }



    else {

      templateIDList = templates.filter(template => (selectedTemplates.findIndex(x => x.name === template.name) >= 0)).map((value) => {
        return value;
      })
      storeIDList = stores.filter(store => (selectedStores.findIndex(x => x.name === store.name) >= 0)).map((value) => {
        return value;
      })


    }

    setSelectedStoresInfo(storeIDList);
    setSelectedTemplatesInfo(templateIDList);
    const dateTo = moment(filterDateTo).add("1", "days").toISOString();

    templateIDList.forEach(template => {
      storeIDList.forEach(async store => {

        const q = query(collection(db, "stores/" + storeDetails.rootID + "/visits"),
          where("template_id", "==", template.cloud_id),
          where("store_id", "==", store.cloud_id),
          where("scorable", "==", true),
          where("date", ">=", filterDateFrom),
          where("date", "<=", dateTo));

        const visitDocs = await getDocs(q);
        visitDocs.docs.forEach(async visit => {
          const visitData = visit.data();

          const sectionQueryCollectionRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits/" + visit.id + "/sections"),
            orderBy("key", "asc"),
          );
          const sectionDocs = await getDocs(sectionQueryCollectionRef);
          const sectionData = sectionDocs.docs.map(doc => {
            const docData = doc.data();
            const sectionID = doc.id;
            return {
              ...docData,
              cloud_id: sectionID,
              name: docData.name,
              score: docData.score + "%"
            }
          })

          setVisits(prevState => [...prevState, {
            ...visitData,
            cloud_id: visit.id,
            date: moment(visitData.date).format("DD-MM-YYYY"),
            name: store.name,
            sections: sectionData
          }])
        })

      })
    })
  }

  const handleStoreChange = (event, value) => {

    if (value) setSelectedStores([value]);
    else setSelectedStores([])
  };

  function handleGroupLocationsClick(region) {
    const locationsGroup = stores.filter(store => store.region === region);
    setSelectedStores(locationsGroup);
  }

  const handleTemplateChange = (event, value) => {
    if (value) setSelectedTemplates([value])
    else setSelectedTemplates([]);
  };

  const handleRowClick = (params) => {

    navigate("/app/storevisit/report/" + params.row.cloud_id);
  };

  function handleSearchFilter() {
    setGridItems([]);
    getVisits();
  }

  return (
    <Fragment>

      <Snackbar open={error} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Choose a location and template first.
        </Alert>
      </Snackbar>

      <TemplateFormFieldRow align="right" style={{ margin: '10px 0px' }}>
        <TemplateFormFieldFilters onClick={() => setFilters(prevState => !prevState)}>{filters == true ? `Hide filters` : `Show filters`}</TemplateFormFieldFilters>
      </TemplateFormFieldRow>

      {filters &&
        <TemplateFilterContainer style={{ marginTop: '5px' }}>
          {stores && stores.length > 0 &&
            <Autocomplete

              id="store-tags"
              options={[...stores].sort((a, b) => {


                const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                if (groupSort && groupSort === 0) {
                  return sortRegions(a, b);
                }
                return groupSort;
              })}
              groupBy={groupByRegion}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              filterSelectedOptions
              onChange={handleStoreChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a location"
                  placeholder="Add a location"
                />
              )}
            />}

          {templates && templates.length > 0 &&
            <Autocomplete
              sx={{ 'marginTop': '10px', 'marginBottom': '10px' }}
              id="template-tags"
              options={[...templates].sort((a, b) => {
                const groupA = a.categoryName;
                const groupB = b.categoryName;
                if (groupA === groupB) {
                  return a.name.localeCompare(b.name);
                }
                return groupA.localeCompare(groupB);
              })}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              groupBy={(option) => option.categoryName}
              onChange={handleTemplateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  label="Choose a template"
                  placeholder="Add a template"
                />
              )}
            />}

          <TemplateFormFieldDateContainer>
            <TextField
              sx={{ 'marginRight': '10px' }}
              label="From Date"
              type="date"
              size='small'
              value={filterDateFrom}
              onChange={(e) => setFilterDateFrom(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="To Date"
              type="date"
              size='small'
              fullWidth
              value={filterDateTo}
              onChange={(e) => setFilterDateTo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />


          </TemplateFormFieldDateContainer>

          <Button disabled={selectedStores.length === 0 || selectedTemplates.length === 0} highlighted onClick={handleSearchFilter} style={{ margin: '10px 0px' }} fullWidth>Run Report</Button>

        </TemplateFilterContainer>}

      {sectionItems &&
        <Chart
          options={options}
          series={series}
          type="bar"

          height="400" />}

      {visits && visits.length > 0 &&
        <TableContainer>
          <DataGrid
            autoHeight
            sx={{ 'border': '0' }}
            components={{ Toolbar: GridToolbar }}
            rows={gridItems}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'visit_date', sort: 'desc' }],
              },
            }}
            onRowClick={handleRowClick}
            getRowId={(row) => row.id}
            pageSize={7}
            rowsPerPageOptions={[7]}
          />
        </TableContainer>


      }
    </Fragment>
  )
}

export default ReportsSectionScores