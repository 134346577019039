import styled from "styled-components";
import { Colors } from "./styled.container";

export const FieldRatingContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 500px) {
        margin-top: 10px;
    }

`

export const FieldRatingItem = styled.button`
    background-color: ${Colors.blue};
    width: 36px;
    height: 36px;
    
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    outline: none;
    border: ${props => props.activate===true ? 0 : "2px solid #7d8597" }; 
    
    background-color: ${props => props.activate===true ? Colors.blue : "transparent" };
    cursor: pointer;
    color: ${props => props.activate===true ? Colors.white : Colors.dark_grey };
    transition: all 0.2s ease-in-out;

    @media (max-width: 400px) {
        margin-top: 10px;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transform: scale(1.05);
    }

`

export const FieldRatingNumber = styled.span`
    font-weight: 500;
    font-size: 0.7rem;
    padding: 2px;
    text-align: center;
`