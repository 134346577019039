import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Typography } from '@mui/material';


export default function UpdateRepeatingScheduleDialog(props) {
  const {open, setOpen, updateEvent, eventInfo, store, template, notificationList, isAllDayEvent, selectedUser, templateName, date, repeatInterval} = props;
  const [selectedOption, setSelectedOption] = useState('thisVisit');

  useEffect(() => {
    setSelectedOption("thisVisit")
  }, [props])

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleAction() {
    //action(selectedFile.section_id, selectedFile.section_item_id, selectedFile.index, selectedFile.file)
    if(selectedOption === "thisVisit") {
      // (selectedUser, template, store, id, parentID, start, end, repeatInterval, isAllDay, notificationList, type)
      updateEvent(selectedUser, template, store, eventInfo.id, eventInfo.parentID, date, date, repeatInterval, isAllDayEvent, notificationList, "thisVisit")
    }
    else if(selectedOption === "allOccurrences") {
      updateEvent(selectedUser, template, store, eventInfo.id, eventInfo.parentID, date, date, repeatInterval, isAllDayEvent, notificationList, "allOccurrences")
    }

    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Update Repeating Scheduled Visit
        </DialogTitle>
        <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup name="options" value={selectedOption} onChange={handleChange}>
            <FormControlLabel value="thisVisit" control={<Radio />} label={<Typography>This Visit</Typography>} />
            <FormControlLabel value="allOccurrences" control={<Radio />} label={<Typography>All Occurrences</Typography>} />
          </RadioGroup>
        </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAction} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}