import React, { useState } from 'react'
import { HeaderTitle, PageContainer, HeaderSubTitle, CardContainer, Button, Card, CardRow, StyledAccountIcon, StyledUserIcon, StyledHomeIcon } from './styled/styled.container'
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { TemplateFormButtonStack } from './styled/styled.templateform';
import DeleteOrganisationDialog from './DeleteOrganisationDialog';
import { auth, logout } from './firebase';
import Loader from './Loader';
import { Alert, Snackbar } from '@mui/material';

function SettingsHome(props) {

  const { storeDetails } = props;
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [showDeleteOrganisationDialog, setShowDeleteOrganisationDialog] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [barMessage, setBarMessage] = useState("");
  const [openBar, setOpenBar] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function deleteOrganisation() {

    setShowLoading(true);

    const idToken = await auth.currentUser.getIdToken(true);


    const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-organisation', {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idToken: idToken,
        storeid: storeDetails.rootID,

      })
    })

    setShowLoading(false);
    if (result.status === 200) {
      logout();
    } else {
      setBarMessage({ type: "error", message: "Error deleting organisation" });
      setOpenBar(true);
    }
  }

  return (
    <>
      <DeleteOrganisationDialog
        open={showDeleteOrganisationDialog}
        setOpen={setShowDeleteOrganisationDialog}
        action={deleteOrganisation}
      />

      <Loader show={showLoading} />

      <Snackbar open={openBar} autoHideDuration={2500} onClose={() => setOpenBar(false)}>
        <Alert onClose={() => setOpenBar(false)} severity={barMessage.type} sx={{ width: '100%' }}>
          {barMessage.message}
        </Alert>

      </Snackbar>


      <CardContainer>

        <Accordion
          sx={{ 'marginBottom': '10px', borderRadius: '5px' }}
          expanded={expanded === 'general'}
          onChange={handleChange('general')}>
          <AccordionSummary

            onClick={() => navigate("/app/settings/details/" + storeDetails.user.uid)}
            expandIcon={<ArrowForwardIosSharpIcon />}>
            <div style={{ 'display': 'flex', alignItems: 'center' }}><StyledAccountIcon /><Typography sx={{ fontSize: '14px' }}>My Profile</Typography></div>

          </AccordionSummary>

        </Accordion>
        {storeDetails.user.admin &&
          <Accordion sx={{
            'marginBottom': '10px',
            borderRadius: '5px',
            '&:before': {
              display: 'none'
            }
          }} expanded={expanded === 'users'}
            onChange={handleChange('users')}>
            <AccordionSummary
              onClick={() => navigate("/app/settings/users")}
              expandIcon={<ArrowForwardIosSharpIcon />}>
              <div style={{ 'display': 'flex', alignItems: 'center' }}>
                <StyledUserIcon />
                <Typography sx={{ fontSize: '14px' }}>User Management</Typography>
              </div>

            </AccordionSummary>

          </Accordion>
        }
        {storeDetails.user.admin &&
          <Accordion sx={{
            'marginBottom': '10px',
            borderRadius: '5px',
            '&:before': {
              display: 'none'
            }

          }} expanded={expanded === 'users'}
            onChange={handleChange('users')}>
            <AccordionSummary
              onClick={() => navigate("/app/settings/timezone")}
              expandIcon={<ArrowForwardIosSharpIcon />}>
              <div style={{ 'display': 'flex', alignItems: 'center' }}>
                <StyledUserIcon />
                <Typography sx={{ fontSize: '14px' }}>Timezone Settings</Typography>
              </div>

            </AccordionSummary>

          </Accordion>
        }

        {storeDetails.user.admin &&
          <Accordion sx={{
            'marginBottom': '10px',
            borderRadius: '5px',
            '&:before': {
              display: 'none'
            }

          }} expanded={expanded === 'users'}
            onChange={handleChange('users')}>
            <AccordionSummary
              onClick={() => navigate("/app/settings/logs")}
              expandIcon={<ArrowForwardIosSharpIcon />}>
              <div style={{ 'display': 'flex', alignItems: 'center' }}>
                <StyledUserIcon />
                <Typography sx={{ fontSize: '14px' }}>Activity Logs</Typography>
              </div>

            </AccordionSummary>

          </Accordion>
        }

      </CardContainer>
      <TemplateFormButtonStack style={{ 'marginTop': '30px' }} align="right">
        {/* <Button onClick={() => setShowDeleteOrganisationDialog(true)}>Delete Organisation</Button> */}
        <Button onClick={() => navigate("/app")}>
          <StyledHomeIcon />
          Back Home
        </Button>
      </TemplateFormButtonStack>

    </>
  )
}

export default SettingsHome