import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoElement from './LogoElement';
import { Colors } from '../styled/styled.container';
import { useTheme } from '@mui/material/styles';
import { StyledSplashNavItem } from '../styled/styled.navbar';
import { useNavigate } from 'react-router-dom';

const pages = ['Features', 'Pricing', "FAQ", "Sign up", 'Login'];

function SplashNav({scrollTo}) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const theme = useTheme();
    const navigate = useNavigate();
    
    const [isScrolled, setIsScrolled] = useState(false);
    
    useEffect(() => {

        const scrollCheck = () => {
            if (window.scrollY > 300) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        }

        window.addEventListener('scroll', () => scrollCheck());

        return () => {
            window.removeEventListener('scroll', () => scrollCheck());
        }

    }, [])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleScroll = (element) => {

        if(element === "Login") {
            navigate("/login");
            return;
        }

        scrollTo(element);
        handleCloseNavMenu();
    }



    return (
        <AppBar sx={{ width: '100%', boxSizing: 'border-box !important', padding: '10px 0px', boxShadow: "none", background: `${!isScrolled ? "transparent" : `${Colors.blue}`}`}}>

                    <Box display="flex" sx={{ px: { xs: 4, md: 6 } }} justifyContent="space-between" alignItems="center">
                        <LogoElement fontSize="1.2rem" logoColor="white" textColor="white" />

                        <Box display="flex" sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'right',
                                    horizontal: 'center',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={() => handleScroll(page)}>
                                        
                                        <Typography variant="h6" textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                            {pages.map((page) => (
                                <StyledSplashNavItem 
                                key={page}
                                onClick={() => handleScroll(page)}>{page}
                                </StyledSplashNavItem>
                                // <Button
                                //     key={page}
                                //     onClick={() => handleScroll(page)}
                                //     sx={{ my: 2, color: 'white', display: 'block' }}
                                // >
                                //     {page}
                                // </Button>
                            ))}
                        </Box>
                    </Box>


        </AppBar>
    );
}
export default SplashNav;