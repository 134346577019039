import styled from "styled-components";
import { Colors } from "./styled.container";

export const PrivacyContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px 50px;
    justify-content: center;

    @media (min-width: 1024px) {
        padding: 20px 100px;
    }

`

export const PrivacyTitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`

export const PrivacyTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: ${Colors.dark_grey};
`

export const PrivacyHeadingText = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
`

export const PrivacyParagraph = styled.div`
    font-size: 0.9rem;
    margin-bottom: 10px;
    font-weight: ${props => props.bold ? props.bold : "500"};
`

export const PrivacyListContainer = styled.ul`
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
`

export const PrivacyListItem = styled.li`
    color: ${Colors.dark_grey};
    font-weight: 600;
    list-style-type: decimal;
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 5px;
`

export const PrivacyListItemExplanation = styled.div`
    color: ${Colors.dark_grey};
    margin-bottom: 10px;
`