import QRCode from "react-qr-code";
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { QRCodeContainer, StyledShareIcon } from "../styled/styled.container";
import { IconButton } from "@mui/material";

export function TemplateShareDialog({ open, setOpen, store, templateDetails }) {

    const [qrValue, setQrValue] = useState('');
    const [totalUrl, setTotalUrl] = useState("")

    useEffect(() => {
        if (templateDetails.type === "internal") {
            setTotalUrl("https://www.storecall.co.za/app/storevisit/new/" + templateDetails.id)
        }
        else {
            setTotalUrl("https://www.storecall.co.za/app/survey/" + store + "/" + templateDetails.id)
        }
    }, [templateDetails])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopy = async () => {
        const copyText = totalUrl;

        try {
            // Copy the text to the clipboard
            await navigator.clipboard.writeText(copyText);

            // Try to open the share dialog
            if (navigator.share) {
                await navigator.share({
                    title: 'Share Storecall Visit Link',
                    text: `Click the link below to access the ${store} ${templateDetails.name}`,
                    url: copyText
                });
            } else {
                console.log('Sharing not supported on this device/browser.');
            }
        } catch (error) {
            console.error('Copying to clipboard or sharing failed:', error);
        }
    };

    function downloadQRCode() {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };

        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }



    return (
        <div>
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{`Share your ${templateDetails.name} template`}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        label="URL"
                        type="url"
                        fullWidth
                        value={totalUrl}
                        onFocus={(e) => e.target.select()}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (<IconButton onClick={handleCopy}>
                                <StyledShareIcon />
                            </IconButton>)
                        }}

                    />
                    <QRCodeContainer>
                        <QRCode
                            size={256}
                            id="QRCode"
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={totalUrl}
                            viewBox={`0 0 256 256`}
                        />
                    </QRCodeContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button onClick={downloadQRCode} color="primary">
                        Download QR Code
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}
