import React, {useEffect, useState} from 'react';
import { Button, Colors, StyledCopyIcon, StyledShareIcon, Text } from './styled/styled.container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from "@mui/material/Dialog"
import { usePDF } from '@react-pdf/renderer';
import { CreatePDF } from './ReportPDF';
import moment from 'moment';

import Loader from './Loader';
import CompleteIcon from "./imgs/success.png"
import LogoIcon from "./imgs/logo_blue.png"
import { IconImage } from './styled/styled.container';
import { TemplateFormButtonStack, TemplateFormCloseDiv, TemplateFormFieldQuestion, TemplateFormFieldRow } from './styled/styled.templateform';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';

export default function StoreVisitCompleteDialog(props) {
  const {open, showLoader, setShowLoader, setPDFLink, storeID, visitDate, visitEndDate, user, manager, title, actionsOutstanding, content, 
    template, store, region, visitID, duration, scorable, score, visitType, 
    visitStatus, visitClosedDate, action, sections, signature_required, signature_link, newReport} = props;
  const dateStoreVisit = moment().format("DD-MM-YYYY HH:mm");
  const [showLoading, setShowLoading] = useState(true);

  const [instance, updateInstance] = usePDF({ 
    document: <CreatePDF 
      template={template} 
      storeID={storeID}
      store={store}
      region={region} 
      date={dateStoreVisit}
      manager={manager}
      user={user} 
      actionsOutstanding={actionsOutstanding}
      sections={sections}
      signature_required={signature_required}
      signature_link={signature_link}
      scorable={scorable}
      score={score}
      visitType={visitType}
      visitStatus={visitStatus}
      visitDate={visitDate}
      visitEndDate={visitEndDate}
      visitClosedDate={visitClosedDate}
      duration={duration}
      /> });

  const [link, setLink] = useState();

  useEffect(() => {

    updateInstance();
  }, [template, signature_link])

  useEffect(() => {
    async function checkLink() {
      //console.log("Sig ", signature_required)
      //console.log("Sig link", signature_link)
      //console.log((signature_required && (signature_link || false)))
      if(open && !instance.loading && instance.url)
      {
        //console.log("Got show", sections)
        if((newReport && sections[0].files_loaded))
        {
            if(signature_required)
            {
              //console.log("Sig required")
              if(signature_link) {
                setShowLoading(false);
                setLink(instance.url);
                if(setPDFLink && signature_link) setPDFLink(instance.url)
              }
            } else {
              //console.log("Sig not requied")
              setLink(instance.url);
              setShowLoading(false);
              if(setPDFLink) setPDFLink(instance.url)
            }
        }
        else if (!newReport) {
            setLink(instance.url);
            setShowLoading(false);
            if(setPDFLink) setPDFLink(instance.url)
        }
      }
    }

    checkLink()
    
  }, [instance])


  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
        
    action()
  }

  function handleClick() {
    
    if(link) window.open(link, '_blank', 'noopener,noreferrer');
    //action()
  }

  const handleCopy = async () => {
    const copyText = `https://www.storecall.co.za/app/storevisit/report/${visitID}`;
    const copyTextString = `Storecall ${title} at ${store} (${region}) on ${visitDate}\n\nLink: ${copyText}`;
  
    try {
      // Copy the text to the clipboard
      await navigator.clipboard.writeText(copyTextString);
  
      // Try to open the share dialog
      if (navigator.share) {
        await navigator.share({
          title: 'Share Storecall Visit Link',
          text: copyTextString,
          url: copyText
        });
      } else {
        console.log('Sharing not supported on this device/browser.');
      }
    } catch (error) {
      console.error('Copying to clipboard or sharing failed:', error);
    }
  };
  
  return (
    <div>

      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        BackdropProps={{
          onClick: () => {},
          onKeyDown: () => {}
        }}
      >

        {!showLoader && <div style={{position: 'relative', 'paddingBottom': '10px'}}>
          <TemplateFormCloseDiv>
            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
          </TemplateFormCloseDiv>

          <DialogTitle>
          <TemplateFormFieldRow style={{'marginTop': '20px'}}>
          <IconImage src={LogoIcon} style={{'margin': '10px 5px'}} />
          <Text fontSize={"1rem"} fontWeight={"600"}>{title} ({region}) on {visitDate}</Text>
          
          </TemplateFormFieldRow>
          
          
        </DialogTitle>
        <DialogContent>
        <Text style={{'margin': '5px 10px'}} fontSize={"0.9rem"} fontWeight={"500"}>{content}</Text>
          <TextField
            sx={{'marginTop': '10px'}} 
            fullWidth
            value={`https://www.storecall.co.za/app/storevisit/report/${visitID}`}
            size="small"
            onFocus={(e) => e.target.select()}
            InputProps={{
              readOnly: true,
              endAdornment: (<IconButton onClick={handleCopy}>
                <StyledShareIcon />
              </IconButton>)
            }}
            inputProps={{
              style: {
                fontFamily: "Poppins",
                fontSize: '0.8rem',
                fontWeight: 500
              }
            }} />
            
        </DialogContent>
        <TemplateFormButtonStack align="right" style={{'padding': '0px 15px'}}>
          <Button disabled={!link } onClick={handleClick}>View Report</Button>
          <Button color={`${Colors.dark_grey_highlight}`} onClick={handleClose} >Close</Button>
        </TemplateFormButtonStack>
        
        </div>}
       
      </Dialog>
    </div>
  );
}