import React, { useContext, useEffect, useState } from 'react'
import { HeaderTitle, PageContainer, TableContainer, CardContainer, Card, NewButton, EmptyContainer, EmptyContainerText, Colors, PageHolder, Button, HeaderHolder, StyledUpdateIcon, StyledEditIcon, Text, ColumnContainer, TextBubble, StyledTextField } from './styled/styled.container'
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, addDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { where, getDocs, orderBy } from 'firebase/firestore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import SatelliteIcon from '@mui/icons-material/Satellite';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField, InputAdornment, Typography, Dialog, DialogContent, DialogTitle, Autocomplete, Chip } from '@mui/material';
import { DivRow, TemplateDescription, TemplateFormButtonStack, TemplateFormFieldRow, TemplateSection } from './styled/styled.templateform';
import { StyledAddIcon } from './styled/styled.container';
import { BlockContainer } from './styled/styled.container';
import Header from './Header';
import InfoDialog from './InfoDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from './Loader';
import AuthContext from './AuthContext';

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EmptyScreen from './EmptyScreen';
import LocationImg from "./imgs/location_img.png"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { InputField } from './InputField';
import logEvent from './hooks/logEvent';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function Regions(props) {

  const [regions, setRegions] = useState([]);
  const [subRegions, setSubRegions] = useState([])
  const [newRegion, setNewRegion] = useState("");
  const [newSubRegion, setNewSubRegion] = useState("")
  const { storeDetails } = useContext(AuthContext)
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [openBar, setOpenBar] = React.useState(false);
  const [barMessage, setBarMessage] = useState({})
  const [showLoading, setShowLoading] = useState(false)
  const [showFieldError, setShowFieldError] = useState(false);
  const [isRegionDialogOpen, setIsRegionDialogOpen] = useState(false)
  const [isSubRegionDialogOpen, setIsSubRegionDialogOpen] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState({});
  const [selectedSubRegion, setSelectedSubRegion] = useState({});

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedSubGroups, setSelectedSubGroups] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    const getRegions = () => {

      const regionRef = query(collection(db, "stores/" + storeDetails.rootID + "/regions"), orderBy("name"));

      const unsub = onSnapshot(regionRef, async (snapshot) => {
        setRegions([]);
        const promises = snapshot.docs.map(async (item) => {
          const regionID = item.id;
          const regionData = item.data();

          const regionItem = {
            cloud_id: regionID,
            name: regionData.name,
          };

          return regionItem;
        });

        const resolvedItems = await Promise.all(promises);

        setRegions(resolvedItems);
      });

      return () => unsub();
    }

    const getSubRegions = () => {
      const subRegionRef = query(collection(db, "stores/" + storeDetails.rootID + "/sub_regions"), orderBy("name"));

      const unsub = onSnapshot(subRegionRef, async (snapshot) => {
        setSubRegions([]);
        const list = await Promise.all(snapshot.docs.map(item => {
          const regionID = item.id;
          const regionData = item.data();
          const regionItem = {
            cloud_id: regionID,
            name: regionData.name,
          };
          return regionItem;
        }));
        setSubRegions(list);
      });

      return () => unsub();
    }

    if (storeDetails.rootID.length > 0) {
      if (!storeDetails.permissions.stores.add || !storeDetails.permissions.stores.edit) navigate(-1);
      getRegions();
      getSubRegions();
    }
  }, [storeDetails])

  const subRegionColumns = [
    {
      field: "cloud_id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Sub Location Group",
      flex: 5, // Adjust the flex property for the "name" column
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      flex: 1, // Adjust the flex property for the "action" column
      renderCell: (params) => (
        <DivRow>
          <IconButton
            edge="end"
            onClick={() => handleShowSubRegionDialog(params.row)}
          >
            <StyledEditIcon />
          </IconButton>
          <IconButton
            edge="end"
            onClick={(e) => handleDeleteSubRegion(e, params.row.cloud_id)}
          >
            <DeleteIcon />
          </IconButton>
        </DivRow>

      ),
    },
  ];

  const regionColumns = [
    {
      field: "cloud_id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Location Group",
      flex: 5, // Adjust the flex property for the "name" column
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      flex: 1, // Adjust the flex property for the "action" column
      renderCell: (params) => (
        <DivRow>
          <IconButton
            edge="end"
            onClick={() => handleShowRegionDialog(params.row)}
          >
            <StyledEditIcon />
          </IconButton>
          <IconButton
            edge="end"
            onClick={(e) => handleDeleteRegion(e, params.row.cloud_id)}
          >
            <DeleteIcon />
          </IconButton>
        </DivRow>

      ),
    },
  ];

  const handleClick = () => {
    setOpenBar(true);
  };

  const handleClose = (event, reason) => {

    setOpenBar(false);
    setShowLoading(false)
    //if(barMessage.result === "success") navigate("/")
  };

  async function handleAddRegion() {

    if (newRegion.length === 0) {
      setShowFieldError(true);
      return;
    }

    const regionRef = collection(db, "stores/" + storeDetails.rootID + "/regions");
    const newRegionItem = await addDoc(regionRef, {
      name: newRegion
    });
    logEvent(storeDetails.rootID, "Locations", `Group added - ${newRegion}`, storeDetails.user.email)

  }

  async function handleAddSubRegion() {

    if (newSubRegion.length === 0) {
      setShowFieldError(true);
      return;
    }

    const subRegionRef = collection(db, "stores/" + storeDetails.rootID + "/sub_regions");
    const newRegionItem = await addDoc(subRegionRef, {
      name: newSubRegion
    });

    logEvent(storeDetails.rootID, "Locations", `Sub Group added - ${newSubRegion}`, storeDetails.user.email)

    setNewSubRegion("")

    // setRegions(prevState => [...prevState, {
    //   cloud_id: newRegionItem.id,
    //   name: newRegion
    // }]);


  }

  function handleRegionKeyPress(e) {
    if (e.key === 'Enter') {
      handleAddRegion()
    }
  }

  function handleSubRegionKeyPress(e) {
    if (e.key === 'Enter') {
      handleAddSubRegion()
    }
  }

  async function handleDeleteRegion() {

    const cloud_id = selectedRegion.cloud_id;

    const storesRef = query(collection(db, "stores/" + storeDetails.rootID + "/stores"), where("region_id", "==", cloud_id));
    const storesDocs = await getDocs(storesRef);
    if (storesDocs.docs.length > 0) {
      setBarMessage({
        type: "error",
        message: "You cannot delete a group with locations linked to it."
      })
      setOpenBar(true)
      return;
    }

    else {

      setShowLoading(true)
      const regionItemRef = doc(db, "stores/" + storeDetails.rootID + "/regions/" + cloud_id);
      await deleteDoc(regionItemRef);
      logEvent(storeDetails.rootID, "Locations", `Group deleted - ${selectedRegion.name}`, storeDetails.user.email)

      setRegions(prevState => prevState.filter(region => region.cloud_id !== cloud_id))
      setIsRegionDialogOpen(false)
      setShowLoading(false)
    }
  }

  async function handleDeleteSubRegion() {
    const cloud_id = selectedSubRegion.cloud_id;

    const storesRef = query(collection(db, "stores/" + storeDetails.rootID + "/stores"), where("sub_region_id", "==", cloud_id));
    const storesDocs = await getDocs(storesRef);
    if (storesDocs.docs.length > 0) {
      setBarMessage({
        type: "error",
        message: "You cannot delete a sub group with locations linked to it."
      })
      setOpenBar(true)
      return;
    }

    else {

      setShowLoading(true)
      const regionItemRef = doc(db, "stores/" + storeDetails.rootID + "/sub_regions/" + cloud_id);
      await deleteDoc(regionItemRef);
      logEvent(storeDetails.rootID, "Locations", `Sub Group deleted - ${selectedSubRegion.name}`, storeDetails.user.email)

      setSubRegions(prevState => prevState.filter(region => region.cloud_id !== cloud_id))
      setIsSubRegionDialogOpen(false)
      setShowLoading(false)
    }
  }

  function handleCloseInfoDialog() {
    setShowErrorDialog(false)
  }

  function editSelectedRegionName(value) {
    setSelectedRegion(prevState => ({ ...prevState, name: value }))
  }

  function editSelectedSubRegionName(value) {
    setSelectedSubRegion(prevState => ({ ...prevState, name: value }))
  }

  function handleShowRegionDialog(region) {
    setSelectedRegion(region)
    console.log(region)
    setIsRegionDialogOpen(true)
  }

  function handleShowSubRegionDialog(sub_region) {
    setSelectedSubRegion(sub_region)
    setIsSubRegionDialogOpen(true)
  }

  async function handleUpdateRegion() {
    try {


      setIsRegionDialogOpen(false)
      const regionRef = doc(db, "stores/" + storeDetails.rootID + "/regions/" + selectedRegion.cloud_id);
      await updateDoc(regionRef, { name: selectedRegion.name });
      logEvent(storeDetails.rootID, "Locations", `Group updated - ${selectedRegion.name}`, storeDetails.user.email)

      setBarMessage({
        type: "info",
        message: "Location group successfully updated"
      })
      setOpenBar(true)
      return;

    } catch {
      setIsRegionDialogOpen(false)
      setBarMessage({
        type: "error",
        message: "There was an error updating the location group."
      })
      setOpenBar(true)

    }
  }

  async function handleUpdateSubRegion() {
    try {

      setIsSubRegionDialogOpen(false)
      const regionRef = doc(db, "stores/" + storeDetails.rootID + "/sub_regions/" + selectedSubRegion.cloud_id);
      await updateDoc(regionRef, { name: selectedSubRegion.name });
      logEvent(storeDetails.rootID, "Locations", `Sub Group updated - ${selectedSubRegion.name}`, storeDetails.user.email)

      setBarMessage({
        type: "info",
        message: "Location sub group successfully updated"
      })
      setOpenBar(true)
      return;

    } catch {
      setIsSubRegionDialogOpen(false)
      setBarMessage({
        type: "error",
        message: "There was an error updating the sub location group."
      })
      setOpenBar(true)

    }
  }

  async function handleAddRegion() {

    if (newRegion.length === 0) {
      setShowFieldError(true);
      return;
    }

    const regionRef = collection(db, "stores/" + storeDetails.rootID + "/regions");
    const newRegionItem = await addDoc(regionRef, {
      name: newRegion
    });

    logEvent(storeDetails.rootID, "Locations", `Group added - ${newRegion}`, storeDetails.user.email)

    setNewRegion("")

    // setRegions(prevState => [...prevState, {
    //   cloud_id: newRegionItem.id,
    //   name: newRegion
    // }]);
  }

  async function handleSelectedGroupsChange(event, values) {



    // check if an item has been removed from regions
    const removedItem = regions.find(region => !values.find(value => value.name === region.name))
    if (removedItem) {

      const storesRef = query(collection(db, "stores/" + storeDetails.rootID + "/stores"), where("region_id", "==", removedItem.cloud_id));
      const storesDocs = await getDocs(storesRef);
      if (storesDocs.docs.length > 0) {
        setBarMessage({
          type: "error",
          message: "You cannot delete a group with locations linked to it."
        })
        setOpenBar(true)
        return;
      }

      else {

        const regionItemRef = doc(db, "stores/" + storeDetails.rootID + "/regions/" + removedItem.cloud_id);
        deleteDoc(regionItemRef);

        setRegions(prevState => prevState.filter(region => region.cloud_id !== removedItem.cloud_id))
        return;
      }
    }
    else {
      const foundElement = regions.find(region => region.name === values[values.length - 1])
      if (!foundElement) {
        const newValue = values[values.length - 1]
        console.log("New value", newValue)

        const regionRef = collection(db, "stores/" + storeDetails.rootID + "/regions");
        const newRegionItem = await addDoc(regionRef, {
          name: newValue
        });




      }
    }



  }

  async function handleSelectedSubGroupsChange(event, values) {


    // check if an item has been removed from regions
    const removedItem = subRegions.find(region => !values.find(value => value.name === region.name))
    if (removedItem) {
      console.log("Removed Item")
      console.log(removedItem)
      const storesRef = query(collection(db, "stores/" + storeDetails.rootID + "/stores"), where("subb_region_id", "==", removedItem.cloud_id));
      const storesDocs = await getDocs(storesRef);
      if (storesDocs.docs.length > 0) {
        setBarMessage({
          type: "error",
          message: "You cannot delete a group with locations linked to it."
        })
        setOpenBar(true)
        return;
      }

      else {

        const regionItemRef = doc(db, "stores/" + storeDetails.rootID + "/sub_regions/" + removedItem.cloud_id);
        deleteDoc(regionItemRef);

        setSubRegions(prevState => prevState.filter(region => region.cloud_id !== removedItem.cloud_id))
        return;
      }
    }
    else {
      const foundElement = subRegions.find(region => region.name === values[values.length - 1])
      if (!foundElement) {
        const newValue = values[values.length - 1]
        console.log("New value", newValue)

        const regionRef = collection(db, "stores/" + storeDetails.rootID + "/sub_regions");
        const newRegionItem = await addDoc(regionRef, {
          name: newValue
        });




      }
    }



  }

  return (
    <PageHolder>
      <Header>
        <HeaderHolder>
          Manage Location Groups and Sub Groups
          <TemplateDescription>Manage your location groups and sub-groups to structure your locations</TemplateDescription>
        </HeaderHolder>

      </Header>
      <PageContainer>
        <Loader show={showLoading} />

        <InfoDialog
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
          title="Error Deleting Group"
          content="You cannot remove a group with locations linked to it."
          action={handleCloseInfoDialog} />

        <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
          <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
            {barMessage.message}
          </Alert>
        </Snackbar>

        <ColumnContainer style={{ 'marginTop': '5px' }}>
          <Text fontSize={"1rem"} fontWeight={"700"} style={{ 'marginBottom': '5px' }}>Location Groups</Text>
          <Text fontSize={"0.8rem"} style={{ 'marginBottom': '15px' }}>Establish and oversee various location groups to organize your locations effectively and enhance structural organization</Text>
          <StyledTextField
            size="small"
            onKeyDown={handleRegionKeyPress}
            InputProps={{
              style: {
                fontFamily: "Poppins",
                fontSize: "1rem",
                fontWeight: 400,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="new region"
                    onClick={handleAddRegion}
                    edge="start"
                  >
                    <StyledAddIcon fontSize="large" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder={"Enter a new location group name"}
            value={newRegion}
            onChange={(e) => setNewRegion(e.target.value)} />
          {/* <Autocomplete
            multiple
            value={[...regions]}
            fullWidth
            options={[...regions].sort((a, b) => {
              if (a.name === b.name) {
                return a.name.localeCompare(b.name);
              }
              return a.name.localeCompare(b.name);
            })}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            freeSolo
            onChange={handleSelectedGroupsChange}
            renderInput={(params) => (

              <TextField
                {...params}
                sx={{ 'borderRadius': '0px', backgroundColor: 'transparent', 'boxShadow': '0' }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (<IconButton>
                    <StyledAddIcon />
                  </IconButton>),
                  style: {

                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 400,
                  },

                }}
                placeholder={"Enter a new group name"}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {

                return ( // Render other selected tags
                  <Chip
                    key={index}
                    label={option.name}
                    onClick={() => handleShowRegionDialog(option)}
                    style={{
                      marginRight: 5, backgroundColor: `${Colors.grey}`,
                      color: `${Colors.deep_blue}`,
                      display: 'inline-flex', // Center horizontally and vertically
                      alignItems: 'center',
                    }}
                    {...getTagProps({ index })}
                  />
                );
              })
            }

            renderGroup={(params) => (
              <li key={params.key}>{params.children}</li>
            )}
          /> */}

          <TemplateFormFieldRow wrap={true} style={{ margin: '15px 0px' }}>
            {[...regions.sort((a, b) => {
              if (a.name === b.name) return a.name.localeCompare(b.name);
              return a.name.localeCompare(b.name)
            })]
              .map((region, index) => {
                return (
                  <TextBubble
                    onClick={() => handleShowRegionDialog(region)}
                    fontSize={'1rem'}
                    fontWeight={500}
                    color={Colors.card_background}
                    backgroundColor={Colors.blue}
                    style={{ cursor: 'pointer', margin: '0px 5px', padding: '5px 20px' }} key={index}>
                    {region.name}
                    <StyledEditIcon style={{ marginLeft: '10px' }} />

                  </TextBubble>)
              })}
          </TemplateFormFieldRow>

        </ColumnContainer>

        <ColumnContainer style={{ 'margin': '20px 0px' }}>
          <Text fontSize={"1rem"} fontWeight={"700"} style={{ 'marginBottom': '5px' }}>Location Sub Groups</Text>
          <Text fontSize={"0.8rem"} style={{ 'marginBottom': '15px' }}>You can optionally subdivide your locations by grouping them further to improve location hierarchy.</Text>
          <StyledTextField
            size="small"
            onKeyDown={handleSubRegionKeyPress}
            InputProps={{
              style: {
                fontFamily: "Poppins",
                fontSize: "1rem",
                fontWeight: 400,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="new region"
                    onClick={handleAddSubRegion}
                    edge="start"
                  >
                    <StyledAddIcon fontSize="large" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder={"Enter a new sub location group name"}
            value={newSubRegion}
            onChange={(e) => setNewSubRegion(e.target.value)} />
          {/* <Autocomplete
            multiple
            value={subRegions}
            fullWidth
            options={[...subRegions].sort((a, b) => {
              if (a.name === b.name) {
                return a.name.localeCompare(b.name);
              }
              return a.name.localeCompare(b.name);
            })}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            freeSolo
            onChange={handleSelectedSubGroupsChange}
            renderInput={(params) => (

              <TextField
                {...params}
                sx={{ 'borderRadius': '0px', backgroundColor: 'transparent', 'boxShadow': '0' }}
                InputProps={{
                  ...params.InputProps,
                  style: {

                    fontFamily: "Poppins",
                    fontSize: "1rem",
                    fontWeight: 400,
                  },
                  onKeyPress: (event) => {
                    if (event.key === 'Enter') {
                      // Trigger the onChange event when the "Enter" key is pressed
                      params.onChange(event);
                    }
                  },

                }}
                placeholder={"Enter a new sub group name"}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {

                return ( // Render other selected tags
                  <Chip
                    key={index}
                    label={option.name}
                    onClick={() => handleShowSubRegionDialog(option)}
                    style={{
                      marginRight: 5, backgroundColor: `${Colors.grey}`,
                      color: `${Colors.deep_blue}`,
                      display: 'inline-flex', // Center horizontally and vertically
                      alignItems: 'center',
                    }}
                    {...getTagProps({ index })}
                  />
                );
              })
            }

            renderGroup={(params) => (
              <li key={params.key}>{params.children}</li>
            )}
          /> */}

          <TemplateFormFieldRow wrap={true} style={{ margin: '15px 0px' }}>
            {[...subRegions.sort((a, b) => {
              if (a.name === b.name) return a.name.localeCompare(b.name);
              return a.name.localeCompare(b.name)
            })]
              .map((sub_region, index) => {
                return (
                  <TextBubble
                    onClick={() => handleShowSubRegionDialog(sub_region)}
                    fontSize={'1rem'}
                    fontWeight={500}
                    color={Colors.card_background}
                    backgroundColor={Colors.blue}
                    style={{ cursor: 'pointer', margin: '0px 5px', padding: '5px 20px' }} key={index}>
                    {sub_region.name}
                    <StyledEditIcon style={{ marginLeft: '10px' }} />

                  </TextBubble>)
              })}
          </TemplateFormFieldRow>
        </ColumnContainer>

        {/* {regions.length === 0 && 
        <EmptyScreen visible 
          imgSrc={LocationImg}
          title={"Let's create your first location group"}
          contents={"Group your locations into regions, areas, departments, or any other way that you wish."}
        />} */}





        <Dialog fullWidth open={isRegionDialogOpen} onClose={() => setIsRegionDialogOpen(false)}>
          <DialogTitle>Edit Location Group</DialogTitle>

          <DialogContent>
            <TextField
              sx={{ 'marginTop': '10px' }}
              fullWidth
              label="Region Name"
              value={selectedRegion.name}
              onChange={(e) => editSelectedRegionName(e.target.value)}
            />
            <TemplateFormButtonStack align="right" style={{ 'marginTop': '20px' }}>
              <Button color={Colors.highlight} onClick={handleDeleteRegion}>Delete</Button>
              <Button onClick={handleUpdateRegion}>
                Update
              </Button>
            </TemplateFormButtonStack>

          </DialogContent>
        </Dialog>

        <Dialog fullWidth open={isSubRegionDialogOpen} onClose={() => setIsSubRegionDialogOpen(false)}>
          <DialogTitle>Edit Sub Location Group</DialogTitle>

          <DialogContent>
            <TextField
              sx={{ 'marginTop': '10px' }}
              fullWidth
              label="Sub Location Name"
              value={selectedSubRegion.name}
              onChange={(e) => editSelectedSubRegionName(e.target.value)}
            />
            <TemplateFormButtonStack align="right" style={{ 'marginTop': '20px' }}>
              <Button color={Colors.highlight} onClick={handleDeleteSubRegion}>Delete</Button>
              <Button onClick={handleUpdateSubRegion}>
                Update
              </Button>
            </TemplateFormButtonStack>

          </DialogContent>
        </Dialog>

      </PageContainer>
    </PageHolder>


  )
}

export default Regions