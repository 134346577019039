import React, { useState, useEffect, useContext } from 'react'
import Loader from './Loader'
import { Button, Colors, ColumnContainer, HeaderHolder, HeaderTitle, PageContainer, PageHolder, StyledActionIcon, StyledEditIcon, StyledScheduleIcon, StyledSearchIcon, Text, TextBubble } from './styled/styled.container'
import { DivRow, TemplateDescription, TemplateFormButtonStack, TemplateFormFieldRow } from './styled/styled.templateform';
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { v4 as uuid } from "uuid";
import { DataLabelCard, DataLabelColor, DataLabelContainer, ScheduleItemAction, ScheduleItemContainer, ScheduleItemOverview, ScheduleItemOverviewStatus, ScheduleItemStore, ScheduleItemTemplate, ScheduleItemTitle, ScheduleItemWrapper, StyledCalendarDetailsHolder, StyledCalendarDetailsHolderContent, StyledCalendarDetailsHolderContentLabel, StyledCalendarDetailsHolderContentTitle, StyledCalendarDetailsHolderContentValue, StyledCalendarDetailsHolderHeader, StyledCalendarDetailsHolderItem, StyledCalendarHolder, StyledCalendarMainHolder, StyledCalendarMobileDetailsHolder, StyledCalendarWrapper, TooltipContent } from './styled/styled.scheduler';
import SchedulerAdd from './SchedulerAdd';
import moment from "moment"
import { db } from "./firebase";
import { collection, onSnapshot, query, doc, getDocs, getDoc, setDoc, where, deleteDoc, updateDoc, addDoc } from "firebase/firestore";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@mui/material/TextField';
import AuthContext from './AuthContext';
import { Autocomplete, Checkbox, Divider, FormControlLabel, FormGroup, InputAdornment, Typography } from '@mui/material';
import { auth } from "./firebase"
import Header from './Header';
import Avatar from '@mui/material/Avatar';
import { StyledCalendarIcon, StyledVisitIcon } from './styled/styled.icons';
import { useMediaQuery } from '@material-ui/core';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import logEvent from './hooks/logEvent';

function SchedulerHome(props) {
    const { storeDetails } = useContext(AuthContext)
    const [showLoading, setShowLoading] = useState(false);
    const [parentEvents, setParentEvents] = useState([])
    const [events, setEvents] = useState([])
    const [originalEvents, setOriginalEvents] = useState([])
    const [showTooltip, setShowTooltip] = useState({
        tooltipContent: '',
        tooltipPosition: {},
    })
    const [showActions, setShowActions] = useState(true)
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [eventInfo, setEventInfo] = useState(null);
    const [eventSelectedDate, setEventSelectedDate] = useState();
    const [calendarUser, setCalendarUser] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null);
    const [users, setUsers] = useState()
    const isSmallScreen = useMediaQuery('(max-width:1250px)');
    const isVerySmallScreen = useMediaQuery('(max-width:850px)');
    const [showEventDetailsDrawer, setShowEventDetailsDrawer] = useState(false);

    useEffect(() => {

        async function getDetails() {
            if (storeDetails.rootID.length > 0) {

                const updatedUser = { ...storeDetails.user, category: "User" }
                setCalendarUser(updatedUser)
                //setSelectedUserName(storeDetails.user.name)
                setShowLoading(true)
                const {parentEvents, childEvents} = await getSchedule(updatedUser);
                console.log("Setting parent events", parentEvents, childEvents)
                setParentEvents(parentEvents)
                setEvents(childEvents)
                await getActions(updatedUser)
                setShowLoading(false)
                await getUsers();
            }
        }

        setShowActions(true)
        getDetails()

    }, [])



    useEffect(() => {

        if (!showActions) {
            setOriginalEvents([...events])
            setEvents(prevState => prevState.filter(event => event.extendedProps.type !== "action"))
        }
        else {
            setEvents(originalEvents)
        }

    }, [showActions])

    const toggleDrawer = (open) => (event) => {
        console.log("Toggling")

        setShowEventDetailsDrawer(prevState => !prevState);
    };

    async function getSchedule(selectedUser) {

        const scheduleItemsCollection = collection(db, `stores/${storeDetails.rootID}/scheduler/${selectedUser.uid}/list`);
        const scheduleItemsRef = query(scheduleItemsCollection)
        const scheduleItemDocs = await getDocs(scheduleItemsRef)
        const childEvents = [];
        const parentList = [];
        if (scheduleItemDocs.docs.length > 0) {
            const parentItem = await Promise.all(
                scheduleItemDocs.docs.map(async item => {
                    const parentData = item.data();
                    
                    parentList.push(parentData);
                    
                    const [templateName, storeName] = parentData.title.split('\n');
                    const template = {
                        name: templateName,
                        cloud_id: parentData.template_id
                    }
                    const store = {
                        name: storeName,
                        cloud_id: parentData.store_id
                    }

                    //console.log("Selected user", selectedUser);

                    const eventsToAdd = await createEventList(selectedUser, template, store, parentData.id, parentData.start, parentData.end, parentData.repeatInterval, parentData.allDay, parentData.notificationList, parentData.missed_dates)

                    for (const event of eventsToAdd) {
                        childEvents.push(event);
                    }
                }))
        }

        return { parentEvents: parentList, childEvents }
    }

    async function getActions(selectedUser) {

        const actionRef = query(collection(db, "stores/" + storeDetails.rootID + "/actions"), where("actionComplete", "==", false), where("actionBy", "==", selectedUser.uid));
        const actionDocs = await getDocs(actionRef);
        const actionEvents = [];

        actionDocs.docs.map(actionItem => {
            const actionData = actionItem.data();
            const actionCompleteStatus = getActionStatus(actionData);
            const actionUser = storeDetails.all_users.find(user => user.uid === actionData.actionBy);

            //console.log("Action Status", actionCompleteStatus);

            const newEvent = {
                start: moment(actionData.action_due_date, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss'),
                end: moment(actionData.action_due_date, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss'),
                title: `${actionData.section_item_name}\n${actionData.store_name || ""}`,
                id: uuid(),
                parentID: actionData.action_id,
                extendedProps: {
                    parentID: actionData.action_id,
                    type: "action",
                    status: actionCompleteStatus,
                    name: actionUser.name,
                    user_id: actionUser.uid
                }
            }

            //console.log("Action Event", newEvent)

            actionEvents.push(newEvent)
        })

        //setParentEvents(prevState => [...prevState, ...actionEvents])
        setEvents(prevState => [...prevState, ...actionEvents])
    }

    function getUsersFromGroup(id) {
        if (id === "All") {
            //console.log(storeDetails.filtered_users)
            return storeDetails.filtered_users;
        }
        
        const filteredUsers = storeDetails.all_users.filter(user => user.group === id);
        //console.log("Filtered", filteredUsers);
        return filteredUsers;
    }

    async function getGroupSchedule(selectedGroup) {
        
        const usersList = getUsersFromGroup(selectedGroup);

        const childEventsGroup = [];
        const parentListGroup = [];

        await Promise.all(usersList.map(async selectedUser => {
            const {parentEvents, childEvents} = await getSchedule(selectedUser);
            parentListGroup.push(...parentEvents);
            childEventsGroup.push(...childEvents);
        }));


        return { parentEvents: parentListGroup, childEvents: childEventsGroup }
    }

    async function getGroupActions(selectedGroup) {

        if (storeDetails.user.type === "user") return;

        const usersList = getUsersFromGroup(selectedGroup);

        if (usersList.length === 0) {
            setEvents([])
            setParentEvents([])
            return;
        }

        const groupUserUID = usersList.map(user => user.uid);

        const actionsRef = collection(db, "stores/" + storeDetails.rootID + "/actions")
        const actionQuery = query(actionsRef, where("actionBy", "in", groupUserUID));
        const actionDocs = await getDocs(actionQuery);
        const actionEvents = [];

        actionDocs.docs.map(actionItem => {
            const actionData = actionItem.data();
            const actionCompleteStatus = getActionStatus(actionData);
            const actionUser = users.find(user => user.uid === actionData.actionBy);


            const newEvent = {
                start: moment(actionData.action_due_date, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss'),
                end: moment(actionData.action_due_date, "DD-MM-YYYY").format('YYYY-MM-DDTHH:mm:ss'),
                title: `${actionData.section_item_name}\n${actionData.store_name || ""}`,
                id: uuid(),
                parentID: actionData.action_id,
                extendedProps: {
                    parentID: actionData.action_id,
                    type: "action",
                    status: actionCompleteStatus,
                    name: actionUser.name,
                    user_id: actionUser.uid
                }
            }

            //console.log("Action Event", newEvent)

            actionEvents.push(newEvent)
        })

        //setParentEvents(prevState => [...prevState, ...actionEvents])
        setEvents(prevState => [...prevState, ...actionEvents])
    }

    async function getUsers() {
        let q = "";
        if (storeDetails.user.admin) {
            // q = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("active", "==", true));
            // const userRef = await getDocs(q);
            // const userData = await Promise.all(userRef.docs.map(doc => {
            //     return new Promise((resolve) => {
            //         const docData = doc.data();

            //         resolve(docData)
            //     })
            // }))

            const user_list = storeDetails.all_users.map(user => {
                return {
                    ...user,
                    uid: user.uid,
                    name: user.name,
                    category: "User"
                }
            })
            const groups = storeDetails.user_groups.map(group => {
                return {
                    uid: group.id,
                    name: group.name,
                    category: "Group"
                }
            });
            const all_users = [...user_list, ...groups];
            setUsers(all_users)
            return all_users;
        }
        else if (storeDetails.user.type === "manager") {
            const filteredUsers = storeDetails.filtered_users.map(user => {
                return { ...user, category: "User" }
            })
            setUsers(filteredUsers);
            return filteredUsers;
        } else {
            const user = storeDetails.user;
            setUsers([user])
            return user;
        }


    }

    const handleUserChange = async (event, values) => {
        //const user = event.target.value;
        //const userIndex = users.findIndex(element => element.uid === user)
        //const name = users[userIndex].name
        //console.log(values)
        if (!values) return;

        if (storeDetails.user.type === "user") return;

        setShowLoading(true)

        setCalendarUser(values); // use user.uid as the selected value
        //setSelectedUserName(name)

        if (values.name === "All") {

            const {parentEvents, childEvents} = await getGroupSchedule("All");
            setParentEvents(parentEvents)
            setEvents(childEvents)
            await getGroupActions("All");

        }

        if (values.category === "User") {
            const {parentEvents, childEvents} = await getSchedule(values);
            setParentEvents(parentEvents)
            setEvents(childEvents)

            if (storeDetails.user.type !== "user")
                await getActions(values)
        }
        else if (values.category === "Group") {
            
            const {parentEvents, childEvents} = await getGroupSchedule(values.uid);
            setParentEvents(parentEvents)
            setEvents(childEvents)
            await getGroupActions(values.uid);
            
        }

        setShowLoading(false)
    }

    async function getIDToken() {
        const token = await auth.currentUser.getIdToken(true)
        return token;
    }

    async function addParentEventCloud(selectedUser, parentEvent) {

        const userDocRef = doc(db, "stores", storeDetails.rootID, "scheduler", selectedUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        let newItemRef = "";


        if (userDocSnapshot.exists()) {
            // The user's document exists, so add the event to the "list" subcollection
            const listCollectionRef = collection(userDocRef, "list");
            newItemRef = await addDoc(listCollectionRef, parentEvent);
        } else {
            // The user's document does not exist, so create a new document and add the event
            const newUserDocRef = doc(db, "stores", storeDetails.rootID, "scheduler", selectedUser.uid);
            await setDoc(newUserDocRef, { uid: selectedUser.uid });
            const newUserListCollectionRef = collection(newUserDocRef, "list");
            newItemRef = await addDoc(newUserListCollectionRef, parentEvent);
        }

        const idToken = await getIDToken();
        const [templateName, storeName] = parentEvent.title.split('\n');
        logEvent(storeDetails.rootID, "Scheduler", `Event added for ${selectedUser.name} - ${templateName} - ${storeName}`, storeDetails.user.email)

        // fetch('https://us-central1-storecall.cloudfunctions.net/api/schedule-event', {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify({
        //         idToken: idToken,
        //         storeid: storeDetails.rootID,
        //         userid: selectedUser.uid,
        //         scheduleid: newItemRef.id,
        //         templateName: templateName,
        //         storeName: storeName,
        //         allDay: parentEvent.allDay,
        //         newEvent: true,
        //         notificationList: JSON.stringify(parentEvent?.notificationList) || [],

        //     })
        // })


    }

    async function removeParentEventCloud(selectedUser, id) {
        const docRef = query(collection(db, "stores/" + storeDetails.rootID + "/scheduler/" + selectedUser.uid + "/list"), where("id", "==", id))
        const docDocs = await getDocs(docRef)
        const docID = docDocs.docs[0].id;

        await deleteDoc(doc(db, "stores/" + storeDetails.rootID + "/scheduler/" + selectedUser.uid + "/list/" + docID))
        
    }

    async function updateParentEventCloud(selectedUser, parentEvent) {


        const docRef = query(collection(db, "stores/" + storeDetails.rootID + "/scheduler/" + selectedUser.uid + "/list"), where("id", "==", parentEvent.id))
        const docDocs = await getDocs(docRef)
        const docID = docDocs.docs[0].id;


        await updateDoc(doc(db, "stores/" + storeDetails.rootID + "/scheduler/" + selectedUser.uid + "/list/" + docID),
            parentEvent,
            { merge: true })


    }

    function getActionStatus(action) {
        if (moment().isAfter(moment(action.action_due_date, "DD-MM-YYYY"))) {
            if (action.actionComplete) return "done"
            else return "missed";
        }
        else {

            return "waiting"
        }
    }

    async function getEventStatus(template, store, start, isAllDay) {

        const eventDateMoment = moment(start);

        

        if (moment().isBefore(moment(start, "YYYY-MM-DDTHH:mm:ss"), 'day')) return "waiting";


        let eventStatus = "waiting";

        const date = new Date(start);
        date.setDate(date.getDate() + 1);
        const nextDay = date.toISOString();
        const visitRef = query(collection(db, "stores/" + storeDetails.rootID + "/visits"),
            where("template_id", "==", template.cloud_id),
            where("store_id", "==", store.cloud_id), where("date", ">=", start),
            where("date", "<", nextDay)
        );
        const visitDocs = await getDocs(visitRef);
        if (visitDocs.docs.length === 0) {
            if (moment().isAfter(eventDateMoment, 'day'))
                eventStatus = "missed";
            else
                eventStatus = "waiting"
        }
        else eventStatus = "complete"

        return eventStatus

    }

    async function createEventList(user, template, store, parentID, start, end, repeatInterval, isAllDay, notificationList, missed_dates) {
        const eventsToAdd = [];

        const endDate = repeatInterval === "None" ? start : moment().endOf('year');

        if (repeatInterval !== "None") {
            const currentDate = moment(start);

            while (currentDate.isBefore(endDate)) {

                const currentDateFormatted = moment(currentDate).format('YYYY-MM-DDTHH:mm:ss')
                let eventStatus = await getEventStatus(template, store, currentDateFormatted, isAllDay)

                const newEvent = {
                    start: currentDateFormatted,
                    end: currentDateFormatted,
                    title: `${template.name}\n${store.name}`,
                    id: uuid(),
                    allDay: isAllDay,
                    parentID: parentID,
                    extendedProps: {
                        parentID: parentID,
                        type: "visit",
                        template_id: template.cloud_id,
                        store_id: store.cloud_id,
                        status: eventStatus,
                        user_id: user.uid,
                        name: user.name,
                        notificationList: notificationList
                    }
                }


                //console.log("Missed dates", missed_dates)

                if (missed_dates.length === 0 || missed_dates.findIndex(date => date === currentDateFormatted) === -1)
                    eventsToAdd.push(newEvent)

                if (repeatInterval === 'Daily') {
                    currentDate.add(1, 'day');
                } else if (repeatInterval === 'Weekly') {
                    currentDate.add(1, 'week');
                }
                else if (repeatInterval === 'Monthly') {
                    // Add one month to the current date
                    currentDate.add(1, 'month');

                    // Check if the original scheduled day was the last day of its month
                    let isScheduledDayLastDayOfMonth = moment(start).endOf('month').date() === moment(start).date();

                    // Get the number of days in the current month
                    let daysInCurrentMonth = currentDate.daysInMonth();

                    if (isScheduledDayLastDayOfMonth) {
                        // If the scheduled day was the last day of the month, set the currentDate to the last day of the current month
                        currentDate.date(daysInCurrentMonth);
                    } else {
                        // Otherwise, set the currentDate to the same day as the original scheduled day or the last day of the current month, whichever is smaller
                        currentDate.date(Math.min(moment(start).date(), daysInCurrentMonth));
                    }
                }

            }

            // setEvents(prevEvents => [...prevEvents, ...eventsToAdd]);

        } else {

            let eventStatus = await getEventStatus(template, store, start, isAllDay)

            const newEvent = {
                start: start,
                end: start,
                title: `${template.name}\n${store.name}`,
                id: uuid(),
                parentID: parentID,
                allDay: isAllDay,
                extendedProps: {
                    parentID: parentID,
                    type: "visit",
                    template_id: template.cloud_id,
                    store_id: store.cloud_id,
                    status: eventStatus,
                    user_id: user.uid,
                    name: user.name,
                    notificationList: notificationList
                }
            }
            //console.log("Event", start, eventStatus)
            eventsToAdd.push(newEvent)

        }


        return eventsToAdd
    }

    // function to add event to calendar
    async function handleAddEvent(selectedUser, template, store, id, start, end, repeatInterval, isAllDay, notificationList, bReplacing) {

        const endDate = repeatInterval === "None" ? start : moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss');

        if (bReplacing) {
            const updatedParentEvents = parentEvents.filter(event => event.id !== id)
            const updatedEvents = events.filter(event => event.extendedProps.parentID !== id)

            await removeParentEventCloud(selectedUser, id)

            const parentID = uuid();

            const newParentEvent = {
                start: start,
                end: endDate,
                date: new Date(start),
                title: `${template.name}\n${store.name}`,
                id: parentID,
                notificationList: notificationList,
                allDay: isAllDay,
                repeatInterval: repeatInterval,
                missed_dates: [],
                template_id: template.cloud_id,
                store_id: store.cloud_id,
                createdBy: storeDetails.user.email
            }

            if (selectedUser.name !== "All" && selectedUser.uid !== calendarUser.uid) {
                //setEventInfo(null)
                setShowAddDialog(false)
                addParentEventCloud(selectedUser, newParentEvent)
                return;
            }



            setParentEvents([...updatedParentEvents, newParentEvent])
            addParentEventCloud(selectedUser, newParentEvent)

            const eventsToAdd = await createEventList(selectedUser, template, store, parentID, start, end, repeatInterval, isAllDay, notificationList, [])

            setEvents([...updatedEvents, ...eventsToAdd])
        }
        else {

            const newParentEvent = {
                start: start,
                end: endDate,
                date: new Date(start),
                title: `${template.name}\n${store.name}`,
                id: id,
                notificationList: notificationList,
                allDay: isAllDay,
                repeatInterval: repeatInterval,
                missed_dates: [],
                template_id: template.cloud_id,
                store_id: store.cloud_id,
                createdBy: storeDetails.user.email
            }

            if (selectedUser.name !== "All" && selectedUser.uid !== calendarUser.uid) {
                //setEventInfo(null)
                setShowAddDialog(false)
                addParentEventCloud(selectedUser, newParentEvent)
                return;
            }


            setParentEvents(prevState => [...prevState, newParentEvent])
            addParentEventCloud(selectedUser, newParentEvent)

            const eventsToAdd = await createEventList(selectedUser, template, store, id, start, end, repeatInterval, isAllDay, notificationList, [])

            const newEventList = [...events, ...eventsToAdd]

            setEvents(newEventList)
        }

        //setEventInfo(null)
        setShowAddDialog(false)
    }

    async function handleUpdateEvent(selectedUser, template, store, id, parentID, start, end, repeatInterval, isAllDay, notificationList, type) {

        const endDate = repeatInterval === "None" ? start : moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss');
        let eventStatus = await getEventStatus(template, store, start, isAllDay)
        const currentParentIndex = parentEvents.findIndex(event => event.id === parentID)

        const currentParentRepeatInterval = parentEvents[currentParentIndex].repeatInterval;

        const newParentEvent = {
            start: start,
            end: endDate,
            date: new Date(start),
            title: `${template.name}\n${store.name}`,
            id: parentID,
            allDay: isAllDay,
            notificationList: notificationList,
            repeatInterval: repeatInterval,
            missed_dates: [],
            template_id: template.cloud_id,
            store_id: store.cloud_id,
            createdBy: storeDetails.user.email
        }

        if (calendarUser.uid !== selectedUser.uid) {

            await addParentEventCloud(selectedUser, newParentEvent)
            handleDeleteEvent(id, parentID, "thisVisit", repeatInterval)
            return;
        }

        const updatedEvent = {
            start: start,
            end: end,
            title: `${template.name}\n${store.name}`,
            id: id,
            allDay: isAllDay,

            parentID: parentID,
            extendedProps: {


                parentID: parentID,
                type: "visit",
                template_id: template.cloud_id,
                store_id: store.cloud_id,
                status: eventStatus,
                user_id: selectedUser.uid,
                name: selectedUser.name,
                notificationList: notificationList,
                createdBy: storeDetails.user.email


            }
        }


        //setEventInfo(null)
        setShowAddDialog(false)

        if (type === "allOccurrences") {


            updateParentEventCloud(selectedUser, newParentEvent)
            if (selectedUser.uid !== calendarUser.uid) return;

            setParentEvents(prevState => {
                const newEvents = [...prevState]
                //const parentID = uuid();


                newEvents[currentParentIndex] = newParentEvent
                return newEvents
            })

            const updatedEvents = events.filter(event => event.extendedProps.parentID !== parentID)
            const eventsToAdd = await createEventList(selectedUser, template, store, parentID, start, end, repeatInterval, isAllDay, notificationList, [])

            setEvents([...updatedEvents, ...eventsToAdd])

            return;

        }

        else if (currentParentRepeatInterval !== repeatInterval) {
            //console.log("Current parent repeat different", currentParentRepeatInterval, repeatInterval)
            //setEvents(prevState => prevState.filter(event => event.extendedProps.parentID !== parentID))
            //setParentEvents(prevState => prevState.filter(event => event.id !== parentID))

            handleAddEvent(selectedUser, template, store, parentID, start, start, repeatInterval, isAllDay, notificationList, true)
            return;
        }
        else if (type === "thisVisit" && repeatInterval !== "None") {
            const newParentID = uuid();
            const endDate = repeatInterval === "None" ? start : moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss');

            const eventStartIndex = events.findIndex(event => event.id === id);
            const eventStartDate = events[eventStartIndex].start;

            const editedParentEvents = [...parentEvents].map(event => {
                if (event.id === parentID) {
                    return { ...event, missed_dates: [...event.missed_dates, eventStartDate] }
                } else return event;
            })


            const editedParentEventIndex = editedParentEvents.findIndex(event => event.id === parentID)
            //console.log("Amended", editedParentEvents[editedParentEventIndex])

            updateParentEventCloud(selectedUser, editedParentEvents[editedParentEventIndex])

            const newParentEvent = {
                start: start,
                end: endDate,
                date: new Date(start),
                title: `${template.name}\n${store.name}`,
                id: newParentID,
                repeatInterval: "None",
                notificationList: notificationList,
                missed_dates: [],
                allDay: isAllDay,
                template_id: template.cloud_id,
                store_id: store.cloud_id,
                
            }
            addParentEventCloud(selectedUser, newParentEvent)

            if (selectedUser.uid === calendarUser.uid) {
                editedParentEvents.push(newParentEvent)

                setParentEvents(editedParentEvents)

                const filteredEventList = events.filter(event => event.id !== id);

                let eventStatus = await getEventStatus(template, store, start, isAllDay);

                setEvents([...filteredEventList, {

                    start: start,
                    end: start,
                    title: `${template.name}\n${store.name}`,
                    id: id,
                    parentID: newParentID,
                    allDay: isAllDay,
                    extendedProps: {
                        parentID: newParentID,
                        template_id: template.cloud_id,
                        store_id: store.cloud_id,
                        status: eventStatus,
                        notificationList: notificationList,
                        createdBy: storeDetails.user.email
                    }

                }])
            }

        }

        else if (type === "thisVisit" && repeatInterval === "None") {

            const newParentEvent = {
                start: start,
                end: endDate,
                date: new Date(start),
                title: `${template.name}\n${store.name}`,
                id: parentID,
                allDay: isAllDay,
                notificationList: notificationList,
                repeatInterval: repeatInterval,
                missed_dates: [],
                template_id: template.cloud_id,
                store_id: store.cloud_id
            }
            updateParentEventCloud(selectedUser, newParentEvent)

            if (selectedUser.uid === calendarUser.uid) {
                setEvents(prevState => {
                    const index = prevState.findIndex(event => event.id === id);
                    const newEvents = [...prevState];

                    newEvents[index] = updatedEvent

                    return newEvents
                })

                setParentEvents(prevState => {
                    const parentEventIndex = prevState.findIndex(event => event.id === parentID)
                    const newEvents = [...prevState]

                    newEvents[parentEventIndex] = newParentEvent


                    return newEvents
                })
            }



            //console.log(parentEvents)
        }

        logEvent(storeDetails.rootID, "Scheduler", `Event updated for ${calendarUser.name} - ${template.name} - ${store.name}`, storeDetails.user.email)
        //setEventInfo(null)
        setShowAddDialog(false)
    }

    function handleDeleteEvent(id, parentID, type, repeatInterval) {

        const parentEvent = parentEvents.find(event => event.id === parentID);
        
        if (type === "allOccurrences" || (type === "thisVisit" && repeatInterval === "None")) {
            setParentEvents(prevState => prevState.filter(event => event.id !== parentID))
            setEvents(prevState => prevState.filter(event => event.parentID !== parentID))
            removeParentEventCloud(calendarUser, parentID)
        }

        if (type === "thisVisit" && repeatInterval !== "None") {
            //setParentEvents(prevState => prevState.filter(event => event.id !== parentID))
            setEvents(prevState => prevState.filter(event => event.id !== id))

            const eventStartIndex = events.findIndex(event => event.id === id);
            const eventStartDate = events[eventStartIndex].start;

            const parentEventId = parentEvents.findIndex(event => event.id === parentID);
            const foundParentEvent = {...parentEvents[parentEventId]};
            foundParentEvent.missed_dates.push(eventStartDate);

            
            setParentEvents(prevState => {
                const newEvents = [...prevState]
                newEvents[parentEventId] = foundParentEvent;
                return newEvents;
            })

            updateParentEventCloud(calendarUser, foundParentEvent);

            // const editedParentList = parentEvents.map(event => {
            //     if (event.id === parentID) {
            //         return { ...event, missed_dates: [...event.missed_dates, eventStartDate] }
            //     } else return event;
            // })

            // const editedParentEventIndex = editedParentList.findIndex(event => event.id === parentID)
            // removeParentEventCloud(calendarUser.uid, parentEvents[editedParentEventIndex].id)

            // setParentEvents(editedParentList)
        }

        const [templateName, storeName] = parentEvent.title.split('\n');

        logEvent(storeDetails.rootID, "Scheduler", `Event deleted for ${calendarUser.name} - ${templateName} - ${storeName}`, storeDetails.user.email)

        setEventInfo(null)
        setSelectedItem(null)
        setShowAddDialog(false)
    }

    // when the schedule now button is clicked
    function handleButtonAddSchedule() {
        const date = moment().format('YYYY-MM-DDTHH:mm:ss');
        setEventInfo({
            start: date,
            end: date,
            allDay: true
        });

        setShowEventDetailsDrawer(false)
        setShowAddDialog(true);
    }

    // when an existing event is clicked
    function handleEventClick(info) {
        const { start, end, title, id, allDay } = info.event;

        const eventType = info.event.extendedProps.type;
        const parentID = info.event.extendedProps.parentID;
        const notificationList = info.event.extendedProps.notificationList || "";

        const [templateName, storeName] = title.split('\n');


        let formattedDate;
        const momentDate = moment(start);
        if (momentDate.isSame(moment(), 'day')) formattedDate = "Today";
        else if (momentDate.isSame(moment().add(1, 'day'), 'day')) formattedDate = "Tomorrow";
        else if (momentDate.isSame(moment().subtract(1, 'day'), 'day')) formattedDate = "Yesterday";
        else formattedDate = moment(start).format('DD MMM YY');

        if (!allDay) {
            const hours = moment(start).format('HH:mm');
            formattedDate += ` ${hours}`
        }


        setSelectedItem({
            template: templateName,
            store: storeName,
            due: formattedDate,
            type: eventType,
            status: info.event.extendedProps.status,
            name: info.event.extendedProps.name,
            notificationList: notificationList
        })

        console.log("Parent events", parentEvents)
        const parentIndex = parentEvents.findIndex(event => event.id === parentID);
        const repeatInterval = parentEvents[parentIndex]?.repeatInterval;
        setEventInfo({
            start: start,
            end: start,
            title: title,
            id: id,
            parentID: parentID,
            allDay: allDay,
            user_id: info.event.extendedProps.user_id,
            repeatInterval: repeatInterval || "",
            name: info.event.extendedProps.name,
            notificationList: notificationList
        })

        if (isSmallScreen) {
            setShowEventDetailsDrawer(true)
        }

        // let repeatInterval;

        // if (eventType !== "action") {
        //     const parentIndex = parentEvents.findIndex(event => event.id === parentID);
        //     repeatInterval = parentEvents[parentIndex].repeatInterval;

        //     //console.log("Info", info.event.extendedProps);

        //     setEventInfo({
        //         start: start,
        //         end: start,
        //         title: title,
        //         id: id,
        //         parentID: parentID,
        //         user_id: info.event.extendedProps.user_id,
        //         repeatInterval: repeatInterval
        //     })
        //     setShowAddDialog(true);
        // }



    }


    // when an event date is selected on the calendar
    const handleSelect = (info) => {
        const { start, end } = info;

        setEventInfo({
            start: start,
            end: end,
            repeatInterval: "None"
        });

        //setShowAddDialog(true);

    };

    function handleShowEditDialog() {
        setShowEventDetailsDrawer(false)
        setShowAddDialog(true)
    }


    const ScheduleItem = ({ info }) => {
        const { event } = info;
        const [templateName, storeName] = event.title.split('\n');
        const eventType = event.extendedProps.type;
        const eventStatus = event.extendedProps.status;
        const actionName = event.extendedProps.name || undefined;
        const actionNameInitials = actionName ? actionName.substring(0, 2).toUpperCase() : '';

        //console.log(event.extendedProps)

        return (

            <ScheduleItemWrapper>
                <ScheduleItemOverview>

                    <ScheduleItemOverviewStatus type={eventType} status={eventStatus}>

                        {isSmallScreen ? null :
                            (eventType === "action" ?
                                <StyledActionIcon sx={{ 'color': `${Colors.white}`, fontSize: '16px' }} /> :
                                <StyledVisitIcon style={{ 'marginRight': '5px' }} sx={{ 'color': `${Colors.white}`, fontSize: '16px' }} />)}

                        {eventStatus}
                        {isVerySmallScreen ? null : actionName &&
                            <Avatar
                                sizes='small'
                                sx={{ display: 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'justifySelf': 'flex-end', 'alignSelf': 'flex-end', width: 24, height: 24, bgcolor: `${Colors.card_background}`, color: `${Colors.dark_grey_highlight}`, fontSize: "0.7rem", fontWeight: "600" }}>
                                {actionNameInitials}
                            </Avatar>}
                    </ScheduleItemOverviewStatus>



                </ScheduleItemOverview>


                <ScheduleItemContainer type={eventType} status={eventStatus}>

                    <ScheduleItemTemplate>{templateName}</ScheduleItemTemplate>
                    <ScheduleItemStore>{storeName === "Not Set" ? "" : storeName}</ScheduleItemStore>


                </ScheduleItemContainer>


            </ScheduleItemWrapper>

        );
    };

    const CalendarActionsComponent = () => {
        return <>
        <FormControlLabel control={<Checkbox checked={showActions} onChange={(e) => setShowActions(e.target.checked)} />} label={<Typography style={{ 'fontFamily': "Poppins", fontSize: '0.9rem' }}>Show actions on calendar</Typography>} />
                    {storeDetails.permissions.schedule.add && calendarUser && calendarUser.category !== "Group" &&
                        <Button style={{ padding: '15px 0px', margin: '10px 0px', width: '100%', fontSize: '0.9rem' }} color={`${Colors.blue}`} onClick={handleButtonAddSchedule}><StyledCalendarIcon sx={{ 'color': 'white', fontSize: '1.5rem' }} style={{ 'marginRight': '5px' }} />Create event</Button>}
        </>
    }

    const EventDetailsHolder = () => {
        return (
            <StyledCalendarDetailsHolder>
                {/* {calendarUser && users && (

                    <Autocomplete
                        sx={{

                            marginBottom: '10px',
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none"
                                }
                            }
                        }}
                        id="user-tags"
                        options={[{ name: "All" }, ...users]}
                        groupBy={(option) => option.category}
                        getOptionLabel={(option) => option.name}
                        value={calendarUser}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        onChange={handleUserChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                    backgroundColor: `${Colors.white}`,
                                    padding: '2px 5px',
                                    outline: 'none',
                                    boxShadow: `rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px;`

                                }}

                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <StyledSearchIcon />
                                        </InputAdornment>
                                    ),
                                    type: 'Choose a user',
                                    style: { 'fontSize': '0.8rem', 'fontWeight': '400', 'fontFamily': 'Poppins' }
                                }}
                                placeholder="Choose a user"
                            />
                        )}
                    />

                )} */}

                <ColumnContainer align="flex-start" style={{ 'margin': '5px 10px' }}>


                    <FormControlLabel control={<Checkbox checked={showActions} onChange={(e) => setShowActions(e.target.checked)} />} label={<Typography style={{ 'fontFamily': "Poppins", fontSize: '0.9rem' }}>Show actions on calendar</Typography>} />
                    {storeDetails.permissions.schedule.add && calendarUser && calendarUser.category !== "Group" &&
                        <Button style={{ padding: '15px 0px', margin: '10px 0px', width: '100%', fontSize: '0.9rem' }} color={`${Colors.blue}`} onClick={handleButtonAddSchedule}><StyledCalendarIcon sx={{ 'color': 'white', fontSize: '1.5rem' }} style={{ 'marginRight': '5px' }} />Create event</Button>}


                </ColumnContainer>
                <StyledCalendarDetailsHolderHeader><StyledCalendarIcon style={{ 'marginRight': '10px' }} />{selectedItem?.type === "action" ? "Action" : "Event"} Details</StyledCalendarDetailsHolderHeader>

                {selectedItem ? <StyledCalendarDetailsHolderContent>

                    <StyledCalendarDetailsHolderItem>
                        <StyledCalendarDetailsHolderContentLabel>{selectedItem.type === "action" ? "Action" : "Template"}</StyledCalendarDetailsHolderContentLabel>
                        <StyledCalendarDetailsHolderContentValue>{selectedItem.template}</StyledCalendarDetailsHolderContentValue>
                    </StyledCalendarDetailsHolderItem>

                    <Divider />

                    <StyledCalendarDetailsHolderItem>
                        <StyledCalendarDetailsHolderContentLabel>Store</StyledCalendarDetailsHolderContentLabel>
                        <StyledCalendarDetailsHolderContentValue>{selectedItem.store}</StyledCalendarDetailsHolderContentValue>
                    </StyledCalendarDetailsHolderItem>

                    <Divider />

                    <StyledCalendarDetailsHolderItem>
                        <StyledCalendarDetailsHolderContentLabel>Due</StyledCalendarDetailsHolderContentLabel>
                        <StyledCalendarDetailsHolderContentValue>{selectedItem.due}</StyledCalendarDetailsHolderContentValue>
                    </StyledCalendarDetailsHolderItem>

                    <Divider />

                    <StyledCalendarDetailsHolderItem>
                        <StyledCalendarDetailsHolderContentLabel>Status</StyledCalendarDetailsHolderContentLabel>
                        <StyledCalendarDetailsHolderContentValue>{selectedItem.status}</StyledCalendarDetailsHolderContentValue>
                    </StyledCalendarDetailsHolderItem>

                    <Divider />

                    <StyledCalendarDetailsHolderItem>
                        <StyledCalendarDetailsHolderContentLabel>Assigned</StyledCalendarDetailsHolderContentLabel>
                        <StyledCalendarDetailsHolderContentValue>{selectedItem.name}</StyledCalendarDetailsHolderContentValue>
                    </StyledCalendarDetailsHolderItem>

                </StyledCalendarDetailsHolderContent> :
                    <StyledCalendarDetailsHolderContent>
                        <StyledCalendarDetailsHolderContentTitle>Click on an event to view it's details.</StyledCalendarDetailsHolderContentTitle>
                    </StyledCalendarDetailsHolderContent>}

                <ColumnContainer style={{ 'margin': '10px 20px' }}>
                    {selectedItem && selectedItem.type === "visit" && <Button color={`${Colors.blue}`} style={{ 'marginBottom': '10px' }} fullWidth onClick={() => handleShowEditDialog()}><StyledEditIcon style={{ 'marginRight': '5px' }} />Click to edit {selectedItem.type === "action" ? "action" : "event"}</Button>}


                </ColumnContainer>

                <Divider />



                {/* <DataLabelContainer style={{ 'marginTop': '10px' }}>
                    <DataLabelCard>
                        <DataLabelColor color={Colors.green} />
                        Complete
                    </DataLabelCard>
                    <DataLabelCard>
                        <DataLabelColor color={Colors.orange} />
                        Waiting
                    </DataLabelCard>
                    <DataLabelCard>
                        <DataLabelColor color={Colors.highlight} />
                        Missed Visits / Overdue Actions
                    </DataLabelCard>
                </DataLabelContainer> */}



            </StyledCalendarDetailsHolder>
        )
    }

    const MobileDetailsHolder = () => {

        function handleShowEditDialog() {
            setShowEventDetailsDrawer(false)
            setShowAddDialog(true)
        }

        return (
            <Box
                sx={{ width: 300, height: '100%', overflow: 'auto' }}
                role="presentation"

            >
                <StyledCalendarMobileDetailsHolder>

                    <StyledCalendarDetailsHolderHeader><StyledCalendarIcon style={{ 'marginRight': '10px' }} />Event Details</StyledCalendarDetailsHolderHeader>

                    {selectedItem ? <StyledCalendarDetailsHolderContent>

                        <StyledCalendarDetailsHolderItem>
                            <StyledCalendarDetailsHolderContentLabel>{selectedItem.type === "action" ? "Action" : "Template"}</StyledCalendarDetailsHolderContentLabel>
                            <StyledCalendarDetailsHolderContentValue>{selectedItem.template}</StyledCalendarDetailsHolderContentValue>
                        </StyledCalendarDetailsHolderItem>

                        <Divider />

                        <StyledCalendarDetailsHolderItem>
                            <StyledCalendarDetailsHolderContentLabel>Store</StyledCalendarDetailsHolderContentLabel>
                            <StyledCalendarDetailsHolderContentValue>{selectedItem.store}</StyledCalendarDetailsHolderContentValue>
                        </StyledCalendarDetailsHolderItem>

                        <Divider />

                        <StyledCalendarDetailsHolderItem>
                            <StyledCalendarDetailsHolderContentLabel>Due</StyledCalendarDetailsHolderContentLabel>
                            <StyledCalendarDetailsHolderContentValue>{selectedItem.due}</StyledCalendarDetailsHolderContentValue>
                        </StyledCalendarDetailsHolderItem>

                        <Divider />

                        <StyledCalendarDetailsHolderItem>
                            <StyledCalendarDetailsHolderContentLabel>Status</StyledCalendarDetailsHolderContentLabel>
                            <StyledCalendarDetailsHolderContentValue>{selectedItem.status}</StyledCalendarDetailsHolderContentValue>
                        </StyledCalendarDetailsHolderItem>

                        <Divider />

                        <StyledCalendarDetailsHolderItem>
                            <StyledCalendarDetailsHolderContentLabel>Assigned</StyledCalendarDetailsHolderContentLabel>
                            <StyledCalendarDetailsHolderContentValue>{selectedItem.name}</StyledCalendarDetailsHolderContentValue>
                        </StyledCalendarDetailsHolderItem>

                    </StyledCalendarDetailsHolderContent> :
                        <StyledCalendarDetailsHolderContent>
                            <StyledCalendarDetailsHolderContentTitle>Click on an event to view details</StyledCalendarDetailsHolderContentTitle>
                        </StyledCalendarDetailsHolderContent>}

                    <ColumnContainer style={{ 'margin': '10px 20px' }}>
                        {selectedItem && selectedItem.type === "visit" && <Button color={`${Colors.blue}`} style={{ 'marginBottom': '10px' }} fullWidth onClick={handleShowEditDialog}><StyledEditIcon style={{ 'marginRight': '5px' }} />Edit {selectedItem.type === "action" ? "action" : "event"}</Button>}


                    </ColumnContainer>

                    <Divider />



                    {/* <DataLabelContainer style={{ 'marginTop': '10px' }}>
                        <DataLabelCard>
                            <DataLabelColor color={Colors.green} />
                            Complete
                        </DataLabelCard>
                        <DataLabelCard>
                            <DataLabelColor color={Colors.orange} />
                            Waiting
                        </DataLabelCard>
                        <DataLabelCard>
                            <DataLabelColor color={Colors.highlight} />
                            Missed Visits / Overdue Actions
                        </DataLabelCard>
                    </DataLabelContainer> */}



                </StyledCalendarMobileDetailsHolder>
            </Box>)
    }

    const CalendarUsersHolder = () => {

        return (
            calendarUser && users && (
                <Box display="flex" flexDirection="column">
                    <Autocomplete
                        sx={{

                            marginBottom: '10px',
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            },
                            "&.Mui-focused": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none"
                                }
                            }
                        }}
                        id="user-tags"
                        options={[{ name: "All" }, ...users]}
                        groupBy={(option) => option.category}
                        getOptionLabel={(option) => option.name}
                        value={calendarUser}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        onChange={handleUserChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                    backgroundColor: `${Colors.white}`,
                                    padding: '2px 5px',
                                    outline: 'none',
                                    boxShadow: `rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 2px 6px 2px;`

                                }}

                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <StyledSearchIcon />
                                        </InputAdornment>
                                    ),
                                    type: 'Choose a user',
                                    style: { 'fontSize': '0.8rem', 'fontWeight': '400', 'fontFamily': 'Poppins' }
                                }}
                                placeholder="Choose a user"
                            />
                        )}
                    />

                    <TemplateFormFieldRow column={true} align="right" style={{ 'width': 'fit-content', 'alignSelf': 'flex-end', 'margin': '5px' }}>

                        {storeDetails.permissions.schedule.add && calendarUser && calendarUser.category !== "Group" &&
                            <Button color={`${Colors.dark_grey}`} onClick={handleButtonAddSchedule}><StyledCalendarIcon sx={{ 'color': 'white' }} style={{ 'marginRight': '5px' }} />Schedule Visit</Button>}

                        <FormControlLabel control={<Checkbox checked={showActions} onChange={(e) => setShowActions(e.target.checked)} />} label={<Typography style={{ 'fontFamily': "Poppins", fontSize: '0.8rem' }}>Show actions on calendar</Typography>} />


                    </TemplateFormFieldRow>

                    {/* <ColumnContainer style={{ 'margin': '10px 20px' }}>
                        {storeDetails.permissions.schedule.add && calendarUser && calendarUser.category !== "Group" && <Button color={`${Colors.dark_grey}`} fullWidth onClick={handleButtonAddSchedule}><StyledCalendarIcon sx={{ 'color': 'white' }} style={{ 'marginRight': '5px' }} />Schedule Visit</Button>}

                    </ColumnContainer> */}
                </Box>)
        )
    }

    function handleDateClick(info) {
        const { date } = info;
        setEventInfo({
            start: date,
            end: date,
            allDay: true,
            repeatInterval: "None"
        });

        setShowAddDialog(true);
    }

    return (

        <PageHolder>
            <Header>
                <HeaderHolder>
                    Calendar
                    <TemplateDescription>Schedule and manage your teams visits for better planning</TemplateDescription>
                </HeaderHolder>

            </Header>

            {calendarUser && users && (
                <>
                
                <Autocomplete
                    sx={{

                        marginBottom: '0px',
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none"
                        },
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            }
                        },
                        
                    }}
                    id="user-tags"
                    options={[{ name: "All" }, ...users]}
                    groupBy={(option) => option.category}
                    getOptionLabel={(option) => option.name}
                    value={calendarUser}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onChange={handleUserChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                backgroundColor: `${Colors.white}`,
                                padding: '2px 5px',
                                fontSize:'1rem',
                                outline: 'none',
                                boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',

                            }}

                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <>
                                        <StyledSearchIcon />
                                        </>
                                        
                                    </InputAdornment>
                                ),
                                type: 'Choose a user',
                                style: { 'fontSize': '1rem', 'fontWeight': '500', 'fontFamily': 'Poppins' }
                            }}
                            placeholder="Choose a user"
                        />
                    )}
                />
                </>
                

            )}

            {isVerySmallScreen && 
            <ColumnContainer style={{backgroundColor: `${Colors.white}`, marginBottom: '0px', padding: '15px 10px'}} align="right"> 
                <CalendarActionsComponent />

            </ColumnContainer>}

            <PageContainer empty_shadow={true} style={{ 'padding': '0px' }}>

                <Loader show={showLoading} />


                {showAddDialog && calendarUser && <SchedulerAdd
                    open={showAddDialog}
                    setOpen={setShowAddDialog}
                    eventInfo={eventInfo}
                    users={users.filter(user => user.category !== "Group")}
                    userID={eventInfo.user_id || calendarUser.uid || storeDetails.user.uid}
                    addEvent={handleAddEvent}
                    updateEvent={handleUpdateEvent}
                    deleteEvent={handleDeleteEvent}
                    storeDetails={storeDetails} />}


                {/* {isSmallScreen && <CalendarUsersHolder />} */}

                {storeDetails && <StyledCalendarMainHolder>
                    <StyledCalendarHolder>
                        <StyledCalendarWrapper>
                            {showTooltip.tooltipContent && (
                                <TooltipContent>{showTooltip.tooltipContent}</TooltipContent>
                            )}
                            <FullCalendar
                                {...props}
                                editable
                                dateClick={handleDateClick}
                                selectable
                                select={handleSelect}
                                eventClick={handleEventClick}
                                events={events}

                                eventContent={(info) => <ScheduleItem info={info} />}
                                headerToolbar={{
                                    start: "prev, title, next",
                                    end: "dayGridWeek, dayGridMonth, dayGridDay, listWeek",
                                }}
                                plugins={[daygridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                                initialView={isSmallScreen ? 'listWeek' : "listWeek"}
                                views={{
                                    dayGridMonth: {
                                        type: 'dayGrid',
                                        buttonText: 'Month'
                                    },
                                    dayGridWeek: {
                                        type: 'dayGrid',
                                        duration: { days: 7 },
                                        buttonText: 'Week'
                                    },
                                    dayGridDay: {
                                        type: "dayGrid",
                                        duration: { days: 1 },
                                        buttonText: "Day"
                                    },
                                    listWeek: {
                                        type: "listWeek",
                                        buttonText: "Agenda",


                                    }
                                }}
                            />
                        </StyledCalendarWrapper>
                    </StyledCalendarHolder>
                    <EventDetailsHolder />

                    <Drawer
                        anchor="right"
                        sx={{ 'marginTop': '50px', zIndex: 2000 }}
                        open={showEventDetailsDrawer}
                        onClose={() => setShowEventDetailsDrawer(false)}
                    >

                        {<MobileDetailsHolder />}
                    </Drawer>

                </StyledCalendarMainHolder>}





            </PageContainer>



        </PageHolder>

    )
}

export default SchedulerHome
