import styled from "styled-components";
import { Colors } from "./styled.container";

export const FooterContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 20px;

    padding-bottom: 10px;
    font-size: 0.6rem;

    justify-content: center;
    background-color: ${Colors.white};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    flex-direction: column;
    color: ${Colors.dark_grey};
`

export const FooterItem = styled.div`
    
    font-weight: 500;
    
    font-size: 0.7rem;
    font-weight: ${props => props.bold ? 700 : 500};

    a {
        font-size: 0.7rem;
        text-decoration: none;
        
    }

    b {
        font-size: 0.7rem;
        font-weight: 700;
    }
`