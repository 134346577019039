import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { HeaderSubTitle, HeaderTitle, Button, StyledCopyIcon, Colors, AddOptionButton, StyledAddOptionIcon, PageHolder, StyledUpdateIcon, StyledTemplateIcon, ColumnContainer, Text, ButtonOutlined, StyledClearIcon, StyledTextField } from './styled/styled.container'
import { TemplateFormFieldContainer, Input, TemplateFormButtonHolder, TemplateFormFieldNewFieldButton, TemplateFormFieldNewField, TemplateFormField, TemplateFormFieldText, TemplateFormFieldType, TemplateFormFieldRow, TemplateFormFieldAssignable, TemplateFormFieldNumber, TemplateFormFieldLabel, TemplateFormFieldButton, TemplateFormTitle, TemplateFormEmptyContainer, TemplateFormEmptyContainerText, TemplateFormErrorContainer, TemplateFormErrorContainerText, TemplateFormFieldAllowComment, TemplateSection, TemplateFormButtonContainer, TemplateTimerContainer, TemplateFormFieldFilters, TemplateFormButtonStack, DivRow } from './styled/styled.templateform';
import { StyledClosedIcon, PageContainer} from './styled/styled.container';
import { auth, db } from "./firebase";
import { collection, onSnapshot, query, doc, setDoc, orderBy, deleteDoc, addDoc, getDoc, updateDoc, getDocs, where } from "firebase/firestore";
import { FieldTypes, TemplateTypes } from './styled/styled.container';
import { TextField, InputAdornment, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, Tooltip, IconButton } from '@mui/material';
import AlertDialog from "./AlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import { InputField } from "./InputField"
import { TemplateSectionHeader } from './styled/styled.templateform';
import { StyledAddIcon, StyledDeleteIcon, StyledUpArrowIcon, StyledDownArrowIcon } from './styled/styled.container';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InventoryIcon from '@mui/icons-material/Inventory';

import Typography from '@mui/material/Typography';


import Divider from '@mui/material/Divider';

import { useTheme } from '@material-ui/core/styles';
import { InputFieldCount } from './InputFieldCount';
import { debounce } from 'lodash';
import { StyledVisitIcon } from './styled/styled.icons';
import EmptyContainer from './components/EmptyContainer';


const useStyles = makeStyles((theme) => ({
    section: {
        "& .MuiFilledInput-root": {
            backgroundColor: "rgb(232, 241, 250)"
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "rgb(250, 232, 241)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: "rgb(232, 241, 250)"
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "rgb(250, 241, 232)"
        }
    }
}));

const SectionTemplate = React.memo(({ rootID, templateID, addNewSection, isTemplateInternal, showFieldError, handleMoveSectionUp, handleMoveSectionDown, updateSection, duplicateSection, sectionProp, handleDeleteSectionDialog }) => {

    const [section, setSection] = useState(sectionProp)
    const theme = useTheme();

    const fieldObject = {
        section_item_id: 0,
        name: "",
        type: "Comment",
        mandatory: true,
        allowComments: true,
        filesAllowed: true,
        actionAllowed: isTemplateInternal ? true : false,
        editAllowed: false,
        allowEditVisit: true,
        closeOnFill: false,
        additionalOptions: true,
        showFieldSettings: false,
        listCheckboxComments: false,
        scorable: false,
        options: [],
        counts: [],
        item_list_comment: [],
        item_list_checkbox: []
    }

    const styles = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: 400,
        marginTop: 5,
        marginBottom: 5,

    };

    //   useEffect(() => {

    //     if(section) {
    //         console.log("Updating section from sectionTemplate")
    //         updateSection(section)
    //     }

    //   }, [section])
    const debouncedUpdateSection = debounce(updateSection, 200);

    useEffect(() => {
        if (section) {

            debouncedUpdateSection(section);
        }
    }, [section]);


    function addNewField() {

        const newField = {
            ...fieldObject,
            "section_item_id": (Math.floor(Date.now() + Math.random()) + '')
        };

        setSection(prevState => {
            return { ...prevState, list: [...prevState.list, newField] }
        })

    }

    function updateSectionName(text) {

        setSection(prevState => { return { ...prevState, name: text } })

    }

    function setSectionShowOnComplete() {
        setSection(prevState => { return { ...prevState, showOnComplete: !prevState.showOnComplete } })
    }

    // function updateFormFieldName(section_item_id, text) {

    //     const updatedSection = section.list.map(item => {
    //         if(item.section_item_id === section_item_id) {
    //             return { ...item, name: text }
    //         } else 
    //         return item; 
    //     })

    //     setSection(prevState => {
    //         return {...prevState, list: updatedSection}
    //     } );

    // }
    function updateFormFieldName(section_item_id, text) {
        setSection(prevState => {
            const itemIndex = prevState.list.findIndex(item => item.section_item_id === section_item_id);

            if (itemIndex === -1) {
                // No item with the given section_item_id found, return the original state
                return prevState;
            }

            // Clone the list and update only the relevant item
            const updatedSection = [...prevState.list];
            updatedSection[itemIndex] = {
                ...updatedSection[itemIndex],
                name: text
            };

            return { ...prevState, list: updatedSection };
        });
    }

    function addFormFieldOption(section_item_id, text) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, options: [...item.options, text] }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        });

    }

    // function addFormFieldCount(section_item_id, product) {

    //     const updatedSection = section.list.map(item => {
    //         if(item.section_item_id === section_item_id) {
    //             return { ...item, counts: [...item.counts, 
    //                 {
    //                     product: product, count: 0
    //                 }] }
    //         } else 
    //         return item; 
    //     })

    //     setSection(prevState => {
    //         return {...prevState, list: updatedSection}
    //     } );

    // }

    // function addFormFieldCount(section_item_id, product) {
    //     setSection(prevState => {
    //       const updatedSection = prevState.list.map(item => {
    //         if(item.section_item_id === section_item_id) {
    //           const updatedCounts = [
    //             ...item.counts,
    //             {
    //               product: product,
    //               count: [
    //                 { label: item.counts[0]?.count[0]?.label ? item.counts[0].count[0].label || "" : "", value: 0},
    //                 { label: item.counts[0]?.count[1]?.label ? item.counts[0].count[1].label || "" : "", value: 0}
    //               ]
    //             }
    //           ];
    //           return {...item, counts: updatedCounts};
    //         } else {
    //           return item;
    //         }
    //       });
    //       return {...prevState, list: updatedSection};
    //     });
    //   }

    function addFormFieldCount(section_item_id, product) {
        setSection(prevState => {
            let found = false;

            const updatedSection = prevState.list.map(item => {
                if (item.section_item_id === section_item_id) {
                    found = true;

                    const newCount = {
                        product: product,
                        count: [
                            { label: item.counts[0]?.count?.[0]?.label || "", value: 0 },
                            { label: item.counts[0]?.count?.[1]?.label || "", value: 0 }
                        ]
                    };

                    return {
                        ...item,
                        counts: [...item.counts, newCount]
                    };
                }
                return item;
            });

            if (found) {
                return { ...prevState, list: updatedSection };
            }

            return prevState; // if no change, return original state
        });
    }


    // function updateFormFieldCountProduct(section_item_id, index, product) {

    //     const updatedSection = section.list.map(item => {
    //         if(item.section_item_id === section_item_id) {
    //             return {
    //                 ...item, counts: item.counts.map((option, i) => {
    //                     if (i === index) return { ...option, product: product }
    //                     else return option
    //                 })
    //             }
    //         } else 
    //         return item; 
    //     })

    //     setSection(prevState => {
    //         return {...prevState, list: updatedSection}
    //     } );
    // }
    function updateFormFieldCountProduct(section_item_id, index, product) {
        setSection(prevState => {
            let found = false;

            const updatedSection = prevState.list.map(item => {
                if (item.section_item_id === section_item_id) {
                    found = true;

                    // Clone the counts array and update the specific index
                    const updatedCounts = [...item.counts];
                    if (updatedCounts[index]) {
                        updatedCounts[index] = {
                            ...updatedCounts[index],
                            product: product
                        };
                    }

                    return {
                        ...item,
                        counts: updatedCounts
                    };
                }
                return item;
            });

            if (found) {
                return { ...prevState, list: updatedSection };
            }

            return prevState; // if no change, return original state
        });
    }

    function updateFormFieldProductLabel(section_item_id, index, countIndex, newLabel) {
        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return {
                    ...item,
                    counts: item.counts.map((product, i) => {

                        // Clone the existing count array to avoid direct mutations
                        const updatedCount = [...product.count];
                        // Update the label for the specified count
                        updatedCount[countIndex].label = newLabel;
                        return { ...product, count: updatedCount };

                    })
                };
            } else {
                return item;
            }
        });

        setSection(prevState => {
            return { ...prevState, list: updatedSection };
        });
    }


    function removeFormFieldCount(section_item_id, i) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, counts: item.counts.filter((option, index) => index !== i) }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })
    }

    function addFormFieldItemComment(section_item_id, product) {
        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return {
                    ...item, item_list_comment: [...item.item_list_comment,
                    {
                        product: "", comment: ""
                    }]
                }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updateFormFieldItemComment(section_item_id, index, product) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return {
                    ...item, item_list_comment: item.item_list_comment.map((option, i) => {
                        if (i === index) return { product: product, comment: "" }
                        else return option
                    })
                }
            } else
                return item;
        })



        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function removeFormFieldItemComment(section_item_id, i) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, item_list_comment: item.item_list_comment.filter((option, index) => index !== i) }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function addFormFieldItemListCheckbox(section_item_id, product) {
        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return {
                    ...item, item_list_checkbox: [...item.item_list_checkbox,
                    {
                        product: "", checked: false, comment: "",
                    }]
                }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })
    }

    function updateFormFieldItemListCheckbox(section_item_id, index, product) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return {
                    ...item, item_list_checkbox: item.item_list_checkbox.map((option, i) => {
                        if (i === index) return { ...option, product: product }
                        else return option
                    })
                }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function removeFormFieldItemListCheckbox(section_item_id, i) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, item_list_checkbox: item.item_list_checkbox.filter((option, index) => index !== i) }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updateFormFieldOption(section_item_id, index, text) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return {
                    ...item, options: item.options.map((option, i) => {
                        // if (i === index) return text
                        if (i === index) return { product: text, comment: "" }
                        else return option
                    })
                }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function removeFormFieldOption(section_item_id, i) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, options: item.options.filter((option, index) => index !== i) }

            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updatedFormFieldType(section_item_id, selection) {

        //console.log("Chose", selection)

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return {
                    ...item,
                    type: selection,
                    options: [],
                    additionalOptions: true // this is here so that for item selection the default is yes (can't change else you cant add items when doing audit) 
                }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updatedFormFieldAssignable(section_item_id, selection) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, assignable: selection === "Yes" ? true : false }

            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updatedFormFieldMarkMandatory(section_item_id, selection) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, mandatory: selection }

            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updatedFormFieldAllowComments(section_item_id, selection) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, allowComments: selection }

            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updatedFormFieldScorable(section_item_id, scorable) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {

                return { ...item, scorable: scorable }

            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updateFormFieldFilesAllowed(section_item_id, files_allowed) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {

                return { ...item, filesAllowed: files_allowed }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updateFormFieldActionAllowed(section_item_id, action_allowed) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, actionAllowed: action_allowed }


            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updateFormFieldCloseOnFill(section_item_id, close_on_fill) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, closeOnFill: close_on_fill }


            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updateFormFieldEditAllowed(section_item_id, edit_allowed) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, allowEditVisit: edit_allowed }
            } else
                return item;
        })



        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })

    }

    function updateFormFieldAdditionalOptionsAllowed(section_item_id, additional_options_allowed) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, additionalOptions: additional_options_allowed }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })
    }

    function updateFormFieldListCheckboxComments(section_item_id, comments_allowed) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, listCheckboxComments: comments_allowed }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })
    }

    function handleToggleFieldSettings(section_item_id) {

        const updatedSection = section.list.map(item => {
            if (item.section_item_id === section_item_id) {
                return { ...item, showFieldSettings: !item.showFieldSettings }
            } else
                return item;
        })

        setSection(prevState => {
            return { ...prevState, list: updatedSection }
        })
    }

    function deleteFormField(section_item_id) {

        if (templateID.length > 0) {
            const sectionItemRef = doc(db, "stores/" + rootID + "/templates/" + templateID + "/sections/" + section.section_id + "/list/" + section_item_id);
            deleteDoc(sectionItemRef).then(result => {

            })
        }

        setSection(prevState => {
            return { ...prevState, list: prevState.list.filter(item => item.section_item_id !== section_item_id) }
        })

    }

    function handleMoveFieldUp(currentIndex) {
        if (currentIndex === 0) return;

        setSection(prevState => {
            const newList = [...prevState.list];
            const element = newList.splice(currentIndex, 1)[0];
            newList.splice(currentIndex - 1, 0, element);

            return { ...prevState, list: newList }
        })

    }

    function handleMoveFieldDown(currentIndex) {
        if (currentIndex === section.list.length - 1) return;

        setSection(prevState => {
            const newList = [...prevState.list];
            const element = newList.splice(currentIndex, 1)[0];
            newList.splice(currentIndex + 1, 0, element);

            return { ...prevState, list: newList }
        })

    }

    const helperTextStyle = {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: `${Colors.highlight}`,
        color: `${Colors.white}`,
        borderRadius: '5px',
        padding: '5px 0px',
        justifyContent: 'center',
        fontSize: '0.7rem',
        fontWeight: 600
    };


    return (
        <TemplateSection>


            <TemplateSectionHeader row backgroundColor={section?.showOnComplete ? Colors.blue : undefined}>

                <ColumnContainer>
                    <InputField
                        error={showFieldError && section.name.length === 0}
                        helperText={showFieldError && section.name.length === 0 ? (<span style={helperTextStyle}>Enter a section name</span>) : ''}
                        placeholder='Enter a section name'
                        padding={'0px'}
                        size="small"
                        margin={'0px'}
                        inputProps={{

                            style: {
                                textAlign: "center",
                                fontSize: 16,
                                textTransform: "capitalize",
                                padding: '0px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: `${section.showOnComplete ? `${Colors.dark_grey}` : `${Colors.dark_grey_highlight}`}`,
                            },
                        }}
                        InputProps={{
                            disableUnderline: true,

                        }}
                        fullWidth
                        variant="standard"
                        myVal={section.name}
                        multiline
                        updateHandler={updateSectionName}
                    />
                    {section?.showOnComplete && <Text color={Colors.white}>This section will only show once the visit has been completed</Text>}

                </ColumnContainer>

                <IconButton size='small' onClick={() => handleMoveSectionUp(section.section_id)}>
                    <StyledUpArrowIcon />
                </IconButton>
                <IconButton size='small' onClick={() => handleMoveSectionDown(section.section_id)}><StyledDownArrowIcon /></IconButton>

                <Tooltip title="Show section only once visit is complete">
                    <IconButton size='small' onClick={setSectionShowOnComplete}>
                        <StyledVisitIcon color={section?.showOnComplete ? Colors.white : Colors.dark_grey_highlight} />
                    </IconButton>

                </Tooltip>
                <IconButton size='small' onClick={() => handleDeleteSectionDialog(section.section_id)}><StyledClearIcon /></IconButton>






            </TemplateSectionHeader>

            {section.list.map((element, itemIndex) => {

                return (
                    <TemplateFormField key={itemIndex}>


                        <TemplateFormFieldRow row>

                            <InputField
                                error={showFieldError && element.name.length === 0}
                                helperText={showFieldError && element.name.length === 0 ? "Enter a section question" : ''}
                                placeholder='Enter your question here'
                                padding={'0px'}
                                multiline
                                margin={'5px'}
                                inputProps={{
                                    style: {
                                        fontFamily: "Poppins",
                                        fontSize: '0.8rem'

                                    }
                                }}

                                InputProps={{
                                    style: { color: "black", fontWeight: '500', fontSize: '0.9rem' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {itemIndex + 1}
                                        </InputAdornment>
                                    ),
                                    endAdornment: (<>
                                        <IconButton size='small' onClick={() => handleMoveFieldUp(itemIndex)}><StyledUpArrowIcon /></IconButton>
                                        <IconButton size='small' onClick={() => handleMoveFieldDown(itemIndex, section.list.length - 1)}><StyledDownArrowIcon /></IconButton>
                                        <IconButton size='small' onClick={() => deleteFormField(element.section_item_id)}><StyledClearIcon /></IconButton>
                                    </>)
                                }}
                                fullWidth
                                size="small"
                                variant="standard"
                                myVal={element.name}
                                section_item_id={element.section_item_id}
                                updateHandler={updateFormFieldName}
                            />



                        </TemplateFormFieldRow>

                        <TemplateFormFieldRow style={{marginTop: '0px'}} wrap >

                            <StyledTextField
                                id="field-type"
                                size="small"
                                select
                                sx={{ 'minWidth': '250px', margin: '5px' }}
                                
                                inputProps={{
                                    style: {
                                        fontFamily: "Poppins",

                                    }
                                }}

                                

                                value={element.type}
                                onChange={(e) => updatedFormFieldType(element.section_item_id, e.target.value)}

                            >
                                {FieldTypes.map((type, i) => {
                                    return <MenuItem key={i} style={{ display: 'flex', alignItems: 'center' }} value={type}>{type}</MenuItem>
                                })}
                            </StyledTextField>
                            <ButtonOutlined selected={element.showFieldSettings} style={{ 'margin': '5px' }} onClick={() => handleToggleFieldSettings(element.section_item_id)}>Field Settings</ButtonOutlined>

                        </TemplateFormFieldRow>

                        {element.type === "Selection" &&
                            <Fragment>
                                {element.options.map((item, i) => {

                                    return <TemplateFormFieldRow key={i}>

                                        <InputField


                                            placeholder='Enter a new option'
                                            inputProps={{
                                                style: {
                                                    margin: '0px',
                                                    padding: '5px 0px',
                                                    fontFamily: `Poppins`,
                                                    fontSize: `0.9rem`,

                                                },
                                            }}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <RadioButtonUncheckedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            variant="standard"
                                            myVal={item.product}
                                            section_item_id={element.section_item_id}
                                            i={i}
                                            updateHandler={updateFormFieldOption}
                                        />


                                        {/* <TextField
                                            fullWidth
                                            placeholder='Enter a new option'
                                            value={item}
                                            onChange={(e) => updateFormFieldOption(element.section_item_id, i, e.target.value)}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <RadioButtonUncheckedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        /> */}
                                        <StyledClosedIcon onClick={() => removeFormFieldOption(element.section_item_id, i)} />
                                    </TemplateFormFieldRow>

                                })}

                                {<TemplateFormFieldRow>
                                    <Button onClick={() => addFormFieldOption(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</Button>
                                </TemplateFormFieldRow>}

                            </Fragment>

                        }

                        {element.type === "Item Selection" &&
                            <Fragment>
                                {element.options.map((item, i) => {

                                    return <TemplateFormFieldRow key={i}>

                                        <InputField


                                            placeholder='Enter a new option'
                                            inputProps={{
                                                style: {
                                                    margin: '0px',
                                                    padding: '5px 0px',
                                                    fontFamily: `Poppins`,
                                                    fontSize: `0.9rem`,

                                                },
                                            }}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <RadioButtonUncheckedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            variant="standard"
                                            myVal={item.product}
                                            section_item_id={element.section_item_id}
                                            i={i}
                                            updateHandler={updateFormFieldOption}
                                        />

                                        {/* <TextField
                                            fullWidth
                                            placeholder='Enter a new option'
                                            value={item}
                                            onChange={(e) => updateFormFieldOption(element.section_item_id, i, e.target.value)}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <RadioButtonUncheckedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        /> */}
                                        <StyledClosedIcon onClick={() => removeFormFieldOption(element.section_item_id, i)} />
                                    </TemplateFormFieldRow>

                                })}

                                {<TemplateFormFieldRow>
                                    <Button onClick={() => addFormFieldOption(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</Button>
                                </TemplateFormFieldRow>}

                            </Fragment>

                        }

                        {element.type === "Item Selection Counts" &&
                            <Fragment>
                                {element.options.map((item, i) => {

                                    return <TemplateFormFieldRow key={i}>

                                        <InputField


                                            placeholder='Enter a new option'
                                            inputProps={{
                                                style: {
                                                    margin: '0px',
                                                    padding: '5px 0px',
                                                    fontFamily: `Poppins`,
                                                    fontSize: `0.9rem`,

                                                },
                                            }}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <RadioButtonUncheckedIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            variant="standard"
                                            myVal={item.product}
                                            section_item_id={element.section_item_id}
                                            i={i}
                                            updateHandler={updateFormFieldOption}
                                        />


                                        <StyledClosedIcon onClick={() => removeFormFieldOption(element.section_item_id, i)} />
                                    </TemplateFormFieldRow>

                                })}

                                {<TemplateFormFieldRow>
                                    <Button onClick={() => addFormFieldOption(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</Button>
                                </TemplateFormFieldRow>}

                            </Fragment>

                        }

                        {element.type === "Item Count" &&
                            <Fragment>
                                {element.counts.map((item, i) => {

                                    return <div key={i}>
                                        <TemplateFormFieldRow>
                                            <InputFieldCount


                                                placeholder='Enter the count label'
                                                inputProps={{
                                                    style: {
                                                        margin: '0px',
                                                        padding: '10px 0px',
                                                        fontFamily: `Poppins`,
                                                        fontSize: `0.9rem`,

                                                    },
                                                }}
                                                InputProps={{
                                                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <InventoryIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                label={"Count Label"}
                                                myVal={item?.count[0]?.label ? item.count[0].label : "Count"}
                                                section_item_id={element.section_item_id}
                                                i={i}
                                                countIndex={0}
                                                updateHandler={updateFormFieldProductLabel}
                                            />
                                        </TemplateFormFieldRow>
                                        <TemplateFormFieldRow>

                                            <InputField


                                                placeholder='Enter an item or leave blank to be completed on visit'
                                                inputProps={{
                                                    style: {
                                                        margin: '0px',
                                                        padding: '5px 0px',
                                                        fontFamily: `Poppins`,
                                                        fontSize: `0.9rem`,

                                                    },
                                                }}
                                                InputProps={{
                                                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <InventoryIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                variant="standard"
                                                myVal={item.product}
                                                section_item_id={element.section_item_id}
                                                i={i}
                                                updateHandler={updateFormFieldCountProduct}
                                            />

                                            <StyledClosedIcon onClick={() => removeFormFieldCount(element.section_item_id, i)} />
                                        </TemplateFormFieldRow>


                                    </div>



                                })}
                                {<TemplateFormFieldRow>
                                    <Button onClick={() => addFormFieldCount(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</Button>
                                </TemplateFormFieldRow>}
                            </Fragment>

                        }

                        {element.type === "Item Double Count" &&
                            <Fragment>
                                {element.counts.map((item, i) => {

                                    return <div key={i}>

                                        {i == 0 && <TemplateFormFieldRow>
                                            <InputFieldCount

                                                placeholder='Enter a count label'
                                                sx={{ 'margin': '0px 5px' }}
                                                inputProps={{
                                                    style: {
                                                        margin: '0px',
                                                        padding: '10px 0px',
                                                        fontFamily: `Poppins`,
                                                        fontSize: `0.8rem`,

                                                    },
                                                }}
                                                InputProps={{
                                                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <InventoryIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                size="medium"
                                                label={"First Count Label"}
                                                myVal={element?.counts[0]?.count[0]?.label ? element.counts[0].count[0].label : "Count"}
                                                section_item_id={element.section_item_id}
                                                i={i}
                                                countIndex={0}
                                                updateHandler={updateFormFieldProductLabel}
                                            />

                                            <InputFieldCount

                                                placeholder='Enter a count label'
                                                inputProps={{
                                                    style: {
                                                        margin: '0px',
                                                        padding: '10px 0px',
                                                        fontFamily: `Poppins`,
                                                        fontSize: `0.8rem`,

                                                    },
                                                }}
                                                InputProps={{
                                                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <InventoryIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                myVal={element.counts[0].count[1].label}
                                                label={"Second Count Label"}
                                                section_item_id={element.section_item_id}
                                                i={i}
                                                countIndex={1}
                                                updateHandler={updateFormFieldProductLabel}
                                            />
                                        </TemplateFormFieldRow>}
                                        <TemplateFormFieldRow>

                                            <InputField


                                                placeholder='Enter an item or leave blank to be completed on visit'
                                                inputProps={{
                                                    style: {
                                                        margin: '0px',
                                                        padding: '5px 0px',
                                                        fontFamily: `Poppins`,
                                                        fontSize: `0.9rem`,

                                                    },
                                                }}
                                                InputProps={{
                                                    style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <InventoryIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                variant="standard"
                                                myVal={item.product}
                                                section_item_id={element.section_item_id}
                                                i={i}
                                                updateHandler={updateFormFieldCountProduct}
                                            />

                                            <StyledClosedIcon onClick={() => removeFormFieldCount(element.section_item_id, i)} />


                                        </TemplateFormFieldRow>


                                    </div>



                                })}
                                {<TemplateFormFieldRow>
                                    <Button onClick={() => addFormFieldCount(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</Button>
                                </TemplateFormFieldRow>}
                            </Fragment>

                        }

                        {(element.type === "Item Comment" || element.type === "Item List") &&
                            <Fragment>
                                {element.item_list_comment.map((item, index) => {

                                    return <TemplateFormFieldRow key={index}>
                                        <InputField


                                            placeholder='Enter an item or leave blank for visit'
                                            inputProps={{
                                                style: {
                                                    margin: '0px',
                                                    padding: '5px 0px',
                                                    fontFamily: `Poppins`,
                                                    lineHeight: 1.5,

                                                    fontSize: `0.8rem`,

                                                },
                                            }}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <InventoryIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            variant="standard"
                                            myVal={item.product}
                                            section_item_id={element.section_item_id}
                                            i={index}
                                            updateHandler={updateFormFieldItemComment}
                                        />
                                        {/* <TextField
                                            fullWidth
                                            placeholder='Enter an item or leave blank to be completed on visit'
                                            value={item.product}
                                            multiline
                                            onChange={(e) => updateFormFieldItemComment(element.section_item_id, i, e.target.value)}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <InventoryIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        /> */}
                                        <StyledClosedIcon onClick={() => removeFormFieldItemComment(element.section_item_id, index)} />
                                    </TemplateFormFieldRow>

                                })}

                                {<TemplateFormFieldRow>
                                    <Button onClick={() => addFormFieldItemComment(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</Button>
                                </TemplateFormFieldRow>}

                            </Fragment>

                        }

                        {(element.type === "List Checkbox") &&
                            <Fragment>
                                {element.item_list_checkbox.map((item, i) => {

                                    return <TemplateFormFieldRow key={i}>
                                        <InputField


                                            placeholder='Enter an option here'
                                            inputProps={{
                                                style: {
                                                    margin: '0px',
                                                    padding: '5px 0px',
                                                    fontFamily: `Poppins`,
                                                    fontSize: `0.9rem`,

                                                },
                                            }}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <InventoryIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            variant="standard"
                                            myVal={item.product}
                                            section_item_id={element.section_item_id}
                                            i={i}
                                            updateHandler={updateFormFieldItemListCheckbox}
                                        />

                                        {/* <TextField
                                            fullWidth
                                            placeholder='Enter a new option'
                                            value={item.product}
                                            multiline
                                            onChange={(e) => updateFormFieldItemListCheckbox(element.section_item_id, i, e.target.value)}
                                            InputProps={{
                                                style: { color: "black", fontWeight: '400', fontSize: '0.9rem' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <InventoryIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />  */}
                                        <StyledClosedIcon onClick={() => removeFormFieldItemListCheckbox(element.section_item_id, i)} />
                                    </TemplateFormFieldRow>

                                })}

                                {<TemplateFormFieldRow>
                                    <Button onClick={() => addFormFieldItemListCheckbox(element.section_item_id, "")}><StyledAddOptionIcon fontSize='medium' style={{ 'marginRight': '5px' }} /> Add option</Button>
                                </TemplateFormFieldRow>}

                            </Fragment>

                        }

                        <FormGroup sx={{ 'marginTop': '0px', 'marginBottom': '5px' }}>

                            {(element.type === "Item Count" || element.type === "Item Double Count" || element.type === "Item Comment" || element.type === "Item List")
                                && <FormControlLabel
                                    control={<Checkbox
                                        checked={element.additionalOptions}
                                        onChange={e => updateFormFieldAdditionalOptionsAllowed(element.section_item_id, e.target.checked)} />}
                                    label={
                                        <Typography>
                                            Allow new options to be added during a visit
                                        </Typography>
                                    } />}


                            {(element.type === "List Checkbox")
                                && <FormControlLabel
                                    control={<Checkbox
                                        checked={element.listCheckboxComments}
                                        onChange={e => updateFormFieldListCheckboxComments(element.section_item_id, e.target.checked)} />}
                                    label={
                                        <Typography>
                                            Each item should have a comment during a visit
                                        </Typography>
                                    } />}

                        </FormGroup>


                        <FormGroup sx={{ 'display': `${element?.showFieldSettings ? "flex" : "none"}`, 'marginTop': '0px' }}>
                            <FormControlLabel

                                control={<Checkbox
                                    checked={element.mandatory || false}
                                    onChange={e => updatedFormFieldMarkMandatory(element.section_item_id, e.target.checked)} />}
                                label={
                                    <Typography>
                                        This field is mandatory
                                    </Typography>
                                } />

                            <FormControlLabel

                                control={<Checkbox
                                    checked={element.allowComments && isTemplateInternal}
                                    disabled={!isTemplateInternal}
                                    onChange={e => updatedFormFieldAllowComments(element.section_item_id, e.target.checked)} />}
                                label={
                                    <Typography>
                                        Allow comments on this field
                                    </Typography>
                                } />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={element.scorable}
                                    onChange={e => updatedFormFieldScorable(element.section_item_id, e.target.checked)} />}
                                label={
                                    <Typography >
                                        This field is scorable
                                    </Typography>
                                } />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={element.filesAllowed && isTemplateInternal}
                                    disabled={!isTemplateInternal}
                                    onChange={e => updateFormFieldFilesAllowed(element.section_item_id, e.target.checked)} />}
                                label={
                                    <Typography >
                                        Allow files to be added to this field
                                    </Typography>
                                } />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={element.actionAllowed && isTemplateInternal}
                                    disabled={!isTemplateInternal}
                                    onChange={e => updateFormFieldActionAllowed(element.section_item_id, e.target.checked)} />}
                                label={
                                    <Typography >
                                        Allow actions on this field
                                    </Typography>
                                } />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={element?.closeOnFill || false}
                                    onChange={e => updateFormFieldCloseOnFill(element.section_item_id, e.target.checked)} />}

                                label={
                                    <Typography>
                                        Automatically trigger the status to closed once this field is filled in
                                    </Typography>
                                } />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={element?.allowEditVisit === undefined ? false : element.allowEditVisit}
                                    onChange={e => updateFormFieldEditAllowed(element.section_item_id, e.target.checked)} />}
                                label={
                                    <Typography>
                                        Allow this field to be edited once the visit is completed
                                    </Typography>
                                } />


                        </FormGroup>



                    </TemplateFormField>)
            })}


            <TemplateFormButtonContainer style={{ 'marginTop': '15px', 'paddingRight': '10px', gap: '5px' }} align="right">


                {/* <AddOptionButton backgroundColor={Colors.orange} style={{'marginRight': '10px'}}  onClick={() => duplicateSection(section)}>Duplicate section</AddOptionButton> */}
                  
                <Button color={Colors.blue} style={{ 'marginRight': '10px' }} onClick={() => addNewField(section.section_id)}>New field</Button>


            </TemplateFormButtonContainer>



        </TemplateSection>
    )
})

export default SectionTemplate