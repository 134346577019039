import React, {useState, useEffect, useContext} from 'react'
import { CardContainer, Button, Colors, StyledUserIcon } from './styled/styled.container'
import { SettingsItemHolder, SettingsText } from './styled/styled.settings'
import { doc, query, setDoc, updateDoc, collection, getDocs, where } from 'firebase/firestore';
import { db, logout} from "./firebase";
import { TableContainer } from './styled/styled.container';
import { DataGrid } from '@mui/x-data-grid';
import { TemplateFormButtonContainer, TemplateFormFieldRow } from './styled/styled.templateform';
import { SettingsButton } from './styled/styled.settings';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import AuthContext from './AuthContext';
import { TemplateFormButtonStack } from './styled/styled.templateform';
import { GroupTitle, PermissionType } from './styled/styled.usergroups';


function SettingsUserGroups() {
    const {storeDetails} = useContext(AuthContext)
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [showLoading, setShowLoading] = useState(false)
    
    const navigate = useNavigate();

    const columns = [
        { field: "id", headerName: "id", hide: true, minWidth: 100},
        { field: "name", headerName: "User Group", minWidth: 200, flex: 1}
      ];

      useEffect(() => {
        const fetchGroups = async () => {
            try {
            
                if(!storeDetails.user.admin) navigate("/app");
    
              setGroups([]) //
    
              const q = query(collection(db, "stores/" + storeDetails.rootID + "/permissions"));
              const doc = await getDocs(q);
              doc.docs.forEach(element => {
                const groupID = element.id;
                const groupData = element.data();
                //console.log(groupDetails)
                setGroups(prevState => ([...prevState, {
                    id: groupID,
                    name: groupData.name
                }]))
              });
        
            } catch (err) {
              console.error(err);
            }
          };

          fetchGroups();
      }, [storeDetails])

      

      const handleRowClick = (params) => {
        
        setSelectedGroup(params.row);
        //console.log(params.row)
        navigate("/app/settings/usergroups/" + params.row.id);
      };

      function handleNewGroupClick() {
        navigate("/app/settings/usergroups/new");
      }

  return (
    <>
    <CardContainer>
        <Loader show={showLoading} />
        <GroupTitle>Click a user group to view the permissions linked to the group</GroupTitle>
        <TableContainer style={{'marginTop': '10px'}}>
            {groups &&
            <DataGrid
                rows={groups}
                sx={{'border': '0'}}
                autoHeight
                columns={columns}
                onRowClick={handleRowClick}
                getRowId={(row) => row.id}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />}
            </TableContainer>
        <TemplateFormButtonStack style={{'marginTop': '20px'}} align="right">
            <Button color={Colors.blue} onClick={handleNewGroupClick}><StyledUserIcon /> New Group</Button>
        </TemplateFormButtonStack>
    </CardContainer>
    </>
  )
}

export default SettingsUserGroups