import styled from "styled-components";
import { Colors } from "./styled.container";

export const TemplateErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    font-size: 18px;
    font-weight: 600;
    margin: auto;
    color: #666;
    text-align: center;
`

export const PublicVisitStoreName = styled.div`
    font-size: 1.2rem;
    font-weight: 500;
    width: fit-content;
    text-transform: capitalize;
    color: ${Colors.white};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    letter-spacing: 1.1px;
    margin: 10px auto;
    margin-bottom: 20px;
    text-align: center;
    min-width: 150px;
    background-color: ${Colors.blue};
    padding: 5px 20px;

`

export const PublicVisitHeaderContainer = styled.div`
    border-radius: 10px;
    display: flex;
    
    
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    flex-direction: column;
    padding: 15px 10px;
    
    width: 100%;
    margin-bottom: 20px;
`