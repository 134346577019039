import React, { useState, useEffect, useContext } from 'react';
import { Button, ColumnContainer, StyledAlertIcon, StyledClosedIcon, StyledScheduleIcon, Text, TextBubble } from './styled/styled.container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, Divider, FormControlLabel, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { db } from "./firebase";
import { v4 as uuid } from "uuid";
import { collection, onSnapshot, query, doc, getDoc, getDocs, where } from "firebase/firestore";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from "moment"
import Loader from './Loader';
import { TemplateFormButtonStack, TemplateFormFieldRow } from './styled/styled.templateform';
import MenuItem from '@mui/material/MenuItem';
import { Colors } from './styled/styled.container';
import DeleteRepeatingScheduleDialog from './DeleteRepeatingScheduleDialog';
import UpdateRepeatingScheduleDialog from './UpdateRepeatingScheduleDialog';
import AuthContext from './AuthContext';
import ScheduleNotificationDialog from './components/ScheduleNotificationDialog';

export default function SchedulerAdd(props) {
  const { storeDetails } = useContext(AuthContext);
  const { open, setOpen, addEvent, updateEvent, deleteEvent, eventInfo, users, userID } = props;
  const [templates, setTemplates] = useState([]);
  const [stores, setStores] = useState([])
  const [eventDate, setEventDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedUser, setSelectedUser] = useState(users.find(user => user.uid === userID))
  const [showLoading, setShowLoading] = useState(false);
  const [showDeleteRepeatingVisit, setShowDeleteRepeatingVisit] = useState(false);
  const [showUpdateRepeatingVisit, setShowUpdateRepeatingVisit] = useState(false)
  const [selectedRepeatInterval, setSelectedRepeatInterval] = useState("None");
  const [isAllDayEvent, setIsAllDayEvent] = useState(true);
  const repeatInterval = ['None', "Daily", "Weekly", "Monthly"]
  const [showNotificationTriggerDialog, setShowNotificationTriggerDialog] = useState(false)
  const [notificationList, setNotificationList] = useState([])


  useEffect(() => {

    async function getDetails() {
      setSelectedStore(null)
      setSelectedTemplate(null)
      setShowLoading(true)

      if (eventInfo) {


        //console.log("Event for ", selectedUser)

        //console.log(eventInfo.repeatInterval)
        if (eventInfo.repeatInterval)
          setSelectedRepeatInterval(eventInfo.repeatInterval)

        if (eventInfo.notificationList)
          setNotificationList(eventInfo.notificationList)

        const templateElements = await getTemplates(selectedUser);
        const storeElements = await getStores(selectedUser);

        if (eventInfo.allDay) {
          setEventDate(moment(eventInfo.start).format("YYYY-MM-DD"))
        } else {
          setEventDate(moment(eventInfo.start).format("YYYY-MM-DDTHH:mm"))

        }
        setIsAllDayEvent(eventInfo.allDay)


        //console.log(eventInfo)
        if (eventInfo && eventInfo.title) {
          //console.log(eventInfo.title)
          const details = eventInfo.title.split('\n');
          const templateName = details[0]
          const storeName = details[1];

          const eventTemplate = templateElements.filter(template => template.name === templateName)[0];
          const eventStore = storeElements.filter(store => store.name === storeName)[0]

          setSelectedTemplate(eventTemplate)
          setSelectedStore(eventStore)
        }
        else {
          setSelectedStore(null)
          setSelectedTemplate(null)
        }

      }


      setShowLoading(false)

    }

    if (eventInfo && open) getDetails();


  }, [eventInfo])

  // useEffect(() => {

  const getTemplates = async (selectedUser) => {

    //console.log("Selected user", selectedUser)
    if (selectedUser) {
      if (selectedUser.type === "admin") {
        const all_templates = storeDetails.templates;
        //console.log(all_templates)
        setTemplates(all_templates)
        return all_templates
      }
      else {
        const user_templates = storeDetails.templates.filter(template => {
          //console.log(template)
          return template.permission_users.includes(selectedUser.group)
        })

        setTemplates(user_templates)
        return user_templates
      }
    }


  }

  const getStores = async (selectedUser) => {
    //console.log("Getting stores")
    const storesElements = storeDetails.stores;
    if (selectedUser.user_specific_stores) {
      const filteredStores = storesElements.filter(store => selectedUser.user_specific_stores.includes(store.cloud_id))
      setStores(filteredStores)
      return filteredStores
    } else {
      setStores(storesElements)
      return storesElements;
    }
  }

  // }, [stores, templates, eventInfo])

  const handleStoreChange = (event, values) => {

    setSelectedStore(values)
    //updateSelectedStore(values)
  };

  const handleTemplateChange = (event, values) => {


    setSelectedTemplate(values)
    //updateSelectedTemplate(values)
  };

  const handleUserChange = async (event, values) => {
    await getTemplates(values)
    await getStores(values)
    setSelectedUser(values)

  }

  const handleClose = () => {
    setOpen(false);
  };

  function handleSaveEvent() {

    if (!storeDetails.permissions.schedule.add) return;

    if (selectedStore && selectedTemplate && selectedUser) {

      const date = moment(eventDate).format();

      if (eventInfo.id) {

        if (eventInfo.repeatInterval !== "None" && eventInfo.repeatInterval === selectedRepeatInterval) setShowUpdateRepeatingVisit(true)
        else updateEvent(selectedUser, selectedTemplate, selectedStore, eventInfo.id, eventInfo.parentID, date, date, selectedRepeatInterval, isAllDayEvent, notificationList, "thisVisit")
      } else
        addEvent(selectedUser, selectedTemplate, selectedStore, uuid(), date, date, selectedRepeatInterval, isAllDayEvent, notificationList, false);

    }

  }


  function handleDeleteEvent() {

    if (eventInfo.repeatInterval !== "None") {
      setShowDeleteRepeatingVisit(true)
    }
    else deleteEvent(eventInfo.id, eventInfo.parentID, "thisVisit", eventInfo.repeatInterval);

  }

  function handleRepeatIntervalChange(e) {
    setSelectedRepeatInterval(e.target.value)
  }

  function handleChangeAllDayEvent(e) {
    setIsAllDayEvent(e.target.checked)

    if (!e.target.checked) {
      const currentEventDate = moment(eventDate).format("YYYY-MM-DD HH:mm")
      setEventDate(currentEventDate)
    }
    else {
      const currentEventDate = moment(eventDate).format("YYYY-MM-DD")
      setEventDate(currentEventDate)
    }
  }

  const groupByRegion = (store) => `${store?.region} (${store?.sub_region})`;

  const sortRegions = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  return (
    <div style={{ 'margin': '10px 0px' }}>
      <Loader show={showLoading} />

      {showDeleteRepeatingVisit && <DeleteRepeatingScheduleDialog
        open={showDeleteRepeatingVisit}
        setOpen={setShowDeleteRepeatingVisit}
        eventInfo={eventInfo}
        deleteEvent={deleteEvent} />}

      {showUpdateRepeatingVisit && <UpdateRepeatingScheduleDialog
        open={showUpdateRepeatingVisit}
        setOpen={setShowUpdateRepeatingVisit}
        eventInfo={eventInfo}
        notificationList={notificationList}
        selectedUser={selectedUser}
        storeName={selectedStore.name}
        templateName={selectedTemplate.name}
        template={selectedTemplate}
        store={selectedStore}
        repeatInterval={selectedRepeatInterval}
        isAllDayEvent={isAllDayEvent}
        date={moment(eventDate).format('YYYY-MM-DDTHH:mm:ss')}
        updateEvent={updateEvent} />}

      {showNotificationTriggerDialog && <ScheduleNotificationDialog
        users={users}
        notificationList={notificationList}
        setNotificationList={setNotificationList}
        open={showNotificationTriggerDialog}
        setOpen={setShowNotificationTriggerDialog} />}

      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <TemplateFormFieldRow style={{ marginBottom: '0px' }} align="space-around">
            <Text fontSize={"1rem"} fontWeight={600}>Schedule Visit</Text>
            <IconButton onClick={handleClose}>
              <StyledClosedIcon sx={{ fontSize: 50 }} />
            </IconButton>

          </TemplateFormFieldRow>

        </DialogTitle>
        <DialogContent>
          <Autocomplete
            sx={{ 'marginTop': '20px', 'marginBottom': '10px' }}
            id="user-list"
            options={[...users].sort((a, b) => {
              if (a.type < b.type) return -1;
              if (a.type > b.type) return 1;
              return 0;
            })}

            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={selectedUser}
            onChange={handleUserChange}
            groupBy={(option) => option.type}
            getOptionLabel={(option) => option.name}


            renderInput={(params) => (
              <TextField
                {...params}
                label="User"
                InputProps={{
                  ...params.InputProps,
                  type: 'Choose a user',
                }}
              />
            )}
          />

          <TemplateFormFieldRow>
            <Autocomplete

              id="stores-list"
              options={[...stores].sort((a, b) => {

                const groupSort = groupByRegion(a)?.localeCompare(groupByRegion(b));
                if (groupSort && groupSort === 0) {
                  return sortRegions(a, b);
                }
                return groupSort;
              })}
              groupBy={groupByRegion}
              fullWidth
              isOptionEqualToValue={(option, value) => option.name === value.name}
              value={selectedStore}
              onChange={handleStoreChange}

              getOptionLabel={(option) => option.name}


              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  InputProps={{
                    ...params.InputProps,
                    type: 'Choose a location',
                  }}
                />
              )}
            />

            <Autocomplete
              sx={{ 'marginLeft': '10px' }}
              id="templates-list"
              fullWidth
              value={selectedTemplate}
              options={[...templates].sort((a, b) => {
                if (a.template_type < b.template_type) return -1;
                if (a.template_type > b.template_type) return 1;
                return 0;
              })}
              isOptionEqualToValue={(option, value) => option?.name === value?.name}
              onChange={handleTemplateChange}
              groupBy={(option) => option.template_type}
              getOptionLabel={(option) => option.name}

              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Template"
                  InputProps={{
                    ...params.InputProps,
                    type: 'Choose a template',
                  }}
                />
              )}
            />
          </TemplateFormFieldRow>

          <TemplateFormFieldRow style={{ 'marginBottom': '5px' }}>
            <TextField
              sx={{ 'marginRight': '10px' }}
              label="Scheduled Date"
              type={isAllDayEvent ? "date" : "datetime-local"}
              fullWidth
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}

              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              id="repeat-list"
              select
              fullWidth
              label="Repeat Interval"
              value={selectedRepeatInterval}
              onChange={handleRepeatIntervalChange}
              variant="outlined"
            >
              {repeatInterval.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

          </TemplateFormFieldRow>
          <TemplateFormFieldRow style={{ marginBottom: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllDayEvent}
                  onChange={handleChangeAllDayEvent}
                  name="allDayEvent"
                  color="primary"
                />
              }
              label="All Day Event"
            />
            <TextBubble style={{ margin: '10px 0px' }} backgroundColor={Colors.card_background} color={Colors.dark_grey_highlight} fontSize={"0.8rem"} fontWeight={500}>You have {notificationList.length} notifications set</TextBubble>
          </TemplateFormFieldRow>

          <ColumnContainer>

            <Button style={{ width: 'fit-content' }} onClick={() => setShowNotificationTriggerDialog(true)} fontSize={"0.7rem"} color={Colors.blue}><StyledScheduleIcon />Manage Notifications</Button>
          </ColumnContainer>




        </DialogContent>
        <DialogActions>
          <Divider />
          <TemplateFormButtonStack style={{ margin: '0px 10px', marginBottom: '10px' }} align="right">

            {eventInfo && eventInfo.id && <Button color={`${Colors.highlight}`} onClick={handleDeleteEvent}>Delete</Button>}
            <Button color={`${Colors.blue}`} onClick={handleSaveEvent} autoFocus>{eventInfo.id ? "Update" : "Set"}</Button>
          </TemplateFormButtonStack>


        </DialogActions>
      </Dialog>
    </div>


  );
}