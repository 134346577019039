import { Autocomplete, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Colors, StyledAddIcon, StyledClearIcon, StyledClosedIcon, Text, TextBubble } from '../styled/styled.container';
import { TemplateFormButtonStack, TemplateFormFieldRow } from '../styled/styled.templateform';
import { StyledNotificationTriggerContainer, StyledNotificationTriggerItem, StyledNotificationTriggerItemTextContainer } from '../styled/styled.scheduler';
import moment from 'moment';
import { v4 as uuid } from "uuid";

function ScheduleNotificationDialog({ open, setOpen, users, notificationList, setNotificationList }) {


    const [newNotification, setNewNotification] = useState({
        user: '',
        interval: 1,
        period: 'Hours',
        type: "Before",
        ifMissed: false,
    });

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [barMessage, setBarMessage] = useState("")

    const handleClose = () => {
        setOpen(false);
    };

    function handleAddNewTriggerItem() {

        if (notificationList.length > 2) {
            setBarMessage("You can only add a maximum of 3 notifications per visit.")
            setShowSnackbar(true)
            return
        }

        if (newNotification.user === "" || newNotification.user === undefined || newNotification.period === "" ||
            newNotification.interval === "" || newNotification.type === "") {
            setBarMessage("Please fill all the fields before adding a notification.")
            setShowSnackbar(true)
            return
        }

        if (!parseInt(newNotification.interval)) {
            setBarMessage("Please enter a valid interval.")
            setShowSnackbar(true)
            return
        }

        if (parseInt(newNotification.interval) < 0 || parseInt(newNotification.interval) > 7) {
            setBarMessage("Please enter an interval between 1-7.")
            setShowSnackbar(true)
            return
        }

        const notificationAlreadyExists = notificationList.find(notification => notification.user === newNotification.user && notification.interval === newNotification.interval && notification.period === newNotification.period && notification.type === newNotification.type && notification.ifMissed === newNotification.ifMissed)
        if (notificationAlreadyExists) {
            setBarMessage("This notification already exists.")
            setShowSnackbar(true)
            return
        }

        const randomId = uuid()

        const newNotificationInterval = newNotification.type === "Before" ? -newNotification.interval : newNotification.interval

        const nextRunDate = moment().add(newNotificationInterval, newNotification.period.toLowerCase()).format("YYYY-MM-DD HH:mm:ss")
        //console.log(nextRunDate)

        const updatedNewNotification = {
            ...newNotification,
            id: randomId,
            createdAt: moment().format(),
            ifMissed: newNotification.type === "Before" ? false : newNotification.ifMissed,
            status: "Pending",
            interval: parseInt(newNotification.interval),
            nextRunDate: nextRunDate
        }

        console.log(updatedNewNotification)

        setNotificationList(prevState => [...prevState, updatedNewNotification])
    }

    const handleUserChangeNotification = (event, value) => {
        // Set the selected user's UID
        setNewNotification({ ...newNotification, user: value.uid });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;


        if (name === "ifMissed") {

            setNewNotification({ ...newNotification, [name]: event.target.checked });
        }
        else setNewNotification({ ...newNotification, [name]: value });

        if (name === "type") {
            setNewNotification({
                ...newNotification,
                ifMissed: false,
                [name]: value
            })
        }
    };

    function handleRemoveNotificationItem(id) {
        setNotificationList(prevState => prevState.filter(item => item.id !== id))
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                message={barMessage}
                key={'bottomcenter'} />

            <Dialog
                open={open}
                fullWidth
                sx={{ padding: '20px 5px' }}
                onClose={handleClose}>

                <DialogTitle>
                    <TemplateFormFieldRow style={{ marginBottom: '0px' }} align="space-around">
                        <Text fontSize={"1rem"} fontWeight={600}>Schedule Notification Triggers</Text>
                        <IconButton onClick={handleClose}>
                            
                            <StyledClosedIcon sx={{ fontSize: 50 }} />
                        </IconButton>

                    </TemplateFormFieldRow>
                </DialogTitle>
                <DialogContent>

                    <div style={{ display: "flex", flexDirection: "column", marginTop: '10px' }}>
                        <TemplateFormFieldRow style={{ marginBottom: '15px' }}>
                            <Autocomplete
                                id={`user-list`}
                                options={users}
                                fullWidth
                                size="small"
                                onChange={(_, newValue) => handleUserChangeNotification(_, newValue)}
                                groupBy={(option) => option.type}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Notification User"
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'text',
                                        }}
                                    />
                                )}
                            />

                        </TemplateFormFieldRow>

                        <TemplateFormFieldRow style={{ marginBottom: '10px' }}>
                            <TextField
                                name="interval"
                                label="Interval"
                                size='small'
                                type="number"
                                value={newNotification.interval}
                                sx={{ flex: 1, marginRight: '10px' }}
                                inputProps={{ min: 0, max: 7 }}

                                onChange={handleInputChange}
                            />
                            <Select
                                name="period"
                                label="Period"
                                size="small"
                                sx={{ flex: 2 }}
                                value={newNotification.period}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Hours">Hours</MenuItem>
                                <MenuItem value="Days">Days</MenuItem>
                            </Select>
                            <Select
                                name="type"
                                sx={{ marginLeft: '10px', flex: 2 }}
                                label="Type"
                                size="small"
                                value={newNotification.type}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Before">Before</MenuItem>
                                <MenuItem value="After">After</MenuItem>
                            </Select>

                        </TemplateFormFieldRow>
                        <FormControlLabel
                            label="Notify only if visit is missed"

                            control={
                                <Checkbox
                                    name="ifMissed"
                                    disabled={newNotification.type === "Before"}
                                    value={newNotification.ifMissed}
                                    onChange={handleInputChange}
                                />
                            } />
                        <TemplateFormButtonStack style={{ marginTop: '5px' }}>


                            <Button onClick={handleAddNewTriggerItem} color={Colors.dark_grey_highlight}>
                                <StyledAddIcon style={{ marginRight: '10px' }} /> Add Notification
                            </Button>
                        </TemplateFormButtonStack>
                    </div>

                    <StyledNotificationTriggerContainer>
                        {notificationList.map((notification, index) => {

                            const user = users.find(user => user.uid === notification.user)
                            let userName = "Not Found"
                            let userEmail = "Not Found"
                            if (user) {
                                userName = users.find(user => user.uid === notification.user)?.name
                                userEmail = users.find(user => user.uid === notification.user)?.email
                            }

                            return <StyledNotificationTriggerItem key={index}>
                                <StyledNotificationTriggerItemTextContainer>
                                    <Text style={{ padding: '0px 5px', marginBottom: '5px' }} fontSize={"0.8rem"} fontWeight={500}>{userName} ({userEmail})</Text>
                                    <TextBubble backgroundColor={notification.ifMissed ? `${Colors.highlight}` : `${Colors.blue}`} color={Colors.card_background} fontSize={"0.7rem"} fontWeight={500}>{notification.interval} {notification.period} {notification.type} {notification.ifMissed ? "(Notify when missed)" : ""}</TextBubble>


                                </StyledNotificationTriggerItemTextContainer>
                                <IconButton onClick={() => handleRemoveNotificationItem(notification.id)}>
                                    <StyledClearIcon />
                                </IconButton>

                            </StyledNotificationTriggerItem>

                        })}
                    </StyledNotificationTriggerContainer>


                </DialogContent>
                

            </Dialog>
        </>

    )
}

export default ScheduleNotificationDialog