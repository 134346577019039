import { useContext, useEffect, useState, useRef } from "react";
import { useDebounce } from "./useDebounce";

import { InputAdornment, TextField } from "@mui/material";
import { StyledTextField } from "./styled/styled.container";


export const InputFieldCount = ({field, myVal, type, InputProps, inputProps, 
  padding, margin, error, sx, variant, updateHandler, helperText, placeholder, 
  align, colour, size, weight, marginTop, lineHeight, letterSpacing, allowEditVisit, textTransform, 
  position, section_item_id, i, countIndex, onFocus, label}) => {
    const [value, setValue] = useState(myVal || "");

  useEffect(() => {
    
    if(myVal) { 
      setValue(myVal)
    }
    else setValue("")
  }, [])

  

  const debouncedRequest = useDebounce((value) => {
    
    
    if(section_item_id && i !== undefined)
    {
      
      updateHandler(section_item_id, i, countIndex, value)
    } else if(section_item_id)
    {
      
      updateHandler(section_item_id, value)
    } else  
      updateHandler(value)
    //setProfileInfo(prevState => ({...prevState, [field]: value}))
  })

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);

    
    debouncedRequest(value);
  }


    return <StyledTextField 
    
    size={size || "medium"}
    fullWidth
    
    label={label || ""}
    variant={variant}
    sx={sx || {'position': position || "relative", 'margin': {margin} || "0px", padding: {padding} || "5px 0px"}}
    placeholder={`${placeholder}`}
    value={type === "number" ? (value || 0) : value || "0"}
    error={error}
    
    onFocus={onFocus}
    helperText={helperText}
    onChange={onChange}
    InputProps={InputProps}
    inputProps={inputProps}
    />
}

