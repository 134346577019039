import styled from "styled-components";
import { Colors } from "./styled.container";

export const MapContainer = styled.div`
    width: 100%;
    height: 200px;
    margin-top: 0px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`