import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { HeaderSubTitle, HeaderTitle, Button, StyledCopyIcon, Colors, AddOptionButton, StyledAddOptionIcon, PageHolder, StyledUpdateIcon, StyledTemplateIcon, StyledDescriptionIcon, HeaderHolder } from './styled/styled.container'
import { TemplateFormFieldContainer, Input, TemplateFormButtonHolder, TemplateFormFieldNewFieldButton, TemplateFormFieldNewField, TemplateFormField, TemplateFormFieldText, TemplateFormFieldType, TemplateFormFieldRow, TemplateFormFieldAssignable, TemplateFormFieldNumber, TemplateFormFieldLabel, TemplateFormFieldButton, TemplateFormTitle, TemplateFormEmptyContainer, TemplateFormEmptyContainerText, TemplateFormErrorContainer, TemplateFormErrorContainerText, TemplateFormFieldAllowComment, TemplateSection, TemplateFormButtonContainer, TemplateTimerContainer, TemplateFormFieldFilters, TemplateDescription } from './styled/styled.templateform';
import { StyledClosedIcon, PageContainer, EmptyContainer, EmptyContainerText } from './styled/styled.container';
import { auth, db } from "./firebase";
import { collection, onSnapshot, query, doc, setDoc, orderBy, deleteDoc, addDoc, getDoc, updateDoc, getDocs, where, arrayUnion } from "firebase/firestore";
import { FieldTypes, TemplateTypes } from './styled/styled.container';
import { TextField, InputAdornment, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, IconButton } from '@mui/material';
import AlertDialog from "./AlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import Loader from './Loader';
import { TemplateSectionHeader } from './styled/styled.templateform';
import { StyledAddIcon, StyledDeleteIcon, StyledUpArrowIcon, StyledDownArrowIcon } from './styled/styled.container';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InventoryIcon from '@mui/icons-material/Inventory';
import ErrorDialog from './ErrorDialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Header from "./Header"
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
//import { UserTypes } from './styled/styled.container';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import InfoDialog from './InfoDialog';
import { useTheme } from '@material-ui/core/styles';
import SectionTemplate from './SectionTemplate';
import { useContext } from "react";
import AuthContext from "./AuthContext";
import { InputField } from './InputField';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    section: {
        "& .MuiFilledInput-root": {
            backgroundColor: "rgb(232, 241, 250)"
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "rgb(250, 232, 241)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: "rgb(232, 241, 250)"
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "rgb(250, 241, 232)"
        }
    }
}));




function TemplateLibraryForm(props) {

    const classes = useStyles();

    const [sections, setSections] = useState([]);
    const [templateID, setTemplateID] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const [selectedGroups, setSelectedGroups] = useState([]); // group selection
    const [selectedUsers, setSelectedUsers] = useState([]) // selected users based on group selection
    const [selectedTemplateCategory, setSelectedTemplateCategory] = useState("")
    const [savedUsersEmail, setSavedUsersEmail] = useState(); // current saved email user uids
    const [timedTemplate, setTimedTemplate] = useState(false);
    const [defaultStatusClosed, setDefaultStatusClosed] = useState(true);
    const [requireSignature, setRequireSignatue] = useState(false)
    const [selectedTemplateType, setSelectedTemplateType] = useState("Audit");
    const [emailStore, setEmailStore] = useState(false)
    const [locationRequired, setLocationRequired] = useState(false)
    const [timerValue, setTimerValue] = useState(30);
    const [templateHeader, setTemplateHeader] = useState("Download this template");
    const [templateSubTitle, setTemplateSubtitle] = useState("View this template and downloaad it to your organisation.")
    const [errorMessage, setErrorMessage] = useState("");
    const [errorContent, setErrorContent] = useState({});
    const [showError, setShowError] = useState(false);
    const [showFieldError, setShowFieldError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    const [showInfoDialog, setShowInfoDialog] = useState(false)
    const [dialogContents, setDialogContents] = useState({
        title: '',
        contents: '',
        extra_content: ''
    })
    const { storeDetails, setStoreDetails } = useContext(AuthContext)
    const [availableGroups, setAvailableGroups] = useState(useState(storeDetails.user_groups))
    // const [availableGroups, setAvailableGroups] = useState(() => {
    //     return(
    //         UserTypes.filter(users => users !== "admin")
    //     )
    // })
    const [userList, setUserList] = useState([])
    const [availableUsers, setAvailableUsers] = useState([])
    const [newUsers, setNewUsers] = useState([])

    const [showDeleteSectionDialog, setShowDeleteSectionDialog] = useState(false);
    const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [selectedSection, setSelectedSection] = useState();
    const [openBar, setOpenBar] = React.useState(false);
    const [barMessage, setBarMessage] = useState({})
    const theme = useTheme();

    const params = useParams();
    const navigate = useNavigate();
    let templateQueryCollectionRef = "";
    let templateCollectionRef = "";
    let templateRootRef = "";

    
    useEffect(() => {
        
   
        async function getUserList() {
            // const userRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("active", "==", true))
            // const userDocs = await getDocs(userRef);
            // const userList = await Promise.all(userDocs.docs.map(user => {
            //     return new Promise((resolve) => {
            //         const userData = user.data();
            //         resolve(userData)
            //     })
            // }))
            const user_list = [...storeDetails.all_users].map(user => {
                return {...user, group_name: storeDetails.user_groups.find(group => group.id === user.group).name}
            })

            

            const sortedUsers = [...user_list].sort((a, b) => a.type.localeCompare(b.type));

            setUserList(sortedUsers);
        }

        if(storeDetails.rootID.length > 0) {
            //console.log(storeDetails.permissions)
            if(!storeDetails.permissions.templates.edit && !storeDetails.permissions.templates.add) {
                navigate("/app/templates");
            }
            else getUserList();
        }
        
    }, [storeDetails])

    useEffect(() => {

        async function getSections() {
            setSections([])

            const template_id = params.id;
            if (template_id) {

                setShowLoading(true)

                setTemplateHeader("Download this template")
                setTemplateSubtitle("View this template and download it to your organisation")
                setTemplateID(template_id);

                templateRootRef = doc(db, "stores/storecall/templates/" + template_id);

                getDoc(templateRootRef).then(result => {
                    if (result.exists())
                        setTemplateName(result.get("name"));
                        setTemplateDescription(result.get("description") || "")
                        const groups = result.get("permission_users");
                        //console.log("Groups Template", groups)
                        
                        const users = result.get("email_users");
                        const email_store = result.get("email_store");
                        const template_type = result.get("template_type");
                        const default_status_closed = result.get("default_status_closed");
                        const location_required = result.get("location_required")
                        const signature_required = result.get("signature_required")

                        if(signature_required !== undefined) setRequireSignatue(signature_required)

                        if(location_required !== undefined) setLocationRequired(location_required)
                        

                        if(template_type) setSelectedTemplateType(template_type)
                        if(default_status_closed) setDefaultStatusClosed(true)

                        if(email_store) setEmailStore(email_store)
                        else setEmailStore(false)

                        if(users) {
                            setSavedUsersEmail(users)
                        }

                        const templateCategory = result.get("category");
                        const templateCategoryElement = storeDetails.template_categories.find(category => category.cloud_id === templateCategory);
                        
                        setSelectedTemplateCategory(storeDetails.template_categories[0]?.cloud_id);

                        setSelectedGroups(storeDetails.user_groups.map(group => group.id))
                        // above is to avoid duplicate admins being added to list
                        // when admin exists already
                        const isTimed = result.get("timer");
                        if(isTimed) {
                            setTimedTemplate(isTimed);
                            setTimerValue(result.get("timer_mins"));
                        }
                })

                templateQueryCollectionRef = query(collection(db, "stores/storecall/templates/" + template_id + "/sections"), orderBy("key"));
                templateCollectionRef = collection(db, "stores/storecall/templates/" + template_id + "/sections");

                const templateDocs = await getDocs(templateQueryCollectionRef);
                const sectionItems = await Promise.all(templateDocs.docs.map(item => {
                        return new Promise((resolve) => {
                            const sectionID = item.id;
                            const sectionData = item.data();

                            // to allow for updates on the model list item keys
                            // this will allow for future updates
                            // ammend the fieldObject and it will append the keys that do not exist
                            const listCheckAmend = sectionData.list.map(element => {
                                return checkUpdateListItemModel(element)
                            })

                            const sectionItem = {
                                //template_id: templateID,
                                section_id: sectionID,
                                name: sectionData.name,
                                list: listCheckAmend,
                                uploaded: true
                            }

                            resolve(sectionItem)
                        })
                        
                }))

                setSections(sectionItems);
                setShowLoading(false)
            }
        }

        if(storeDetails.rootID.length > 0) getSections();

    }, [params.id])

    useEffect(() => {

        async function getAvailableUsers() {

            if(userList.length > 0) {

                let filteredGroups = [...availableGroups];
                //console.log(filteredGroups);
                // const checkAdminInGroup = availableGroups.filter(group => group === "admin").length > 0;
                // if(!checkAdminInGroup) filteredGroups.push("admin");

                // show all available users except the manager list
                // can only email the store directly with separate checkbox
                
                // let availableUserList = userList.filter(user => {
                //     return filteredGroups.indexOf(user.type) > -1 && user.type !== "manager"
                // })
                //console.log("Selected groups", selectedGroups);
                let availableUserList = [...userList].filter(user => {
                    if(user.type === "admin") return true;
                    else return selectedGroups.some(group => group === user.group)
                })
                //console.log(availableUserList)
                // if there is a saved list of emails already
                if(savedUsersEmail && savedUsersEmail.length > 0) {

                    // go through the saved emails and check if email belongs to available list of users
                    const usersAdd = savedUsersEmail.map(savedUser => {
                       
                        if(availableUserList.filter(availableUser => availableUser.uid === savedUser).length > 0) {
                            
                            //const existingUser = availableUserList.filter(user => user.email === savedUser)[0]
                            //console.log(existingUser)
                            const exisiting = availableUserList.filter(user => user.uid === savedUser)[0];
                            
                            return exisiting;
                        } else {
                            
                            // email does not belong to available users but user added as 3rd party to email
                            const newUser = {
                                uid: savedUser,
                                name: savedUser,
                                email: savedUser,
                                type: "New"
                            }
                            return newUser
                        }
                    })


                    setSelectedUsers(usersAdd)
                    
                }

                setAvailableUsers(availableUserList);
            }
        }
        
        getAvailableUsers();

    }, [availableGroups, userList, savedUsersEmail, selectedGroups])

    useEffect(() => {

            if(selectedUsers.length > 0)
            {
                const updatedSelectedUsers = selectedUsers.filter(user => (selectedGroups.indexOf(user.type).length > -1) || user.admin);
                
                setSelectedUsers(updatedSelectedUsers)
                //setSelectedUsers(prevState => prevState.filter(user => (selectedGroups.indexOf(user.type).length > -1) || user.type === "admin"))
            }
        

    }, [selectedGroups])

    const assignableTypes = [
        "Yes", "No"
    ]

    const fieldObject = {
        section_item_id: 0,
        name: "",
        type: "Comment",
        allowComments: true,
        filesAllowed: true,
        actionAllowed: true,
        editAllowed: false,
        additionalOptions: true,
        listCheckboxComments: false,
        scorable: true,
        options: [],
        counts: [],
        item_list_comment: [],
        item_list_checkbox: []
    }

    function checkUpdateListItemModel(list) {
        for (const key in fieldObject) {
          if (!list.hasOwnProperty(key)) {
            list[key] = fieldObject[key]
          }
        }
        return list
    }

    const styles = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: 400,
        marginTop: 5,
        marginBottom: 5,
      
      };

      const handleSelectedUserGroupChange = (event, value) => {
        setSelectedGroups(value.map((option) => option.id));
      };

      const handleSelectedCategoryChange = (event) => {
        console.log("Changed", event.target.value)
        setSelectedTemplateCategory(event.target.value);
      };
    
      const handleUpdateSection = useCallback((sectionProp) => {
        const sectionIndex = sections.findIndex(section => section.section_id === sectionProp.section_id);
        setSections(prevSections => {
          const updatedSections = [...prevSections];
          updatedSections[sectionIndex] = sectionProp;
          return updatedSections;
        });
      }, [sections]);

    const handleClick = () => {
        setOpenBar(true);
      };
    
      const handleClose = (event, reason) => {
        
        setOpenBar(false);
        setShowLoading(false)
        if(barMessage.result === "success") navigate("/app/templates")
      };

    const handleGroupChange = (event) => {
        const {
          target: { value },
        } = event;

        setSelectedGroups(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );

      };
    
      const handleUserChange = (event, values) => {
        const lastElement = values[values.length-1]

        if(values === '') return;

        if(typeof lastElement === 'string') {
            if(isValidEmail(lastElement)) {

                // if the manually entered email already exists don't enter
                if(selectedUsers.filter(user => user.email === lastElement).length > 0) {
                    return;

                }

                const newUser = {
                    uid: lastElement,
                    name: lastElement,
                    email: lastElement,
                    type: "New"
                }
                setSelectedUsers([...selectedUsers, newUser])
                
                if(availableUsers.filter(user => user.email === lastElement).length === 0) {
                    setDialogContents({
                        title: 'Email User',
                        contents: `${lastElement} does not belong to the user groups that you have selected, and will not be able to access this template on Storecall.`,
                        extra_content: 'If this was intended, you may continue with only sending an email to the new user.'
                    })
                    setShowInfoDialog(true)
                }

            }
        }

        else setSelectedUsers(values);
      };

    const handleTimerSliderChange = (event, newValue) => {
        if(newValue < 1) setTimerValue(1);
        else setTimerValue(newValue);
    };

    const handleTimerInputChange = (event) => {
        setTimerValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleTimerCheck = () => {
        if (timerValue < 1) {
            setTimerValue(1);
        } else if (timerValue > 122) {
            setTimerValue(120);
        }
    };

    function addNewSection() {
        setSections(prevState => ([...prevState, {
            section_id: (Math.floor(Date.now() + Math.random()) + ''),
            name: "",
            list: [],
            uploaded: false
        }]))
    }

    function duplicateSection(section, i) {

        const new_section = {
            ...section,
            uploaded: false,
            section_id: (Math.floor(Date.now() + Math.random()) + '')
        };

        const updatedSections = sections.slice(0);
        updatedSections.splice(i, 0, new_section);

        setSections(updatedSections);
    }

    

    const handleMoveSectionUp = useCallback((section_id) => {
        const currentIndex = sections.findIndex(section => section.section_id === section_id)
        if(currentIndex === 0) return;

        const newSections = [...sections];
        //console.log(currentIndex)

        const section = newSections.splice(currentIndex, 1)[0];
        newSections.splice(currentIndex-1, 0, section);
        //console.log(newSections)        
        setSections(newSections)
    }, [sections])

    function handleMoveSectionDown(section_id) {
        const currentIndex = sections.findIndex(section => section.section_id === section_id)

        if(currentIndex === sections.length-1) return;

        const newSections = [...sections];

        const section = newSections.splice(currentIndex, 1)[0];
        newSections.splice(currentIndex+1, 0, section);
                
        setSections(newSections)

    }

    function handleDeleteTemplateDialog() {
        setShowDeleteTemplateDialog(true);
    }

    function handleDeleteSectionDialog(section_id) {
        setSelectedSection(section_id);
        setShowDeleteSectionDialog(true);
    }

    function deleteSection(section_id) {
        setShowDeleteSectionDialog(false);

        if (templateID.length > 0) {
            const sectionItemRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections/" + section_id);

            deleteDoc(sectionItemRef).then(result => {

            })
        }

        setSections(prevState => (prevState.filter(section => section.section_id !== section_id)));
    }

    function handleCloseDeleteTemplateDialog() {
        setShowDeleteTemplateDialog(false)
    }

    async function getIDToken() {
        const token = await auth.currentUser.getIdToken(true)
        return token;
      }

    async function deleteTemplate() {

        setShowDeleteTemplateDialog(false)
        setShowLoading(true);

        const idToken = await getIDToken();

        const result = await fetch('https://us-central1-storecall.cloudfunctions.net/api/delete-template', {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          idToken: idToken,
          templateid: templateID,
          storeid: storeDetails.rootID
        })
        })

        if(result.status === 200) {
            
            setBarMessage({
                type: "info",
                message: "Template has been successfully deleted.",
                result: "success"
            })
            setOpenBar(true)
            //setSections([]);
            //setTemplateID("");
        } 
        else if(result.status === 300) {
            setShowLoading(false)
            setShowDeleteTemplateDialog(false)
                setBarMessage({
                    type: "error",
                    message: "You cannot delete a template with store visits linked to it."
                })
                setOpenBar(true)
        }
        else {
            setShowLoading(false)
            setShowDeleteTemplateDialog(false)
                setBarMessage({
                    type: "error",
                    message: "There was an error deleting this template."
                })
                setOpenBar(true)
        }

        setShowLoading(false)
    }

    function checkTemplateFieldsFilled() {
        let bCheck = true;

        if(templateName.length === 0 || selectedGroups.length === 0 || selectedTemplateCategory.length === 0 || templateDescription.length === 0) {
            bCheck = false;
        }
        
        if(bCheck) {
            sections.forEach(element => {

                if (element.name.length == 0) {
                    
                    bCheck = false;
                } 
                
                element.list.forEach(item => {
                    if (item.name.length === 0) bCheck = false;
                })
            })
        }
        
        if(!bCheck) setShowFieldError(true)

        return bCheck;
    }

    function isTemplateScorable() {
        let bCheck = false;
        sections.forEach(section => {
            bCheck = section.list.filter(listItem => listItem.scorable).length > 0;
        })

        return bCheck;
    }

    async function handleCreateTemplate() {


        if (!checkTemplateFieldsFilled()) {
          
            setBarMessage({
                type: "error",
                message: "Please check that all fields have been filled before saving.",
            })
            setOpenBar(true)
            return;
        }

        setShowLoading(true);
        createTemplate().then((result) => {

            if(result) {
                setBarMessage({
                    type: "info",
                    message: "Template has been successfully created.",
                    result: "success"
                })
                setOpenBar(true)
            }
            else {
                setBarMessage({
                    type: "error",
                    message: "There was an error creating your template.",
                
                })
                setOpenBar(true)
            }

            setShowLoading(false);
            
        }).catch(err => {
            setShowLoading(false);
        })
    }

    async function handleSaveTemplate() {
        //console.log("Updating template")
        if (!checkTemplateFieldsFilled()) {
          
            setBarMessage({
                type: "error",
                message: "Please check that all fields have been filled before saving.",
            })
            setOpenBar(true)
            return;
        }

        setShowLoading(true);

        saveTemplate().then((result) => {
            if(result) {
                setBarMessage({
                    type: "info",
                    message: "Template has been successfully updated.",
                    result: "success"
                })
                setOpenBar(true)
            }
            else {
                setBarMessage({
                    type: "error",
                    message: "There was an error updating your template.",
                    
                })
                setOpenBar(true)
            }

            setShowLoading(false)
            
        }).catch(err => {
            setShowLoading(false);
        })
    }

    async function createTemplate() {

        try {
            const templateScorable = isTemplateScorable();

            const templateCollectionRef = collection(db, `stores/${storeDetails.rootID}/templates`);
            const tempRef = doc(templateCollectionRef);

            
        // add the template
        await setDoc(tempRef, {
            name: templateName,
            description: templateDescription,
            scorable: templateScorable,
            template_type: selectedTemplateType,
            category: selectedTemplateCategory,
            default_status_closed: defaultStatusClosed,
            timer: timedTemplate,
            store_created: false,
            master_template_id: params.id,
            timer_mins: timerValue,
            location_required: locationRequired,
            signature_required: requireSignature,
            permission_users: selectedGroups,
            email_users: selectedUsers.map(user => user.uid),
            email_store: emailStore
        }, {merge: true});

        const templateID = tempRef.id;

        //await createTemplatePermissions(tempRef.id);

        // add template name
        //await setDoc(doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID), { name: templateName });

        sections.forEach(async (element, i) => {
            const sectionRef = doc(collection(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections"));
            const idElement = {
                ...element,
                section_id: sectionRef.id,
                key: i
            };

            await setDoc(doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections", sectionRef.id), idElement, { merge: true });
        })

        // if(storeDetails.user.specific_templates) {
            
        //     await setDoc(doc(db, "stores/" + storeDetails.rootID + "/users/" + storeDetails.user.key), { specific_template_list: arrayUnion(templateID)  }, {merge: true})
        //     setStoreDetails({...storeDetails, user: {...storeDetails.user, specific_template_list: [...storeDetails.user.specific_template_list, templateID]}})
        // }

        setTemplateID(templateID);
       // console.log("Template created successfully " + templateID)
        return true;
        }catch(err) {
            console.log(err.message)
            return false;
        }
        
    }

    // async function createTemplatePermissions(template_id) {
    //     const permissionUsers = ["admin", ...selectedUsers];
    //     const q = doc(db, "stores/" + storeDetails.rootID + "/template_permissions/" + template_id);
    //     await addDoc(collection(db, "stores/" + storeDetails.rootID + "/templates"), {
    //         permission_users: selectedUsers
    //     });

    // }

    // async function updateTemplatePermissions() {
    //     const permissionUsers = ["admin", ...selectedUsers];
    //     const q = doc(db, "stores/" + storeDetails.rootID + "/template_permissions/" + templateID);
    //     await updateDoc(q, {
    //         users: permissionUsers
    //     });
    //     console.log("Template permissions updated");
    // }

    async function saveTemplate() {

        const templateScorable = isTemplateScorable();
      
        const q = doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID);
        
        await updateDoc(q, { 
          name: templateName, 
          description: templateDescription,
          scorable: templateScorable,
          category: selectedTemplateCategory,
          template_type: selectedTemplateType,
          default_status_closed: defaultStatusClosed,
          signature_required: requireSignature,
          timer: timedTemplate,
          store_created: true,
          master_template_id: q.id,
          timer_mins: timerValue,
          location_required: locationRequired,
          permission_users: selectedGroups,
          email_users: selectedUsers.map(user => user.uid),
          email_store: emailStore
        });
        
      
        const modTempSections = [...sections];
        const promises = []; // create an array to hold the promises
      
        for (const [i, section] of modTempSections.entries()) {
          if (section.uploaded) {
            
            const sectionRef = doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections", section.section_id);
            const idElement = {...section, key: i };
            promises.push(setDoc(sectionRef, idElement, { merge: true }));
          } else {
            //console.log("not uploaded")
            const sectionRef = doc(collection(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections"));
            const idElement = {...section, section_id: sectionRef.id, key: i };
            promises.push(setDoc(doc(db, "stores/" + storeDetails.rootID + "/templates/" + templateID + "/sections", sectionRef.id), idElement, { merge: true }));
          }
        }
      
        await Promise.all(promises); // wait for all promises to complete
        return true;
      }

    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return emailRegex.test(email);
      };

    //   const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //       const value = event.target.value;
    //       if (!availableUsers.map(user => user.email).includes(value) && isValidEmail(value) && value !== '') {
    //         console.log("Adding " + value)
    //         const newUser = {
    //             uid: value,
    //             name: value,
    //             email: value,
    //             type: "New"
    //         }
    //           //setNewUsers([...newUsers, newUser]);
    //           //setSelectedUsers([...selectedUsers, newUser])
    //       }
    //     }
    //   };

    return (
        <PageHolder>
            <Header>
        <HeaderHolder>
        {templateHeader}
        
        <TemplateDescription>{templateSubTitle}</TemplateDescription>
        </HeaderHolder>
        
        </Header>

            <Loader show={showLoading} />

            <InputField
            error={showFieldError && templateName.length === 0}
            helperText={showFieldError && templateName.length === 0 ? 'Enter a template name' : ''}
            
            placeholder='Enter a new template name' 

            InputProps={{
                style: {
                  borderRadius: '10px'
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledTemplateIcon color={Colors.blue} />
                    
                  </InputAdornment>
            )}}
            inputProps={{
                style: {
                    margin: '0px', 
                    padding: '10px 0px',
                    fontFamily: `Poppins`,
                    lineHeight: 1.5,
  
                    fontSize: `0.8rem`,
                    
                },
            }}
            fullWidth 
            variant="outlined"
            myVal={templateName}
            updateHandler={setTemplateName} 
             />


            {/* <TextField 
            size="small"
            error={showFieldError && templateName.length === 0}
            helperText={showFieldError && templateName.length === 0 ? 'Enter a template name' : ''}
            variant="outlined" 
            placeholder='Enter a new template name' 
            InputLabelProps={{ shrink: true }}
            inputProps={{
                style: {
                    color: "black", 
                    fontFamily: "Poppins",
                    fontWeight: '500',

                    fontSize: '0.9rem',
                    backgroundColor: `${Colors.white}`,
                }
            }}
            InputProps={{
                  style: {
                    borderRadius: '10px'
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledTemplateIcon color={Colors.blue} />
                      
                    </InputAdornment>
                  )}} 
            value={templateName} 
            onChange={e => setTemplateName(e.target.value)} /> */}
            <InputField
            error={showFieldError && templateDescription.length === 0}
            helperText={showFieldError && templateDescription.length === 0 ? 'Enter a template description' : ''} 
            margin={'10px 0px'}
            placeholder='Enter a description for the template' 
            inputProps={{
                style: {
                    margin: '0px', 
                    padding: '10px 0px',
                    fontFamily: `Poppins`,
                    lineHeight: 1.5,
                    
                    fontSize: `0.8rem`,
                    
                },
            }}
            InputProps={{
                style: 
                { 
                color: "black", 

                fontWeight: '400', 
                fontSize: '0.9rem', 
                borderRadius: '10px' },
                
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledDescriptionIcon />
                      
                      
                    </InputAdornment>
                  )}}
            fullWidth 
            variant="outlined"
            myVal={templateDescription || ""}
            updateHandler={setTemplateDescription} 
             />

            {/* <TextField 
            sx={{'marginTop': '10px', 'marginBottom': '10px'}} 
            size="small" 
            multiline
            variant="outlined" 
            placeholder='Enter a description for the template'
            error={showFieldError && templateDescription.length === 0}
            helperText={showFieldError && templateDescription.length === 0 ? 'Enter a template description' : ''} 
            InputLabelProps={{ shrink: true }} 
            inputProps={{
                style: {
                    color: "black", 
                    fontFamily: "Poppins",
                    backgroundColor: `${Colors.white}`, 
                    fontWeight: '500', 
                    fontSize: '0.9rem', 
                }
            }}
            InputProps={{
                style: 
                { 
                color: "black", 
                backgroundColor: `${Colors.white}`, 
                fontWeight: '400', 
                fontSize: '0.9rem', 
                borderRadius: '10px' },
                
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledDescriptionIcon />
                      
                      
                    </InputAdornment>
                  )}}
            value={templateDescription || ""} 
            onChange={e => setTemplateDescription(e.target.value)} /> */}

                <TextField
                id="template-category"
                select
                size='small'
                sx={{'marginTop': '5px', 'marginBottom': '15px'}}
                inputProps={{
                    style: {
                        fontFamily: "Poppins",
                        
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={() => navigate("/app/templates/categories")} style={{'marginRight': '10px'}}>
                            <StyledAddIcon  />
                        </IconButton>
                    ),
                    style: { 
                        color: `${Colors.dark_grey}`, 
                        fontWeight: '400',
                        
                        fontSize: '0.9rem',
                        fontFamily: "Poppins",
                        borderRadius: '10px',
                        backgroundColor: `${Colors.white}`
                          
                    } 
                }}
                fullWidth
                error={showFieldError && selectedTemplateCategory.length === 0}
                helperText={"Place your template into a category for a better structure."}
                label="Template Category"
                
                value={selectedTemplateCategory}
                onChange={handleSelectedCategoryChange}>
                    {storeDetails.template_categories.map((category, i) => {
                        return <MenuItem key={category.cloud_id} style={{display: 'flex', alignItems: 'center'}} value={category.cloud_id}>{category.name}</MenuItem>
                    })}
            </TextField>

            <TextField
                id="field-type"
                select
                size='small'
                sx={{'marginTop': '10px', 'marginBottom': '10px'}}
                inputProps={{
                    style: {
                        fontFamily: "Poppins",
                        
                    }
                }}
                InputProps={{
                    style: { 
                        color: `${Colors.dark_grey}`, 
                        fontWeight: '400',
                        padding: '5px 0px', 
                        fontSize: '0.9rem',
                        fontFamily: "Poppins",
                        borderRadius: '10px',
                        backgroundColor: `${Colors.white}`
                          
                    } 
                }}
                fullWidth
                label="Template Type"
                helperText="Audits help you to assess the performance of a location. Logs can be used for issue reporting."
                value={selectedTemplateType}
                onChange={(e) => setSelectedTemplateType(e.target.value)}>
                    {TemplateTypes.map((type, i) => {
                        return <MenuItem key={i} style={{display: 'flex', alignItems: 'center'}} value={type}>{type}</MenuItem>
                    })}
            </TextField>

            

            <PageContainer empty_shadow={true} style={{'padding': '0px', 'marginTop': '10px'}}>
            
                <AlertDialog
                    open={showDeleteSectionDialog} setOpen={setShowDeleteSectionDialog}
                    title="Delete Section"
                    content="Are you sure you want to remove this section and its contents?"
                    id={selectedSection}
                    action={deleteSection} />

                <AlertDialog
                    open={showDeleteTemplateDialog} setOpen={setShowDeleteTemplateDialog}
                    title="Delete Template"
                    content="Are you sure you want to delete this template and its contents?"
                    action={deleteTemplate} />

                <ErrorDialog
                    open={showErrorDialog} setOpen={setShowErrorDialog}
                    title={errorContent.title}
                    content={errorContent.content} />

                <InfoDialog
                    open={showInfoDialog}
                    setOpen={setShowInfoDialog}
                    title={dialogContents.title}
                    content={dialogContents.contents}
                    extra_content={dialogContents.extra_content} />

                <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
                    {barMessage.message}
                    </Alert>
                </Snackbar>

                
                

                <TemplateTimerContainer style={{'marginBottom': '10px'}}>
                    
                    <Typography>User groups allowed to use this template</Typography>
                    <FormControl sx={{marginTop: '5px'}}>
                    <Autocomplete
                    sx={{'margin': '5px 0px'}}
                    multiple
                    
                    fullWidth
                    id="manager-tags"
                    options={storeDetails.user_groups}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={selectedGroups.map((id) =>
                    storeDetails.user_groups.find((group) => group.id === id)
                    )}
                    isOptionEqualToValue={(option, value) =>
                    selectedGroups.includes(option.id)
                    }
                    onChange={handleSelectedUserGroupChange}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        size='small'
                        fullWidth
                        
                        error={showFieldError && selectedGroups.length === 0}
                        helperText={"Select the user groups that will have access to this template."}
                        placeholder="Add a user group"
                    />
                )} />
                    </FormControl>

                    <FormControl sx={{marginTop: '10px'}}>
                    <Typography>Users to email when visit is completed</Typography>
                    <Autocomplete
                        multiple
                        freeSolo
                        size='small'
                        sx={{marginTop: '5px'}}
                        id="user-tags"
                        options={availableUsers.concat(newUsers)}
                        
                        groupBy={(option) => option.group_name}
                        getOptionLabel={(option) => option.name}
                        value={selectedUsers}
                        
                        filterSelectedOptions
                        
                        onChange={handleUserChange}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select users to email"
                            helperText="Automatically email your team whenever a new visit is complete."
                        />
                        )} />

                        
                    </FormControl>
                  
                    <FormGroup sx={{marginTop: '10px'}}>
                        <FormControlLabel control={<Checkbox checked={emailStore} onChange={(e) => setEmailStore(prevState => !prevState)} />} 
                            label={
                                <>
                                <Typography >
                                    Send visit report to location when complete
                                </Typography>
                                
                                </>
                                
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox checked={locationRequired} onChange={(e) => setLocationRequired(prevState => !prevState)} />} 
                            label={
                                <Typography >
                                    Save user's geo location when visit complete
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox checked={defaultStatusClosed} onChange={(e) => setDefaultStatusClosed(e.target.checked)} />} 
                            label={
                                <Typography >
                                    Set default visit status to closed
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox checked={requireSignature} onChange={(e) => setRequireSignatue(e.target.checked)} />} 
                            label={
                                <Typography >
                                    Require a signature at the end of the visit
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox checked={timedTemplate} onChange={(e) => setTimedTemplate(e.target.checked)} />} 
                            label={
                                <Typography>
                                    Add a time limit to complete
                                </Typography>
                            } 
                        />
                    </FormGroup>

                    {timedTemplate &&
                    <TemplateFormFieldRow style={{'marginTop': '10px'}}>
                        <Box sx={{ width: '100%' }}>
 
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <AccessTimeFilledIcon />
                                </Grid>
                                <Grid item xs>
                                    <Slider
                                        value={typeof timerValue === 'number' ? timerValue : 30}
                                        onChange={handleTimerSliderChange}
                                        max={120}
                                        aria-labelledby="input-slider"
                                    />
                                </Grid>
                                <Grid item>
                                    <Input
                                        value={timerValue}
                                        size="small"
                                        onChange={handleTimerInputChange}
                                        onBlur={handleTimerCheck}
                                        inputProps={{
                                            step: 10,
                                            min: 1,
                                            max: 120,
                                            type: 'number',
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                    <Typography sx={{ fontSize: 14 }}>minute(s)</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </TemplateFormFieldRow>}
                </TemplateTimerContainer>

                <TemplateFormFieldContainer>
                    {sections && sections.map((section, i) => {

                        return (
                            <SectionTemplate key={section.section_id}
                            sectionProp={section}
                            showFieldError={showFieldError}
                            templateID={templateID}
                            handleMoveSectionUp={handleMoveSectionUp}
                            handleMoveSectionDown={handleMoveSectionDown}
                            updateSection={handleUpdateSection}
                            duplicateSection={duplicateSection}
                            handleDeleteSectionDialog={handleDeleteSectionDialog}
                            rootID={storeDetails.rootID}
                            />
                        )
                    })}

                    <TemplateFormFieldRow align="right" style={{'marginTop': '10px'}}>
                        <AddOptionButton style={{'marginRight': '10px'}}  onClick={addNewSection}><StyledAddOptionIcon fontSize='medium' style={{'marginRight': '10px'}} /> Add Template Section</AddOptionButton>
                    </TemplateFormFieldRow>


                    
                </TemplateFormFieldContainer>
                    
            </PageContainer>
            {templateID.length > 0 &&
                        <TemplateFormFieldRow align="right" style={{ 'marginTop': '10px' }}>
                            
                            {storeDetails.permissions.templates.add && <Button style={{'margin': '5px'}} color={Colors.green} fullWidth onClick={handleCreateTemplate}><StyledTemplateIcon /> Download Template</Button>}
                        </TemplateFormFieldRow>
                    }

            {showError &&
                <TemplateFormErrorContainer>
                    <TemplateFormErrorContainerText>{errorMessage}</TemplateFormErrorContainerText>
                    <Button onClick={() => setShowError(false)}>OK</Button>
                </TemplateFormErrorContainer>}
    </PageHolder>

    )
}

export default TemplateLibraryForm