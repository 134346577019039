import styled from "styled-components";
import { Colors } from "./styled.container";


export const StoreFormTitle = styled.input`
    color: ${Colors.blue};
    display: flex;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border: ${props => props.error===true ? "2px solid blue" : ""};
    border-radius: 10px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px 10px;
    font-size: 0.9rem;
`

export const TemplateFormFieldButton = styled.button`
    background-color: ${props => props.alert ? Colors.highlight : Colors.blue }; 
    padding: 10px;
    outline: none;
    border: none;
    width: fit-content;
    padding-left: 15px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    display: inline-block;
    margin-right: 10px;
    padding-right: 15px;
    font-weight: 600;
    font-size: 0.8rem;

    cursor: pointer;
    color: ${Colors.white};

    &:disabled {
        background-color: ${Colors.light_grey};
        color: ${Colors.disabled};
    }
`

export const StoreFormFieldContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 10px 0px;
    
`

export const StoreFormField = styled.div`

    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px 5px;
    padding: 20px 20px;
    border-radius: 10px;

`

export const StoreFormFieldNumber = styled.p`
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 0.8rem;
`

export const StoreFormFieldRow = styled.div`
    display: flex;
    margin-top: 10px 0px;
    margin-bottom: 5px;
    align-items: center;
    
    justify-content: ${props => props.align==="right" ? "flex-end" : "flex-start"};
`

export const StoreFormFieldLabel = styled.p`
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    font-weight: 600;
    margin-right: 5px;

`

export const StoreFormFieldText = styled.input`
    display: flex;
    flex: 1;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 10px;
    border: 0;
    outline: 0;
    margin-top: 15px;
    margin-left: 1px;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 10px 10px;
    font-size: 0.8rem;
    max-width: 1024px;
`

export const StoreFormFieldButton = styled.button`
    background-color: ${props => props.alert ? Colors.highlight : Colors.blue }; 
    padding: 10px 30px;
    outline: none;
    border-radius: 10px;
    border: none;
    
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-right: 10px;
    font-size: 0.9rem;

    cursor: pointer;
    color: ${Colors.white};

    &:disabled {
        background-color: ${Colors.light_grey};
        color: ${Colors.disabled};
    }
`

export const StoreFormErrorContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    background-color: ${Colors.light_grey};
    align-items: center;
    z-index: 2;
    width: 100%;
    height: 100%;
`

export const StoreFormErrorContainerText = styled.p`
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
    color: ${Colors.dark_grey};
    margin-bottom: 20px;
`
