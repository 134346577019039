import React, {useState, useEffect, useContext} from 'react'
import { Button, CardContainer, Colors } from './styled/styled.container'
import { GroupTitle, PermissionType } from './styled/styled.usergroups'
import { useNavigate, useParams } from 'react-router-dom'
import { Autocomplete, Checkbox, Chip, Divider, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { db } from './firebase';
import AuthContext from './AuthContext';
import Loader from './Loader';
import { TemplateFormButtonStack, TemplateFormFieldRow } from './styled/styled.templateform';
import DeleteReportDialog from './DeleteReportDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    section: {
        "& .MuiFilledInput-root": {
            backgroundColor: "rgb(232, 241, 250)"
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "rgb(250, 232, 241)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                backgroundColor: "rgb(232, 241, 250)"
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "rgb(250, 241, 232)"
        }
    }
}));


function SettingsUserGroupPermissions() {
    const params = useParams();
    const group = params.id;
    
    const {storeDetails, setStoreDetails} = useContext(AuthContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const [permissions, setPermissions] = useState({
        templates: {
            add: false,
            edit: false,
            view: false,
            delete: false
        },
        stores: {
            add: false,
            edit: false,
            view: false,
            delete: false
        },
        visits: {
            add: false,
            add_comments: false,
            edit: false,
            view: false,
            delete: false
        },
        actions: {
            add: false,
            edit: false,
            view: false,
            delete: false,
            complete: false,
        },
        schedule: {
            add: false,
            edit: false,
            view: false,
            delete: false
        },
        reports: {
            view: false
        }
    });
    const [groupUsers, setGroupUsers] = useState([])
    const [showLoading, setShowLoading] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [openBar, setOpenBar] = React.useState(false);
    const [groupName, setGroupName] = useState("");
    const [barMessage, setBarMessage] = useState({})

    useEffect(() => {
        async function getDetails() {
            setShowLoading(true)
            const permissionTable = {}
            const groupRef = await getDoc(doc(db, "stores/" + storeDetails.rootID + "/permissions/" + group));
            
            if(!groupRef.exists())  {
                //console.log("Group does not exist")
                navigate("/app");
            }

            const groupData = groupRef.data();
            const groupName = groupData.name;

            const usersFromGroup = storeDetails.all_users.filter(user => {
                return user.group === params.id
            });

            setGroupName(groupName);
            setGroupUsers(usersFromGroup);

            const permissionRef = query(collection(db, "stores/" + storeDetails.rootID + "/permissions/" + group + "/resources"));
            const groupPermissionDocs = await getDocs(permissionRef);
            groupPermissionDocs.docs.forEach(doc => {
                permissionTable[doc.id] = doc.data();
            })

            setPermissions(permissionTable)
            setShowLoading(false)
        }

        if(params.id !== "new") getDetails()
        
    }, [])

    // useEffect(() => {
    //     if(permissions)
    //         console.log(permissions)
    // }, [permissions])

    const styles = {
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        fontWeight: 400,
        marginTop: 5,
        marginBottom: 5,
    };

    function updatePermission(type, permission) {
        setPermissions({
          ...permissions,
          [type]: {
            ...permissions[type],
            [permission]: !permissions[type][permission]
          }
        });
      }

      async function handleDeleteUserGroup() {
        setShowLoading(true);
        const userRef = query(collection(db, "stores/" + storeDetails.rootID + "/users"), where("active", "==", true), where("group", "==", group));
        const userDocs = await getDocs(userRef);
        if(userDocs.docs.length) {
            setShowConfirmDialog(false)
            setShowLoading(false)
            setBarMessage({
                type: "error",
                message: "You cannot delete a group with active users.",
                result: "error"
            })
            setOpenBar(true)
            return;
        } else {
            const groupDoc = doc(db, "stores/" + storeDetails.rootID + "/permissions/" + group);
            await deleteDoc(groupDoc)

                setBarMessage({
                    type: "info",
                    message: `${groupName} group has been successfully removed.`,
                    result: "success"
                })
                setOpenBar(true)
            
            
            
        }

      }

      function handleShowDeleteDialog() {
        setShowConfirmDialog(true)
      }

      const handleClose = (event, reason) => {
        
        setOpenBar(false);
        setShowLoading(false)
        if(barMessage.result === "success") navigate(-1)
      };

      async function createUserGroupCloud() {
        
        if(groupName.length === 0) {
            setBarMessage({
                type: "error",
                message: "Please enter a name for your user group.",
                result: "error"
            })
            setOpenBar(true)
            return false;
        }

        setShowLoading(true);

        const groupRef = query(collection(db, "stores/" + storeDetails.rootID + "/permissions"), where("name", "==", groupName))
        const groupDocs = await getDocs(groupRef);
        if(groupDocs.docs.length > 0) {
            setBarMessage({
                type: "error",
                message: "This user group already exists.",
                result: "error"
            })
            setOpenBar(true)
            return false;
        }
        
        
        const permissionsArray = Object.entries(permissions).map(([key, value]) => ({ [key]: value }));

        const permissionsRef = collection(db, `stores/${storeDetails.rootID}/permissions`);
        const docRef = await addDoc(permissionsRef, { name: groupName }); // Empty object for now, you can add other fields as needed

        // Add permissionsArray items inside the 'resources' subcollection
        const resourcesRef = collection(docRef, 'resources');
        for (const permissionItem of permissionsArray) {
            const permissionKey = Object.keys(permissionItem)[0];
            const permissionDocRef = doc(resourcesRef, permissionKey);
            await setDoc(permissionDocRef, permissionItem[permissionKey]);
          }

        // setStoreDetails({...storeDetails,
        //     user_groups: [...storeDetails.user_groups, {
        //         id: docRef.id,
        //         name: groupName
        //     }]
        // })
        

        return true;

      }

      async function handleUpdateGroupCloud() {

        // if new group create
        if(group === "new") {
            const bCreate = await createUserGroupCloud();
            if(bCreate) {
                setBarMessage({
                    type: "info",
                    message: "User group has been successfully created.",
                    result: "success"
                })
                setOpenBar(true)
            }
            return;
        }

        // update group
        setShowLoading(true);
        const permissionsArray = Object.entries(permissions).map(([key, value]) => ({ [key]: value }));
       
        // check if name changed then delete current
        // and update name
        await updateDoc(doc(db, "stores/" + storeDetails.rootID + "/permissions", group), { name: groupName }, { merge: true });
        for (const permissionItem of permissionsArray) {
            const key = Object.keys(permissionItem)[0]; // Extract the key from the object
        
            const permissionRef = doc(db, "stores/" + storeDetails.rootID + "/permissions/" + group + "/resources", key);
            await setDoc(permissionRef, permissionItem[key]);
        }

        // setStoreDetails({...storeDetails,
        //     user_groups: storeDetails.user_groups.map(groupItem => {
        //         if(groupItem.id === group) return {...groupItem, name: groupName}
        //         else return groupItem
        //     }),
            
        // })

        setBarMessage({
            type: "info",
            message: "User group has been successfully updated.",
            result: "success"
        })
        setOpenBar(true)


      }

  return (
    <>
    <Loader show={showLoading} />
    <DeleteReportDialog
            open={showConfirmDialog}
            setOpen={setShowConfirmDialog}
            title="Delete User Group"
            content="Are you sure you want to delete this user group?"
            action={() => handleDeleteUserGroup(true, true)} />
    
    <Snackbar open={openBar} autoHideDuration={2500} onClose={handleClose}>
        <Alert onClose={handleClose} severity={barMessage.type} sx={{ width: '100%' }}>
          {barMessage.message}
        </Alert>
        </Snackbar>
    {permissions && 
    <CardContainer>
        

        
        {params.id !== "new" ? <GroupTitle>Enter a name for this user group</GroupTitle>:
        <GroupTitle>Enter a name for this user group</GroupTitle>} 
        <TemplateFormFieldRow>
        <TextField 
                            fullWidth
                            size="small"
                            variant="outlined" 
                            placeholder='Enter a name for the user group' 
                            value={groupName} 
                            onChange={e => setGroupName(e.target.value)}
                            />
        </TemplateFormFieldRow>

        {params.id !== "new" && <>
        <GroupTitle>Users that are part of this user group</GroupTitle>

        <Autocomplete
        sx={{'marginBottom': '20px'}}
        multiple
        fullWidth

        id="group-tags"
        options={storeDetails.all_users}
        getOptionLabel={(option) => option.name}
        value={groupUsers}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option.name}
                {...getTagProps({ index })}
                onDelete={() => {}} // Prevent removal by not providing onDelete handler
              />
            ))
          }
        renderInput={(params) => (
        <TextField
            {...params}
            size='small'

        />
      )} />

        </>
        }

        <GroupTitle>Permissions for {groupName} group</GroupTitle>
        <CardContainer style={{'marginBottom': '10px'}}>
            <PermissionType>Templates</PermissionType>
            <FormGroup sx={{marginTop: '10px'}}>
            
                        <FormControlLabel control={<Checkbox checked={permissions.templates.view} onChange={(e) =>  updatePermission("templates", "view")} />} 
                            label={
                                <Typography style={styles}>
                                    View templates
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel 
                            control={<Checkbox disabled={!permissions.templates.view} checked={permissions.templates.view && permissions.templates.add} onChange={(e) =>  updatePermission("templates", "add")} />} 
                            label={
                                <Typography style={styles}>
                                    Add templates
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.templates.view} checked={permissions.templates.view && permissions.templates.edit} onChange={(e) =>  updatePermission("templates", "edit")} />} 
                            label={
                                <Typography style={styles}>
                                    Edit templates
                                </Typography>
                            } 
                        />
                        
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.templates.view} checked={permissions.templates.view && permissions.templates.delete} onChange={(e) =>  updatePermission("templates", "delete")} />} 
                            label={
                                <Typography style={styles}>
                                    Delete templates
                                </Typography>
                            } 
                        />
                        
                    </FormGroup>
        </CardContainer>

        <CardContainer style={{'marginBottom': '10px'}}>
            <PermissionType>Locations</PermissionType>
            <FormGroup sx={{marginTop: '10px'}}>
            
                        <FormControlLabel control={<Checkbox checked={permissions.stores.view} onChange={(e) =>  updatePermission("stores", "view")} />} 
                            label={
                                <Typography style={styles}>
                                    View locations
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel 
                            control={<Checkbox disabled={!permissions.stores.view} checked={permissions.stores.view && permissions.stores.add} onChange={(e) =>  updatePermission("stores", "add")} />} 
                            label={
                                <Typography style={styles}>
                                    Add locations
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.stores.view} checked={permissions.stores.view && permissions.stores.edit} onChange={(e) =>  updatePermission("stores", "edit")} />} 
                            label={
                                <Typography style={styles}>
                                    Edit locations
                                </Typography>
                            } 
                        />
                        
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.stores.view} checked={permissions.stores.view && permissions.stores.delete} onChange={(e) =>  updatePermission("stores", "delete")} />} 
                            label={
                                <Typography style={styles}>
                                    Delete locations
                                </Typography>
                            } 
                        />
                        
                    </FormGroup>
        </CardContainer>

        <CardContainer style={{'marginBottom': '10px'}}>
            <PermissionType>Visits</PermissionType>
            <FormGroup sx={{marginTop: '10px'}}>
            
                        <FormControlLabel control={<Checkbox checked={permissions.visits.view} onChange={(e) =>  updatePermission("visits", "view")} />} 
                            label={
                                <Typography style={styles}>
                                    View visit reports
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel 
                            control={<Checkbox disabled={!permissions.visits.view} checked={permissions.visits.view && permissions.visits.add} onChange={(e) =>  updatePermission("visits", "add")} />} 
                            label={
                                <Typography style={styles}>
                                    Start visits
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.visits.view} checked={permissions.visits.view && permissions.visits.edit} onChange={(e) =>  updatePermission("visits", "edit")} />} 
                            label={
                                <Typography style={styles}>
                                    Edit visits after completed
                                </Typography>
                            } 
                        />
                        
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.visits.view} checked={permissions.visits.view && permissions.visits.delete} onChange={(e) =>  updatePermission("visits", "delete")} />} 
                            label={
                                <Typography style={styles}>
                                    Delete visits
                                </Typography>
                            } 
                        />
                        
                    </FormGroup>
        </CardContainer>

        <CardContainer style={{'marginBottom': '10px'}}>
            <PermissionType>Actions</PermissionType>
            <FormGroup sx={{marginTop: '10px'}}>
            <FormControlLabel control={<Checkbox checked={permissions.actions.view} onChange={(e) =>  updatePermission("actions", "view")} />} 
                            label={
                                <Typography style={styles}>
                                    View actions
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel 
                            control={<Checkbox disabled={!permissions.actions.view} checked={permissions.actions.view && permissions.actions.add} onChange={(e) =>  updatePermission("actions", "add")} />} 
                            label={
                                <Typography style={styles}>
                                    Add actions
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.actions.view} checked={permissions.actions.view && permissions.actions.edit} onChange={(e) =>  updatePermission("actions", "edit")} />} 
                            label={
                                <Typography style={styles}>
                                    Edit actions
                                </Typography>
                            } 
                        />
                        <Divider />
                        
                        <FormControlLabel control={<Checkbox disabled={!permissions.actions.view} checked={permissions.actions.view && permissions.actions.complete} onChange={(e) =>  updatePermission("actions", "complete")} />} 
                            label={
                                <Typography style={styles}>
                                    Complete actions
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.actions.view} checked={permissions.actions.view && permissions.actions.delete} onChange={(e) =>  updatePermission("actions", "delete")} />} 
                            label={
                                <Typography style={styles}>
                                    Delete actions
                                </Typography>
                            } 
                        />
                        
                    </FormGroup>
        </CardContainer>

        <CardContainer style={{'marginBottom': '10px'}}>
            <PermissionType>Schedule</PermissionType>
            <FormGroup sx={{marginTop: '10px'}}>
                        <FormControlLabel control={<Checkbox checked={permissions.schedule.view} onChange={(e) =>  updatePermission("schedule", "view")} />} 
                            label={
                                <Typography style={styles}>
                                    View schedule
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel 
                            control={<Checkbox disabled={!permissions.schedule.view} checked={permissions.schedule.view && permissions.schedule.add} onChange={(e) =>  updatePermission("schedule", "add")} />} 
                            label={
                                <Typography style={styles}>
                                    Add scheduled visits
                                </Typography>
                            } 
                        />
                        <Divider />
                        <FormControlLabel control={<Checkbox disabled={!permissions.schedule.view} checked={permissions.schedule.view && permissions.schedule.edit} onChange={(e) =>  updatePermission("schedule", "edit")} />} 
                            label={
                                <Typography style={styles}>
                                    Edit schedule visits
                                </Typography>
                            } 
                        />
                        <Divider />
                        
                        <FormControlLabel control={<Checkbox disabled={!permissions.schedule.view} checked={permissions.schedule.view && permissions.schedule.delete} onChange={(e) =>  updatePermission("schedule", "delete")} />} 
                            label={
                                <Typography style={styles}>
                                    Delete scheduled items
                                </Typography>
                            } 
                        />
                        
                    </FormGroup>
        </CardContainer>

        <CardContainer style={{'marginBottom': '10px'}}>
            <PermissionType>Reports</PermissionType>
            <FormGroup sx={{marginTop: '10px'}}>
                        <FormControlLabel 
                            control={<Checkbox checked={permissions.reports.view} onChange={(e) =>  updatePermission("reports", "view")} />} 
                            label={
                                <Typography style={styles}>
                                    Run reports
                                </Typography>
                            } 
                        />
                        
                        
                    </FormGroup>
        </CardContainer>
    </CardContainer>}

    {group !== "new" ?
    <TemplateFormButtonStack align="right" style={{'marginTop': '20px'}}>
        <Button color={Colors.highlight} onClick={handleShowDeleteDialog}>Delete Group</Button>
        <Button color={Colors.green} onClick={handleUpdateGroupCloud}>Update Group</Button>
        
    </TemplateFormButtonStack> :
    
    <TemplateFormButtonStack align="right" style={{'marginTop': '20px'}}>
        <Button color={Colors.green} onClick={handleUpdateGroupCloud}>Create Group</Button>
    </TemplateFormButtonStack>
    }
    </>
    
  )
}

export default SettingsUserGroupPermissions