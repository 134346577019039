import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Colors } from './styled/styled.container';
import { Typography } from '@mui/material';

export default function DeleteReportDialog(props) {
  const {open, setOpen, title, content, extra_content, action} = props;

  const handleClose = () => {
    setOpen(false);
  };

  function handleAction() {
    action();
    }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
          {extra_content && 
          <Typography sx={{'marginTop': '10px', color: `${Colors.highlight}`}}>{extra_content}</Typography>
          }
          
        </DialogContent>

        
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleAction} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}