import styled from "styled-components";
import { Colors } from "./styled.container";
import FullCalendar from "@fullcalendar/react";

export const ScheduleItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 100%;
    background-color: ${Colors.light_grey};
    color: ${Colors.dark_grey};
    border: 0;
`

export const ScheduleItemContainer = styled.div`
    
    width: 100%;

    height: fit-content;
    
    
    /* background-color: ${props => props.type === "action" ? Colors.highlight : Colors.blue}; */
    
    color: ${Colors.dark_grey};
    font-size: 0.7rem;
    padding: 0px 10px;
    margin-bottom: 5px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`

export const ScheduleItemOverview = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  margin-bottom: 3px;

  
`

export const ScheduleItemOverviewStatus = styled.div`
  background-color: white;
  color: ${Colors.card_background};
  background: ${props => props.status === "done" ? Colors.scheduler_gradient_done : props.status === "waiting" ? Colors.scheduler_gradient_waiting : Colors.scheduler_gradient_missed};
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 4px;
  padding: 3px 5px;
  display: flex;

  justify-content: space-between;

`

export const ScheduleItemTemplate = styled.div`
  font-size: 0.7rem;
  width: 100%;
  white-space: nowrap;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50ch;
`

export const ScheduleItemStore = styled.div`

    font-size: 0.7rem;
    font-weight: 500;
    
    
`

export const ScheduleItemAction = styled.div`

    font-size: 0.6rem;
    
    overflow: hidden;
    margin-top: 5px;
    font-weight: 600;
    background-color: ${Colors.white};
    color: ${Colors.dark_blue};
    text-align: center;
    padding: 2px;
    border-radius: 5px;
    
    
`

export const StyledCalendarWrapper = styled.div`

box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
border-radius: 5px;
background-color: ${Colors.white};
  width: 100%;
  height: 100%;
  user-select: none;
  display: inline-block;
  
  --fc-today-bg-color: ${Colors.light_grey_blue};
  
  /* .fc-list-event-time {
    display: none;
  } */

  

  .fc-h-event {
    background-color: transparent !important;
    border: 0 !important;
  }

  

  .fc .fc-toolbar.fc-header-toolbar {

    background-color: ${Colors.white};
    margin-bottom: 0px;
    padding: 0px;

  }



  

  .fc-toolbar {
    display: flex;

    flex-direction: column;

    width: 100%;
    padding: 0px 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    margin-bottom: 5px;

    .fc-toolbar-chunk {
      display: flex;
      width: 100%;
      
    }

    .fc-toolbar-chunk:first-child {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: ${Colors.white};
      padding: 10px 0px;
      margin-bottom: 5px;

      button {
        box-shadow: none;
        font-weight: 700;
        font-size: 1rem;
        background-color: ${Colors.white};
        color: ${Colors.blue};
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
          
          box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
        
        }
      }
    }

    .fc-toolbar-chunk:last-child {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      margin-bottom: 25px;

      button {
        font-size: 0.9rem;
      }
    }
  }

  

  .fc-toolbar-title {
    color: ${Colors.blue};
    margin: 0px;

    padding: 10px;

    font-weight: 700;
    font-size: 1.4rem;
    
    background-color: ${Colors.white};
    border: 0;
    
    border-radius: 2px;
    
    width: 100%;
    text-align: center;
  }

  .fc--button {
    display: none;
  }

  .fc .fc-button {
    
    border: 0;
    background-color: ${Colors.card_background};
    padding: 5px 15px;
    color: ${Colors.dark_grey};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    font-weight: 500;
    font-size: 0.8rem;

    @media (max-width: 1024px) {

      margin: 2px;
    }


  }

  

  .fc-theme-standard .fc-scrollgrid {
    border: 0;
    
    
  }



  @media (max-width: 700px) {
    .fc-toolbar-title {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
    }

    .fc .fc-media-screen {
      width: 100% !important;
      height: 100vh !important;
    }

    .fc .fc-button {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 360px) {
    .fc-toolbar-title {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
    }

    .fc .fc-button {
      font-size: 0.8rem;
    }
  }
`;

export const StyledCalendarMainHolder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;

`

export const StyledCalendarHolder = styled.div`
  
  width: 100%;
  flex: 1;
  
`



export const DataLabelContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const DataLabelCard = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin: 5px;
  font-size: 0.8rem;
`

export const DataLabelColor = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: ${props => props.color ? props.color : Colors.blue};
`

export const TooltipContent = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
`;

export const StyledCalendarMobileDetailsHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.3;
  margin-top: 10px;
  margin-left: 0px;
  
  border-radius: 5px;
`

export const StyledCalendarDetailsHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.3;
  margin-left: 10px;
  
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  padding: 10px 0px;

  @media(max-width: 900px) {
    background-color: red;
    
    display: none;
  }
`

export const StyledCalendarDetailsHolderHeader = styled.div`
  display: flex;
  
  align-items: center;
  padding: 10px 10px;
  background: ${Colors.white};
  color: 'black';
  
  font-size: 1rem;
  margin-bottom: 15px;
  
  font-weight: 500;
  letter-spacing: 0.8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  
`

export const StyledCalendarDetailsHolderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: ${Colors.dark_grey};
  font-size: 0.8rem;

`

export const StyledCalendarDetailsHolderContentTitle = styled.div`
  display: flex;
  font-weight: 500;
  margin-bottom: 5px;
  justify-content: center;
  font-size: 0.9rem;
  color: ${Colors.dark_grey};
`

export const StyledCalendarDetailsHolderItem = styled.div`
  display: flex;
  
  padding: 5px 3px;
  margin: 3px 0px;
  justify-content: space-between;
  
  color: ${Colors.dark_grey};
  font-size: 0.8rem;

  &:last-child {
    border-bottom: 0;
  }
`

export const StyledCalendarDetailsHolderContentLabel = styled.div`
  display: flex;
  font-weight: 500;
  display: flex;
  align-items: center;
  
  height: fit-content;
  
  
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 10px;
  min-width: 100px;
  font-size: 0.8rem;
  color: ${Colors.dark_grey};
`

export const StyledCalendarDetailsHolderContentValue = styled.div`
  display: flex;
  font-weight: 600;
  text-align: right;
  align-items: center;
  font-size: 0.8rem;
  color: ${Colors.dark_grey_highlight};
`

export const StyledNotificationTriggerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

`

export const StyledNotificationTriggerItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-right: 10px;
`

export const StyledNotificationTriggerItem = styled.div`
  display: flex;
  
  width: 100%;
  justify-content: space-around;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${Colors.light_grey};
  color: ${Colors.dark_grey};
  margin-bottom: 5px;
`