import { Divider } from '@mui/material'
import React from 'react'
//import { Button } from '@mui/material'
import { ActionButton, Button, StyledActionIcon, StyledTickIcon } from './styled/styled.container'
import { Colors } from './styled/styled.container'

function ActionCompleteButton({element, currentUser, action, undoAction, actionComplete, actionCompleteDate}) {

    if(element.actionByName === "None") return(<ActionButton style={{marginTop: '10px'}} fullWidth variant='outlined' disabled>
        <div style={{display: 'flex', 'alignItems': 'center'}}><StyledTickIcon />No action required</div>
    </ActionButton>)
    else {
        if(element.actionComplete) {
            if(element.actionByName === currentUser.name || currentUser.admin) 
                return(
                
                <ActionButton fullWidth 
                style={{marginTop: '10px'}} 
                color={Colors.green} 
                onClick={action} variant="contained">
                    <div style={{display: 'flex', 'alignItems': 'center'}}><StyledTickIcon />Completed by {element.actionByName} (Click to undo)</div>
                    <Divider sx={{margin: '5px'}} />
                    <div style={{'fontSize': '0.8rem', 'fontWeight': '600'}}>Completed {element.actionCompleteDate}</div>
                </ActionButton>

                ) 
            else 
                return(
                <ActionButton fullWidth
                style={{marginTop: '10px'}} variant="contained">
                    <div style={{display: 'flex', 'alignItems': 'center'}}>
                    <StyledTickIcon />Completed by {element.actionByName}
                    <Divider sx={{margin: '5px'}} />
                    <div style={{'fontSize': '0.7rem', 'fontWeight': '600'}}>Completed {element.actionCompleteDate}</div>
                    </div>
                    
                </ActionButton>)
        }
        else {
            if(element.actionByName === currentUser.name) 
                return(
                <ActionButton fullWidth style={{marginTop: '10px'}} color={Colors.highlight} onClick={action} variant="contained">
                    <div style={{display: 'flex', 'alignItems': 'center'}}><StyledActionIcon /> Waiting for you to complete</div>
                    
                </ActionButton>
                )
            else return(<ActionButton fullWidth style={{marginTop: '10px'}} color={Colors.highlight}>
                <div style={{display: 'flex', 'alignItems': 'center'}}><StyledActionIcon />Waiting for {element.actionByName} to complete</div>
                </ActionButton>)
        }
    }
}

export default ActionCompleteButton