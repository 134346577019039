import React, { useState, useRef, useLayoutEffect, forwardRef, useImperativeHandle } from "react";

import styled from "styled-components";
import { StyledCanvasContainer } from "./styled/styled.container";
import SignatureCanvas from 'react-signature-canvas';
const { useEffect } = React;

const Canvas = styled.canvas`
  max-width: 100%;
  border-radius: 10px;
  max-height: 200px;
  touch-action: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
`;

// Define the SignatureCanvas component
const Signature = forwardRef((props, ref) => {
  const canvasRef = useRef(null);

  useLayoutEffect(() => {
    if (canvasRef.current) {
      //console.log(canvasRef.current)
      ref.current = canvasRef.current;
    }
  }, [canvasRef]);

  useEffect(() => {
    if (canvasRef.current) {
      ref.current = canvasRef.current;
    }
  }, [canvasRef.current, ref]);

  useImperativeHandle(ref, () => ({
    // Define methods for the ref that can be accessed by the parent component
    clearCanvas() {
      //console.log("Called here", canvasRef.current)
      //const context = canvasRef.current.getContext("2d");
      canvasRef.current.clear();
      //context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    },

    handleSaveClick() {
      return new Promise((resolve, reject) => {
        // Create a temporary canvas element to draw the signature image onto
        const tempCanvas = document.createElement("canvas");
        const tempContext = tempCanvas.getContext("2d");
    
        // Get the signature image from the signature canvas
        const signatureImage = new Image();
        signatureImage.src = canvasRef.current.toDataURL("image/png");
    
        // Once the signature image is loaded, draw it onto the temporary canvas
        signatureImage.onload = () => {
          tempCanvas.width = signatureImage.width;
          tempCanvas.height = signatureImage.height;
          tempContext.drawImage(signatureImage, 0, 0);
    
          // Convert the temporary canvas to a blob object and resolve the promise
          tempCanvas.toBlob(blob => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Failed to create blob from canvas'));
            }
          }, 'image/png');
        };
      });
    },

    isCanvasBlank() {
      return canvasRef.current.isEmpty();
    }
  }));

  return (
    <StyledCanvasContainer>
      <SignatureCanvas 
          penColor="black"
          ref={canvasRef}

        />
    </StyledCanvasContainer>
  );
});

export default Signature;